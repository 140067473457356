import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {
  CopyrightDetailRequest,
  CopyrightUtils,
  IpUtils,
  SearchUtils,
  SharesError,
  SocietiesUtils,
  StringUtils,
  TerritoryUtils,
  UsersUtils,
  WorkUtils,
  selectControlValue,
} from '@ice';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { WorkStatusType } from 'config/constants/global.constants';
import { agreementsSearchMask } from 'config/constants/masked-ids.constants';
import { LEGACY_AGREEMENT_FIELD, ORIGINAL_AGREEMENT_FIELD } from 'config/constants/search.constants';
import {
  ERROR_AT_LEAST_ONE_RIGHT_TERRITORY,
  ERROR_NO_NEGATIVE_VALUE,
  ERROR_NO_REPETITION_RIGHT_TERRITORY,
  ERROR_ONLY_2_DECIMALS,
  ERROR_TOTAL_100,
  ERROR_WRITER_MECHANICAL_REASON_IS_EMPTY,
} from 'config/constants/shares.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { environment } from 'config/env';
import { AgreementsSearchExpressions } from 'config/search-form-builders/search-copyright.agreements';
import { SectionsConfig } from 'config/sections-config';
import { SectionCopyrightAgreements } from 'config/sections-config/section-copyright-agreements';
import { SectionCopyrightIps } from 'config/sections-config/section-copyright-ips';
import { SectionCopyrightWorks } from 'config/sections-config/section-copyright-works';
import { AdditionalFieldsValidator, SharesValidator } from 'config/stepper-builders/agreements/steps/validators';
import { find, get, includes, isArray, isEmpty, last, remove } from 'lodash';
import { ApiNamespace } from 'models';
import { SearchPartyNamesResponse } from 'models/response/search-party-names.response';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take, withLatestFrom } from 'rxjs/operators';
import { DetailService } from 'services/detail/detail.service';
import { FormlyService } from 'services/formly/formly.service';
import { IceMaskedIdsConfig } from 'services/masked-ids/masked-ids.model';
import { SearchService } from 'services/search/search.service';
import * as fromRoot from 'store/root';
import { SearchSimple } from 'store/root';
import * as fromUtils from 'store/root/utils';

const BASE_ID_PREFIX = 'IPI';
const BASE_ID_PREFIX_ICE = 'ICE';
const XREF_PREFIX = BASE_ID_PREFIX;
interface ForceSearchByField {
  key: string;
  value: string;
  customMaskedIdsConfig: IceMaskedIdsConfig[];
}

@Injectable()
export class FieldValidatorService {
  ns: ApiNamespace;

  territoryPromises: Map<string, any>;

  msgValidationControl: AbstractControl;
  errors = new Array<SharesError>();
  currentErrorMsg = '';
  validationErrors: Array<SharesError>;
  fieldShares: FormlyFieldConfig;
  writerMechanicalIsHidden: boolean;
  agreementFormAssignorType$ = new BehaviorSubject(null);
  isClaimRoleSubPublisher = new BehaviorSubject(false);
  isAssignorGenericCreator = new BehaviorSubject(false);

  repertoireDetailsFields: {
    creatorAffiliations: FormlyFieldConfig;
    workList: FormlyFieldConfig;
    contractingCompanies: FormlyFieldConfig;
    additionalCollectors: FormlyFieldConfig;
  } = { creatorAffiliations: null, workList: null, contractingCompanies: null, additionalCollectors: null };

  repertoireExcludedFields: {
    worksExcludedList: FormlyFieldConfig;
    agreementsExcludedList: FormlyFieldConfig;
  } = { worksExcludedList: null, agreementsExcludedList: null };

  workRegisterFields: {
    additionalClaims: FormlyFieldConfig;
  } = { additionalClaims: null };

  agreementRegisterFields: {
    gemaRemunerationClaimIndicator: FormlyFieldConfig;
    writerMechanicalReason: FormlyFieldConfig;
  } = { gemaRemunerationClaimIndicator: null, writerMechanicalReason: null };

  workClaimOwnershipSharesField: FormlyFieldConfig = null;
  iswcRegExp = /^([T]?)\-([0-9][0-9][0-9]?)\.([0-9][0-9][0-9]?)\.([0-9][0-9][0-9]?)\-([0-9]?)$/;

  constructor(private searchService: SearchService, private store: Store<fromRoot.RootState>, private detailService: DetailService, private formlyService: FormlyService) {
    store
      .pipe(select(fromRoot.getGlobalApiNamespace))
      .subscribe(ns => (this.ns = ns))
      .unsubscribe();
    this.territoryPromises = new Map<string, any>();
  }

  existOrganization(term: string): Promise<boolean> {
    const ns = (term && term.split(':')[0]) || 'ICE';
    const sectionData = CopyrightUtils.getSectionData(SectionsConfig.ORGANIZATIONS.name);
    const params = { id: term };
    const queryParams = params ? fromUtils.SearchUtilsFactory.formatParamsAdvancedSearch(params) : { term };
    return this.searchService
      .getSearchResults(sectionData, { ...queryParams, ns })
      .toPromise()
      .then(res => res.total > 0);
  }

  validIPKey(control, onValidate, onFailValidation) {
    return new Promise((resolve, reject) => (!includes(control.value, ',') ? resolve(true) : reject()))
      .then(res1 => {
        if (control.value) {
          this.getIpKey(control, onValidate, onFailValidation).subscribe(res => {
            if (res && res.items && res.items.length > 0) {
              const partyNameId = get(res, 'items[0].parties[0].partyNameId');
              const firstName = get(res, 'items[0].attributes.firstName');
              const name = get(res, 'items[0].attributes.name');
              const fullName = IpUtils.getIpFullName(get(res, 'items[0].attributes'));
              onValidate({ partyNameId, firstName, name, fullName });
            }
            onFailValidation();
          });
        } else {
          onValidate('');
        }
      })
      .catch(err => {
        onFailValidation();
        return false;
      });
  }

  validIPLegalEntityKey(control, onValidate, onFailValidation) {
    if (includes(control.value, ',')) {
      return false;
    }
    return new Promise((resolve, reject) => {
      if (control.value) {
        this.existLegalEntityIpKey(control, onValidate, onFailValidation).subscribe(res => {
          if (res && res.items && res.items.length > 0) {
            const partyId = get(res, 'items[0].parties[0].partyId');
            onValidate(partyId);
            return resolve(true);
          }
          onFailValidation();
          return resolve(false);
        });
      } else {
        onValidate('');
        return reject();
      }
    }).catch(() => {
      onFailValidation();
      return false;
    });
  }

  validWorkKey(control, onValidate, onFailValidation) {
    return new Promise((resolve, reject) => (!includes(control.value, ',') ? resolve(true) : reject()))
      .then(res => {
        if (control.value) {
          this.existWorkKey(control, onValidate, onFailValidation);
        } else {
          onValidate();
        }
      })
      .catch(() => {
        onFailValidation();
        return false;
      });
  }

  notExistsUser(userId: string): Promise<boolean> {
    const ns = UsersUtils.getNSFromUserId(userId);
    const sectionData = CopyrightUtils.getSectionData(SectionsConfig.USERS.name);
    const params = { id: userId };
    const queryParams = params ? fromUtils.SearchUtilsFactory.formatParamsAdvancedSearch(params) : { userId };
    return this.searchService
      .getSearchResults(sectionData, { ...queryParams, ns })
      .pipe(withLatestFrom(this.store.pipe(select(fromRoot.getIsOpenDialog))))
      .toPromise()
      .then(([res, statusDialog]: [any, boolean]) => res.total === 0 || statusDialog);
  }

  existEmail(ns: string, email: string): Promise<boolean> {
    const sectionData = CopyrightUtils.getSectionData(SectionsConfig.USERS.name);
    const params = { email };
    const queryParams = params ? fromUtils.SearchUtilsFactory.formatParamsAdvancedSearch(params) : { email };
    return this.searchService
      .getSearchResults(sectionData, { ...queryParams, ns })
      .toPromise()
      .then(res => res.total === 0);
  }

  existTerritory(control: FormControl, field, onError?, onSuccess?) {
    const selectedValue = selectControlValue(control);
    if (field.templateOptions) {
      field.templateOptions.lastValue = selectedValue;
    }
    const codes = (selectedValue || '')
      .toString()
      .split(',')
      .map(code => StringUtils.trimLeadingZerosFromString(code))
      .join();
    let cachedPromise = this.territoryPromises.get(codes);
    if (!cachedPromise) {
      try {
        cachedPromise = this.searchService.getTerritoriesCountries(codes).toPromise();
        this.territoryPromises.set(codes, cachedPromise);
      } catch (error) {
        return false;
      }
    }

    return cachedPromise
      .then(res => {
        if (res && res['tisns'] && res['tisns'].length && res['tisns'].length > 0) {
          if (onSuccess) {
            onSuccess(res);
          }
          return true;
        }
        if (onError) {
          onError();
        }
        return false;
      })
      .catch(() => false);
  }

  territoryStringToList(codes) {
    return this.searchService.getTerritoriesCountries(codes);
  }

  territoryShortenValidator(control, onSuccess = codesControl => {}) {
    const codes = control.value;
    const territoryFieldValue =
      get(control, 'parent.value.territory.value', '') ||
      get(control, 'parent.value.territory', '') ||
      get(control, 'parent.value.rollupExcludeTerritoriesText', '') ||
      get(control, 'parent.value.territories', '');
    return isArray(codes)
      ? this.searchService
          .getShortenTerritory(codes)
          .toPromise()
          .then(res => {
            const tisAText = TerritoryUtils.convertTerritoryArrayElements(res, TerritoryDataType.TISA).join('');
            const isTheSameValue = tisAText === territoryFieldValue || tisAText === `+${territoryFieldValue}`;
            if (territoryFieldValue && !isTheSameValue) {
              const territoryFieldControl =
                get(control, 'parent.controls.territory', null) ||
                get(control, 'parent.controls.rollupExcludeTerritoriesText', null) ||
                get(control, 'parent.controls.territories', null);
              if (territoryFieldControl) {
                territoryFieldControl.setValue(tisAText);
              }
            }
            onSuccess(control);
            return true;
          })
          .catch(() => false)
      : of(true);
  }

  existTerritoryAndIsInTerritoryList(control: FormControl, field, territoryList, onError?, onSuccess?) {
    if (field.templateOptions) {
      field.templateOptions.lastValue = control.value;
    }
    const codes = control.value
      .toString()
      .split(',')
      .map(code => StringUtils.trimLeadingZerosFromString(code))
      .join();
    let cachedPromise = this.territoryPromises.get(codes);
    if (!cachedPromise) {
      cachedPromise = this.territoryStringToList(codes).toPromise();
      this.territoryPromises.set(codes, cachedPromise);
    }

    return cachedPromise
      .then(res => {
        if (res && res['tisns'] && res['tisns'].length && res['tisns'].length > 0) {
          if (onSuccess) {
            onSuccess(res);
          }
          const actual = field.templateOptions.bindedFieldTerritoryCodes.value;
          let found = true;
          if (territoryList) {
            actual.map(territory => {
              found = found && territoryList.filter(originalTerritory => originalTerritory === territory).length > 0;
            });
          }
          field.templateOptions.errorMessage = !found ? 2 : 1;
          return found;
        }
        if (onError) {
          onError();
        }
        field.templateOptions.errorMessage = 1;
        return false;
      })
      .catch(() => false);
  }

  async existAgreementGroupReference(value: string): Promise<boolean> {
    const url = `${SectionCopyrightAgreements.baseUrlCubeData}/${SectionsConfig.AGREEMENT_GROUP.apiSegment}/${this.ns}/search`;
    const request = { include: SectionsConfig.AGREEMENTS.apiIncludes.search };
    const valueRequest = StringUtils.isContainsLetters(value) ? value : 'CUBE:' + value;
    const body = { or: [{ equals: { id: `${valueRequest}` } }, { equals: { 'relations[XREF].otherId': `${valueRequest}` } }] };
    try {
      const res = await this.searchService.customSearch(url, request, body, agreementsSearchMask).toPromise();
      return (res && res.total && res.total > 0) || false;
    } catch (e) {
      return false;
    }
  }

  existIPINameNumber(ipi, onValidate, onFailValidation) {
    const searchParams = {
      baseIdPrefix: 'IPI',
      expertQueryParams: undefined,
      from: 0,
      ns: 'CUBE',
      size: 35,
      xref: ipi,
      xrefList: false,
      xrefPrefix: 'IPI',
    };
    return this.searchService
      .getSearchResults(SectionCopyrightIps, searchParams)
      .toPromise()
      .then(res => {
        if (res && res.items && res.items.length > 0) {
          onValidate(res);
          return true;
        }
        onFailValidation();
        return false;
      })
      .catch(() => false);
  }

  existIpKey(control: FormControl, typeOf, onValidate, onFailValidation, searchByXrefPrefix: string = null) {
    const xrefPrefix = searchByXrefPrefix || XREF_PREFIX;
    const ipSearch = new fromUtils.SearchUtilsFactory(SectionsConfig.IPS.name).getFormatted(`#${control.value}`);
    return this.searchService
      .getSearchResults(SectionCopyrightIps, { ...ipSearch, baseIdPrefix: BASE_ID_PREFIX, xrefPrefix, typeOf, ns: this.ns })
      .toPromise()
      .then(res => {
        if (res && res.items && res.items.length > 0) {
          const item = res.items[0];
          const name = (item['attributes'] && IpUtils.getIpFullName(item['attributes'])) || '';
          const parties = item.parties.map(partyItem => ({
            ...partyItem.party,
            baseIpiNumber: partyItem.party.relations ? IpUtils.selectIPINumber(partyItem.party.relations).replace('IPI:', '') : '',
          }));
          const partiesWithBaseIpiNumber = parties.filter(partyItem => partyItem.baseIpiNumber && partyItem.baseIpiNumber !== '');

          const party = partiesWithBaseIpiNumber && partiesWithBaseIpiNumber.length > 0 ? partiesWithBaseIpiNumber[0] : parties[0];
          if (party) {
            const societies = party['societies'] || [];
            const prSociety = SocietiesUtils.getPRorMRSociety(societies, 'PR').societyCodes || '';
            const type = (party['attributes'] && party['attributes'].typeOf) || '';
            const relations = item['relations'] || '';
            const ipKey = IpUtils.selectIpsKey(relations, item.id);
            const baseIpiNumber = party.baseIpiNumber;
            const ipiNameNumber = relations && IpUtils.selectIPINumber(relations).replace('IPI:', '');
            const nameKey = ipKey && ipKey.replace('ICE:', '');
            const baseKey = party.relations && IpUtils.selectICENumber(party.relations).replace('ICE:', '');
            onValidate(name, prSociety, type, ipKey, baseIpiNumber, nameKey, baseKey, ipiNameNumber);
            return true;
          }
        }
        onFailValidation();
        return false;
      })
      .catch(() => false);
  }

  existIPBaseKey(control: FormControl, onValidate) {
    return new Promise((resolve, reject) => {
      const searchParams = {
        from: 0,
        size: 0,
        followRedirects: false,
      };

      const baseId = !control.value.includes(':') ? 'ICE:' + control.value : control.value;
      const query = `{"equals":{"relations[XREF].otherId":"${baseId}"}}`;

      this.searchService
        .postRequestCubeData(`${environment.apiUrlCubeData}/parties/CUBE/search`, searchParams, query)
        .toPromise()
        .then((res: { total: number }) => {
          if (res && res.total > 0) {
            onValidate();
            resolve(true);
            return true;
          }
          reject();
          return false;
        })
        .catch(() => {
          reject();
          return false;
        });
    });
  }

  existAgreementRefKey(control: FormControl, onValidate, onFailValidation) {
    const value = new AgreementsSearchExpressions().formatExactIDSearch((control.value || '').trim());
    const search = { xrefs: value };
    return this.searchService
      .getSearchResults(SectionCopyrightAgreements, { ...search, ns: this.ns })
      .toPromise()
      .then(res => {
        if (res && res.items && res.items.length > 0) {
          onValidate();
          return true;
        }
        onFailValidation();
        return false;
      })
      .catch(() => {
        onFailValidation();
        return false;
      });
  }

  getContributorFields(control: FormControl, onValidate, onFailValidation, extraParams = {}, xrefPrefix = XREF_PREFIX, forceSearchByField: ForceSearchByField = null) {
    const ipiNumber = (control.value || '').split(':')[1] ? control.value : `${xrefPrefix}:${control.value}`;
    const ipSearch = forceSearchByField
      ? JSON.parse(`{ "${forceSearchByField.key}": "${forceSearchByField.value}"}`)
      : new fromUtils.SearchUtilsFactory(SectionsConfig.IPS.name).getFormatted(`#${ipiNumber}`);
    const customMaskedIdsConfig = forceSearchByField && forceSearchByField.customMaskedIdsConfig;
    const searchWithExtra = { ...ipSearch, ...extraParams, customMaskedIdsConfig };
    return this.searchService
      .getSearchResults(SectionCopyrightIps, { ...searchWithExtra, baseIdPrefix: BASE_ID_PREFIX, xrefPrefix, ns: this.ns })
      .toPromise()
      .then((res: SearchPartyNamesResponse) => {
        if (res && res.items && res.items.length > 0) {
          const partyName = res.items[0];
          const name = IpUtils.getNameFromParty(partyName);
          const partyNameId = partyName.id;
          const partyId = partyName.parties && partyName.parties[0] && partyName.parties[0].partyId;
          const partyObject = partyName.parties && partyName.parties[0] && partyName.parties[0].party;
          if (partyNameId !== null && partyId !== null) {
            onValidate(partyId, partyNameId, name, partyName, partyObject);
            return true;
          }
        }
        onFailValidation();
        return false;
      })
      .catch(() => false);
  }

  getContributorFieldsFromIPI(field: any, control: FormControl, onValidate, onFailValidation, extraParams = {}, forceSearchByField: ForceSearchByField = null) {
    const xrefType = (field.key === 'ClaimantIPNameNumber' && BASE_ID_PREFIX_ICE) || undefined;
    return this.getContributorFields(control, onValidate, onFailValidation, extraParams, xrefType, forceSearchByField);
  }

  existLegalEntityIpKey(control: FormControl, onValidate, onFailValidation): Observable<any> {
    const value = control && control.value;
    const url = `${SectionCopyrightIps.baseUrlCubeData}/${SectionCopyrightIps.apiSegment}/CUBE/search`;
    const request = { include: SectionsConfig.IPS.apiIncludes.search };
    const body = `{"and":[{"not":{"equals":{"parties.party.id":""}}},
    {"or":[{"wildcard":{"relations[XREF].otherId":"IPI:${value}"}},
    {"equals":{"parties.party.relations[XREF].otherId":"IPI:${value}"}}]},
    {"equals":{"parties.party.attributes.typeOf":"L"}}]}`;
    return this.searchService.customSearch(url, request, body);
  }

  getIpKey(control: FormControl, onValidate, onFailValidation): Observable<any> {
    const value = control && control.value;
    const url = `${SectionCopyrightIps.baseUrlCubeData}/${SectionCopyrightIps.apiSegment}/CUBE/search`;
    const request = { include: SectionsConfig.IPS.apiIncludes.search };
    const body = `{"and":[{"not":{"equals":{"parties.party.id":""}}},
    {"wildcard":{"relations[XREF].otherId":"IPI:${value.trim()}"}}
    ]}`;
    return this.searchService.customSearch(url, request, body);
  }

  private getWorkIdFromSearchResult(res) {
    return res?.items?.[0]?.id;
  }

  existWorkKey(control: FormControl, onValidate, onFailValidation) {
    const workSearch = new fromUtils.SearchUtilsFactory(SectionsConfig.WORKS.name).getFormatted(`#${control.value.trim()}`);
    return this.searchService
      .getSearchResults(SectionCopyrightWorks, { ...workSearch, ns: this.ns })
      .toPromise()
      .then(res => {
        const workId = this.getWorkIdFromSearchResult(res);
        if (workId) {
          onValidate(workId);
          return true;
        }
        onFailValidation();
        return false;
      })
      .catch(() => {
        onFailValidation();
        return false;
      });
  }

  async isWorkKeyStatus4(control: FormControl): Promise<boolean> {
    if (!control.value) {
      return true;
    }
    const workSearch = new fromUtils.SearchUtilsFactory(SectionsConfig.WORKS.name).getFormatted(`#${control.value.trim()}`);
    const works = await this.searchService
      .getSearchResults(SectionCopyrightWorks, { ...workSearch, ns: this.ns })
      .toPromise()
      .catch(() => false);
    const shouldADifferentValidatorBeTriggered = !this.getWorkIdFromSearchResult(works);
    const workStatus = works?.items?.[0]?.attributes?.status;
    return workStatus === WorkStatusType['4'] || shouldADifferentValidatorBeTriggered;
  }

  existWorkKeyList(xrefList, onValidate, onFailValidation) {
    const formatedList = SearchUtils.formatXrefList(xrefList);
    return this.searchService
      .getSearchResults(SectionCopyrightWorks, { xrefList: true, ...formatedList, ns: this.ns })
      .toPromise()
      .then(res => {
        const preview = (xrefList || []).map(xref => {
          const work = find(get(res, 'items', []), item => xref && item.id && parseFloat(item.id.split(':')[1]) === parseFloat(xref.split(':')[1]));
          return [xref, (work && get(WorkUtils.selectTitle(get(work, 'attributes.titles')), 'title', '')) || 'NOT_FOUND'];
        });
        if (res && res.items && res.items.length === xrefList.length) {
          onValidate(preview);
          return true;
        }
        onFailValidation(preview);
        return false;
      })
      .catch(() => {
        onFailValidation();
        return false;
      });
  }

  useValidatorOnTouch(field): Promise<boolean> {
    return !field.formControl.touched && of(true).toPromise();
  }

  existAgreementKey(control: FormControl, onValidate, onFailValidation) {
    const agreementSearch = new fromUtils.SearchUtilsFactory(SectionsConfig.AGREEMENTS.name).getFormatted(`#${control.value}`);
    return this.searchService
      .getSearchResults(SectionCopyrightAgreements, { ...agreementSearch, ns: this.ns })
      .toPromise()
      .then(res => {
        if (res && res.items && res.items.length > 0) {
          const firstResult = res.items[0];
          onValidate(firstResult);
          return true;
        }
        onFailValidation();
        return false;
      })
      .catch(() => false);
  }

  searchPartyFieldValidator(
    onSuccessAssignValues: Function,
    control: FormControl,
    field: any,
    forceSearchByField: ForceSearchByField = null,
    xrefPrefix: string = null,
    onFailCallback?,
  ) {
    return new Promise((resolve, reject) =>
      field && field.formControl && field.formControl.value && field.formControl.value.length && field.formControl.value.length > 0
        ? this.getContributorFields(
            control,
            (partyId, partyNameId, name, partyNameObject) => {
              onSuccessAssignValues(partyId, partyNameId, name, partyNameObject);
              resolve(true);
            },
            () => {
              if (onFailCallback) {
                onFailCallback();
              }
              resolve(false);
            },
            {},
            xrefPrefix || BASE_ID_PREFIX_ICE,
            forceSearchByField,
          )
        : resolve(true),
    );
  }

  validAgreementKey(control, onValidate, onFailValidation) {
    return new Promise((resolve, reject) => (control.value && !includes(control.value, ',') ? resolve(true) : reject()))
      .then(res => this.existAgreementKey(control, onValidate, onFailValidation))
      .catch(() => {
        onFailValidation();
        return false;
      });
  }

  getParyNameIPIByPartyIPI(partyIPI, onSuccess, onError) {
    const request = { include: 'partyNames.partyName.attributes,partyNames.partyName.relations', followRedirects: false };
    return this.searchService.getRequestCubeData(`${environment.apiUrlCubeData}/parties/CUBE/${partyIPI}`, request).toPromise().then(onSuccess).catch(onError);
  }

  getSimpleMaskId(maskedIdConfig: IceMaskedIdsConfig, onValidate, onFailValidation) {
    return this.searchService
      .getRequestCubeData(`${environment.urlGetMaskedId}/${maskedIdConfig.type}/${maskedIdConfig.id}`, {})
      .toPromise()
      .then((res: { maskedId: string }) => {
        if (res && res.maskedId) {
          onValidate(res.maskedId);
          return true;
        }
        onFailValidation();
        return false;
      })
      .catch(() => false);
  }

  maskIdAgreement(control, onValidate, onFailValidation) {
    const { value } = control;
    return new Promise((resolve, reject) => (value ? resolve(true) : reject()))
      .then(() => {
        const id = value.includes('ICE:') ? value : `ICE:${value}`;
        return this.getSimpleMaskId({ type: 'agreement', id }, onValidate, onFailValidation);
      })
      .catch(() => true);
  }

  generalSharesValidator(termTerritoryCodes?) {
    const field = this.fieldShares;
    const parentModel = get(field, 'parent.model');
    if (field) {
      if (SharesValidator.check2DecimalsInAll(field.model)) {
        this.addError(ERROR_ONLY_2_DECIMALS, 'ONLY_2_DECIMALS');
        return false;
      } else {
        this.removeError(ERROR_ONLY_2_DECIMALS, 'ONLY_2_DECIMALS');
      }
      if (SharesValidator.checkNegative(field.model)) {
        this.addError(ERROR_NO_NEGATIVE_VALUE, 'ERROR_NO_NEGATIVE_VALUE');
        return false;
      } else {
        this.removeError(ERROR_NO_NEGATIVE_VALUE, 'ERROR_NO_NEGATIVE_VALUE');
      }
      if (!SharesValidator.checkTotal100(field.model)) {
        this.addError(ERROR_TOTAL_100, 'ERROR_TOTAL_100');
        return false;
      } else {
        this.removeError(ERROR_TOTAL_100, 'ERROR_TOTAL_100');
      }
      if (!SharesValidator.checkNoRepeatRightForTerritory(field.model)) {
        this.addError(ERROR_NO_REPETITION_RIGHT_TERRITORY, 'ERROR_NO_REPETITION_RIGHT_TERRITORY');
        return false;
      } else {
        this.removeError(ERROR_NO_REPETITION_RIGHT_TERRITORY, 'ERROR_NO_REPETITION_RIGHT_TERRITORY');
      }
      if (termTerritoryCodes && !SharesValidator.checkAtLeastOneRightForEachTerritory(field.model, termTerritoryCodes)) {
        this.addError(ERROR_AT_LEAST_ONE_RIGHT_TERRITORY, 'ERROR_AT_LEAST_ONE_RIGHT_TERRITORY');
        return false;
      } else {
        this.removeError(ERROR_AT_LEAST_ONE_RIGHT_TERRITORY, 'ERROR_AT_LEAST_ONE_RIGHT_TERRITORY');
      }

      this.writerMechanicalIsHidden = AdditionalFieldsValidator.writerMechanicalReasonHideExpression(parentModel, this.agreementRegisterFields.writerMechanicalReason);
      const writerMechanicalReason = parentModel?.writer_mechanical_reason;
      if (!this.writerMechanicalIsHidden && !writerMechanicalReason) {
        this.addError(ERROR_WRITER_MECHANICAL_REASON_IS_EMPTY, 'ERROR_WRITER_MECHANICAL_REASON_IS_EMPTY');
      } else {
        this.removeError(ERROR_WRITER_MECHANICAL_REASON_IS_EMPTY, 'ERROR_WRITER_MECHANICAL_REASON_IS_EMPTY');
      }
      this.checkFormlyExpressions();
    }
  }

  checkFormlyExpressions() {
    this.formlyService.checkExpressions();
  }

  addError(type: number, id: string) {
    const error = find(this.errors, { id, type });
    if (!error) {
      this.errors.push({
        msg: this.validationErrors[type].msg,
        type,
        id,
      });
    } else {
      error['msg'] = this.validationErrors[type].msg;
    }
    this.updateErrorMsg();
  }

  removeError(type: number, id: string) {
    remove(this.errors, { id, type });
    this.updateErrorMsg();
  }

  removeAllErrorsField(id: string) {
    remove(this.errors, { id });
    this.updateErrorMsg();
  }

  updateErrorMsg() {
    if (this.msgValidationControl) {
      if (this.errors.length > 0) {
        if (this.currentErrorMsg !== this.errors[this.errors.length - 1].msg) {
          this.currentErrorMsg = this.errors[this.errors.length - 1].msg;
        }
      } else if (this.currentErrorMsg !== '') {
        this.currentErrorMsg = '';
      }
      this.msgValidationControl.setValue(this.currentErrorMsg);
    }
  }

  agreementLegacyValidator(errorMessage: string) {
    return {
      expression: (control: FormControl) =>
        new Promise((resolve, reject) => {
          if (!control.value) {
            resolve(true);
          } else {
            const originalAgreementRefControl = control.parent.controls[ORIGINAL_AGREEMENT_FIELD];
            const legacyAgreementControl = control.parent.controls[LEGACY_AGREEMENT_FIELD];
            if (control.value === originalAgreementRefControl.value || control.value === legacyAgreementControl.value) {
              resolve(true);
            } else {
              this.store.dispatch(new fromRoot.ShowDataLoadingVisibility(true));
              return this.searchService
                .existAgreementReference(control.value, this.ns)
                .then((res: boolean) => {
                  if (!res) {
                    originalAgreementRefControl.setValue(control.value);
                    this.existLegacyAgreementReferenceValidator(control, legacyAgreementControl).then((result: boolean) => {
                      resolve(result);
                    });
                  } else {
                    legacyAgreementControl.setValue('');
                    originalAgreementRefControl.setValue('');
                    resolve(true);
                  }
                  this.store.dispatch(new fromRoot.ShowDataLoadingVisibility(false));
                })
                .catch(err =>
                  this.existLegacyAgreementReferenceValidator(control, legacyAgreementControl).then((result: boolean) => {
                    resolve(result);
                    this.store.dispatch(new fromRoot.ShowDataLoadingVisibility(false));
                  }),
                );
            }
          }
        }),
      message: errorMessage,
    };
  }

  async existLegacyAgreementReferenceValidator(control: FormControl, legacyAgreementControl: FormControl): Promise<boolean> {
    const value = control.value;

    try {
      const res = await this.searchService.existLegacyAgreementReference(value);
      if (res) {
        legacyAgreementControl.setValue(last(res.mainId.split(':')));
        if (value !== legacyAgreementControl.value) {
          this.store
            .pipe(select(fromRoot.getRouterQueryParams), withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection))), take(1))
            .subscribe(([queryParams, sectionName]: [any, string]) => {
              const { searchSimple } = queryParams;
              if (sectionName === SectionsConfig.AGREEMENTS.name && searchSimple) {
                this.store.dispatch(new SearchSimple(`#${legacyAgreementControl.value}`, sectionName));
              }
            });
          control.setValue(legacyAgreementControl.value);
          control.markAsDirty();
        }
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  agreementRefFieldAndLegacyValidator(fieldName: string, translate: TranslateService): FormlyFieldConfig[] {
    return [
      {
        className: 'flex-1',
        key: fieldName,
        type: 'input',
        wrappers: ['wrapper-info', 'wrapper-custom-comment', 'form-field', 'wrapper-input-text'],
        modelOptions: {
          updateOn: 'blur',
        },
        templateOptions: {
          type: 'text',
          placeholder: translate.instant('AGREEMENTS.REFERENCE_NUMBER'),
          infoText: translate.instant('AGREEMENTS.REFERENCE_INFO'),
          required: false,
          customComment: '',
        },
        expressionProperties: {
          'templateOptions.customComment': model =>
            model[fieldName] && model[fieldName] === model[LEGACY_AGREEMENT_FIELD] && model[LEGACY_AGREEMENT_FIELD] !== model[ORIGINAL_AGREEMENT_FIELD]
              ? translate.instant('AGREEMENTS.ERRORS.LEGACY_AGREEMENT_CHANGE', {
                  searchedXref: model[ORIGINAL_AGREEMENT_FIELD],
                  correctXref: model[fieldName],
                })
              : '',
        },
        asyncValidators: {
          refValidation: FormlyValidatorUtils.getReferenceValidator(translate, 'AGREEMENTS.ERRORS.INVALID_AGREEMENT_XREF'),
          refLegacyValidation: this.agreementLegacyValidator(translate.instant('AGREEMENTS.ERRORS.INVALID_AGREEMENT_XREF')),
        },
        hooks: {
          onDestroy: field => field.formControl.setValue(null),
        },
      },
      {
        key: LEGACY_AGREEMENT_FIELD,
        type: 'input',
        className: 'ice-display-none',
      },
      {
        key: ORIGINAL_AGREEMENT_FIELD,
        type: 'input',
        className: 'ice-display-none',
      },
    ];
  }

  organizationIdExists(controlValue, checkNotExisting = false): Promise<any> {
    if (!controlValue) {
      return of(true).toPromise();
    }

    const request: CopyrightDetailRequest = {
      key: controlValue,
      section: SectionsConfig.ORGANIZATIONS.name,
    };
    return this.detailService
      .getDetail(request)
      .toPromise()
      .then(items => checkNotExisting !== !isEmpty(items))
      .catch(error => {
        if (error.status === HttpStatusCode.NotFound) {
          return of(true).toPromise();
        }
      });
  }

  repertoireIdExists(controlValue, onSuccess = item => {}, onFail = () => {}): Promise<any> {
    if (!controlValue) {
      return of(true).toPromise();
    }

    const request: CopyrightDetailRequest = {
      key: !controlValue.includes('ICE:') ? `ICE:${controlValue}` : controlValue,
      section: SectionsConfig.REPERTOIRES.name,
      ns: 'CUBE',
    };
    return this.detailService
      .getDetail(request)
      .toPromise()
      .then(item => {
        if (!isEmpty(item)) {
          onSuccess(item);
        } else {
          onFail();
        }
        return !isEmpty(item);
      })
      .catch(() => {
        onFail();
        return false;
      });
  }
  isIswcValidValue(value) {
    let sum = 0;
    Array.from(value.split('-')[1].replace(/\./g, '')).forEach((digit, index) => (sum += +digit * (index + 1)));
    return +value.split('-')[2] === (10 - ((sum + 1) % 10)) % 10;
  }
}
