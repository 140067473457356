import { createApiCall, isResponseHandler, setBody, setInclude, setLabelResolver, setQueryParams, setResultCleaner, validateLabels } from 'config/api-calls/api-call.factory';
import { environment } from 'config/env';
import { SectionsConfig } from 'config/sections-config';
import { RequestType } from 'config/sections-config/api-call';
import * as fromLabelResolvers from './activity.label-resolvers';
import * as fromResultCleaners from './activity.result-cleaners';

const conflictsSection = SectionsConfig.CONFLICTS.name;
const ns = environment.apiNamespace;
const workIncludes = `attributes{category,societyMarkers, duration, genre, id, ns, origin, owner, purpose, source,
tags, titles, version, status,instruments,standardInstrumentation,copyrightOverrides},copyrightOverrides,
relations{relation,otherId},
contributions.agreements,
contributions.contributor.partyName.relations{relation,otherId},
contributions.contributor.partyName.attributes,
contributions.contributor.societies,
contributions.manuscriptShares.shares,
societies.society.attributes,
partyNames{role,partyName}.partyName{attributes, relations}.attributes{id,typeOfName,name,firstName},
partyNames.partyName.relations{relation,otherId},
admin.attributes, claims,
claims.claimant.partyName.attributes,
conflicts.conflict.claimCases.claimCase.attributes,
conflicts.conflict.attributes.data,
claims.claimant.party.societies,
claims.claimant.party.relations{relation,otherId},
claims.claimant.partyName.relations{relation,otherId},
claims.extensions,tags,counterclaims.attributes`;

export const getWorkAnalysis = createApiCall(
  `${environment.apiUrlCubeData}/submitted-claims/${ns}/<<id>>`,
  RequestType.GET,
  setInclude(workIncludes),
  setBody(`{"and":[{"equals":{"phrase":{"relations.otherId":"ICE"}}]}`),
  setLabelResolver(conflictsSection, fromLabelResolvers.getActivityId),
  setResultCleaner(['workAnalysis', fromResultCleaners.getAlternativeTitlesAndXrefsResultCleaner]),
  validateLabels,
);

export const getWorkAnalysisSelected = createApiCall(
  `${environment.apiUrlCubeData}/submitted-claims/${ns}/<<id>>`,
  RequestType.GET,
  setInclude(workIncludes),
  setBody(`{"and":[{"equals":{"phrase":{"relations.otherId":"ICE"}}]}`),
  setLabelResolver(conflictsSection, fromLabelResolvers.getWorksAnalysisId),
  setResultCleaner(['workAnalysis', fromResultCleaners.getAlternativeTitlesAndXrefsResultCleaner]),
  validateLabels,
);

export const getWorkToCompare = createApiCall(
  `${environment.apiUrlCubeData}/submitted-claims/${ns}/<<id>>`,
  RequestType.GET,
  setInclude(workIncludes),
  setBody(`{"and":[{"equals":{"phrase":{"relations.otherId":"ICE"}}]}`),
  setLabelResolver(conflictsSection, fromLabelResolvers.getSelectedOptionToCompare),
  setResultCleaner(['workToCompare', fromResultCleaners.getAlternativeTitlesAndXrefsResultCleaner]),
  validateLabels,
);

export const getWorkToCompareDetails = createApiCall(
  '',
  RequestType.GET,
  setInclude(workIncludes),
  setBody(`{"and":[{"equals":{"phrase":{"relations.otherId":"ICE"}}]}`),
  setResultCleaner(['workToCompare', fromResultCleaners.getAlternativeTitlesAndXrefsResultCleaner]),
  validateLabels,
);

export const searchWorks = createApiCall(
  `${environment.apiUrlCubeData}/works/<<ns>>/search`,
  RequestType.POST,
  validateLabels,
  setQueryParams({ from: 0, size: 100 }),
  setInclude(`attributes{titles}`),
);

export const getWorksToComparesTitles = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.WORKS.name}/${ns}/search`,
  RequestType.POST,
  setInclude(`attributes{titles}`),
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(conflictsSection, fromLabelResolvers.getActivityId, fromLabelResolvers.getComparesTitlesPattern),
  setResultCleaner(['optionsToCompare', fromResultCleaners.getComparesTitlesResultCleaner]),
  validateLabels,
);

export const checkWorksVersion = createApiCall(`${environment.apiUrlCubeData}/${SectionsConfig.WORKS.name}/<<ns>>/<<id>>`, RequestType.GET, isResponseHandler, validateLabels);

export const scopeOfConflicts = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.WORKS.name}/${ns}/<<id>>`,
  RequestType.GET,
  setInclude(`conflicts.conflict.claimConflictPoints`),
  setLabelResolver(conflictsSection, fromLabelResolvers.getWorksAnalysisId),
  setResultCleaner(['scopeOfConflicts', fromResultCleaners.getScopeOfConflictsResultCleaner]),
  validateLabels,
);

export const getActivityConflictWorkIds = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.CONFLICTS.detailSegment}`,
  RequestType.GET,
  setInclude(`attributes.data.conflictWorkIds`),
  validateLabels,
);

export const IgnoreMergeClaims = createApiCall(
  `${environment.apiUrlCubeData}/work-ignored-claims/${ns}/<<id>>`,
  RequestType.PUT,
  setBody(`{ "attributes" : {"claimIds":<<ignoreClaims>>}}`),
  setLabelResolver(conflictsSection, fromLabelResolvers.getIgnoredConflictWorkId, fromLabelResolvers.getIgnoreClaimsPattern),
);

export const getActivityConflicts = createApiCall(
  `${environment.apiUrlCubeData}/conflicts/CUBE/<<id>>`,
  RequestType.GET,
  setInclude(`societies{conflict,conflictId,version},attributes,case{conflictId,data,status,user},works.work.attributes,works,works.work.relations,partyNames{partyName,conflict}`),
  setLabelResolver(conflictsSection, fromLabelResolvers.getConflictId),
);

export const discardAllMatches = createApiCall(`${environment.apiUrlCubeData}/works/CUBE/bulkUpdate/workMatches`, RequestType.POST);
