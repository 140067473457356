export const REPERTOIRES_CONSTANTS = {
  CLAIM_TYPES: {
    VALIDATED: 'VALIDATED',
    SUBMITTED: 'SUBMITTED',
    DERIVED: 'DERIVED',
    ASSUMED: 'ASSUMED',
  },
  REPERTOIRE_TYPE: {
    PUBLISHER: 'PUBLISHER',
    SOCIETY: 'SOCIETY',
    WORK: 'WORK',
  },
  PUBLISHER_ROLE: {
    BOTH: 'BOTH',
    COLLECTOR: 'COLLECTOR',
    CONTRACTOR: 'CONTRACTOR',
  },
};

export enum CLAIM_TYPES {
  VALIDATED = 'VALIDATED',
  SUBMITTED = 'SUBMITTED',
  DERIVED = 'DERIVED',
  ASSUMED = 'ASSUMED',
}

export const RIGHT_TYPES = {
  PR: 'PR',
  MR: 'MR',
};

export const REPERTOIRE_TYPES = {
  PUBLISHER: 'PUBLISHER',
  SOCIETY: 'SOCIETY',
  WORK: 'WORK',
};

export const PUBLISHER_ROLES = {
  BOTH: 'BOTH',
  COLLECTOR: 'COLLECTOR',
  CONTRACTOR: 'CONTRACTOR',
};
