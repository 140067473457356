import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { SearchUtils } from '@ice/utils/search/search.utils';
import { TabsUtils } from '@ice';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as sharedEnglish } from 'assets/i18n/en/app/common/shared';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { AddAgreementWorks, AgreementWorksType } from 'config/constants/agreements.constants';
import { CopyrightWorksDataTable } from 'config/data-table-builders/copyright.works';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { DialogAddById } from 'config/dialog-builders/dialog-add-by-id';
import { SearchCopyrightWorksForm, WorksSearchExpressions } from 'config/search-form-builders/search-copyright.works';
import { SectionsConfig } from 'config/sections-config';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { concat, get } from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { delay, filter, finalize, map, retry, take, takeUntil, tap } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import type { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { SearchUtilsFactory } from 'store/root/utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { withLatestFrom } from 'rxjs/operators';
import { ISelectOption } from '@ice/components/formly-types/select-type/select-type.pipe';

export class TabAgreementsWorks implements SectionTabConfig {
  private selectionDatatable: SelectionDatatableBuilder;
  private unsubscribeAll = new Subject();

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: null,
    private detailService: null,
    private nsService: null,
    private iceFacade: null,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
    private searchService: SearchService,
    private saveItemService: Store<fromForm.NewSectionItemState>,
    tabName,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(sharedEnglish);
    this.worksDatatable = new CopyrightWorksDataTable(this.translate, this.fuseTranslationLoader);
    this.searchWorksForm = new SearchCopyrightWorksForm(this.translate, this.fuseTranslationLoader, this.fieldValidatorService);
    this.agreementWorksType = tabName === 'included-works' ? AgreementWorksType.Inclusion : AgreementWorksType.Exclusion;
    this.tabNameLabel = tabName.replace(/-/g, '_').toUpperCase();
    this.getAgreementId();
    this.getSelectionDatatable();
  }
  dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private worksDatatable: CopyrightWorksDataTable;
  private tabNameLabel: string;
  private searchWorksCount = 0;
  private searchWorksForm: SearchCopyrightWorksForm;
  private searchWorksBody;
  private agreementICEId;
  private agreementType;
  private responseTextSubject = new BehaviorSubject(null);
  private file;
  private searchWorksModel = {};
  private agreementWorksType: AgreementWorksType;
  private opListOptions$ = new Subject<ISelectOption[]>();
  private ALL = 'ALL';
  private filterValues = {
    opValue: this.ALL,
    terminatedWorks: true,
  };
  private showPublisherWorks$ = new BehaviorSubject(false);
  private showPublisherWorksAsObs$ = this.showPublisherWorks$.asObservable();
  private readonly model$ = combineLatest([
    this.store.select(fromRoot.getAgreementIncludedWorks),
    this.store.select(fromRoot.getAgreementExcludedWorks),
    this.store.select(fromRoot.getAgreementPublisherWorks),
    this.showPublisherWorksAsObs$,
  ]).pipe(
    takeUntil(this.unsubscribeAll),
    map(([includedWorks, excludedWorks, publisherWorks, showPublisherWorks$]) => {
      if (this.isInclusionType()) {
        if (showPublisherWorks$) {
          return publisherWorks;
        }
        return includedWorks;
      }
      return excludedWorks;
    }),
  );

  onDestroy = () => {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  };

  getSelectionDatatable() {
    const visibleRows = this.canEditWorks().pipe(
      map(canEdit => ['title', 'keyWithoutPrefix', 'namesToString', 'roleCodesToString', 'IPINumbersToString', 'source', 'status', ...((canEdit && ['delete']) || [])]),
    );
    const schema = [
      ...this.worksDatatable.getDataTable(),
      {
        name: '',
        actionButtonIcon: 'delete',
        prop: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: selected => this.openDialog(selected),
      },
    ];
    const onSelect = (event: any[]) => {
      const defaultTabName = TabsUtils.getDefaultTabName(SectionsConfig.WORKS.name);
      this.store.dispatch(
        new fromRoot.Go({
          path: [`copyright/${SectionsConfig.WORKS.name}/${event[0].key || event[0].id}/${defaultTabName}`],
        }),
      );
    };
    this.selectionDatatable = new SelectionDatatableBuilder(this.store, schema, onSelect, fromApiCalls.getAgreementWorks, visibleRows, 100, this.model$);
  }

  getAgreementId() {
    this.store
      .pipe(
        select(fromRoot.getAgreementKey),
        filter(key => !!key),
        take(1),
      )
      .subscribe(key => {
        this.agreementICEId = key;
      });
    this.store
      .select(fromRoot.getAgreementAttributes)
      .pipe(
        filter(attributes => !!attributes),
        take(1),
      )
      .subscribe(attributes => {
        this.agreementType = attributes.agreementType;
      });
  }

  isInclusionType() {
    return this.agreementWorksType === AgreementWorksType.Inclusion;
  }

  canEditWorks(): Observable<boolean> {
    return this.store.select(fromRoot.getAgreementAttributes).pipe(
      filter(attributes => !!attributes),
      take(1),
      map(
        attributes =>
          this.permissionsService.can('agreements_add_works') &&
          ((attributes.agreementType === 'G' && !this.isInclusionType()) || (attributes.agreementType === 'S' && this.isInclusionType())),
      ),
    );
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithForm',
            isHidden: of(!this.isInclusionType()),
            config: {
              formClass: 'bg-filter-form',
              title: '',
              titleClass: 'ice-display-none',
              model: of({}),
              formBuilder: this.getFilterForm(),
              onSubmit: model => {
                if (model.originalPublisher?.value !== this.ALL) {
                  this.filterValues.opValue = model.originalPublisher?.value;
                  this.filterValues.terminatedWorks = model.terminatedWorks;
                  this.store.dispatch(
                    new fromRoot.StartApiCall({
                      apiCall: {
                        ...fromApiCalls.getAgreementPublisherWorks,
                        body: this.getFilterBody(),
                      },
                    }),
                  );
                  this.showPublisherWorks$.next(true);
                } else {
                  this.store.dispatch(new fromRoot.StartApiCall(fromApiCalls.getAgreementWorks));
                  this.showPublisherWorks$.next(false);
                }
              },
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant(`AGREEMENTS.${this.tabNameLabel}.TAB_TITLE`),
              model: this.model$,
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              sortConfig: { format: this.worksDatatable.formatSort, sortPrefix: 'work.' },
              onMouseSelect: event => {
                const defaultTabName = TabsUtils.getDefaultTabName(SectionsConfig.WORKS.name);
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.WORKS.name}/${event.key || event.id}/${defaultTabName}`],
                  }),
                );
              },
              loadingIndicator: true,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              actionButtons: this.canEditWorks().pipe(
                map(
                  canEdit =>
                    (canEdit && [
                      {
                        icon: 'add',
                        tooltip: this.translate.instant('AGREEMENTS.' + this.tabNameLabel + '.ADD_WORKS'),
                        class: 'mat-white-icon',
                        onClick: () => this.openDialog(),
                      },
                    ]) ||
                    [],
                ),
              ),
              apiCall: () => {
                if (this.showPublisherWorks$.value) {
                  return {
                    ...fromApiCalls.getAgreementPublisherWorks,
                    body: this.getFilterBody(),
                  };
                }
                return fromApiCalls.getAgreementWorks;
              },
            },
          },
        ],
      },
    ];
  }

  openDialog(deleteItem?): void {
    if (!deleteItem) {
      this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getWorksToAdd, apiCallData: { body: this.searchWorksBody } }));
    } else {
      this.responseTextSubject.next({ text: this.translate.instant(`AGREEMENTS.${this.tabNameLabel}.DELETE`) });
    }
    const dialogAddSingleWork = new DialogAddById(this.translate, this.dialog, this.store, this.fieldValidatorService, SectionsConfig.WORKS.name);

    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      panelClass: 'overflow-hidden',
      data: {
        loading: this.store.pipe(select(fromRoot.getCopyrightLoading)),
        layouts: [
          ...(!deleteItem
            ? [
                this.getAddWorkMenuLayout(),
                dialogAddSingleWork.getAddByIdLayout(singleWorkId => this.editAgreementApplication({ id: singleWorkId }, this.isInclusionType())),
                this.getLayoutSearchWorks(),
                this.getAddWorksByCSVLayout(),
              ]
            : []),
          this.getResponseLayout(deleteItem),
        ],
        doPagination: () => {
          if (this.dialogRef.componentInstance.layout === 2) {
            this.store.dispatch(new fromRoot.PaginateApiCall(fromApiCalls.getWorksToAdd));
          }
        },
      },
    });
    this.setAfterDialogClosedHandler();
  }

  getAddWorksByCSVLayout() {
    this.file = null;
    let type = this.agreementWorksType;
    const errorSubject$ = new Subject();
    const customPreview$ = new BehaviorSubject(null);
    const message$ = new BehaviorSubject(this.translate.instant(`AGREEMENTS.MESSAGES_CSV_FILE.${this.agreementType}.${this.agreementWorksType}`));
    return {
      title: of(this.translate.instant('AGREEMENTS.EDIT_WORKS_DIALOG.SELECT_WORKS')),
      actions: [
        { tooltip: this.translate.instant('POPUP.CANCEL'), color: 'warn', icon: 'close', onClick: () => this.dialogRef.close() },
        {
          tooltip: this.translate.instant('POPUP.CONFIRM'),
          nextLayout: 4,
          disabled: customPreview$.pipe(map(preview => !preview)),
          icon: 'done',
          onClick: () => {
            this.dialogRef.componentInstance.setLayout(4);
            this.store.dispatch(
              new fromRoot.StartApiCall({
                apiCall: fromApiCalls.fetchAgreementWorksUploadURL,
                callBack: response => {
                  if (response) {
                    this.setResponseHandler();
                    this.store.dispatch(new fromRoot.UploadUsingPreSignedURL({ url: response.url, file: this.file, sourceId: response.sourceId }));
                  }
                },
              }),
            );
          },
        },
      ],
      layout: [
        {
          group: [
            {
              type: 'buttonsGroup',
              config: {
                emitEvent: (value: any) => {
                  message$.next(this.translate.instant(`AGREEMENTS.MESSAGES_CSV_FILE.${this.agreementType}.${value}`));
                  return (type = value);
                },
                config: {
                  customClass: 'new-claim-dialog-buttons',
                  type: 'matToggle',
                  defaultValue: this.agreementWorksType,
                  buttons: [
                    {
                      value: AgreementWorksType.Inclusion,
                      label: 'AGREEMENTS.EDIT_WORKS_DIALOG.INCLUDE',
                    },
                    {
                      value: AgreementWorksType.Exclusion,
                      label: 'AGREEMENTS.EDIT_WORKS_DIALOG.EXCLUDE',
                    },
                  ],
                  message: message$,
                },
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'input-upload-file',
              config: {
                title: this.translate.instant('XREF_LIST_POPUP.MSG'),
                placeholder: this.translate.instant('XREF_LIST_POPUP.PLACEHOLDER'),
                message: this.translate.instant('XREF_LIST_POPUP.MESSAGE_AGREEMENTS'),
                error: this.translate.instant('XREF_LIST_POPUP.ERROR_AGREEMENTS'),
                csvMaxHeightStyle: true,
                hasError: errorSubject$,
                customPreview: customPreview$,
                startReadingFile: event => {
                  errorSubject$.next(false);
                  customPreview$.next(null);
                },
                fileData: data => {
                  const xrefList = SearchUtilsFactory.getXrefFromData(data, 0);
                  if (xrefList) {
                    this.fieldValidatorService.existWorkKeyList(
                      xrefList,
                      preview => {
                        customPreview$.next(preview);
                        errorSubject$.next(false);
                      },
                      preview => {
                        customPreview$.next(preview);
                        errorSubject$.next(true);
                      },
                    );
                  } else {
                    customPreview$.next(null);
                    errorSubject$.next(true);
                  }
                },
                rawFile: data => {
                  this.file = data;
                },
              },
            },
          ],
        },
      ],
    };
  }

  getAddWorkMenuLayout() {
    return {
      title: of(this.translate.instant('AGREEMENTS.EDIT_WORKS_DIALOG.TAB_TITLE')),
      className: 'dialog-wrapper-auto',
      layout: [
        {
          group: [
            {
              type: 'buttonsGroup',
              config: {
                emitEvent: (action: any) => {
                  switch (action) {
                    case AddAgreementWorks.Single:
                      this.dialogRef.componentInstance.setLayout(1);
                      break;
                    case AddAgreementWorks.Search:
                      this.dialogRef.componentInstance.setLayout(2);
                      break;
                    case AddAgreementWorks.Import:
                      this.dialogRef.componentInstance.setLayout(3);
                  }
                },
                config: {
                  type: 'matBtn',
                  layout: 'column',
                  customClass: 'new-claim-dialog-buttons',
                  buttons: [
                    {
                      color: 'accent',
                      label: `AGREEMENTS.${this.tabNameLabel}.ADD_WORKS`,
                      action: AddAgreementWorks.Single,
                    },
                    {
                      color: 'accent',
                      label: `AGREEMENTS.EDIT_WORKS_DIALOG.SEARCH_WORKS`,
                      action: AddAgreementWorks.Search,
                    },
                    {
                      color: 'accent',
                      label: `AGREEMENTS.EDIT_WORKS_DIALOG.UPLOAD_CSV`,
                      action: AddAgreementWorks.Import,
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    };
  }

  getResponseLayout(deleteItem?) {
    return {
      title: deleteItem ? this.translate.get(`AGREEMENTS.${this.tabNameLabel}.DELETE_WORKS`) : this.translate.get(`AGREEMENTS.${this.tabNameLabel}.ADD_WORKS`),
      className: 'dialog-wrapper-auto',
      layout: [
        {
          group: [
            {
              type: 'response-error',
              config: {
                response: this.responseTextSubject,
                responseButtons: of(
                  deleteItem
                    ? [
                        {
                          text: of(this.translate.instant('POPUP.CLOSE')),
                          action: () => {
                            this.dialogRef.close();
                          },
                        },
                        {
                          text: of(this.translate.instant('POPUP.CONFIRM')),
                          action: () => this.deleteAgreementApplication(deleteItem, !this.isInclusionType()),
                        },
                      ]
                    : [
                        {
                          text: of(this.translate.instant('POPUP.CLOSE')),
                          action: () => {
                            this.dialogRef.close();
                          },
                        },
                      ],
                ),
                errors: of(null),
                errorButtons: of(null),
              },
            },
          ],
        },
      ],
    };
  }

  getLayoutSearchWorks() {
    return {
      title: this.translate.get(`AGREEMENTS.${this.tabNameLabel}.ADD_WORKS`),
      actions: [
        {
          tooltip: this.translate.instant('POPUP.CANCEL'),
          color: 'warn',
          icon: 'close',
          onClick: () => this.dialogRef.close(),
        },
      ],
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                model: of(this.searchWorksModel),
                submitAvailable: true,
                formBuilder: of(this.searchWorksForm.getForm()),
                submitLabel: this.translate.instant('SEARCH_POPUP.BT_SEARCH'),
                submit: $event => {
                  this.searchWorksModel = $event;
                  this.searchWorksBody = JSON.stringify(SearchUtils.generateExpressionCubeData($event, new WorksSearchExpressions(), null));
                  this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getWorksToAdd, apiCallData: { body: this.searchWorksBody } }));
                },
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'dataTable',
              config: {
                data: this.store.pipe(
                  select(fromRoot.getAgreementSearchWorks),
                  tap(works => (this.searchWorksCount = (works || []).length)),
                ),
                isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
                shadowed: true,
                showLoader: true,
                schema: this.worksDatatable.getDataTable(),
                onSelect: (selectedRows: any[]) => {
                  this.editAgreementApplication(selectedRows[0], this.isInclusionType());
                  this.dialogRef.componentInstance.setLayout(4);
                },
              },
            },
          ],
        },
      ],
    };
  }

  setAfterDialogClosedHandler() {
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(_ => {
        this.refreshAgreementWorks();
      });
  }

  setResponseHandler() {
    let nextAction = null;
    this.store
      .pipe(
        select(fromRoot.getCopyrightResponse),
        filter(response => !!response),
        take(1),
        finalize(() => {
          this.store.dispatch(new fromRoot.CleanResponse());
          of(nextAction)
            .pipe(
              filter(a => !!a),
              delay(200),
            )
            .subscribe(a => (this.setResponseHandler(), a()));
        }),
      )
      .subscribe((response: any) => {
        this.afterEditAgreement(response);
        if (response.sourceId) {
          nextAction = () => this.store.dispatch(new fromRoot.UploadUsingPreSignedURL({ url: response.url, file: this.file, sourceId: response.sourceId }));
        } else {
          if (response.uploadToS3Success) {
            this.store.dispatch(
              new fromRoot.ShowSnackBar({
                message: this.translate.instant(`AGREEMENTS.EDIT_WORKS_DIALOG.UPLOAD_SUCCESS`),
                duration: 7000,
              }),
            );
          }
        }
      });
  }

  afterEditAgreement(response) {
    if (response.error) {
      const errorTrace = get(response, 'error.events[0].data.exceptions[0].message');
      const errorArray = errorTrace && errorTrace.split('"');
      const error = get(errorArray, '[5]', this.translate.instant(`AGREEMENTS.EDIT_WORKS_DIALOG.EDIT_ERROR`));
      this.responseTextSubject.next({ text: error });
    } else {
      this.dialogRef.close();
      setTimeout(() => {
        this.refreshAgreementWorks();
      }, 7000);
    }
  }

  refreshAgreementWorks() {
    this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getAgreementWorks }));
    this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getAgreementExcludedWorksCounter }));
  }

  editAgreementApplication(selected, include: boolean) {
    const data = { workId: selected.id, agreementId: this.agreementICEId };
    const body = JSON.stringify({
      attributes: {
        ns: 'ICE',
        ...data,
        include,
        shares: [],
      },
    });
    this.store.dispatch(
      new fromRoot.StartApiCall({
        apiCall: fromApiCalls.editAgreementWorks,
        apiCallData: { body, labels: { workId: selected.id } },
        callBack: response => this.afterEditAgreement(response),
      }),
    );
  }

  deleteAgreementApplication(selected, include: boolean) {
    const body = JSON.stringify({});
    this.store.dispatch(
      new fromRoot.StartApiCall({
        apiCall: fromApiCalls.deleteAgreementWorks,
        apiCallData: { body, labels: { workId: selected.id, applicationId: this.agreementICEId, agreementId: this.agreementICEId } },
        callBack: response => this.afterEditAgreement(response),
      }),
    );
  }

  getFilterForm(): FormlyFieldConfig[] {
    let formData;

    return [
      {
        fieldGroupClassName: 'display-flex ice-justify-space-between ice-mt-12',
        fieldGroup: [
          {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
              {
                className: 'flex-1 ice-min-width-400',
                key: 'originalPublisher',
                type: 'ice-autocomplete',
                templateOptions: {
                  type: 'text',
                  placeholder: this.translate.instant('AGREEMENTS.WORKS_FILTER.ORIGINAL_PUBLISHER'),
                  options: this.opListOptions$,
                  required: true,
                },
                hooks: {
                  onInit: field => {
                    formData = field.form;
                    this.store
                      .pipe(
                        select(fromRoot.getAgreementOriginalPublishersList),
                        withLatestFrom(this.store.pipe(select(fromRoot.getAgreementIncludedWorksCounter))),
                        takeUntil(this.unsubscribeAll),
                      )
                      .subscribe(([opList, worksCount]) => {
                        if (opList.length > 0 && worksCount > 0) {
                          this.opListOptions$.next(
                            concat(
                              {
                                label: `ALL Publishers (${worksCount})`,
                                value: this.ALL,
                              },
                              ...opList.map(item => {
                                return {
                                  label: `${item?.opName} (${item?.worksCount})`,
                                  value: item?.opPartyNameId,
                                };
                              }),
                            ),
                          );
                        }
                      });

                    field.formControl.valueChanges.subscribe(opValue => {
                      if (opValue?.value === this.ALL) {
                        field.form.controls['terminatedWorks']?.setValue(true);
                        field.form.controls['terminatedWorks']?.disable();
                      } else {
                        field.form.controls['terminatedWorks']?.enable();
                      }
                    });
                  },
                  afterViewInit: field => {
                    field.formControl.setValue(this.ALL);
                  },
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'display-flex flex-align-items-center ice-accent',
            fieldGroup: [
              {
                className: 'flex-1 ice-mr-20 input-checkbox-wrapper',
                key: 'terminatedWorks',
                type: 'checkbox',
                defaultValue: true,
                templateOptions: {
                  label: this.translate.instant('AGREEMENTS.WORKS_FILTER.INCLUDE_TERMINATED_WORKS'),
                  required: false,
                  indeterminate: false,
                },
              },
              {
                className: 'ice-accent ice-ml-10',
                type: 'button',
                templateOptions: {
                  text: this.translate.instant('AGREEMENTS.WORKS_FILTER.RESET'),
                  btnType: ' ice-accent',
                  materialType: 'mat-button',
                  onClick: () => {
                    formData.controls.originalPublisher.setValue(this.ALL);
                    this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getAgreementWorks }));
                    this.showPublisherWorks$.next(false);
                  },
                },
              },
              {
                className: 'ice-accent ice-ml-10',
                type: 'button',
                templateOptions: {
                  type: 'submit',
                  text: this.translate.instant('AGREEMENTS.WORKS_FILTER.APPLY_FILTER'),
                  color: 'accent',
                  materialType: 'mat-flat-button',
                  isDisabled: () => {
                    return !formData?.dirty || !formData?.valid;
                  },
                  disablePreventDefault: true,
                },
              },
            ],
          },
        ],
      },
    ];
  }

  getFilterBody() {
    const body: { and: object[] } = {
      and: [
        {
          equals: {
            'attributes.agreementId': '<<id>>',
          },
        },
      ],
    };

    if (this.filterValues.terminatedWorks) {
      body.and.push({
        equals: {
          'custom:claims-via-agreementId-and-workId[include-terminated].rootPublisherPartyNameId': this.filterValues.opValue,
        },
      });
    } else {
      body.and.push({
        equals: {
          'custom:claims-via-agreementId-and-workId.rootPublisherPartyNameId': this.filterValues.opValue,
        },
      });
    }
    return JSON.stringify(body);
  }
}
