import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { concat } from 'lodash';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SearchService } from 'services/search/search.service';
import { StorageService } from 'services/storage/storage.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { StepType } from '../stepper-config';
import { AditionalFieldsStep, AgreementTermsStep, CoreAgreementStep, PartiesStep, SharesStep } from './steps';
import { SummaryAdditionalFields, SummaryAgreementParties, SummaryAgreementTerms, SummaryCoreAgreement, SummaryShares } from './summary';

export class AgreementSteps {
  static getSummary(translate: TranslateService): FormlyFieldConfig[] {
    return concat(
      SummaryCoreAgreement.getFormField(translate),
      SummaryAgreementTerms.getFormField(translate),
      SummaryAgreementParties.getFormField(translate),
      SummaryShares.getFormField(translate),
      SummaryAdditionalFields.getFormField(translate),
    );
  }

  static getSteps(
    translate: TranslateService,
    translationLoader: FuseTranslationLoaderService,
    store: Store<fromRoot.RootState>,
    storeNewItem: Store<fromForm.NewSectionItemState>,
    fieldValidatorService: FieldValidatorService,
    searchService: SearchService,
    dialog: MatDialog,
    permissionsService: PermissionsService,
    storageService: StorageService,
  ): StepType[] {
    return [
      new CoreAgreementStep().getStep(translate, fieldValidatorService),
      new AgreementTermsStep(store, storageService, fieldValidatorService).getStep(translate, fieldValidatorService),
      new PartiesStep(translate, translationLoader, store, storeNewItem, dialog, fieldValidatorService, searchService).getStep(),
      new SharesStep(translate, dialog, fieldValidatorService, storageService).getStep(),
      new AditionalFieldsStep(translate, translationLoader, store, storeNewItem, dialog, fieldValidatorService).getStep(store, translate, fieldValidatorService),
    ];
  }
}
