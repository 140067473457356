import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivityDetail, ConflictUtils, DateTimeUtils, WorkUtils } from '@ice';
import { AnalysisAndComparisons } from '@ice/dynamic-components/analysis-and-comparisons/analysis-and-comparisons';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromApiCalls from 'config/api-calls';
import { ActivityType } from 'config/constants/activity.constants';
import { WORK_CLAIM_SHARE_TYPE, CollectionOrOwnership } from 'config/constants/shares.constants';
import { SharesFilterConfig } from 'config/filter-builders/shares-filter';
import { SectionsConfig } from 'config/sections-config';
import { get } from 'lodash';
import moment from 'moment';
import { combineLatest, merge, of } from 'rxjs';
import { filter, map, skip, withLatestFrom } from 'rxjs/operators';
import { NamespaceService } from 'services/namespace/namespace.service';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';
import { TabActivity } from './tab-activity';

const HEIGHT_TOP_CARDS = 430;

export class TabActivityMergeAnalysis extends TabActivity implements SectionTabConfig {
  public claimType: CollectionOrOwnership = 'collection';
  constructor(
    public translate: TranslateService,
    public fuseTranslationLoader: FuseTranslationLoaderService,
    public store: Store<fromRoot.RootState>,
    public dialog: MatDialog,
    commonApiService: null,
    detailService: null,
    public nsService: NamespaceService,
  ) {
    super(translate, fuseTranslationLoader, store, dialog, commonApiService, detailService, nsService);
    this.getFormlyModel();
  }

  expansionListPairs = [
    ['all-titles-main', 'all-titles-compare'],
    ['extended-main', 'extended-compare'],
    ['instruments-main', 'instruments-compare'],
    ['instrumentations-main', 'instrumentations-compare'],
    ['xrefs-main', 'xrefs-compare'],
  ];

  getConf(): AnalysisAndComparisons {
    const today = DateTimeUtils.formatDate(moment());
    const left$ = this.store.pipe(select(fromRoot.getActivityWorkToCompareSocietyMarkers)).pipe(map(societyMarkers => WorkUtils.getSocietyMarkers(societyMarkers, this.translate)));
    const right$ = this.store.pipe(select(fromRoot.getActivityWorkAnalysisSocietyMarkers)).pipe(map(societyMarkers => WorkUtils.getSocietyMarkers(societyMarkers, this.translate)));
    const extendedTopLeft$ = combineLatest([
      this.store.pipe(
        select(fromRoot.getActivityWorkToCompareExtendedToP),
        filter(extended => !!extended),
      ),
      this.store.pipe(select(fromRoot.getActivityClaimsFilter)),
    ]).pipe(map(([extended, filterOptions]: [any[], any]) => this.filterExtendedToP(extended, filterOptions)));

    const extendedTopRight$ = combineLatest([
      this.store.pipe(
        select(fromRoot.getActivityWorkAnalysisExtendedToP),
        filter(extended => !!extended),
      ),
      this.store.pipe(select(fromRoot.getActivityClaimsFilter)),
    ]).pipe(map(([extended, filterOptions]: [any[], any]) => this.filterExtendedToP(extended, filterOptions)));

    const instrumentsLeft = this.store.pipe(
      select(fromRoot.getActivityWorkToCompareInstruments),
      map(instruments => WorkUtils.getWorkInstruments(instruments, this.translate)),
    );

    const instrumentsRight = this.store.pipe(
      select(fromRoot.getActivityWorkAnalysisInstruments),
      map(instruments => WorkUtils.getWorkInstruments(instruments, this.translate)),
    );

    const toggleHighlightResultsLabel = (isActive = true) => {
      if (isActive) {
        this.toggleHighlightResultsLabelSubject.next(this.translate.instant('ACTIVITY.ANALYSIS_MERGE.FRONTEND_HIGHLIGHT'));
      } else {
        this.toggleHighlightResultsLabelSubject.next(this.translate.instant('ACTIVITY.ANALYSIS_MERGE.BACKEND_HIGHLIGHT'));
      }
      this.drawHighLights(isActive);
    };

    return {
      toggleHighlightResults: {
        toggle: true,
        label: this.toggleHighlightResultsLabelSubject,
        tooltip: this.toggleHighlightResultsLabelSubject,
        checked: true,
        switchChange: event => {
          this.toggleHighlightActive = event.checked;
          toggleHighlightResultsLabel(event.checked);
        },
      },
      comparisonFilter: {
        isVisible: of(true),
        formBuilder: of(
          SharesFilterConfig.getActivityForm(this.store, this.translate, claimsFilter =>
            this.store.dispatch(new fromRoot.SetClaimsFilterToCompare({ ...claimsFilter, usageDate: claimsFilter.usageDate || today })),
          ),
        ),
        model: {
          removeE: false,
          removeSE: false,
        },
        formClass: 'bg-filter-form ice-p-16',
        submitShortcutEnable: true,
      },
      cardConflictScopeDataTable: this.getCardConflictScopeDataTable(),
      cardWithFormWorkToCompare: {
        className: 'compare-card analysis-left',
        title: this.store.pipe(
          select(fromRoot.getActivityWorkToCompare),
          map(model => `${this.translate.instant('ACTIVITY.ANALYSIS.REVISION.COMPARE')} : ${this.getTitle(model)}`),
        ),
        titleTooltip: this.store.pipe(
          select(fromRoot.getActivityWorkToCompare),
          map(model => this.getTitle(model)),
        ),
        iswc: this.store.pipe(
          select(fromRoot.getActivityWorkToCompare),
          map(model => this.getIswcAndStatus(model)),
        ),
        wsm: this.store.pipe(
          select(fromRoot.getActivityWorkToCompare),
          map(model => this.getSocietyMarkers(model)),
        ),
        expanded: this.expanded8,
        model: this.store.pipe(
          select(fromRoot.getActivityWorkToCompare),
          map(model => this.cleanMetaDataModel(model)),
        ),
        resetAvailable: false,
        submitAvailable: false,
        button1Available: false,
        formBuilder: this.formlyModel,
        buttonTitle: 'launch',
        buttonTitleTooltip: this.translate.instant('ACTIVITY.ANALYSIS.OPEN_WORK'),
        showCounterclaimIndicator: this.shouldDisplayCounterclaimIndicator(fromRoot.getActivityWorkToCompare),
        openNewTab: (workId: string) => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}`] })),
        extendedWorkSelector: true,
        extendedWorkSelectorTitle: this.getExtendedWorkSelectorTitle(ActivityType.MERGE),
        extendedWorkSelectorPlaceHolder: this.getExtendedWorkSelectorPlaceHolder(ActivityType.MERGE),
        optionSelected: this.store.select(fromRoot.getActivityOptionsToCompare).pipe(
          withLatestFrom(this.store.pipe(select(fromRoot.getActivityWorkToCompare))),
          map(([options, workToCompare]) => {
            return options.find(option => option.viewValue === workToCompare.id)?.value;
          }),
        ),
        options: this.options$,
        onSelect: event => {
          if (event.value) {
            this.selectedClaimsAnalysisLeft = [];
            sessionStorage.setItem(`selectedClaimsAnalysisLeft_${WORK_CLAIM_SHARE_TYPE.COLLECTION}`, JSON.stringify(this.selectedClaimsAnalysisLeft));
            sessionStorage.setItem(`selectedClaimsAnalysisLeft_${WORK_CLAIM_SHARE_TYPE.OWNERSHIP}`, JSON.stringify(this.selectedClaimsAnalysisLeft));
            this.selectionClaimsAnalysisLeft$.next(this.selectedClaimsAnalysisLeft);
            this.store.dispatch(new fromRoot.SetSelectedOptionToCompare({ key: event.value }));
            this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getWorkToCompare }));
          }
        },
        open: () => this.expanseRow(8),
        close: () => this.collapseRow(8),
        listIdentifier: 'compare-left',
      },
      cardWithFormMasteredWork: {
        className: 'compare-card analysis-right',
        openNewTab: (workId: string) => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}`] })),
        buttonTitle: 'launch',
        buttonTitleTooltip: this.translate.instant('ACTIVITY.ANALYSIS.OPEN_WORK'),
        title: this.store.pipe(
          select(fromRoot.getActivityWorkAnalysis),
          map(model => `${this.translate.instant('ACTIVITY.ANALYSIS.REVISION.COMPARE')} : ${get(model, 'title', '')}`),
        ),
        showCounterclaimIndicator: this.shouldDisplayCounterclaimIndicator(fromRoot.getActivityWorkAnalysis),
        titleTooltip: this.store.pipe(
          select(fromRoot.getActivityWorkAnalysis),
          map(model => this.getTitle(model)),
        ),
        referenceId: this.store.pipe(
          select(fromRoot.getActivityWorkAnalysis),
          map((activity: ActivityDetail) => activity?.id),
        ),
        model: this.store.pipe(
          select(fromRoot.getActivityWorkAnalysis),
          map(model => this.cleanMetaDataModel(model)),
        ),
        resetAvailable: false,
        submitAvailable: false,
        formBuilder: this.formlyModel,
        iswc: this.store.pipe(
          select(fromRoot.getActivityWorkAnalysis),
          map(model => this.getIswcAndStatus(model)),
        ),
        wsm: this.store.pipe(
          select(fromRoot.getActivityWorkAnalysis),
          map(model => this.getSocietyMarkers(model)),
        ),
        expanded: this.expanded8,
        listIdentifier: 'compare-right',
        open: () => this.expanseRow(8),
        close: () => this.collapseRow(8),
        extendedWorkSelector: true,
        extendedWorkSelectorTitle: this.getExtendedWorkSelectorTitle(ActivityType.MERGE),
        extendedWorkSelectorPlaceHolder: this.getExtendedWorkSelectorPlaceHolder(ActivityType.MERGE),
        options: this.options$,
        optionSelected: this.defaultMergeAnalysisWorkSelected$,
        onSelect: event => {
          if (event.value) {
            this.selectedClaimsAnalysisRight = [];
            sessionStorage.setItem(`selectedClaimsAnalysisRight_${WORK_CLAIM_SHARE_TYPE.COLLECTION}`, JSON.stringify(this.selectedClaimsAnalysisRight));
            sessionStorage.setItem(`selectedClaimsAnalysisRight_${WORK_CLAIM_SHARE_TYPE.OWNERSHIP}`, JSON.stringify(this.selectedClaimsAnalysisRight));
            this.selectionClaimsAnalysisRight$.next(this.selectedClaimsAnalysisRight);
            this.store.dispatch(new fromRoot.SetWorkAnalysis({ id: event.value }));
            this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getWorkAnalysisSelected }));
          }
        },
      },
      ...this.getClaimsHeaderToggle(),
      ...this.claimsComparatorConfig(),
      expansionListTitlesLeft: {
        className: 'analysis-left',
        title: this.translate.instant('ACTIVITY.ANALYSIS.REVISION.LIST_TITLES'),
        model: {
          model: this.store.pipe(
            select(fromRoot.getActivityWorkToCompareTitles),
            map(titles => this.completeTitles(titles)),
          ),
          columnMode: 'flex',
          schema: this.getTilesSchemaClaims(),
        },
        listIdentifier: 'all-titles-compare',
        open: () => this.expanseRow(1),
        close: () => this.collapseRow(1),
        expanded: this.expanded1,
        height: this.getTitlesHeight(),
      },
      expansionListTitlesRight: {
        className: 'analysis-right',
        title: this.translate.instant('ACTIVITY.ANALYSIS.REVISION.LIST_TITLES'),
        model: {
          model: this.store.pipe(
            select(fromRoot.getActivityWorkAnalysisTitles),
            map(titles => this.completeTitles(titles)),
          ),
          columnMode: 'flex',
          schema: this.getTilesSchemaClaims(),
        },
        listIdentifier: 'all-titles-main',
        open: () => this.expanseRow(1),
        close: () => this.collapseRow(1),
        expanded: this.expanded1,
        height: this.getTitlesHeight(),
      },
      expansionListDataTableInstrumentsLeft: {
        className: 'analysis-left',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.INSTRUMENTS'),
        listIdentifier: 'instruments-compare',
        buttonTitle: 'launch',
        iconConflict: combineLatest([this.store.select(fromRoot.getActivityCompareConflicts), this.store.select(fromRoot.getActivityAnalysisConflicts)]).pipe(
          map(([compare, analysis]) => (get(compare, 'instruments') || get(analysis, 'instruments') ? 'warning' : null)),
        ),
        tooltipIconConflict: of(this.translate.instant('ACTIVITY.ANALYSIS.REVISION.POTENTIAL_CONFLICT')),
        openNewTab: (workId: string) => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}/instruments`] })),
        extraData: {
          workId: this.store.pipe(select(fromRoot.getActivitySelectedOptionToCompare)),
        },
        model: {
          model: this.compareListsAndHighlightDifference(instrumentsLeft, instrumentsRight, 'instrumentCode'),
          sorts: [{ prop: 'instrumentCode', dir: 'asc' }],
          columnMode: 'flex',
          schema: this.getSchemaInstruments(this.highlightNoResultsMessage),
        },
        open: () => {
          this.expanseRow(5);
          this.drawHighLights(this.toggleHighlightActive);
        },
        close: () => this.collapseRow(5),
        expanded: merge(this.expanded5.pipe(skip(1)), this.expandedObservable$('instruments')),
        height: this.getComponentsHeight(this.instrumentsCases$),
      },
      expansionListDataTableInstrumentsRight: {
        className: 'analysis-right',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.INSTRUMENTS'),
        listIdentifier: 'instruments-main',
        buttonTitle: 'launch',
        iconConflict: combineLatest([this.store.select(fromRoot.getActivityCompareConflicts), this.store.select(fromRoot.getActivityAnalysisConflicts)]).pipe(
          map(([compare, analysis]) => (get(compare, 'instruments') || get(analysis, 'instruments') ? 'warning' : null)),
        ),
        tooltipIconConflict: of(this.translate.instant('ACTIVITY.ANALYSIS.REVISION.POTENTIAL_CONFLICT')),
        openNewTab: (workId: string) => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}/instruments`] })),
        extraData: {
          workId: this.store.pipe(
            select(fromRoot.getConflictsActivity),
            map((activity: ActivityDetail) => ConflictUtils.getWorkTitle(activity)),
          ),
        },
        model: {
          model: instrumentsRight,
          sorts: [{ prop: 'instrumentCode', dir: 'asc' }],
          columnMode: 'flex',
          schema: this.getSchemaInstruments(),
        },
        open: () => {
          this.expanseRow(5);
          this.drawHighLights(this.toggleHighlightActive);
        },
        close: () => this.collapseRow(5),
        expanded: merge(this.expanded5.pipe(skip(1)), this.expandedObservable$('instruments')),
        height: this.getComponentsHeight(this.instrumentsCases$),
      },
      expansionListDataTableInstrumentationLeft: {
        className: 'analysis-left',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.INSTRUMENTATION'),
        listIdentifier: 'instrumentations-compare',
        buttonTitle: 'launch',
        iconConflict: combineLatest([this.store.select(fromRoot.getActivityCompareConflicts), this.store.select(fromRoot.getActivityAnalysisConflicts)]).pipe(
          map(([compare, analysis]) => (get(compare, 'instrumentations') || get(analysis, 'instrumentations') ? 'warning' : null)),
        ),
        tooltipIconConflict: of(this.translate.instant('ACTIVITY.ANALYSIS.REVISION.POTENTIAL_CONFLICT')),
        openNewTab: (workId: string) => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}/instruments`] })),
        extraData: {
          workId: this.store.pipe(select(fromRoot.getActivitySelectedOptionToCompare)),
        },
        model: {
          model: this.store.pipe(
            select(fromRoot.getActivityWorkToCompareInstrumentations),
            map(instrumentations => WorkUtils.getWorkInstrumentations(instrumentations, this.translate)),
          ),
          sorts: [{ prop: 'instrumentCode', dir: 'asc' }],
          columnMode: 'flex',
          schema: this.getSchemaInstrumentations(this.highlightNoResultsMessage),
        },
        selectable: true,
        onSelect: id => {},
        onMouseSelect: event => {},
        open: () => {
          this.expanseRow(6);
          this.drawHighLights(this.toggleHighlightActive);
        },
        close: () => this.collapseRow(6),
        expanded: merge(this.expanded6.pipe(skip(1)), this.expandedObservable$('instrumentations')),
        height: this.getComponentsHeight(this.instrumentationsCases$),
      },
      expansionListDataTableInstrumentationRight: {
        className: 'analysis-right',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.INSTRUMENTATION'),
        listIdentifier: 'instrumentations-main',
        buttonTitle: 'launch',
        iconConflict: combineLatest([this.store.select(fromRoot.getActivityCompareConflicts), this.store.select(fromRoot.getActivityAnalysisConflicts)]).pipe(
          map(([compare, analysis]) => (get(compare, 'instrumentations') || get(analysis, 'instrumentations') ? 'warning' : null)),
        ),
        tooltipIconConflict: of(this.translate.instant('ACTIVITY.ANALYSIS.REVISION.POTENTIAL_CONFLICT')),
        openNewTab: (workId: string) => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}/instruments`] })),
        extraData: {
          workId: this.store.pipe(
            select(fromRoot.getConflictsActivity),
            map((activity: ActivityDetail) => ConflictUtils.getWorkTitle(activity)),
          ),
        },
        model: {
          model: this.store.pipe(
            select(fromRoot.getActivityWorkAnalysisInstrumentations),
            map(instrumentations => WorkUtils.getWorkInstrumentations(instrumentations, this.translate)),
          ),
          sorts: [{ prop: 'instrumentCode', dir: 'asc' }],
          columnMode: 'flex',
          schema: this.getSchemaInstrumentations(),
        },
        selectable: true,
        onSelect: id => {},
        onMouseSelect: event => {},
        open: () => {
          this.expanseRow(6);
          this.drawHighLights(this.toggleHighlightActive);
        },
        close: () => this.collapseRow(6),
        expanded: merge(this.expanded6.pipe(skip(1)), this.expandedObservable$('instrumentations')),
        height: this.getComponentsHeight(this.instrumentationsCases$),
      },
      workSocietyMarkerLeft: {
        type: 'select',
        title: this.translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.TITLE'),
        selectTitle: this.translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.SUBTITLE'),
        expanded: this.expanded9,
        model: this.getActivityWorkToCompareSocietyMarkersWithHighlight(left$, right$),
        minHeight: HEIGHT_TOP_CARDS,
        maxHeight: HEIGHT_TOP_CARDS,
        filterItemsOn: 10,
        onSelect: event => {},
        showCounter: true,
        onOpen: () => {
          this.expanded9Subject.next(true);
          this.drawHighLights(this.toggleHighlightActive);
        },
        onClosed: () => this.expanded9Subject.next(false),
      },
      workSocietyMarkerRight: {
        class: 'analysis-right',
        headerClass: 'analysis-right',
        type: 'select',
        title: this.translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.TITLE'),
        selectTitle: this.translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.SUBTITLE'),
        expanded: this.expanded9,
        model: right$,
        minHeight: HEIGHT_TOP_CARDS,
        maxHeight: HEIGHT_TOP_CARDS,
        filterItemsOn: 10,
        onSelect: event => {},
        showCounter: true,
        onOpen: () => {
          this.expanded9Subject.next(true);
          this.drawHighLights(this.toggleHighlightActive);
        },
        onClosed: () => this.expanded9Subject.next(false),
      },
      expansionListDataTableExtendedToPLeft: {
        className: 'analysis-left',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.EXTENDED_TOP'),
        listIdentifier: 'extended-compare',
        buttonTitle: 'launch',
        iconConflict: combineLatest([this.store.select(fromRoot.getActivityCompareConflicts), this.store.select(fromRoot.getActivityAnalysisConflicts)]).pipe(
          map(([compare, analysis]) => (get(compare, 'overrides') || get(analysis, 'overrides') ? 'warning' : null)),
        ),
        tooltipIconConflict: of(this.translate.instant('ACTIVITY.ANALYSIS.REVISION.POTENTIAL_CONFLICT')),
        openNewTab: (workId: string) => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}/extended-top`] })),
        extraData: {
          workId: this.store.pipe(select(fromRoot.getActivitySelectedOptionToCompare)),
        },
        model: {
          model: this.compareListsAndHighlightDifference(extendedTopLeft$, extendedTopRight$, 'protectionDate'),
          sorts: [{ prop: 'societyIds', dir: 'asc' }],
          columnMode: 'flex',
          schema: this.getSchemaExtendedToP(this.highlightNoResultsMessage),
        },
        selectable: true,
        onSelect: id => {},
        onMouseSelect: event => {},
        open: () => this.expanseRow(7),
        close: () => this.collapseRow(7),
        expanded: merge(this.expanded7.pipe(skip(1)), this.expandedObservable$('overrides')),
        height: this.getComponentsHeight(this.extendedTopCases$),
      },
      expansionListDataTableExtendedToPRight: {
        className: 'analysis-right',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.EXTENDED_TOP'),
        listIdentifier: 'extended-main',
        buttonTitle: 'launch',
        iconConflict: combineLatest([this.store.select(fromRoot.getActivityCompareConflicts), this.store.select(fromRoot.getActivityAnalysisConflicts)]).pipe(
          map(([compare, analysis]) => (get(compare, 'overrides') || get(analysis, 'overrides') ? 'warning' : null)),
        ),
        tooltipIconConflict: of(this.translate.instant('ACTIVITY.ANALYSIS.REVISION.POTENTIAL_CONFLICT')),
        openNewTab: (workId: string) => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}/extended-top`] })),
        extraData: {
          workId: this.store.pipe(
            select(fromRoot.getConflictsActivity),
            map((activity: ActivityDetail) => ConflictUtils.getWorkTitle(activity)),
          ),
        },
        model: {
          model: extendedTopRight$,
          sorts: [{ prop: 'societyIds', dir: 'asc' }],
          columnMode: 'flex',
          schema: this.getSchemaExtendedToP(),
        },
        selectable: true,
        onSelect: id => {},
        onMouseSelect: event => {},
        open: () => this.expanseRow(7),
        close: () => this.collapseRow(7),
        expanded: merge(this.expanded7.pipe(skip(1)), this.expandedObservable$('overrides')),
        height: this.getComponentsHeight(this.extendedTopCases$),
      },
      expansionListXrefsLeft: {
        className: 'analysis-left',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.XREFS'),
        model: this.store.pipe(select(fromRoot.getActivityWorkToCompareRelations)),
        listIdentifier: 'xrefs-compare',
        open: () => {
          this.expanseRow(0);
          this.drawHighLights(this.toggleHighlightActive);
        },
        close: () => this.collapseRow(0),
        expanded: this.expanded0,
        height: this.getXrefsHeight(this.activityCases$),
      },
      expansionListXrefsRight: {
        className: 'analysis-right',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.XREFS'),
        model: this.store.pipe(select(fromRoot.getActivityWorkAnalysisRelations)),
        listIdentifier: 'xrefs-main',
        open: () => {
          this.expanseRow(0);
          this.drawHighLights(this.toggleHighlightActive);
        },
        close: () => this.collapseRow(0),
        expanded: this.expanded0,
        height: this.getXrefsHeight(this.activityCases$),
      },
    };
  }

  private filterExtendedToP(extended: any, filterOptions: any): any[] {
    let itemsFiltered = [];
    if (filterOptions?.mrSociety && filterOptions?.mrSociety?.value && filterOptions?.mrSociety?.value !== 'ALL') {
      extended.forEach(item => {
        item.societyIds.forEach(data => {
          if (data.split(':')[1].padStart(3, 0) === filterOptions.mrSociety.value) {
            itemsFiltered.push(item);
          }
        });
      });
    } else if (filterOptions?.prRightType && filterOptions?.prSociety?.value && filterOptions?.prSociety?.value !== 'ALL') {
      extended.forEach(item => {
        item.societyIds.forEach(data => {
          if (data.split(':')[1].padStart(3, 0) === filterOptions.prSociety.value) {
            itemsFiltered.push(item);
          }
        });
      });
    } else if (filterOptions?.territory && filterOptions?.territory?.value && filterOptions?.territory?.value !== 'ALL') {
      extended.forEach(item => {
        const territories: any[] = item && item.territories && item.territories.inExTisns;
        territories.forEach(data => {
          if (data.replace('+', '') === filterOptions.territory.value) {
            itemsFiltered.push(item);
          }
        });
      });
    } else {
      itemsFiltered = extended;
    }
    return itemsFiltered.sort(this.sortSocietyIdsAndDateAscendant);
  }

  private getFormlyModel() {
    this.formlyModel = this.activityType.pipe(
      map(_ => {
        return [
          {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [...this.getWorkDetailPropertiesMerge('main', 'flex-4'), ...this.getWorkDetailPropertiesMerge('genre', 'flex-4')],
          },
          {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
              ...this.getWorkDetailPropertiesMerge('other', 'flex-4'),
              ...this.getWorkDetailPropertiesMerge('purpose', 'flex-4'),
              ...this.getWorkDetailPropertiesMerge('creationDate', 'flex-4'),
            ],
          },
        ];
      }),
    );
  }
}
