import { IceOption, PostTermCollectionValue } from '@ice';

export enum TrimClaimsSelection {
  ALL = 'all',
  SELECTED = 'selected',
}
export enum TrimScopeOptions {
  REPLACE = 'replace',
  TRIM = 'trim',
  ADD = 'add',
}
export interface TrimClaimsConfig {
  claimsSelection: TrimClaimsSelection;
  scope: TrimScopeOptions;
  excludeCreators: boolean;
  chains?: string[];
  codes?: number[];
  startDate?: string;
  endDate?: string;
  priorRoyaltiesDate?: string;
  postTermCollectionDate?: string;
  lastTerritoryValue?: IceOption;
  postTermCollection?: PostTermCollectionValue;
  rightsCodes?: {
    Performing?: string[];
    Mechanical?: string[];
  };
  territory?: IceOption;
  pr?: string;
  mr?: string;
}
