import { FormControl } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils, IpUtils } from '@ice';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ICE } from 'config/constants/global.constants';
import { REPORT_REF_TYPE_AGREEMENT, REPORT_REF_TYPE_IPI, REPORT_REF_TYPE_WORK, REPORTS_IP_SHARES_REF_NUMBER_TYPES, REPORTS_REF_TYPES } from 'config/constants/reports.constants';
import { get, isEmpty } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import { InputFileType } from '@ice/components/input-upload-file/input-upload-file.model';
import { StepType } from '../../stepper-config';
import { ReportsSegment } from '../../../../models/reports/jobs.model';

const MAX_VALIDATION_KEYS = 5;
const refNumberTypeSelector = (state: any): ReportsSegment => state.newSectionItem?.newItem.fields.refNumberType;
export class ReportWorkDetailsGenerateFicheKeyListStep {
  private fieldRefType: any;
  private fieldRefNumber: any;
  private fieldPartyObj: any;
  private fieldRefNumberTypeWork: any;
  private fieldWorkeys: any;
  private workKeys = [];
  private showWarning: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private warningMessage: BehaviorSubject<String> = new BehaviorSubject('');
  private showInformation: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private information: BehaviorSubject<String> = new BehaviorSubject('');
  private prefix: string;

  unsubscribeAll = new Subject();
  private refNumberType$ = this.store.pipe(select(refNumberTypeSelector));

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private fieldValidatorService: FieldValidatorService,
  ) {
    this.store
      .pipe(select(fromForm.getNewSectionInitialFields))
      .pipe(take(1))
      .subscribe(initialFields => {
        this.initializeWorkKeys(initialFields);
        this.initializePartyNames(initialFields);
      });
  }

  initializeWorkKeys(initialFields) {
    const initWorkeys = initialFields['workeys'];
    if (initWorkeys && initWorkeys.length > 1) {
      this.workKeys = initWorkeys;
      this.showInformation.next(true);
      const slicedDisplayKeys = this.sliceWorkKeysForDisplay(this.workKeys);
      this.information.next(slicedDisplayKeys);
    }
  }

  initializePartyNames(initialFields) {
    const initPartyId = initialFields.partyId;
    if (initPartyId && initPartyId.length) {
      this.fieldValidatorService.getParyNameIPIByPartyIPI(
        initPartyId,
        res => {
          const { partyNames } = res;
          if (!isEmpty(partyNames)) {
            const partyNamePA = partyNames.find(pname => pname.type === 'PA');
            const IPIPartyNamePA = IpUtils.selectICENumber(get(partyNamePA, 'partyName.relations', []));
            this.fieldRefNumber.formControl.setValue((IPIPartyNamePA?.includes(':') && IPIPartyNamePA.split(':')[1]) || '');
          }
        },
        err => {},
      );
    }
  }

  getStep(translate: TranslateService): StepType {
    return {
      label: translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.TITLE_STEP_1'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'refNumberType',
              type: 'select',
              className: 'flex-1',
              defaultValue: REPORT_REF_TYPE_WORK,
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                label: translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.REF_NUM_TYPE'),
                options: REPORTS_REF_TYPES,
                change: field => {
                  this.fieldRefNumber.formControl.setValue('');
                  this.resetDocument();
                },
              },
              hooks: {
                onInit: field => (this.fieldRefType = field),
              },
            },
            {
              key: 'refNumber',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              className: 'flex-1',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: false,
                label: translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.REF_NUM'),
                change: field => this.resetDocument(),
              },
              hooks: {
                onInit: field => {
                  this.fieldRefNumber = field;
                  if (this.workKeys && this.workKeys.length > 1) {
                    this.fieldRefNumber.formControl.disable();
                  }
                },
              },
              asyncValidators: this.getRefNumberAsyncValidator(),
            },
            {
              key: 'ipBaseWorksList',
              type: 'select',
              className: 'flex-1',
              hooks: {
                onInit: field => {
                  this.refNumberType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(refNumberType => {
                    field.hide = refNumberType !== REPORT_REF_TYPE_IPI;
                  });
                },
              },
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: true,
                label: translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.SELECT_ALL_WORKS'),
                options: [
                  { value: true, label: this.translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.YES_MIN') },
                  { value: false, label: this.translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.NO_MIN') },
                ],
              },
            },
            { className: 'flex-1', template: '<div></div>', hideExpression: model => model.refNumberType === REPORT_REF_TYPE_IPI },
            { className: 'flex-1', template: '<div></div>' },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              hideExpression: model => model.refNumberType !== REPORT_REF_TYPE_WORK,
              template: `<div class='ice-mb-5'><b>${translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.OR')}</b></div>`,
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'refNumberTypeWork',
              type: 'select',
              className: 'flex-1',
              defaultValue: ICE,
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                label: translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.WORK_REF_TYPE'),
                options: REPORTS_IP_SHARES_REF_NUMBER_TYPES,
                change: (field, event) => {
                  this.prefix = event.value;
                  this.resetDocument();
                },
              },
              hideExpression: model => model.refNumberType !== REPORT_REF_TYPE_WORK,
              hooks: {
                onInit: field => (this.fieldRefNumberTypeWork = field),
              },
            },
            {
              className: 'flex-2',
              key: 'files',
              hooks: {
                onInit: field => {
                  this.refNumberType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(refNumberType => {
                    field.hide = refNumberType !== REPORT_REF_TYPE_WORK;
                  });
                },
              },
              type: 'upload-file',
              templateOptions: {
                showWarning: this.showWarning,
                warningMessage: this.warningMessage,
                showInformation: this.showInformation,
                information: this.information,
                required: false,
                fileType: InputFileType.CSV,
                customAddFile: file => {
                  if (CopyrightUtils.isValidCSVFile(file)) {
                    const reader = new FileReader();
                    reader.onload = event => {
                      const csvData = reader.result as string;
                      if (csvData?.length) {
                        const parsedCsvData: string[][] = CopyrightUtils.parseCsvData(csvData);
                        this.workKeys = parsedCsvData.map(cols => cols[0]);
                        this.workKeys = this.workKeys.filter(row => row); // Filter out empty workkeys
                      }
                      const prefix = get(this.fieldRefNumberTypeWork, 'formControl.value', 'ICE');
                      this.validateWorkKeys(prefix);
                    };
                    reader.readAsText(file);
                  } else {
                    this.setDocument(null, false, '', true, translate.instant('REPORTS.IPS_SHARES.INCORRECT_FILE'), true);
                  }
                },
                onClose: () => this.resetDocument(),
              },
            },
            { className: 'flex-1', template: '<br>' },
            { className: 'flex-1', template: '<br>' },
            {
              key: 'partyId',
              type: 'input',
              className: 'ice-display-none',
              templateOptions: {
                required: false,
              },
              hooks: {
                onInit: field => (this.fieldPartyObj = field),
              },
            },
            {
              key: 'workeys',
              type: 'input',
              className: 'ice-display-none',
              templateOptions: {
                required: false,
              },
              hooks: {
                onInit: field => (this.fieldWorkeys = field),
              },
            },
          ],
        },
      ],
    };
  }

  getRefNumberAsyncValidator() {
    return {
      refValidator: {
        expression: async (control: FormControl) => {
          const length = get(control, 'value.length', 0);
          if (length) {
            if (this.fieldRefType && this.fieldRefType.formControl && this.fieldRefType.formControl.value) {
              switch (this.fieldRefType.formControl.value) {
                case REPORT_REF_TYPE_WORK:
                  const prefixRef = this.prefix ? this.prefix : get(this.fieldRefNumberTypeWork, 'formControl.value', 'ICE');
                  const formattedWorkkey = [`${prefixRef}:${control.value}`];
                  return this.fieldValidatorService.existWorkKeyList(
                    formattedWorkkey,
                    () => {},
                    () => {},
                  );
                case REPORT_REF_TYPE_AGREEMENT:
                  return this.fieldValidatorService.existAgreementRefKey(
                    control,
                    () => {},
                    () => {},
                  );
                case REPORT_REF_TYPE_IPI:
                  if (control && control.value && control.value.length) {
                    return this.fieldValidatorService.existIPINameNumber(
                      (control.value || '').trim(),
                      partyName => {
                        const partyId = [];
                        const parties = get(partyName, 'items[0].parties', []);
                        parties.map(party => {
                          partyId.push(IpUtils.selectICENumber(get(party, 'party.relations', [])));
                        });
                        this.fieldPartyObj.formControl.setValue(partyId);
                      },
                      () => {},
                    );
                  }
              }
            }
            return false;
          }
          return false;
        },
        message: () => {
          const type = this.fieldRefType.formControl.value;
          switch (type) {
            case REPORT_REF_TYPE_WORK:
              return this.translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.ERROR_WORK_KEY');
            case REPORT_REF_TYPE_AGREEMENT:
              return this.translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.ERROR_AGREEMENT_NUMBER');
            case REPORT_REF_TYPE_IPI:
              return this.translate.instant('REPORTS.WORK_DETAILS_GENERATE_FICHE.ERROR_IPI_NAME_NUMBER');
          }
        },
      },
    };
  }

  validateWorkKeys(prefix) {
    if (this.workKeys) {
      const length = Math.min(this.workKeys.length, MAX_VALIDATION_KEYS);
      const formatedWorkKeys = this.workKeys.slice(0, length).map(key => {
        return key.includes(':') ? key : `${prefix}:${key}`;
      });
      const information = this.sliceWorkKeysForDisplay(this.workKeys);
      this.fieldValidatorService.existWorkKeyList(
        formatedWorkKeys,
        preview => this.setDocument(this.workKeys, true, information, false, '', false),
        () => this.setDocument(null, true, information, true, this.translate.instant('REPORTS.IPS_SHARES.ERROR_NO_WORKEYS'), true),
      );
    } else {
      this.setDocument(null, false, '', true, this.translate.instant('REPORTS.IPS_SHARES.ERROR_NO_WORKEYS'), true);
    }
  }

  resetDocument() {
    this.setDocument(null, false, '', false, '', true);
  }

  sliceWorkKeysForDisplay(workKeys) {
    return workKeys.length > 5 ? workKeys.slice(0, 5).join(', ') : workKeys.join(', ');
  }

  setDocument(formattedWorkKeys, showInformation, information, showWarning, warningMessage, enableRef) {
    if (enableRef) {
      this.fieldRefNumber.formControl.enable();
    } else {
      this.fieldRefNumber.formControl.disable();
    }
    this.fieldWorkeys.formControl.setValue(formattedWorkKeys);
    this.showInformation.next(showInformation);
    this.information.next(information);
    this.showWarning.next(showWarning);
    this.warningMessage.next(warningMessage);
  }
}
