import { createSelector } from '@ngrx/store';
import * as fromFeature from '../../reducers';
import * as fromUi from '../../reducers/ui/ui.reducer';

export const getToolBar = createSelector(fromFeature.getUiFeatureState, fromUi.getSearchBarToggleState);

export const getAdvancedSearchCollapsed = createSelector(getToolBar, state => state && state.advancedCollapsed);

export const getExpertSearchCollapsed = createSelector(getToolBar, state => state && state.expertCollapsed);

export const getSnackBar = createSelector(fromFeature.getUiFeatureState, fromUi.getSnackBar);

export const getProgressBar = createSelector(fromFeature.getUiFeatureState, fromUi.getProgressBarState);

export const getStatusComponentDialog = createSelector(fromFeature.getUiFeatureState, fromUi.getDialogStatus);

export const getRouterProgressBar = createSelector(getProgressBar, state => state && state.routerLoading);

export const getDataProgressBar = createSelector(getProgressBar, state => state && state.dataLoading);

export const getLoadingVisibility = createSelector(getRouterProgressBar, getDataProgressBar, (router, data) => router || data);

export const getIsOpenDialog = createSelector(getStatusComponentDialog, state => state && state.isOpen);

export const getNavigation = createSelector(fromFeature.getUiFeatureState, fromUi.getNavigationState);

export const getStatusSidebar = createSelector(fromFeature.getUiFeatureState, fromUi.getSideBarState);

export const getIsOpenSidebar = createSelector(getStatusSidebar, state => state && state.isOpen);

export const getStatusForms = createSelector(fromFeature.getUiFeatureState, fromUi.getForms);

export const getHotkey = createSelector(getStatusForms, state => state.hotkey);

export const getResetTerritorySearchInput = createSelector(getStatusForms, state => state && state.resetTerritorySearchInput);

export const getFabToggle = createSelector(fromFeature.getUiFeatureState, fromUi.getFabToggle);

export const getFilterSharePicture = createSelector(fromFeature.getUiFeatureState, detail => detail.forms.filterSharePicture);
