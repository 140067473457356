import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { selectControlValue } from '@ice/utils';
import { Store } from '@ngrx/store';
import { FieldType } from '@ngx-formly/material';
import { TerritoryGroup } from 'config/constants/territories.constants';
import { filter, find, get, head, isArray, isNil, isObject, isString, toLower, flatMap } from 'lodash';
import { OptionsGroup } from 'models/options-group';
import { SelectCodeItem } from 'models/selectCodeItem';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, map, filter as rxjsFilter, share, startWith, takeUntil } from 'rxjs/operators';
import { getHotkey, RootState } from 'store/root';

export const _filter = (opt: SelectCodeItem[] | any[], value: string): string[] => {
  return filter(opt, option => toLower(option['label']).includes(value));
};

@Component({
  selector: 'ice-autocomplete-grouped',
  templateUrl: './autocomplete-grouped-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteGroupedTypeComponent extends FieldType implements OnInit, AfterViewInit, OnDestroy {
  filteredOptions: any | Observable<any[]>;
  formattedValue: any;
  asyncOptionsSubscription: Subscription;
  allOptions: any | Observable<any[]>;
  clearing = false;
  flex: string;
  unsubscribeAll = new Subject();
  onScrollEvent: any;
  onSearchEvent: any;
  tempTooltip = '';
  preferredCountries: any[];
  searchChanged$: Subject<string> = new Subject<string>();

  @ViewChild('autoGroup', { static: true }) statesAutocompleteRef: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;
  @ViewChild('inputAutoComplete', { static: true }) autocompleteInput: ElementRef;

  constructor(private fb: FormBuilder, private chDet: ChangeDetectorRef, private store: Store<RootState>) {
    super();
  }

  ngOnInit() {
    const searchChanged = this.searchChanged$.pipe(
      takeUntil(this.unsubscribeAll),
      rxjsFilter(() => this.to.onSearchEvent),
      debounceTime(250),
      share(),
    );
    this.flex = this.to.flex || '90';
    if (this.to.asyncOptions) {
      this.filteredOptions = this.to.asyncOptions.pipe(
        rxjsFilter(options => !!options),
        map(([options, value]) => {
          this.allOptions = isArray(options) ? options : [options];
          const filterOption = typeof value === 'string' ? value : value?.header;
          return value ? this._filterGroup(filterOption) : this.allOptions;
        }),
      );
    } else {
      this.initializeFilter(this.to.options);
      this.updateValue();
    }
    this.formControl.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(changes => {
      if (this.to.change) {
        this.to.change(this.field, changes);
      }
      if (isString(changes) && this.to.onSearchEvent) {
        this.executeSearch(changes);
        this.updateInputValue({ value: changes, label: changes });
      } else {
        this.updateValue();
      }
    });
    searchChanged.subscribe(value => this.to.onSearchEvent(value));
    // listen to hotkeys
    if (this.to.useCountryShortcuts) {
      this.store
        .select(getHotkey)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe(countryFromHotkey => {
          const preferredCountries = get(this.allOptions && this.allOptions.find(group => group.header === TerritoryGroup.ICE_TERRITORIES), 'options', []);
          const countryOption = (preferredCountries || []).find(country => country.label === countryFromHotkey);
          if (!countryOption) {
            return;
          }
          this.formControl.setValue(countryOption.value);
          this.formControl.markAsDirty();
          // dispatch the `submit form shortKey` when submitShortcutEnable is true
          window.dispatchEvent(
            new KeyboardEvent('keyup', {
              key: 'Enter',
              altKey: true,
              bubbles: true,
              shiftKey: true,
            }),
          );
        });
    }
  }

  ngAfterViewInit() {
    if (isString(this.formControl.value) && this.to.onSearchEvent) {
      this.updateInputValue({ value: this.formControl.value, label: this.formControl.value });
    }
  }

  click(event) {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    const value = get(event, 'target.innerText', '').trim();
    this.autocomplete.writeValue({ value, label: value });
  }

  private initializeFilter(options) {
    this.allOptions = isArray(options) ? options : [options];
    this.filteredOptionsPipe();
  }

  private filteredOptionsPipe() {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith<string | any>(''),
      map(value => {
        return typeof value === 'string' ? value : value?.label?.toLowerCase();
      }),
      map(label => (label ? this._filterGroup(label) : this.allOptions)),
    );
  }

  private _filterGroup(value: string): OptionsGroup[] {
    const options = <OptionsGroup[]>this.allOptions;
    if (value) {
      const filteredGroups: any[] = options
        .map(group => ({ header: group.header, options: _filter(group.options, value.toLowerCase()) }))
        .filter(group => group.options.length > 0);
      if (filteredGroups.length > 0) {
        return filteredGroups;
      }
    }
    return options;
  }

  ngOnDestroy() {
    if (this.asyncOptionsSubscription) {
      this.asyncOptionsSubscription.unsubscribe();
    }
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  displayFn(item?: SelectCodeItem): string | undefined {
    return item ? item.label : undefined;
  }

  clearValue() {
    this.clearing = true;
    setTimeout(() => this.formControl.setValue(null), 100);
  }

  updateValue() {
    if (this.formControl && this.formControl.value && !this.to.onSearchEvent) {
      const filteredItem = flatMap(this.allOptions, group => {
        return filter(group['options'], option => {
          return option['value'].toString() === this.formControl.value || option['label'].toString() === (selectControlValue(this.formControl) || '').toUpperCase();
        });
      });
      if (filteredItem && filteredItem.length > 0) {
        this.updateInputValue(head(filteredItem));
      }
    } else if (this.to.onSearchEvent && this.formControl.value && !isObject(this.formControl.value)) {
      const selectObject = find(
        flatMap(this.allOptions, group => group.options),
        option => option.value && option.value.toString().toUpperCase() === this.formControl.value.toUpperCase(),
      );

      if (isObject(selectObject) && isNil(selectObject)) {
        this.updateInputValue(selectObject);
      }
    }
  }

  private updateInputValue(newValue: object) {
    this.formControl.setValue(newValue);
    this.chDet.detectChanges();
  }

  onBlurMethod() {
    const selectedValue = this.autocompleteInput.nativeElement.value;
    const selectedOption = flatMap(this.allOptions, optionGroup => optionGroup?.options).find(option => option.label === selectedValue);
    if (!selectedOption && this.formControl?.value && !this.to.allowAnyValue) {
      this.formControl.setValue(null);
    }

    if (selectedValue && selectControlValue(this.formControl) !== selectedValue) {
      this.formControl.setValue(selectedOption || { value: selectedValue, label: selectedValue });
    }

    if (!this.to.onSearchEvent) {
      this.updateValue();
      // To hide the options when the reset button is pressed in advanced search
      setTimeout(() => {
        if (this.clearing) {
          this.clearing = false;
        } else {
          this.autocomplete.closePanel();
        }
      }, 300);
    }
  }

  autocompleteScrollEvent() {
    if (this.to.onScrollEvent) {
      setTimeout(() => {
        if (this.statesAutocompleteRef && this.autocomplete && this.statesAutocompleteRef.panel) {
          fromEvent(this.statesAutocompleteRef.panel.nativeElement, 'scroll')
            .pipe(
              map(x => this.statesAutocompleteRef.panel.nativeElement.scrollTop),
              takeUntil(this.autocomplete.panelClosingActions),
            )
            .subscribe(x => {
              const scrollTop = this.statesAutocompleteRef.panel.nativeElement.scrollTop;
              const scrollHeight = this.statesAutocompleteRef.panel.nativeElement.scrollHeight;
              const elementHeight = this.statesAutocompleteRef.panel.nativeElement.clientHeight;
              const atBottom = scrollHeight === scrollTop + elementHeight;
              if (atBottom) {
                this.to.onScrollEvent();
              }
            });
        }
      });
    }
  }

  searchEvent(event) {
    if (this.to.onSearchEvent && !['ArrowDown', 'ArrowUp'].includes(event.key)) {
      this.allOptions = [];
      const value = event && event.target && event.target.value;
      if (value && value.length > 1) {
        this.executeSearch(value);
      }
    }
  }

  executeSearch(value: string) {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    if (value !== this.formControl.value) {
      this.searchChanged$.next(value);
      this.autocomplete.openPanel();
    }
  }

  doHighlight(event) {
    if (event) {
      this.autocompleteInput.nativeElement.select();
    }
  }

  enterField(event, tooltipValue) {
    // If it's ellipsed
    if (event?.target && event.target.offsetWidth < event.target.scrollWidth) {
      this.tempTooltip = tooltipValue;
    }
  }

  leaveField() {
    this.tempTooltip = '';
  }

  getPlaceholder() {
    return `${this.to.placeholder}${(this.to.required && ' *') || ''}`;
  }
}
