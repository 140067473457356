import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableComponent } from '@ice';
import { locale as english } from '@ice/i18n/en/button-labels';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { findIndex, get } from 'lodash';
import { of, Subject } from 'rxjs';
import { delay, pairwise, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ice-formly-datatable-editable',
  templateUrl: './datatable-editable-type.component.html',
  styleUrls: ['./datatable-editable-type.component.scss'],
})
export class DatatableEditableTypeComponent extends FieldType implements OnInit, OnDestroy {
  addButtonText: string;
  removeButtonText: string;
  customError: string;
  searchOptions: Array<any>;
  searchProperty: any;
  searchValue: any;
  offset: number;
  rows: any[];
  currentHeight = 'auto';
  unsubscribeAll = new Subject();
  lastSearchScrollIndex;
  @ViewChild(DataTableComponent) table: DataTableComponent;
  @ViewChild('datatable_container') tableContainer: ElementRef;

  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private chDet: ChangeDetectorRef) {
    super();
    this.fuseTranslationLoader.loadTranslations(english);
    this.addButtonText = this.translate.instant('ADD_BUTTON');
    this.removeButtonText = this.translate.instant('REMOVE_BUTTON');
  }

  search() {
    if (this.table) {
      let offset = this.getOffset();
      if (offset < 0 && this.lastSearchScrollIndex) {
        this.lastSearchScrollIndex = null;
        offset = this.getOffset();
      } else {
        this.lastSearchScrollIndex = offset;
      }
      this.table.setDatatableOffset(Math.max(offset, 0) * get(this.to, `config.rowHeight`, 0));
    }
  }

  getOffset() {
    return findIndex(
      this.rows || [],
      row =>
        get(row, this.searchProperty, '')
          .toLowerCase()
          .includes((this.searchValue || '').toLowerCase()),
      this.lastSearchScrollIndex ? this.lastSearchScrollIndex + 1 : 0,
    );
  }

  ngOnInit() {
    const searchColumns = get(this.to, `config.searchColumns`);
    this.searchOptions = get(this.to, `config.schema`, []).filter(column => !searchColumns || searchColumns.includes(column.prop));
    this.searchProperty = get(searchColumns, `[0]`, '');
    get(this.to, `config.data`, of([]))
      .pipe(
        takeUntil(this.unsubscribeAll),
        tap((data: any) => {
          const height = this.tableContainer && this.tableContainer.nativeElement.offsetHeight;
          this.currentHeight = height ? `${height}px` : 'auto';
          this.rows = data;
        }),
        startWith([]),
        pairwise(),
        delay(0),
      )
      .subscribe(([previousRows, currentRows]) => {
        if (get(this.to, `config.expandNewRows`)) {
          const previousRowIdentities = previousRows?.map(row => row.rowIdentity(row));
          const newRows = currentRows.filter(row => !previousRowIdentities?.includes(row.rowIdentity(row)));
          this.currentHeight = 'auto';
          this.table.toggleExpandRows(newRows);
        }
      });
    if (this.field.templateOptions['addButtonText']) {
      this.addButtonText = this.field.templateOptions['addButtonText'];
    }
    if (this.field.templateOptions['removeButtonText']) {
      this.removeButtonText = this.field.templateOptions['removeButtonText'];
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
