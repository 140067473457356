import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { WorkUtils } from '@ice';
import { AnalysisAndComparisons } from '@ice/dynamic-components/analysis-and-comparisons/analysis-and-comparisons';
import { FormsAndTables } from '@ice/dynamic-components/forms-and-tables/forms-and-tables';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { DatatableUtils } from '@ice/utils/datatable/datatable.utils';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { TitleTypes } from 'config/constants/works.constants';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { get, invert, keyBy, mapValues } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from 'store/root';

const DEFAULT_CARDS_HEIGHT = window.innerHeight - 370;

export class TabCounterClaimsXref implements SectionTabConfig {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getConf(): IceGroupComponent[] | AnalysisAndComparisons | FormsAndTables {
    const expandTopCardsSubject = new BehaviorSubject(true);
    const titleLabels = keyBy(WorkUtils.getAlternativeTitlesOptions(this.translate), 'value');
    return [
      {
        group: [
          {
            type: 'card',
            flex: 64,
            config: {
              onOpen: () => expandTopCardsSubject.next(true),
              onClosed: () => expandTopCardsSubject.next(false),
              expanded: expandTopCardsSubject,
              showCounter: this.store.pipe(
                select(fromRoot.getCounterClaimWorkTitles),
                map(xref => xref.length),
              ),
              title: this.translate.instant('COUNTER.WORK_TITLES.TITLE'),
              minHeight: DEFAULT_CARDS_HEIGHT,
              maxHeight: DEFAULT_CARDS_HEIGHT,
              hideToggle: true,
              content: [
                {
                  group: [
                    {
                      type: 'dataTable',
                      config: {
                        columnMode: 'flex',
                        customClass: 'simple-list',
                        data: this.store.pipe(
                          select(fromRoot.getCounterClaimWorkTitles),
                          map(rows => rows.slice(0, 100)),
                          map(rows =>
                            DatatableUtils.getDatatableSimpleListFromRows(
                              rows,
                              this.translate,
                              mapValues(invert(TitleTypes), value => ({ label: get(titleLabels, `${value}.label`) } as any)),
                            ),
                          ),
                        ),
                        schema: [
                          { name: '', prop: 'label', flexGrow: 0.3 },
                          { name: '', prop: 'value', flexGrow: 1, cellClass: 'bold-table-cell' },
                        ],
                        headerHeight: 0,
                        dontStrip: true,
                        shadowed: true,
                        showLoader: true,
                        sorts: [],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            type: 'card',
            flex: 34,
            config: {
              onOpen: () => expandTopCardsSubject.next(true),
              onClosed: () => expandTopCardsSubject.next(false),
              expanded: expandTopCardsSubject,
              showCounter: this.store.pipe(
                select(fromRoot.getCounterClaimXREF),
                map(xref => xref.length),
              ),
              title: this.translate.instant('COUNTER.CROSS_REFERENCES.TITLE'),
              minHeight: DEFAULT_CARDS_HEIGHT,
              maxHeight: DEFAULT_CARDS_HEIGHT,
              hideToggle: true,
              content: [
                {
                  group: [
                    {
                      type: 'dataTable',
                      config: {
                        columnMode: 'flex',
                        customClass: 'simple-list',
                        data: this.store.pipe(
                          select(fromRoot.getCounterClaimXREF),
                          map(rows => rows.slice(0, 100)),
                          map(rows => DatatableUtils.getDatatableSimpleListFromRows(rows, this.translate, {}, {})),
                        ),
                        schema: [
                          { name: '', prop: 'label', flexGrow: 1, tooltip: 'labelXrefTooltip', canHideTooltip: true },
                          { name: '', prop: 'value', flexGrow: 1, cellClass: 'bold-table-cell' },
                        ],
                        headerHeight: 0,
                        dontStrip: true,
                        shadowed: true,
                        showLoader: true,
                        sorts: [],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    ];
  }
}
