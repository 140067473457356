import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { SectionsConfig } from 'config/sections-config';
import * as fromRoot from 'store/root';
import { PermissionsService } from 'services/permissions/permissions.service';
import * as fromForm from 'store/form';
import { DataTableBuilder } from './data-table-builder';

export class ConflictsActionsDataTable extends RootDatatable implements DataTableBuilder {
  private canOpenWorks: boolean;
  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<any>,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
    protected permissionsService: PermissionsService,
  ) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
    this.canOpenWorks = permissionsService.can('works_details_data');
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'counterclaimId', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'id':
      case 'idFormatted':
        return { prop: `id`, dir: sort.dir };
      case 'workId':
        return { prop: `counterclaim.work.id`, dir: sort.dir };
      case 'workTitle':
        return { prop: `counterclaim.work.attributes.titles.*.titleValue`, dir: sort.dir };
      case 'counterclaimIdFormatted':
        return { prop: `counterclaim.id`, dir: sort.dir };
      case 'actionResolverName':
        return { prop: `counterclaimParticipant.claimant.attributes.name`, dir: sort.dir };
      case 'type':
        return { prop: `counterclaim.attributes.type`, dir: sort.dir };
      case 'status':
        return { prop: `counterclaim.attributes.status`, dir: sort.dir };
      case 'actionType':
        return { prop: `attributes.type`, dir: sort.dir };
      case 'actionStatus':
        return { prop: `attributes.status`, dir: sort.dir };
      case 'actionOwner':
        return { prop: `ownerName.attributes.name`, dir: sort.dir };
      case 'actionResponse':
        return { prop: `attributes.response`, dir: sort.dir };
      case 'actionDeadline':
        return { prop: `attributes.deadline`, dir: sort.dir };
      case 'actionAssignedUser':
        return { prop: `attributes.assignor`, dir: sort.dir };
      case 'resolutionOwner':
        return { prop: `counterclaimParticipant.resolutionOwner`, dir: sort.dir };
      case 'actionResolver':
      case 'createdDate':
      case 'territoriesLabel':
        return null;
    }
  }

  getDataTableAdditionalFields(): DataTableRow[] {
    return [
      {
        cellClass: this.canOpenWorks ? 'ice-table-link' : null,
        name: this.translate.instant('COUNTER_CLAIMS.WORK_TITLE'),
        prop: 'workTitle',
        flexGrow: 3,
        comparator: () => this.serverSideSorting(),
        redirectPath: this.goToWork.bind(this),
        onClickAction: this.goToWork.bind(this),
        headerTooltip: ' ',
        tooltip: 'workTitle',
      },
      { name: this.translate.instant('COUNTER_CLAIMS.CREATION_DATE'), prop: 'createdDate', flexGrow: 1.5, comparator: () => this.serverSideSorting(), headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.STATUS'), prop: 'status', flexGrow: 2, comparator: () => this.serverSideSorting(), headerTooltip: ' ' },
      {
        name: this.translate.instant('COUNTER_CLAIMS.TERRITORY_SCOPE'),
        prop: 'territoriesLabel',
        tooltip: 'territoriesTooltip',
        flexGrow: 2,
        tooltipDuration: 250,
        comparator: (itemA, itemB) => this.arrayComparator(itemA, itemB, this.generalComparator),
        headerTooltip: ' ',
      },
    ];
  }

  getDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('COUNTER_CLAIMS.ACTION_ID'),
        prop: 'idFormatted',
        flexGrow: 2.2,
        comparator: () => this.serverSideSorting(),
        headerTooltip: ' ',
      },
      {
        cellClass: 'cursor-pointer',
        name: this.translate.instant('COUNTER_CLAIMS.ACTION_RESOLVER'),
        prop: 'actionResolver',
        flexGrow: 2.5,
        comparator: (a, b) => {
          const valueA = a && !isNaN(a) ? a : 0;
          const valueB = b && !isNaN(b) ? b : 0;
          return this.numericalComparator(valueA, valueB);
        },
        redirectPath: this.goToIP.bind(this),
        onClickAction: this.goToIP.bind(this),
        headerTooltip: ' ',
      },
      {
        cellClass: 'cursor-pointer',
        name: this.translate.instant('COUNTER_CLAIMS.ACTION_RESOLVER_NAME'),
        prop: 'actionResolverName',
        flexGrow: 2.5,
        comparator: () => this.serverSideSorting(),
        redirectPath: this.goToIP.bind(this),
        onClickAction: this.goToIP.bind(this),
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('COUNTER_CLAIMS.RESOLUTION_OWNER'),
        prop: 'resolutionOwner',
        flexGrow: 1.5,
        comparator: () => this.serverSideSorting(),
        headerTooltip: ' ',
        tooltip: 'resolutionOwnerTooltip',
        canHideTooltip: true,
      },
      { name: this.translate.instant('COUNTER_CLAIMS.ACTION_TYPE'), prop: 'actionType', flexGrow: 2.5, comparator: () => this.serverSideSorting(), headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.ACTION_RESPONSE'), prop: 'actionResponse', flexGrow: 2, comparator: () => this.serverSideSorting(), headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.ACTION_OWNER'), prop: 'actionOwner', flexGrow: 2, comparator: () => this.serverSideSorting(), headerTooltip: ' ' },
      {
        cellClass: 'ice-table-link',
        name: this.translate.instant('COUNTER_CLAIMS.COUNTERCLAIM_ID'),
        prop: 'counterclaimIdFormatted',
        flexGrow: 2.2,
        comparator: () => this.serverSideSorting(),
        onClickAction: this.goToCounterClaimId.bind(this),
        headerTooltip: ' ',
      },
      { name: this.translate.instant('COUNTER_CLAIMS.COUNTERCLAIM_TYPE'), prop: 'type', flexGrow: 2, comparator: () => this.serverSideSorting(), headerTooltip: ' ' },
      {
        cellClass: this.canOpenWorks ? 'ice-table-link' : null,
        name: this.translate.instant('COUNTER_CLAIMS.WORK_REF'),
        prop: 'workId',
        flexGrow: 1.5,
        comparator: () => this.serverSideSorting(),
        redirectPath: this.goToWork.bind(this),
        onClickAction: this.goToWork.bind(this),
        headerTooltip: ' ',
      },
      { name: this.translate.instant('COUNTER_CLAIMS.ACTION_STATUS'), prop: 'actionStatus', flexGrow: 2, comparator: () => this.serverSideSorting(), headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.ACTION_DEADLINE'), prop: 'actionDeadline', flexGrow: 1.5, comparator: () => this.serverSideSorting(), headerTooltip: ' ' },
      {
        name: this.translate.instant('COUNTER_CLAIMS.ACTION_ASSIGNED_USER'),
        prop: 'actionAssignedUser',
        flexGrow: 1.5,
        comparator: () => this.serverSideSorting(),
        headerTooltip: ' ',
      },
    ];
  }

  private goToWork(row, getPath = false) {
    if (!this.canOpenWorks) {
      return;
    }
    if (row && row.workId) {
      const path = [`${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/ICE:${row.workId}/details`];
      if (getPath) {
        return path;
      }
      this.store.dispatch(new fromRoot.Go({ path }));
    }
  }

  private goToCounterClaimId(row, getPath = false) {
    if (row && row.counterclaimId) {
      const path = [`${SectionsConfig.COUNTERCLAIMS.domainName}/${SectionsConfig.COUNTERCLAIMS.name}/${row.counterclaimId}`];
      if (getPath) {
        return path;
      }
      this.store.dispatch(new fromRoot.Go({ path }));
    }
  }

  private goToIP(row, getPath = false) {
    if (row && row.workId) {
      const path = [`${SectionsConfig.IPS.domainName}/${SectionsConfig.IPS.name}/${row.actionResolverKey}/details`];
      if (getPath) {
        return path;
      }
      this.store.dispatch(new fromRoot.Go({ path }));
    }
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
