import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IpNature } from 'config/constants/ips.constants';
import { TYPE_CLAIMANT } from 'config/constants/shares.constants';
import { DialogLayoutCreateIp } from 'config/dialog-layouts/dialog-layout-create-ip';
import { DialogLayoutSearchParty } from 'config/dialog-layouts/dialog-layout-search-party';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, isObservable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { DialogSharesUsesTypes } from './dialog-shares-uses-types';

export const DIALOG_FLOATING_BT = 'fab';
export const DIALOG_NORMAL_BT = 'normal';
export const DIALOG_NO_BT = 'no buttons';

export class DialogClaimNode {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private rightsIncludeView$ = new Subject();
  private updateUsageTypes: any;
  constructor(
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
    private dialog: MatDialog,
    private isWorkRegister = false,
  ) {}

  openDialog(title, model, form, onSubmit, onCancel, onSearchItemSelected: Function, searchBaseType: string): MatDialogRef<DialogMultiLayoutComponent, any> {
    const isFormValid = new BehaviorSubject(false);
    let modelForm = null;
    const formBuilder = isObservable(form) ? form : of(form);
    this.store.dispatch(new fromForm.NewItemCleanSearch());
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-85vw-90vh',
        layouts: [
          {
            title: of(title),
            actions: [
              { tooltip: this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.CLAIM_DIALOG.CANCEL'), color: 'warn', nextLayout: 0, icon: 'close', onClick: onCancel },
              {
                tooltip: this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.CLAIM_DIALOG.SUBMIT'),
                nextLayout: 1,
                icon: 'done',
                disabled: isFormValid.pipe(map(isValid => !isValid)),
                onClick: () => onSubmit(modelForm),
                className: 'ok-icon-button',
              },
            ],
            layout: [
              {
                group: [
                  {
                    type: 'formly',
                    config: {
                      model: of(model),
                      formBuilder,
                      change: (modelChange: any) => (modelForm = cloneDeep(modelChange)),
                      setValidForm: isValid => isFormValid.next(isValid),
                    },
                  },
                ],
              },
            ],
          },
          this.getLayoutSearchParty(TYPE_CLAIMANT, onSearchItemSelected, searchBaseType, this.isWorkRegister),
          {
            title: of(this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.SELECT_IPI_RIGHTS')),
            actions: [],
            layout: [this.includeRightsLayout()],
          },
          ...this.getDummyIPLayout(),
        ],
      },
    });
    return this.dialogRef;
  }

  changeLayout(layout) {
    this.dialogRef.componentInstance.setLayout(layout);
  }

  getLayoutSearchParty(type, onItemSelected, baseType = null, isWorkRegister = false) {
    const { translate, translationLoader, store, storeNewItem } = this;
    const layoutSearchParty = new DialogLayoutSearchParty(translate, translationLoader, store, storeNewItem, false, isWorkRegister);
    return layoutSearchParty.getLayout(
      type,
      selected => {
        if (selected) {
          onItemSelected(selected);
          this.changeLayout(0);
        }
      },
      {},
      true,
      true,
      null,
      null,
      baseType,
    );
  }

  includeRightsLayout(): IceGroupComponent {
    const popup = DialogSharesUsesTypes.getPopupLabels(this.translate);
    const usesTypes = DialogSharesUsesTypes.getUsesTypes(this.translate);
    return {
      group: [
        {
          type: 'formly',
          config: {
            formBuilder: this.rightsIncludeView$.pipe(
              tap(({ rightType, currentIncluded, updateUsageTypes }) => (this.updateUsageTypes = updateUsageTypes)),
              map(({ rightType, currentIncluded, updateUsageTypes }) => [
                { fieldGroupClassName: 'display-flex-col', fieldGroup: DialogSharesUsesTypes.generateModelAndFormConfig(rightType, currentIncluded, usesTypes).formConfig },
              ]),
            ),
            model: this.rightsIncludeView$.pipe(
              map(({ rightType, currentIncluded, updateUsageTypes }) => DialogSharesUsesTypes.generateModelAndFormConfig(rightType, currentIncluded, usesTypes).model),
            ),
            submitAvailable: true,
            submitLabel: popup.submitLabel,
            button1Enabled: true,
            button1Available: true,
            button1Label: popup.button1Label,
            submit: event => {
              if (this.updateUsageTypes) {
                this.updateUsageTypes(event, () => this.dialogRef.componentInstance.setLayout(0));
              }
            },

            onButton1: () => {
              this.changeLayout(0);
            },
          },
        },
      ],
    };
  }

  getDummyIPLayout() {
    const layoutCreateIp = new DialogLayoutCreateIp(this.translate);
    const nsSubmitNewIp = 'ICEADMIN';
    return [
      {
        title: of(this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.TITLE')),
        actions: [],
        layout: [
          {
            group: [
              {
                type: 'formly',
                config: {
                  formBuilder: of(layoutCreateIp.getLayout(false, IpNature.NATURAL_PERSON)),
                  model: of({}),
                  submitEnabled: true,
                  submitAvailable: 'true',
                  submitLabel: this.translate.instant('POPUP.CONFIRM'),
                  button1Enabled: true,
                  button1Available: 'true',
                  button1Label: this.translate.instant('POPUP.CANCEL'),
                  submit: (dummyIpFields: any) => {
                    this.store.dispatch(new fromForm.PutNewIp({ ...dummyIpFields, submitterIPI: nsSubmitNewIp, getIpDetail: true }));
                    this.changeLayout(4);
                  },
                  onButton1: () => this.changeLayout(0),
                },
              },
            ],
          },
        ],
      },
      {
        title: of(this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.TITLE')),
        actions: [],
        layout: [
          {
            group: [
              {
                type: 'response-error',
                config: {
                  response: of({ text: this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.DUMMY_IP_ADDED') }),
                  responseButtons: of([
                    {
                      text: of(this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.DUMMY_RETURN')),
                      action: () => {
                        this.changeLayout(0);
                      },
                    },
                  ]),
                  errors: this.store.pipe(
                    select(fromForm.getNewSectionNewIpError),
                    map(error => error?.text),
                  ),
                  errorButtons: of([
                    {
                      text: of(this.translate.instant('POPUP.CLOSE')),
                      action: () => {
                        this.changeLayout(3);
                      },
                    },
                  ]),
                  loading: this.store.pipe(select(fromForm.getNewSectionNewIpIsLoading)),
                  loadingText: of(this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.CREATING_DUMMY_IP')),
                },
              },
            ],
          },
        ],
      },
    ];
  }

  public goToRightsLayout(rightType, currentIncluded, updateUsageTypes) {
    this.rightsIncludeView$.next({ rightType, currentIncluded, updateUsageTypes });
    this.changeLayout(2);
  }
}
