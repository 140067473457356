import { get, intersection, isEqual, last } from 'lodash';
import moment, { Moment } from 'moment';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DATE_FORMAT, END_DATE_NO_DISPLAY } from 'config/constants/global.constants';
import { DatepickerParams } from 'models/copyright/formly/datepicker-params';
import { combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { InputQuerySelector, InputTagName, PostTermCollectionValue, DatepickerUtils, StringUtils } from '@ice';
import { STEPPER_ERRORS } from 'config/constants/formly.constants';
import { ROW_MATCH } from 'config/constants/works.constants';
import { countBy, first, some } from 'lodash';
import { OptionsGroup } from 'models/options-group';
import { Observable } from 'rxjs';

export function equalFieldsValuesAndNotEmpty(fieldA, fieldB) {
  const valueA = get(fieldA, 'formControl.value');
  const valueB = get(fieldB, 'formControl.value');
  if (valueA && valueB) {
    const fieldALength = get(valueA, 'length', 0);
    const fieldBLength = get(valueB, 'length', 0);
    return (fieldALength > 0 || fieldBLength > 0) && valueA === valueB;
  }
  return false;
}

export function validateField(field) {
  if (field) {
    field.formControl.updateValueAndValidity({ onlySelf: true });
  }
}

export function getParentSelector(elm, selector) {
  const all = document.querySelectorAll(selector);
  let cur = elm.parentNode;
  while (cur && !Array.from(all).includes(cur)) {
    cur = cur.parentNode;
  }
  return cur;
}

export const IsValidValidator = (control: FormControl, field: FormlyFieldConfig) =>
  control.value || !field.templateOptions.required || (!field.templateOptions.currentValue && field.templateOptions.required);

export function hasDuplicatedValue(control: FormControl) {
  if (control.value && control.value !== '') {
    const values = StringUtils.trimLeadingZerosFromArray(
      selectControlValue(control)
        .replace(/\+|\-| /g, ',')
        .split(','),
    );
    const filteredValues = values.filter(val => val !== '0');
    return !some(
      countBy(filteredValues, val => val.trim().toUpperCase()),
      val => val > 1,
    );
  }
  return true;
}

export function hasDuplicatedSpaces(control: FormControl) {
  if (control.value && control.value !== '') {
    const value = selectControlValue(control);
    const res = value.indexOf('  ') < 0;

    return res;
  }
  return true;
}

export function selectControlValue(control) {
  if (!control || !control.value) {
    return null;
  }
  if (typeof control.value === 'string') {
    return control.value;
  }
  if (moment.isMoment(control.value)) {
    return control.value;
  } else {
    return control.value.value;
  }
}

export function generateHiddenFields(fieldKeys, instanceProperties?, instance?): FormlyFieldConfig[] {
  const mapFieldsToProps = instance && instanceProperties && instanceProperties.length === fieldKeys.length;
  return fieldKeys.map((key, index) => {
    return {
      className: '',
      key,
      type: 'label',
      modelOptions: {
        updateOn: 'blur',
      },
      templateOptions: {
        type: 'text',
      },
      hooks: {
        onInit: field => {
          if (mapFieldsToProps) {
            instance[instanceProperties[index]] = field;
          }
        },
      },
    };
  });
}

export const fieldConfig = (fieldGroup: FormlyFieldConfig[], fieldGroupClassName: string = 'display-flex') => ({
  fieldGroupClassName,
  fieldGroup,
});

export const fieldInput = (
  key: string,
  label: string,
  className: string = 'flex-1',
  required: boolean = false,
  disabled: boolean = true,
  expressionProperties: any = {},
  testClassName: string = '',
  focus: boolean = false,
  hideExpression = model => false,
  validators: any = {},
) => ({
  className: testClassName.length > 0 ? `${className} ${testClassName}` : `${className}`,
  key,
  type: 'input',
  wrappers: ['form-field', 'wrapper-input-text'],
  focus,
  templateOptions: {
    type: 'text',
    label,
    required,
    disabled,
    infoText: undefined,
  },
  expressionProperties,
  hideExpression,
  validators,
});

export const fieldInputRequiredTesteable = (key: string, placeholder: string, className: string = 'flex-1', testClassName: string = '', focus: boolean = false) =>
  fieldInput(key, placeholder, className, true, undefined, undefined, testClassName);

export const fieldInputRequiredEditable = (key: string, placeholder: string, className: string = 'flex-1', testClassName: string = '', focus: boolean = false) =>
  fieldInput(key, placeholder, className, true, false, undefined, testClassName);

export interface IceOption<ValueType = string, MetadataType = any> {
  label: string;
  value: ValueType;
  disabled?: boolean;
  metadata?: MetadataType;
}
export type IceOptions<ValueType = string, MetadataType = any> = IceOption<ValueType, MetadataType>[];

export const fieldSelect = (
  key: string,
  label: string,
  defaultValue: string,
  options: IceOptions | Observable<IceOptions>,
  required: boolean = false,
  hideExpression = model => false,
  className = 'flex-1',
) => ({
  className,
  key,
  type: 'select',
  defaultValue,
  templateOptions: {
    label,
    required,
    options,
  },
  hideExpression,
});

export const fieldSelectRequired = (key: string, label: string, defaultValue: string, options: { label: string; value: string }[]) =>
  fieldSelect(key, label, defaultValue, options, true);

export function fieldAutocompleteGrouped(
  key: string,
  placeholder: string,
  defaultValue: string,
  options: OptionsGroup[],
  className: string = 'flex-1',
  required: boolean = false,
  useCountryShortcuts: boolean = false,
  updateOn?: 'submit',
) {
  if (defaultValue) {
    return {
      key,
      className,
      type: 'ice-autocomplete-grouped',
      modelOptions: {
        updateOn,
      },
      defaultValue,
      templateOptions: {
        placeholder,
        options,
        required,
        useCountryShortcuts,
      },
    };
  }
  return {
    key,
    className,
    type: 'ice-autocomplete-grouped',
    modelOptions: {
      updateOn,
    },
    templateOptions: {
      placeholder,
      options,
      required,
      useCountryShortcuts,
    },
  };
}

export const fieldAutocompleteGroupedRequired = (
  key: string,
  placeholder: string,
  defaultValue: string,
  options: OptionsGroup[],
  className: string = 'flex-1',
  useCountryShortcuts: boolean,
) => fieldAutocompleteGrouped(key, placeholder, defaultValue, options, className, true, useCountryShortcuts);

export const getFormlyRow = (rowFields: string[], totalFields: FormlyFieldConfig[]): FormlyFieldConfig[] =>
  totalFields.filter(field => rowFields.includes(field.key as string)).sort((a, b) => rowFields.indexOf(a.key as string) - rowFields.indexOf(b.key as string));

export function combineFormDateFields(formControls, inputDateFields: [string, string], outputDateField: string) {
  const firstInput = formControls[inputDateFields[0]];
  const secondInput = formControls[inputDateFields[1]];
  const fieldOutput = formControls[outputDateField];
  if (firstInput && secondInput && fieldOutput) {
    if (firstInput.value && secondInput.value) {
      const fromValue = moment(firstInput.value).format('YYYY-MM-DD');
      const toValue = moment(secondInput.value).format('YYYY-MM-DD');
      fieldOutput.setValue(fromValue + '_' + toValue);
    } else {
      fieldOutput.setValue(null);
    }
  }
}

export function buildOptions(optionValues: string[]): any[] {
  const options = optionValues.map(value => {
    return { label: value, value };
  });
  return options;
}

export const fieldRepeaterValidator = (key: string, translate, hideExpression = model => false, messages = { minimum1: null }) => ({
  fieldGroupClassName: 'display-flex repeater-validator',
  hideExpression,
  fieldGroup: [
    fieldConfig([
      {
        className: 'flex-1',
        fieldGroup: [
          {
            className: 'ice-input-only-display-none-stepper',
            key,
            type: 'label',
            wrappers: ['form-field'],
            validation: {
              show: true,
            },
            validators: {
              duplicatedValue: {
                expression: (control: FormControl) => !control.value || control.value !== STEPPER_ERRORS.DUPLICATED,
                message: translate.instant('ERROR.HAS_DUPLICATED_VALUE'),
              },
              minimum1Value: {
                expression: (control: FormControl) => !control.value || control.value !== STEPPER_ERRORS.MINIMUM1,
                message: messages.minimum1 || translate.instant('ERROR.MINIMUM_1_ITEM'),
              },
            },
          },
        ],
      },
    ]),
  ],
});

export const sortFieldGroups = (fieldGroup: any[], orderKeys: string[]): any[] => {
  return orderKeys.map(orderKey => fieldGroup.find(field => orderKey === field.key));
};

export const isFormFocused = (currentTarget: Element): boolean => {
  return [InputTagName.INPUT, InputTagName.MAT_SELECT, InputTagName.BUTTON, InputTagName.MAT_CHECKBOX].map(tag => tag.toString()).includes(currentTarget?.tagName);
};

export const isFormAndNotLast = (currentTarget: Element, event: any): boolean => {
  const isForm = isFormFocused(currentTarget);
  const target = isMatChecboxInput(currentTarget) ? getParentSelector(currentTarget, InputQuerySelector.MAT_CHECKBOX.toString()) : currentTarget;
  let isLast = false;
  let fields;
  if (isForm) {
    const formElement = getParentSelector(target, InputQuerySelector.ICE_FORM.toString());
    fields = getFields(formElement);
    isLast = isEqual(target, last(fields));
  }
  if (isForm && !isLast && fields.length > 1) {
    return true;
  }
  return false;
};

export const formNavigation = (currentTarget: Element, event: any) => {
  const target = isMatChecboxInput(currentTarget) ? getParentSelector(currentTarget, InputQuerySelector.MAT_CHECKBOX.toString()) : currentTarget;
  const formElement = getParentSelector(target, InputQuerySelector.ICE_FORM.toString());
  const fields = getFields(formElement);
  const isLast = isEqual(target, last(fields));
  if (!isLast && fields.length > 1) {
    focusNextInput(fields, target, event);
  }
};

export const focusNextInput = (fields: Element[], currentTarget: Element, event: any) => {
  const targetItem = getTargetItem(fields, currentTarget, event);
  if (targetItem && targetItem.tagName === InputTagName.MAT_CHECKBOX) {
    event.target.blur();
    event.preventDefault();
    event.stopPropagation();
    targetItem.querySelector(InputQuerySelector.INPUT.toString()).focus();
    const efect = new MouseEvent('mouseover', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    targetItem.querySelector(`.${InputQuerySelector.MAT_CHECKBOX_INNER_CONTAINER}`).dispatchEvent(efect);
  } else if (targetItem) {
    event.preventDefault();
    event.stopPropagation();
    targetItem.focus();
  }
};

export const getNextItem = (arrChildrenElements: any[], index: number): any => {
  return (index === arrChildrenElements.length - 1 ? first(arrChildrenElements) : arrChildrenElements[index + 1]) as any;
};
export const getPreviousItem = (arrChildrenElements: any[], index: number): any => {
  return (index === 0 ? last(arrChildrenElements) : arrChildrenElements[index - 1]) as any;
};

export const getTargetItem = (fields: Element[], currentTarget: Element, event: any): any => {
  const index = fields.indexOf(currentTarget);
  if (!event.shiftKey) {
    return getNextItem(fields, index);
  } else {
    return getPreviousItem(fields, index);
  }
};

export const isMatChecboxInput = (currentTarget): boolean => {
  return currentTarget.tagName === InputTagName.INPUT && currentTarget.classList.contains(InputQuerySelector.MAT_CHECKBOX_INPUT.toString());
};

export const getFields = (formElement: any): any[] => {
  const querySelector1 = `${InputQuerySelector.INPUT}, ${InputQuerySelector.MAT_SELECT}, ${InputQuerySelector.MAT_RADIO}  ${InputQuerySelector.INPUT}`;
  const querySelector2 = `${InputQuerySelector.MAT_CHECKBOX}, ${InputQuerySelector.BUTTON}`;
  const formItems = Array.from(formElement?.querySelectorAll(`${querySelector1}, ${querySelector2}`) || []);
  return filterActiveElements(formItems);
};

export const filterActiveElements = (formItems: any[]): any[] => {
  return (formItems || []).filter(
    (item: any) =>
      !item.disabled &&
      !item.readOnly &&
      (item?.classList?.contains(InputQuerySelector.MAT_RADIO_INPUT.toString()) ||
        (intersection(
          item.classList,
          [InputQuerySelector.CDK_VISUALLY_HIDDEN, InputQuerySelector.MAT_ICON_BUTTON].map(term => term.toString()),
        ).length === 0 &&
          window.getComputedStyle(item).display !== 'none' &&
          window.getComputedStyle(item).visibility !== 'hidden')) &&
      !item.disabled &&
      item.offsetParent !== null &&
      item.offsetHeight > 0,
  );
};

export const getDialogElement = (): Element => {
  return document.querySelector(InputQuerySelector.MAT_DIALOG_CONTAINER.toString());
};

export const checkDialogOpen = (event): boolean => {
  let dialogOpen = false;
  const dialog = getDialogElement();
  if (dialog) {
    dialogOpen = true;
    const currentTarget = document.activeElement;
    const inputTarget: any = dialog.querySelector(`${InputQuerySelector.MAT_SELECT}, ${InputQuerySelector.INPUT}`);
    if (
      inputTarget &&
      currentTarget.classList.contains(InputQuerySelector.MAT_FOCUS_INDICATOR.toString()) &&
      getParentSelector(currentTarget, InputQuerySelector.MAT_DIALOG_CONTAINER.toString())
    ) {
      event.preventDefault();
      event.stopPropagation();
      inputTarget.focus();
    }
  }
  return dialogOpen;
};

export const getChildTarget = (targetItem: any, selectorQuery: string) => {
  const childrenTarget = Array.from(targetItem?.parentElement.querySelectorAll(selectorQuery) || []);
  const filterTarget = (filterActiveElements(childrenTarget) || []).filter(
    (item: any) => item !== document.activeElement && (item !== targetItem || item.classList.contains(InputQuerySelector.EXTRA_BUTTON.toString())),
  );
  let selectTarget = filterTarget[0];
  if (filterTarget.length > 0) {
    selectTarget = filterTarget[0];
  } else if (childrenTarget.length === 1 && targetItem.tagName === InputTagName.BUTTON) {
    selectTarget = childrenTarget[0];
  }
  return selectTarget;
};

export const getNextFormTarget = (targetItem: any, selectorQuery: string, currentTarget: any) => {
  const formTargets = Array.from(targetItem?.parentElement.querySelectorAll(selectorQuery) || []);
  const filterFormTargets = filterActiveElements(formTargets) || [];
  const currentIndex = filterFormTargets.indexOf(currentTarget);
  return filterFormTargets[currentIndex + 1] || null;
};

export const getCloseTabElement = (arrChildrenElements: Element[], target: Element): Element => {
  let parent = target.parentElement;
  let match = intersection(arrChildrenElements, parent?.children);
  while (parent && match.length === 0) {
    parent = parent.parentElement;
    match = intersection(arrChildrenElements, parent?.children);
  }
  let index = 0;
  let subMatch;
  while (!subMatch && index < match.length) {
    subMatch = Array.from(match[index].querySelectorAll('*'))?.find(item => item === target);
    index++;
  }
  return match[index - 1] || parent;
};

export const getParentNode = (elm): Element => {
  let parent = elm.parentNode;
  while (parent && parent?.childElementCount <= 1) {
    parent = parent.parentNode;
  }
  return parent;
};

export const getScopeElements = () => {
  const currentTarget = document.activeElement;
  const dialogElementOpen = getDialogElement();
  const scope = dialogElementOpen || document;
  return { currentTarget, scope, dialogElementOpen };
};

export const checkMatchRow = () => {
  if (document.querySelector(`.${ROW_MATCH}`)) {
    scrollToRowMatch();
  } else {
    autoScrollBottomToFetchData();
  }
};

export const scrollToRowMatch = () => {
  autoScrollToItem(`.${ROW_MATCH}`);
};

export const autoScrollBottomToFetchData = () => {
  autoScrollToItem('datatable-body-row');
};

export const autoScrollToItem = (querySelector: string = '') => {
  setTimeout(() => {
    autoScrollToRow(last(document.querySelectorAll(querySelector)));
  }, 50);
};

export const autoScrollToRow = (rowElement: any) => {
  if (rowElement) {
    rowElement.focus();
    rowElement.blur();
  }
};

export const getPostTermCollectionInputs = ({
  translate,
  endDateKey = 'endDate',
  postTermProps,
  postTermDateProps,
}: {
  translate: TranslateService;
  endDateKey?: string;
  postTermProps?: FormlyFieldConfig;
  postTermDateProps?: Partial<DatepickerParams>;
}): FormlyFieldConfig[] => {
  const defaultPtcKey = 'postTermCollection';
  const ptcKey = get(postTermProps, 'key', defaultPtcKey) as string;
  const defaultPtcDateKey = 'postTermCollectionDate';
  const ptcDateKey = get(postTermDateProps, 'key', defaultPtcDateKey) as string;
  const postTermCollectionInputs: FormlyFieldConfig[] = [
    {
      className: 'flex-2 ice-w-100',
      key: ptcKey,
      type: 'select',
      ...postTermProps,
      templateOptions: {
        ...postTermProps?.templateOptions,
        attributes: { 'data-testid': 'select-postTermCollection' },
        label: translate.instant('POST_TERM_COLLECTION.LABEL'),
        options: [
          { label: translate.instant('POST_TERM_COLLECTION.NONE'), value: PostTermCollectionValue.NONE },
          { label: translate.instant('POST_TERM_COLLECTION.UNTIL_DATE'), value: PostTermCollectionValue.DATE },
          { label: translate.instant('POST_TERM_COLLECTION.INDEFINITE'), value: PostTermCollectionValue.INDEFINITE },
        ],
      },
      hooks: {
        ...postTermProps?.hooks,
        onInit: field => {
          const endDate = field.form.controls[endDateKey];

          const postCollectionDate = field.form.controls[ptcDateKey];
          const postTermCollection = field.formControl;

          const setInferredPtc = ({ endDateValue, ptcDateValue }) => {
            const endDateFormatted = endDateValue ? moment(endDateValue).format(DATE_FORMAT) : undefined;
            const ptcDateFormatted = ptcDateValue ? moment(ptcDateValue).format(DATE_FORMAT) : undefined;

            if (!postTermCollection.value && ptcDateFormatted === END_DATE_NO_DISPLAY) {
              return postTermCollection.setValue(PostTermCollectionValue.INDEFINITE);
            }

            if (endDateFormatted && ptcDateFormatted) {
              if (!postTermCollection.value) {
                // Conditions to initialize postTermCollection
                if (endDateFormatted < ptcDateFormatted) {
                  return postTermCollection.setValue(PostTermCollectionValue.DATE);
                }
                if (endDateFormatted === ptcDateFormatted) {
                  return postTermCollection.setValue(PostTermCollectionValue.NONE);
                }
              }
            }
          };

          setInferredPtc({ endDateValue: endDate?.value, ptcDateValue: postCollectionDate?.value });

          combineLatest([endDate?.valueChanges, postCollectionDate?.valueChanges]).subscribe(([endDateValue, ptcDateValue]) => {
            setInferredPtc({ endDateValue, ptcDateValue });
          });

          const disableByEndDate: (value: Moment) => void = endDateValue => {
            const endDateFormatted = endDateValue ? moment(endDateValue).format(DATE_FORMAT) : undefined;
            if (endDateFormatted === END_DATE_NO_DISPLAY) {
              postTermCollection.disable();
            } else {
              postTermCollection.enable();
            }
          };
          disableByEndDate(endDate.value);
          endDate.valueChanges.subscribe(disableByEndDate);

          postTermProps?.hooks?.onInit?.(field);
        },
      },
    },
    DatepickerUtils.getDatepickerField({
      key: ptcDateKey,
      label: translate.instant('POST_TERM_COLLECTION.END_DATE'),
      datepickerOptions: {
        filter: (date: Date, formGroup: FormGroup) => {
          if (formGroup.controls[ptcKey].value === PostTermCollectionValue.NONE || formGroup.controls[ptcKey].value === PostTermCollectionValue.INDEFINITE) {
            return true;
          }
          return !formGroup.value[endDateKey] || moment(formGroup.value[endDateKey]).format(DATE_FORMAT) < moment(date).format(DATE_FORMAT);
        },
      },
      translate,
      extraClass: 'flex-2 ice-w-100',
      ...postTermDateProps,
      extraTemplateOptions: {
        ...postTermDateProps?.extraTemplateOptions,
        attributes: {
          'data-testid': 'datePicker-postTermCollectionDate',
          ...postTermDateProps?.extraTemplateOptions?.attributes,
        },
      },
      hooks: {
        ...postTermDateProps?.hooks,
        onInit: field => {
          const endDate: AbstractControl = field.form.controls[endDateKey];
          const postCollectionDate: AbstractControl = field.formControl;
          const postTermCollection = field.form.controls[ptcKey];
          const disableByPtcValue = () => {
            if (postTermCollection.value === PostTermCollectionValue.NONE || postTermCollection.value === PostTermCollectionValue.INDEFINITE) {
              field.formControl.disable();
            } else {
              field.formControl.enable();
            }
          };
          disableByPtcValue();
          postTermCollection.valueChanges.subscribe(ptcValue => {
            if (ptcValue === PostTermCollectionValue.DATE) {
              postCollectionDate.setValue(moment(endDate.value || undefined).add(1, 'days'));
              field.model[ptcDateKey] = moment(endDate.value || undefined).add(1, 'days');
            }
            if (ptcValue === PostTermCollectionValue.INDEFINITE) {
              postCollectionDate.setValue(moment(END_DATE_NO_DISPLAY));
              field.model[ptcDateKey] = moment(END_DATE_NO_DISPLAY);
            }
            disableByPtcValue();
            field.hide = ptcValue !== PostTermCollectionValue.DATE;
          });
          combineLatest([endDate.valueChanges, postTermCollection.valueChanges]).subscribe(([endDateValue, ptcValue]) => {
            const endDateFormatted = endDateValue ? moment(endDateValue).format(DATE_FORMAT) : undefined;
            const ptcDateFormatted = postCollectionDate?.value ? moment(postCollectionDate.value).format(DATE_FORMAT) : undefined;
            if (ptcValue === PostTermCollectionValue.NONE && ptcDateFormatted !== endDateFormatted) {
              postCollectionDate.setValue(moment(endDateValue));
              field.model[ptcDateKey] = moment(endDateValue);
            }
            if (ptcValue === PostTermCollectionValue.INDEFINITE && ptcDateFormatted !== END_DATE_NO_DISPLAY) {
              postCollectionDate.setValue(moment(END_DATE_NO_DISPLAY));
              field.model[ptcDateKey] = moment(END_DATE_NO_DISPLAY);
            }
          });

          field.hide = true;
          postTermDateProps?.hooks?.onInit?.(field);
        },
      },
    }),
  ];
  return postTermCollectionInputs;
};
