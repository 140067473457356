import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CounterClaimDocumentCurrentState } from '@ice';
import { DateTimeUtils } from '@ice/utils/date-time/date-time.utils';

import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { StepType } from 'config/stepper-builders/stepper-config';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromForm from 'store/form';

// TODO: Add Angular decorator.
export class CounterClaimSupportUploadDocumentationStep {
  private uploadFileField: FormlyFieldConfig;
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<any>, private dialog: MatDialog) {}

  getStep(): StepType {
    const fileMessage$ = new BehaviorSubject(null);
    return {
      label: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.TITLE'),
      hide: this.store.pipe(
        select(fromForm.getNewSectionSelectedParties),
        map(parties => !parties || parties.filter(party => !!party.actionSupportingDocumentRequired).length === 0),
      ),
      onDestroy: model => {
        if (model.documents) {
          this.store.dispatch(new fromForm.DeleteDocuments(model.documents));
        }
      },
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'documents',
              type: 'datatable-editable',
              wrappers: ['wrapper-card'],
              templateOptions: {
                required: true,
                cardTitle: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DOCUMENTS.TITLE'),
                config: {
                  data: this.store.select(fromForm.getNewSectionDocuments).pipe(map(documents => (documents || []).filter(document => !!document.fileName))),
                  columnMode: ColumnMode.flex,
                  selectionType: SelectionType.checkbox,
                  schema: [
                    {
                      name: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DOCUMENTS.DATE_TIME'),
                      prop: 'dateTime',
                      flexGrow: 1.5,
                    },
                    {
                      name: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DOCUMENTS.FILE_NAME'),
                      prop: 'fileName',
                      flexGrow: 2,
                    },
                    {
                      name: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DOCUMENTS.OWNER_NAME'),
                      prop: 'ownerName',
                      flexGrow: 2,
                    },
                    {
                      name: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DOCUMENTS.OWNER_IPI_NUMBER'),
                      prop: 'ownerIPINameNumber',
                      flexGrow: 2,
                    },
                    {
                      name: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DOCUMENTS.CLAIMANT_NAME'),
                      prop: 'claimantName',
                      flexGrow: 2,
                    },
                    {
                      name: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DOCUMENTS.CLAIMANT_IPI_NUMBER'),
                      prop: 'claimantIPINameNumber',
                      flexGrow: 2,
                    },
                    {
                      name: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DOCUMENTS.STATUS'),
                      prop: 'status',
                      flexGrow: 1,
                    },
                    {
                      name: '',
                      actionButtonIcon: 'delete',
                      prop: 'delete',
                      flexGrow: 0.001,
                      maxWidth: 50,
                      minWidth: 50,
                      resizeable: false,
                      action: row => {
                        if (this.uploadFileField) {
                          this.store
                            .select(fromForm.getNewSectionDocuments)
                            .pipe(
                              map(documents => (documents || []).filter(document => !!document.fileName && document.location !== row.location)),
                              take(1),
                            )
                            .subscribe(documents => {
                              this.uploadFileField.parent.form.get('documents').setValue((!isEmpty(documents) && documents) || null);
                              this.store.dispatch(new fromForm.DeleteDocument(row.location));
                            });
                        }
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex ice-mb-40',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'files',
              type: 'upload-file',
              templateOptions: {
                customAddFile: file => {
                  const doc = this.setDocuments(file);

                  this.store
                    .pipe(select(fromForm.getNewSectionDocuments))
                    .pipe(
                      filter(docs => docs && doc.length === docs.length),
                      take(1),
                    )
                    .subscribe(() => this.store.dispatch(new fromForm.SaveDocument(documents => this.uploadFileField.parent.form.get('documents').setValue(documents))));
                },
                message: fileMessage$,
              },
              validators: {
                actionSelectedValidator: {
                  expression: (control, field) => {
                    const selectedParties = field.model.selectedParties;
                    if (selectedParties && selectedParties.every(selectedParty => !selectedParty.actionSupportingDocumentRequired)) {
                      fileMessage$.next(this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.ERROR_ACTION_NO_NEEDS_DOCUMENTATION'));
                      if (this.uploadFileField) {
                        this.uploadFileField.parent.form.get('documents').setValue(null);
                        this.uploadFileField.parent.form.get('documents').disable();
                      }
                    } else {
                      if (fileMessage$.value) {
                        fileMessage$.next(null);
                        if (this.uploadFileField) {
                          this.uploadFileField.parent.form.get('documents').enable();
                        }
                      }
                    }
                    return selectedParties && !!selectedParties.find(selectedParty => !!selectedParty.actionId);
                  },
                  message: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.ERROR_NO_ACTION_SELECTED'),
                },
              },
              hooks: {
                onInit: field => (this.uploadFileField = field),
              },
            },
          ],
        },
        {
          wrappers: ['wrapper-card'],
          templateOptions: { cardTitle: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DISCLAIMER.TITLE') },
          fieldGroup: [
            {
              template: `<p>${this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DISCLAIMER.TEXT_1')}<br>
              ${this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DISCLAIMER.TEXT_2')}<br>
              ${this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DISCLAIMER.TEXT_3')}</p>
              <p>${this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DISCLAIMER.TEXT_4')}<br>
              ${this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DISCLAIMER.TEXT_5')}</p>`,
            },
            {
              key: 'acceptDisclaimer',
              type: 'checkbox',
              defaultValue: false,
              templateOptions: {
                label: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DISCLAIMER.ACCEPT'),
              },
              expressionProperties: {
                'templateOptions.required': model => this.getRequiredPartiesCount(model) > 0,
              },
              validators: {
                isChecked: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => !!control.value || this.getRequiredPartiesCount(field.model || {}) === 0,
                  message: this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.UPLOAD_DOCUMENTATION.DISCLAIMER.YOU_MUST_ACCEPT_DISCLAIMER'),
                },
              },
            },
          ],
        },
      ],
    };
  }

  getRequiredPartiesCount(model) {
    return (model.selectedParties || []).filter(party => !!party.actionSupportingDocumentRequired).length;
  }

  setDocuments(file) {
    const selectedParties = this.uploadFileField.model.selectedParties;
    const documents = this.uploadFileField.model.documents || [];
    const actions = selectedParties.filter(selectedParty => !!selectedParty.actionId);
    const doc = [
      ...documents,
      ...actions.map(action => {
        const { actionId, ownerName, ownerIPINameNumber, claimantName, claimantIPINameNumber, actionSupportingDocumentRequired } = action;
        return {
          fileName: file.name,
          dateTime: DateTimeUtils.formatDate(moment(), 'YYYY-MM-DD'),
          status: CounterClaimDocumentCurrentState.UPLOADING,
          file,
          ownerName,
          ownerIPINameNumber,
          claimantName,
          claimantIPINameNumber,
          actionId,
          actionSupportingDocumentRequired,
        };
      }),
    ];
    this.uploadFileField.parent.form.get('documents').setValue(doc);
    return doc;
  }
}
