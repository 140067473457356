import { environment } from 'config/env';
import type { SectionConfig } from './sections-config';

export const SectionConflictsCounterClaimsActions: SectionConfig = {
  name: 'counter-claims-actions',
  url: '/conflicts/counter-claims-actions',
  domainName: 'conflicts',
  baseUrl: environment.apiUrl,
  itemId: 'counterclaimId',
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'actions',
  redirectDetail: 'counter-claims',
  minCharsToSearch: 5,
  apiIncludes: {
    search: `counterclaimParticipant.claimant.attributes, counterclaimParticipant.claimant.relations{ relation, otherId },
    counterclaimParticipant.claimant.xref, counterclaim.attributes, counterclaim.participants.claimant.attributes,
    ownerName.relations{otherId,relation}, ownerName.attributes{name,firstName}, counterclaim.tags, tags, attributes,
    counterclaim.participants, counterclaim.work, counterclaim.work.attributes, counterclaim.work.relations`,
    detail: ``,
  },
  searchServerSideSortDefault: 'counterclaim.id',
  allowedActions: {
    newItem: false,
    editItem: null,
    newNote: false,
    removeItem: false,
  },
  hideHomeEditButton: true,
  avoidSearchRedirect: true,
  homeRedirect: 'search',
};
