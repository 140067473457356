import { CopyrightUtils, Ip, IpBaseInformation, IpCleaned, IpSearchItem, IpSociety, IpUtils, RelationsUtils } from '@ice';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IPI_PREFIX } from 'config/constants/global.constants';
import { IpNature, PartyRelationType } from 'config/constants/ips.constants';
import { flatten, get, map } from 'lodash';
import { ClaimClaimantPartySociety } from 'models/copyright/detail/claims';
import { IconInterface } from 'models/copyright/detail/icon';

export class SearchIpsParser {
  static parse(items: any[], translate: TranslateService, extraParams?: { includedOriginalItem?: boolean }, extraItems?: string[], store?: Store<any>) {
    const includedOriginalItem = get(extraParams, 'includedOriginalItem', false);
    return this.IpSearchCleaner(items, translate, extraItems, includedOriginalItem);
  }

  static IpSearchCleaner(items: any[], translate, extraItems?: string[], addFullItemInfo = false) {
    return (
      items &&
      items.map((item: IpSearchItem) => {
        const ip: Ip = item.attributes;
        const id = item.id;
        if (ip) {
          const fullKey = IpUtils.selectIpsKey(item['relations'], id);
          const baseParty = item['parties'] && item['parties'][0] && item['parties'][0]['party'];
          const parties = item['parties'];
          const baseParties = parties && map(parties, base => base['party']).filter(party => !!party);
          const partyId = get(parties, '[0].partyId');
          const partyNameId = get(parties, '[0].partyNameId');
          const parentRelations = IpUtils.getIpRelations(parties, 'relations') || [];

          let baseIpiNumbers: string[];
          let baseIpiNumbersIcons: IconInterface[];
          let typeOf: IpNature;
          let types: string[];
          let typeOfIcon: IconInterface[];
          let status: string;
          let statusLiteral: string;
          let societies: IpSociety[] | ClaimClaimantPartySociety[];
          let prSociety: string;
          let prSocietyCodeAndName: string;
          let prSocietyIsIce: boolean;
          let prSocietyIcons: IconInterface[];
          let mrSociety: string;
          let mrSocietyCodeAndName: string;
          let mrSocietyIsIce: boolean;
          let mrSocietyIcons: IconInterface[];
          let societyCodes: string;
          let baseIpKey: string;

          if (baseParties) {
            baseIpiNumbers = map(baseParties, bp => (bp['relations'] ? IpUtils.selectIPINumber(bp['relations']).replace('IPI:', '') : ''));
            const statusList = baseParties.map(bp => bp?.attributes?.status || '');
            status = statusList.join('<br>');
            statusLiteral = statusList.map(statusValue => IpUtils.displayStatus(statusValue, translate)).join('<br>');
            if (baseParties.length <= 5) {
              types = map(baseParties, t => (t.attributes && t.attributes['typeOf']) || '');
              typeOfIcon = flatten(map(types, t => IpUtils.generateBaseTypeIcons(t, translate, 'material-icons md-14') || []));
              societies = flatten(map(baseParties, party => party['societies'] || []));
            } else {
              typeOfIcon = CopyrightUtils.generateIconWithTooltip(translate.instant('IPS.MULTIPLE_TYPES'), 'more_horiz');
              baseIpiNumbersIcons = CopyrightUtils.generateIconWithTooltip(baseIpiNumbers.join(' '), 'more_horiz', 'ice-pl-24');
              baseIpiNumbers = [];
            }
          }

          if (baseParty) {
            typeOf = (baseParty.attributes && baseParty.attributes['typeOf']) || '';
            baseIpKey = IpUtils.selectIpsKey(baseParty['relations'], baseParty.id);
          }

          const prSocietyObject = SocietiesUtils.getPRorMRSociety(societies, 'PR');
          if (prSocietyObject) {
            prSociety = (!prSocietyObject.multipleSocieties && prSocietyObject.societyCode) || '';
            prSocietyCodeAndName = prSociety !== '' ? `${SocietiesUtils.searchSocietyNameById(prSocietyObject.societyCode)}:${prSociety}` : '';
            societyCodes = prSocietyObject.societyCodes;
            const prSocieties = (prSocietyObject.multipleSocieties && prSocietyObject.societyCodes) || '';
            prSocietyIcons = prSocietyObject.multipleSocieties ? SocietiesUtils.generateIsICESocietyIcons({ isICE: prSocietyObject.hasICESociety, societies: prSocieties }) : [];
            prSocietyIsIce = prSocietyObject.hasICESociety;
          }

          const mrSocietyObject = SocietiesUtils.getPRorMRSociety(societies, 'MR');
          if (mrSocietyObject) {
            mrSociety = (!mrSocietyObject.multipleSocieties && mrSocietyObject.societyCode) || '';
            mrSocietyCodeAndName = mrSociety !== '' ? `${SocietiesUtils.searchSocietyNameById(mrSocietyObject.societyCode)}:${mrSociety}` : '';
            mrSocietyIsIce = mrSocietyObject.hasICESociety;
            const mrSocieties = (mrSocietyObject.multipleSocieties && mrSocietyObject.societyCodes) || '';
            mrSocietyIcons = mrSocietyObject.multipleSocieties ? SocietiesUtils.generateIsICESocietyIcons({ isICE: mrSocietyObject.hasICESociety, societies: mrSocieties }) : [];
          }
          const works = ''; // To complete when api is enabled

          // Extra Items to Add
          let baseInformation: IpBaseInformation[];
          let rawItem: any;
          if (extraItems) {
            extraItems.forEach(extraParam => {
              switch (extraParam) {
                case 'baseInformation':
                  baseInformation = IpUtils.getIpBaseInfo(item, translate);
                  break;
                case 'rawItem':
                  rawItem = item;
              }
            });
          }
          const agreementCount = get(item, 'agg.attributes.agreementCount', 0);
          const workCount = get(item, 'agg.attributes.workCount', 0);

          const itemCleaned: IpCleaned = {
            id,
            baseIpiNumbersFormatted: baseIpiNumbers && baseIpiNumbers.join('<br>'),
            baseIpiNumbersIcons,
            baseIpiNumber: baseIpiNumbers && baseIpiNumbers[0],
            fullName: item.attributes?.fullName || IpUtils.cleanNameLabel(IpUtils.getIpFullName(ip), typeOf),
            ipiNameNumber: RelationsUtils.selectRelationByTypeAndPrefix(item['relations'], [PartyRelationType.CROSS_REFERENCE], [IPI_PREFIX]).replace('IPI:', ''),
            baseKey: baseIpKey && baseIpKey.replace('ICE:', ''),
            key: fullKey,
            nameKey: fullKey && fullKey.replace('ICE:', ''),
            typeOfName: ip.typeOfName,
            tooltipTypeOfName: translate.instant(`IPS.TYPE_OF_NAME_TOOLTIPS.${ip.typeOfName}`),
            typeOf,
            typeOfIcon,
            status,
            statusLiteral,
            mrSociety,
            mrSocietyCodeAndName,
            mrSocietyIsIce,
            mrSocietyIcons,
            prSociety,
            prSocietyCodeAndName,
            prSocietyIsIce,
            prSocietyIcons,
            societyCodes,
            works,
            baseInformation,
            dataType: 'ip',
            rawItem,
            partyId,
            partyNameId,
            agreementCount,
            workCount,
            societies,
            baseParty,
            parentRelations,
            tooltipAgreementCount: translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.TOOLTIP_AGREEMENTS_COUNT'),
            tooltipWorkCount: translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.TOOLTIP_WORKS_COUNT'),
          };

          return addFullItemInfo ? { ...itemCleaned, originalItem: item } : itemCleaned;
        }
        return <IpCleaned>{
          id,
          dataType: 'ip',
        };
      })
    );
  }
}
