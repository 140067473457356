import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { FormConfig } from 'config/search-form-builders/form-config';
import { SectionConfigTab } from 'config/sections-config';
import { SectionRouterViews } from 'config/sections-config/section-router-views';
import { StepperSettings } from 'config/stepper-builders/stepper-builder';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { PermissionsService } from 'services/permissions/permissions.service';
import { ApiCallPayload } from './api-call';
import { SectionConflictsActivity } from './section-conflicts-activity';
import { SectionConflictsAgreementConflict } from './section-conflicts-agreement-conflict';
import { SectionConflictsCounterClaims } from './section-conflicts-counterclaims';
import { SectionConflictsCounterClaimsActions } from './section-conflicts-counterclaims-actions';
import { SectionCounterClaimsSupport } from './section-conflicts-counterclaims-support';
import { SectionAgreementGroup } from './section-copyright-agreement-group';
import { SectionCopyrightAgreements } from './section-copyright-agreements';
import { SectionCopyrightBulkUpdates } from './section-copyright-bulk-updates';
import { SectionConflictsCounterclaimNotifications } from './section-copyright-counterclaim-notifications';
import { SectionCopyrightIps } from './section-copyright-ips';
import { SectionCopyrightRepertoires } from './section-copyright-repertoires';
import { SectionCopyrightReports } from './section-copyright-reports';
import { SectionCopyrightSocieties } from './section-copyright-societies';
import { SectionCopyrightTerritories } from './section-copyright-territories';
import { SectionCopyrightWorks } from './section-copyright-works';
import { SectionUserManagementOrganizations } from './section-user-management-organizations';
import { SectionUserManagementUsers } from './section-user-management-users';

export interface SectionConfig {
  name: string;
  url: string;
  domainName: string;
  baseUrl: string;
  baseUrlCubeData?: string;
  apiSegment: string;
  detailSegment?: string;
  redirectDetail?: string;
  itemId?: string;
  removeUrlNSInSearch?: boolean;
  detailParams?: object;
  filterDetailParams?: string[];
  title?: string;
  minCharsToSearch?: number;
  apiIncludes: {
    search: string;
    dashboard?: string;
    detail: string | object;
    expertSearch?: string;
  };
  searchServerSideSortDefault: string;
  newItemBtn?: SectionRouterViews[];
  hideHomeEditButton?: boolean;
  homeRedirect?: string;
  allowedActions: SectionActions;
  customSearchSize?: number;
  avoidSearchRedirect?: boolean;
  exportThisIdAsFirstElement?: string;
  homeInAnotherNavigationItem?: boolean;
  body?: string;
  auditTypes?: string[];
  searchResultsUniqByParameter?: string;
  enableFabToggle?: boolean;
  fabActionsOrder?: string[];
}

export interface SectionModelMap {
  [id: string]: SectionModel;
}

export interface SectionModel {
  config?: SectionConfig;
  tabs?: Tab;
  apiCalls?: ApiCallPayload | ((detail: any) => ApiCallPayload);
  subSections?: SectionModelMap;
  defaultTab?: string;
  stepper?: StepperSettings;
  search?: SearchSettings;
  /**
   * ### Home configuration for the section.
   * Useful to create a custom home page for the section when a search page is not needed.
   *
   * Consider to use a `SectionConfig.homeRedirect` in the section configuration to redirect to the homePage,
   * so user will land in the home page when clicking in the section in the navigation menu.
   */
  homeConfig?: HomeConfig<any>;
}

export interface SearchSettings {
  searchSection?: string;
  datatable?: (
    tservice: TranslateService,
    tloader: FuseTranslationLoaderService,
    store: Store<fromRoot.RootState>,
    newItem?: Store<fromForm.NewSectionItemState>,
    permissions?: PermissionsService,
  ) => DataTableBuilder;
  cleaner?: SearchItemCleaner;
  form?: (
    fuseTranslateService: TranslateService,
    fuseTranslationLoaderService: FuseTranslationLoaderService,
    searchService?: SearchService,
    store?: Store<any>,
    fieldValidatorService?: FieldValidatorService,
  ) => FormConfig;
}

export interface SearchItemCleaner {
  parse: (items: any[], translate: TranslateService, extraParams?: any, extraItems?: string[], store?: Store<any>) => any;
}

export interface HomeConfig<K = SectionHomeConfig> {
  content: K;
  initialModel: any;
  location?: string;
  apiCalls?: ApiCallPayload;
  cleaner: any;
}

export interface SectionHomeConfig {
  getConf(): IceGroupComponent[];
}

export interface Tab {
  [id: string]: SectionConfigTab<any, any>;
}

export interface SectionActions {
  newItem: boolean;
  editItem?: 'stepper' | 'tab';
  newNote: boolean;
  newCounterClaim?: boolean;
  removeItem: boolean;
  newClaim?: boolean;
  workEditActions?: boolean;
  createClaimReadMode?: boolean;
  workEditBySociety?: boolean;
  alternativeUpdateLabel?: string; // Label for button in button-labels.ts translation, if exist the button is showed
  enableUser?: boolean;
  disableUser?: boolean;
  clone?: boolean;
  createRemoteRod?: boolean;
  trimClaims?: boolean;
}

export const SectionsConfig = {
  WORKS: SectionCopyrightWorks,
  IPS: SectionCopyrightIps,
  AGREEMENTS: SectionCopyrightAgreements,
  AGREEMENT_GROUP: SectionAgreementGroup,
  REPERTOIRES: SectionCopyrightRepertoires,
  SOCIETIES: SectionCopyrightSocieties,
  TERRITORIES: SectionCopyrightTerritories,
  REPORTS: SectionCopyrightReports,
  CONFLICTS: SectionConflictsActivity,
  AGREEMENTCONFLICT: SectionConflictsAgreementConflict,
  COUNTERCLAIMS_ACTIONS: SectionConflictsCounterClaimsActions,
  COUNTERCLAIMS: SectionConflictsCounterClaims,
  COUNTERCLAIMSSUPPORT: SectionCounterClaimsSupport,
  ORGANIZATIONS: SectionUserManagementOrganizations,
  USERS: SectionUserManagementUsers,
  BULK_UPDATES: SectionCopyrightBulkUpdates,
  COUNTERCLAIM_NOTIFICATIONS: SectionConflictsCounterclaimNotifications,
};
