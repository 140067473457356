import { DictionaryCodeItem } from 'models/dictionaryCodeItem';

export const ipsSocietiesCodes: DictionaryCodeItem[] = [
  {
    code: '000',
    name: 'DP',
    isIce: false,
    status: 'member',
  },
  {
    code: '001',
    name: 'ACUM',
    isIce: false,
    status: 'member',
  },
  {
    code: '002',
    name: 'ADDAF',
    isIce: false,
    status: 'member',
  },
  {
    code: '003',
    name: 'AEPI',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '004',
    name: 'AGADU',
    isIce: false,
    status: 'member',
  },
  {
    code: '005',
    name: 'AKM',
    isIce: false,
    status: 'member',
  },
  {
    code: '006',
    name: 'BUCADA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '007',
    name: 'APDAYC',
    isIce: false,
    status: 'member',
  },
  {
    code: '008',
    name: 'APRA',
    isIce: false,
    status: 'member',
  },
  {
    code: '009',
    name: 'ARTISJUS',
    isIce: false,
    status: 'member',
  },
  {
    code: '010',
    name: 'ASCAP',
    isIce: false,
    status: 'member',
  },
  {
    code: '011',
    name: 'AUSTRO-MECHANA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '012',
    name: 'AMCOS',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '013',
    name: 'AWA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '014',
    name: 'ARGENTORES',
    isIce: false,
    status: 'member',
  },
  {
    code: '015',
    name: 'APA',
    isIce: false,
    status: 'member',
  },
  {
    code: '016',
    name: 'BUMDA',
    isIce: false,
    status: 'member',
  },
  {
    code: '017',
    name: 'AMRA',
    isIce: false,
    status: 'member',
  },
  {
    code: '018',
    name: 'BGDA',
    isIce: false,
    status: 'member',
  },
  {
    code: '019',
    name: 'BMDA',
    isIce: false,
    status: 'member',
  },
  {
    code: '020',
    name: 'SODRAC',
    isIce: false,
    status: 'member',
  },
  {
    code: '021',
    name: 'BMI',
    isIce: false,
    status: 'member',
  },
  {
    code: '022',
    name: 'MCSN',
    isIce: false,
    status: 'member',
  },
  {
    code: '023',
    name: 'BUMA',
    isIce: false,
    status: 'member',
  },
  {
    code: '024',
    name: 'BURIDA',
    isIce: false,
    status: 'member',
  },
  {
    code: '025',
    name: 'SODAV ',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '026',
    name: 'CASH',
    isIce: false,
    status: 'member',
  },
  {
    code: '027',
    name: 'CAPAC',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '028',
    name: 'LITA',
    isIce: false,
    status: 'member',
  },
  {
    code: '029',
    name: 'SCD',
    isIce: false,
    status: 'member',
  },
  {
    code: '030',
    name: 'AMAR',
    isIce: false,
    status: 'member',
  },
  {
    code: '031',
    name: 'DILIA',
    isIce: false,
    status: 'member',
  },
  {
    code: '032',
    name: 'FILSCAP',
    isIce: false,
    status: 'member',
  },
  {
    code: '033',
    name: 'OMDA',
    isIce: false,
    status: 'member',
  },
  {
    code: '034',
    name: 'HARRY FOX AGENCY',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '035',
    name: 'GEMA',
    isIce: true,
    status: 'member',
  },
  {
    code: '036',
    name: 'IPRS',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '037',
    name: 'BUBEDRA',
    isIce: false,
    status: 'member',
  },
  {
    code: '038',
    name: 'JASRAC',
    isIce: false,
    status: 'member',
  },
  {
    code: '039',
    name: 'MUSICAUTOR',
    isIce: false,
    status: 'member',
  },
  {
    code: '040',
    name: 'KODA',
    isIce: true,
    status: 'member',
  },
  {
    code: '041',
    name: 'LITERAR-MECHANA',
    isIce: false,
    status: 'member',
  },
  {
    code: '042',
    name: 'LVG',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '043',
    name: 'MCSK',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '044',
    name: 'MCPS',
    isIce: true,
    status: 'member',
    extraSocPermissions: ['SO52'],
  },
  {
    code: '045',
    name: 'BBDA',
    isIce: false,
    status: 'member',
  },
  {
    code: '046',
    name: 'MRS',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '047',
    name: 'BCDA',
    isIce: false,
    status: 'member',
  },
  {
    code: '048',
    name: 'NCB',
    isIce: true,
    status: 'member',
  },
  {
    code: '049',
    name: 'ONDA',
    isIce: false,
    status: 'member',
  },
  {
    code: '050',
    name: 'OSA',
    isIce: false,
    status: 'member',
  },
  {
    code: '051',
    name: 'PROLITTERIS',
    isIce: false,
    status: 'member',
  },
  {
    code: '052',
    name: 'PRS',
    isIce: true,
    status: 'member',
    extraSocPermissions: ['SO44'],
  },
  {
    code: '053',
    name: 'PROCAN',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '054',
    name: 'ALCS',
    isIce: false,
    status: 'member',
  },
  {
    code: '055',
    name: 'SABAM',
    isIce: true,
    status: 'member',
  },
  {
    code: '056',
    name: 'SACD',
    isIce: false,
    status: 'member',
  },
  {
    code: '057',
    name: 'SACERAU',
    isIce: false,
    status: 'member',
  },
  {
    code: '058',
    name: 'SACEM',
    isIce: false,
    status: 'member',
  },
  {
    code: '059',
    name: 'SACM',
    isIce: false,
    status: 'member',
  },
  {
    code: '060',
    name: 'SACVEN',
    isIce: false,
    status: 'member',
  },
  {
    code: '061',
    name: 'SADAIC',
    isIce: false,
    status: 'member',
  },
  {
    code: '062',
    name: 'SADEMBRA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '063',
    name: 'SAMRO',
    isIce: false,
    status: 'member',
  },
  {
    code: '064',
    name: 'SOKOJ',
    isIce: false,
    status: 'member',
  },
  {
    code: '065',
    name: 'SAYCE',
    isIce: false,
    status: 'member',
  },
  {
    code: '066',
    name: 'SBACEM',
    isIce: false,
    status: 'member',
  },
  {
    code: '067',
    name: 'SBAT',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '068',
    name: 'SDRM',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '069',
    name: 'SPA',
    isIce: false,
    status: 'member',
  },
  {
    code: '070',
    name: 'SOGEM',
    isIce: false,
    status: 'member',
  },
  {
    code: '071',
    name: 'SESAC',
    isIce: false,
    status: 'member',
  },
  {
    code: '072',
    name: 'SGAE',
    isIce: false,
    status: 'member',
  },
  {
    code: '073',
    name: 'SCAM',
    isIce: false,
    status: 'member',
  },
  {
    code: '074',
    name: 'SIAE',
    isIce: false,
    status: 'member',
  },
  {
    code: '075',
    name: 'SUISSIMAGE',
    isIce: false,
    status: 'member',
  },
  {
    code: '076',
    name: 'ACEMLA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '077',
    name: 'STEF',
    isIce: false,
    status: 'member',
  },
  {
    code: '078',
    name: 'STEMRA',
    isIce: false,
    status: 'member',
    extraSocPermissions: ['SO23'],
  },
  {
    code: '079',
    name: 'STIM',
    isIce: true,
    status: 'member',
  },
  {
    code: '080',
    name: 'SUISA',
    isIce: false,
    status: 'member',
  },
  {
    code: '081',
    name: 'SARRAL',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '082',
    name: 'OTDAV',
    isIce: false,
    status: 'member',
  },
  {
    code: '083',
    name: 'SONECA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '084',
    name: 'SAYCO',
    isIce: false,
    status: 'member',
  },
  {
    code: '085',
    name: 'SOZA',
    isIce: false,
    status: 'member',
  },
  {
    code: '086',
    name: 'SICAM',
    isIce: false,
    status: 'member',
  },
  {
    code: '087',
    name: 'SPACEM',
    isIce: false,
    status: 'notMemeber',
  },
  {
    code: '088',
    name: 'CMRRA',
    isIce: false,
    status: 'member',
  },
  {
    code: '089',
    name: 'TEOSTO',
    isIce: true,
    status: 'member',
  },
  {
    code: '090',
    name: 'TONO',
    isIce: true,
    status: 'member',
  },
  {
    code: '091',
    name: 'SSA',
    isIce: false,
    status: 'member',
  },
  {
    code: '092',
    name: 'SOCINADA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '093',
    name: 'UBC',
    isIce: false,
    status: 'member',
  },
  {
    code: '094',
    name: 'RAO',
    isIce: false,
    status: 'member',
  },
  {
    code: '095',
    name: 'VG WORT',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '096',
    name: 'COTT',
    isIce: false,
    status: 'member',
  },
  {
    code: '097',
    name: 'ZAIKS',
    isIce: false,
    status: 'member',
  },
  {
    code: '098',
    name: 'ZIMURA',
    isIce: false,
    status: 'member',
  },
  {
    code: '099',
    name: 'NS',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '101',
    name: 'SOCAN',
    isIce: false,
    status: 'member',
  },
  {
    code: '102',
    name: 'NASCAM',
    isIce: false,
    status: 'member',
  },
  {
    code: '103',
    name: 'ACDAM',
    isIce: false,
    status: 'member',
  },
  {
    code: '104',
    name: 'MACP',
    isIce: false,
    status: 'member',
  },
  {
    code: '105',
    name: 'MASA',
    isIce: false,
    status: 'member',
  },
  {
    code: '106',
    name: 'COMPASS',
    isIce: false,
    status: 'member',
  },
  {
    code: '107',
    name: 'ACAM',
    isIce: false,
    status: 'member',
  },
  {
    code: '108',
    name: 'CHA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '109',
    name: 'KCI',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '110',
    name: 'LATGA',
    isIce: false,
    status: 'member',
  },
  {
    code: '111',
    name: 'HDS-ZAMP',
    isIce: false,
    status: 'member',
  },
  {
    code: '112',
    name: 'SAZAS',
    isIce: false,
    status: 'member',
  },
  {
    code: '113',
    name: 'LAA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '114',
    name: 'AGAYC',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '115',
    name: 'UCMR-ADA',
    isIce: false,
    status: 'member',
  },
  {
    code: '116',
    name: 'EAU',
    isIce: false,
    status: 'member',
  },
  {
    code: '117',
    name: 'MESAM',
    isIce: false,
    status: 'member',
  },
  {
    code: '118',
    name: 'KOMCA',
    isIce: false,
    status: 'member',
  },
  {
    code: '119',
    name: 'MCSC',
    isIce: false,
    status: 'member',
  },
  {
    code: '120',
    name: 'LIRA',
    isIce: false,
    status: 'member',
  },
  {
    code: '121',
    name: 'VDFS',
    isIce: false,
    status: 'member',
  },
  {
    code: '122',
    name: 'AKKA-LAA',
    isIce: false,
    status: 'member',
  },
  {
    code: '123',
    name: 'COSGA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '124',
    name: 'COSOMA',
    isIce: false,
    status: 'member',
  },
  {
    code: '125',
    name: 'BNDA',
    isIce: false,
    status: 'member',
  },
  {
    code: '126',
    name: 'MCT',
    isIce: false,
    status: 'member',
  },
  {
    code: '127',
    name: 'ALBAUTOR',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '128',
    name: 'IMRO',
    isIce: false,
    status: 'member',
  },
  {
    code: '129',
    name: 'SOBODAYCOM',
    isIce: false,
    status: 'member',
  },
  {
    code: '130',
    name: 'BUTODRA',
    isIce: false,
    status: 'member',
  },
  {
    code: '131',
    name: 'SADA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '132',
    name: 'BILD-KUNST',
    isIce: false,
    status: 'member',
  },
  {
    code: '133',
    name: 'ZAMCOPS',
    isIce: false,
    status: 'member',
  },
  {
    code: '134',
    name: 'SLPRS',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '135',
    name: 'SADH',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '136',
    name: 'ZAMP',
    isIce: false,
    status: 'member',
  },
  {
    code: '137',
    name: 'SOFAM',
    isIce: false,
    status: 'member',
  },
  {
    code: '138',
    name: 'KOPIOSTO',
    isIce: false,
    status: 'member',
  },
  {
    code: '139',
    name: 'VISDA',
    isIce: false,
    status: 'member',
  },
  {
    code: '140',
    name: 'NGO-UACRR',
    isIce: false,
    status: 'member',
  },
  {
    code: '141',
    name: 'ATN',
    isIce: false,
    status: 'member',
  },
  {
    code: '142',
    name: 'DALRO',
    isIce: false,
    status: 'member',
  },
  {
    code: '143',
    name: 'TEATERAUTOR',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '144',
    name: 'HAA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '145',
    name: 'DIRECTORS UK',
    isIce: false,
    status: 'member',
  },
  {
    code: '146',
    name: 'SPAC',
    isIce: false,
    status: 'member',
  },
  {
    code: '147',
    name: 'FILMAUTOR',
    isIce: false,
    status: 'member',
  },
  {
    code: '148',
    name: 'ADAGP',
    isIce: false,
    status: 'member',
  },
  {
    code: '149',
    name: 'ARS',
    isIce: false,
    status: 'member',
  },
  {
    code: '150',
    name: 'BEELDRECHT',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '151',
    name: 'BONO',
    isIce: false,
    status: 'member',
  },
  {
    code: '152',
    name: 'BUS',
    isIce: false,
    status: 'member',
  },
  {
    code: '153',
    name: 'DACS',
    isIce: false,
    status: 'member',
  },
  {
    code: '154',
    name: 'HUNGART',
    isIce: false,
    status: 'member',
  },
  {
    code: '155',
    name: 'SOMAAP',
    isIce: false,
    status: 'member',
  },
  {
    code: '156',
    name: 'VAGA',
    isIce: false,
    status: 'member',
  },
  {
    code: '157',
    name: 'BILDRECHT',
    isIce: false,
    status: 'member',
  },
  {
    code: '158',
    name: 'VEGAP',
    isIce: false,
    status: 'member',
  },
  {
    code: '159',
    name: 'VISCOPY',
    isIce: false,
    status: 'member',
  },
  {
    code: '160',
    name: 'NCIP',
    isIce: false,
    status: 'member',
  },
  {
    code: '161',
    name: 'MUST',
    isIce: false,
    status: 'member',
  },
  {
    code: '163',
    name: 'APG-Japan',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '164',
    name: 'APSAV',
    isIce: false,
    status: 'member',
  },
  {
    code: '168',
    name: 'COPYRIGHT AGENCY',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '169',
    name: 'COSCAP',
    isIce: false,
    status: 'member',
  },
  {
    code: '170',
    name: 'CPSN',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '171',
    name: 'CREAIMAGEN',
    isIce: false,
    status: 'member',
  },
  {
    code: '172',
    name: 'DGA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '173',
    name: 'DIRECTORES',
    isIce: false,
    status: 'member',
  },
  {
    code: '174',
    name: 'FILMJUS',
    isIce: false,
    status: 'member',
  },
  {
    code: '175',
    name: 'CopyRo',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '176',
    name: 'JACAP',
    isIce: false,
    status: 'member',
  },
  {
    code: '177',
    name: 'KazAK',
    isIce: false,
    status: 'member',
  },
  {
    code: '178',
    name: 'KOSA',
    isIce: false,
    status: 'member',
  },
  {
    code: '179',
    name: 'KUVASTO',
    isIce: false,
    status: 'member',
  },
  {
    code: '180',
    name: 'MUSIKEDITION',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '182',
    name: 'PAPPRI',
    isIce: false,
    status: 'notMember',
  },

  {
    code: '183',
    name: 'SACK',
    isIce: false,
    status: 'member',
  },
  {
    code: '184',
    name: 'SARTEC',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '186',
    name: 'SGDL',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '189',
    name: 'SOCINPRO',
    isIce: false,
    status: 'member',
  },
  {
    code: '190',
    name: 'SOPE',
    isIce: false,
    status: 'member',
  },
  {
    code: '191',
    name: 'SPACQ',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '193',
    name: 'SOA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '195',
    name: 'VEVAM',
    isIce: false,
    status: 'member',
  },
  {
    code: '196',
    name: 'WGA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '198',
    name: 'ZAMP',
    isIce: false,
    status: 'member',
  },
  {
    code: '199',
    name: 'ZAPA',
    isIce: false,
    status: 'member',
  },
  {
    code: '200',
    name: 'MSG',
    isIce: false,
    status: 'member',
  },
  {
    code: '201',
    name: 'ABRAMUS',
    isIce: false,
    status: 'member',
  },
  {
    code: '202',
    name: 'ASDAC',
    isIce: false,
    status: 'member',
  },
  {
    code: '203',
    name: 'AWGACS',
    isIce: false,
    status: 'member',
  },
  {
    code: '204',
    name: 'GCA',
    isIce: false,
    status: 'member',
  },
  {
    code: '206',
    name: 'UFW',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '209',
    name: 'ARMAUTHOR',
    isIce: false,
    status: 'member',
  },
  {
    code: '210',
    name: 'ACCESS COPYRIGHT',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '212',
    name: 'CSCS',
    isIce: false,
    status: 'member',
  },
  {
    code: '213',
    name: 'DRCC',
    isIce: false,
    status: 'member',
  },
  {
    code: '214',
    name: 'ECCO',
    isIce: false,
    status: 'member',
  },
  {
    code: '215',
    name: 'KYRGYZPATENT',
    isIce: false,
    status: 'member',
  },
  {
    code: '216',
    name: 'SQN',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '219',
    name: 'ASSIM',
    isIce: false,
    status: 'member',
  },
  {
    code: '222',
    name: 'FONOPERU',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '223',
    name: 'COSOTA',
    isIce: false,
    status: 'member',
  },
  {
    code: '224',
    name: 'SOMAS',
    isIce: false,
    status: 'member',
  },
  {
    code: '225',
    name: 'SAIF',
    isIce: false,
    status: 'member',
  },
  {
    code: '226',
    name: 'AACIMH',
    isIce: false,
    status: 'member',
  },
  {
    code: '227',
    name: 'SGACEDOM',
    isIce: false,
    status: 'member',
  },
  {
    code: '229',
    name: 'ICG',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '230',
    name: 'ADAVIS',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '231',
    name: 'AUTVIS',
    isIce: false,
    status: 'member',
  },
  {
    code: '232',
    name: 'GESTOR',
    isIce: false,
    status: 'member',
  },
  {
    code: '233',
    name: 'SACEMLUXEMBOURG',
    isIce: false,
    status: 'member',
  },
  {
    code: '234',
    name: 'UPRS',
    isIce: false,
    status: 'member',
  },
  {
    code: '235',
    name: 'SACENC',
    isIce: false,
    status: 'member',
  },
  {
    code: '237',
    name: 'TALI',
    isIce: false,
    status: 'member',
  },
  {
    code: '238',
    name: 'BSCAP',
    isIce: false,
    status: 'member',
  },
  {
    code: '239',
    name: 'CMC',
    isIce: false,
    status: 'member',
  },
  {
    code: '240',
    name: 'DAMA',
    isIce: false,
    status: 'member',
  },
  {
    code: '241',
    name: 'NICAUTOR',
    isIce: false,
    status: 'member',
  },
  {
    code: '242',
    name: 'SACIM',
    isIce: false,
    status: 'member',
  },
  {
    code: '243',
    name: 'SADIA',
    isIce: false,
    status: 'member',
  },
  {
    code: '244',
    name: 'SASUR',
    isIce: false,
    status: 'member',
  },
  {
    code: '245',
    name: 'SETEM',
    isIce: false,
    status: 'member',
  },
  {
    code: '246',
    name: 'VCPMC',
    isIce: false,
    status: 'member',
  },
  {
    code: '247',
    name: 'IVARO',
    isIce: false,
    status: 'member',
  },
  {
    code: '248',
    name: 'DAC',
    isIce: false,
    status: 'member',
  },
  {
    code: '249',
    name: 'PAM CG',
    isIce: false,
    status: 'member',
  },
  {
    code: '250',
    name: 'AEI',
    isIce: false,
    status: 'member',
  },
  {
    code: '251',
    name: 'ASDACS',
    isIce: false,
    status: 'member',
  },
  {
    code: '253',
    name: 'AAS',
    isIce: false,
    status: 'member',
  },
  {
    code: '254',
    name: 'SOCILADRA',
    isIce: false,
    status: 'member',
  },
  {
    code: '256',
    name: 'PICTORIGHT',
    isIce: false,
    status: 'member',
  },
  {
    code: '257',
    name: 'SAVA',
    isIce: false,
    status: 'member',
  },
  {
    code: '258',
    name: 'MRCSN',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '259',
    name: 'SDCSI',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '260',
    name: 'ACS',
    isIce: false,
    status: 'member',
  },
  {
    code: '261',
    name: 'GAI UZ',
    isIce: false,
    status: 'member',
  },
  {
    code: '263',
    name: 'SACS',
    isIce: false,
    status: 'member',
  },
  {
    code: '265',
    name: 'MACA',
    isIce: false,
    status: 'member',
  },
  {
    code: '267',
    name: 'UPRAVIS',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '268',
    name: 'COSON',
    isIce: false,
    status: 'member',
  },
  {
    code: '269',
    name: 'WAMI',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '270',
    name: 'JASPAR',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '271',
    name: 'DHFR',
    isIce: false,
    status: 'member',
  },
  {
    code: '272',
    name: 'MOSCAP',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '273',
    name: 'AMUS',
    isIce: false,
    status: 'member',
  },
  {
    code: '274',
    name: 'AuPO CINEMA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '275',
    name: 'AUTODIA',
    isIce: false,
    status: 'member',
  },
  {
    code: '276',
    name: 'DASC',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '277',
    name: 'RSAU',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '278',
    name: 'RUR',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '279',
    name: 'SDADV',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '280',
    name: 'SANASTO',
    isIce: false,
    status: 'member',
  },
  {
    code: '282',
    name: 'UNAC-SA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '283',
    name: 'CAPASSO',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '284',
    name: 'COSOZA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '285',
    name: 'GHAMRO',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '286',
    name: 'ODDA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '287',
    name: 'KORRA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '288',
    name: 'ABYROY',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '289',
    name: 'AIPA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '290',
    name: 'AZDG',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '291',
    name: 'OFA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '292',
    name: 'ZPAP',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '293',
    name: 'DBCA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '294',
    name: 'REDES',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '295',
    name: 'SAGCRYT',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '296',
    name: 'DACIN-SARA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '297',
    name: 'GEDAR',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '298',
    name: 'OOA-S',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '299',
    name: 'SCM-COOPERATIVA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '302',
    name: 'LATINAUTOR',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '306',
    name: 'ACCS',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '319',
    name: 'ICE',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '322',
    name: 'EVA',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '778',
    name: 'GMR',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '779',
    name: 'POLARIS',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '793',
    name: 'NMP',
    isIce: false,
    status: 'notMember',
  },
  {
    code: '800',
    name: 'ICE ONLINE',
    isIce: false,
    status: 'notMember',
  },
];
