import {
  buildOptions,
  CC_STATUS_CLOSED,
  CC_STATUS_CONFIRMED_DISPUTE,
  CC_STATUS_POSS_OVERLAP,
  CC_STATUS_POTENTIAL_CONFLICT,
  CC_STATUS_RESOLVED,
  ClaimantType,
  ContributorsUtils,
  CopyrightUtils,
  CounterClaimClaimantRelation,
  CounterClaimClaimantStatus,
  IpUtils,
  RelationsUtils,
  SharePictureUtils,
  TerritoryUtils,
  WorkClaimsUtils,
} from '@ice';
import { TranslateService } from '@ngx-translate/core';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { tisnTisaConversionItems } from 'assets/ts/tisnTisaConversionItems';
import {
  ActionResponse,
  ActionStatus,
  ActionType,
  ConflictSubType,
  CounterClaimResolutionOwnerType,
  CounterClaimType,
  GOTOWORK_CONFLICTSUBTYPES,
} from 'config/constants/activity.constants';
import { END_DATE_NO_DISPLAY, ICE, MIN_START_DATE } from 'config/constants/global.constants';
import { iceCustomerSocieties, mrRightsSet, prRightsSet } from 'config/constants/ips.constants';
import { ALL_RIGHTS, WORK_CLAIM_SHARE_TYPE } from 'config/constants/shares.constants';
import { INCLUDE_WORLD_IN_EX_TISN, TerritoryDataType } from 'config/constants/territories.constants';
import { WORK_RELATION_TYPE } from 'config/constants/works.constants';
import { every, find, flatMap, flattenDeep, get, groupBy, has, intersection, isArray, isEmpty, padStart, pickBy, some, sortBy, uniq, compact } from 'lodash';
import { ActivityTriggerAttributes, CounterClaimConflictCondition, PointOfConflict, PointOfConflictTypes, TitlesInterface, WorkDetail } from 'models';
import { ActivityDetail, CaseWorkDetail } from 'models/copyright/detail/activity';
import { CounterClaimDetail } from 'models/copyright/detail/counterclaim';
import { EditAction } from 'models/copyright/detail/edit';
import { OptionsGroup } from 'models/options-group';
import moment from 'moment';
import { ClaimantUtils } from '../claimant/claimant.utils';
import { DateTimeUtils } from '../date-time/date-time.utils';
import { SocietiesUtils } from '../societies/societies.utils';
import { WorkUtils } from '../work/work.utils';

export class ConflictUtils {
  static getStatusOptions(translate) {
    return [
      { value: '', label: '' },
      { label: translate.instant('COUNTER.AWAITING_REPLY'), value: CounterClaimClaimantStatus.AWAITING_REPLY },
      { label: translate.instant('COUNTER.SUPPORTED'), value: CounterClaimClaimantStatus.SUPPORTED },
      { label: translate.instant('COUNTER.CONFIRMED'), value: CounterClaimClaimantStatus.CONFIRMED },
      { label: translate.instant('COUNTER.RESOLVED'), value: CounterClaimClaimantStatus.RESOLVED },
    ];
  }

  static getActionStatusOptions(translate): OptionsGroup[] {
    return [
      {
        header: translate.instant('COUNTER.ALL_STATUSES'),
        options: [
          { label: translate.instant('COUNTER.OPEN'), value: ActionStatus.OPEN },
          { label: translate.instant('COUNTER.CLOSED'), value: ActionStatus.CLOSED },
          { label: translate.instant('COUNTER.EXPIRED'), value: ActionStatus.EXPIRED },
        ],
      },
    ];
  }

  static getConflictTypeOptions(translate) {
    const options = [{ value: '', label: '' }];
    for (const conflictType of Object.values(CounterClaimType)) {
      if (
        ![CounterClaimType.OWNERSHIP_CONFIRMED, CounterClaimType.OWNERSHIP_NOT_CONFIRMED, CounterClaimType.AGREEMENT_OVERLAP, CounterClaimType.AGREEMENT_MANUAL_CONFLICT].includes(
          conflictType,
        )
      ) {
        options.push({ value: conflictType, label: translate.instant(`COUNTER.${conflictType}`) });
      }
    }
    return options;
  }

  static getWorkTitle(activity: ActivityDetail): string {
    let workId = get(
      find(get(activity, 'works[0].work.relations', []), relation => relation.relation === WORK_RELATION_TYPE.MATCH),
      'otherId',
    );
    if (!workId) {
      workId = get(activity, 'works[0].workId');
    }
    return workId ? `${workId}` : '';
  }

  static getResolutionOwnerOptions() {
    return buildOptions(
      ipsSocietiesCodes.filter(ipsSociety => ipsSociety.isIce && !['044', '048'].includes(ipsSociety.code)).map(ipsSociety => `${ipsSociety.code} ${ipsSociety.name}`),
    );
  }

  static getActivityConflicts(conflictData) {
    return {
      instruments: conflictData.some(data => !isEmpty(get(data, 'conflict.attributes.data.instruments', []))),
      instrumentations: conflictData.some(data => !isEmpty(get(data, 'conflict.attributes.data.standardInstrumentation', []))),
      overrides: conflictData.some(data => !isEmpty(get(data, 'conflict.attributes.data.copyright_overrides', []))),
    };
  }

  static calculatePath(path, claimId, parentMap, ipiNameMap) {
    const parent = parentMap.get(claimId);
    if (parent) {
      return this.calculatePath([...path, ipiNameMap.get(claimId)], parent, parentMap, ipiNameMap);
    }
    return [...path, ipiNameMap.get(claimId)];
  }

  static sortByPath(claims, index) {
    return [
      ...this.sortBySimilarity(claims.filter(claim => claim.path.length === index - 1)),
      ...Object.values(
        groupBy(
          claims.filter(claim => claim.path.length >= index),
          claim => claim.path[claim.path.length - index],
        ),
      )
        .map(claimGroup => {
          if (claimGroup.length > 1) {
            return this.sortByPath(claimGroup, index + 1);
          } else {
            return this.sortBySimilarity(claimGroup);
          }
        })
        .reduce((acc, it) => [...acc, ...it], []),
    ];
  }

  static sortBySimilarity(claims) {
    const masteredClaims = claims.filter(claim => claim.sourceWork === 'mastered');
    const compareClaims = claims.filter(claim => claim.sourceWork === 'compare');
    let masteredMatchedClaim = masteredClaims[0];
    const compareSortedClaims = sortBy(
      compareClaims.map(compareClaim => {
        let matchScore = 0;
        const sameTerritoriesmasteredClaims = masteredClaims.filter(masteredClaim => masteredClaim.territories.join() === compareClaim.territories.join());
        if (sameTerritoriesmasteredClaims.length > 0) {
          matchScore = 1;
          masteredMatchedClaim = sameTerritoriesmasteredClaims[0];
          const sameTerritoriesRightTypesClaims = sameTerritoriesmasteredClaims.filter(
            masteredClaim =>
              compareClaim.mrRights &&
              compareClaim.prRights &&
              masteredClaim.mrRights &&
              masteredClaim.prRights &&
              masteredClaim.mrRights.join() === compareClaim.mrRights.join() &&
              masteredClaim.prRights.join() === compareClaim.prRights.join(),
          );
          if (sameTerritoriesRightTypesClaims.length > 0) {
            matchScore = 2;
            masteredMatchedClaim = sameTerritoriesRightTypesClaims[0];
            const sameTerritoriesRightTypesDatesClaims = sameTerritoriesRightTypesClaims.filter(
              masteredClaim => masteredClaim.startDate === compareClaim.startDate && masteredClaim.endDate === compareClaim.endDate,
            );
            if (sameTerritoriesRightTypesDatesClaims.length > 0) {
              masteredMatchedClaim = sameTerritoriesRightTypesDatesClaims[0];
              matchScore = 3;
              const sameTerritoriesRightTypesDatesSharesClaims = sameTerritoriesRightTypesDatesClaims.filter(
                masteredClaim => masteredClaim.pr === compareClaim.pr && masteredClaim.mr === compareClaim.mr,
              );
              if (sameTerritoriesRightTypesDatesSharesClaims.length > 0) {
                matchScore = 4;
                masteredMatchedClaim = sameTerritoriesRightTypesDatesSharesClaims[0];
              }
            }
          }
        }
        return { ...compareClaim, matchScore };
      }),
      'matchScore',
    );

    const masteredSortedClaims =
      (masteredMatchedClaim &&
        sortBy(masteredClaims, (masteredClaim, index) => {
          const indexMatched = masteredClaims.indexOf(masteredMatchedClaim);
          const indexmastered = masteredClaims.indexOf(masteredClaim);
          return indexMatched === indexmastered ? 0 : 1;
        })) ||
      masteredClaims;

    return [
      ...masteredSortedClaims.map((masteredClaim, index) => {
        return {
          mastered: masteredClaim,
          compare: index < compareSortedClaims.length ? compareSortedClaims[index] : null,
        };
      }),
      ...compareSortedClaims.slice(masteredSortedClaims.length, compareSortedClaims.length).map(compareClaim => ({ mastered: null, compare: compareClaim })),
    ];
  }

  static filterClaims(claims, filterClaims, sourceWork, translate, claimType = null) {
    const parentMap = new Map();
    const ipiNameMap = new Map();
    const claimRightGroupList = claims
      .map(claim => {
        const partyName = claim.claimant && claim.claimant.partyName;
        const societies = claim.claimant?.party?.societies || [];
        const partyRelations = get(claim, 'claimant.party.relations');
        const claimIPIName = partyName && partyName.relations && IpUtils.selectIPINumber(partyName.relations);
        const claimIPIKey = partyName && partyName.relations && IpUtils.selectIpsKey(partyName.relations, '');
        const roleTooltip = claim.role ? ContributorsUtils.getRoleLabelFromRoleValue(claim.role) : '';
        const claimsFilter = {
          territory: filterClaims.territory,
          prRight: filterClaims.prRightType === ALL_RIGHTS ? null : filterClaims.prRightType,
          mrRight: filterClaims.mrRightType === ALL_RIGHTS ? null : filterClaims.mrRightType,
          usageDate: filterClaims.usageDate,
        };
        const { societyCodePr, societyCodePrTooltip, societyCodePrIcon, societyCodeMr, societyCodeMrTooltip, societyCodeMrIcon } = WorkClaimsUtils.getSocietiesCodes({
          societies,
          claimsFilter,
        });

        // To conserve space on these narrow tables, we use a truncated version
        // of the usual societyCode display values. PRS:052 → 052
        const trimmedSocietyCodePr = societyCodePr.split(':')[1];
        const trimmedSocietyCodeMr = societyCodeMr.split(':')[1];

        const claimProperties = {
          roleRaw: claim.role,
          name: partyName && partyName.attributes && IpUtils.getIpFullName(partyName.attributes),
          ipiBaseNameKey: partyRelations && RelationsUtils.getKeySuffixFromRelation(partyRelations.find(elem => elem.otherId.indexOf('IPI') === 0)),
          parentId: claim.parentId,
          claimId: claim.claimId,
          ipiName: claimIPIName,
          ipiNameKey: claimIPIKey,
          roleTooltip,
          societyCodePr: trimmedSocietyCodePr,
          societyCodePrTooltip,
          societyCodePrIcon,
          societyCodeMr: trimmedSocietyCodeMr,
          societyCodeMrTooltip,
          societyCodeMrIcon,
        };
        return Object.values(
          groupBy(
            [
              ...(((!claimType || claimType === WORK_CLAIM_SHARE_TYPE.COLLECTION) && claim.claimShares) || []),
              ...(((!claimType || claimType === WORK_CLAIM_SHARE_TYPE.OWNERSHIP) && claim.ownershipShares) || []),
            ],
            claimShare =>
              `${Array.isArray(claimShare.territories) && claimShare.territories.join()}${claimShare.endDate}${claimShare.startDate}${claimShare.postTermCollectionDate}`,
          ) || [],
        ).map(groupedClaims => {
          const shares: any = groupBy(this.createFormattedGroupedClaims(groupedClaims), 'type');
          const unauthorized: boolean[] = groupedClaims.map(claimShare => claimShare.unauthorized);
          const tisnTerritories = (shares.mr && shares.mr[0] && shares.mr[0].territories) || (shares.pr && shares.pr[0] && shares.pr[0].territories);
          const tisaTerritories = TerritoryUtils.convertTerritoryStringElements(tisnTerritories, TerritoryDataType.TISA);
          const isIncomeParticipant = claim?.extensions?.incomeParticipant?.[0] === 'true';
          const counterClaimIcon = claim.hasNotResolvedCounterclaim ? CopyrightUtils.getCounterclaimIconWithTooltip() : undefined;
          const hasUnauthorized = unauthorized.some(Boolean);
          const unauthorizedIcon = hasUnauthorized ? CopyrightUtils.getUnauthorizedIconWithTooltip(translate) : undefined;
          const incomeParticipantIcon = isIncomeParticipant ? CopyrightUtils.getIncomeparticipantIconWithTooltip(translate) : undefined;
          const alertIcons = compact(flattenDeep([incomeParticipantIcon, unauthorizedIcon, counterClaimIcon]));

          return {
            ...claimProperties,
            pr: (shares.pr && shares.pr[0] && shares.pr[0].share) || 0,
            mr: (shares.mr && shares.mr[0] && shares.mr[0].share) || 0,
            territories: tisaTerritories,
            tisnTerritories,
            endDate: (shares.mr && shares.mr[0] && shares.mr[0].endDate) || (shares.pr && shares.pr[0] && shares.pr[0].endDate),
            startDate: (shares.mr && shares.mr[0] && shares.mr[0].startDate) || (shares.pr && shares.pr[0] && shares.pr[0].startDate),
            priorRoyaltiesDate: (shares.mr && shares.mr[0] && shares.mr[0].priorRoyaltiesDate) || (shares.pr && shares.pr[0] && shares.pr[0].priorRoyaltiesDate),
            postTermCollectionDate: (shares.mr && shares.mr[0] && shares.mr[0].postTermCollectionDate) || (shares.pr && shares.pr[0] && shares.pr[0].postTermCollectionDate),
            mrRights: shares.mr && shares.mr[0].rightTypes,
            prRights: shares.pr && shares.pr[0] && shares.pr[0].rightTypes,
            sourceWork,
            unauthorized,
            alert: isIncomeParticipant || hasUnauthorized,
            alertIcons,
            hasNotResolvedCounterclaim:
              (shares.mr && shares.mr[0] && shares.mr[0].hasNotResolvedCounterclaim) || (shares.pr && shares.pr[0] && shares.pr[0].hasNotResolvedCounterclaim),
          };
        });
      })
      .map(claimRightGroup =>
        Object.values(
          groupBy(
            claimRightGroup,
            claimRight =>
              `${claimRight.mrRights && claimRight.mrRights.join()}${claimRight.prRights && claimRight.prRights.join()}${claimRight.mr}${claimRight.pr}${claimRight.endDate}${
                claimRight.startDate
              }${claimRight.postTermCollectionDate}`,
          ),
        ).map(claimRowGroup => {
          const territories = claimRowGroup.reduce((acc, it) => [...((acc && acc.territories) || []), ...((it && it.territories) || [])], []);
          const tisnTerritories = claimRowGroup.reduce((acc, it) => [...((acc && acc.tisnTerritories) || []), ...((it && it.tisnTerritories) || [])], []);
          const territoriesTooltip = TerritoryUtils.getTerritoriesNamesTooltipText(TerritoryUtils.convertTerritoryArrayElements(tisnTerritories, TerritoryDataType.NAME));
          return {
            ...claimRowGroup[0],
            territories,
            mr: `${((claimRowGroup[0].mr || 0) / 100).toFixed(2)} %`,
            pr: `${((claimRowGroup[0].pr || 0) / 100).toFixed(2)} %`,
            territoriesLabel: territories.join(', '),
            territoriesTooltip,
            mrRightsLabel: (claimRowGroup[0].mrRights || ['NONE']).join(', '),
            prRightsLabel: (claimRowGroup[0].prRights || ['NONE']).join(', '),
            rowClass: claimRowGroup[0].hasNotResolvedCounterclaim ? 'unresolved-cc-row' : '',
            alertIcon: claimRowGroup[0].hasNotResolvedCounterclaim ? CopyrightUtils.getCounterclaimIconWithTooltip() : null,
          };
        }),
      )
      .reduce((acc, it) => [...acc, ...it], [])
      .filter(shareClaim => {
        return this.filterShareClaim(shareClaim, filterClaims);
      });
    claimRightGroupList.forEach(claim => {
      parentMap.set(claim.claimId, claim.parentId);
      ipiNameMap.set(claim.claimId, claim.ipiBaseNameKey);
    });
    const claimsResponse = claimRightGroupList.map(claim => {
      const claimPath = this.calculatePath([], claim.claimId, parentMap, ipiNameMap);
      return { ...claim, role: `${'&nbsp;'.repeat(claimPath.length - 1)}${claim.roleRaw}`, path: claimPath };
    });
    return claimsResponse;
  }

  static createFormattedGroupedClaims(groupedClaims) {
    // Related to the bug https://ice-cube.atlassian.net/browse/CAR-368 duplicate claimShares when the claimShare has PR and MR Rights
    const formattedGroupedClaims = [];
    groupedClaims.forEach(claimShare => {
      const { share, territories, rightTypes, endDate, startDate, priorRoyaltiesDate, postTermCollectionDate, hasNotResolvedCounterclaim } = claimShare;
      const mrRightsList = rightTypes.filter(right => mrRightsSet.has(right));
      const prRightsList = rightTypes.filter(right => prRightsSet.has(right));
      const commonPropertiesObject = {
        share,
        territories,
        rightTypes: mrRightsList,
        endDate,
        startDate,
        priorRoyaltiesDate,
        postTermCollectionDate,
        hasNotResolvedCounterclaim,
        type: 'mr',
      };

      if (mrRightsList.length > 0) {
        formattedGroupedClaims.push({ ...commonPropertiesObject, rightTypes: mrRightsList, type: 'mr' });
      }
      if (prRightsList.length > 0) {
        formattedGroupedClaims.push({ ...commonPropertiesObject, rightTypes: prRightsList, type: 'pr' });
      }
    });
    return formattedGroupedClaims;
  }

  static filterShareClaimByTerritory(shareClaim, filterTerritories): boolean {
    return this.getTerritories(shareClaim).includes(parseFloat(filterTerritories.value));
  }

  static filterShareClaimByTerritoryArray(shareClaim, filterTerritories): boolean {
    const shareTisNTerritories = TerritoryUtils.flatTerritories(shareClaim.tisnTerritories || []);
    const match = intersection(
      shareTisNTerritories.map(tisn => Number(tisn)),
      filterTerritories.map(tisn => Number(tisn)),
    );
    return match.length > 0;
  }

  private static getTerritories(shareClaim: any) {
    return shareClaim.territoriesLabel
      .replace(/\+|-/g, '')
      .split(',')
      .map(territory => {
        if (parseFloat(territory) <= 2136 && parseFloat(territory) >= 2100) {
          const findTerritories = (tisnTisaConversionItems.find(tisnTisa => tisnTisa.tisn === territory) || ({} as any)).tisnGroup || [];
          return [...findTerritories, territory];
        } else {
          return [territory];
        }
      })
      .reduce((acc, it) => [...acc, ...it], []);
  }

  static filterShareClaimRemove(shareClaim: any, filterChecked: boolean, type: string): boolean {
    return shareClaim.roleRaw.includes(type) && filterChecked ? false : true;
  }

  static filterShareClaim(shareClaim, filterClaims) {
    let isOutDatedClaim = moment(shareClaim.postTermCollectionDate).isBefore(moment());
    if (!filterClaims) {
      return !isOutDatedClaim;
    } else {
      let match = true;
      const filterProperties = Object.keys(filterClaims);
      filterProperties.forEach(property => {
        const currentFilter = filterClaims[property] || '';
        if (match && !(currentFilter.value && currentFilter.value === 'ALL') && currentFilter !== 'ALL') {
          switch (property) {
            case 'territory':
              if (isArray(currentFilter)) {
                match = this.filterShareClaimByTerritoryArray(shareClaim, currentFilter);
              } else {
                match = this.filterShareClaimByTerritory(shareClaim, currentFilter);
              }
              break;
            case 'prRightType':
              if (match) {
                if (isArray(currentFilter)) {
                  match = currentFilter.includes('ALL') || intersection(shareClaim.prRights, currentFilter).length > 0;
                } else {
                  match = shareClaim.prRights && shareClaim.prRights.includes(currentFilter);
                }
              }
              break;
            case 'mrRightType':
              if (match) {
                if (isArray(currentFilter)) {
                  match = currentFilter.includes('ALL') || intersection(shareClaim.mrRights, currentFilter).length > 0;
                } else {
                  match = shareClaim.mrRights && shareClaim.mrRights.includes(currentFilter);
                }
              }
              break;
            case 'usageDate':
              if (match) {
                match =
                  moment(currentFilter).isSameOrBefore(shareClaim.endDate || END_DATE_NO_DISPLAY) && moment(currentFilter).isSameOrAfter(shareClaim.startDate || MIN_START_DATE);
              }
              break;
            case 'distributionDate':
              if (match) {
                match = moment(currentFilter).isBefore(shareClaim.postTermCollectionDate) && moment(currentFilter).isAfter(shareClaim.priorRoyaltiesDate);
              }
              break;
            case 'mrSociety':
              if (match) {
                match = shareClaim.mrSociety && shareClaim.mrSociety.includes(currentFilter.value);
              }
              break;
            case 'prSociety':
              if (match) {
                match = shareClaim.prSociety && shareClaim.prSociety.includes(currentFilter.value);
              }
              break;
            case 'addOutdated':
              if (match) {
                if (currentFilter) {
                  isOutDatedClaim = false;
                }
              }
              break;
            case 'removeSE':
              match = this.filterShareClaimRemove(shareClaim, currentFilter, 'SE');
              break;
            case 'removeE':
              match = this.filterShareClaimRemove(shareClaim, currentFilter, 'E');
              break;
          }
        }
      });
      return match;
    }
  }

  static loadHeaderAlternativeTitlesAndXrefs(workDetail: WorkDetail, translate: TranslateService): CaseWorkDetail {
    const { relations, claims, conflicts, contributions, partyNames } = workDetail;
    let manuscriptData = [];
    if (contributions && translate) {
      manuscriptData = SharePictureUtils.getManuscriptData(contributions, translate);
    }
    const workDetailCleaned: CaseWorkDetail = WorkUtils.getWorkWithIswcAndDuration(workDetail, translate);
    const { titles } = workDetailCleaned;
    if (titles) {
      const firstTitle = WorkUtils.selectTitle(titles);
      workDetailCleaned.title = firstTitle && firstTitle.title;
      const alternativeTitles = WorkUtils.getTitles(titles.AT, 'AT');
      if (alternativeTitles) {
        if (firstTitle.type === 'AT') {
          if (alternativeTitles.length > 1) {
            workDetailCleaned.alternativeTitle1 = alternativeTitles[1].title;
          }
          if (alternativeTitles.length > 2) {
            workDetailCleaned.alternativeTitle2 = alternativeTitles[2].title;
          }
        } else {
          if (alternativeTitles.length > 0) {
            workDetailCleaned.alternativeTitle1 = alternativeTitles[0].title;
          }
          if (alternativeTitles.length > 1) {
            workDetailCleaned.alternativeTitle2 = alternativeTitles[1].title;
          }
        }
      }
    }
    if (relations) {
      const xrefs = WorkUtils.getWorkToCompareXrefsIdsOrdered(workDetail.relations);
      if (xrefs.length > 1) {
        workDetailCleaned.xref1 = xrefs[1].value;
      }
      if (xrefs.length > 2) {
        workDetailCleaned.xref2 = xrefs[2].value;
      }
    }
    return { ...workDetailCleaned, relations, claims, conflicts, manuscriptData, partyNames };
  }

  // ARE: It looks unsed method. keep commented to restore if it's necessary
  // static workToCompareOtherId(relations, iteratees = ['viewValue'], orders = ['desc'], conflictType: string): any[] {
  //   const mergeConflict = 'MERGE' === conflictType;
  //   relations = relations.filter(elem => (!mergeConflict && elem.relation === 'POSS') || (mergeConflict && elem.relation === 'MCFL'));
  //   const transformedRelations = relations.map(relation => {
  //     const value = relation.otherId;
  //     return { value, viewValue: value };
  //   });
  //   const orderedTransformedRelations = orderBy(transformedRelations, iteratees, orders);
  //   return orderedTransformedRelations;
  // }

  static getTitlesList(titles) {
    return WorkUtils.getWorkAlternativeTitles(titles || [])
      .sort()
      .map(title => {
        return { ...title, durationFormatted: DateTimeUtils.getDurationHoursFormat(title.duration) };
      });
  }

  static getPointOfConflictAutoOption(type: CounterClaimType, counterClaimConflictConditionList: CounterClaimConflictCondition[], pointOfConflictAllOptions): any {
    const pointOfConflictConditionMap = ClaimantUtils.getPointOfConflictConditionMap();
    const pointOfConflictAvailableOptions = Object.keys(
      pickBy(
        pointOfConflictConditionMap[type],
        (counterClaimConflictConditions: CounterClaimConflictCondition[][]) =>
          !counterClaimConflictConditions.length ||
          counterClaimConflictConditions.filter((conflict: CounterClaimConflictCondition[]) => intersection(counterClaimConflictConditionList, conflict).length === conflict.length)
            .length,
      ),
    ) as PointOfConflict[];
    const autoOption = pointOfConflictAllOptions.find(pointOfConflictOption => pointOfConflictAvailableOptions.includes(pointOfConflictOption.value));
    return autoOption && autoOption.value;
  }

  static getAvailablePointOfConflictByType(type): PointOfConflictTypes[] {
    switch (type) {
      case CounterClaimType.AUTHORSHIP_CLAIM:
        return ['NEW_CREATORS', 'BETWEEN_CREATORS'];
      case CounterClaimType.OWNERSHIP_CLAIM:
      case CounterClaimType.ORIGINAL_CLAIM:
      case CounterClaimType.NO_AGREEMENT_CLAIM:
      case CounterClaimType.BREACH_OF_CONTRACT_CLAIM:
        return ['WRITER_AND_ORIGINAL_PUBLISHER', 'PUBLISHER_AND_SUBPUBLISHER'];
      case CounterClaimType.SAMPLE_CLAIM:
      case CounterClaimType.AUTHOR_SHARE_CLAIM:
        return ['BETWEEN_CREATORS'];
      case CounterClaimType.INFRINGEMENT_CLAIM:
        return ['INFRINGED_PARTIES'];
    }
  }

  static getPointOfConflictOptions(translate: TranslateService, type: CounterClaimType): Array<{ label: string; value: PointOfConflict }> {
    const pointOfConflictAllOptions = this.getAllPointOfConflictOptions(translate);
    const availableOptions = this.getAvailablePointOfConflictByType(type);
    return pointOfConflictAllOptions.filter(pointOfConflictOption => availableOptions.includes(pointOfConflictOption.value));
  }

  static getAllPointOfConflictOptions(translate: TranslateService) {
    return [
      { label: translate.instant('COUNTERCLAIMS.POINT_OF_CONFLICT.BC'), value: PointOfConflict.BETWEEN_CREATORS },
      { label: translate.instant('COUNTERCLAIMS.POINT_OF_CONFLICT.WAP'), value: PointOfConflict.WRITER_AND_ORIGINAL_PUBLISHER },
      { label: translate.instant('COUNTERCLAIMS.POINT_OF_CONFLICT.PASP'), value: PointOfConflict.PUBLISHER_AND_SUBPUBLISHER },
      { label: translate.instant('COUNTERCLAIMS.POINT_OF_CONFLICT.IP'), value: PointOfConflict.INFRINGED_PARTIES },
      { label: translate.instant('COUNTERCLAIMS.POINT_OF_CONFLICT.NC'), value: PointOfConflict.NEW_CREATORS },
    ];
  }

  static getAllConflictsPointOfConflictOptions(translate: TranslateService) {
    return [
      { value: '', label: '' },
      { label: translate.instant('ACTIVITY.POINT_OF_CONFLICT_TYPES.BC'), value: PointOfConflict.BETWEEN_CREATORS },
      { label: translate.instant('ACTIVITY.POINT_OF_CONFLICT_TYPES.WAP'), value: PointOfConflict.WRITER_AND_ORIGINAL_PUBLISHER },
      { label: translate.instant('ACTIVITY.POINT_OF_CONFLICT_TYPES.PASP'), value: PointOfConflict.PUBLISHER_AND_SUBPUBLISHER },
      { label: translate.instant('ACTIVITY.POINT_OF_CONFLICT_TYPES.IP'), value: PointOfConflict.INFRINGED_PARTIES },
      { label: translate.instant('ACTIVITY.POINT_OF_CONFLICT_TYPES.NC'), value: PointOfConflict.NEW_CREATORS },
    ];
  }

  static getCounterClaimRelationTypeOptions(translate: TranslateService) {
    return [
      { value: '', label: '' },
      { label: translate.instant('ACTIVITY.CLAIMANT_RELATION_TYPES.FIRST'), value: CounterClaimClaimantRelation.FIRST },
      { label: translate.instant('ACTIVITY.CLAIMANT_RELATION_TYPES.INFORMED'), value: CounterClaimClaimantRelation.INFORMED },
      { label: translate.instant('ACTIVITY.CLAIMANT_RELATION_TYPES.NEW'), value: CounterClaimClaimantRelation.NEW },
      { label: translate.instant('ACTIVITY.CLAIMANT_RELATION_TYPES.RESOLVER'), value: CounterClaimClaimantRelation.RESOLVER },
    ];
  }

  static getCounterClaimsTypesOptions(selectedClaims, translate: TranslateService): any[] {
    const allCreators = every(selectedClaims, claim => ClaimantUtils.getClaimantType(claim) === ClaimantType.CREATOR);
    if (allCreators) {
      return [
        { label: translate.instant('COUNTERCLAIMS.TYPES.AU'), value: CounterClaimType.AUTHORSHIP_CLAIM },
        { label: translate.instant('COUNTERCLAIMS.TYPES.SA'), value: CounterClaimType.SAMPLE_CLAIM },
        { label: translate.instant('COUNTERCLAIMS.TYPES.AS'), value: CounterClaimType.AUTHOR_SHARE_CLAIM },
        { label: translate.instant('COUNTERCLAIMS.TYPES.IN'), value: CounterClaimType.INFRINGEMENT_CLAIM },
      ];
    }
    const allPublishers = every(selectedClaims, claim => ClaimantUtils.getClaimantType(claim) !== ClaimantType.CREATOR);
    const allNewClaimant = !some(selectedClaims, claim => claim.claimantStatus === CounterClaimClaimantRelation.FIRST);
    if (allPublishers) {
      return [
        { label: translate.instant('COUNTERCLAIMS.TYPES.OW'), value: CounterClaimType.OWNERSHIP_CLAIM },
        ...(allNewClaimant ? [{ label: translate.instant('COUNTERCLAIMS.TYPES.OR'), value: CounterClaimType.ORIGINAL_CLAIM }] : []),
      ];
    }
    return [
      { label: translate.instant('COUNTERCLAIMS.TYPES.AS'), value: CounterClaimType.AUTHOR_SHARE_CLAIM },
      { label: translate.instant('COUNTERCLAIMS.TYPES.NA'), value: CounterClaimType.NO_AGREEMENT_CLAIM },
      { label: translate.instant('COUNTERCLAIMS.TYPES.BC'), value: CounterClaimType.BREACH_OF_CONTRACT_CLAIM },
    ];
  }

  static getAgreementConflictStateOptions(translate: TranslateService): any[] {
    return [
      { label: '', value: '' },
      { label: translate.instant('AGREEMENT_CONFLICT.STATUS_OVERLAP'), value: CC_STATUS_POSS_OVERLAP },
      { label: translate.instant('AGREEMENT_CONFLICT.STATUS_CONFLICT'), value: CC_STATUS_POTENTIAL_CONFLICT },
      { label: translate.instant('AGREEMENT_CONFLICT.STATUS_DISPUTE'), value: CC_STATUS_CONFIRMED_DISPUTE },
      { label: translate.instant('AGREEMENT_CONFLICT.STATUS_RESOLVED'), value: CC_STATUS_RESOLVED },
      { label: translate.instant('AGREEMENT_CONFLICT.STATUS_CLOSED'), value: CC_STATUS_CLOSED },
    ];
  }

  static getAgreementConflictStates(): any[] {
    return [CC_STATUS_POSS_OVERLAP, CC_STATUS_POTENTIAL_CONFLICT, CC_STATUS_CONFIRMED_DISPUTE, CC_STATUS_RESOLVED, CC_STATUS_CLOSED];
  }

  static getAllCounterClaimsTypesOptions(translate: TranslateService): any[] {
    return [
      { value: '', label: '' },
      { label: translate.instant('ACTIVITY.COUNTERCLAIMS_TYPES.AU'), value: CounterClaimType.AUTHORSHIP_CLAIM },
      { label: translate.instant('ACTIVITY.COUNTERCLAIMS_TYPES.SA'), value: CounterClaimType.SAMPLE_CLAIM },
      { label: translate.instant('ACTIVITY.COUNTERCLAIMS_TYPES.AS'), value: CounterClaimType.AUTHOR_SHARE_CLAIM },
      { label: translate.instant('ACTIVITY.COUNTERCLAIMS_TYPES.IN'), value: CounterClaimType.INFRINGEMENT_CLAIM },
      { label: translate.instant('ACTIVITY.COUNTERCLAIMS_TYPES.NA'), value: CounterClaimType.NO_AGREEMENT_CLAIM },
      { label: translate.instant('ACTIVITY.COUNTERCLAIMS_TYPES.BC'), value: CounterClaimType.BREACH_OF_CONTRACT_CLAIM },
      { label: translate.instant('ACTIVITY.COUNTERCLAIMS_TYPES.OR'), value: CounterClaimType.ORIGINAL_CLAIM },
      { label: translate.instant('ACTIVITY.COUNTERCLAIMS_TYPES.OW'), value: CounterClaimType.OWNERSHIP_CLAIM },
    ];
  }

  static getAllCounterClaimsResolutioOwnerTypesOptions(translate: TranslateService): any[] {
    return [
      { value: '', label: '' },
      { label: translate.instant('ACTIVITY.RESOLUTION_OWNER_TYPES.PRS'), value: CounterClaimResolutionOwnerType.PRS },
      { label: translate.instant('ACTIVITY.RESOLUTION_OWNER_TYPES.STIM'), value: CounterClaimResolutionOwnerType.STIM },
      { label: translate.instant('ACTIVITY.RESOLUTION_OWNER_TYPES.BUMA'), value: CounterClaimResolutionOwnerType.BUMA },
      { label: translate.instant('ACTIVITY.RESOLUTION_OWNER_TYPES.SABAM'), value: CounterClaimResolutionOwnerType.SABAM },
      { label: translate.instant('ACTIVITY.RESOLUTION_OWNER_TYPES.KODA'), value: CounterClaimResolutionOwnerType.KODA },
      { label: translate.instant('ACTIVITY.RESOLUTION_OWNER_TYPES.TEOSTO'), value: CounterClaimResolutionOwnerType.TEOSTO },
      { label: translate.instant('ACTIVITY.RESOLUTION_OWNER_TYPES.TONO'), value: CounterClaimResolutionOwnerType.TONO },
    ];
  }

  static getActionTypesOptions(translate: TranslateService): any[] {
    // Filtering criteria in the following this jira:
    // https://onstage.atlassian.net/browse/CUBE-11514
    return [
      { value: '', label: '' },
      { label: translate.instant('ACTIVITY.ACTION_TYPES.MEMBERS_TO_RESOLVE'), value: ActionType.MEMBERS_TO_RESOLVE },
      { label: translate.instant('ACTIVITY.ACTION_TYPES.REQUEST_TO_SUPPORT_CLAIM'), value: ActionType.REQUEST_TO_SUPPORT_CLAIM },
      { label: translate.instant('ACTIVITY.ACTION_TYPES.WITHDRAW_CLAIM'), value: ActionType.WITHDRAW_CLAIM },
      { label: translate.instant('ACTIVITY.ACTION_TYPES.REDUCE_CLAIM'), value: ActionType.REDUCE_CLAIM },
      { label: translate.instant('ACTIVITY.ACTION_TYPES.CLAIM_NOT_SUPPORTED'), value: ActionType.CLAIM_NOT_SUPPORTED },
      { label: translate.instant('ACTIVITY.ACTION_TYPES.EARLY_WARNING'), value: ActionType.EARLY_WARNING },
    ];
  }

  static getActionResponseOptions(translate: TranslateService): any[] {
    return [
      { value: '', label: '' },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.SUPPORTED'), value: ActionResponse.SUPPORTED },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.WITHDRAWN'), value: ActionResponse.WITHDRAWN },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.REDUCED'), value: ActionResponse.REDUCED },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.REDUCED_AND_SUPPORTED'), value: ActionResponse.REDUCED_AND_SUPPORTED },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.REQUESTED_TO_SUPPORT_CLAIM'), value: ActionResponse.REQUESTED_TO_SUPPORT_CLAIM },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.SUPPORTED_AND_DOCUMENT_REJECTED'), value: ActionResponse.SUPPORTED_AND_DOCUMENT_REJECTED },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.COUNTERCLAIM_RESOLVED'), value: ActionResponse.COUNTERCLAIM_RESOLVED },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.CLAIMS_REDUCED'), value: ActionResponse.CLAIMS_REDUCED },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.CLAIMS_WITHDRAWN'), value: ActionResponse.CLAIMS_WITHDRAWN },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.CLAIMS_REMOVED'), value: ActionResponse.CLAIMS_REMOVED },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.NOT_SUPPORTED'), value: ActionResponse.NOT_SUPPORTED },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.CLAIM_NOT_SUPPORTED'), value: ActionResponse.CLAIM_NOT_SUPPORTED },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.CLAIMS_SUPPORTED_AND_REDUCED'), value: ActionResponse.CLAIMS_SUPPORTED_AND_REDUCED },
      { label: translate.instant('ACTIVITY.ACTION_RESPONSES.CLAIMS_SUPPORTED_AND_REJECTED'), value: ActionResponse.CLAIMS_SUPPORTED_AND_REJECTED },
    ];
  }

  static cleanCounterClaimDetail(item) {
    const { status, createdDate, resolutionOwners, workId, type, pointOfConflict, counterClaimTerritories, onlyIncludeConflicts } = item && item.attributes;
    const formattedStatus = status?.replace(/\_/g, ' ');
    const titleItem = WorkUtils.selectTitle(get(item, `work.attributes.titles`));
    const formattedConflictType = type?.replace(/CLAIM|_/g, match => ({ _: ' ', CLAIM: '' }[match]));
    const formattedPointOfConflict = pointOfConflict?.replace(/\_/g, ' ');
    const territoryFilter =
      isEmpty(counterClaimTerritories) || !!counterClaimTerritories.find(territory => territory.inExTisns.includes(INCLUDE_WORLD_IN_EX_TISN))
        ? ''
        : `claims[countries.value=(${(counterClaimTerritories || []).map(territory => territory.inExTisns).join(',')})].countries`;
    const territories: string[] = flatMap((counterClaimTerritories || []).map(territory => territory.inExTisns));
    const workXREF = get(item, 'work.relations', [])
      .filter(relation => relation.relation === 'XREF')
      .map(relation => ({ value: ((relation.otherId || '').match(':(.*)') || [])[1], label: (relation.otherId || '').split(':')[0] }));
    const workTitles = WorkUtils.getWorkAlternativeTitles(get(item, 'work.attributes.titles', [])).map(title => ({ value: title.title, label: title.type }));
    const actionsList = ClaimantUtils.getCounterClaimActionsList(item.participants, item.actions);
    const resolutionOwnersValue = (resolutionOwners || []).filter(owner => !!owner).join(', ');
    const resolutionOwnersTooltip = SocietiesUtils.getSocietyName(resolutionOwnersValue);
    const { territoriesTooltip, territoriesTooltipWithoutHtml } = TerritoryUtils.getTerritoriesDisplayListData(territories, TerritoryDataType.NAME);
    const iceId = workXREF.find(xref => xref.label === ICE);
    const cleanedTerritoriesTooltip = !(TerritoryUtils.getGroupsSelected(tisnTisaConversionItems, territories).filter(territory => territory.tisa === '2WL').length > 0)
      ? territoriesTooltipWithoutHtml
      : tisnTisaConversionItems.find(element => element.tisa === '2WL').name;
    const territoriesLabels = TerritoryUtils.convertTerritoryArrayElements(territories, TerritoryDataType.TISA).map(code =>
      TerritoryUtils.normalizeTerritoryDefaultModifyerCode(code),
    );
    return {
      id: item.id,
      type,
      typeLabel: formattedConflictType,
      pointOfConflict: formattedPointOfConflict,
      territoriesLabel: territoriesLabels.join(' '),
      territoriesTooltip,
      territoriesTooltipWithoutHtml: cleanedTerritoriesTooltip,
      territories,
      territoryFilter,
      workXREF,
      workTitles,
      actionsList,
      createdDate,
      workId,
      iceId: iceId ? `${iceId.label}:${iceId.value}` : workId,
      workTitle: titleItem ? titleItem.title : '',
      resolutionOwners: resolutionOwnersValue,
      resolutionOwnersTooltip,
      status: formattedStatus,
      actions: item.actions,
      participants: item.participants,
      rawItem: item,
      downloadDocumentMode: false,
      rejectDocumentMode: false,
      onlyIncludeConflicts,
    };
  }

  static formatOnlyIncludeConflicts(onlyIncludeConflicts, translate: TranslateService) {
    return onlyIncludeConflicts ? translate.instant('COUNTER.ONLY_INCLUDE_CONFLICTS_TRUE') : translate.instant('COUNTER.ONLY_INCLUDE_CONFLICTS_FALSE');
  }

  static cleanSearchConflictWork(firstWork: WorkDetail): {
    workId: string;
    workTitle: string;
    firstCreator: string;
    soc: string;
    value: string;
    deadline: string;
    uploadCat: string;
    triggers: ActivityTriggerAttributes[];
    conflictData: string;
    conflictArea: string;
  } {
    let workTitleItem: TitlesInterface;
    let workTitle: string;
    let soc: string;
    let value: string;
    let deadline: string;
    let uploadCat: string;
    let conflictData: string;

    const workAttributes = firstWork.attributes;
    if (workAttributes) {
      workTitleItem = WorkUtils.selectTitle(workAttributes.titles);
      workTitle = workTitleItem ? workTitleItem.title : '';
      conflictData = workAttributes.entityType;
    }

    const workContributions = firstWork.contributions;
    const firstCreator = workContributions && ContributorsUtils.concatContributionsPartyNames(workContributions);
    const triggers = firstWork.activityTriggers.map(trigger => trigger.attributes).sort((a, b) => (a.deadline > b.deadline ? -1 : 1));
    const firstTriggerAttributes = triggers.length && triggers[0];

    if (firstTriggerAttributes) {
      ({ soc, value, deadline, uploadCat } = firstTriggerAttributes);
    }

    const workRelations = firstWork.relations;

    const workId = workRelations && has(firstWork, 'id') && WorkUtils.selectWorkKey(workRelations, firstWork.id);
    const conflictArea = firstWork && get(firstWork, 'attributes.caseArea', null);

    return { workId, workTitle, firstCreator, soc, value, deadline, uploadCat, triggers, conflictData, conflictArea };
  }

  static editConflict(activityDetail: ActivityDetail, action: EditAction) {
    const { object, newValue, type } = action;
    switch (object) {
      default:
        activityDetail[object] = newValue;
    }
  }

  static getAssignActionPayload(id, action) {
    return {
      id: action.id,
      attributes: { ...action.attributesRaw, assignor: id },
    };
  }

  static editCounterClaim(counterClaimDetail: CounterClaimDetail, action: EditAction) {
    const { object, newValue, type } = action;
    const { rawItem } = counterClaimDetail || {};
    if (rawItem) {
      switch (object) {
        case 'territories':
          rawItem.attributes.counterClaimTerritories = [
            {
              tisDate: DateTimeUtils.getTodayFormatted(),
              inExTisns: (!isEmpty(newValue) && newValue) || [INCLUDE_WORLD_IN_EX_TISN],
            },
          ];
          break;
        default:
          rawItem.attributes[object] = newValue;
      }
    }
  }

  static goToWork(conflictSubType: string): boolean {
    return !!GOTOWORK_CONFLICTSUBTYPES.find(subType => subType === conflictSubType);
  }

  static getDomesticSociety(domesticSocieties: string[]) {
    const customerSocieties: string[] = [];
    const noCustomerSocieties: string[] = [];
    (domesticSocieties || []).map(societyCode => {
      const society = padStart(societyCode, 3, '0');
      if (intersection(iceCustomerSocieties, [society]).length > 0) {
        customerSocieties.push(society);
      } else {
        noCustomerSocieties.push(society);
      }
    });
    const orderedSocieties = [...customerSocieties, ...noCustomerSocieties];

    return uniq(orderedSocieties).join(', ');
  }

  static getDomesticSocietyTooltip(domesticSocieties: string[], translate) {
    const customerSocieties: string[] = [];
    const noCustomerSocieties: string[] = [];
    (domesticSocieties || []).map(societyCode => {
      const society = padStart(societyCode, 3, '0');
      if (intersection(iceCustomerSocieties, [society]).length > 0) {
        customerSocieties.push(SocietiesUtils.searchSocietyNameById(society));
      } else {
        noCustomerSocieties.push(SocietiesUtils.searchSocietyNameById(society));
      }
    });
    const orderedSocieties = [...customerSocieties, ...noCustomerSocieties];
    return uniq(orderedSocieties).join(', ');
  }

  static getConflictSubtypesTooltip({ conflict, translate }: { conflict: any; translate: TranslateService }) {
    const isMissingLinksConflict = conflict.conflictSubType === ConflictSubType.MISSING_CREATOR_LINK_DATA;
    const conflictData: string[] = (isMissingLinksConflict ? conflict.attributesData?.claimsWithMissingLinks : conflict.attributesData?.conflictClaimIds) || [];
    const maxRowsToDisplay = 10;
    const isOverMaxRowsToDisplay = conflictData.length > maxRowsToDisplay;
    const hasAdditionalDetails = !!conflict.attributesDetails;
    const additionalInformationBlock = hasAdditionalDetails
      ? `<h3>${translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.ADDITIONAL_INFORMATION')}</h3>
                        <p>${conflict.attributesDetails}</p>`
      : '';
    const moreClaimsBlock = conflictData.length
      ? `<h3>${translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.CAUSED_BY_CLAIMS')}</h3>
                        ${conflictData
                          .slice(0, maxRowsToDisplay)
                          .map(id => `<div>${id}</div>`)
                          .join('')}
                        ${
                          isOverMaxRowsToDisplay
                            ? `<div>${translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.HAS_MORE_CLAIMS', {
                                count: conflictData.length - maxRowsToDisplay,
                              })}</div>`
                            : ''
                        }`
      : '';
    if (!additionalInformationBlock && !moreClaimsBlock) {
      return conflict.conflictSubType;
    }
    const conflictSubTypeTooltip = `${additionalInformationBlock} ${moreClaimsBlock}`;

    return conflictSubTypeTooltip;
  }
}
