import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironment } from 'models/app-environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  url: string;
  constructor(private http: HttpClient) {}

  async getConfig() {
    const envConf = await this.http.get<AppEnvironment>('assets/environment/environment.json').toPromise();

    const {
      baseUrl: apiUrl,
      userPoolAuthDomain,
      identityPoolId,
      region,
      userPoolId,
      clientId,
      apiNamespace,
      flagsmith_environment_id: flagsmithEnvironmentId,
      datadog: dataDogConf,
    } = envConf;
    const imbIceBaseUrl = 'https://integration.iceaccess.com/';
    const AuthConf = {
      mandatorySignIn: true,
      identityPoolId,
      region,
      userPoolId,
      userPoolWebClientId: clientId,
      oauth: {
        domain: userPoolAuthDomain,
        scope: ['openid', 'profile', 'email', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:4200/auth.html',
        redirectSignOut: 'http://localhost:4200/login',
        responseType: 'token',
        clientID: clientId,
      },
    };
    this.url = apiUrl;
    return {
      apiUrl,
      userPoolAuthDomain,
      AuthConf,
      apiUrlCubeData: apiUrl,
      apiUrlCubeRegister: apiUrl,
      territoriesApiUrl: `${apiUrl}/territories/countries`,
      territoriesTisnApiUrl: `${apiUrl}/territories/TISN/search`,
      urlSubmitSharePicture: `${apiUrl}/share-pictures/submit`,
      urlGetStatusSharePicture: `${apiUrl}/share-pictures`,
      urlGetSharePicture: `${apiUrl}/share-pictures`,
      urlSharePicDebug: `${apiUrl}/share-pictures/submit/debug`,
      apiNamespace,
      imbIceBaseUrl,
      urlImbIceEnquiry: `${imbIceBaseUrl}/enquiry/requests/add`,
      urlImbIceEnvironmentPath: 'W',
      urlGetMaskedId: `${apiUrl}/ids/mask`,
      flagsmithEnvironmentId,
      dataDogConf,
    };
  }
}
