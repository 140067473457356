import * as fromNewSectionItemActions from 'store/form/actions';
import * as fromActions from 'store/root/actions';

export type showErrorTypes =
  | fromActions.FetchSearchPageFail
  | fromActions.SelectedSearchItemMissingKey
  | fromActions.GetItemFail
  | fromActions.GetAccessFail
  | fromActions.GetUserDetailsFail
  | fromActions.GetItemNotFound
  | fromActions.ApiCallError
  | fromActions.GetSharePictureFail
  | fromActions.UpdateIpsRelationsFail
  | fromActions.SaveSectionItemFail
  | fromNewSectionItemActions.SaveNewSectionItemFail
  | fromActions.AuthError
  | fromActions.IceError
  | fromActions.AuditHistoryFilterFail
  | fromActions.DeleteFail
  | fromActions.GetAllTerritoriesFail;
export const showErrorActions = [
  fromActions.FETCH_SEARCH_PAGE_FAIL,
  fromActions.SELECTED_SEARCH_ITEM_MISSING_KEY,
  fromActions.GET_ITEM_FAIL,
  fromActions.GET_ACCESS_FAIL,
  fromActions.GET_USER_DETAILS_FAIL,
  fromActions.GET_ITEM_NOT_FOUND,
  fromActions.GET_SHARE_PICTURE_FAIL,
  fromActions.UPDATE_IPS_RELATIONS_FAIL,
  fromActions.SAVE_SECTION_ITEM_FAIL,
  fromActions.AUTH_ERROR,
  fromNewSectionItemActions.SAVE_NEW_SECTION_ITEM_FAIL,
  fromActions.ICE_ERROR,
  fromActions.AUDIT_FILTER_HISTORY_FAIL,
  fromActions.API_CALL_ERROR,
  fromActions.DELETE_FAIL,
  fromActions.GET_ALL_TERRITORIES_FAIL,
];

export enum errorMessageShowtime {
  fast = 2000,
  normal = 3500,
  slow = 5000,
}
