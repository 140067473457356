import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TrimClaimsSelection, DatepickerUtils, fieldConfig, getPostTermCollectionInputs, TrimScopeOptions, TerritoryUtils, TrimClaimsConfig, WorkClaimsUtils } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceLayout } from '@ice/dynamic-components/group-component/group-component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ERROR_TERRITORY_IS_EMPTY, ERROR_TERRITORY_NOT_EXISTS } from 'config/constants/shares.constants';
import { BehaviorSubject, of } from 'rxjs';
import { map, pairwise, startWith, take, withLatestFrom } from 'rxjs/operators';
import { StorageService } from 'services/storage/storage.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import * as fromForm from 'store/form';
import { difference, union } from 'lodash';
import { Observable } from 'rxjs';

export class DialogTrimClaims {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private isFormValid$ = new BehaviorSubject(false);
  private termTerritoryField$ = new BehaviorSubject(true);
  private isExpertMode$ = new BehaviorSubject<boolean>(false);
  constructor(
    private dependencies: {
      translate: TranslateService;
      store: Store<fromRoot.RootState>;
      dialog: MatDialog;
      fieldValidatorService: FieldValidatorService;
      storageService: StorageService;
    },
  ) {}

  open() {
    const { store, dialog } = this.dependencies;
    // set the flag to false so it does not open a second time after the OBO dialog
    store.dispatch(new fromRoot.OpenTrimClaims(false));
    this.dialogRef = dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-height-auto',
        layouts: this.getLayouts(),
      },
      disableClose: true,
    });

    // cleanup Observables when closing the dialog
    this.dialogRef.afterClosed().subscribe(() => {
      this.isExpertMode$.complete();
      this.isFormValid$.complete();
      this.termTerritoryField$.complete();
    });
  }
  territoryValidator(control, field) {
    const { fieldValidatorService, storageService } = this.dependencies;
    if (!control.value) {
      fieldValidatorService.removeError(ERROR_TERRITORY_NOT_EXISTS, field.id);
      return true;
    }
    return fieldValidatorService.existTerritory(
      control,
      field,
      () => fieldValidatorService.addError(ERROR_TERRITORY_NOT_EXISTS, field.id),
      res => {
        if (res && res['tisns']) {
          storageService.setEntity('trim-claims-territory', [field.templateOptions.lastValue.toUpperCase()], true, 20);
          this.termTerritoryField$.next(true);
          field.templateOptions.bindedFieldCodes.setValue(res['tisns']);
        }
        fieldValidatorService.removeError(ERROR_TERRITORY_NOT_EXISTS, field.id);
      },
    );
  }
  getLayouts(): IceLayout[] {
    const { translate, fieldValidatorService, storageService, store, dialog } = this.dependencies;
    const initialModel: TrimClaimsConfig = { claimsSelection: TrimClaimsSelection.ALL, scope: TrimScopeOptions.TRIM, excludeCreators: false };

    let formOutput: TrimClaimsConfig;
    const isExpertMode$ = this.isExpertMode$;
    const expertModeText$ = isExpertMode$.pipe(
      map(isExpertMode => (isExpertMode ? translate.instant('WORKS.TRIM_CLAIMS.EXPERT_MODE') : translate.instant('WORKS.TRIM_CLAIMS.SWITCH_TO_EXPERT'))),
    );
    const expertModeColor$ = isExpertMode$.pipe(map(isExpertMode => (isExpertMode ? 'error' : 'accent')));
    const expertModeClass$ = isExpertMode$.pipe(map(isExpertMode => (isExpertMode ? 'btn btn-ice-border-error' : 'btn btn-ice-border-accent')));
    const expertModeTooltip$ = isExpertMode$.pipe(
      map(isExpertMode => (isExpertMode ? translate.instant('WORKS.TRIM_CLAIMS.EXPERT_MODE_TOOLTIP') : translate.instant('WORKS.TRIM_CLAIMS.SWITCH_TO_EXPERT_TOOLTIP'))),
    );
    const claimsSelectionOptions$ = isExpertMode$.pipe(
      map(isExpertMode => {
        const options = [{ label: translate.instant('WORKS.TRIM_CLAIMS.SELECT_ALL_CLAIMS'), value: TrimClaimsSelection.ALL }];
        if (isExpertMode) {
          options.push({ label: translate.instant('WORKS.TRIM_CLAIMS.SELECTED_CHAINS'), value: TrimClaimsSelection.SELECTED });
        }
        return options;
      }),
    );
    const scopeTrimOptions$ = isExpertMode$.pipe(
      map(isExpertMode => {
        const options = [{ label: translate.instant('WORKS.TRIM_CLAIMS.TRIM_SCOPE'), value: TrimScopeOptions.TRIM }];
        if (isExpertMode) {
          options.push(
            {
              label: translate.instant('WORKS.TRIM_CLAIMS.REPLACE_SCOPE'),
              value: TrimScopeOptions.REPLACE,
            },
            { label: translate.instant('WORKS.TRIM_CLAIMS.ADD_SCOPE'), value: TrimScopeOptions.ADD },
          );
        }
        return options;
      }),
    );
    const sinceFieldConfig = DatepickerUtils.getDatepickerField({
      key: 'startDate',
      label: translate.instant('CLAIMS.START_DATE'),
      translate,
      extraTemplateOptions: { popupForcePt: 8 },
    });
    const toFieldConfig = DatepickerUtils.getDatepickerField({
      key: 'endDate',
      label: translate.instant('CLAIMS.END_DATE'),
      translate,
      extraTemplateOptions: { popupForcePt: 8 },
    });
    DatepickerUtils.createDatepickerRange({ translate, sinceFieldConfig, toFieldConfig });

    return [
      {
        title: of(translate.instant('WORKS.TRIM_CLAIMS.TITLE')),
        verticalFloatingButtons: true,
        actions: [
          {
            tooltip: translate.instant('POPUP.APPLY_CHANGES'),
            icon: 'done',
            disabled: this.isFormValid$.pipe(map(isValid => !isValid)),
            onClick: () => {
              store.dispatch(new fromForm.TrimClaims(formOutput));
              this.dialogRef.close();
            },
            className: 'ok-icon-button',
          },
          {
            tooltip: translate.instant('POPUP.CLOSE'),
            icon: 'close',
            color: 'warn',
            onClick: () => {
              this.dialogRef.close();
            },
            className: 'ok-icon-button',
          },
        ],
        layout: [
          {
            group: [
              {
                type: 'stepper',
                config: {
                  setValidForm: valid => {
                    this.isFormValid$.next(valid);
                  },
                  change: (model: TrimClaimsConfig) => {
                    formOutput = model;
                  },
                  stepperConfig: {
                    model: initialModel,
                    steps: [
                      {
                        label: translate.instant('WORKS.TRIM_CLAIMS.CLAIMS_SELECTION'),
                        labelButtons: of([
                          {
                            class: expertModeClass$,
                            text: expertModeText$,
                            color: expertModeColor$,
                            tooltip: expertModeTooltip$,
                            onClick: () => {
                              isExpertMode$.pipe(take(1)).subscribe(isExpertMode => {
                                if (isExpertMode) {
                                  return;
                                }
                                isExpertMode$.next(true);
                              });
                            },
                          },
                        ]),
                        formBuilder: [
                          {
                            key: 'claimsSelection',
                            type: 'radio',
                            className: 'radio-vertical',
                            templateOptions: {
                              attributes: { 'data-testid': 'radio-claimsSelection' },
                              options: claimsSelectionOptions$,
                              placeholder: translate.instant('WORKS.TRIM_CLAIMS.SELECT_CLAIMS_PLACEHOLDER'),
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'chains',
                            type: 'select',
                            templateOptions: {
                              attributes: { 'data-testid': 'select-chains' },
                              label: translate.instant('WORKS.TRIM_CLAIMS.SELECTED_CHAINS_PLACEHOLDER'),
                              multiple: true,
                              options: store.select(fromRoot.getTrimClaimsChainsOptions),
                            },
                            hooks: {
                              onInit: field => {
                                field.hide = true; // start hidden and then decide based on `claimsSelection` value
                                const claimsSelection = field.form.controls['claimsSelection'];
                                claimsSelection.valueChanges.subscribe(value => {
                                  field.hide = value !== TrimClaimsSelection.SELECTED;
                                  field.templateOptions.required = value === TrimClaimsSelection.SELECTED;
                                });
                                const excludeCreators = field.form.controls['excludeCreators'];
                                let prevValue = field.formControl.value;
                                (field.formControl.valueChanges as Observable<string[]>)
                                  .pipe(withLatestFrom(store.select(fromRoot.getClaims), store.select(fromRoot.getClaimParentGroups)))
                                  .subscribe(([value, claims, claimCollection]) => {
                                    const excludeCreatorsValue: boolean = excludeCreators.value;
                                    // identify if we added a creator
                                    const [added] = difference(value, prevValue);
                                    if (added && !excludeCreatorsValue) {
                                      const parentId = claims.find(claim => claim.claimId === added).parentId;
                                      if (parentId) {
                                        const newValue = union(
                                          value,
                                          claimCollection[parentId].map(({ claimId }) => claimId),
                                        );
                                        field.formControl.setValue(newValue, { emitEvent: false });
                                      }
                                    }
                                    // otherwise we removed one. Identify the removed one
                                    const [removed] = difference(prevValue, value);
                                    if (removed && !excludeCreatorsValue) {
                                      const parentId = claims.find(claim => claim.claimId === removed).parentId;
                                      if (parentId) {
                                        const newValue = difference(
                                          value,
                                          claimCollection[parentId].map(({ claimId }) => claimId),
                                        );
                                        field.formControl.setValue(newValue, { emitEvent: false });
                                      }
                                    }
                                    // update the `prevValue`
                                    prevValue = field.formControl.value;
                                  });

                                // add all the missing publishers when the `excludeCreators` is false
                                (excludeCreators.valueChanges as Observable<boolean>)
                                  .pipe(
                                    withLatestFrom(
                                      field.formControl.valueChanges as Observable<string[]>,
                                      store.select(fromRoot.getClaims),
                                      store.select(fromRoot.getClaimParentGroups),
                                    ),
                                  )
                                  .subscribe(([isExcludeCreators, selectedValues, claims, claimCollection]) => {
                                    if (!isExcludeCreators) {
                                      const newValue = [...selectedValues];
                                      selectedValues.forEach(id => {
                                        const parentId = claims.find(claim => claim.claimId === id).parentId;
                                        if (parentId) {
                                          const relatedPublishers = claimCollection[parentId].map(({ claimId }) => claimId);
                                          const idsToAdd = difference(relatedPublishers, newValue);
                                          newValue.push(...idsToAdd);
                                        }
                                      });
                                      field.formControl.setValue(newValue, { emitEvent: false });
                                    }
                                  });
                              },
                            },
                            modelOptions: {
                              updateOn: 'change',
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'excludeCreators',
                            type: 'checkbox',
                            templateOptions: {
                              attributes: { 'data-testid': 'checkbox-excludeCreators' },
                              label: translate.instant('WORKS.TRIM_CLAIMS.EXCLUDE_CREATORS'),
                            },
                            hooks: {
                              onInit: field => {
                                isExpertMode$.subscribe(isExpertMode => {
                                  field.hide = !isExpertMode;
                                });
                              },
                            },
                          },
                        ],
                      },
                      {
                        label: translate.instant('WORKS.TRIM_CLAIMS.NEW_SCOPE'),
                        formBuilder: [
                          {
                            className: 'flex-2',
                            type: 'ice-autocomplete',
                            key: 'territory',
                            modelOptions: { updateOn: 'blur' },
                            templateOptions: {
                              allowAnyValue: true,
                              listOnly: true,
                              required: false,
                              addTooltip: true,
                              placeholder: translate.instant('BULK-UPDATES.IP_DETAILS.TERRITORIES'),
                              label: translate.instant('BULK-UPDATES.IP_DETAILS.TERRITORIES'),
                              options: this.termTerritoryField$.pipe(
                                map(_ => (storageService.getEntity('trim-claims-territory') || []).map(territory => ({ value: territory, label: territory }))),
                              ),
                              codes: [],
                            },
                            hooks: {
                              onInit: field => {
                                const form = field.form;
                                field.templateOptions.bindedFieldCodes = form.controls.codes;
                              },
                              onDestroy: field => {
                                fieldValidatorService.removeError(ERROR_TERRITORY_IS_EMPTY, field.id);
                                field.templateOptions?.subscriptionChanges?.unsubscribe();
                              },
                            },
                            asyncValidators: {
                              territoryValidator: {
                                expression: async (control, field) => this.territoryValidator(control, field),
                                message: translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_TERRITORY_NOT_EXISTS'),
                              },
                            },
                          },
                          ...TerritoryUtils.getTerritoryHiddenFields(fieldValidatorService),
                          {
                            fieldGroupClassName: 'display-flex',
                            fieldGroup: [sinceFieldConfig, toFieldConfig],
                            hooks: {
                              onInit: field =>
                                isExpertMode$.subscribe(isExpertMode => {
                                  field.hide = !isExpertMode;
                                }),
                            },
                          },
                          fieldConfig(
                            getPostTermCollectionInputs({
                              translate,
                              postTermProps: {
                                hooks: {
                                  onInit: field =>
                                    isExpertMode$.subscribe(isExpertMode => {
                                      field.hide = !isExpertMode;
                                    }),
                                },
                              },
                            }),
                          ),
                          DatepickerUtils.getDatepickerField({
                            key: 'priorRoyaltiesDate',
                            label: translate.instant('WORKS.TRIM_CLAIMS.PRIOR_ROYALTIES_DATE'),
                            translate,
                            extraClass: 'ice-px-0',
                            hooks: {
                              onInit: field =>
                                isExpertMode$.subscribe(isExpertMode => {
                                  field.hide = !isExpertMode;
                                }),
                            },
                          }),
                          fieldConfig([
                            WorkClaimsUtils.getRightTypesInput({
                              dialog,
                              translate,
                              rightType: 'Performing',
                              inputProps: {
                                key: 'pr',
                                templateOptions: {
                                  placeholder: translate.instant('WORKS.TRIM_CLAIMS.PERFORMING_PLACEHOLDER'),
                                },
                              },
                              buttonHooks: {
                                onInit: field =>
                                  isExpertMode$.subscribe(isExpertMode => {
                                    field.hide = !isExpertMode;
                                  }),
                              },
                              inputHooks: {
                                onInit: field =>
                                  isExpertMode$.subscribe(isExpertMode => {
                                    field.hide = !isExpertMode;
                                  }),
                              },
                            }),
                            WorkClaimsUtils.getRightTypesInput({
                              dialog,
                              translate,
                              rightType: 'Mechanical',
                              inputProps: {
                                key: 'mr',
                                templateOptions: {
                                  placeholder: translate.instant('WORKS.TRIM_CLAIMS.MECHANICAL_PLACEHOLDER'),
                                },
                              },
                              buttonHooks: {
                                onInit: field =>
                                  isExpertMode$.subscribe(isExpertMode => {
                                    field.hide = !isExpertMode;
                                  }),
                              },
                              inputHooks: {
                                onInit: field =>
                                  isExpertMode$.subscribe(isExpertMode => {
                                    field.hide = !isExpertMode;
                                  }),
                              },
                            }),
                          ]),
                        ],
                      },
                      {
                        label: translate.instant('WORKS.TRIM_CLAIMS.NEW_SCOPE_HOW'),
                        formBuilder: [
                          {
                            key: 'scope',
                            type: 'radio',
                            className: 'radio-vertical',
                            templateOptions: {
                              options: scopeTrimOptions$,
                              placeholder: translate.instant('WORKS.TRIM_CLAIMS.NEW_SCOPE_PLACEHOLDER'),
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            group: [
              {
                type: 'htmlTemplate',
                config: {
                  htmlClass: 'ice-error-msg',
                  htmlTemplate: of(`
                    <p class="text-14">${translate.instant('WORKS.TRIM_CLAIMS.CLAIM_CHANGE_WARNING')}</p>
                    <p class="text-14">${translate.instant('WORKS.TRIM_CLAIMS.ADDITIONAL_CONFLICTS_WARNING')}</p>
                    <p class="text-14">${translate.instant('WORKS.TRIM_CLAIMS.TEMPORARY_UPDATES_WARNING')}</p>
                    `),
                },
              },
            ],
          },
        ],
      },
    ];
  }
}
