import { FormControl } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DateTimeUtils, TerritoryUtils } from '@ice';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { StepType } from 'config/stepper-builders/stepper-config';
import moment from 'moment';
import { FieldValidatorService } from 'services/validators/field.validator.service';

export class RepertoireTermStep {
  private startDateLabel = 'startDate';
  private endDateLabel = 'endDate';
  private startDateControl;
  private endDateControl;
  private dateValidationInProcess = false;
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<any>) {}

  getStep(translate: TranslateService, fieldValidatorService: FieldValidatorService): StepType {
    return {
      label: translate.instant('REPERTOIRES.STEPS.TERM.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            DatepickerUtils.getDatepickerField({
              key: this.startDateLabel,
              label: translate.instant('REPERTOIRES.STEPS.TERM.FIELDS.VALID_FROM'),
              controlFieldToAssign: this.startDateControl,
              datepickerOptions: {
                filter: (date: Date) => (!this.endDateControl?.value ? true : moment(date).isBefore(this.endDateControl.value)),
              },
              extraValidators: {
                endDateSameOrAfterStartDate: {
                  expression: (control: FormControl) => {
                    const controlValue = DateTimeUtils.getIndefiniteDate(control.value);
                    const otherDateControl = control.parent.controls[this.endDateLabel];
                    const otherDateControlValue = DateTimeUtils.getIndefiniteDate(otherDateControl.value);
                    this.dateValidationInProcess = DatepickerUtils.changeDateValidationInProcessValue(this.dateValidationInProcess, otherDateControl);
                    DatepickerUtils.validateOtherDate(otherDateControl, this.dateValidationInProcess);
                    return !controlValue || DatepickerUtils.isOtherDateValidated(otherDateControl) || moment(controlValue).isSameOrBefore(otherDateControlValue);
                  },
                  message: (error, field: FormlyFieldConfig) => this.translate.instant('REPERTOIRES.STEPS.TERM.FIELDS.ERROR_VALID_TO_AFTER_VALID_FROM'),
                },
              },
              required: true,
              translate: this.translate,
              extraTemplateOptions: { popupForcePt: 6 },
            }),
            DatepickerUtils.getDatepickerField({
              key: this.endDateLabel,
              label: translate.instant('REPERTOIRES.STEPS.TERM.FIELDS.VALID_TO'),
              controlFieldToAssign: this.endDateControl,
              datepickerOptions: {
                filter: (date: Date) => (!this.startDateControl?.value ? true : moment(date).isSameOrAfter(this.startDateControl.value)),
              },
              extraValidators: {
                endDateSameOrAfterStartDate: {
                  expression: (control: FormControl) => {
                    const controlValue = DateTimeUtils.getIndefiniteDate(control.value);
                    const otherDateControl = control.parent.controls[this.startDateLabel];
                    const otherDateControlValue = DateTimeUtils.getIndefiniteDate(otherDateControl.value);
                    this.dateValidationInProcess = DatepickerUtils.changeDateValidationInProcessValue(this.dateValidationInProcess, otherDateControl);
                    DatepickerUtils.validateOtherDate(otherDateControl, this.dateValidationInProcess);
                    return !controlValue || DatepickerUtils.isOtherDateValidated(otherDateControl) || moment(controlValue).isSameOrAfter(otherDateControlValue);
                  },
                  message: (error, field: FormlyFieldConfig) => this.translate.instant('REPERTOIRES.STEPS.TERM.FIELDS.ERROR_VALID_TO_AFTER_VALID_FROM'),
                },
              },
              required: false,
              translate: this.translate,
              extraTemplateOptions: { popupForcePt: 6 },
            }),
            TerritoryUtils.getTerritoryField(
              'territory',
              'term_territory_codes',
              translate.instant('REPERTOIRES.STEPS.TERM.FIELDS.TERRITORY'),
              translate,
              fieldValidatorService,
              true,
            ),
            {
              className: 'flex-2',
              fieldGroup: [
                {
                  key: 'active',
                  type: 'checkbox',
                  defaultValue: false,
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    label: translate.instant('REPERTOIRES.DETAILS.TERMS_CARD.ACTIVE'),
                    required: false,
                  },
                },
              ],
            },
            {
              className: 'flex-2',
              fieldGroup: [
                {
                  key: 'unpublishedWriterShares',
                  type: 'checkbox',
                  defaultValue: false,
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    label: translate.instant('REPERTOIRES.DETAILS.TERMS_CARD.UNPUBLISHED_WRITER_SHARES'),
                    required: false,
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
