import {
  CC_STATUS_CLOSED,
  ContributorsUtils,
  CounterClaimClaimantRelation,
  CounterClaimConflictCondition,
  CounterClaimConflictConditionMap,
  PostTermCollectionValue,
  DateTimeUtils,
  PointOfConflictConditionMap,
  RelationsUtils,
  TerritoryUtils,
} from '@ice';
import { ActionResponse, ActionStatus, ActionType, CounterClaimType, PublisherActionTypes } from 'config/constants/activity.constants';
import { PartyRelationType, mrRights, prRights } from 'config/constants/ips.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import { AUTHOR_ROLES, RightTypes, WORK_CLAIM_SHARE_TYPE } from 'config/constants/shares.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { INCOME_PARTICIPANT_VALUE } from 'config/constants/works.constants';
import {
  capitalize,
  cloneDeep,
  compact,
  difference,
  every,
  findIndex,
  flatten,
  get,
  includes,
  isEmpty,
  last,
  mapValues,
  maxBy,
  padStart,
  pickBy,
  round,
  startsWith,
  take,
  toNumber,
  trim,
  uniqBy,
  zip,
} from 'lodash';
import { ClaimantType } from 'models';
import { ClaimantShare, CounterClaimGroup } from 'models/copyright/send-update/counter-claim';
import { SearchIpsParser } from 'models/search-parsers/search-ips-parser';
import { IPI_PREFIX } from 'config/constants/global.constants';
import { ClaimsUtils } from '../claim/claims.utils';
import { CounterClaimUtils } from '../counter-claim/counter-claim.utils';
import { SocietiesUtils } from '../societies/societies.utils';

export class ClaimantUtils {
  static hasReducedClaims(reducedClaims: any[]): boolean {
    return !!reducedClaims && reducedClaims.some(claim => !isNaN(claim.reducedPr) || !isNaN(claim.reducedMr));
  }

  static getClaimGroup(resolvers, claimant, ownedResolvers): CounterClaimGroup[] {
    return [
      ...uniqBy(
        resolvers.map(claim => ({ ...claim, claimant: CounterClaimClaimantRelation.RESOLVER })),
        `id`,
      ),
      claimant,
    ].map(claim => {
      const currentResolver = ownedResolvers.find(resolver => resolver.participantNameId === claim.id);
      return {
        participantNameId: claim.id,
        participantRole: claim.roleRaw,
        isPublishedCreator: claim.hasChildren,
        ...(claim.claimant === CounterClaimClaimantRelation.RESOLVER ? { parentParticipantNameId: claimant.id, parentParticipantRole: claimant.roleRaw } : {}),
        topLevelNameId: claim.topLevelNameId,
        relation: claim.claimant,
        ...(get(currentResolver, `resolutionOwner`) ? { resolutionOwner: currentResolver.resolutionOwner } : {}),
      };
    });
  }

  static getClaimantsShares(claims: any[], claimsGroups: CounterClaimGroup[]): ClaimantShare[] {
    return claims
      .map(claim => {
        const isResolver = !!this.findResolver(claim, claimsGroups);
        const isClaimant = !!this.findClaimant(claim, claimsGroups);
        if (isResolver || isClaimant) {
          return {
            territories: claim.territories,
            startDate: claim.startDate,
            endDate: claim.endDate,
            rightTypes: claim.allRightsArray,
            priorRoyaltiesDate: claim.priorRoyaltyDate,
            postTermCollectionDate: claim.postTermCollectionDate,
            claimantPartyId: claim.claimantPartyId,
            claimantPartyNameId: claim.claimantPartyNameId,
            role: claim.roleRaw,
          };
        }
      })
      .filter(share => !!share);
  }

  static parsePostTermCollectionValue(value) {
    switch (value?.toUpperCase()) {
      case PostTermCollectionValue.INDEFINITE:
        return false;
      case PostTermCollectionValue.NONE:
        return null;
      case PostTermCollectionValue.DATE:
        return true;
      default:
        return false;
    }
  }
  static getResponseToCloseAction(action) {
    switch (action) {
      case ActionType.WITHDRAW_CLAIM:
        return ActionResponse.CLAIMS_WITHDRAWN;
      case ActionType.REDUCE_CLAIM:
        return ActionResponse.CLAIMS_REDUCED;
      case ActionType.CLAIM_NOT_SUPPORTED:
        return ActionResponse.CLAIMS_REMOVED;
      default:
        return ActionResponse.CLAIMS_REMOVED;
    }
  }

  static getActionsForClaims(counterClaimWork, claims) {
    const selectedActions = claims.map(claim => get(claim, `actionId`));
    return get(counterClaimWork, 'actions', []).filter(action => selectedActions.includes(get(action, `attributes.id`)));
  }

  static getRelatedPartyActionId(party, indexedParties) {
    const relatedParty = indexedParties.find(
      filteredParty => !!filteredParty.actionId && party.id !== filteredParty.id && startsWith(filteredParty.levelId, party.levelId) && !filteredParty.reducePending,
    );
    return get(relatedParty, 'actionId');
  }

  static getUserAllowedCounterClaimParties(parties, currentOrganization, filterByAllowedUsers = false): any[] {
    const indexedParties = this.addIndexToParties(parties);
    // Check if the 'currentOrganization' type is 'society'.
    if (currentOrganization.type === OrganizationType.society) {
      // Create a new array 'visiblePartiesRefs' by filtering 'indexedParties' and extracting 'ref' properties from parties
      // where 'resolutionOwnerLabel' or 'resolutionOwner' includes the 'currentOrganization.societyCode'.
      const visiblePartiesRefs = indexedParties
        .filter(party => (party.resolutionOwnerLabel || party.resolutionOwner || '').includes(currentOrganization.societyCode))
        .map(party => party.ref);
      // Return a modified version of 'indexedParties', where each party is augmented with 'userAllowed' and 'actionId' properties.
      // Filter out parties that do not have 'userAllowed' or have no matching 'visiblePartiesRefs'.
      return indexedParties
        .map(party => ({ ...party, userAllowed: visiblePartiesRefs.includes(party.ref), actionId: party.actionId || this.getRelatedPartyActionId(party, indexedParties) }))
        .filter(party => party.userAllowed || indexedParties.some(filteredParty => party.id !== filteredParty.id && visiblePartiesRefs.includes(filteredParty.ref)));
      // Check if the 'currentOrganization' type is 'publisher'.
    } else if (currentOrganization.type === OrganizationType.publisher) {
      // Create an array 'accessPartyNames' by extracting the 'accessPartyNames' property from 'currentOrganization'.
      // Create 'partiesChildrenNoAccessRemoved' array by mapping 'indexedParties' and adding 'userAllowed' and 'actionId' properties.
      // Filter out parties with no 'claimantRelations' or where 'userAllowed' is false.
      const accessPartyNames = currentOrganization.accessPartyNames || [];
      const partiesChildrenNoAccessRemoved = indexedParties.map(party => ({
        ...party,
        userAllowed: party.userHasAccess || (accessPartyNames || []).some(partyNameId => (party.claimantRelations || []).includes(partyNameId || '')),
        actionId: party.actionId || this.getRelatedPartyActionId(party, indexedParties),
      }));
      return filterByAllowedUsers ? partiesChildrenNoAccessRemoved.filter(party => party.userAllowed) : partiesChildrenNoAccessRemoved;
    } else {
      return indexedParties.map(party => ({ ...party, userAllowed: true, actionId: party.actionId || this.getRelatedPartyActionId(party, indexedParties) }));
    }
  }

  static addIndexToParties(parties: any[]) {
    let index = 0;
    let lastLevel = 0;
    let currentIdArray = [];
    return parties.map(party => {
      index = index + 1;
      currentIdArray = this.calculateIdArray(currentIdArray, party.level, lastLevel);
      lastLevel = party.level;
      return { ...party, index, levelId: currentIdArray.join('-') };
    });
  }

  static calculateIdArray(currentIdArray: number[], level: number, lastLevel: number) {
    let newIdArray = [...currentIdArray];
    if (level > lastLevel) {
      newIdArray.push(1);
    } else {
      if (level < lastLevel) {
        newIdArray = take(currentIdArray, level);
      }
      newIdArray[newIdArray.length - 1] = newIdArray[newIdArray.length - 1] + 1;
    }
    return newIdArray;
  }

  static getUserAllowedCounterClaimActions(actions, participants, currentOrganization, onlyResolvers?) {
    const participantActions = actions.filter(action =>
      participants.some(participant => {
        return (
          (onlyResolvers || get(action, 'attributes.status') !== CC_STATUS_CLOSED) &&
          get(action, 'attributes.participantRelationshipId') === participant.id &&
          (!onlyResolvers || participant.relation === CounterClaimClaimantRelation.RESOLVER)
        );
      }),
    );
    if (currentOrganization.type === OrganizationType.society) {
      return participantActions.filter(action =>
        participants.some(
          participant =>
            get(action, 'attributes.participantRelationshipId') === participant.id && participant.resolutionOwner === padStart(last(currentOrganization.id.split(':SO')), 3, '0'),
        ),
      );
    } else {
      return participantActions;
    }
  }

  static reduceClaims(claims, reduced) {
    if (!reduced) {
      return claims;
    }
    return claims.map(claim =>
      JSON.stringify(claim.path) === JSON.stringify(reduced.path) && (this.checkReducedShare(claim.mr, reduced.reducedMr) || this.checkReducedShare(claim.pr, reduced.reducedPr))
        ? reduced
        : claim,
    );
  }

  static checkReducedShare(share, reducedShare) {
    return !reducedShare || (!isNaN(reducedShare) && !isNaN(parseFloat(reducedShare)) && reducedShare > 0 && reducedShare < parseFloat((share || '').split('%')[0].trim()));
  }

  static hasChangedGroups(prev: CounterClaimGroup[], curr: CounterClaimGroup[]) {
    if (!curr || !prev) {
      return false;
    }

    if (curr.length !== prev.length) {
      return false;
    }
    const currFirstClaimant = [];
    const prevFirstClaimant = [];
    const currNewClaimant = [];
    const prevNewClaimant = [];
    prev.forEach(claimGroup => {
      if (claimGroup.relation === CounterClaimClaimantRelation.FIRST) {
        currFirstClaimant.push(`${claimGroup.topLevelNameId}${claimGroup.participantNameId}`);
      }
      if (claimGroup.relation === CounterClaimClaimantRelation.NEW) {
        currNewClaimant.push(`${claimGroup.topLevelNameId}${claimGroup.participantNameId}`);
      }
    });
    curr.forEach(claimGroup => {
      if (claimGroup.relation === CounterClaimClaimantRelation.FIRST) {
        prevFirstClaimant.push(`${claimGroup.topLevelNameId}${claimGroup.participantNameId}`);
      }
      if (claimGroup.relation === CounterClaimClaimantRelation.NEW) {
        prevNewClaimant.push(`${claimGroup.topLevelNameId}${claimGroup.participantNameId}`);
      }
    });
    return isEmpty(difference(currFirstClaimant, prevFirstClaimant)) && isEmpty(difference(currNewClaimant, prevNewClaimant));
  }

  static hasAllResolutionOwners(claimantGroups: CounterClaimGroup[]) {
    return claimantGroups.every(claimantGroup => claimantGroup.relation !== CounterClaimClaimantRelation.RESOLVER || !!claimantGroup.resolutionOwner);
  }

  static claimantGroupsCleaner(claimantGroups: CounterClaimGroup[], claims, translate) {
    const groupClaimants = claimantGroups ? claimantGroups.filter(claimantGroup => claimantGroup.relation !== CounterClaimClaimantRelation.RESOLVER) : [];
    return groupClaimants.map(groupClaimant => {
      const groupResolvers = claimantGroups.filter(
        claimantGroup =>
          claimantGroup.relation === CounterClaimClaimantRelation.RESOLVER &&
          claimantGroup.parentParticipantNameId === groupClaimant.participantNameId &&
          claimantGroup.topLevelNameId === groupClaimant.topLevelNameId,
      );

      const claimantStartIndex = findIndex(claims, (claim: any) => claim.id === groupClaimant.participantNameId && claim.topLevelNameId === groupClaimant.topLevelNameId);
      const selectedClaimant = claims[claimantStartIndex];
      const resolvers: any[] = uniqBy(
        claims
          .map(claim => {
            const group = groupResolvers.find(
              resolver => !!resolver.parentParticipantNameId && resolver.participantNameId === claim.id && claim.topLevelNameId === resolver.topLevelNameId,
            );
            if (group) {
              return {
                ...claim,
                rowClass: 'resolution-owner-resolver-row',
                relation: translate.instant(`COUNTERCLAIMS.STEPS.${CounterClaimClaimantRelation.RESOLVER.toUpperCase()}`),
                resolutionOwner: group.resolutionOwner,
              };
            } else {
              return claim;
            }
          })
          .filter(claim => !!claim.relation),
        'id',
      );
      return {
        ...selectedClaimant,
        relation: translate.instant(`COUNTERCLAIMS.STEPS.${groupClaimant.relation.toUpperCase()}`),
        rowIdentity: row => row.id,
        rowClass:
          resolvers.filter(resolver => !!resolver.resolutionOwner).length !== resolvers.length
            ? 'select-resolution-owner-warning-row resolution-owner-claimant-row'
            : 'select-resolution-owner-success-row resolution-owner-claimant-row',
        resolutionOwner: `${resolvers.filter(resolver => !!resolver.resolutionOwner).length} / ${resolvers.length}`,
        detail: resolvers,
        resolutionOwnerIcons: [
          {
            icon: 'edit',
            onClickAction: true,
          },
        ],
      };
    });
  }

  static userHasAccess(resolver): boolean {
    return !!resolver && !!resolver.userHasAccess;
  }

  static getRelatedParties(claims, claimGroups: CounterClaimGroup[], actions, translate) {
    const relatedClaims = claims.map(claim => {
      const resolver = this.findResolver(claim, claimGroups);
      const claimant = this.findClaimant(claim, claimGroups);
      const isTop = claimGroups.some(claimGroup => claim.id === claimGroup.topLevelNameId && claim.level === 1);
      const claimantRelation = (claimant && claimant.relation && translate.instant(`COUNTER.${claimant.relation.toUpperCase()}`)) || '';
      const resolverRelation = (!!resolver && translate.instant(`COUNTER.RESOLVER`)) || '';
      const space = (claimantRelation && !!resolver && ' / ') || '';
      const relation = claimantRelation + space + resolverRelation;
      const reducePending = !!resolver && this.hasOpenReduceAction(actions, resolver);
      const action = resolver && this.getActiveAction(actions, resolver);
      return {
        ...claim,
        ...(relation && { relation }),
        ...(action && { actionId: get(action, 'id') }),
        reducePending,
        ...(reducePending && { reducedMr: translate.instant(`COUNTER.PENDING`), reducedPr: translate.instant(`COUNTER.PENDING`), rowClass: 'reduce-pending-claimant-row' }),
        isTop,
        reducedMr: claim.mr,
        reducedPr: claim.pr,
        userHasAccess: this.userHasAccess(resolver),
        resolutionOwner: get(resolver, 'resolutionOwner'),
        claimantRelations: this.formatClaimantRelations(resolver),
        roleLabel: ContributorsUtils.getRoleLabelFromRoleValue(claim.role.replace(/\&nbsp;/g, '')),
      };
    });
    return relatedClaims.filter((claim, index) => claim.relation || claim.isTop);
  }

  static isPublisherAction(action) {
    return PublisherActionTypes.includes(get(action, `attributes.type`));
  }

  static hasOpenReduceAction(actions, claimGroup) {
    return actions.some(
      action =>
        get(action, `attributes.participantRelationshipId`) === claimGroup.id &&
        get(action, 'attributes.type') === ActionType.REDUCE_CLAIM &&
        get(action, `attributes.status`) === ActionStatus.OPEN,
    );
  }

  static getActiveAction(actions, claimGroup) {
    const participantActions = actions.filter(action => get(action, `attributes.participantRelationshipId`) === claimGroup.id && this.isPublisherAction(action));
    const openActions = participantActions.filter(action => get(action, `attributes.status`) === ActionStatus.OPEN);
    return maxBy((!isEmpty(openActions) && openActions) || participantActions, action => {
      const numericId = parseInt(get(action, `attributes.id`, '').split(':')[1], 10);
      return isNaN(numericId) ? 1 : numericId;
    });
  }

  static isIceAction(action) {
    return [ActionType.WITHDRAW_CLAIM, ActionType.REDUCE_CLAIM, ActionType.CLAIM_NOT_SUPPORTED].includes(get(action, 'type', ''));
  }

  static getCounterClaimActionsList(claimGroups: CounterClaimGroup[], actions: any[]) {
    return (actions || [])
      .filter(action => [ActionType.WITHDRAW_CLAIM, ActionType.REDUCE_CLAIM, ActionType.CLAIM_NOT_SUPPORTED].includes(get(action, 'attributes.type', '')))
      .map(action => {
        const participant = (claimGroups || []).find(claimGroup => claimGroup.id === get(action, 'attributes.participantRelationshipId'));
        let ipiNameNumber;
        let resolverUser;
        if (participant) {
          const resolverFirstName = get(participant, 'claimant.attributes.firstName', '').trim();
          const resolverName = get(participant, 'claimant.attributes.name', '').trim();
          resolverUser = resolverFirstName ? resolverName + ', ' + resolverFirstName : resolverName;
          ipiNameNumber =
            participant &&
            participant.claimant?.relations &&
            RelationsUtils.selectRelationByTypeAndPrefix(participant.claimant.relations, [PartyRelationType.CROSS_REFERENCE], [IPI_PREFIX]).split(':')[1];
        }
        return {
          action: get(action, 'attributes.type', ''),
          user: get(action, 'attributes.assignor', ''),
          resolverName: resolverUser,
          ipiNameNumber,
          id: action.id,
          response: get(action, 'attributes.response', ''),
          type: get(action, 'attributes.type', ''),
          status: get(action, 'attributes.status', ''),
          deadline: get(action, 'attributes.deadline', ''),
          withdrawnInfo: this.getWithDrawnInfo(action),
        };
      })
      .sort((a, b) => (`${a.status}-${a.id}` < `${b.status}-${b.id}` ? 1 : -1));
  }

  static getWithDrawnInfo(action) {
    return get(action, 'audits', [])
      .filter(audit => audit.action === ActionResponse.WITHDRAWN)
      .map(audit => {
        const { actions } = audit;
        return {
          ...actions,
          postTermCollectionDate: actions.postTermCollectionDate && DateTimeUtils.formatDate(actions.postTermCollectionDate),
          terminationDate: actions.terminationDate && DateTimeUtils.formatDate(actions.terminationDate),
        };
      });
  }

  static translateActionsList(actionsList, translate) {
    return (actionsList || []).map(action => {
      const actionValue = get(action, 'action', '');
      const actionButtonTooltip = actionValue ? translate.instant(`COUNTER.ACTION_TOOLTIP.${actionValue}`) : null;
      return {
        ...action,
        action: actionValue ? translate.instant(`COUNTER.${actionValue}`) : '',
        status: get(action, 'status', '') ? translate.instant(`COUNTER.${get(action, 'status', '')}`) : '',
        actionButtonTooltip,
      };
    });
  }

  static getCounterClaimParties(claims, claimGroups: CounterClaimGroup[], actions: any[], translate) {
    const associatedClaimsList = this.getRelatedParties(claims, claimGroups, actions, translate);
    const topLevelIps = associatedClaimsList.filter(claim => !claim.relation);
    return claimGroups
      .filter(claimGroup => claimGroup && !claimGroup.isRemoved)
      .map(claimGroup => {
        const claimFind = claims.find(claim => claimGroup.participantNameId === claim.id && claim.topLevelNameId === claimGroup.topLevelNameId);
        const action = this.getActiveAction(actions, claimGroup);
        const resolvers = !claimGroup.parentParticipantNameId
          ? claimGroups.filter(group => group.parentParticipantNameId === claimGroup.participantNameId && group.topLevelNameId === claimGroup.topLevelNameId)
          : [];
        const response = !claimGroup.parentParticipantNameId ? this.getClaimantResponse(resolvers, actions) : get(action, 'attributes.response', '');
        const actionOwnerAndClaimantInfo = CounterClaimUtils.getOwnerAndClaimantInfoFromAction(action, claimGroups);
        const associatedClaims = associatedClaimsList.filter(claim => claimGroup.participantNameId === claim.id && claim.topLevelNameId === claimGroup.topLevelNameId);
        return {
          ...(claimFind || {}),
          sortProperty: `${claimGroup.topLevelNameId}${claimGroup.parentParticipantNameId || claimGroup.participantNameId}${claimGroup.parentParticipantNameId ? 'R' : ''}`,
          relation: translate.instant(`COUNTER.${claimGroup.relation.toUpperCase()}`),
          actionResponseRaw: response,
          level: claimGroup.relation.toUpperCase() === CounterClaimClaimantRelation.RESOLVER ? 0 : 1,
          resolutionOwnerLabel: claimGroup.resolutionOwner,
          resolutionOwnerTooltip: SocietiesUtils.getSocietyName(claimGroup.resolutionOwner),
          relationRaw: claimGroup.relation,
          claimantRelations: this.formatClaimantRelations(claimGroup),
          actionId: get(action, 'id'),
          actionType: get(action, 'attributes.type', '') ? translate.instant(`COUNTER.${get(action, 'attributes.type', '')}`) : '',
          actionDeadline: get(action, 'attributes.deadline', ''),
          actionResponse: response ? translate.instant(`COUNTER.${response}`) : '',
          actionStatus: get(action, 'attributes.status', '') ? translate.instant(`COUNTER.${get(action, 'attributes.status', '')}`) : '',
          actionStatusRaw: get(action, 'attributes.status', ''),
          actionSupportingDocumentRequired: get(action, 'attributes.supportingDocumentRequired', false),
          withdrawnInfo: this.getWithDrawnInfo(action),
          roleLabel: claimFind && ContributorsUtils.getRoleLabelFromRoleValue(claimFind.role.replace(/\&nbsp;/g, '')),
          associatedClaims,
          topLevelIp: topLevelIps.find(top => top.id === claimFind?.topLevelNameId),
          ...actionOwnerAndClaimantInfo,
          id: get(action, 'id', claimFind?.id),
        };
      })
      .sort((a, b) => (a.sortProperty > b.sortProperty ? 1 : -1));
  }

  static getCounterClaimsRelatedParties(counterClaimParties: any[], claimGroups: CounterClaimGroup[], translate, displayTopLevelIps = false, actionList = null) {
    const NEW = capitalize(CounterClaimClaimantRelation.NEW);
    const FIRST = capitalize(CounterClaimClaimantRelation.FIRST);
    const RESOLVER = capitalize(CounterClaimClaimantRelation.RESOLVER);
    const CLAIMANT = 'claimant';
    const mergedRelations = [`${NEW} ${CLAIMANT} / ${RESOLVER}`, `${FIRST} ${CLAIMANT} / ${RESOLVER}`];
    const classList = ['first-group', 'second-group'];
    let currentClass = classList[0];
    let counter = 0;

    function switchClass() {
      currentClass = currentClass === classList[0] ? classList[1] : classList[0];
      counter = 0;
    }

    const isClaimantWithMergedRelation = item => mergedRelations.includes(item.relation);

    function setRowClass(currentParty, resolver) {
      currentParty.rowClass = !!resolver ? 'resolver-row' : 'claimant-row';
    }

    // Because of different data restrictions, some Counterclaims created on and then
    // migrated from the old IBM system don't have Resolution Owners. These migrated
    // Counterclaims also have Related Parties that are duplicative for our purposes
    const doesResolutionOwnerExist = counterClaimParties.some(party => party.resolutionOwnerLabel);
    const filteredParties = doesResolutionOwnerExist
      ? counterClaimParties
      : counterClaimParties.filter(currentParty => {
          return currentParty.actionStatusRaw;
        });

    const associatedClaimsTemp = [];
    const items = filteredParties
      .map(currentParty => {
        const resolver = this.findResolver(currentParty, claimGroups);
        const claimant = this.findClaimant(currentParty, claimGroups);
        const claimantRelation = (claimant && claimant.relation && translate.instant(`COUNTER.${claimant.relation.toUpperCase()}`)) || '';
        const resolverRelation = (!!resolver && translate.instant(`COUNTER.RESOLVER`)) || '';
        const space = (claimantRelation && !!resolver && ' / ') || '';

        const relation = claimantRelation + space + resolverRelation;
        const action = actionList?.find(a => a.ipiNameNumber === currentParty.claimantIPINameNumber);
        const item = {
          ...currentParty,
          ...(relation && { relation }),
          action,
          actionButtonTooltip: action?.actionButtonTooltip,
          userHasAccess: this.userHasAccess(resolver),
        };
        // Parties are sorted by claimant(s) + resolver, so we can assume that we have a list
        // of parties which will be claimants and will potentially have `associatedClaims`.
        // [ Claimant, Resolver, Claimant, Claimant Resolver, ...]
        // or the Claimant can also be the Resolver, in which case we don't want to remove any associated claims
        // For UX purposes we want to display the associated claims with the resolver, so when they come separated
        // we need to move them to the resolver's list of associated claims.
        if (!!claimant && !resolver) {
          associatedClaimsTemp.push(...(item.associatedClaims || []));
          item.associatedClaims = null;
        }
        if (!claimant && !!resolver) {
          currentParty.associatedClaims.unshift(...associatedClaimsTemp);
          associatedClaimsTemp.splice(0);
        }

        setRowClass(item, resolver);

        if (isClaimantWithMergedRelation(item) && !item.resolutionOwnerLabel && doesResolutionOwnerExist) {
          return null;
        }

        item.rowClass += ` ${currentClass}`;
        counter = counter + 1;
        if (counter === 2 || isClaimantWithMergedRelation(item)) {
          switchClass();
        }

        return item;
      })
      .filter(party => !!party);

    return displayTopLevelIps ? this.setTopLevelIpRows(items, RESOLVER).map((item, index) => ({ ...item, index })) : items;
  }

  static setTopLevelIpRows(items: any[], resolverType: string): any[] {
    const newItems = [];
    for (const item of items) {
      if (item.relation !== resolverType && !!item.topLevelIp) {
        const newItem = cloneDeep(item);
        newItem.topLevelIp.rowClass = newItem.rowClass.replace('resolver-row', 'claimant-row') || '';
        newItems.push(newItem.topLevelIp);
      }
      newItems.push(item);
    }
    return newItems;
  }

  static findResolver(claim, claimGroups: CounterClaimGroup[]): CounterClaimGroup {
    return claimGroups?.find(
      claimGroup =>
        !!claimGroup?.parentParticipantNameId &&
        claimGroup?.participantNameId === claim?.claimantPartyNameId &&
        claim?.topLevelNameId === claimGroup?.topLevelNameId &&
        !claimGroup?.isRemoved,
    );
  }
  static findClaimant(claim, claimGroups: CounterClaimGroup[]): CounterClaimGroup {
    return claimGroups?.find(
      claimGroup =>
        !claimGroup?.parentParticipantNameId &&
        claimGroup?.participantNameId === claim?.claimantPartyNameId &&
        claim?.topLevelNameId === claimGroup?.topLevelNameId &&
        !claimGroup?.isRemoved,
    );
  }

  static formatClaimantRelations(claimant) {
    return get(claimant, 'claimant.relations', []).map(relation => relation.otherId || '');
  }

  static getClaimantResponse(resolvers, actions) {
    const resolverActions = actions.filter(action => resolvers.map(resolver => resolver.id).includes(get(action, `attributes.participantRelationshipId`)));
    if (this.checkActionsAttributes(resolverActions, 'response', [ActionResponse.WITHDRAWN])) {
      return ActionResponse.CLAIMS_WITHDRAWN;
    }
    if (
      this.checkActionsAttributes(resolverActions, 'type', [ActionType.MEMBERS_TO_RESOLVE]) ||
      this.checkActionsAttributes(resolverActions, 'response', [ActionResponse.SUPPORTED, ActionResponse.REDUCED_AND_SUPPORTED])
    ) {
      return ActionResponse.SUPPORTED;
    }
    if (this.checkActionsAttributes(resolverActions, 'type', [ActionType.REQUEST_TO_SUPPORT_CLAIM]) && resolverActions.some(action => !get(action, 'attributes.response'))) {
      return ActionResponse.AWAITING_REPLY;
    }
    if (this.checkActionsAttributes(resolverActions, 'type', [ActionType.EARLY_WARNING])) {
      return ActionResponse.EARLY_WARNING;
    }
    if (
      this.checkActionsAttributes(resolverActions, 'type', [ActionType.REQUEST_TO_SUPPORT_CLAIM]) ||
      this.checkActionsAttributes(resolverActions, 'response', [ActionResponse.CLAIM_NOT_SUPPORTED])
    ) {
      return ActionResponse.CLAIM_NOT_SUPPORTED;
    }
    return '';
  }

  static checkActionsAttributes(actions: any[], attribute: string, values: string[]): boolean {
    return actions.some(action => values.includes(get(action, `attributes.${attribute}`)));
  }

  static setClaimsResolvers(claims, claimGroups: CounterClaimGroup[], translate) {
    return claims.map(claim => {
      const resolver = claimGroups.find(
        claimGroup => !!claimGroup.parentParticipantNameId && claimGroup.participantNameId === claim.id && claim.topLevelNameId === claimGroup.topLevelNameId,
      );
      const claimant = claimGroups.find(
        claimGroup => !claimGroup.parentParticipantNameId && claimGroup.participantNameId === claim.id && claim.topLevelNameId === claimGroup.topLevelNameId,
      );

      const action = !!claimant ? 'edit' : 'add';
      return {
        ...claim,
        resolverCheckIcons: [
          {
            icon: resolver ? 'check_box' : 'check_box_outline_blank',
            text: (translate && translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.RESOLVER')) || '',
            class: 'ice-checkbox-icon',
          },
        ],
        rowClass: !resolver || claimant ? (action === 'edit' ? `edit-claimant-button-row-edit ${claim.rowClass}` : `edit-claimant-button-row ${claim.rowClass}`) : '',
        ...(!resolver || claimant
          ? {
              claimantStatusIcons: [
                {
                  icon: action,
                  text: (translate && translate.instant(`WORKS.SHARE_PICTURE.TABLE_SCHEMA.${action.toUpperCase()}_CLAIMANT`)) || '',
                  onClickAction: true,
                },
              ],
              claimantStatusLabel:
                (translate &&
                  (action === 'edit'
                    ? translate.instant(`COUNTERCLAIMS.STEPS.${claimant.relation.toUpperCase()}`)
                    : translate.instant(`WORKS.SHARE_PICTURE.TABLE_SCHEMA.${action.toUpperCase()}_CLAIMANT`))) ||
                '',
            }
          : {}),
      };
    });
  }

  static updateResolutionOwnwer(claimantGroups: CounterClaimGroup[], claim): CounterClaimGroup[] {
    return claimantGroups.map(claimantGroup => ({
      ...claimantGroup,
      ...(!!claimantGroup.parentParticipantNameId && claimantGroup.participantNameId === claim.id && claim.topLevelNameId === claimantGroup.topLevelNameId
        ? { resolutionOwner: claim.resolutionOwner }
        : {}),
    }));
  }

  static getClaimStatus(claimants, claimGroups: CounterClaimGroup[], addResolvers): any[] {
    if (claimGroups) {
      const claimantGroupsParent = addResolvers ? claimGroups : claimGroups.filter(claimGroup => !claimGroup.parentParticipantNameId);
      return uniqBy(
        claimantGroupsParent
          .map(claimGroup => {
            return {
              claimGroup,
              claim: claimants.find(claimant => claimGroup.topLevelNameId === claimant.topLevelNameId && claimGroup.participantNameId === claimant.id),
            };
          })
          .filter(claimStatus => claimStatus.claim)
          .map(claimStatus => ({ ...claimStatus.claim, claimantStatus: claimStatus.claimGroup.relation })),
        claim => claim.partyNameId + claim.claimantStatus,
      );
    }
    return claimants;
  }

  static checkClaimantsForCounterClaimType(type, claimants) {
    return (
      ![CounterClaimType.AUTHORSHIP_CLAIM, CounterClaimType.ORIGINAL_CLAIM].includes(type) ||
      (type === CounterClaimType.AUTHORSHIP_CLAIM && claimants && every(claimants, row => this.isAuthorRole(row.role.replace(/^(&nbsp;|<br>)+/, '')))) ||
      (type === CounterClaimType.ORIGINAL_CLAIM && claimants && every(claimants, row => !this.isAuthorRole(row.role.replace(/^(&nbsp;|<br>)+/, ''))))
    );
  }

  static checkMinClaimants(claimants) {
    return !!claimants && claimants.length > 1;
  }

  static checkMinFirstClaimant(claimants) {
    const firstClaimants = claimants.filter(claimant => claimant.claimantStatus === CounterClaimClaimantRelation.FIRST);
    return (firstClaimants && firstClaimants.length > 0) || false;
  }

  static claimantsAreValid(type, claimants) {
    return this.checkMinClaimants(claimants) && this.checkClaimantsForCounterClaimType(type, claimants);
  }

  static addClaimantData(claimant, claimantList, originalPublisherList, subPublisherList, creatorList) {
    const claimantStatus = claimant.claimantStatus;
    claimantList[claimantStatus].push(claimant);
    switch (this.getClaimantType(claimant)) {
      case ClaimantType.CREATOR:
      case ClaimantType.WRITER:
        creatorList[claimantStatus].push(claimant);
        break;
      case ClaimantType.ORIGINAL_PUBLISHER:
        originalPublisherList[claimantStatus].push(claimant);
        break;
      case ClaimantType.SUB_PUBLISHER:
        subPublisherList[claimantStatus].push(claimant);
    }
  }

  static isAuthorRole(role): boolean {
    return includes(AUTHOR_ROLES, role);
  }

  static replaceClaimant(newValue, claim) {
    claim['role'] = newValue.role;
    claim['claimantPartyId'] = newValue.ClaimantPartyId;
    claim['claimantPartyNameId'] = newValue.ClaimantPartyNameId;
    claim['agreementId'] = newValue.agreementId;
    claim['auxFields'] = { name: newValue.ClaimantName, ipiNameNumber: newValue.ClaimantIPI, ipiBaseNameNumber: newValue.ClaimantIPBaseNameNumber };
  }

  static getClaimantTotalShares(claimantList) {
    const prParser = item => parseFloat(item.pr.slice(0, -1));
    const mrParser = item => parseFloat(item.mr.slice(0, -1));
    const { totalPR, totalMR } = ClaimsUtils.calculateCopyrightOwnershipTotals(claimantList, prParser, mrParser) || { totalMR: 0, totalPR: 0 };
    return Math.max(+totalPR, +totalMR);
  }

  static cleanLinkedWriters(linkedWriters, translate) {
    return SearchIpsParser.IpSearchCleaner(
      linkedWriters.map(writer => get(writer, 'claimant.partyName', null)).filter(writer => !!writer),
      translate,
    );
  }

  static getClaimantListData(allClaimantList) {
    const [claimantList, claimantOriginalPublisherList, claimantSubPublisherList, claimantCreatorList] = Array.from({ length: 4 }).map(() => ({
      [CounterClaimClaimantRelation.FIRST]: [],
      [CounterClaimClaimantRelation.NEW]: [],
    }));

    allClaimantList.forEach(claimant => this.addClaimantData(claimant, claimantList, claimantOriginalPublisherList, claimantSubPublisherList, claimantCreatorList));
    const claimantTotalShares = {
      [CounterClaimClaimantRelation.FIRST]: this.getClaimantTotalShares(claimantList[CounterClaimClaimantRelation.FIRST]),
      [CounterClaimClaimantRelation.NEW]: this.getClaimantTotalShares(claimantList[CounterClaimClaimantRelation.NEW]),
    };
    return {
      claimantList,
      claimantOriginalPublisherList,
      claimantSubPublisherList,
      claimantCreatorList,
      claimantTotalShares,
    };
  }

  static getClaimantType(claimant): ClaimantType {
    if (claimant.roleRaw === 'E') {
      return ClaimantType.ORIGINAL_PUBLISHER;
    }
    if (claimant.roleRaw === 'SE') {
      return ClaimantType.SUB_PUBLISHER;
    }
    return ClaimantType.CREATOR;
  }

  static hasSameParent(claimantList: Array<any>): boolean {
    return this.checkSamePropertyInList(claimantList, 'parentId');
  }

  static isSameOriginalPublisher(claimantList: Array<any>): boolean {
    return this.checkSamePropertyInList(claimantList, 'refLabel');
  }

  static getCounterClaimConflictConditionList(allClaimantList): CounterClaimConflictCondition[] {
    const { claimantList, claimantOriginalPublisherList, claimantSubPublisherList, claimantCreatorList, claimantTotalShares } = this.getClaimantListData(allClaimantList);

    const newHasSameParent = this.hasSameParent(claimantList[CounterClaimClaimantRelation.NEW]);
    const isSameOriginalPublisher = this.isSameOriginalPublisher(allClaimantList);
    const isNewSameOriginalPublisher = this.isSameOriginalPublisher(claimantList[CounterClaimClaimantRelation.NEW]);

    const counterClaimConflictConditionMap: CounterClaimConflictConditionMap = {
      FIRST_CLAIMANT_SHARE_EQUALS_100: claimantTotalShares[CounterClaimClaimantRelation.FIRST] === 100,
      NEW_CLAIMANT_SHARE_LESS_100: claimantTotalShares[CounterClaimClaimantRelation.NEW] < 100,
      NEW_CLAIMANT_SHARE_GREATER_EQUALS_100: claimantTotalShares[CounterClaimClaimantRelation.NEW] >= 100,
      NEW_CLAIMANT_SUBPUBLISHER: claimantSubPublisherList[CounterClaimClaimantRelation.NEW].length === claimantList[CounterClaimClaimantRelation.NEW].length,
      FIRST_CLAIMANT_SUBPUBLISHER: claimantSubPublisherList[CounterClaimClaimantRelation.FIRST].length === claimantList[CounterClaimClaimantRelation.FIRST].length,
      NEW_CLAIMANT_NO_SUBPUBLISHER: claimantSubPublisherList[CounterClaimClaimantRelation.NEW].length !== claimantList[CounterClaimClaimantRelation.NEW].length,
      NEW_CLAIMANT_ORIGINAL: claimantOriginalPublisherList[CounterClaimClaimantRelation.NEW].length === claimantList[CounterClaimClaimantRelation.NEW].length,
      FIRST_CLAIMANT_ORIGINAL: claimantOriginalPublisherList[CounterClaimClaimantRelation.FIRST].length === claimantList[CounterClaimClaimantRelation.FIRST].length,
      FIRST_CLAIMANT_CREATOR: claimantCreatorList[CounterClaimClaimantRelation.FIRST].length === claimantList[CounterClaimClaimantRelation.FIRST].length,
      NEW_CLAIMANT_SAME_PARENT: newHasSameParent,
      NEW_CLAIMANT_NOT_SAME_PARENT: !newHasSameParent,
      NEW_CLAIMANT_ORIGINAL_SAME: isNewSameOriginalPublisher,
      NEW_CLAIMANT_NO_ORIGINAL_SAME: !isNewSameOriginalPublisher,
      ALL_SAME_ORIGINAL: isSameOriginalPublisher,
      NOT_ALL_SAME_ORIGINAL: !isSameOriginalPublisher,
    };
    return Object.keys(
      pickBy(
        mapValues(counterClaimConflictConditionMap, (conditionValue, conditionKey: CounterClaimConflictCondition) =>
          this.checkHasClaimants(conditionKey, conditionValue, claimantList),
        ),
        value => value,
      ),
    ) as CounterClaimConflictCondition[];
  }

  static checkHasClaimants(conditionKey: CounterClaimConflictCondition, conditionValue, claimantList) {
    const hasFirstClaimants = !!claimantList[CounterClaimClaimantRelation.FIRST].length;
    const hasNewClaimants = !!claimantList[CounterClaimClaimantRelation.NEW].length;
    switch (conditionKey) {
      case 'FIRST_CLAIMANT_SHARE_EQUALS_100':
      case 'FIRST_CLAIMANT_CREATOR':
      case 'FIRST_CLAIMANT_ORIGINAL':
        return hasFirstClaimants && conditionValue;
      case 'NEW_CLAIMANT_ORIGINAL_SAME':
      case 'NEW_CLAIMANT_NO_ORIGINAL_SAME':
      case 'NEW_CLAIMANT_SAME_PARENT':
      case 'NEW_CLAIMANT_NOT_SAME_PARENT':
      case 'NEW_CLAIMANT_SHARE_LESS_100':
      case 'NEW_CLAIMANT_SHARE_GREATER_EQUALS_100':
      case 'NEW_CLAIMANT_ORIGINAL':
      case 'NEW_CLAIMANT_SUBPUBLISHER':
        return hasNewClaimants && conditionValue;
      case 'ALL_SAME_ORIGINAL':
      case 'NOT_ALL_SAME_ORIGINAL':
      default:
        return conditionValue;
    }
  }

  static getPointOfConflictConditionMap(): PointOfConflictConditionMap {
    return {
      NO_AGREEMENT_CLAIM: {
        WRITER_AND_ORIGINAL_PUBLISHER: [['FIRST_CLAIMANT_CREATOR']],
        PUBLISHER_AND_SUBPUBLISHER: [['FIRST_CLAIMANT_ORIGINAL']],
      },
      BREACH_OF_CONTRACT_CLAIM: {
        WRITER_AND_ORIGINAL_PUBLISHER: [['FIRST_CLAIMANT_CREATOR']],
        PUBLISHER_AND_SUBPUBLISHER: [['FIRST_CLAIMANT_ORIGINAL']],
      },
      AUTHORSHIP_CLAIM: {
        BETWEEN_CREATORS: [['NEW_CLAIMANT_SHARE_GREATER_EQUALS_100']],
        NEW_CREATORS: [['NEW_CLAIMANT_SHARE_LESS_100', 'FIRST_CLAIMANT_SHARE_EQUALS_100']],
      },
      ORIGINAL_CLAIM: {
        PUBLISHER_AND_SUBPUBLISHER: [['NEW_CLAIMANT_ORIGINAL_SAME'], ['NEW_CLAIMANT_SAME_PARENT', 'NEW_CLAIMANT_SUBPUBLISHER']],
        WRITER_AND_ORIGINAL_PUBLISHER: [
          ['NEW_CLAIMANT_NO_ORIGINAL_SAME', 'NEW_CLAIMANT_NOT_SAME_PARENT'],
          ['NEW_CLAIMANT_NO_ORIGINAL_SAME', 'NEW_CLAIMANT_NO_SUBPUBLISHER'],
        ],
      },
      OWNERSHIP_CONFIRMED: {
        PUBLISHER_AND_SUBPUBLISHER: [['ALL_SAME_ORIGINAL']],
        WRITER_AND_ORIGINAL_PUBLISHER: [['NOT_ALL_SAME_ORIGINAL']],
      },
      OWNERSHIP_NOT_CONFIRMED: {
        WRITER_AND_ORIGINAL_PUBLISHER: [['FIRST_CLAIMANT_CREATOR']],
        PUBLISHER_AND_SUBPUBLISHER: [['FIRST_CLAIMANT_ORIGINAL']],
      },
      INFRINGEMENT_CLAIM: {
        INFRINGED_PARTIES: [],
      },
      SAMPLE_CLAIM: {
        BETWEEN_CREATORS: [],
      },
      AUTHOR_SHARE_CLAIM: {
        BETWEEN_CREATORS: [],
      },
    };
  }

  static checkSamePropertyInList(list: Array<any>, property) {
    return !!list.length && list.every(element => element[property] === list[0][property]);
  }

  static getClaimFromClaims(claimId, claims) {
    return claims.find(claim => claim.claimId === claimId);
  }

  static getRelationFromClaim(claim, relation) {
    const claimRelations = get(claim, 'claimant.partyName.relations');
    const IPIRelation = claimRelations && claimRelations.find(rel => rel.relation === PartyRelationType.CROSS_REFERENCE && rel.otherId.includes(relation));
    return IPIRelation && IPIRelation.otherId;
  }

  static getDatesFromClaimShares(claim) {
    if (claim) {
      const claimShares = claim.claimShares;
      if (claimShares && claimShares.length && claimShares.length > 0) {
        const startDate = get(claimShares, '[0].startDate');
        const endDate = get(claimShares, '[0].endDate');
        return { startDate, endDate };
      }
    }
    return { startDate: '', endDate: '' };
  }

  static getParsedSharesFromClaim(claim, shareType?) {
    if (claim) {
      const claimShares = shareType === WORK_CLAIM_SHARE_TYPE.OWNERSHIP ? claim.ownershipShares : claim.claimShares;
      if (claimShares && claimShares.length && claimShares.length > 0) {
        const parsedPRShares = [];
        const parsedMRShares = [];
        claimShares.map((claimShare, index) => {
          const { rightTypes, share, territories, endDate, postTermCollectionDate, expectedTerminationDate, priorRoyaltiesDate, startDate, unauthorized } = claimShare;
          const parsedShare = (share === 0 ? share : share / 100).toFixed(2);
          const prRightsSelected = [];
          const mrRightsSelected = [];
          rightTypes.map(right => {
            const inMechanical = mrRights.find(value => value === right);
            const inPerforming = prRights.find(value => value === right);
            if (inMechanical) {
              mrRightsSelected.push(right);
            } else if (inPerforming) {
              prRightsSelected.push(right);
            }
          });

          if (prRightsSelected.length > 0) {
            parsedPRShares.push(
              this.genShare(
                parsedShare,
                RightTypes.PR,
                prRightsSelected,
                TerritoryUtils.convertTerritoryArrayElements(territories, TerritoryDataType.TISA),
                endDate,
                postTermCollectionDate,
                expectedTerminationDate,
                priorRoyaltiesDate,
                startDate,
                unauthorized,
                index,
              ),
            );
          }
          if (mrRightsSelected.length > 0) {
            parsedMRShares.push(
              this.genShare(
                parsedShare,
                RightTypes.MR,
                mrRightsSelected,
                TerritoryUtils.convertTerritoryArrayElements(territories, TerritoryDataType.TISA),
                endDate,
                postTermCollectionDate,
                expectedTerminationDate,
                priorRoyaltiesDate,
                startDate,
                unauthorized,
                index,
              ),
            );
          }
        });

        const parsedShares = compact(flatten(zip(parsedMRShares, parsedPRShares)));
        return parsedShares;
      }
    }
    return null;
  }

  static genShare(share, type, rights, codes, endDate, postTermCollectionDate, expectedTerminationDate, priorRoyaltiesDate, startDate, unauthorized, index) {
    return {
      share,
      type,
      index,
      endDate,
      postTermCollectionDate,
      expectedTerminationDate,
      priorRoyaltiesDate,
      startDate,
      inclusion: rights.join(','),
      territory: codes.join(''),
      unauthorized,
      codes,
    };
  }

  static getFormattedClaimantInfoFromClaim(claim, includeOwnership = true, allClaims = null) {
    const firstName = get(claim, `claimant.partyName.attributes.firstName`, null);
    const name = claim?.claimant ? `${get(claim, `claimant.partyName.attributes.name`)}${firstName ? `, ${firstName}` : ''}` : get(claim, 'auxFields.name');
    const claimInfo = claim.claimantId.split('_');
    const claimantPartyId = claim.claimantPartyId ? claim.claimantPartyId : claimInfo[1];
    const claimantPartyNameId = claim.claimantPartyNameId ? claim.claimantPartyNameId : claimInfo[0];
    const relations = get(claim, `claimant.partyName.relations`, []);
    const relationFound = relations.find(relation => relation.relation === PartyRelationType.DISPLAY_REFERENCE);
    let claimantIPNameNumber;
    if (relationFound && relationFound.otherId) {
      claimantIPNameNumber = get(relationFound, 'otherId', '');
    }
    const incomeParticipant = get(claim, `extensions.incomeParticipant[0]`, null);
    const parentId = get(claim, `parentId`);
    const linkCreatorId = parentId
      ? ClaimantUtils.getRelationFromClaim(
          allClaims.find(parentClaim => parentClaim.claimId === parentId),
          'ICE',
        )
      : incomeParticipant
      ? INCOME_PARTICIPANT_VALUE
      : null;

    const selectPublisherId = claim.role === 'SE' ? parentId : null;
    const baseClaimantInfo = {
      role: claim.role,
      ClaimantIPI: claim.claimant ? ClaimantUtils.getRelationFromClaim(claim, 'IPI') : get(claim, 'auxFields.ipiNameNumber'),
      ClaimantName: name,
      ClaimantIPNameNumber: claimantIPNameNumber,
      ClaimantId: claim.claimantId,
      ClaimantPartyId: claimantPartyId,
      ClaimantPartyNameId: claimantPartyNameId,
      shares: ClaimantUtils.getParsedSharesFromClaim(claim, WORK_CLAIM_SHARE_TYPE.SHARES),
      ClaimantInfo: get(claim, 'claimant.partyName', null),
      linkCreator: [linkCreatorId],
      agreementId: claim.agreementId,
      agreementNumber: claim.agreementId,
      SelectPublisherIPI: selectPublisherId,
    };

    return includeOwnership ? { ...baseClaimantInfo, ownership: ClaimantUtils.getParsedSharesFromClaim(claim, WORK_CLAIM_SHARE_TYPE.OWNERSHIP) } : baseClaimantInfo;
  }

  static roundShare(share: string) {
    if (share.includes('%')) {
      return `${round(toNumber(trim(share.replace('%', ''))), 2)} %`;
    }
    return share;
  }

  static getRoleLabelTooltip(role, translate) {
    if (role) {
      return translate.instant(`COUNTER_CLAIMS.ROLE.${role}`);
    }
  }
}
