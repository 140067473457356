import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as englishErrors } from 'assets/i18n/en/app/formly-global-message-errors';
import { locale as english } from 'assets/i18n/en/config/section-new-data-builders';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { StorageService } from 'services/storage/storage.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { StepType } from '../stepper-config';
import { BulkUpdateTypeStep } from './steps/bulk-update-type-step';
import { BulkUpdateDetailsStep } from './steps/bulk-update-details-step';
import { BulkUpdateAdditionalInfoStep } from './steps/bulk-update-additional-info-step';

export class BulkUpdatesSteps {
  constructor(private storageService: StorageService) {}
  static getSteps(
    translate: TranslateService,
    translationLoader: FuseTranslationLoaderService,
    store: Store<fromRoot.RootState>,
    storeNewItem: Store<fromForm.NewSectionItemState>,
    fieldValidatorService: FieldValidatorService,
    searchService: SearchService,
    dialog: MatDialog,
    permissionsService: PermissionsService,
    storageService: StorageService,
  ): StepType[] {
    translationLoader.loadTranslations(english, englishErrors);
    return [
      new BulkUpdateTypeStep(translate, store).getStep(),
      new BulkUpdateDetailsStep(translate, translationLoader, fieldValidatorService, dialog, store, storeNewItem, searchService, storageService).getStep(),
      new BulkUpdateAdditionalInfoStep(translate, store).getStep(),
    ];
  }
}
