import { AllClaimsFilter, CopyrightUtils, getSubPublisherNumberFromClaimId, IpUtils, isSubPublisherRole, TerritoryUtils, WorkDetail } from '@ice';
import { allRightsSet, mrRights, mrRightsSet, prRights, prRightsSet, societiesICE } from 'config/constants/ips.constants';
import { RIGHT_MR, RIGHT_PR, WORK_CLAIM_SHARE_TYPE } from 'config/constants/shares.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { concat, difference, flatMap, flattenDeep, get, groupBy, intersection, mapValues, pickBy, reduce, sortBy, uniq } from 'lodash';
import moment from 'moment';
import { DialogSharesUsesTypes } from 'config/dialog-builders/dialog-shares-uses-types';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl } from '@angular/forms';
import { RightTypeGroup } from 'models/copyright/formly/right-types';
import { ContributorsUtils } from '../contributors/contributors.utils';
import { SocietiesUtils } from '../societies/societies.utils';
import { ClaimsUtils } from './claims.utils';

export class WorkClaimsUtils {
  static workClaimsCleaner(claims: WorkDetail['claims'], translate, claimsFilter?) {
    const sortedClaims = WorkClaimsUtils.getSortedClaims(claims);
    return flatMap(
      sortedClaims.map(claim => {
        let name: string;
        if (claim?.claimant?.partyName) {
          name = ClaimsUtils.getClaimantFullNameFromClaim(claim);
        } else {
          name = get(claim, 'auxFields.name');
        }
        const claimShares = get(claim, 'claimShares', []);
        const typeOf = get(claim, `claimant.party.attributes.typeOf`);
        name = IpUtils.cleanNameLabel(name, typeOf) || name;
        const ipiNumber = claim?.claimant?.partyName ? IpUtils.selectIPINumber(get(claim, `claimant.partyName.relations`)) : get(claim, 'auxFields.ipiNameNumber');
        const ipiBaseNumber = claim?.claimant?.party ? IpUtils.selectIPINumber(get(claim, `claimant.party.relations`)) : get(claim, 'auxFields.ipiBaseNameNumber');
        const partyNameId = get(claim, 'claimant.partyNameId', '');
        const partyId = get(claim, 'claimant.partyId', '');
        const ipiNumberFallback = (partyNameId.includes('IPI') && partyNameId) || '';
        const ipiBaseNumberFallback = (partyId.includes('IPI') && partyId) || '';
        const claimId = claim.claimId;
        const societies = get(claim, 'claimant.party.societies') || get(claim, 'claimant.partyName.societies', []);

        const { societyCodePr, societyCodePrTooltip, societyCodePrIcon, societyCodeMr, societyCodeMrTooltip, societyCodeMrIcon } = this.getSocietiesCodes({
          societies,
          claimsFilter,
        });
        const isIncomeParticipant = claim?.extensions?.incomeParticipant?.[0] === 'true';
        const incomeParticipantIcon = isIncomeParticipant ? CopyrightUtils.getIncomeparticipantIconWithTooltip(translate) : undefined;
        let counterClaimIcon;
        return [
          ...claimShares.map((cShare, index) => ({ ...cShare, type: WORK_CLAIM_SHARE_TYPE.SHARES, index })),
          ...get(claim, `ownershipShares`, []).map((oShare, index) => ({ ...oShare, type: WORK_CLAIM_SHARE_TYPE.OWNERSHIP, index })),
        ].map(tShare => {
          counterClaimIcon = tShare.hasNotResolvedCounterclaim ? CopyrightUtils.getCounterclaimIconWithTooltip() : undefined;
          const { prShares, mrShares } = SocietiesUtils.getPrMrShares(tShare);
          const prSharesString = `${(prShares / 100).toFixed(2)} %`;
          const mrSharesString = `${(mrShares / 100).toFixed(2)} %`;
          const unauthorized = tShare.unauthorized;
          const unauthorizedIcon = tShare.unauthorized ? CopyrightUtils.getUnauthorizedIconWithTooltip(translate) : undefined;
          const alertIcons = flattenDeep([incomeParticipantIcon, unauthorizedIcon, counterClaimIcon].filter(item => item !== undefined));
          return {
            role: `${' '.repeat(claim.depth)}${claim.role}`,
            roleRaw: claim.role,
            name,
            ipiNumber: CopyrightUtils.getKeySuffix(ipiNumber) || CopyrightUtils.getKeySuffix(ipiNumberFallback),
            ipiBaseNumber: CopyrightUtils.getKeySuffix(ipiBaseNumber) || CopyrightUtils.getKeySuffix(ipiBaseNumberFallback),
            claimId,
            index: tShare.index,
            type: tShare.type,
            priorDate: tShare.priorRoyaltiesDate,
            startDate: tShare.startDate,
            endDate: tShare.endDate,
            postTermCollectionDate: tShare.postTermCollectionDate,
            territories: tShare.territories,
            territoriesTisa: TerritoryUtils.convertTerritoryArrayElements(tShare.territories, TerritoryDataType.TISA).join(''),
            territoriesTooltip: TerritoryUtils.getTerritoriesNamesTooltipText(TerritoryUtils.convertTerritoryArrayElements(tShare.territories, TerritoryDataType.NAME)),
            rights: this.getRightsDisplayValue(tShare.rightTypes, translate),
            share: `${(tShare.share / 100).toFixed(2)} %`,
            roleLabel: ContributorsUtils.getRoleLabelFromRoleValue(claim.role),
            alert: unauthorized || isIncomeParticipant || false,
            alertIcons,
            prSoc: societyCodePr,
            prSocTooltip: societyCodePrTooltip,
            prSocIcon: societyCodePrIcon,
            mrSoc: societyCodeMr,
            mrSocTooltip: societyCodeMrTooltip,
            mrSocIcon: societyCodeMrIcon,
            prShares: prSharesString,
            mrShares: mrSharesString,
            pr: prShares,
            mr: mrShares,
            prTooltip: prSharesString,
            mrTooltip: mrSharesString,
            rightTypes: tShare.rightTypes,
            level: claim.depth + 2,
            rowClass: tShare.hasNotResolvedCounterclaim ? 'unresolved-cc-row' : '',
          };
        });
      }),
    );
  }

  static getFormattedCodesAndTooltipsByRight(societyCodes, right: typeof RIGHT_MR | typeof RIGHT_PR) {
    const matchingSocietyCodes = societyCodes.filter(society => society.rights.includes(right)).map(society => society.code);

    return WorkClaimsUtils.getFormattedSocietyCodesAndTooltips(matchingSocietyCodes);
  }

  static getFormattedSocietyCodesAndTooltips(matchingSocietyCodes: string[]) {
    const defaultDisplaySocietyCode = '099';
    const defaultValue = SocietiesUtils.formatSocietyCodeNameFirst(defaultDisplaySocietyCode);
    const sortBySocietyCodes = (a, b) => +societiesICE.includes(b) - +societiesICE.includes(a);

    const uniqSocietyCodes = uniq(matchingSocietyCodes);
    const societyCodes = sortBy(uniqSocietyCodes, sortBySocietyCodes);
    const hasIceSociety = societyCodes.some(code => societiesICE.includes(code));
    const icon = societyCodes.length > 1 ? SocietiesUtils.generateIsICESocietyIcons({ isICE: hasIceSociety, excludeMargin: true })[0] : null;

    const formattedSocietyCodes = societyCodes.map(SocietiesUtils.formatSocietyCodeNameFirst);
    return {
      code: formattedSocietyCodes[0] || defaultValue,
      tooltip: formattedSocietyCodes.join(', ') || defaultValue,
      icon,
    };
  }

  static claimsWithSocietyCodes({ claims, claimsFilter }: { claims: any[]; claimsFilter: AllClaimsFilter }) {
    return claims.map(claim => {
      const societies = claim.societies;
      const societyCodesData = WorkClaimsUtils.getSocietiesCodes({ societies, claimsFilter });
      return { ...claim, ...societyCodesData };
    });
  }

  static getSocietiesCodes({ societies, claimsFilter }: { societies: any[]; claimsFilter: AllClaimsFilter }) {
    const filteredSocieties = !claimsFilter
      ? societies
      : societies.filter(society => {
          const { memberships } = society;
          const societyHasMatchingMembership = memberships.some(membership => {
            const { startDate, endDate, territories, rights } = membership;
            const { territory: filterTerritories, mrRight: filterMrRight, prRight: filterPrRight, usageDate: filterUsageDate } = claimsFilter;

            const isTerritoryCorrect = this.isTerritoryMatchFilter({ claimTerritories: territories, filterTerritories });
            const isRightTypeMatch = this.isRightTypeMatchFilter({ claimRightTypes: rights, filterPrRight, filterMrRight });
            const isDateCorrect = this.isDateCorrectFilter({ startDate, endDate, usageDate: filterUsageDate });

            return isTerritoryCorrect && isRightTypeMatch && isDateCorrect;
          });

          return societyHasMatchingMembership;
        });

    const societyCodes = filteredSocieties.map((society: any) => ({
      code: SocietiesUtils.getSocietyCode(society.societyId),
      rights: society.memberships.map(member => member.rights || []).reduce((acc, it) => [...acc, ...it], []),
    }));

    const { code: societyCodePr, tooltip: societyCodePrTooltip, icon: societyCodePrIcon } = this.getFormattedCodesAndTooltipsByRight(societyCodes, RIGHT_PR);
    const { code: societyCodeMr, tooltip: societyCodeMrTooltip, icon: societyCodeMrIcon } = this.getFormattedCodesAndTooltipsByRight(societyCodes, RIGHT_MR);

    return {
      societyCodePr,
      societyCodePrTooltip,
      societyCodePrIcon,
      societyCodeMr,
      societyCodeMrTooltip,
      societyCodeMrIcon,
    };
  }

  static decomposeWorkClaimsByRightType(claims) {
    const decomposedClaims = [];
    claims.forEach(claim => {
      const claimPrRights = claim.rightTypes.filter(rightType => prRightsSet.has(rightType));
      const claimMrRights = claim.rightTypes.filter(rightType => mrRightsSet.has(rightType));

      if (claimPrRights.length && claimMrRights.length) {
        decomposedClaims.push({ ...claim, rightTypes: claimPrRights });
        decomposedClaims.push({ ...claim, rightTypes: claimMrRights });
      } else {
        decomposedClaims.push(claim);
      }
    });
    return decomposedClaims;
  }

  static isDateCorrectFilter({ usageDate, endDate, startDate }) {
    return (
      !usageDate ||
      (moment(startDate).isSameOrBefore(moment(usageDate)) && moment(endDate).isSameOrAfter(moment(usageDate))) ||
      (!startDate && !endDate) ||
      (!startDate && moment(endDate).isSameOrAfter(moment(usageDate))) ||
      (moment(startDate).isSameOrBefore(moment(usageDate)) && !endDate)
    );
  }

  static isTerritoryMatchFilter({ claimTerritories, filterTerritories }) {
    const flatTerritories = TerritoryUtils.flatTerritories(claimTerritories);
    const intersectionData = intersection(flatTerritories, filterTerritories);
    return !filterTerritories || !!intersectionData.length;
  }

  static hasPerformingRights(rightTypes) {
    return !!intersection(rightTypes, prRights).length;
  }
  static hasMechanicalRights(rightTypes) {
    return !!intersection(rightTypes, mrRights).length;
  }
  static isRightTypeMatchFilter({ claimRightTypes, filterPrRight, filterMrRight }: { claimRightTypes: string[]; filterPrRight: string; filterMrRight: string }) {
    const isPrClaim = this.hasPerformingRights(claimRightTypes);
    const isMrClaim = this.hasMechanicalRights(claimRightTypes);
    if (!isPrClaim && !isMrClaim) {
      // this case is unlikely, but we want to be able to see faulty claims without PR and MR rights
      return true;
    }
    const hasPrMatch = !filterPrRight || claimRightTypes.includes(filterPrRight); // when there is no filter, we want to match all claims having PR rights
    const hasMrMatch = !filterMrRight || claimRightTypes.includes(filterMrRight); // when there is no filter, we want to match all claims having MR rights
    if (isPrClaim && !isMrClaim) {
      // we want to exclude claims when there is a MR filter applied and the claim has no MR rights
      return !filterMrRight && hasPrMatch;
    }
    if (!isPrClaim && isMrClaim) {
      // we want to exclude claims when there is a PR filter applied and the claim has no PR rights
      return !filterPrRight && hasMrMatch;
    }
    // the claim has both PR and MR rights
    return hasPrMatch && hasMrMatch;
  }

  static filterWorkClaims(workClaims, claimsFilter, mode, translate) {
    return this.decomposeWorkClaimsByRightType(workClaims)
      .filter(claim => {
        if (claim.type !== mode) return false;
        if (!claimsFilter) {
          return true;
        }
        const { territory, mrRight, prRight, usageDate, removeE, removeSE } = claimsFilter;
        const isRightTypeMatch = this.isRightTypeMatchFilter({ claimRightTypes: claim.rightTypes, filterPrRight: prRight, filterMrRight: mrRight });
        if (!isRightTypeMatch) return false;

        const role = claim.roleRaw;
        const removePublisher = removeE && role === 'E';
        if (removePublisher) return false;

        const removeSubPublisher = removeSE && ['AM', 'SE'].includes(role);
        if (removeSubPublisher) return false;

        const isTerritoryCorrect = this.isTerritoryMatchFilter({ claimTerritories: claim.territories, filterTerritories: territory });
        if (!isTerritoryCorrect) return false;

        const { startDate, endDate } = claim;
        const isDateCorrect = this.isDateCorrectFilter({ startDate, endDate, usageDate });
        if (!isDateCorrect) return false;

        return true;
      })
      .map((claim, index) => {
        claim.rights = this.getRightsDisplayValue(claim.rightTypes, translate);
        claim.hierarchy = index;
        return claim;
      });
  }

  static getRightsDisplayValue(rightTypes, translate) {
    const hasAllPr = difference(prRights, rightTypes).length === 0;
    const hasAllMr = difference(mrRights, rightTypes).length === 0;
    let rights = rightTypes.slice();

    if (hasAllPr) {
      if (hasAllMr) {
        rights = [translate.instant('WORKS.WORK_CLAIMS.ALL_PR_MR')];
      } else if (prRights.length === rightTypes.length) {
        rights = [translate.instant('WORKS.WORK_CLAIMS.ALL_PR')];
      }
    } else if (hasAllMr && mrRights.length === rightTypes.length) {
      rights = [translate.instant('WORKS.WORK_CLAIMS.ALL_MR')];
    }

    return rights;
  }

  static groupClaimsRowsByMode(workClaimsFiltered: any[], mode): any[] {
    return this.groupClaimsRows(workClaimsFiltered.filter(claim => claim.type === mode));
  }

  static groupClaimsRows(workClaimsFiltered: any[]): any[] {
    const groupsByUniqueKey = this.getGroupRightsByUniqueKey(workClaimsFiltered);
    const mergeGroupsDataObject = this.getMergeGroupsData(groupsByUniqueKey);
    return this.changeObjectValuesToArray(mergeGroupsDataObject);
  }

  static getGroupRightsByUniqueKey(workClaimsFiltered: any[]): object {
    return groupBy(workClaimsFiltered, item => {
      const { claimId, role, ipiNumber, prSoc, mrSoc, priorDate, startDate, endDate, postTermCollectionDate, territoriesTisa, unauthorized } = item;
      return [claimId, role, ipiNumber, prSoc, mrSoc, priorDate, startDate, endDate, postTermCollectionDate, territoriesTisa, unauthorized].join('/');
    });
  }

  static getMergeGroupsData(groupsByUniqueKey: object) {
    return mapValues(groupsByUniqueKey, (value: any) => {
      return reduce(value, (acc, current) => {
        let rights = '';
        let prShares;
        let mrShares;
        let rightTypes;
        let pr;
        let mr;
        let prTooltip;
        let mrTooltip;
        ({ prShares, mrShares, rightTypes, pr, mr, prTooltip, mrTooltip } = acc);
        if (SocietiesUtils.checkClaimHasRights(current.rightTypes, prRights)) {
          rights = `${current.rights}/${acc.rights}`;
          ({ prShares, pr, prTooltip } = current);
          rightTypes = concat(acc.rightTypes, current.rightTypes);
        }
        if (SocietiesUtils.checkClaimHasRights(current.rightTypes, mrRights)) {
          rights = `${acc.rights}/${current.rights}`;
          ({ mrShares, mr, mrTooltip } = current);
          rightTypes = concat(acc.rightTypes, current.rightTypes);
        }
        return { ...acc, rights, prShares, mrShares, rightTypes, mr, pr, mrTooltip, prTooltip };
      });
    });
  }

  private static changeObjectValuesToArray(mergeGroupsDataObject: object): object[] {
    const mergeGroupsDataArray = [];
    mapValues(mergeGroupsDataObject, value => mergeGroupsDataArray.push(value));
    return mergeGroupsDataArray;
  }
  static getRightTypesInput({
    dialog,
    translate,
    buttonHooks,
    inputHooks,
    rightType = 'PRMR',
    inputProps = {},
    buttonProps = {},
  }: {
    dialog: MatDialog;
    translate: TranslateService;
    buttonHooks?: FormlyFieldConfig['hooks'];
    inputHooks?: FormlyFieldConfig['hooks'];
    rightType?: RightTypeGroup;
    inputProps?: Omit<FormlyFieldConfig, 'hooks'>;
    buttonProps?: Omit<FormlyFieldConfig, 'hooks'>;
  }): FormlyFieldConfig {
    let triggerButton: AbstractControl;
    let rightsInput: AbstractControl;
    function openRightTypesDialog() {
      const dialogRef = DialogSharesUsesTypes.openDialog(dialog, translate, rightType, triggerButton.value[rightType], (event: { [useType: string]: boolean }) => {
        const newValue = Object.keys(pickBy(event, value => value)).filter(right => allRightsSet.has(right));
        triggerButton.setValue({ ...triggerButton.value, [rightType]: newValue });
        const rightTypes = newValue.filter(right => allRightsSet.has(right));
        rightsInput.setValue(CopyrightUtils.rightsSpecificToGeneric(rightTypes));

        dialogRef.close();
      });
    }
    return {
      className: 'flex-1',
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          className: 'flex-1 ice-no-underline',
          key: 'rightTypes',
          type: 'label',
          wrappers: ['form-field', 'wrapper-input-text'],
          templateOptions: {
            attributes: { 'data-testid': 'rightTypesInput' },
            type: 'text',
            disabled: false,
            required: false,
            placeholder: translate.instant('PRE_STEPPER_DIALOG.AGREEMENTS.TERMINATE_TAB.RIGHT_TYPES'),
          },
          validation: {
            show: true,
          },
          ...inputProps,
          hooks: {
            ...inputHooks,
            onInit: field => {
              rightsInput = field.formControl;
              inputHooks?.onInit?.(field);
            },
          },
        },
        {
          key: 'rightsCodes',
          className: 'ice-bt-ip',
          type: 'label',
          templateOptions: {
            attributes: { 'data-testid': 'rightTypesButton' },
            materialType: 'mat-icon-button',
            icons: {
              icon: 'edit',
            },
            tooltipText: translate.instant('PRE_STEPPER_DIALOG.AGREEMENTS.TERMINATE_TAB.EDIT_RIGHT_TYPES'),
            onClick: model => {
              openRightTypesDialog();
            },
          },
          ...buttonProps,
          hooks: {
            ...buttonHooks,
            onInit: field => {
              triggerButton = field.formControl;
              field.formControl.setValue({ ...triggerButton.value, [rightType]: [] });
              buttonHooks?.onInit?.(field);
            },
          },
        },
      ],
    };
  }
  /**
   * Sorts claims by:
   *  1. Publishers (E) not linked to creators
   *  2. Creators published
   *  3. Creators not published
   *
   * And adds all their children in between:
   *  ['E1', 'E1_SE1', 'E1_SE1_SE2', 'E2', 'E2_SE2',
   *  'C1', 'C1_E2', 'C1_E2_SE2', 'C2', 'C2_E2', 'C2_E2_SE2',
   *  'C3', 'C4', ...]
   */
  static getSortedClaims(claims: WorkDetail['claims'] = []) {
    const claimsByParentId = groupBy(claims, 'parentId');
    const parentClaims = claimsByParentId.undefined;
    const sortedParentClaims = sortBy(parentClaims, claim => {
      // only creators and publishers are parents
      const hasChildren = !!claimsByParentId[claim.claimId]?.length || 0;
      const isPublisher = claim.role === 'E';
      if (isPublisher) {
        return -1;
      }
      if (hasChildren) {
        return -1;
      }
      return 1;
    });
    type SortedClaim = WorkDetail['claims'][number] & {
      depth: number;
    };
    const sortSubPublisherByClaimId = (a: SortedClaim, b: SortedClaim) => {
      const isSubPublisherA = isSubPublisherRole(a.role);
      const isSubPublisherB = isSubPublisherRole(b.role);
      if (isSubPublisherA && isSubPublisherB) {
        return getSubPublisherNumberFromClaimId(a.claimId) > getSubPublisherNumberFromClaimId(b.claimId) ? 1 : -1;
      }
      return 0;
    };

    const recursivelyAddChildren = (claim: WorkDetail['claims'][number], depth = 0) => {
      const children = claimsByParentId[claim.claimId];
      sortedClaims.push({ ...claim, depth });
      if (children) {
        children.sort(sortSubPublisherByClaimId);
        children.forEach(child => {
          recursivelyAddChildren(child, depth + 1);
        });
      }
    };

    const sortedClaims: SortedClaim[] = [];
    sortedParentClaims.forEach(claim => recursivelyAddChildren(claim, 0));

    return sortedClaims;
  }
}
