import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils, StringUtils } from '@ice';
import { ActivityUtils } from '@ice/utils/activity/activity.utils';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import {
  HAS_PRIORITY_KEY,
  OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY,
  POSS_MATCH_CONFLICT_SUBTYPE,
  PROCESS_DESCRIPTION_OPTIONS,
  PUB_NO_MERGE_CONFLICT_SUBTYPE,
  UnassignedUserValue,
} from 'config/constants/activity.constants';
import { categorySocietiesCode } from 'config/constants/societies.constants';
import { isArray, map, split, startsWith } from 'lodash';
import moment from 'moment';
import { RootState } from 'store/root';
import { FormConfig, SearchExpressions } from './form-config';

export class ConflictsSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    if (!isArray(value) || (isArray(value) && value?.length)) {
      switch (key) {
        case 'workId':
          return `{"equals": {"workId":"${StringUtils.padZeros(value, 11)}"}}`;
        case 'conflictArea':
          return `{"or": [${map(split(value, ','), val =>
            val === '' ? `{"not":{"wildcard": {"caseArea": "*"}}},{"equals":{"caseArea":""}}` : `{"equals": {"caseArea":"${val}"}}`,
          ).toString()}]}`;
        case 'caseStatus':
          return JSON.stringify({
            or: split(value, ',').map(val => {
              if (val === 'NEW') {
                return { not: { exists: { field: 'caseStatus' } } };
              } else {
                return { equals: { caseStatus: val } };
              }
            }),
          });
        case 'conflictSubType':
          return `{"or": [${map(split(value, ','), val => `{"equals": {"conflictSubtype":"${val}"}}`).toString()}]}`;
        case 'conflictType':
          return `{"or": [${map(split(value, ','), val => `{"equals": {"conflictType":"${val}"}}`).toString()}]}`;
        case 'creator':
          return JSON.stringify({
            wildcard: {
              firstCreator: {
                value: `*${value}*`,
                case_insensitive: true,
              },
            },
          });
        case 'domesticSociety':
          return `{"or": [${map(split(value, ','), val => `{"equals": {"domesticSocieties":"${val}"}}`).toString()}]}`;
        case 'processDescription':
          return `{"equals": {"processDescription":"${value}"}}`;
        case 'dueDateFrom':
          return `{"range":{"deadline":{"gte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
        case 'dueDateTo':
          return `{"range":{"deadline":{"lte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
        case 'uploadCat':
          return `{"equals": {"uploadCategory":"${value}"}}`;
        case 'userId':
          if (value === UnassignedUserValue) {
            return '{"not":{"exists":{"field":"assignee"}}}';
          } else {
            if (allUsers) {
              const exactMatch = allUsers.find(user => user.fullName.toLowerCase() === value.toLowerCase());
              if (exactMatch) {
                return `{"match":{"assignee":"${exactMatch.id}"}}`;
              } else {
                const partialMatch = allUsers.filter(user => startsWith(user.fullName.toLowerCase(), value.toLowerCase()));
                if (partialMatch.length > 0) {
                  return `{"or":[${partialMatch.map(user => `{"match":{"assignee":"${user.id}"}}`).join(',')}]}`;
                }
              }
            }
            return `{"match":{"assignee":"${value}"}}`;
          }
        case 'value':
          return `{"range":{"value":{"gte": "${value}"}}}`;
        case 'valueTo':
          return `{"range":{"value":{"lt": "${value}"}}}`;
        case 'workTitle':
          return JSON.stringify({
            wildcard: {
              title: {
                value: `*${value}*`,
                case_insensitive: true,
              },
            },
          });
        case HAS_PRIORITY_KEY:
          return !value ? '' : `{"not":{"equals":{"${HAS_PRIORITY_KEY}":false}}}`;
        case OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY:
          return value ? `{"equals":{"${OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY}":${!value}}}` : '';
        case 'claimantIpiBaseNumbers':
          const ipiNameNumber = value.includes('IPI:') ? value : `IPI:${value}`;
          return `{"or": [{"match": { "claimantIpiBaseNumbers": "${ipiNameNumber}" }}]}`;
      }
      return `{"wildcard":{"${key}":"*${value}*"}}`;
    }
  }

  getExtraSearchExpression() {
    return '';
  }
}

export class SearchConflictsActivityForm implements FormConfig {
  constructor(private store: Store<RootState>, private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getForm(): FormlyFieldConfig[] {
    const translate = this.translate;
    let canChangePriority = true;
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'workId',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.WORK_KEY'),
            },
          },
          DatepickerUtils.getDatepickerField({
            key: 'dueDateFrom',
            label: this.translate.instant('ACTIVITY.DEADLINE_DATE_FROM'),
            translate,
          }),
          DatepickerUtils.getDatepickerField({
            key: 'dueDateTo',
            label: this.translate.instant('ACTIVITY.DEADLINE_DATE_TO'),
            translate,
          }),
          {
            className: 'flex-1',
            key: 'value',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'number',
              min: 0,
              placeholder: this.translate.instant('ACTIVITY.VALUE_FROM'),
            },
          },
          {
            className: 'flex-1',
            key: 'valueTo',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'number',
              min: 0,
              placeholder: this.translate.instant('ACTIVITY.VALUE_TO'),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'uploadCat',
            type: 'ice-autocomplete',
            templateOptions: {
              flex: 100,
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.UPLOAD_CATEGORY'),
              options: CopyrightUtils.createOptionsFromDictionary(categorySocietiesCode, translate, 'ACTIVITY.SOCIETIES_CATEGORIES'),
            },
          },
          ActivityUtils.getConflictAreaSelectFormlyConfig(this.translate),
          ActivityUtils.getDomesticSocietySelectFormlyConfig(this.translate),
          ActivityUtils.getCaseStatusSelectFormlyConfig(this.translate),
          ActivityUtils.getConflictTypeSelectFormlyConfig(this.translate),
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          ActivityUtils.getConflictSubTypeSelectFormlyConfig(this.translate),
          {
            className: 'flex-1',
            key: 'processDescription',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.PROCESS_DESCRIPTION'),
              options: PROCESS_DESCRIPTION_OPTIONS,
            },
          },
          {
            className: 'flex-1',
            key: 'workTitle',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.WORK_TITLE'),
            },
          },
          {
            className: 'flex-1',
            key: 'creator',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.CREATOR'),
            },
          },
          ActivityUtils.getSearchAutocompleteUsersFormlyConfig(this.store, this.translate),
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            id: 'claimantIpiBaseNumbers',
            key: 'claimantIpiBaseNumbers',
            type: 'ice-autocomplete',
            templateOptions: {
              flex: 100,
              type: 'text',
              attributes: { 'data-testid': 'select-search_activity_ipi_number' },
              allowAnyValue: true,
              listOnly: true,
              required: false,
              addTooltip: true,
              placeholder: this.translate.instant('ACTIVITY.IPI_BASE_NUMBER'),
              options: [{ value: 'IPI:I-001656684-8', label: 'Sony' }],
            },
          },
          this.getEmptyField(),
          this.getEmptyField(),
          this.getEmptyField(),
          this.getEmptyField(),
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1 infoText ice-txt-size-12-i ice-accent font-weight-normal',
            wrappers: ['wrapper-info'],
            id: OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY,
            key: OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY,
            type: 'checkbox',
            defaultValue: true,
            templateOptions: {
              label: this.translate.instant('ACTIVITY.EXCLUDE_CONTERCLAIMS'),
              infoText: translate.instant('ACTIVITY.EXCLUDE_CONTERCLAIMS_INFO'),
              wrapperClasses: 'center fit',
            },
          },
          {
            className: 'flex-1 infoText ice-txt-size-12-i ice-accent font-weight-normal',
            wrappers: ['wrapper-info'],
            id: HAS_PRIORITY_KEY,
            key: HAS_PRIORITY_KEY,
            type: 'checkbox',
            defaultValue: true,
            templateOptions: {
              label: this.translate.instant('ACTIVITY.PRIORITY_INDICATOR'),
              infoText: translate.instant('ACTIVITY.PRIORITY_INDICATOR_INFO'),
              wrapperClasses: 'center fit',
              indeterminate: false,
            },
            expressionProperties: {
              'templateOptions.disabled': (model: any, formState: any, field: FormlyFieldConfig) => {
                const allowedValues = [PUB_NO_MERGE_CONFLICT_SUBTYPE, POSS_MATCH_CONFLICT_SUBTYPE];
                const isMergeOrCreateConflict = model?.conflictSubType.length <= 2 && model?.conflictSubType.every(value => allowedValues.includes(value));
                const haveUploadCatValue = !!model?.uploadCat?.value;
                if (haveUploadCatValue && isMergeOrCreateConflict && canChangePriority) {
                  field?.formControl?.setValue(true);
                  canChangePriority = false;
                } else if (!canChangePriority) {
                  canChangePriority = true;
                }
                return false;
              },
            },
          },
          this.getEmptyField(),
          this.getEmptyField(),
          this.getEmptyField(),
        ],
      },
    ];
  }

  getEmptyField(className = 'flex-1'): any {
    return {
      className,
      template: '<span></span>',
    };
  }
}
