import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CREATORS } from 'config/constants/global.constants';
import { TYPE_PUBLISHER } from 'config/constants/shares.constants';
import { INCOME_PARTICIPANT_VALUE } from 'config/constants/works.constants';
import { ClaimantStepType } from 'config/stepper-builders/common-steps/claimant-step-type';
import { StepType } from 'config/stepper-builders/stepper-config';
import { cloneDeep, difference, get, includes, isEqual, uniqBy } from 'lodash';
import { SelectEditorDatatableRow } from 'models/copyright/detail/edit-mode';
import { map, filter, take } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';

export class ClaimDetailsStep extends ClaimantStepType {
  private fieldContributorPartyId: any;
  private fieldContributorPartyNameId: any;

  private prevLinkCreators: string[] = [];

  private modelLinkCreator = this.store.pipe(
    select(fromRoot.getOptionsWorkCreators),
    map(options => {
      options.push({ value: INCOME_PARTICIPANT_VALUE, label: this.translate.instant('CLAIM.INCOME_PARTICIPANT') });
      return uniqBy(options, option => option.value);
    }),
  );

  constructor(
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected fieldValidatorService: FieldValidatorService,
    protected store: Store<fromRoot.RootState>,
    protected storeNewItem: Store<fromForm.NewSectionItemState>,
    protected translationLoader: FuseTranslationLoaderService,
    protected permissionsService: PermissionsService,
    protected claimsType?: string,
  ) {
    super(translate, dialog, fieldValidatorService, store, storeNewItem, translationLoader, permissionsService, claimsType);
    this.getWorkPublishers();
    this.getWorkNS();
  }

  getWorkPublishers() {
    this.store
      .select(fromRoot.getWorkPublishersAndCreatorsICE)
      .pipe(
        filter(data => !!data),
        map(publishers => publishers || []),
        take(1),
      )
      .subscribe((publishers: SelectEditorDatatableRow[]) => {
        this.workPublishers = publishers;
      });
  }

  getWorkNS() {
    this.store
      .pipe(
        select(fromRoot.getCopyrightWork),
        filter(data => !!data),
        take(1),
      )
      .subscribe((detail: any) => {
        this.nsSubmitNewIp = get(detail, 'attributes.ns', 'ICEADMIN');
      });
  }

  onInitForm(form, field) {}

  onDestroyForm() {}

  getStep(): StepType {
    const translate = this.translate;
    const checkEditModeParams = false;
    return {
      label: translate.instant('CLAIM.STEP_CLAIM_DETAILS'),
      formBuilder: !!this.isWorkClaimScreen()
        ? [this.getSearchPartyField(), ...this.getRoleLineField(true, true, CREATORS), this.getSelectOriginalPublisherField(), this.getFieldsLinkCreatorAndAgreementNumber()]
        : [
            this.getSearchPartyField(checkEditModeParams),
            ...this.getRoleAndDatesLineFields(),
            this.getSelectOriginalPublisherField(),
            this.getFieldsLinkCreatorAndAgreementNumber(),
          ],
    };
  }

  resetByType(type) {}

  updateContributorFields(partyId, partyNameId) {
    this.fieldContributorPartyId.formControl.setValue(partyId);
    this.fieldContributorPartyNameId.formControl.setValue(partyNameId);
  }

  onLinkCreatorChange(field) {
    const data = field.formControl['_pendingValue'];
    const income = INCOME_PARTICIPANT_VALUE;
    if (!isEqual(data, this.prevLinkCreators)) {
      if (includes(data, income) && !includes(this.prevLinkCreators, income)) {
        this.prevLinkCreators = cloneDeep([INCOME_PARTICIPANT_VALUE]);
      } else if (includes(data, income) && includes(this.prevLinkCreators, income)) {
        this.prevLinkCreators = cloneDeep(difference(data, this.prevLinkCreators));
      } else {
        this.prevLinkCreators = cloneDeep(data);
      }
      this.fieldlinkCreator.formControl.setValue(this.prevLinkCreators);
      this.fieldlinkCreator.formControl.markAsTouched();
    }
  }

  getSearchPartyField(checkEditModeParams: boolean = true) {
    const fieldGroup = [...this.getHiddenSearchPartyFields(), ...this.getBasicSearchPartyFields(true, false, null, checkEditModeParams)];

    return {
      fieldGroupClassName: 'display-flex',
      fieldGroup,
    };
  }

  getSelectOriginalPublisherField() {
    return {
      fieldGroupClassName: 'display-flex-wrap',
      hideExpression: model => model.role !== 'SE',
      fieldGroup: [
        this.genHiddenField(TYPE_PUBLISHER, 'ParentId'),
        this.genHiddenField(TYPE_PUBLISHER, 'Info'),

        {
          hideExpression: model => !model.isPublisherFromWork,
          className: 'flex-1 selectPublisherIPIEdit',
          key: 'SelectPublisherIPI',
          type: 'select',
          templateOptions: {
            label: this.translate.instant('CLAIM.PUBLISHER_IPI'),
            required: true,
            options: this.workPublishers.map(publisher => {
              return { label: `${publisher.parent.parentPath} ${publisher.key} - ${publisher.role} - ${publisher.value}`, value: publisher.newClaimId };
            }),
            change: field => {
              const selectedValuePublisherIPI = this.workPublishers.find(publisher => publisher.newClaimId === field.formControl._pendingValue);
              this['fieldPublisherIPI'].formControl.setValue(selectedValuePublisherIPI.ipiNameNumber || '');
              this['fieldPublisherIPNameNumber'].formControl.setValue(selectedValuePublisherIPI.key);
              this['fieldPublisherName'].formControl.setValue(selectedValuePublisherIPI.value);
              this['fieldPublisherParentId'].formControl.setValue(selectedValuePublisherIPI.newClaimId);
            },
          },
          expressionProperties: {
            'templateOptions.disabled': model => this.editClaim,
          },
          hooks: {
            onInit: field => {
              this['fieldSelectPublisherIPI'] = field;
            },
          },
        },
        {
          hideExpression: model => !model.isPublisherFromWork,
          key: 'PublisherIPNameNumber',
          expressionProperties: {
            'templateOptions.disabled': model => this.editClaim,
          },
          hooks: {
            onInit: field => {
              this['fieldPublisherIPNameNumber'] = field;
              this.setPublisherData(field, 'key');
            },
          },
        },
        {
          hideExpression: model => !model.isPublisherFromWork,
          key: 'PublisherIPI',
          expressionProperties: {
            'templateOptions.disabled': model => this.editClaim,
          },
          hooks: {
            onInit: field => {
              this['fieldPublisherIPI'] = field;
              this.setPublisherData(field, 'ipiNameNumber');
            },
          },
        },
        {
          hideExpression: model => model.isPublisherFromWork,
          className: 'flex-1 field-padding-left-0 selectOriginalPublisherInput',
          key: 'PublisherIPI',
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          templateOptions: {
            label: this.translate.instant('CLAIM.PUBLISHER_IPI'),
          },
          expressionProperties: {
            'templateOptions.required': model => !this.editClaim && this['fieldPublisherName'] && !this['fieldPublisherName'].formControl.value,
            'templateOptions.disabled': model => this.editClaim,
          },
          hooks: { onInit: field => (this['fieldPublisherIPI'] = field) },
          asyncValidators: {
            expression: (control, field) => {
              const selectPublisherIPIField = get(field, 'parent.fieldGroup', []).find(fieldParent => fieldParent.key === 'SelectPublisherIPI');
              const isSelectPublisherIPIHidden = selectPublisherIPIField && selectPublisherIPIField.hide;
              const selectPublisherIPIValue = selectPublisherIPIField && selectPublisherIPIField.formControl.value;
              let xrefKey;
              if (selectPublisherIPIValue) {
                const { ipiNameNumber, key } = selectPublisherIPIValue;
                xrefKey = !isSelectPublisherIPIHidden && ((ipiNameNumber && `IPI:${ipiNameNumber}`) || (key && `ICE:${key}`));
              }
              return this.searchPartyFieldValidatorIPNameKey(TYPE_PUBLISHER, control, field, xrefKey);
            },
          },
        },
        {
          hideExpression: model => model.isPublisherFromWork || this.editClaim,
          key: 'PublisherBTSearch',
          className: 'claim-bt-search-ip',
          type: 'label',
          templateOptions: {
            materialType: 'mat-icon-button',
            icons: {
              icon: 'search',
            },
            tooltipText: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.SEARCH_PUBLISHER'),
            onClick: model => {
              this.openDialogSearchParty(TYPE_PUBLISHER, model);
            },
          },
        },
        {
          className: 'flex-1 publisherFromWorkCheckbox',
          type: 'checkbox',
          key: 'isPublisherFromWork',
          value: true,
          templateOptions: {
            label: this.translate.instant('CLAIM.PUBLISHER_FROM_WORK'),
            change: (field, $event) => {
              this['fieldPublisherIPI'].formControl.setValue(null);
              this.updatePartyFiedsWithType(TYPE_PUBLISHER, null, null, null);
            },
          },
          expressionProperties: {
            'templateOptions.disabled': model => this.editClaim,
          },
          hooks: {
            onInit: field => {
              return field.formControl.setValue(true), (this.fieldIsPublisherFromWork = field);
            },
          },
        },
        {
          className: 'flex-1 PublisherName',
          key: 'PublisherName',
          type: 'input',
          templateOptions: {
            label: this.translate.instant('CLAIM.NAME'),
            required: false,
            disabled: true,
          },
          hooks: {
            onInit: field => {
              this['fieldPublisherName'] = field;
              this.setPublisherData(field, 'value');
            },
          },
        },
        this.genHiddenField(TYPE_PUBLISHER, 'PartyId'),
        this.genHiddenField(TYPE_PUBLISHER, 'PartyNameId'),
        this.genHiddenField(TYPE_PUBLISHER, 'Type'),
        this.genHiddenField(TYPE_PUBLISHER, 'SocietyCode'),
      ],
    };
  }

  getFieldsLinkCreatorAndAgreementNumber() {
    return {
      fieldGroupClassName: 'display-flex-wrap',
      fieldGroup: [
        {
          className: 'flex-1 linkCreator',
          key: 'linkCreator',
          type: 'select',
          defaultValue: '',
          templateOptions: {
            attributes: { 'data-testid': 'select-link-creator' },
            label: this.translate.instant('CLAIM.LINK_CREATOR'),
            multiple: true,
            options: this.modelLinkCreator,
            change: this.onLinkCreatorChange.bind(this),
          },
          expressionProperties: {
            'templateOptions.required': model => model.role === 'E' || (model.role === 'SE' && !model.isPublisherFromWork),
          },
          hooks: {
            onInit: field => {
              this.store.pipe(select(fromRoot.getRouterParams), take(1)).subscribe(params => {
                if (params.tab === 'edit-claim') {
                  setTimeout(() => (field.focus = true), 100);
                }
              });
              return (this.fieldlinkCreator = field);
            },
          },
          modelOptions: {
            updateOn: 'blur',
          },
          hideExpression: model => (model.role !== 'E' && model.role !== 'SE') || (model.role === 'SE' && model.isPublisherFromWork),
        },
        {
          className: 'flex-1 field-padding-left-0',
          key: 'agreementNumber',
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          templateOptions: {
            label: this.translate.instant('CLAIM.AGREEMENT_NUMBER'),
            required: false,
          },
          expressionProperties: {
            'templateOptions.disabled': model => this.editClaim,
          },
          hideExpression: model => model.role !== 'E' && model.role !== 'SE',
          asyncValidators: {
            agreementNumberValidation: {
              expression: (control, field) => (control.value ? this.agreementNumberValidation(control, field, this.fieldValidatorService) : new Promise(resolve => resolve(true))),
              message: this.translate.instant('CLAIM.ERRORS.AGREEMENT_NUMBER_ERROR'),
            },
          },
        },
        {
          key: 'agreementId',
        },
      ],
    };
  }

  agreementNumberValidation(control, field, fieldValidatorService) {
    return new Promise((resolve, reject) =>
      fieldValidatorService.validAgreementKey(
        control,
        agreementDetail => {
          const agreementId = get(agreementDetail, 'id', null);
          if (agreementId) {
            control.parent.get('agreementId').setValue(agreementId);
            resolve(true);
          }
          resolve(false);
        },
        () => resolve(false),
      ),
    );
  }

  setPublisherData(currentField, valueParameterName) {
    if (this['fieldSelectPublisherIPI']?.formControl?.value) {
      const selectedValuePublisherIPI = this.workPublishers.find(publisher => publisher.newClaimId === this['fieldSelectPublisherIPI']?.formControl?.value);
      currentField.formControl.setValue(selectedValuePublisherIPI[valueParameterName] || '');
    }
  }
}
