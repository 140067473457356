import { SearchService } from 'services/search/search.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { DialogSharesUsesTypes } from 'config/dialog-builders/dialog-shares-uses-types';
import { SectionsConfig } from 'config/sections-config';
import { intersection, pickBy } from 'lodash';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CLAIM_TYPES, mrRights, prRights, TerritoryDataType } from 'config/constants';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceLayout } from '@ice/dynamic-components/group-component/group-component';
import { BasicButton, BasicResponse } from '@ice/components/response-error/response-error';
import * as fromApiCalls from 'config/api-calls';
import { DialogNewClaim } from 'config/dialog-builders/dialog-new-claim';
import { ClaimSteps } from 'config/stepper-builders/claim/claim-steps';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import { TerritoryUtils } from '@ice';
import { DataTableBuilder } from './data-table-builder';
import { RootDatatable } from './root-datatable';

export class CopyrightSharesDataTable extends RootDatatable implements DataTableBuilder {
  private editorialActionsDialog: MatDialogRef<DialogMultiLayoutComponent>;
  constructor(
    private props: {
      translate: TranslateService;
      fuseTranslationLoader: FuseTranslationLoaderService;
      store: Store<any>;
      dialog?: MatDialog;
      permissionsService?: PermissionsService;
      fieldValidatorService?: FieldValidatorService;
      storeNewItem?: Store<fromForm.NewSectionItemState>;
      searchService?: SearchService;
    },
  ) {
    super(props.translate);
    props.fuseTranslationLoader.loadTranslations(english);
  }

  navigateToIPSTab(event) {
    const { store } = this.props;

    if (event && event[0]) {
      if (event[0].ipiNameNumber || event[0].ipiNameKey) {
        const path = event[0].ipiNameNumber ? `IPI:${event[0].ipiNameNumber}` : `ICE:${event[0].ipiNameKey}`;
        store.dispatch(
          new fromRoot.Go({
            path: [`copyright/${SectionsConfig.IPS.name}/` + path],
          }),
        );
      }
    }
  }

  navigateToAgreementsTab(model) {
    const { store } = this.props;

    if (model && model.agreementId) {
      store.dispatch(
        new fromRoot.Go({
          path: [`copyright/${SectionsConfig.AGREEMENTS.name}/CUBE:${model.agreementId}/details`],
        }),
      );
    }
  }

  openIpsInNewTab(event) {
    const { store } = this.props;

    if (event && event.ipiNameNumber) {
      let path = `copyright/${SectionsConfig.IPS.name}/IPI:${event.ipiNameNumber}`;

      if (event.activatedColumn === 'agreementId' && event.agreementId) {
        path = `copyright/${SectionsConfig.AGREEMENTS.name}/CUBE:${event.agreementId}/details`;
      }

      store.dispatch(
        new fromRoot.OpenNewTab({
          path: [path],
        }),
      );
    }
  }

  getDefaultSorting(): SortInfo[] {
    return [];
  }

  formatSort(sort: SortInfo) {
    return { prop: '', dir: '' };
  }

  getDataTable(dataTableHeaderButtons?, editable = true, onEditStatus?, isWorkDetail = false): DataTableRow[] {
    const { store, permissionsService, translate, dialog } = this.props;
    const hideEditButton = row => {
      if (row.rawStatus !== CLAIM_TYPES.DERIVED) {
        return of(true);
      }
      return store.select(fromRoot.getEditMode).pipe(map(editMode => !editMode || !permissionsService.can('works_edit_claim')));
    };

    const dataTableSchema: DataTableRow[] = [
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_ROLE'),
        prop: 'role',
        cellClass: 'ice-cell-bold',
        flexGrow: 1.1,
        width: 80,
        comparator: (valueA, valueB) => this.generalComparator(valueA.replace(/&nbsp;/g, ''), valueB.replace(/&nbsp;/g, '')),
        tooltip: 'roleLabel',
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_NAME'),
        prop: 'name',
        flexGrow: !!onEditStatus ? 3 : 7,
        minWidth: 100,
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_NUMBER'),
        prop: 'ipiNameNumber',
        flexGrow: 2.2,
        minWidth: 70,
        tooltip: (isWorkDetail && 'ipiNameNumberTooltip') || '',
        headerTooltip: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_NUMBER_TOOLTIP'),
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_KEY'),
        prop: 'ipiNameKey',
        flexGrow: !!onEditStatus ? 2 : 2,
        minWidth: 70,
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_BASE_NUMBER'),
        prop: 'ipiBaseNumber',
        flexGrow: 2,
        minWidth: 100,
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_BASE_KEY'),
        prop: 'ipiBaseKey',
        flexGrow: 2,
        minWidth: 70,
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_INCOME_PARTICIPATION'),
        prop: 'incomeParticipantIcon',
        flexGrow: 1,
        cellClass: 'ice-justify-center',
        headerClass: 'ice-justify-center',
        icons: 'incomeParticipantCheckIcons',
        minWidth: 90,
        onClickAction: row => {
          if (editable) {
            store.dispatch(new fromRoot.UpdateField({ object: 'incomeParticipant', newValue: { ...row, incomeParticipant: !row.incomeParticipant }, type: 'edit' }));
          }
        },
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR_SOCIETY'),
        prop: 'prSociety',
        icons: 'prSocietyIcons',
        flexGrow: !!onEditStatus ? 2 : 2,
        minWidth: !!onEditStatus ? 30 : 40,
        headerTooltip: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR_SOCIETY_TOOLTIP'),
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR_SOCIETY'),
        prop: 'mrSociety',
        icons: 'mrSocietyIcons',
        flexGrow: !!onEditStatus ? 2 : 2,
        minWidth: !!onEditStatus ? 30 : 40,
        headerTooltip: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR_SOCIETY_TOOLTIP'),
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_AGREEMENT'),
        prop: 'agreementId',
        flexGrow: 2.2,
        minWidth: 100,
        badge: {
          text: 'agreementBadgeText',
          backgroundColor: 'black',
          textColor: 'white',
          tooltip: 'agreementBadgeTextTooltip',
        },
        onClickAction: row => this.navigateToAgreementsTab(row),
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_STATUS'),
        prop: 'status',
        icons: 'statusIcons',
        cellClass: 'display-flex ice-justify-center',
        headerClass: 'display-flex ice-justify-center',
        flexGrow: 2.5,
        minWidth: 100,
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_IP'),
        prop: 'refLabel',
        flexGrow: 2,
        width: 240,
        maxWidth: 240,
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_SOCIETY'),
        prop: 'society',
        flexGrow: 2,
        width: 350,
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR'),
        prop: 'pr',
        cellClass: 'ice-justify-right',
        headerClass: 'display-flex ice-justify-right',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 90,
        flexGrow: 0.7,
        comparator: (valueA, valueB) => this.numericalComparator(valueA.replace(/%/g, ''), valueB.replace(/%/g, '')),
        headerTooltip: translate.instant('CLAIMS.PR_SHARES_TOOLTIP'),
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR'),
        prop: 'mr',
        cellClass: 'ice-justify-right',
        headerClass: 'display-flex ice-justify-right',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 90,
        flexGrow: 0.7,
        comparator: (valueA, valueB) => this.numericalComparator(valueA.replace(/%/g, ''), valueB.replace(/%/g, '')),
        headerTooltip: translate.instant('CLAIMS.MR_SHARES_TOOLTIP'),
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.PERFORMANCE_REPERTOIRE'),
        prop: 'prRepertoireName',
        flexGrow: 0.7,
        headerClass: 'ice-justify-center',
        cellClass: 'ice-justify-center',
        canAutoResize: false,
        resizeable: false,
        width: 90,
        onClickAction: row => {
          store.dispatch(
            new fromRoot.Go({
              path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${row.prRepertoireId}`],
            }),
          );
        },
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.MECHANICAL_REPERTOIRE'),
        prop: 'mrRepertoireName',
        flexGrow: 0.7,
        headerClass: 'ice-justify-center',
        cellClass: 'ice-justify-center',
        canAutoResize: false,
        resizeable: false,
        width: 90,
        onClickAction: row => {
          store.dispatch(
            new fromRoot.Go({
              path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${row.mrRepertoireId}`],
            }),
          );
        },
      },
      {
        prop: 'responseStatus',
        flexGrow: 0.5,
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.RESPONSE_STATUS'),
        headerClass: 'ice-justify-center',
        cellClass: 'ice-justify-center',
        canAutoResize: false,
        resizeable: false,
      },
      {
        name: translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.ALERT'),
        prop: 'alert',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        hideTextProperty: true,
        icons: 'alertIcon',
        width: 90,
        flexGrow: 1,
        headerTooltip: ' ',
      },
      {
        name: '',
        prop: 'rowSelected',
        flexGrow: 2,
        headerCheckboxable: false,
        checkboxable: true,
      },
      {
        name: '',
        actionButtonIcon: 'delete',
        prop: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => {
          store.dispatch(new fromForm.NewItemUnselectSharePicIps(row));
        },
      },
      {
        name: '',
        prop: 'editBtn',
        actionButtonIcon: 'edit',
        resizeable: false,
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        action: row => {
          const loading$ = new BehaviorSubject<boolean>(true);
          const id = `CUBE:${row.agreementId}`;
          const mrAgreementId = row.rawMrShare.agreementId;
          const prAgreementId = row.rawPrShare.agreementId;
          const isSameAgreement = mrAgreementId === prAgreementId;
          const agreement$ = new BehaviorSubject<any>(null);

          if (isSameAgreement) {
            store.dispatch(
              new fromRoot.StartApiCall([
                {
                  apiCall: fromApiCalls.getAgreementDetails,
                  apiCallData: {
                    labels: {
                      id,
                    },
                  },
                  callBack: (response, error) => {
                    if (!error) {
                      agreement$.next(response);
                    }
                    loading$.next(false);
                  },
                },
              ]),
            );
          } else {
            loading$.next(false);
          }

          this.editorialActionsDialog = dialog.open(DialogMultiLayoutComponent, {
            data: {
              loading: loading$,
              loadingText: of(
                translate.instant('WORKS.SHARE_PICTURE.DIALOG.RETRIEVING_AGREEMENT', {
                  id: row.agreementId,
                }),
              ),
              layouts: [this.getEditDialogLayout({ row, agreement$ })],
            },
          });
        },
        hideActionButton: hideEditButton,
      },
    ];
    if (dataTableHeaderButtons) {
      return this.addDataTableHeaderButtons(dataTableSchema, dataTableHeaderButtons);
    }
    return dataTableSchema;
  }
  getEditDialogLayout({ row, agreement$ }): IceLayout {
    const mrAgreementId = row.rawMrShare.agreementId;
    const prAgreementId = row.rawPrShare.agreementId;
    const { translate, store, dialog, permissionsService, fuseTranslationLoader, fieldValidatorService, searchService, storeNewItem } = this.props;
    const response$: Observable<BasicResponse> = agreement$.pipe(
      map<any, BasicResponse>(agreement => {
        if (!agreement) {
          return { text: translate.instant('WORKS.SHARE_PICTURE.DIALOG.DIFFERENT_AGREEMENT', { prAgreementId, mrAgreementId }) };
        }
        return { text: translate.instant('WORKS.SHARE_PICTURE.DIALOG.SAME_AGREEMENT') };
      }),
    );
    const responseButtons$: Observable<BasicButton[]> = agreement$.pipe(
      withLatestFrom(
        store.select(fromRoot.getWorkPublishersAndCreatorsICE).pipe(
          filter(data => !!data),
          map(publishers => publishers || []),
        ),
        store.select(fromRoot.getOwnershipRowsSharePictureWithClaimants),
      ),
      map<any, BasicButton[]>(([agreement, publishers, allRows]) => {
        const buttons = [
          {
            text: of(translate.instant('POPUP.CLOSE')),
            action: () => {
              this.editorialActionsDialog.close();
            },
          },
        ];
        if (agreement) {
          buttons.unshift({
            text: of(translate.instant('WORKS.SHARE_PICTURE.DIALOG.ADD_DERIVED_AS_SUBMITTED')),
            action: () => {
              this.editorialActionsDialog.close();
              const newClaimDialog = new DialogNewClaim(
                translate,
                dialog,
                store,
                storeNewItem,
                ClaimSteps.getSteps(translate, fuseTranslationLoader, store, storeNewItem, fieldValidatorService, searchService, dialog, permissionsService, null),
              );
              const { inExTisns: territoryArray, tisDate: startDate } = agreement.attributes.termTerritory;
              // TODO: remove this debug console when `SelectPublisherIPI` logic is implemented
              console.debug(`%cdata available`, 'background-color: lime;', { agreement, row, publishers, allRows });
              const territory = TerritoryUtils.convertTerritoryArrayElements(territoryArray, TerritoryDataType.TISA).join(',');
              const inclusionPr = intersection(agreement.attributes.shares[0]?.rights, prRights).join(',');
              const inclusionMr = intersection(agreement.attributes.shares[0]?.rights, mrRights).join(',');
              const endDate = agreement.attributes.expectedTerminationDate;

              const initialModel = {
                shares: [
                  {
                    type: 'Mechanical',
                    share: row.rawMrShare.totalShare,
                    territory,
                    inclusion: inclusionMr,
                  },
                  { type: 'Performing', share: row.rawPrShare.totalShare, territory, inclusion: inclusionPr },
                ],
                ClaimantIPI: row.ipiNameNumber,
                SelectPublisherIPI: '', // TODO: we currently lack data from BE to select the `SelectPublisherIPI`
                role: row.roleRaw,
                startDate,
                endDate,
                agreementNumber: Number(row.agreementId),
              };

              newClaimDialog.openAddDerivedAsSubmittedDialog({ initialModel });
            },
          });
        }
        return buttons;
      }),
    );
    return {
      className: 'min-h-200',
      title: of(translate.instant('WORKS.SHARE_PICTURE.DIALOG.EDITORIAL_ACTIONS')),
      actions: [],
      layout: [
        {
          group: [
            {
              type: 'response-error',
              config: {
                response: response$,
                errorButtons: of([]),
                responseButtons: responseButtons$,
                errors: null,
              },
            },
          ],
        },
      ],
    };
  }

  getDataTableRepertoire(dataTableHeaderButtons?, editable = true, onEditStatus?, isWorkDetail = false): DataTableRow[] {
    const { store } = this.props;
    let claimantStatusDefaultValue = 'new';
    if (!editable) {
      claimantStatusDefaultValue = '';
    }
    const dataTableSchema = [
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_ROLE'),
        prop: 'role',
        cellClass: 'ice-cell-bold',
        flexGrow: 0.7,
        maxWidth: 100,
        minWidth: 50,
        comparator: (valueA, valueB) => this.generalComparator(valueA.replace(/&nbsp;/g, ''), valueB.replace(/&nbsp;/g, '')),
        tooltip: 'roleLabel',
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_NAME'),
        prop: 'name',
        flexGrow: 1,
        minWidth: 100,
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_NUMBER'),
        prop: 'ipiNameNumber',
        flexGrow: 1,
        maxWidth: 170,
        minWidth: 70,
        tooltip: (isWorkDetail && 'ipiNameNumberTooltip') || '',
        headerTooltip: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_NUMBER_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR_SOCIETY'),
        prop: 'prSociety',
        icons: 'prSocietyIcons',
        flexGrow: 1,
        minWidth: !!onEditStatus ? 30 : 60,
        headerTooltip: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR_SOCIETY_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR_SOCIETY'),
        prop: 'mrSociety',
        icons: 'mrSocietyIcons',
        flexGrow: 1,
        minWidth: !!onEditStatus ? 30 : 60,
        headerTooltip: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR_SOCIETY_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_AGREEMENT'),
        prop: 'agreementId',
        flexGrow: 1,
        maxWidth: 170,
        minWidth: 100,
        onClickAction: row => this.navigateToAgreementsTab(row),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR'),
        prop: 'pr',
        cellClass: 'ice-justify-right',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 90,
        flexGrow: 0.7,
        comparator: (valueA, valueB) => this.numericalComparator(valueA.replace(/%/g, ''), valueB.replace(/%/g, '')),
        headerTooltip: this.translate.instant('CLAIMS.PR_SHARES_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR'),
        prop: 'mr',
        cellClass: 'ice-justify-right',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 90,
        flexGrow: 0.7,
        comparator: (valueA, valueB) => this.numericalComparator(valueA.replace(/%/g, ''), valueB.replace(/%/g, '')),
        headerTooltip: this.translate.instant('CLAIMS.MR_SHARES_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.PERFORMANCE_REPERTOIRE'),
        prop: 'prRepertoireName',
        flexGrow: 3,
        width: 350,
        onClickAction: row => {
          if (!!row.prRepertoireId) {
            store.dispatch(
              new fromRoot.Go({
                path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${row.prRepertoireId}`],
              }),
            );
          }
        },
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.MECHANICAL_REPERTOIRE'),
        prop: 'mrRepertoireName',
        flexGrow: 3,
        width: 350,
        onClickAction: row => {
          if (!!row.prRepertoireId) {
            store.dispatch(
              new fromRoot.Go({
                path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${row.mrRepertoireId}`],
              }),
            );
          }
        },
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.ALERT'),
        prop: 'alert',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        hideTextProperty: true,
        icons: 'alertIcon',
        width: 90,
        flexGrow: 1,
        headerTooltip: ' ',
      },
    ];
    if (dataTableHeaderButtons) {
      return this.addDataTableHeaderButtons(dataTableSchema, dataTableHeaderButtons);
    }
    return dataTableSchema;
  }

  private addDataTableHeaderButtons(dataTableSchema: DataTableRow[], dataTableHeaderButtons: { prop: string; value: Object }[]) {
    return dataTableSchema.map(row => {
      const headerButton = dataTableHeaderButtons.find(button => button.prop === row.prop);
      if (headerButton) {
        return { ...row, ...headerButton.value };
      }
      return row;
    });
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  public openUsesTypesDialog(dialog, translate, type, usageTypesFormControl) {
    const counterClaimUsesTypes = { ...((usageTypesFormControl && usageTypesFormControl.value) || {}) };
    const dialogRef = DialogSharesUsesTypes.openDialog(dialog, translate, type, counterClaimUsesTypes[type], (event: { [useType: string]: boolean }) => {
      counterClaimUsesTypes[type] = Object.keys(pickBy(event, value => value));
      if (usageTypesFormControl) {
        usageTypesFormControl.setValue(counterClaimUsesTypes);
      }
      dialogRef.close();
    });
  }
}
