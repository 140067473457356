import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { RepertoireUtils } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { CopyrightWorksDataTable } from 'config/data-table-builders/copyright.works';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { DialogAddById } from 'config/dialog-builders/dialog-add-by-id';
import { SectionsConfig } from 'config/sections-config';
import { isEmpty } from 'lodash';
import { of, Subject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';
import { RepertoireEditInline } from './shared/edit';

export class TabRepertoireIncludedWorks implements SectionTabConfig {
  private schema: CopyrightWorksDataTable;
  private selectionDatatable: SelectionDatatableBuilder;
  private dialogAddById: DialogAddById;
  private dialogRef: MatDialogRef<any, any>;
  private unsubscribeAll = new Subject();
  private repertoireRequiredFields: any;
  private currentIds = [];
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: null,
    private detailService: null,
    private nsService: null,
    private iceFacade: null,
    private fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new CopyrightWorksDataTable(this.translate, this.fuseTranslationLoader);
    this.store
      .select(fromRoot.getRepertoireDetail)
      .pipe(
        filter(detail => !isEmpty(detail)),
        take(1),
      )
      .subscribe((detail: any) => {
        const { id, version, attributes } = detail;
        this.repertoireRequiredFields = { id, version, attributes };
      });
    const onSingleSelection = (event: any[]) => this.goTo(event);

    this.selectionDatatable = new SelectionDatatableBuilder(
      store,
      [
        ...this.schema.getDataTable(),
        ...this.schema.getRepertoireWorksField(
          this.dialog,
          this.store,
          this.translate.instant('REPERTOIRES.INCLUDED_WORKS.TAB_TITLE'),
          this.translate.instant('REPERTOIRES.INCLUDED_WORKS.DELETE_MESSAGE'),
          (row, dialogRef) => {
            const body = JSON.stringify(RepertoireUtils.getRepertoireWorkUpdateBody(this.repertoireRequiredFields, row.id));
            RepertoireEditInline.updateRepertoireWithMode(this.store, dialogRef, this.translate, this.repertoireRequiredFields.id, body, 'DELETE', row.id, this.currentIds);
          },
          !this.permissionsService.can(`repertoires_edit`),
        ),
      ],
      onSingleSelection,
      fromApiCalls.getIncludedWorks,
    );
    this.dialogAddById = new DialogAddById(this.translate, this.dialog, this.store, this.fieldValidatorService, SectionsConfig.WORKS.name);
  }

  onDestroy = () => {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  };

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('REPERTOIRES.INCLUDED_WORKS.TAB_TITLE'),
              model: this.store.pipe(
                select(fromRoot.getRepertoireIncludedWorks),
                tap(includedWorks => (this.currentIds = includedWorks?.map(work => work.id))),
              ),
              sorts: [],
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onMouseSelect: event => this.goTo(event, true),
              getRowClass: (): any => ({ 'ice-search-results-table-row': true }),
              actionButtons: of(
                this.permissionsService.can(`repertoires_edit`)
                  ? [
                      {
                        icon: 'add',
                        tooltip: `${this.translate.instant('REPERTOIRES.COMMON.ADD')} ${this.translate.instant('REPERTOIRES.INCLUDED_WORKS.TAB_TITLE')}`,
                        class: 'mat-white-icon',
                        onClick: () => {
                          this.dialogRef = this.dialogAddById.openDialog(workId => {
                            const body = JSON.stringify(RepertoireUtils.getRepertoireWorkUpdateBody(this.repertoireRequiredFields, workId));
                            RepertoireEditInline.updateRepertoireWithMode(this.store, this.dialogRef, this.translate, this.repertoireRequiredFields.id, body, 'MERGE');
                          });
                        },
                      },
                    ]
                  : [],
              ),
            },
          },
        ],
      },
    ];
  }

  private goTo(event: any, newTap: boolean = false): void {
    const workId = (Array.isArray(event) && (event[0].key || event[0].id)) || event.key || event.id;
    const path = `copyright/${SectionsConfig.WORKS.name}/${workId}`;
    if (newTap) {
      this.store.dispatch(new fromRoot.OpenNewTab({ path: [path] }));
    } else {
      this.store.dispatch(new fromRoot.Go({ path: [path] }));
    }
  }
}
