import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as englishErrors } from 'assets/i18n/en/app/formly-global-message-errors';
import { locale as english } from 'assets/i18n/en/config/section-new-data-builders';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { StepType } from '../stepper-config';
import { AgreementGroupStep } from './steps/agreement-group-step';

export class AgreementGroupSteps {
  static getSteps(
    translate: TranslateService,
    translationLoader: FuseTranslationLoaderService,
    store: Store<fromRoot.RootState>,
    storeNewItem: Store<fromForm.NewSectionItemState>,
    fieldValidatorService: FieldValidatorService,
    searchService: SearchService,
    dialog: MatDialog,
  ): StepType[] {
    translationLoader.loadTranslations(english, englishErrors);
    return [new AgreementGroupStep(translate, translationLoader, fieldValidatorService, dialog, store, storeNewItem, searchService).getStep()];
  }
}
