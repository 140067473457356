import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AgreementUtils } from '@ice/utils/agreement/agreement.utils';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { CopyrightAgreementChainsDataTable } from 'config/data-table-builders/copyright.agreement-chains';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { escape, isNumber } from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import flagsmith from 'flagsmith';

export class TabIpAgreementChains implements SectionTabConfig {
  private schema: CopyrightAgreementChainsDataTable;
  private selectionDatatable: SelectionDatatableBuilder;
  private titleSpace = 75;
  private rowHeight = 40;
  private minHeight = 1 * this.rowHeight + this.titleSpace;
  private dataTableHeight$ = new BehaviorSubject(this.minHeight);
  constructor(private translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, protected store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new CopyrightAgreementChainsDataTable(this.translate, this.fuseTranslationLoader, store);
    this.normalizeData = this.normalizeData.bind(this);
    const onSelect = ([row]) => {
      this.store
        .select(fromRoot.getIpDetailAgreementChains)
        .pipe(take(1))
        .subscribe(chains => {
          const item = chains.entities[row.id];
          const maxDepth = flagsmith.getValue('cube-ui.ips.agreement-chains.max-depth');
          if (item.depth >= maxDepth || isNumber(item.total)) {
            return;
          }

          const rootAgreement = chains.entities[item.parentAgreementIds[0]] || item;
          const parentAgreementIds = item.parent ? item.parentAgreementIds.concat(item.id) : item.parentAgreementIds;
          this.store.dispatch(
            new fromRoot.StartApiCall({
              apiCall: fromApiCalls.getAgreementChains,
              apiCallData: {
                cleanerData: { parent: item.id, depth: (item.depth || 0) + 1, parentAgreementIds },
                labels: {
                  tisDate: JSON.stringify(item.attributes.termTerritory.tisDate),
                  inExTisns: JSON.stringify(item.attributes.shares[0].territories || ['+2WL']),
                  rightTypes: JSON.stringify(item.attributes.shares[0].rights),
                  rootAssignorPartyId: rootAgreement.attributes.partyId,
                  rootAssignorPartyNameId: rootAgreement.attributes.partyNameId,
                  parentAgreementIds: JSON.stringify(parentAgreementIds),
                },
              },
              callBack: (response, error) => {
                if (error) {
                  if (error?.error?.message?.includes('Parent agreements form a cycle')) {
                    this.store.dispatch(new fromRoot.UpdateAgreementChain({ id: item.id, changes: { total: -1 } }));
                    this.store.dispatch(
                      new fromRoot.ShowSnackBar({
                        duration: 8000,
                        message: this.translate.instant('ERROR.SERVER.AGREEMENT_CYCLE'),
                        icon: 'lightbulb_outline',
                      }),
                    );
                  }
                  return;
                }
                this.store.dispatch(new fromRoot.UpdateAgreementChain({ id: item.id, changes: { total: response.total } }));
              },
            }),
          );
        });
    };
    this.selectionDatatable = new SelectionDatatableBuilder(
      this.store,
      this.schema.getDataTable(),
      onSelect,
      fromApiCalls.getIpsAgreements,
      null,
      null,
      this.store.select(fromRoot.getIpDetailAgreements),
    );
  }

  normalizeData(data: any): any {
    const { depth, total } = data;
    const normalizedAgreement = AgreementUtils.cleanAgreement(data, this.translate);
    const owner = escape(normalizedAgreement.assignee.detailTitle);
    const maxDepth = flagsmith.getValue('cube-ui.ips.agreement-chains.max-depth');
    const isMaxDepth = depth >= maxDepth;
    const isAgreementCycle = total === -1;
    const totalItems = isNumber(total) ? (isAgreementCycle ? '-' : String(total)) : '?';
    const badgeText = isMaxDepth ? '-' : totalItems;
    const totalItemsText = isNumber(total) ? this.translate.instant('IPS.AGREEMENT_CHAINS.TOTAL', { total }) : this.translate.instant('IPS.AGREEMENT_CHAINS.TOTAL_UNKNOWN');
    const badgeTooltip = isMaxDepth
      ? this.translate.instant('IPS.AGREEMENT_CHAINS.MAX_DEPTH', { maxDepth })
      : isAgreementCycle
      ? this.translate.instant('ERROR.SERVER.AGREEMENT_CYCLE')
      : totalItemsText;

    return {
      ...normalizedAgreement,
      badgeText,
      badgeTooltip,
      ownerTooltip: owner,
      agreementLevel: depth,
      ownerTemplate: `<div class="depth-container depth-${depth}"><div class="indicator color-${depth % 5}">${depth}</div></div><div class="name">${owner}</div>`,
    };
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'dataTable',
            config: {
              data: this.store.select(fromRoot.getIpDetailAgreementChains).pipe(
                withLatestFrom(this.store.select(fromRoot.getIpDetailAgreementChainsTable)),
                map(([agreementChains, ids]) => ids.map(id => this.normalizeData(agreementChains.entities[id]))),
                tap(items => this.dataTableHeight$.next(Math.min(window.innerHeight - 250, items?.length * this.rowHeight + this.titleSpace))),
              ),
              sorts: [],
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: false,
              height: this.dataTableHeight$,
              columnMode: this.schema.getColumnMode(),
              getRowClass: (row: any): any => ({ 'ice-search-results-table-row': true }),
            },
          },
        ],
      },
    ];
  }
}
