import { IpNature, mrRights, prRights } from 'config/constants/ips.constants';
import {
  ALL_RIGHTS,
  ERROR_AT_LEAST_ONE_RIGHT_FILLED,
  ERROR_AT_LEAST_ONE_RIGHT_TERRITORY,
  ERROR_NAN,
  ERROR_NO_NEGATIVE_VALUE,
  ERROR_NO_REPETITION_RIGHT_TERRITORY,
  ERROR_ONLY_2_DECIMALS,
  ERROR_TERRITORY_IS_EMPTY,
  ERROR_TERRITORY_NOT_EXISTS,
  ERROR_TOTAL_100,
  ERROR_WRITER_MECHANICAL_REASON_IS_EMPTY,
  FIRST_VALIDATOR_REQUEST_DELAY,
} from 'config/constants/shares.constants';
import { DialogSharesUsesTypes } from 'config/dialog-builders/dialog-shares-uses-types';
import { difference, get } from 'lodash';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { StorageService } from 'services/storage/storage.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { isNotValidPrimitiveNumerical, selectControlValue, TerritoryUtils } from '@ice';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { StepType } from '../../stepper-config';

export class SharesStep {
  private form: FormGroup;
  private termTerritoryCodes = new Array<number>();
  private territoriesSubscription: Subscription;
  private termTerritoryCodesSubscription: Subscription;
  private fieldSharesSubscription: Subscription;
  private termTerritoryField$ = new BehaviorSubject(true);
  private unsubscribeAll = new Subject();

  constructor(private translate: TranslateService, private dialog: MatDialog, private fieldValidatorService: FieldValidatorService, private storageService: StorageService) {
    this.fieldValidatorService.validationErrors = [
      { type: ERROR_NAN, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.NAN') },
      { type: ERROR_ONLY_2_DECIMALS, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ONLY_2_DECIMALS') },
      { type: ERROR_TOTAL_100, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.TOTAL_100') },
      { type: ERROR_AT_LEAST_ONE_RIGHT_FILLED, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ONE_RIGHT_FILLED') },
      { type: ERROR_NO_REPETITION_RIGHT_TERRITORY, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.NO_REPETITION_RIGHT') },
      { type: ERROR_AT_LEAST_ONE_RIGHT_TERRITORY, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.AT_LEAST_ONE_BY_TERRITORY') },
      { type: ERROR_NO_NEGATIVE_VALUE, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.NO_NEGATIVE_VALUE') },
      { type: ERROR_TERRITORY_NOT_EXISTS, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_TERRITORY_NOT_EXISTS') },
      { type: ERROR_TERRITORY_IS_EMPTY, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_TERRITORY_IS_EMPTY') },
      { type: ERROR_WRITER_MECHANICAL_REASON_IS_EMPTY, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_WRITER_MECHANICAL_REASON_IS_EMPTY') },
    ];
  }

  getStep(): StepType {
    return {
      label: this.translate.instant('AGREEMENTS.SHARES.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              type: 'label',
              key: 'msgValidation',
              modelOptions: { updateOn: 'submit' },
              templateOptions: { class: 'ice-error-msg' },
              asyncValidators: {
                sharesValidation: (control: FormControl, field) =>
                  new Promise((resolve, reject) => {
                    resolve(this.fieldValidatorService.errors.length === 0);
                  }),
              },
              expressionProperties: {
                className: model => (this.fieldValidatorService.errors.length === 0 && 'shares-error-hide') || '',
              },
            },
          ],
        },
        this.getRepeatSectionFormConfig(),
      ],
    };
  }

  getRepeatSectionFormConfig(): FormlyFieldConfig {
    return {
      key: 'shares',
      type: 'shares-repeat',
      templateOptions: {
        wrapperClass: 'ice-share-col-w agreement-new-shares',
        clickInclusionButton: (rightType, currentIncluded, updateUsageTypes) =>
          DialogSharesUsesTypes.showInclusionPopup(this.dialog, this.translate, rightType, currentIncluded, updateUsageTypes),
        btSelectUsageTypes: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.MODIFY_USAGE_TYPES'),
        tooltipRemove: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.REMOVE_COLUMN'),
        tooltipClone: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.CLONE_COLUMN'),
        popup: {
          title: this.translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.TITLE'),
          message: this.translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.MESSAGE'),
          ok: this.translate.instant('POPUP.OK'),
          ko: this.translate.instant('POPUP.CANCEL'),
          submitLabel: this.translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.BT_INCLUDE'),
          button1Label: this.translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.BT_CANCEL'),
        },
        rowLabels: this.fieldValidatorService.agreementFormAssignorType$.pipe(
          takeUntil(this.unsubscribeAll),
          map(value => [
            {
              label: this.translate.instant('AGREEMENTS.SHARES.TABLE.COL_TITLE_RIGHT_TYPE'),
              info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.RIGHT_TYPE'),
            },
            {
              label: this.translate.instant('AGREEMENTS.SHARES.TABLE.NO_SHAREOUT'),
              info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.NO_SHAREOUT'),
            },
            ...((value?.assignorType === IpNature.LEGAL_ENTITY && [
              {
                label: this.translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_CREATOR'),
                info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.CREATOR'),
              },
            ]) ||
              []),
            {
              label:
                value?.assignorType === IpNature.NATURAL_PERSON
                  ? this.translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_CREATOR_NATURAL_PERSON')
                  : this.translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_PUBLISHER'),
              info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.PUBLISHER'),
            },
            {
              label: this.translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_ASSIGNEE'),
              info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.ASSIGNEE'),
            },
            {
              label: this.translate.instant('AGREEMENTS.SHARES.TABLE.SHARES_TERRITORY'),
              info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.SHARES'),
            },
            {
              label: this.translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_INCLUSION'),
              info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.INCLUSION'),
            },
          ]),
        ),
        addButtons: {
          addMechanical: this.translate.instant('AGREEMENTS.SHARES.BOTTOM_BUTTONS.ADD_MECHANICAL'),
          addPerforming: this.translate.instant('AGREEMENTS.SHARES.BOTTOM_BUTTONS.ADD_PERFORMING'),
          addSynchronisation: this.translate.instant('AGREEMENTS.SHARES.BOTTOM_BUTTONS.ADD_SYNCHRONISATION'),
          addPrint: this.translate.instant('AGREEMENTS.SHARES.BOTTOM_BUTTONS.ADD_PRINT'),
        },
        showButtons: of(true),
      },
      hooks: {
        onInit: field => {
          this.form = field.form;
          this.fieldValidatorService.fieldShares = field;
          this.fieldSharesSubscription = field.formControl.valueChanges.subscribe(data => {
            this.fieldValidatorService.generalSharesValidator(this.termTerritoryCodes);
          });
          if (this.form && this.form.get('msgValidation')) {
            this.fieldValidatorService.msgValidationControl = this.form.get('msgValidation');
            this.fieldValidatorService.generalSharesValidator(this.termTerritoryCodes);
          }
        },
        onDestroy: () => {
          if (this.territoriesSubscription) {
            this.territoriesSubscription.unsubscribe();
          }
          if (this.termTerritoryCodesSubscription) {
            this.termTerritoryCodesSubscription.unsubscribe();
          }
          if (this.fieldSharesSubscription) {
            this.fieldSharesSubscription.unsubscribe();
          }
        },
      },
      fieldArray: {
        fieldGroupClassName: 'ice-share-col-w',
        fieldGroup: [
          {
            className: 'display-flex-row-justify-left',
            type: 'label',
            defaultValue: 'Mechanical',
            key: 'type',
            modelOptions: { updateOn: 'submit' },
            templateOptions: { class: 'ice-bold' },
          },
          {
            fieldGroupClassName: 'display-flex ',
            fieldGroup: [
              {
                className: 'ice-form-field-infix ice-ml-minus-30',
                key: 'noShareout',
                type: 'checkbox',
                defaultValue: false,
                templateOptions: {
                  attributes: { 'data-testid': 'input_noShareout' },
                  required: false,
                },
                hooks: {
                  onInit: field => {
                    field.templateOptions.subscriptionChanges = field.formControl.valueChanges.subscribe(noShareout => {
                      if (noShareout) {
                        field.form.controls['creator']?.setValue('0.00');
                        field.form.controls['publisher']?.setValue('0.00');
                        field.form.controls['creator']?.disable();
                        field.form.controls['publisher']?.disable();
                      } else {
                        field.form.controls['creator']?.enable();
                        field.form.controls['publisher']?.enable();
                      }
                    });
                  },
                  onDestroy: field => {
                    if (field && field.templateOptions && field.templateOptions.subscriptionChanges) {
                      field.templateOptions.subscriptionChanges.unsubscribe();
                    }
                  },
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'display-flex-row-justify-left',
            fieldGroup: [
              {
                className: 'ice-form-field-infix shares-input-number-field',
                type: 'ice-input',
                key: 'creator',
                defaultValue: 0,
                modelOptions: { updateOn: 'blur' },
                templateOptions: {
                  attributes: { 'data-testid': 'input_creator' },
                  type: 'number',
                  min: 0,
                  max: 100,
                  required: true,
                  hideTooltip: true,
                },
                hooks: {
                  onInit: field => {
                    this.onInitShareField(field);
                    this.fieldValidatorService.agreementFormAssignorType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
                      if ([IpNature.NATURAL_PERSON, IpNature.LEGAL_ENTITY].includes(value?.assignorType) && !value?.isInitialValue) {
                        field.formControl.setValue('0.00');
                      }
                    });
                  },
                  onDestroy: field => this.onDestroyShareField(field),
                },
              },
              {
                className: 'ice-form-field-infix ice-form-field-infix-edge',
                template: '%',
              },
            ],
          },
          {
            fieldGroupClassName: 'display-flex-row-justify-left',
            fieldGroup: [
              {
                className: 'ice-form-field-infix shares-input-number-field',
                type: 'ice-input',
                key: 'publisher',
                defaultValue: 0,
                modelOptions: { updateOn: 'blur' },
                templateOptions: {
                  type: 'number',
                  min: 0,
                  max: 100,
                  required: true,
                  hideTooltip: true,
                },
                hooks: {
                  onInit: field => {
                    this.onInitShareField(field);
                    this.onInitPublisherField({ field, setValueToDefault: true });
                  },
                  onDestroy: field => this.onDestroyShareField(field),
                },
              },
              {
                className: 'ice-form-field-infix ice-form-field-infix-edge',
                template: '%',
                hooks: {
                  onInit: field => {
                    this.onInitPublisherField({ field });
                  },
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'display-flex-row-justify-left',
            fieldGroup: [
              {
                className: 'ice-form-field-infix',
                type: 'ice-input',
                key: 'assignee',
                defaultValue: 0,
                modelOptions: { updateOn: 'blur' },
                templateOptions: {
                  attributes: { 'data-testid': 'input_assignee_collectable_share' },
                  type: 'number',
                  min: 0,
                  max: 100,
                  required: true,
                  hideTooltip: true,
                },
                hooks: {
                  onInit: field => this.onInitShareField(field, true),
                  onDestroy: field => this.onDestroyShareField(field),
                },
              },
              {
                className: 'ice-form-field-infix ice-form-field-infix-edge',
                type: 'label',
                template: '%',
              },
            ],
          },
          {
            className: 'ice-form-field-infix-no-percentage',
            type: 'ice-autocomplete',
            key: 'territory',
            modelOptions: { updateOn: 'blur' },
            defaultValue: { value: '2WL', label: '2WL' },
            templateOptions: {
              allowAnyValue: true,
              listOnly: true,
              required: true,
              addTooltip: true,
              options: this.termTerritoryField$.pipe(
                map(_ => (this.storageService.getEntity('agreement_term_territory_field') || []).map(territory => ({ value: territory, label: territory }))),
              ),
              codes: [],
            },
            hooks: {
              onInit: field => {
                const form = field.form;
                field.templateOptions.bindedFieldCodes = form.controls.codes;
                field.templateOptions.subscriptionChanges = field.formControl.valueChanges.subscribe(territory => {
                  if (!territory || (territory && territory.length === 0)) {
                    this.fieldValidatorService.addError(ERROR_TERRITORY_IS_EMPTY, field.id);
                  } else {
                    this.fieldValidatorService.removeError(ERROR_TERRITORY_IS_EMPTY, field.id);
                  }
                });
              },
              onDestroy: field => {
                this.fieldValidatorService.removeError(ERROR_TERRITORY_IS_EMPTY, field.id);
                if (field && field.templateOptions && field.templateOptions.subscriptionChanges) {
                  field.templateOptions.subscriptionChanges.unsubscribe();
                }
              },
            },
            asyncValidators: {
              territoryValidator: {
                expression: (control, field) =>
                  new Promise(resolve => setTimeout(resolve, FIRST_VALIDATOR_REQUEST_DELAY)).then(
                    () => this.fieldValidatorService.useValidatorOnTouch(field) || this.territoryValidator(control, field),
                  ),
                message: this.translate.instant('AGREEMENT.SHARES.VALIDATION.ERROR_TERRITORY_NOT_EXISTS'),
              },
            },
          },
          ...TerritoryUtils.getTerritoryHiddenFields(this.fieldValidatorService),
          {
            className: 'ice-form-field-infix-no-percentage w-50 ice-overflow-x-hidden-span',
            type: 'ice-input',
            key: 'inclusion',
            defaultValue: 'ALL',
            modelOptions: { updateOn: 'blur' },
            templateOptions: {
              required: true,
              disabled: true,
            },
            hooks: {
              onInit: field => {
                const rights = get(field, 'formControl.value', '').split(',') || [];
                if (rights && rights.length) {
                  if (difference(prRights, rights).length === 0 || difference(mrRights, rights).length === 0) {
                    field.formControl.setValue(ALL_RIGHTS, { emitEvent: false });
                  }
                }
              },
            },
          },
        ],
      },
    };
  }

  onInitShareField(field, ignoreShareout = false) {
    if (!ignoreShareout && field.form.controls['noShareout'].value) {
      field.formControl.disable();
    }
    if (isNotValidPrimitiveNumerical(field.formControl.value) || field.formControl.value === 0) {
      field.formControl.setValue('0.00');
    }
    field.templateOptions.subscriptionChanges = field.formControl.valueChanges.subscribe(share => {
      if (share >= 0 && share <= 100) {
        this.fieldValidatorService.removeError(ERROR_NO_NEGATIVE_VALUE, field.id);
      } else {
        this.fieldValidatorService.addError(ERROR_NO_NEGATIVE_VALUE, field.id);
      }
      if (isNotValidPrimitiveNumerical(share) || share === 0) {
        field.formControl.setValue('0.00');
      } else if (share !== '0.00' && !Number.isInteger(share)) {
        if ((share && share.includes('.') && share.split('.')[1].length === 1) || !share.includes('.')) {
          field.formControl.setValue(parseFloat(share).toFixed(2));
        }
      }
    });
  }

  onDestroyShareField(field) {
    this.fieldValidatorService.removeError(ERROR_NO_NEGATIVE_VALUE, field.id);
    if (field && field.templateOptions && field.templateOptions.subscriptionChanges) {
      this.fieldValidatorService.removeAllErrorsField(field.id);
      field.templateOptions.subscriptionChanges.unsubscribe();
    }
  }

  territoryValidator(control, field) {
    return this.fieldValidatorService.existTerritory(
      control,
      field,
      () => this.fieldValidatorService.addError(ERROR_TERRITORY_NOT_EXISTS, field.id),
      res => {
        if (res && res['tisns']) {
          this.storageService.setEntity('agreement_term_territory_field', [field.templateOptions.lastValue.toUpperCase()], true, 20);
          this.termTerritoryField$.next(true);
          field.templateOptions.bindedFieldCodes.setValue(res['tisns']);
        }
        this.fieldValidatorService.removeError(ERROR_TERRITORY_NOT_EXISTS, field.id);
      },
    );
  }

  onInitPublisherField({ field, setValueToDefault = false }: { field: FormlyFieldConfig; setValueToDefault?: boolean }) {
    this.fieldValidatorService.agreementFormAssignorType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
      if ([IpNature.NATURAL_PERSON, IpNature.LEGAL_ENTITY].includes(value?.assignorType)) {
        this.updatePublisherField({ field, setValueToDefault, assignorType: value.assignorType });
      }
    });
  }

  updatePublisherField({ field, setValueToDefault, assignorType }: { field: FormlyFieldConfig; setValueToDefault: boolean; assignorType: string }) {
    const hidePublisher = assignorType === IpNature.NATURAL_PERSON;
    if (hidePublisher && setValueToDefault) {
      field.formControl.setValue('0.00');
    }
    field.hide = hidePublisher;
  }

  formlyOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
