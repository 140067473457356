import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { ErrorsUtils } from '@ice/utils/api-responses/errors.utils';
import { select } from '@ngrx/store';
import { SectionsConfig } from 'config/sections-config';
import { AgreementGroupSteps } from 'config/stepper-builders/agreement-group/agreement-group-steps';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import * as fromForm from 'store/form';

export class DialogCreateAgreementGroup {
  static openDialog(dialog, storeNewItem, translate, translationLoader, fieldValidatorService, onItemCreation) {
    const copyrightAgreementGroupStepper = AgreementGroupSteps.getSteps(translate, translationLoader, null, storeNewItem, fieldValidatorService, null, dialog);
    let groupModel = {};
    const isFormValid = new BehaviorSubject(false);
    const dialogCreateAgreementGroupRef = dialog.open(DialogMultiLayoutComponent, {
      data: {
        loading: storeNewItem.pipe(select(fromForm.getNewSectionLoading)),
        layouts: [
          {
            title: of(translate.instant('AGREEMENT_GROUP.CREATE_POPUP_TITLE')),
            actions: [
              { tooltip: translate.instant('POPUP.CANCEL'), color: 'warn', icon: 'close', onClick: () => dialogCreateAgreementGroupRef.close() },
              {
                tooltip: translate.instant('POPUP.CONFIRM'),
                icon: 'done',
                disabled: isFormValid.pipe(map(isValid => !isValid)),
                onClick: () => {
                  isFormValid
                    .subscribe(isValid => {
                      if (isValid && groupModel) {
                        const section = SectionsConfig.AGREEMENT_GROUP.name;

                        storeNewItem.dispatch(new fromForm.SaveNewSectionItem({ section, fields: groupModel }));

                        dialogCreateAgreementGroupRef.componentInstance.setLayout(1);

                        this.dialogResultHandler(storeNewItem, onItemCreation, dialogCreateAgreementGroupRef);
                      }
                    })
                    .unsubscribe();
                },
              },
            ],
            layout: [
              {
                group: [
                  {
                    type: 'stepper',
                    config: {
                      stepperConfig: {
                        model: {},
                        steps: copyrightAgreementGroupStepper,
                      },
                      change: model => {
                        groupModel = cloneDeep(model);
                      },
                      setValidForm: isValid => {
                        isFormValid.next(isValid);
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            title: of(translate.instant('POPUP.RESULTS')),
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    config: {
                      response: of(null),
                      responseButtons: of(null),
                      errors: storeNewItem.pipe(
                        select(fromForm.getNewSectionResponse),
                        map(response => ErrorsUtils.getResponseErrors(response)),
                      ),
                      errorButtons: of([
                        {
                          text: of(translate.instant('POPUP.BACK')),
                          action: () => {
                            storeNewItem.dispatch(new fromForm.NewItemResetResponse());
                            dialogCreateAgreementGroupRef.componentInstance.setLayout(0);
                          },
                        },
                        {
                          text: of(translate.instant('POPUP.CLOSE')),
                          action: () => {
                            storeNewItem.dispatch(new fromForm.NewItemResetResponse());
                            dialogCreateAgreementGroupRef.close();
                          },
                        },
                      ]),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }

  static dialogResultHandler(store, onItemCreation, dialogRef) {
    store
      .pipe(
        select(fromForm.getNewSectionResponse),
        filter(response => !!response),
        take(1),
        map(response => [ErrorsUtils.getResponseErrors(response), response]),
        filter(([responseErrors, response]) => !responseErrors),
        tap(([responseErrors, response]) => (onItemCreation(response), store.dispatch(new fromForm.NewItemResetResponse()), dialogRef.close())),
      )
      .subscribe();
  }
}
