import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {
  CopyrightOwnershipTableItem,
  CopyrightUtils,
  IceOptions,
  RelationsUtils,
  SharePictureTypes,
  SharePictureUtils,
  SharesFilter,
  WorkDetail,
  WorkUtils,
  fieldConfig,
  fieldInput,
  fieldInputRequiredEditable,
  fieldSelectRequired,
  selectControlValue,
} from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { DialogUtils } from '@ice/utils/dialog/dialog.utils';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';
import { LocalStorageUtils } from '@ice/utils/local-storage/localstorage.utils';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { locale as englishEditMode } from 'assets/i18n/en/config/tab-edit-builders';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { langConversionItem } from 'assets/ts/lang-works';
import { ROLES } from 'config/constants/global.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import {
  DEFAULT_DISTRIBUTION_SOCIETY_MR,
  DEFAULT_DISTRIBUTION_SOCIETY_PR,
  DEFAULT_FILTER_TERRITORY,
  DEFAULT_SHARES_FILTER_FORM,
  PUBLISHER_ROLES_LIST,
  SUBPUBLISHER_ROLES,
  workDetailVisibleColumns,
  workTitlesVisibleColumns,
} from 'config/constants/shares.constants';
import { WORK_CATEGORY_OPTIONS, WORK_MUSIC_RELATIONSHIP_OPTIONS, WORK_SOURCE_OPTIONS } from 'config/constants/works.constants';
import { CopyrightSharesDataTable } from 'config/data-table-builders/copyright.shares';
import { DIALOG_NORMAL_BT, DialogForm } from 'config/dialog-builders/dialog-form';
import { SharesFilterConfig } from 'config/filter-builders/shares-filter';
import { environment } from 'config/env';
import { IceFacade } from 'facades/ice.facade';
import { clone, get, isEmpty, isEqual, some, sortBy, without } from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter as rxjsFilter, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import * as fromForm from 'store/form';
import { SearchService } from 'services/search/search.service';
import { TabExtProtection } from './shared/tab-ext-protection';

const HEIGHT_TOP_CARDS = 430;
const HEIGHT_TOP_OTHER_INFORMATION = 220;
const HEIGHT_TOP_TITLES = 446;
export class TabWorkDetail extends TabExtProtection {
  private copyrightSharesDatatable: CopyrightSharesDataTable;
  private workId: string;
  private expandTopCards$ = new BehaviorSubject<boolean>(false);
  private unsubscribeAll = new Subject();
  isEditMode: boolean;
  work: WorkDetail;
  dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  canEditTitles = false;
  canEditXrefs = false;
  canEditSocietyMarkers = false;
  canEditDuration = false;
  canEditOrigin = false;
  canEditTextMusicRelationship = false;
  canEditLyricAdaptation = false;
  canEditPurpose = false;
  canEditGenre = false;
  canEditLanguage = false;
  canEditMusicArrangement = false;
  canEditCategory = false;
  canEditPublicationDate = false;
  canViewData = false;
  canViewManuscript = false;
  private isISWC: boolean;
  lastPublicationDate: string;
  private filteredTitles$ = new BehaviorSubject<any>([]);
  private titles$ = this.store.select(fromRoot.getWorkDetailAlternativeTitles).pipe(
    map(titles => {
      const formattedTitles = WorkUtils.getTitleOfTitles(this.translate, titles);
      this.filteredTitles$.next(formattedTitles);
      return formattedTitles;
    }),
  );
  private expandOtherInfoCards$ = new BehaviorSubject<boolean>(false);
  private isForcedNs: boolean;
  private hierarchySort$ = new BehaviorSubject(true);
  // permissions
  private canViewAuditInfo = false;
  private viewSharesRestrictively = false;
  private searchXrefEvents$ = new Subject<FormlyFieldConfig>();
  private xrefOptions$ = new BehaviorSubject<any[]>(WorkUtils.getXrefGroupedOptions(this.translate));
  private xrefOptions = [];
  private editMode$ = this.store.select(fromRoot.getEditMode);
  private userCurrentOrganization$ = this.store.select(fromRoot.getUserCurrentOrganization);
  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected dialog: MatDialog,
    protected commonApiService: CommonApiService,
    protected detailService: DetailService,
    protected nsService: NamespaceService,
    protected iceFacade: IceFacade,
    protected fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
    protected searchService: SearchService,
    protected storeNewItem: Store<fromForm.NewSectionItemState>,
  ) {
    super(translate, fuseTranslationLoader, store, dialog, commonApiService, detailService, nsService, iceFacade, fieldValidatorService, permissionsService);
    this.xrefOptions$.pipe(takeUntil(this.unsubscribeAll)).subscribe(xrefOptions => {
      this.xrefOptions = xrefOptions.map(group => ({ ...group, options: sortBy(group.options, 'label') }));
    });
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishEditMode);
    this.store
      .pipe(
        select(fromRoot.getWorkDetailWork),
        withLatestFrom(this.store.select(fromRoot.getRouterQueryParams), this.store.select(fromRoot.getForcedNS)),
        takeUntil(this.unsubscribeAll),
      )
      .subscribe(([work, queryParams, isForcedNs]: [WorkDetail, { [key: string]: string }, boolean]) => {
        this.work = work;
        this.isForcedNs = isForcedNs;
        if (isForcedNs || (work && queryParams?.expandWSM)) {
          this.expandTopCards();
        }
        if (isForcedNs) {
          this.expandOtherInfoCards();
        }
      });
    this.editMode$.pipe(takeUntil(this.unsubscribeAll)).subscribe((editMode: boolean) => {
      this.isEditMode = editMode;
      if (editMode) {
        this.canEditTitles = this.permissionsService.can('works_details_titles');
        this.canEditXrefs = this.permissionsService.can('works_details_xrefs');
        this.canEditSocietyMarkers = this.permissionsService.can('works_details_societyMarkers');
        this.canEditDuration = this.permissionsService.can('works_details_duration');
        this.canEditOrigin = this.permissionsService.can('works_details_origin');
        this.canEditTextMusicRelationship = this.permissionsService.can('works_details_textMusicRelationship');
        this.canEditLyricAdaptation = this.permissionsService.can('works_details_lyricAdaption');
        this.canEditPurpose = this.permissionsService.can('works_details_purpose');
        this.canEditGenre = this.permissionsService.can('works_details_genre');
        this.canEditLanguage = this.permissionsService.can('works_details_language');
        this.canEditMusicArrangement = this.permissionsService.can('works_details_musicArrangement');
        this.canEditCategory = this.permissionsService.can('works_details_category');
        this.canEditPublicationDate = this.permissionsService.can('works_details_publicationDate');
        this.fieldValidatorService.checkFormlyExpressions();
      }
    });
    this.copyrightSharesDatatable = new CopyrightSharesDataTable({
      translate: this.translate,
      fuseTranslationLoader: this.fuseTranslationLoader,
      store: this.store,
      dialog: this.dialog,
      permissionsService: this.permissionsService,
      fieldValidatorService: this.fieldValidatorService,
      storeNewItem: this.storeNewItem,
    });
    this.setWorkId();
    this.disableToggleTree();
    this.store
      .select(fromRoot.getForcedNS)
      .pipe(take(1))
      .subscribe(forcedNS => {
        this.canViewAuditInfo = this.permissionsService.can('ips_works_share_picture_audit_info_read') && !forcedNS;
      });
    this.canViewData = this.permissionsService.can('works_details_data');
    this.canViewManuscript = this.permissionsService.can('works_details_manuscript');
    this.userCurrentOrganization$.pipe(withLatestFrom(this.store.select(fromRoot.getUserDetailRoles)), takeUntil(this.unsubscribeAll)).subscribe(([currentOrganization, roles]) => {
      this.viewSharesRestrictively = currentOrganization?.type === OrganizationType.publisher && [ROLES.viewer, ROLES.publisherExtendedViewer].includes(roles);
    });

    this.searchXrefEvents$.pipe(debounceTime(250)).subscribe({
      next: (field: FormlyFieldConfig) => {
        WorkUtils.getXrefPublisherReference({
          field,
          store: this.store,
          translate: this.translate,
          callback: ({ backendXrefList, frontXrefList, backendOption, frontOption }) => {
            if (backendXrefList.length > 0) {
              this.xrefOptions$.next([{ header: 'Publisher', options: backendXrefList }]);
            } else {
              this.xrefOptions$.next(frontXrefList);
            }
          },
        });
      },
    });
  }

  getConf(): IceGroupComponent[] {
    return this.isForcedNs ? [...this.getBasicDetailInfo()] : [...this.getBasicDetailInfo(), ...this.getSharePictureInfo()];
  }

  getSharePictureInfo(): IceGroupComponent[] {
    const ownership$ = this.store.select(fromRoot.getOwnershipRowsSharePictureWithClaimants).pipe(this.normalizeRows());
    const payment$ = this.store.select(fromRoot.getPaymentRowsSharePictureWithClaimants).pipe(this.normalizeRows());
    const sharesData$ = this.store.select(fromRoot.getDataRowsSharePictureWithClaimants).pipe(this.normalizeRows());
    const manuscript$ = this.store.select(fromRoot.getManuscriptData).pipe(this.normalizeRows());
    const editMode$ = this.editMode$;
    return [
      {
        group: [
          {
            type: 'cardWithShares',
            config: {
              title: '',
              loading: this.store.select(fromRoot.getLoadingShares),
              noPermissionTitle: this.translate.instant('WORKS.SHARE_PICTURE.NO_PERMISSION'),
              shareTypes: [
                {
                  type: SharePictureTypes.MANUSCRIPT,
                  label: this.translate.instant('WORKS.SHARE_PICTURE.MANUSCRIPT'),
                  hidden: of(!this.canViewManuscript),
                },
                {
                  type: SharePictureTypes.DATA,
                  label: this.translate.instant('WORKS.SHARE_PICTURE.DATA'),
                  hidden: of(!this.canViewData),
                },
                {
                  type: SharePictureTypes.OWNERSHIP,
                  label: this.translate.instant('WORKS.SHARE_PICTURE.OWNERSHIP'),
                  checked: true,
                },
                {
                  type: SharePictureTypes.PAYMENT,
                  label: this.translate.instant('WORKS.SHARE_PICTURE.PAYMENT'),
                },
              ],
              filter: this.getFilterShares(this.store, this.translate, this.nsService),
              table: {
                model: {
                  manuscript: manuscript$,
                  ownership: ownership$,
                  payment: payment$,
                  data: sharesData$,
                  default: of([]),
                },
                requestStatus: this.store.select(fromRoot.getSharesRequestStatus),
                class: {
                  manuscript: 'manuscript-datatable',
                  default: 'work-share-pictures-table',
                },
                visibleColumns: {
                  manuscript: editMode$.pipe(
                    map(isEdit => {
                      const finalVisibleColumns = without(workDetailVisibleColumns, 'alert');
                      if (isEdit) {
                        finalVisibleColumns.push('editBtn');
                      }
                      return finalVisibleColumns;
                    }),
                  ),
                  default: ownership$.pipe(
                    switchMap(ownershipRowsSharePictureWithClaimants => {
                      const columnsToRemove: string[] = [];
                      const hasMrShareTotalValues = some(ownershipRowsSharePictureWithClaimants, 'rawMrShare.totalShare');
                      const hasPrShareTotalValues = some(ownershipRowsSharePictureWithClaimants, 'rawPrShare.totalShare');

                      return editMode$.pipe(
                        withLatestFrom(this.store.select(fromRoot.hasOwnershipIncomeParticipant)),
                        map(([editMode, hasOwnershipIncomeParticipant]) => {
                          const finalVisibleColumns = clone(workDetailVisibleColumns);
                          if (editMode || (!editMode && hasOwnershipIncomeParticipant)) {
                            finalVisibleColumns.splice(6, 0, 'incomeParticipantIcon');
                            finalVisibleColumns.push('editBtn');
                          }
                          if (this.viewSharesRestrictively && !hasMrShareTotalValues && !hasPrShareTotalValues) {
                            columnsToRemove.push('mr', 'pr');
                          }
                          return without(finalVisibleColumns, ...columnsToRemove);
                        }),
                      );
                    }),
                  ),
                },
                schema: editMode$.pipe(map(editMode => this.copyrightSharesDatatable.getDataTable(null, editMode, null, true))),
                selectionType: editMode$.pipe(map(editMode => (editMode && SelectionType.checkbox) || SelectionType.single)),
                select: {
                  default: {
                    onSelect: (event: any[]) => {
                      this.copyrightSharesDatatable.navigateToIPSTab(event);
                    },
                    onMouseSelect: event => {
                      this.copyrightSharesDatatable.openIpsInNewTab(event);
                    },
                  },
                },
                sorts: [{ prop: 'hierarchy', dir: 'asc' }],
                sortReset: true,
                disabledSort: this.hierarchySort$,
                onSort: sort => (get(sort, '[0].prop') === 'hierarchy' ? this.hierarchySort$.next(true) : this.hierarchySort$.next(false)),
              },
              tree: {
                levels: {
                  data: this.store.select(fromRoot.getDataLevelsSharePicture),
                  ownership: this.store.select(fromRoot.getOwnershipLevelsSharePicture),
                  payment: this.store.select(fromRoot.getPaymentLevelsSharePicture),
                  default: of(null),
                },
                visibility: {
                  manuscript: of(false),
                  default: of(true),
                },
                model: {
                  data: this.store.select(fromRoot.getDataTreeSharePicture),
                  ownership: this.store.select(fromRoot.getOwnershipTreeSharePicture),
                  payment: this.store.select(fromRoot.getPaymentTreeSharePicture),
                  default: of([]),
                },
              },
              totals: {
                totalParser: (rows: any) => SharePictureUtils.getSharesTotals(rows),
                publisherSubTotalLabel: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_PUBLISHER_SUB_TOTAL'),
                writerSubTotalLabel: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_CREATOR_SUB_TOTAL'),
                specialSubTotalLabel: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_SPECIAL_SUB_TOTAL'),
                totalClaimsLabel: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_TOTAL'),
                class: {
                  manuscript: of('manuscript-totals'),
                  default: ownership$.pipe(
                    map(ownershipRowsSharePictureWithClaimants => {
                      const hasMrShareTotalValues = some(ownershipRowsSharePictureWithClaimants, 'rawMrShare.totalShare');
                      const hasPrShareTotalValues = some(ownershipRowsSharePictureWithClaimants, 'rawPrShare.totalShare');
                      const isTotalsHidden = this.viewSharesRestrictively && !hasMrShareTotalValues && !hasPrShareTotalValues;
                      const classNames = ['hierarchy-colo-totals', 'work-detail-alert-col-margin'];
                      if (isTotalsHidden) {
                        classNames.push('ice-visibility-hidden');
                      }
                      return classNames.join(' ');
                    }),
                  ),
                },
              },
              widgetSwitch: {
                model: {
                  disabled: this.disableToggleButton,
                  titleFront: this.translate.instant('WORKS.SHARE_PICTURE.SHOW_TREE_CHART'),
                  titleBack: this.translate.instant('WORKS.SHARE_PICTURE.SHOW_TABLE_VIEW'),
                  cssClass: { 'ice-h-auto': true, 'ice-overflow-scroll': false },
                  frontIcon: 'account_tree',
                  backIcon: 'table_chart',
                },
              },
              extraButtons: [
                {
                  type: 'button',
                  key: 'auditInfo',
                  templateOptions: {
                    text: this.translate.instant('WORKS.SHARE_PICTURE.AUDIT_INFO'),
                    btnType: 'info',
                    isHidden: of(!this.canViewAuditInfo),
                    icon: 'format_list_bulleted',
                    onClick: () => this.openAuditInfoLogDialog(),
                  },
                },
                {
                  type: 'button',
                  key: 'addEnquiry',
                  templateOptions: {
                    text: this.translate.instant('WORKS.SHARE_PICTURE.REQUEST_ICE_UPDATE'),
                    btnType: 'info',
                    isHidden: editMode$.pipe(
                      withLatestFrom(this.userCurrentOrganization$),
                      map(([editMode, userCurrentOrganization]) => {
                        if (!editMode || !userCurrentOrganization) {
                          return true;
                        } else {
                          const can = this.permissionsService.can('works_details_add_enquiry');
                          return !can;
                        }
                      }),
                    ),
                    onClick: () => {
                      this.userCurrentOrganization$
                        .pipe(
                          rxjsFilter(userCurrentOrganization => !!userCurrentOrganization),
                          withLatestFrom(this.store.select(fromRoot.getWorkId)),
                          take(1),
                        )
                        .subscribe(([userCurrentOrganization, workId]) => {
                          const url = `${environment.urlImbIceEnquiry}/${Number(CopyrightUtils.getKeySuffix(workId))}/${environment.urlImbIceEnvironmentPath}/${
                            userCurrentOrganization?.name
                          }`;
                          window.open(url, '_blank');
                        });
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    ];
  }

  getBasicDetailInfo(): IceGroupComponent[] {
    const { translate } = this;
    return [
      {
        group: [
          {
            type: 'card',
            flex: 40,
            config: {
              class: 'flex-1',
              onOpen: this.expandTopCards.bind(this),
              onClosed: this.collapseTopCards.bind(this),
              expanded: this.expandTopCards$,
              showCounter: this.titles$.pipe(map(titles => titles.length)),
              actionButton: this.canEditTitles
                ? of({
                    icon: 'add',
                    tooltip: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.ADD_WORK_TITLES'),
                    class: 'mat-white-icon add-title-button',
                    onClick: () =>
                      this.openEditDialog(
                        this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.ADD_WORK_TITLES'),
                        {},
                        this.getPopupAlternativeTitlesForm(),
                        this.onSubmitAlternativeTitlesPopup.bind(this),
                        'new',
                      ),
                  })
                : of(null),
              title: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.WORK_TITLES.TITLES'),
              minHeight: HEIGHT_TOP_TITLES,
              maxHeight: HEIGHT_TOP_TITLES,
              hideToggle: false,
              filter: {
                model: this.titles$,
                filterItemsOn: 10,
                onFilter: filterValue =>
                  this.titles$
                    .subscribe(titles =>
                      this.filteredTitles$.next(titles.filter(row => filterValue === '' || (row.value || '').toLowerCase().startsWith(filterValue.toLowerCase()))),
                    )
                    .unsubscribe(),
              },
              content: [
                {
                  group: [
                    {
                      type: 'dataTable',
                      config: {
                        columnMode: 'flex',
                        customClass: 'simple-list title-data-table',
                        data: this.filteredTitles$,
                        schema: [
                          {
                            name: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.WORK_TITLES.TITLE'),
                            prop: 'value',
                            flexGrow: 4,
                            filter: false,
                            cellClass: 'ice-cell-bold fs-14',
                          },
                          {
                            name: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.WORK_TITLES.TYPE'),
                            prop: 'type',
                            flexGrow: 1,
                            filter: false,
                            tooltip: 'tooltipTitlesType',
                            tooltipDuration: 250,
                          },
                          {
                            name: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.WORK_TITLES.DURATION'),
                            prop: 'durationFormatted',
                            flexGrow: 1,
                            filter: false,
                          },
                          {
                            actionButtonIcon: 'delete',
                            prop: 'delete',
                            cellClass: 'p-0',
                            flexGrow: 0.001,
                            maxWidth: 50,
                            minWidth: 50,
                            resizeable: false,
                            action: item => this.deleteAlternativeTitle(item),
                            hideActionButton: () => of(!this.canEditTitles),
                            actionButtonTooltip: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.DELETE_WORK_TITLES'),
                            sortable: false,
                          },
                          {
                            actionButtonIcon: 'edit',
                            prop: 'edit',
                            cellClass: 'p-0',
                            flexGrow: 0.001,
                            maxWidth: 40,
                            minWidth: 40,
                            resizeable: false,
                            action: (event: any) => {
                              const title = event.value;
                              const type = WorkUtils.getTitleTypeFromLabel(this.translate, event.label);
                              const model = { ...event, oldTitle: title, title, oldType: type, type, index: event.index };
                              this.openEditDialog(
                                this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.EDIT_WORK_TITLES'),
                                model,
                                this.getPopupAlternativeTitlesForm(),
                                this.onSubmitAlternativeTitlesPopup.bind(this),
                                'edit',
                              );
                            },
                            hideActionButton: () => of(!this.canEditTitles),
                            actionButtonTooltip: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.EDIT_WORK_TITLES'),
                            sortable: false,
                          },
                        ],
                        dontStrip: true,
                        shadowed: true,
                        showLoader: true,
                        sorts: [],
                        visibleColumns: this.editMode$.pipe(
                          map(editMode => (editMode ? workTitlesVisibleColumns : workTitlesVisibleColumns.slice(0, workTitlesVisibleColumns.length - 2))),
                        ),
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            type: 'cardWithExpansionList',
            flex: 30,
            config: {
              class: 'flex-1 expansion-list-xrefs',
              type: 'labeledList',
              title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.XREFS'),
              expanded: this.expandTopCards$,
              model: this.store.select(fromRoot.getWorkDetailXrefsIds).pipe(
                map(RelationsUtils.getSortedLabeledXrefs),
                map(xrefList => xrefList && xrefList.map(xref => ({ ...xref, value: CopyrightUtils.getKeySuffix(xref.value) }))),
              ),
              minHeight: HEIGHT_TOP_CARDS,
              maxHeight: HEIGHT_TOP_CARDS,
              filterItemsOn: 10,
              selectable: this.editMode$,
              actionButton: this.canEditXrefs
                ? of({
                    icon: 'add',
                    tooltip: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.ADD_XREFS'),
                    class: 'mat-white-icon add-xref-button',
                    onClick: this.openEditDialog.bind(
                      this,
                      this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.ADD_XREFS'),
                      {},
                      this.getPopupXrefsForm(),
                      this.onSubmitEditXrefPopup.bind(this),
                    ),
                  })
                : of(null),
              rowButtons: this.canEditXrefs
                ? of([
                    {
                      icon: 'edit',
                      tooltip: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.EDIT_XREFS'),
                      class: '',
                      onClick: event => {
                        const xrefToEdit = (event.rawItem.otherId || '').split(':');
                        const xrefToDecompose = [...xrefToEdit];
                        const xref = xrefToDecompose.pop(); // The XREF is the last item. ie: SWREF:079:123 => 123
                        const type = xrefToDecompose.join(':'); // The type can be SWREF:079
                        const model = xrefToEdit && { type, xref, xrefToEdit };
                        this.openEditDialog(
                          this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.EDIT_XREFS'),
                          model,
                          this.getPopupXrefsForm(),
                          this.onSubmitEditXrefPopup.bind(this),
                        );
                      },
                      getVisibility: item => {
                        return this.checkVisibility(item);
                      },
                    },
                    {
                      icon: 'delete',
                      tooltip: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.DELETE_XREFS'),
                      class: '',
                      onClick: this.deleteXREF.bind(this),
                      getVisibility: item => {
                        return this.checkVisibility(item);
                      },
                    },
                  ])
                : of([]),
              onSelect: event => {},
              showCounter: true,
              onOpen: () => this.expandTopCards(),
              onClosed: () => this.collapseTopCards(),
            },
          },
          {
            type: 'cardWithExpansionList',
            flex: 28,
            config: {
              class: 'flex-1',
              type: 'select',
              title: this.translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.TITLE'),
              selectTitle: this.translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.SUBTITLE'),
              expanded: this.expandTopCards$,
              model: this.store.select(fromRoot.getWorkSocietyMarkers).pipe(map(societyMarkers => WorkUtils.getSocietyMarkers(societyMarkers, this.translate))),
              minHeight: HEIGHT_TOP_CARDS,
              maxHeight: HEIGHT_TOP_CARDS,
              filterItemsOn: 10,
              selectable: of(this.canEditSocietyMarkers),
              onSelect: event => {},
              showCounter: true,
              onOpen: () => this.expandTopCards(),
              onClosed: () => this.collapseTopCards(),
              actionEdit: this.canEditSocietyMarkers
                ? of({
                    class: '',
                    tooltip: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.EDIT_WORK_SOCIETY_MARKER'),
                    onClick: event => this.store.dispatch(new fromRoot.UpdateField({ object: 'societyMarkers', newValue: event.code, type: 'edit' })),
                  })
                : of(null),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithExpansionList',
            config: {
              class: 'flex-1 other-information',
              type: 'form',
              title: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.CARD_TITLE'),
              expanded: this.expandOtherInfoCards$,
              onOpen: this.expandOtherInfoCards.bind(this),
              onClosed: this.collapseOtherInfoCards.bind(this),
              model: this.store.select(fromRoot.getWorkDetailWork),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getOtherInformationForm(),
              minHeight: HEIGHT_TOP_OTHER_INFORMATION,
              maxHeight: HEIGHT_TOP_OTHER_INFORMATION,
              showCounter: false,
            },
          },
        ],
      },
    ];
  }

  disableToggleTree(): void {
    this.availableSharePictureTreeSubscription = this.store
      .select(fromRoot.getCounterClaimTreeSharePicture)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(tree => (tree && tree.length && tree.length > 0 ? this.disableToggleButton.next(false) : this.disableToggleButton.next(true)));
  }

  openAuditInfoLogDialog() {
    DialogUtils.openDialogJsonViewer(
      this.dialog,
      this.translate.instant('WORKS.SHARE_PICTURE.AUDIT_INFO'),
      SharePictureUtils.getSharePictureTriggeredRulesFormatted(this.store.select(fromRoot.getAuditInfoSharePicture)),
      true,
      this.translate.instant('SHARED.AUDIT_HISTORY.POPUP.CLOSE_BUTTON'),
    );
  }

  setWorkId() {
    this.store
      .select(fromRoot.getRouterParams)
      .pipe(take(1))
      .subscribe(params => {
        this.workId = params.key;
      });
  }

  expandTopCards() {
    this.expandTopCards$.next(true);
  }

  collapseTopCards() {
    this.expandTopCards$.next(false);
  }

  checkVisibility(item) {
    return item && ((item.label && item.label.startsWith('SO')) || ['ICE', 'ALLTC', 'DELTC'].includes(item.label) || item.hasMTCH) ? 'hidden' : '';
  }

  private getOtherInformationForm() {
    const { translate } = this;
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-5 ice-form-field-height-18 edit-duration ice-cell-bold',
            key: 'durationFormatted',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.DURATION'),
              required: false,
              change: (field, $event) => this.updateField(field, 'durationFormatted'),
            },
            validators: {
              duration: FormlyValidatorUtils.getDurationValidator(this.translate),
              maxDuration: FormlyValidatorUtils.getMaxDurationValidator(this.translate),
            },
            expressionProperties: {
              'templateOptions.disabled': (model: any) => !this.canEditDuration,
            },
            hooks: {
              onDestroy: field => {
                this.unsubscribeAll.next();
                this.unsubscribeAll.complete();
              },
            },
          },
          {
            className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
            key: 'origin',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.ORIGIN'),
              required: false,
              options: [
                { label: 'Original', value: 'ORI' },
                { label: 'Modification/Version', value: 'MOD' },
              ],
              change: (field, $event) => this.updateField(field, 'origin'),
            },
            expressionProperties: {
              'templateOptions.disabled': (model: any) => !this.canEditOrigin,
            },
          },
          {
            className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
            key: 'source',
            type: 'select',
            defaultValue: '',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.SOURCE'),
              required: false,
              options: WORK_SOURCE_OPTIONS,
              change: field => this.updateField(field, 'source'),
            },
            expressionProperties: {
              'templateOptions.disabled': (model: any) => !this.canEditDuration,
            },
          },
          fieldInput('status', translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.STATUS'), 'flex-5 ice-form-field-height-18 ice-cell-bold'),
          {
            className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
            key: 'textMusicRelationship',
            type: 'ice-select',
            defaultValue: '',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.TEXT_MUSIC_RELATIONSHIP'),
              required: false,
              options: WORK_MUSIC_RELATIONSHIP_OPTIONS,
              selectedLabelsAsValue: true,
              change: (field, $event) => this.updateField(field, 'textMusicRelationship'),
            },
            hooks: {
              onInit: field => {
                if (field.formControl) {
                  field.templateOptions.tooltip = WorkUtils.getMusicRelationshipLabelTooltip(field.formControl.value, this.translate);
                }
                field.formControl.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
                  field.templateOptions.tooltip = WorkUtils.getMusicRelationshipLabelTooltip(field.formControl.value, this.translate);
                });
              },
            },
            expressionProperties: { 'templateOptions.disabled': (model: any) => !this.canEditTextMusicRelationship },
          },
          {
            className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
            key: 'language',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.LANGUAGE'),
              required: false,
              options: langConversionItem.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              }),
              change: (field, $event) => this.updateField(field, 'language'),
            },
            expressionProperties: { 'templateOptions.disabled': (model: any) => !this.canEditLanguage },
          },
        ],
      },
      fieldConfig([
        {
          className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
          key: 'owner',
          type: 'ice-input',
          templateOptions: {
            type: 'text',
            placeholder: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.OWNER'),
            required: false,
            disabled: true,
            tooltipField: 'ownerTooltip',
          },
        },
        fieldInput('version', translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.VERSION'), 'flex-5 ice-form-field-height-18 ice-cell-bold'),
        {
          className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
          key: 'lyricAdaption',
          type: 'select',
          defaultValue: '',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.LYRIC_ADAPTION.TITLE'),
            required: false,
            options: WorkUtils.getLyricAdaptationOptions(this.translate),
            change: (field, $event) => this.updateField(field, 'lyricAdaption'),
          },
          expressionProperties: { 'templateOptions.disabled': (model: any) => !this.canEditLyricAdaptation },
        },
        {
          className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
          key: 'shareDivisionOwner',
          type: 'ice-input',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.SHARE_DIVISION_OWNER'),
            required: false,
            disabled: true,
            tooltipField: 'shareDivisionOwnerTooltip',
          },
        },
        {
          className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
          key: 'purpose',
          type: 'select',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.PURPOSE'),
            required: false,
            options: WorkUtils.getPurposeOptions(this.translate),
            change: field => this.updateField(field, 'purpose'),
          },
          expressionProperties: { 'templateOptions.disabled': (model: any) => !this.canEditPurpose },
        },
        {
          className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
          key: 'genre',
          type: 'select',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.GENRE'),
            required: false,
            options: this.getGenreOptions(),
            change: field => this.updateField(field, 'genre'),
          },
          expressionProperties: { 'templateOptions.disabled': (model: any) => !this.canEditGenre },
        },
      ]),
      fieldConfig([
        {
          className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
          key: 'musicArrangement',
          type: 'select',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.MUSIC_ARRANGEMENT.TITLE'),
            required: false,
            options: WorkUtils.getMusicArrangementOptions(this.translate),
            change: field => this.updateField(field, 'musicArrangement'),
          },
          expressionProperties: { 'templateOptions.disabled': (model: any) => !this.canEditMusicArrangement },
        },
        {
          className: 'flex-5 ice-form-field-height-18 ice-cell-bold',
          key: 'category',
          type: 'ice-select',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.CATEGORY'),
            required: false,
            options: WORK_CATEGORY_OPTIONS,
            selectedLabelsAsValue: true,
            change: field => this.updateField(field, 'category'),
          },
          hooks: {
            onInit: field => {
              if (field.formControl) {
                field.templateOptions.tooltip = WorkUtils.getWorkCategoryLabelTooltip(field.formControl.value, this.translate);
              }
              field.formControl.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
                field.templateOptions.tooltip = WorkUtils.getWorkCategoryLabelTooltip(field.formControl.value, this.translate);
              });
            },
          },
          expressionProperties: { 'templateOptions.disabled': (model: any) => !this.canEditCategory },
        },
        DatepickerUtils.getDatepickerField({
          key: 'publicationDate',
          label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.PUBLICATION_DATE'),
          translate: this.translate,
          extraClass: 'flex-5 ice-cell-bold',
          expressionProperties: { 'templateOptions.disabled': (model: any) => !this.canEditPublicationDate },
        }),
        { className: 'flex-5', template: '<div></div>' },
        { className: 'flex-5', template: '<div></div>' },
        { className: 'flex-5', template: '<div></div>' },
      ]),
    ];
  }

  openEditDialog(title, model, formBuilder, onSubmit, type?) {
    this.editMode$
      .subscribe(editMode => {
        if (editMode) {
          const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NORMAL_BT);
          this.dialogRef = dialogForm.openDialog(
            title,
            model,
            formBuilder,
            newModel => onSubmit(model, newModel, type),
            () => this.dialogRef.close(),
            this.translate.instant('POPUP.CONFIRM'),
            this.translate.instant('POPUP.CANCEL'),
            'dialog-wrapper-width-420-h',
          );
        }
      })
      .unsubscribe();
  }

  private onSubmitAlternativeTitlesPopup(model, newValue, type) {
    const value = { ...model, ...newValue };
    this.store.dispatch(new fromRoot.UpdateField({ object: 'alternativeTitle', newValue: value, type }));
    this.dialogRef.close();
  }

  private onSubmitEditXrefPopup(model, newValue) {
    this.store.dispatch(new fromRoot.UpdateField({ object: 'xrefs', newValue, type: model.xrefToEdit ? 'edit' : 'new' }));
    this.dialogRef.close();
  }

  private getPopupAlternativeTitlesForm() {
    return [
      fieldConfig([
        fieldSelectRequired(
          'type',
          this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.SELECT_TITLE_TYPE'),
          'alternative',
          WorkUtils.getAlternativeTitlesOptions(this.translate),
        ),
      ]),
      fieldConfig([fieldInputRequiredEditable('title', this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.WORK_TITLES.TITLE'), undefined, 'add-title-input')]),
      fieldConfig([
        {
          key: 'durationFormatted',
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          className: 'flex-1 add-duration-input',
          modelOptions: {
            updateOn: 'blur',
          },
          templateOptions: {
            placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.WORK_TITLES.DURATION'),
          },
          validators: {
            duration: FormlyValidatorUtils.getDurationValidator(this.translate),
            maxDuration: FormlyValidatorUtils.getMaxDurationValidator(this.translate),
          },
        },
      ]),
    ];
  }

  private getPopupXrefsForm() {
    return [
      fieldConfig([
        {
          className: 'ice-w-90',
          fieldGroup: [
            {
              className: 'add-xref-input',
              key: 'xref',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              modelOptions: {
                updateOn: 'change',
              },
              templateOptions: {
                type: 'text',
                required: true,
                label: '',
              },
              hooks: {
                onInit: field => {
                  field.parent.form
                    .get('type')
                    .valueChanges.pipe(takeUntil(this.unsubscribeAll))
                    .subscribe(xrefType => {
                      const isISWC = ['ISWCA', 'ISWC'].includes(xrefType?.value);
                      this.isISWC = isISWC;
                      field.templateOptions.label = isISWC ? 'ISWC: T-000.000.000-0' : this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF');
                      field.formControl.updateValueAndValidity();
                    });
                },
              },
              asyncValidators: {
                iswcValidation: {
                  expression: async (control: FormControl, field: FormlyFieldConfig) => {
                    if (this.isISWC) {
                      const value = (control && control.value) || '';
                      if (!this.fieldValidatorService.iswcRegExp.test(value)) {
                        return false;
                      } else {
                        return this.fieldValidatorService.isIswcValidValue(value);
                      }
                    }
                    return true;
                  },
                  message: this.translate.instant('WORKS.DETAILS.ISWC_VALIDATION'),
                },
              },
            },
          ],
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          fieldGroup: [
            {
              className: 'type-select',
              key: 'type',
              type: 'ice-autocomplete-grouped',
              modelOptions: {
                updateOn: 'change',
              },
              templateOptions: {
                required: true,
                placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.SELECT_XREF_TYPE'),
                options: this.xrefOptions,
                panelWidth: 'auto',
                change: (field, event) => {
                  const xrefControl = field.formControl.parent.get('xref');
                  const xrefTypeValue = selectControlValue(field.formControl);
                  if (xrefControl && xrefControl.value) {
                    this.isISWC = ['ISWCA', 'ISWC'].includes(xrefTypeValue);
                    xrefControl.markAsTouched();
                    xrefControl.setValue(xrefControl.value);
                  }
                  if (field.formControl.value?.length >= 2) {
                    this.searchXrefEvents$.next(field);
                  }
                },
              },
              hooks: {
                onInit: field => {
                  WorkUtils.getXrefPublisherReference({
                    field,
                    store: this.store,
                    translate: this.translate,
                    callback: ({ backendXrefList, frontXrefList, backendOption, frontOption }) => {
                      if (backendXrefList.length > 0) {
                        this.xrefOptions$.next([{ header: 'Publisher', options: backendXrefList }]);
                      } else {
                        this.xrefOptions$.next(frontXrefList);
                      }
                      field.formControl.markAsTouched();
                      field.formControl.setValue(frontOption || backendOption);
                    },
                  });
                },
              },
            },
          ],
        },
      ]),
    ];
  }

  private deleteAlternativeTitle(item) {
    this.store.dispatch(new fromRoot.UpdateField({ object: 'alternativeTitle', newValue: item.value, type: 'delete' }));
  }

  private deleteXREF(item) {
    this.store.dispatch(new fromRoot.UpdateField({ object: 'xrefs', newValue: `${item.label}:${item.value}`, type: 'delete' }));
  }

  private getGenreOptions(): IceOptions {
    return [
      { label: 'Unconfirmed', value: 'UNC' },
      { label: 'Jazz', value: 'JAZ' },
      { label: 'Serious', value: 'SER' },
      { label: 'Pop', value: 'POP' },
    ];
  }

  private normalizeRows(): (source$: Observable<CopyrightOwnershipTableItem[]>) => Observable<CopyrightOwnershipTableItem[]> {
    return (source$: Observable<CopyrightOwnershipTableItem[]>) =>
      combineLatest([source$, this.store.select(fromRoot.getFilterSharePicture)]).pipe(
        rxjsFilter(([data]) => !!data?.length),
        distinctUntilChanged(isEqual),
        map(([data, filter]) => {
          const filteredRows = data.filter(item => {
            const role = item.roleRaw;
            const isE = PUBLISHER_ROLES_LIST.includes(role);
            const isSE = SUBPUBLISHER_ROLES.includes(role);
            if (filter.removeE && isE) {
              return false;
            }
            if (filter.removeSE && isSE) {
              return false;
            }
            return true;
          });
          return filteredRows;
        }),
        map(rows => WorkUtils.cleanWorkDataTableLabels(rows)),
      );
  }

  private updateField(field: FormlyFieldConfig, fieldLabel: string) {
    if (field.formControl) {
      const value = field.formControl.value;
      this.store.dispatch(new fromRoot.UpdateField({ object: fieldLabel, newValue: value, type: 'edit' }));
    }
  }

  private getFilterShares(store: Store<fromRoot.RootState>, translate: TranslateService, nsService: NamespaceService): SharesFilter {
    return {
      submitShortcutEnable: true,
      formClass: 'bg-filter-form ice-pt-30 ice-mx-24 ice-mt-20',
      model: this.store.select(fromRoot.getFilterSharePicture).pipe(
        withLatestFrom(this.store.select(fromRoot.getUserDetail)),
        map(([filterSharePicture, userDetail]) => {
          const userName = userDetail?.cognitoUserName;
          /**
           * We default to the state in redux (to keep it consistent while SPA navigation).
           * If it's a new navigation or refresh, we default to the user defaults which are stored in localStorage
           * Those defaults can be overridden by the user by clicking on the `Set as default` button
           */
          const userFilter = isEmpty(filterSharePicture) && userName ? LocalStorageUtils.getDefaultSharePictureFilter(userName) : filterSharePicture;
          const mrFilter =
            (userFilter?.mrDistributionSocietyId && SocietiesUtils.getSocietyCode(SharePictureUtils.formatSocietyCode(userFilter?.mrDistributionSocietyId))) || '000';
          const prFilter =
            (userFilter?.prDistributionSocietyId && SocietiesUtils.getSocietyCode(SharePictureUtils.formatSocietyCode(userFilter?.prDistributionSocietyId))) || '000';
          return {
            ...userFilter,
            territory: userFilter?.country || DEFAULT_FILTER_TERRITORY,
            mrSociety: mrFilter !== '000' ? mrFilter : DEFAULT_DISTRIBUTION_SOCIETY_MR,
            prSociety: prFilter !== '000' ? prFilter : DEFAULT_DISTRIBUTION_SOCIETY_PR,
            removeE: userFilter?.removeE || false,
            removeSE: userFilter?.removeSE || false,
            usageDate: userFilter?.usageDate,
            distributionDate: userFilter?.distributionDate,
          };
        }),
      ),
      formBuilder: SharesFilterConfig.getFormInlineWithoutRepertoire(store, translate, nsService.getNamespace()),
      forceSubmitDisabled: {
        default: false,
      },
      avoidResetModel: true,
      onSubmit: event => SharesFilterConfig.doSubmit(event, this.store, this.nsService.getNamespace(), this.workId),
      onReset: (form: FormGroup) => {
        const value = DEFAULT_SHARES_FILTER_FORM;
        WorkUtils.updateForm(form, value);
        form.get('usageDate').updateValueAndValidity();
        SharesFilterConfig.doSubmit(value, this.store, this.nsService.getNamespace(), this.workId);
      },
    };
  }

  private expandOtherInfoCards() {
    this.expandOtherInfoCards$.next(true);
  }

  private collapseOtherInfoCards() {
    this.expandOtherInfoCards$.next(false);
  }
}
