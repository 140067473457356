<ng-container *ngIf="layout === 'vertical'">
  <div class="navbar-vertical">
    <div class="navbar-header">
      <div class="logo">
        <img class="logo-icon" src="assets/images/logos/ice.svg" />
        <span class="logo-text"></span>
      </div>

      <button mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarFolded('navbar')" fxHide.lt-lg matTooltip="Ctrl + Shift + L">
        <mat-icon>menu</mat-icon>
      </button>

      <button mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarOpened('navbar')" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <div class="navbar-content" fusePerfectScrollbar>
      <fuse-navigation [navigation]="navigation$ | async" layout="vertical"></fuse-navigation>
    </div>

    <div *ngIf="needsUpdate$ | zonedAsync" class="new-version-warning">{{ 'APP_NEW_VERSION' | translate }}</div>
    <div class="navbar-footer">
      <span class="version-text" [ngClass]="(needsUpdate$ | zonedAsync) ? 'ice-red' : ''">{{ (needsUpdate$ | zonedAsync) ? 'old ' : '' }}{{ initialVersion$ | zonedAsync }}</span>
      <span *ngIf="(needsUpdate$ | zonedAsync) && (updatedVersion$ | zonedAsync)" class="version-text">new {{ updatedVersion$ | zonedAsync }}</span>
      <button *ngIf="needsUpdate$ | zonedAsync" (click)="refreshApp()" mat-button>{{ 'APP_NEEDS_UPDATE' | translate }}</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="layout === 'horizontal'">
  <div class="navbar-horizontal">
    <fuse-navigation [navigation]="navigation$ | async" layout="horizontal"></fuse-navigation>
  </div>
</ng-container>
