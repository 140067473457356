import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { combineFormDateFields, ConflictUtils, CounterClaimClaimantRelation, DateTimeUtils } from '@ice';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { locale as englishTabs } from 'assets/i18n/en/config/tabs-data-builders';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { FormConfig, SearchExpressions } from './form-config';

export class CounterClaimsSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    switch (key) {
      case 'id':
        const valueArray = value.split(':');
        const addPrefix = valueArray.length > 1 ? false : true;
        if (prefix) {
          if (addPrefix) {
            return `{"wildcard":{"work.relations[XREF].otherId":"*${prefix}:${value}*"}}`;
          } else {
            return `{"wildcard":{"work.relations[XREF].otherId":"*${value}*"}}`;
          }
        }
        return `{"wildcard":{"work.relations[XREF].otherId":"*${value}*"}}`;
      case 'workTitle':
        return `{"wildcard":{"work.attributes.titles.*.titleValue":"*${value}*"}}`;
      case 'workRef':
        return `{"wildcard":{"attributes.workId":"*${value}*"}}`;
      case 'counterClaimType':
        return `{"wildcard":{"attributes.type":"*${value}*"}}`;
      case 'status':
        return `{"equals":{"attributes.status":"${value}"}}`;
      case 'type':
        return `{"equals":{"attributes.type":"${value}"}}`;
      case 'counterClaimStatus':
        return `{"equals":{"attributes.status":"${value}"}}`;
      case 'pointOfConflict':
        return `{"equals":{"attributes.pointOfConflict":"${value}"}}`;
      case 'resolutionOwners':
        return `{"equals":{"attributes.resolutionOwners":"${value}"}}`;
      case 'counterClaimCreationFrom':
        return `{"range":{"attributes.createdDate":{"gte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
      case 'counterClaimCreationTo':
        return `{"range":{"attributes.createdDate":{"lte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
      case 'counterClaimResolutionFrom':
        return `{"range":{"attributes.resolvedDate":{"gte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
      case 'counterClaimResolutionTo':
        return `{"range":{"attributes.resolvedDate":{"lte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
      case 'counterClaimParty':
        return `{"or": [
          {"wildcard":{"participants.claimant.relations[XREF].otherId": "*${value}*"}},
          {"wildcard":{"participants.claimant.attributes.name": "*${value}*"}}
      ]}`;
      case 'counterClaimRelationType':
        return `{"equals":{"participants.relation":"${value}"}}`;
      case 'actionType':
        return `{"equals":{"actions.attributes.type":"${value}"}}`;
      case 'actionStatus':
        return `{"equals":{"actions.attributes.status":"${value}"}}`;
      case 'actionParticipant':
        return `{"and": [
          {"wildcard":{"participants.claimant.relations[XREF].otherId": "*${value}*"}},
          {"equals":{"participants.relation": "${CounterClaimClaimantRelation.RESOLVER}"}}
      ]}`;
      case 'actionOwner':
        return `{"wildcard":{"actions.attributes.owner":"*${value}*"}}`;
      case 'actionUser':
        return `{"wildcard":{"actions.attributes.assignor":"*${value}*"}}`;
      case 'actionDeadline':
        return `{"range":{"actions.attributes.deadline":{
            "gte": "${value.split('_')[0]}",
            "lte": "${value.split('_')[1]}"
          }}}`;
      case 'actionDeadlineFrom':
        return `{"range":{"actions.attributes.deadline":{"gte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
      case 'actionDeadlineTo':
        return `{"range":{"actions.attributes.deadline":{"lte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
      case 'actionResponse':
        return `{"equals":{"actions.attributes.response":"${value}"}}`;
      case 'actionOwner':
        return `{"equals":{"actions.attributes.owner":"${value}"}}`;
      case 'createdDate':
        return `{"equals":{"attributes.createdDate":"${DateTimeUtils.formatDate(moment(value))}"}}`;
      case 'deadline':
        return `{"equals":{"actions.attributes.deadline":"${DateTimeUtils.formatDate(moment(value))}"}}`;
    }
    return `{"wildcard":{"attributes.${key}":"*${value}*"}}`;
  }

  getExtraSearchExpression() {
    return '';
  }
}
export class SearchConflictsCounterClaimsForm implements FormConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishTabs);
  }

  getForm(): FormlyFieldConfig[] {
    let subsDeadlineFrom: Subscription;
    let subsDeadlineTo: Subscription;
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'workTitle',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.WORK_TITLE'),
            },
          },
          {
            className: 'flex-1',
            key: 'workRef',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.WORK_REF'),
            },
          },
          {
            className: 'flex-1',
            key: 'id',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.COUNTERCLAIM_ID'),
            },
          },
          {
            className: 'flex-1',
            key: 'counterClaimType',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.COUNTERCLAIM_TYPE'),
              options: ConflictUtils.getAllCounterClaimsTypesOptions(this.translate),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'counterClaimStatus',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.COUNTERCLAIM_STATUS'),
              options: ConflictUtils.getStatusOptions(this.translate),
            },
          },
          {
            className: 'flex-1',
            key: 'pointOfConflict',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.POINT_OF_CONFLICT'),
              options: ConflictUtils.getAllConflictsPointOfConflictOptions(this.translate),
            },
          },
          {
            className: 'flex-1',
            key: 'resolutionOwners',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.RESOLUTION_OWNERS'),
              options: ConflictUtils.getAllCounterClaimsResolutioOwnerTypesOptions(this.translate),
            },
          },
          DatepickerUtils.getDatepickerField({
            key: 'counterClaimCreationFrom',
            label: this.translate.instant('ACTIVITY.COUNTER_CLAIM_CREATION_FROM'),
            translate: this.translate,
            extraClass: 'flex-1',
          }),
          DatepickerUtils.getDatepickerField({
            key: 'counterClaimCreationTo',
            label: this.translate.instant('ACTIVITY.COUNTER_CLAIM_CREATION_TO'),
            translate: this.translate,
            extraClass: 'flex-1',
          }),
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          DatepickerUtils.getDatepickerField({
            key: 'counterClaimResolutionFrom',
            label: this.translate.instant('ACTIVITY.COUNTER_CLAIM_RESOLUTION_FROM'),
            translate: this.translate,
            extraClass: 'flex-1',
          }),
          DatepickerUtils.getDatepickerField({
            key: 'counterClaimResolutionTo',
            label: this.translate.instant('ACTIVITY.COUNTER_CLAIM_RESOLUTION_TO'),
            translate: this.translate,
            extraClass: 'flex-1',
          }),
          {
            className: 'flex-1',
            key: 'counterClaimParty',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.COUNTER_CLAIM_PARTY'),
            },
          },
          {
            className: 'flex-1',
            key: 'counterClaimRelationType',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.COUNTER_CLAIM_RELATION_TYPE'),
              options: ConflictUtils.getCounterClaimRelationTypeOptions(this.translate),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'actionType',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.ACTION_TYPE'),
              options: ConflictUtils.getActionTypesOptions(this.translate),
            },
          },
          {
            className: 'flex-1',
            key: 'actionStatus',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.ACTION_STATUS'),
            },
          },
          {
            className: 'flex-1',
            key: 'actionParticipant',
            wrappers: ['wrapper-info', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.ACTION_PARTICIPANT'),
              infoText: this.translate.instant('ACTIVITY.ACTION_PARTICIPANT_INFO'),
            },
          },
          {
            className: 'flex-1',
            key: 'actionOwner',
            wrappers: ['wrapper-info', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.ACTION_OWNER'),
              infoText: this.translate.instant('ACTIVITY.ACTION_OWNER_INFO'),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'actionUser',
            wrappers: ['wrapper-info', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.ACTION_USER'),
              infoText: this.translate.instant('ACTIVITY.ACTION_USER_INFO'),
            },
          },
          {
            className: '',
            key: 'actionDeadline',
            type: 'input',
            hide: true,
          },
          DatepickerUtils.getDatepickerField({
            key: 'actionDeadlineFrom',
            label: this.translate.instant('ACTIVITY.ACTION_DEADLINE_FROM'),
            translate: this.translate,
            extraClass: 'flex-1',
            hooks: {
              onInit: field => {
                subsDeadlineFrom = field.formControl.valueChanges.subscribe(value =>
                  combineFormDateFields(field.form.controls, ['actionDeadlineFrom', 'actionDeadlineTo'], 'actionDeadline'),
                );
              },
              onDestroy: () => {
                subsDeadlineFrom.unsubscribe();
              },
            },
          }),
          DatepickerUtils.getDatepickerField({
            key: 'actionDeadlineTo',
            label: this.translate.instant('ACTIVITY.ACTION_DEADLINE_TO'),
            translate: this.translate,
            extraClass: 'flex-1',
            hooks: {
              onInit: field => {
                subsDeadlineTo = field.formControl.valueChanges.subscribe(value =>
                  combineFormDateFields(field.form.controls, ['actionDeadlineFrom', 'actionDeadlineTo'], 'actionDeadline'),
                );
              },
              onDestroy: () => {
                subsDeadlineTo.unsubscribe();
              },
            },
          }),
          {
            className: 'flex-1',
            key: 'actionResponse',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('ACTIVITY.ACTION_RESPONSE'),
              options: ConflictUtils.getActionResponseOptions(this.translate),
            },
          },
        ],
      },
    ];
  }
}
