import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightOwnershipTableItem, fieldConfig, IpUtils, TerritoryUtils } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as englishErrors } from 'assets/i18n/en/app/formly-global-message-errors';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { DialogForm, DIALOG_NORMAL_BT } from 'config/dialog-builders/dialog-form';
import { IceFacade } from 'facades/ice.facade';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../../tabs-data';

// TODO: Add Angular decorator.
export class TabExtProtection implements SectionTabConfig {
  protected types = { work: 'work', ip: 'ip' };
  protected subscriptionEditMode: Subscription;
  protected availableSharePictureTreeSubscription: Subscription;
  protected disableToggleButton: BehaviorSubject<boolean>;

  protected constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected dialog: MatDialog,
    protected commonApiService: CommonApiService,
    protected detailService: DetailService,
    protected nsService: NamespaceService,
    protected iceFacade: IceFacade,
    protected fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english, englishErrors);
    this.disableToggleButton = new BehaviorSubject(true);
  }

  getConf(): IceGroupComponent[] {
    return [];
  }

  openTermsOfProtectionDialog() {
    const name = 'protectedUntil';
    const label = this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.PROTECTED_UNTIL');
    const required = true;
    const translate = this.translate;
    const asyncValidators = {
      invalidDate: {
        expression: (control: FormControl) => of(!control.errors?.invalidDate),
      },
      incorrect: {
        expression: (control: FormControl) => of(!control.errors?.incorrect),
      },
      required: {
        expression: (control: FormControl) => of(!!control.value),
      },
    };

    const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NORMAL_BT);
    const dialogRef = dialogForm.openDialog(
      this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.TITLE'),
      {},
      this.store.pipe(
        select(fromRoot.getManuscriptData),
        map((manuscripts: CopyrightOwnershipTableItem[]) => {
          return [
            fieldConfig([
              {
                className: 'flex-1',
                key: 'type',
                type: 'select',
                templateOptions: {
                  label: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.TYPE.TITLE'),
                  required: true,
                  options: [
                    { label: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.TYPE.SELECT'), value: '' },
                    { label: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.TYPE.WORK_LEVEL'), value: this.types.work },
                    { label: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.TYPE.IP_LEVEL'), value: this.types.ip },
                  ],
                  change: (field, $event) => this.existTermOfProtection(field.formControl),
                },
              },
            ]),
            fieldConfig([
              {
                className: 'flex-1',
                key: 'ip',
                type: 'select',
                templateOptions: {
                  label: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.SELECTED_IP'),
                  required: true,
                  options: manuscripts.map(manuscript => ({
                    label: IpUtils.formatLabelSelectIPProtection(manuscript),
                    value: manuscript.contributorId,
                  })),
                  change: (field, $event) => this.existTermOfProtection(field.formControl),
                },
                hideExpression: model => model.type !== this.types.ip,
              },
            ]),
            fieldConfig([
              {
                className: 'flex-1',
                key: 'societies',
                type: 'input',
                modelOptions: {
                  updateOn: 'change',
                },
                templateOptions: {
                  type: 'text',
                  label: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.SOCIETY_CODE'),
                  required: true,
                  change: (field, $event) => this.existTermOfProtection(field.formControl),
                },
                hooks: {
                  onDestroy: () => {
                    if (this.subscriptionEditMode) {
                      this.subscriptionEditMode.unsubscribe();
                    }
                    if (this.availableSharePictureTreeSubscription) {
                      this.availableSharePictureTreeSubscription.unsubscribe();
                    }
                    this.disableToggleButton.next(false);
                    this.disableToggleButton.complete();
                  },
                },
                validators: {
                  socityCode: {
                    expression: c => {
                      let isCorrect = true;
                      if (c.value) {
                        let selectedValue: string;
                        if (typeof c.value === 'string') {
                          selectedValue = c.value;
                        } else {
                          // It's an option
                          selectedValue = c.value.value;
                        }
                        selectedValue.split(',').forEach(value => {
                          if (!ipsSocietiesCodes.find(society => society.code === value.trim())) {
                            isCorrect = false;
                          }
                        });
                      }
                      return isCorrect;
                    },
                    message: (error, field: FormlyFieldConfig) => this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.ERROR_SOCIETY'),
                  },
                },
                asyncValidators: {
                  duplicatedCombination: {
                    expression: (control: FormControl) =>
                      FormlyValidatorUtils.checkDuplicatedCombinationTerritoriesSocieties(this.store.select(fromRoot.getExtendedToP), control, 'territories'),
                    message: this.translate.instant('ERROR.COMBINATION_ALL_READY_EXISTS'),
                  },
                },
              },
            ]),
            fieldConfig([
              TerritoryUtils.getTerritoryField(
                'territories',
                'term_territory_codes',
                this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.COUNTRY_CODE'),
                this.translate,
                this.fieldValidatorService,
                true,
                field => this.existTermOfProtection(field.formControl),
                {
                  duplicatedCombination: {
                    expression: (control: FormControl) =>
                      FormlyValidatorUtils.checkDuplicatedCombinationTerritoriesSocieties(this.store.select(fromRoot.getExtendedToP), control, 'societies'),
                    message: this.translate.instant('ERROR.COMBINATION_ALL_READY_EXISTS'),
                  },
                },
              ),
            ]),
            fieldConfig([
              {
                className: 'flex-1',
                fieldGroup: [
                  DatepickerUtils.getDatepickerField({
                    key: name,
                    label,
                    required,
                    translate,
                    asyncValidators,
                  }),
                ],
              },
            ]),
            fieldConfig([
              {
                className: 'flex-1',
                fieldGroup: [
                  {
                    className: 'ice-input-only-display-none',
                    key: 'errorValidator',
                    type: 'label',
                    wrappers: ['form-field'],
                    validation: {
                      show: true,
                    },
                    validators: {
                      ipError: {
                        expression: (control: FormControl) => !control.value || control.value !== this.types.ip,
                        message: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.VALIDATION_ERROR_IP'),
                      },
                      workError: {
                        expression: (control: FormControl) => !control.value || control.value !== this.types.work,
                        message: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.IP_PROTECTION_POPUP.VALIDATION_ERROR_WORK'),
                      },
                    },
                  },
                ],
              },
            ]),
          ];
        }),
      ),
      newValue => {
        this.store.dispatch(new fromRoot.UpdateField({ object: 'ExtProtection', newValue, type: 'new' }));
        dialogRef.close();
      },
      () => dialogRef.close(),
      this.translate.instant('POPUP_COMMON.CONFIRM'),
      this.translate.instant('POPUP_COMMON.CANCEL'),
      'dialog-wrapper-width-420-h form-fields-with-gap',
    );
  }

  existTermOfProtection(control) {
    this.store
      .pipe(
        select(fromRoot.getExtendedToP),
        filter(res => res),
        take(1),
      )
      .toPromise()
      .then(protections => {
        const fields = control.parent.value;
        const existProtection = protections.filter(protection => {
          if (fields.type === this.types.work) {
            // Work Level
            // if true => Already exists one society/country combination in this work
            return protection.territories.toLowerCase() === fields.territories.toLowerCase() && protection.societyIds === fields.societies;
          } else if (fields.type === this.types.ip) {
            // IP Level
            // if true => Already exists one society/country combination for the same IP or in the Work Level
            return (
              (protection.contributorId &&
                protection.territories.toLowerCase() === fields.territories.toLowerCase() &&
                protection.societyIds === fields.societies &&
                protection.contributorId === fields.ip) ||
              (!protection.contributorId && protection.territories.toLowerCase() === fields.territories.toLowerCase() && protection.societyIds === fields.societies)
            );
          }
        });
        if (existProtection.length === 0) {
          control.parent.controls['errorValidator'].setValue('');
        } else {
          control.parent.controls['errorValidator'].setValue(fields.type);
        }
        return existProtection.length === 0;
      })
      .catch(() => false);
  }
}
