import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivityDetail, CaseWorkDetail, ClaimsUtils, ConflictUtils, ContributorsUtils, CopyrightUtils, WorkUtils } from '@ice';
import { CardWithDataTable } from '@ice/components/card-with-data-table/card-with-data-table';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { CounterClaimStatus } from '@ice/components/expert-search-form/model/enums';
import { AnalysisAndComparisonsUtils } from '@ice/dynamic-components/analysis-and-comparisons/analysis-and-comparisons.utils';
import { locale as englishFloatingButtons } from '@ice/i18n/en/button-labels';
import { ActivityUtils } from '@ice/utils/activity/activity.utils';
import { DefaultProjectorFn, MemoizedSelector, Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@stage/ngx-datatable';
import { locale as englishInstrumentations } from 'assets/i18n/en/app/works/instrumentation';
import { locale as englishInstruments } from 'assets/i18n/en/app/works/instruments';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { SharedActions } from 'config/buttons-group-builders/buttons-group-actions';
import { FloatingButtons } from 'config/buttons-group-builders/floating-buttons.config';
import { ActivityType, EXPANSION_LIST_PAIRS } from 'config/constants/activity.constants';
import { ClaimInfo, WORK_CLAIM_SHARE_TYPE, CollectionOrOwnership } from 'config/constants/shares.constants';
import { SectionsConfig } from 'config/sections-config';
import { flattenDeep, get, includes, isEqual } from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, mergeMap, publish, refCount, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { NamespaceService } from 'services/namespace/namespace.service';
import * as fromRoot from 'store/root';

const subTypeToExpandible = {
  instruments: 'INSTR_NO_MERGE',
  instrumentations: 'INSTR_NO_MERGE',
  overrides: 'TOP_NO_MERGE',
};

export class TabActivity {
  public claimType: CollectionOrOwnership = 'collection';
  public claimType$ = new BehaviorSubject<CollectionOrOwnership>(null);
  public dialogRef;
  protected highlightNoResultsMessage = true;
  protected toggleHighlightActive = true;
  protected toggleHighlightResultsLabelSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.translate.instant('ACTIVITY.ANALYSIS_MERGE.FRONTEND_HIGHLIGHT'));

  protected selectedClaimsAnalysisLeft: ClaimInfo[] = [];
  protected selectedClaimsAnalysisRight: ClaimInfo[] = [];
  protected selectionClaimsAnalysisLeft$ = new BehaviorSubject([]);
  protected selectionClaimsAnalysisRight$ = new BehaviorSubject([]);
  protected originalClaimsAnalysisLeft: ClaimInfo[] = [];
  protected originalClaimsAnalysisRight: ClaimInfo[] = [];

  constructor(
    public translate: TranslateService,
    public fuseTranslationLoader: FuseTranslationLoaderService,
    public store: Store<fromRoot.RootState>,
    public dialog: MatDialog,
    commonApiService: null,
    detailService: null,
    public nsService: NamespaceService,
  ) {
    sessionStorage.removeItem(`selectedClaimsAnalysisLeft_${WORK_CLAIM_SHARE_TYPE.COLLECTION}`);
    sessionStorage.removeItem(`selectedClaimsAnalysisLeft_${WORK_CLAIM_SHARE_TYPE.OWNERSHIP}`);
    sessionStorage.removeItem(`selectedClaimsAnalysisRight_${WORK_CLAIM_SHARE_TYPE.COLLECTION}`);
    sessionStorage.removeItem(`selectedClaimsAnalysisRight_${WORK_CLAIM_SHARE_TYPE.OWNERSHIP}`);
    sessionStorage.removeItem('claimsSelectedAll');
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishFloatingButtons);
    this.fuseTranslationLoader.loadTranslations(englishInstruments);
    this.fuseTranslationLoader.loadTranslations(englishInstrumentations);

    this.expandedObservable$ = (expandible: string) =>
      combineLatest([
        this.activitySubType,
        this.store.select(fromRoot.getActivityCompareConflicts).pipe(filter(data => !!data)),
        this.store.select(fromRoot.getActivityAnalysisConflicts).pipe(filter(data => !!data)),
      ]).pipe(
        map(([subType, compare, analysis]) => subType === subTypeToExpandible[expandible] && (get(compare, expandible) || get(analysis, expandible))),
        take(1),
      );

    combineLatest([this.store.select(fromRoot.getActivityCompareConflicts), this.store.select(fromRoot.getActivityAnalysisConflicts)])
      .pipe(
        map(([compare, analysis]) => ({
          instruments: get(compare, 'instruments') || get(analysis, 'instruments'),
          instrumentations: get(compare, 'instrumentations') || get(analysis, 'instrumentations'),
          overrides: get(compare, 'overrides') || get(analysis, 'overrides'),
        })),
      )
      .subscribe(conflicts => {
        this.analysisAndComparisonsUtils.highLightExpansionListPairs = EXPANSION_LIST_PAIRS.filter(
          expansionListPair =>
            !['extended-main', 'instruments-main', 'instrumentations-main'].includes(expansionListPair[0]) ||
            (expansionListPair[0] === 'extended-main' && conflicts.overrides) ||
            (expansionListPair[0] === 'instruments-main' && conflicts.instruments) ||
            (expansionListPair[0] === 'instrumentations-main' && conflicts.instrumentations),
        );
      });

    this.activityType = this.store.pipe(
      select(fromRoot.getActivityConflictType),
      filter(type => !!type),
      takeUntil(this.componentDestroyed),
      map(type => type),
    );

    this.activitySubType = this.store.pipe(
      select(fromRoot.getActivityConflictSubType),
      filter(subType => !!subType),
      takeUntil(this.componentDestroyed),
      map(subType => subType),
    );

    this.sharesCompareSource = combineLatest([
      this.store.pipe(
        select(fromRoot.getActivityWorkAnalysisClaims),
        filter(claims => !!claims),
      ),
      this.store.pipe(
        select(fromRoot.getActivityWorkToCompareClaims),
        filter(claims => !!claims),
      ),
      this.store.pipe(select(fromRoot.getActivityClaimsFilter)),
      this.claimType$,
    ]).pipe(
      map(([claimsAnalysis, claimsCompare, claimsFilter, claimType]) => {
        const claimsAnalysisFiltered = ConflictUtils.filterClaims(claimsAnalysis, claimsFilter, 'mastered', translate, claimType);
        const claimsCompareFiltered = ConflictUtils.filterClaims(claimsCompare, claimsFilter, 'compare', translate, claimType);
        return ConflictUtils.sortByPath(
          [...claimsAnalysisFiltered.map(claim => ({ ...claim, claimView: this.claimType })), ...claimsCompareFiltered.map(claim => ({ ...claim, claimView: this.claimType }))],
          1,
        );
      }),
    );

    this.sharesCompare = this.sharesCompareSource.pipe(publish(), refCount());

    this.activitySubType.subscribe(subType => {
      switch (subType) {
        case 'POSS_MATCH':
          this.expanded0Subject.next(false);
          this.expanded1Subject.next(false);
          this.expanded2Subject.next(false);
          this.expanded3Subject.next(false);
          this.expanded4Subject.next(true);
          this.expanded5Subject.next(false);
          this.expanded6Subject.next(false);
          this.expanded7Subject.next(false);
          this.expanded8Subject.next(true);
          this.expanded9Subject.next(false);
          this.expanded10Subject.next(false);
          break;
        case 'CREATOR_NO_MERGE':
        case 'PUB_NO_MERGE':
          this.expanded0Subject.next(false);
          this.expanded1Subject.next(false);
          this.expanded2Subject.next(false);
          this.expanded3Subject.next(false);
          this.expanded4Subject.next(true);
          this.expanded5Subject.next(false);
          this.expanded6Subject.next(false);
          this.expanded7Subject.next(false);
          this.expanded8Subject.next(true);
          this.expanded9Subject.next(false);
          this.expanded10Subject.next(false);
          break;
        case 'TOP_NO_MERGE':
          this.expanded0Subject.next(false);
          this.expanded1Subject.next(false);
          this.expanded2Subject.next(false);
          this.expanded3Subject.next(false);
          this.expanded4Subject.next(false);
          this.expanded5Subject.next(false);
          this.expanded6Subject.next(false);
          this.expanded8Subject.next(false);
          this.expanded9Subject.next(false);
          this.expanded10Subject.next(false);
          break;
        case 'INSTR_NO_MERGE':
          this.expanded0Subject.next(false);
          this.expanded1Subject.next(false);
          this.expanded2Subject.next(false);
          this.expanded3Subject.next(false);
          this.expanded4Subject.next(false);
          this.expanded7Subject.next(false);
          this.expanded8Subject.next(false);
          this.expanded9Subject.next(false);
          this.expanded10Subject.next(false);
          break;
      }
    });

    this.compareTitles$ = this.store.pipe(select(fromRoot.getActivityWorkToCompareTitles));
    this.analysisTitles$ = this.store.pipe(select(fromRoot.getActivityWorkAnalysisTitles));

    this.claimType$.next(WORK_CLAIM_SHARE_TYPE.COLLECTION);

    this.getOriginalClaims();
  }

  protected options$ = this.store.pipe(
    select(fromRoot.getActivityOptionsToCompare),
    map(options => AnalysisAndComparisonsUtils.cleanOptions(options)),
  );

  protected defaultMergeAnalysisWorkSelected$ = this.store.pipe(
    withLatestFrom(this.store.pipe(select(fromRoot.getActivityOptionsToCompare)), this.store.pipe(select(fromRoot.getActivityWorkAnalysisId))),
    map(([rootState, optionsToCompare, workAnalysisId]) => {
      const selectedOptionAnalysis = optionsToCompare.find(option => option.viewValue === workAnalysisId);
      return selectedOptionAnalysis?.value;
    }),
  );

  activityCases$ = this.store.pipe(select(fromRoot.getConflictsActivity), withLatestFrom(this.store.pipe(select(fromRoot.getActivityWorkToCompareRelations))));
  instrumentsCases$ = combineLatest([this.store.pipe(select(fromRoot.getActivityWorkToCompareInstruments)), this.store.pipe(select(fromRoot.getActivityWorkAnalysisInstruments))]);
  instrumentationsCases$ = combineLatest([
    this.store.pipe(select(fromRoot.getActivityWorkToCompareInstrumentations)),
    this.store.pipe(select(fromRoot.getActivityWorkAnalysisInstrumentations)),
  ]);

  extendedTopCases$ = combineLatest([this.store.pipe(select(fromRoot.getActivityWorkToCompareExtendedToP)), this.store.pipe(select(fromRoot.getActivityWorkAnalysisExtendedToP))]);

  ownershipCountSubject: Subject<number> = new BehaviorSubject<number>(0);
  ownershipCountObservable: Observable<number> = this.ownershipCountSubject.asObservable();
  componentDestroyed = new Subject();

  expanded0Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded1Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded2Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded3Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded4Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded5Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded6Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded7Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded8Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded9Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded10Subject: Subject<Boolean> = new BehaviorSubject<Boolean>(true);
  expanded0: Observable<Boolean> = this.expanded0Subject.asObservable();
  expanded1: Observable<Boolean> = this.expanded1Subject.asObservable();
  expanded4: Observable<Boolean> = this.expanded4Subject.asObservable();
  expanded5: Observable<Boolean> = this.expanded5Subject.asObservable();
  expanded6: Observable<Boolean> = this.expanded6Subject.asObservable();
  expanded7: Observable<Boolean> = this.expanded7Subject.asObservable();
  expanded8: Observable<Boolean> = this.expanded8Subject.asObservable();
  expanded9: Observable<Boolean> = this.expanded9Subject.asObservable();
  expanded10: Observable<Boolean> = this.expanded10Subject.asObservable();
  expandedRows: Array<Subject<Boolean>> = [
    this.expanded0Subject,
    this.expanded1Subject,
    this.expanded2Subject,
    this.expanded3Subject,
    this.expanded4Subject,
    this.expanded5Subject,
    this.expanded6Subject,
    this.expanded7Subject,
    this.expanded8Subject,
    this.expanded9Subject,
    this.expanded10Subject,
  ];

  public analysisAndComparisonsUtils = new AnalysisAndComparisonsUtils();
  public formlyModel: Observable<FormlyFieldConfig[]>;
  public activityType: Observable<string>;
  public activitySubType: Observable<string>;
  public sharesCompare: Observable<any[]>;
  public sharesCompareSource: Observable<any[]>;
  public extendedToPCompare: Observable<any>;
  public instrumentsCompare: Observable<any[]>;
  public instrumentationsCompare: Observable<any[]>;
  public compareTitles$: Observable<any[]>;
  public analysisTitles$: Observable<any[]>;
  public expandedObservable$: (type: string) => Observable<any>;

  getFilter() {}

  private getOriginalClaims() {
    combineLatest([
      this.store.pipe(
        select(fromRoot.getActivityWorkAnalysisClaims),
        filter(claims => !!claims),
      ),
      this.store.pipe(
        select(fromRoot.getActivityWorkToCompareClaims),
        filter(claims => !!claims),
      ),
    ])
      .pipe(
        takeUntil(this.componentDestroyed),
        map(([claimsAnalysis, claimsCompare]) => {
          this.originalClaimsAnalysisRight = [
            ...this.processClaims(claimsAnalysis, 'mastered', WORK_CLAIM_SHARE_TYPE.COLLECTION),
            ...this.processClaims(claimsAnalysis, 'mastered', WORK_CLAIM_SHARE_TYPE.OWNERSHIP),
          ];
          this.originalClaimsAnalysisLeft = [
            ...this.processClaims(claimsCompare, 'compare', WORK_CLAIM_SHARE_TYPE.COLLECTION),
            ...this.processClaims(claimsCompare, 'compare', WORK_CLAIM_SHARE_TYPE.OWNERSHIP),
          ];
        }),
      )
      .subscribe();
  }

  expanseRow(row: number) {
    this.expandedRows[row].next(true);
  }

  collapseRow(row: number) {
    this.expandedRows[row].next(false);
  }

  public drawHighLights(toogleHighlightActive = true): void {
    of('ok')
      .pipe(withLatestFrom(this.sharesCompareSource))
      .subscribe(([_, sharesCompare]) => {
        if (!!sharesCompare) {
          this.analysisAndComparisonsUtils.removeHighlightedElems();
          this.analysisAndComparisonsUtils.formBuilderHighlight(this.formlyModel, toogleHighlightActive);
          this.analysisAndComparisonsUtils.highlightExpansionLists(['#expansion-list-', '#expansion-list-data-table-'], toogleHighlightActive);
          this.analysisAndComparisonsUtils.setClaimRowsStyle(sharesCompare, toogleHighlightActive);
        }
      });
  }

  public getDummyRow() {
    return { role: '', ipiName: '', name: '', ipiNameKey: '', territories: ' ', societyCodePr: '', prRights: '', pr: '', societyCodeMr: '', mrRights: '', mr: '' };
  }

  public getWorkDetailProperties(property: string, style: string) {
    switch (property) {
      case 'title':
        return [
          {
            className: style,
            key: 'title',
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.OT'),
              required: false,
              disabled: true,
            },
            hooks: {
              onDestroy: form => {
                this.componentDestroyed.next();
                this.componentDestroyed.complete();
                this.expanded0Subject.next();
                this.expanded0Subject.complete();
                this.expanded1Subject.next();
                this.expanded1Subject.complete();
                this.expanded2Subject.next();
                this.expanded2Subject.complete();
                this.expanded3Subject.next();
                this.expanded3Subject.complete();
                this.expanded4Subject.next();
                this.expanded4Subject.complete();
                this.expanded5Subject.next();
                this.expanded5Subject.complete();
                this.expanded6Subject.next();
                this.expanded6Subject.complete();
                this.expanded7Subject.next();
                this.expanded7Subject.complete();
                this.expanded8Subject.next();
                this.expanded8Subject.complete();
                this.expanded9Subject.next();
                this.expanded9Subject.complete();
                this.expanded10Subject.next();
                this.expanded10Subject.complete();
                this.ownershipCountSubject.complete();
              },
            },
            expressionProperties: {
              'templateOptions.placeholder': (model: any) => {
                if (model) {
                  const { titles } = model;
                  if (titles) {
                    const titleType = WorkUtils.selectTitle(titles).type;
                    return this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.' + titleType);
                  }
                }
              },
            },
          },
        ];
      case 'iswc':
        return [
          {
            className: style,
            key: 'iswc',
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.ISWC'),
              required: false,
              disabled: true,
            },
          },
        ];
      case 'main':
        return [
          {
            className: style,
            key: 'version',
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.VERSION'),
              required: false,
              disabled: true,
            },
          },
          {
            className: style,
            key: 'status',
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.STATUS'),
              required: false,
              disabled: true,
            },
          },
          {
            className: style,
            key: 'origin',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.ORIGIN'),
              required: false,
              disabled: true,
              tooltipField: 'originTooltip',
            },
          },
        ];
      case 'genre':
        return [
          {
            className: style,
            key: 'genre',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.GENRE'),
              required: false,
              disabled: true,
              tooltipField: 'genreTooltip',
            },
          },
        ];
      case 'purpose':
        return [
          {
            className: style,
            key: 'purpose',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.PURPOSE'),
              required: false,
              disabled: true,
              tooltipField: 'purposeTooltip',
            },
          },
          {
            className: style,
            key: 'category',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.CATEGORY'),
              required: false,
              disabled: true,
              tooltipField: 'categoryTooltip',
            },
          },
        ];
      case 'other':
        return [
          {
            className: style,
            key: 'durationFormatted',
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.DURATION'),
              required: false,
              disabled: true,
            },
          },
          {
            className: style,
            key: 'source',
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.SOURCE'),
              required: false,
              disabled: true,
            },
          },
          {
            className: style,
            key: 'owner',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.OWNER'),
              required: false,
              disabled: true,
              tooltipField: 'ownerTooltip',
            },
          },
        ];
    }
  }

  public getWorkDetailPropertiesMerge(property: string, style: string) {
    switch (property) {
      case 'main':
        return [
          {
            className: style,
            key: 'version',
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.VERSION'),
              required: false,
              disabled: true,
            },
          },
          {
            className: style,
            key: 'owner',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.OWNER'),
              required: false,
              disabled: true,
              tooltipField: 'ownerTooltip',
            },
          },
          {
            className: style,
            key: 'origin',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.ORIGIN'),
              required: false,
              disabled: true,
              tooltipField: 'originTooltip',
            },
          },
        ];
      case 'genre':
        return [
          {
            className: style,
            key: 'genre',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.GENRE'),
              required: false,
              disabled: true,
              tooltipField: 'genreTooltip',
            },
          },
        ];
      case 'purpose':
        return [
          {
            className: style,
            key: 'purpose',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.PURPOSE'),
              required: false,
              disabled: true,
              tooltipField: 'purposeTooltip',
            },
          },
        ];
      case 'creationDate':
        return [
          {
            className: style,
            key: 'creationDate',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.CREATION_DATE'),
              required: false,
              disabled: true,
            },
          },
        ];
      case 'other':
        return [
          {
            className: style,
            key: 'durationFormatted',
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.DURATION'),
              required: false,
              disabled: true,
            },
          },
          {
            className: style,
            key: 'source',
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.SOURCE'),
              required: false,
              disabled: true,
            },
          },
        ];
    }
  }

  public getSchemaInstruments(highlightsEmptyResultsMessage = false): DataTableRow[] {
    return [
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_CODE'), prop: 'instrumentCode', flexGrow: 1, highlightsEmptyResultsMessage },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_NAME'), prop: 'instrumentName', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_FAMILY'), prop: 'instrumentFamily', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_NUMBER'), prop: 'numberOfInstruments', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_NUMBER_OF_PLAYERS'), prop: 'numberOfPlayers', flexGrow: 1 },
    ];
  }

  public getSchemaInstrumentations(highlightsEmptyResultsMessage = false): DataTableRow[] {
    return [
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_CODE'), prop: 'instrumentCode', flexGrow: 1, highlightsEmptyResultsMessage },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_NAME'), prop: 'instrumentName', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_DESCRIPTION'), prop: 'instrumentDescription', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_NUMBER'), prop: 'numberOfInstruments', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_NUMBER_OF_PLAYERS'), prop: 'numberOfPlayers', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.NUMBER_OF_PARTS'), prop: 'numberOfParts', flexGrow: 1 },
    ];
  }

  public getSchemaExtendedToP(highlightsEmptyResultsMessage = false): DataTableRow[] {
    return [
      {
        name: this.translate.instant('WORKS.EXTENDED_TOP.SOCIETY'),
        prop: 'societyIds',
        flexGrow: 1,
        cellClass: 'ice-txt-size-14',
        comparator: (valueA, valueB, modelA, modelB) => this.sortSocietyIdsAndDate(modelA, modelB),
        highlightsEmptyResultsMessage,
      },
      {
        name: this.translate.instant('WORKS.EXTENDED_TOP.COUNTRY'),
        prop: 'territories.territoriesTisa',
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        flexGrow: 1,
        cellClass: 'ice-txt-size-14',
      },
      {
        name: this.translate.instant('WORKS.EXTENDED_TOP.DATE'),
        prop: 'protectionDate',
        flexGrow: 1,
        cellClass: 'ice-txt-size-14',
      },
      { name: this.translate.instant('WORKS.EXTENDED_TOP.IP_ROLE'), prop: 'role', flexGrow: 1, cellClass: 'ice-txt-size-14' },
      { name: this.translate.instant('WORKS.EXTENDED_TOP.NAME'), prop: 'name', flexGrow: 1, cellClass: 'ice-txt-size-14' },
      { name: this.translate.instant('WORKS.EXTENDED_TOP.IPI_NAME_NUMBER'), prop: 'ipiNameNumber', flexGrow: 1, cellClass: 'ice-txt-size-14' },
    ];
  }

  public sortSocietyIdsAndDate(modelA, modelB) {
    const dateAisGreater = modelA.protectionDate > modelB.protectionDate;
    return modelA.societyIds.join(',') > modelB.societyIds.join(',') ? 1 : modelA.societyIds.join(',') === modelB.societyIds.join(',') && dateAisGreater ? -1 : 1;
  }

  public sortSocietyIdsAndDateAscendant(modelA, modelB) {
    const dateAisGreater = modelA.protectionDate > modelB.protectionDate;
    if (modelA.societyIds.join(',') === modelB.societyIds.join(',')) {
      return dateAisGreater ? -1 : 1;
    } else {
      const maxLength = Math.max(modelA.societyIds.length, modelB.societyIds.length);
      let i = 0;
      while (i < maxLength) {
        if (modelA.societyIds[i] === modelB.societyIds[i]) {
          i++;
        } else if (!modelA.societyIds[i]) {
          return -1;
        } else if (!modelB.societyIds[i]) {
          return 1;
        } else {
          const regEx = /\d+/;
          const codesA = modelA.societyIds[i].match(regEx);
          const codesB = modelB.societyIds[i].match(regEx);
          return Number(codesA[0]) - Number(codesB[0]);
        }
      }
    }
  }

  public getSchemaClaims(activityType: ActivityType = ActivityType.MATCH): DataTableRow[] {
    const schema: DataTableRow[] = [
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_ROLE'),
        prop: 'role',
        flexGrow: 1,
        sortable: false,
        tooltip: 'roleTooltip',
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_NAME'),
        prop: 'name',
        flexGrow: 3,
        sortable: false,
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_IP_NAME_NUMBER'),
        prop: 'ipiName',
        flexGrow: 2,
        sortable: false,
        headerTooltip: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_IP_NAME_NUMBER_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_PR_SOCIETY'),
        prop: 'societyCodePr',
        flexGrow: 1.5,
        sortable: false,
        tooltip: 'societyCodePrTooltip',
        iconRightOfText: { icon: 'societyCodePrIcon' },
        headerTooltip: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_PR_SOCIETY_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_MR_SOCIETY'),
        prop: 'societyCodeMr',
        flexGrow: 1.5,
        sortable: false,
        tooltip: 'societyCodeMrTooltip',
        iconRightOfText: { icon: 'societyCodeMrIcon' },
        headerTooltip: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_MR_SOCIETY_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_PR'),
        prop: 'pr',
        cellClass: 'ice-justify-right',
        flexGrow: 1.2,
        sortable: false,
        headerTooltip: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_PR_RIGHTS_SHARE_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_MR'),
        prop: 'mr',
        cellClass: 'ice-justify-right',
        flexGrow: 1.2,
        sortable: false,
        headerTooltip: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_MR_RIGHTS_SHARE_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_TERRITORY'),
        icons: 'territoriesIcon',
        prop: 'territoriesLabel',
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        cellClass: 'ice-txt-size-14',
        flexGrow: 1.5,
        sortable: false,
      },
      {
        name: this.translate.instant('CLAIMS.ALERT'),
        prop: 'alert',
        hideTextProperty: true,
        icons: 'alertIcons',
        cellClass: 'ice-justify-right icon-cell',
        flexGrow: 1,
        sortable: false,
      },
    ];
    if (activityType === ActivityType.MERGE) {
      schema.unshift({
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_IGNORE'),
        prop: 'rowSelected',
        headerCheckboxable: false,
        checkboxable: true,
        width: 50,
        resizeable: false,
        canAutoResize: false,
        draggable: false,
        sortable: false,
        cellClass: 'ice-transfer-ip-works-data-table-checkbox',
        headerClass: 'ice-transfer-ip-works-data-table-checkbox',
      });
    }
    return schema;
  }

  public getTitlesHeight(): Observable<number> {
    return this.store.pipe(
      select(fromRoot.getActivityWorkToCompareTitles),
      withLatestFrom(this.store.pipe(select(fromRoot.getActivityWorkAnalysisTitles))),
      takeUntil(this.componentDestroyed),
      map(([compareValues, analysisValues]) => this.getHeight(compareValues, analysisValues)),
    );
  }

  public getXrefsHeight(activityCases$: Observable<any>, maxHeight: number = 296): Observable<number> {
    return activityCases$.pipe(
      map(([casesAnalysis, xrefsToCompare]) => {
        const relations = get(casesAnalysis, 'works[0].work.relations', null) || [];
        const xrefs = WorkUtils.getWorkToCompareXrefsIdsOrdered(relations);
        const xrefsTotal = (xrefs && xrefs.length) || 0;
        const xrefsToCompareTotal = (xrefsToCompare && xrefsToCompare.length) || 0;
        return this.getCardMinHeight((xrefsTotal > xrefsToCompareTotal ? xrefsTotal : xrefsToCompareTotal) * 36 + 16, maxHeight);
      }),
    );
  }

  public getComponentsHeight(itemsCases$: Observable<any>): Observable<number> {
    return itemsCases$.pipe(
      takeUntil(this.componentDestroyed),
      map(([compare, analysis]) => this.calculateHeightComponents(compare, analysis)),
    );
  }

  private calculateHeightComponents(compare: any, analysis: any): number {
    const compareLength = (compare && compare.length) || 0;
    const analysisLength = (analysis && analysis.length) || 0;
    if (compareLength === 0 && analysisLength === 0) {
      return 0;
    } else {
      return (compareLength > analysisLength ? compareLength : analysisLength) * 48 + 60;
    }
  }

  private getTranslationKey(options: any[], activityType: ActivityType): string {
    const matchKey = options.length === 1 ? 'MATCH' : 'MATCHES';
    const confirmedKey = activityType === ActivityType.MATCH ? 'POSSIBLE' : 'CONFIRMED';
    return `${confirmedKey}_${matchKey}`;
  }

  protected getExtendedWorkSelectorTitle(activityType: ActivityType): Observable<string> {
    return this.options$.pipe(
      map(options => {
        const key = this.getTranslationKey(options, activityType);
        return this.translate.instant(`ACTIVITY.ANALYSIS.REVISION.${key}`);
      }),
    );
  }

  protected getExtendedWorkSelectorPlaceHolder(activityType: ActivityType): Observable<string> {
    return this.options$.pipe(
      map(options => {
        const key = this.getTranslationKey(options, activityType);
        return `${this.translate.instant(`ACTIVITY.ANALYSIS.REVISION.SELECT`)} ${this.translate.instant(`ACTIVITY.ANALYSIS.REVISION.${key}`)}`;
      }),
    );
  }

  private checkComplementaryConflicts(data: any[]) {
    const complementaryList = data?.map(item => Object.assign({}, item)) ?? [];
    const indexToDelete = [];
    for (let firstObjectIndex = 0; firstObjectIndex < complementaryList.length; firstObjectIndex++) {
      for (let secondObjectIndex = firstObjectIndex + 1; secondObjectIndex < complementaryList.length; secondObjectIndex++) {
        const obj1 = complementaryList[firstObjectIndex];
        const obj2 = complementaryList[secondObjectIndex];
        const diff = Object.keys(obj1).filter(key => !obj2.hasOwnProperty(key) || !isEqual(obj1[key], obj2[key]));
        const diffOnlyRights = diff.length <= 5 && diff.every(diffKey => diffKey.toLowerCase().includes('rights'));
        if (diffOnlyRights) {
          const equalsRight = obj1['mrRights'] === '';
          const equalsLeft = obj1['prRights'] === '';
          if (equalsRight && !equalsLeft) {
            complementaryList[firstObjectIndex]['mrRights'] = obj2['mrRights'];
            complementaryList[firstObjectIndex]['mrRightsTooltip'] = obj2['mrRightsTooltip'];
          } else {
            complementaryList[firstObjectIndex]['prRights'] = obj2['prRights'];
            complementaryList[firstObjectIndex]['prRightsTooltip'] = obj2['prRightsTooltip'];
          }
          indexToDelete.push(secondObjectIndex);
        }
      }
    }
    return complementaryList.filter((_, index) => !indexToDelete.includes(index));
  }

  public getCardConflictScopeDataTable(): CardWithDataTable {
    return {
      title: this.translate.instant('WORKS.DETAILS.CONFLICTS.TITLE'),
      visibleColumns: this.getCardConflictScopeDataTableVisibleColumns(),
      model: this.store.pipe(
        select(fromRoot.getConflictsActivity),
        withLatestFrom(this.store.pipe(select(fromRoot.getScopeOfConflicts))),
        filter(([activity, scopeOfConflicts]) => !!activity && scopeOfConflicts?.length > 0),
        map(([activity, scopeOfConflicts]) => {
          const activityId = activity?.id;
          const data = scopeOfConflicts.find(conflict => includes(conflict.conflictId, activityId));
          return data?.claimConflictPoints;
        }),
        mergeMap(data => {
          const resultList = this.checkComplementaryConflicts(data);
          return of(resultList);
        }),
        map(claimConflictPoints => {
          return claimConflictPoints?.map(conflictPoint => {
            return {
              ...conflictPoint,
              claimShareType: conflictPoint.claimShareType && this.translate.instant(`WORKS.DETAILS.CONFLICTS.TABLE.CLAIM_TYPE_${conflictPoint.claimShareType.toUpperCase()}`),
            };
          });
        }),
      ),
      columnMode: 'flex',
      schema: this.getCardConflictScopeDataTableSchema(),
      tableWidth: '100',
      customClass: 'ice-fixed-header',
      fixedHeight: '180px',
    };
  }

  public getCardConflictScopeDataTableSchema(): any[] {
    return [
      {
        name: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.TERRITORY'),
        prop: 'territoriesLabel',
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.RIGHTS_PR'),
        prop: 'prRights',
        tooltip: 'prRightsTooltip',
        flexGrow: 1,
        headerTooltip: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.RIGHTS_PR_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.RIGHTS_MR'),
        prop: 'mrRights',
        tooltip: 'mrRightsTooltip',
        flexGrow: 1,
        headerTooltip: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.RIGHTS_MR_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.START_DATE'),
        prop: 'startDate',
        flexGrow: 1,
        headerTooltip: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.START_DATE_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.END_DATE'),
        prop: 'endDate',
        flexGrow: 1,
        headerTooltip: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.END_DATE_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.CLAIM_TYPE'),
        prop: 'claimShareType',
        flexGrow: 1,
        headerTooltip: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.CLAIM_TYPE'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.WORKS_INCLUDED'),
        prop: 'conflictWorkIds',
        cellClass: 'cy-works-included',
        flexGrow: 1.5,
        headerTooltip: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.WORKS_INCLUDED'),
        tooltip: 'conflictWorkIds',
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.CONFLICT_CAUSE'),
        prop: 'conflictCause',
        cellClass: 'cy-conflict-cause',
        flexGrow: 3,
        headerTooltip: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.CONFLICT_CAUSE'),
        tooltip: 'conflictCause',
      },
    ];
  }

  public getCardConflictScopeDataTableVisibleColumns(): Observable<string[]> {
    return of(['territoriesLabel', 'prRights', 'mrRights', 'startDate', 'endDate', 'claimShareType', 'conflictWorkIds', 'conflictCause']);
  }

  public getIswcAndStatus(model: CaseWorkDetail | {}): string {
    const iswc = get(model, 'iswc');
    const status = get(model, 'status');
    let iswcText = '';
    let statusText = '';
    if (iswc) {
      iswcText = `<span>${iswc}</span>&nbsp;/&nbsp`;
    }
    if (status) {
      statusText = `${this.translate.instant('ACTIVITY.ANALYSIS.REVISION.STATUS')}:<span>&nbsp;${get(model, 'status')}</span>`;
    }
    return iswcText + statusText;
  }

  public getSocietyMarkers(model: CaseWorkDetail | {}): string {
    const societyMarkers = get(model, 'societyMarkers');
    if (societyMarkers) {
      return WorkUtils.getWSMResult(societyMarkers);
    }
  }

  public cleanCompareLine(compare: any, translate: any): any {
    const roleTooltip = compare?.roleRaw ? ContributorsUtils.getRoleLabelFromRoleValue(compare.roleRaw) : '';
    const incomeParticipantIcon = compare?.isIncomeParticipant ? CopyrightUtils.getIncomeparticipantIconWithTooltip(translate) : undefined;
    const unauthorizedIcon = ClaimsUtils.getUnauthorizedIcons(compare, translate);
    const counterClaimIcon = compare.hasNotResolvedCounterclaim ? CopyrightUtils.getCounterclaimIconWithTooltip() : undefined;
    const alertIcons = flattenDeep([incomeParticipantIcon, unauthorizedIcon, counterClaimIcon].filter(item => item !== undefined));
    return {
      ...compare,
      ipiName: CopyrightUtils.getKeySuffix(compare.ipiName),
      alertIcons,
      roleTooltip,
    };
  }

  public getFirstSociety(societies: string): string {
    const arrSocieties = societies.split(',');
    return !!arrSocieties[0] ? arrSocieties[0].replace('(', '') : societies || '';
  }

  public getTilesSchemaClaims(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.WORK_TITLES.TITLE'),
        prop: 'title',
        flexGrow: 4,
        filter: false,
        cellClass: 'ice-cell-bold fs-14',
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.WORK_TITLES.TYPE'),
        prop: 'type',
        flexGrow: 1,
        filter: false,
        tooltip: 'tooltipTitlesType',
        tooltipDuration: 250,
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.WORK_TITLES.DURATION'),
        prop: 'durationFormatted',
        flexGrow: 1,
        filter: false,
      },
    ];
  }

  public completeTitles(titles: any[]): any[] {
    return (titles || []).map(title => {
      return { ...title, tooltipTitlesType: this.translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${title.type}`) };
    });
  }

  getActivityWorkToCompareSocietyMarkersWithHighlight(
    left$: Observable<{ value: string; key: boolean; tooltip: string; code: string; label: string }[]>,
    right$: Observable<{ value: string; key: boolean; tooltip: string; code: string; label: string }[]>,
  ): Observable<{ value: string; key: boolean; tooltip: string; code: string; label: string; highlight: boolean }[]> {
    return combineLatest([left$, right$]).pipe(
      takeUntil(this.componentDestroyed),
      map(([left, right]) => {
        const newValues = left.map((data: any) => {
          const matchToCompare = right.find(option => option.code === data.code);
          if (matchToCompare) {
            const distinc = data.value !== matchToCompare.value;
            data = { ...data, highlight: distinc };
          }
          return data;
        });
        return newValues;
      }),
    );
  }

  public getOtherPartiesHeight(): Observable<number> {
    return this.store.pipe(
      select(fromRoot.getActivityWorkToCompareOtherParties),
      withLatestFrom(this.store.pipe(select(fromRoot.getActivityWorkAnalysisOtherParties))),
      takeUntil(this.componentDestroyed),
      map(([compareValues, analysisValues]) => this.getHeight(compareValues, analysisValues)),
    );
  }

  public getOtherInstrumentsHeight(): Observable<number> {
    return this.store.pipe(
      select(fromRoot.getActivityWorkToCompareInstruments),
      withLatestFrom(this.store.pipe(select(fromRoot.getActivityWorkAnalysisInstruments))),
      takeUntil(this.componentDestroyed),
      map(([compareValues, analysisValues]) => this.getHeight(compareValues, analysisValues)),
    );
  }

  private getHeight(compareValues: any[], analysisValues: any[], maxHeight: number = 296): number {
    const compareLength = compareValues?.length || 0;
    const analysisLength = analysisValues?.length || 0;
    return this.getCardMinHeight(Math.max(compareLength, analysisLength) * 41 + 51, maxHeight);
  }

  public cleanMetaDataModel(model: any): any {
    return CopyrightUtils.trimObjectValues(model);
  }

  private getCardMinHeight(totalHeight: number, maxHeight: number = 296): number {
    return Math.min(totalHeight, maxHeight) || maxHeight;
  }

  compareListsAndHighlightDifference(left$: Observable<any[]>, right$: Observable<any[]>, keyToCompare: string) {
    return ActivityUtils.compareListsAndHighlightDifference(left$, right$, keyToCompare, this.componentDestroyed);
  }

  public getTitle(model: any): string {
    return get(model, 'titles["OT"][0].national') || get(model, 'titles["OT"][0].titleValue');
  }

  public shouldDisplayCounterclaimIndicator(selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>): Observable<boolean> {
    return this.store.pipe(
      select(selector),
      filter(model => !!model),
      map(model => {
        const counterClaims = get(model, 'counterclaims', []);
        return !!counterClaims?.length && !!counterClaims?.filter(cc => cc?.attributes?.status !== CounterClaimStatus.RESOLVED).length;
      }),
    );
  }

  private isClaimsShareTypeSelected(value: CollectionOrOwnership) {
    let isSameType = this.claimType === WORK_CLAIM_SHARE_TYPE.COLLECTION && !this.claimType ? true : false;
    if (this.claimType === value) {
      isSameType = true;
    }
    return isSameType;
  }

  public getClaimsHeaderToggle() {
    return {
      hasClaimsHeader: true,
      claimsHeaderToggle: {
        options: [
          {
            value: WORK_CLAIM_SHARE_TYPE.COLLECTION,
            label: this.translate.instant('ACTIVITY.ANALYSIS_MERGE.CLAIMS_HEADER.COLLECTION'),
            checked: this.isClaimsShareTypeSelected(WORK_CLAIM_SHARE_TYPE.COLLECTION),
          },
          {
            value: WORK_CLAIM_SHARE_TYPE.OWNERSHIP,
            label: this.translate.instant('ACTIVITY.ANALYSIS_MERGE.CLAIMS_HEADER.OWNERSHIP'),
            checked: this.isClaimsShareTypeSelected(WORK_CLAIM_SHARE_TYPE.OWNERSHIP),
          },
        ],
        text: this.translate.instant('ACTIVITY.ANALYSIS_MERGE.CLAIMS_HEADER.CLAIM_VIEW'),
        onChange: claimTypeOption => {
          this.claimType = claimTypeOption.value;
          this.claimType$.next(claimTypeOption.value);
        },
      },
    };
  }
  public claimsComparatorConfig(openPopupButton = true, activityType: ActivityType = ActivityType.MERGE) {
    const openPopupButtonConfig = openPopupButton
      ? {
          claimsHeaderButtons: {
            emitEvent: action => {
              this.openClaimsViewPopup();
            },
            config: {
              type: 'matBtn',
              layout: 'row',
              customClass: 'claims-header-buttons-group',
              buttons: [
                {
                  label: 'ACTIVITY.ANALYSIS_MERGE.CLAIMS_HEADER.SHOW_CLAIMS_IN_FULLSCREEN',
                  action: '',
                  isHidden: of(false),
                  className: 'ice-text-button claims-fullscreen-button',
                  icon: 'aspect_ratio',
                  iconClassName: 'material-icons-outlined ice-ml-8',
                },
              ],
            },
          },
        }
      : {};
    return {
      ...this.getClaimsHeaderToggle(),
      ...openPopupButtonConfig,
      expansionListDataTableClaimsLeft: {
        className: 'analysis-left',
        listIdentifier: 'analysis-left',
        title: this.translate.instant('ACTIVITY.ANALYSIS.REVISION.SUBMITTED_CLAIMS'),
        buttonTitle: 'launch',
        buttonTitleTooltip: this.translate.instant('ACTIVITY.ANALYSIS.OPEN_WORK'),
        iconConflict: of('warning'),
        tooltipIconConflict: of(this.translate.instant('ACTIVITY.ANALYSIS.REVISION.POTENTIAL_CONFLICT')),
        openNewTab: (workId: string) => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}`] })),
        extraData: {
          workId: this.store.pipe(select(fromRoot.getActivitySelectedOptionToCompare)),
        },
        model: {
          customClass: 'analysis-table',
          model: this.sharesCompare.pipe(
            map(shareLines => shareLines.map(shareLine => (shareLine.compare ? this.cleanCompareLine(shareLine.compare, this.translate) : this.getDummyRow()))),
          ),
          sorts: [],
          columnMode: 'flex',
          schema: this.getSchemaClaims(activityType),
          selectionType: of(SelectionType.checkbox),
          selected: this.selectionClaimsAnalysisLeft$,
          displayCheck: (row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean => {
            return this.displayCheckbox(row, firstSelectedItem, targetItem, column, value);
          },
          rowIdentity: (row: any) => `${row.claimId} ${row.parentId} ${row.territoriesLabel} ${row.prRightsLabel} ${row.mrRightsLabel}`,
        },
        onSelectedCheckbox: (event: any) => {
          event.event.stopPropagation();
          this.selectedClaimsAnalysisLeft = this.handleClaimSelectionChange(
            event.event.target.checked,
            event.row,
            this.selectedClaimsAnalysisLeft,
            this.originalClaimsAnalysisLeft,
          );
          this.saveClaimsSelections('selectedClaimsAnalysisLeft_', this.selectedClaimsAnalysisLeft, this.originalClaimsAnalysisLeft);
          setTimeout(() => {
            this.selectionClaimsAnalysisLeft$.next(this.selectedClaimsAnalysisLeft);
          }, 0);
        },
        onSelect: ([row]) => {
          this.selectedClaimsAnalysisLeft = this.handleClaimSelectionChange(true, row, this.selectedClaimsAnalysisLeft, this.originalClaimsAnalysisLeft);
          this.saveClaimsSelections('selectedClaimsAnalysisLeft_', this.selectedClaimsAnalysisLeft, this.originalClaimsAnalysisLeft);
          setTimeout(() => {
            this.selectionClaimsAnalysisLeft$.next(this.selectedClaimsAnalysisLeft);
          }, 0);
        },
        singleSelection: true,
        selectable: true,
        onResized: event => this.drawHighLights(this.toggleHighlightActive),
        open: () => this.expanseRow(4),
        close: () => this.collapseRow(4),
        expanded: this.expanded4,
        height: this.ownershipCountObservable,
      },
      expansionListDataTableClaimsRight: {
        className: 'analysis-right',
        listIdentifier: 'analysis-right',
        title: this.translate.instant('ACTIVITY.ANALYSIS.REVISION.SUBMITTED_CLAIMS'),
        buttonTitle: 'launch',
        buttonTitleTooltip: this.translate.instant('ACTIVITY.ANALYSIS.OPEN_WORK'),
        iconConflict: of('warning'),
        tooltipIconConflict: of(this.translate.instant('ACTIVITY.ANALYSIS.REVISION.POTENTIAL_CONFLICT')),
        openNewTab: (workId: string) => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}`] })),
        extraData: {
          workId: this.store.pipe(
            select(fromRoot.getConflictsActivity),
            map((activity: ActivityDetail) => ConflictUtils.getWorkTitle(activity)),
          ),
        },
        model: {
          customClass: 'analysis-table',
          model: this.sharesCompare.pipe(
            map(shareLines => shareLines.map(shareLine => (shareLine.mastered ? this.cleanCompareLine(shareLine.mastered, this.translate) : this.getDummyRow()))),
          ),
          sorts: [],
          columnMode: 'flex',
          schema: this.getSchemaClaims(activityType),
          selectionType: of(SelectionType.checkbox),
          selected: this.selectionClaimsAnalysisRight$,
          displayCheck: (row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean => {
            return this.displayCheckbox(row, firstSelectedItem, targetItem, column, value);
          },
          rowIdentity: (row: any) => `${row.claimId} ${row.parentId} ${row.territoriesLabel} ${row.prRightsLabel} ${row.mrRightsLabel}`,
        },
        onSelectedCheckbox: (event: any) => {
          event.event.stopPropagation();
          this.selectedClaimsAnalysisRight = this.handleClaimSelectionChange(
            event.event.target.checked,
            event.row,
            this.selectedClaimsAnalysisRight,
            this.originalClaimsAnalysisRight,
          );
          this.saveClaimsSelections('selectedClaimsAnalysisRight_', this.selectedClaimsAnalysisRight, this.originalClaimsAnalysisRight);
          setTimeout(() => {
            this.selectionClaimsAnalysisRight$.next(this.selectedClaimsAnalysisRight);
          }, 0);
        },
        singleSelection: true,
        selectable: true,
        onSelect: rows => {
          const [row] = rows;
          this.selectedClaimsAnalysisRight = this.handleClaimSelectionChange(true, row, this.selectedClaimsAnalysisRight, this.originalClaimsAnalysisRight);
          this.saveClaimsSelections('selectedClaimsAnalysisRight_', this.selectedClaimsAnalysisRight, this.originalClaimsAnalysisRight);
          setTimeout(() => {
            this.selectionClaimsAnalysisRight$.next(this.selectedClaimsAnalysisRight);
          }, 0);
        },
        open: () => this.expanseRow(4),
        close: () => this.collapseRow(4),
        expanded: this.expanded4,
        height: this.ownershipCountObservable,
      },
    };
  }

  private openClaimsViewPopup() {
    this.store.dispatch(new fromRoot.OnAppFocus());
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      panelClass: 'claims-view-panel',
      data: {
        layouts: [this.getDialogClaimsViewLayout()],
        floatingAndInlineButtons: true,
        inlineButtons: [
          {
            color: 'accent',
            label: `ACTIVITY.ANALYSIS_MERGE.CLAIMS_HEADER.CLOSE_BUTTON`,
            action: 'DELETE_CLAIM',
            onClick: () => this.dialogRef.close(),
          },
        ],
      },
    });
  }

  private getDialogClaimsViewLayout() {
    return {
      title: of(this.translate.instant('ACTIVITY.ANALYSIS_MERGE.CLAIMS_HEADER.CLAIMS_COMPARISON')),
      className: 'dialog-wrapper-width-95vw',
      verticalFloatingButtons: true,
      actions: [FloatingButtons.forceMerge, FloatingButtons.discardMatch, FloatingButtons.modifyMatch].map(menuItem => ({
        ...menuItem,
        tooltip: this.translate.instant(menuItem.label),
        onClick: () => SharedActions.doSharedAction({ action: menuItem.action, dialog: this.dialog, translate: this.translate, store: this.store }),
      })),
      layout: [
        {
          group: [
            {
              type: 'analysisAndComparisons',
              config: this.claimsComparatorConfig(false),
            },
          ],
        },
      ],
    };
  }

  private handleClaimSelectionChange(checked: boolean, selectedRow: ClaimInfo, selectedClaimsAnalysisSide: ClaimInfo[], originalClaimsAnalysisSide: ClaimInfo[]) {
    let selectedClaimsAnalysisSideToManipulate = selectedClaimsAnalysisSide;
    if (checked) {
      selectedClaimsAnalysisSideToManipulate = ActivityUtils.claimsSharesSelect(selectedRow, originalClaimsAnalysisSide, selectedClaimsAnalysisSideToManipulate).filter(
        x => x.claimId,
      );
    } else {
      const claimsToUncheck = ActivityUtils.claimsSharesSelect(selectedRow, selectedClaimsAnalysisSideToManipulate, []);
      selectedClaimsAnalysisSideToManipulate = selectedClaimsAnalysisSideToManipulate.filter(
        ignoredClaim => !claimsToUncheck.some(claimToUncheck => claimToUncheck.claimId === ignoredClaim.claimId),
      );
      // when removing a child, the parent should also be removed. Selecting a parent implies to select all of the children.
      const parent = originalClaimsAnalysisSide.find(claim => claim.claimId === selectedRow.parentId);
      // we only have two levels depth, no need to go deeper
      const grandParent = originalClaimsAnalysisSide.find(claim => claim.claimId === parent?.parentId);
      selectedClaimsAnalysisSideToManipulate = selectedClaimsAnalysisSideToManipulate.filter(claim => ![grandParent?.claimId, parent?.claimId].includes(claim.claimId));
    }
    return selectedClaimsAnalysisSideToManipulate.filter(claim => claim.claimView === this.claimType);
  }

  private displayCheckbox(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return row.role === '' && row.ipiName === '' && row.name === '' && row.ipiNameKey === '' ? false : true;
  }

  private saveClaimsSelections(itemName: string, selectedSide: ClaimInfo[], originalSide: ClaimInfo[]) {
    sessionStorage.setItem(`${itemName}${WORK_CLAIM_SHARE_TYPE.COLLECTION}`, JSON.stringify(selectedSide.filter(claim => claim.claimView === WORK_CLAIM_SHARE_TYPE.COLLECTION)));
    sessionStorage.setItem(`${itemName}${WORK_CLAIM_SHARE_TYPE.OWNERSHIP}`, JSON.stringify(selectedSide.filter(claim => claim.claimView === WORK_CLAIM_SHARE_TYPE.OWNERSHIP)));
  }

  private processClaims(claims: ClaimInfo[], actionType: 'mastered' | 'compare', claimShareType: CollectionOrOwnership) {
    const filteredClaims = ConflictUtils.filterClaims(claims, {}, actionType, this.translate, claimShareType);
    return ConflictUtils.sortByPath(filteredClaims, 1)
      .map(claim => claim[actionType])
      .map(claim => ({
        ...claim,
        claimView: claimShareType,
      }));
  }
}
