import { ActivityDetail, CaseWorkDetail, ConflictUtils, WorkUtils } from '@ice';
import { createSelector } from '@ngrx/store';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getConflictsActivity = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getConflictsActivityState);

export const getActivityWorkAnalysis = createSelector(getConflictsActivity, (detail: ActivityDetail) => (detail && detail.workAnalysis) || ({} as CaseWorkDetail));

export const getActivityWorkAnalysisTitles = createSelector(
  getActivityWorkAnalysis,
  (workAnalysis: CaseWorkDetail) => (workAnalysis && workAnalysis.titles && ConflictUtils.getTitlesList(workAnalysis.titles)) || [],
);

export const getActivityWorkAnalysisInstruments = createSelector(getActivityWorkAnalysis, (workAnalysis: CaseWorkDetail) => (workAnalysis && workAnalysis.instruments) || []);

export const getActivityWorkAnalysisInstrumentations = createSelector(
  getActivityWorkAnalysis,
  (workAnalysis: CaseWorkDetail) => (workAnalysis && workAnalysis.standardInstrumentation) || [],
);

export const getActivityWorkAnalysisExtendedToP = createSelector(
  getActivityWorkAnalysis,
  workAnalysis => (workAnalysis && WorkUtils.getExtendedToP(workAnalysis, 'copyrightOverrides')) || [],
);

export const getActivityWorkAnalysisId = createSelector(getActivityWorkAnalysis, (workAnalysis: CaseWorkDetail) => workAnalysis?.id);

export const getActivityClaimsFilter = createSelector(getConflictsActivity, (detail: ActivityDetail) => (detail && detail.claimsFilter) || {});

export const getActivityWorkAnalysisRelations = createSelector(
  getActivityWorkAnalysis,
  workAnalysis => (workAnalysis && workAnalysis.relations && WorkUtils.getWorkToCompareXrefsIdsOrdered(workAnalysis.relations)) || [],
);

export const getActivityWorkAnalysisClaims = createSelector(getActivityWorkAnalysis, (workAnalysis: CaseWorkDetail) => workAnalysis && workAnalysis.claims);

export const getActivityWorkAnalysisSharePicture = createSelector(getActivityWorkAnalysis, (workAnalysis: CaseWorkDetail) => (workAnalysis && workAnalysis.ownerships) || []);

export const getActivityOptionsToCompare = createSelector(getConflictsActivity, (detail: ActivityDetail) => (detail && detail.optionsToCompare) || []);

export const getActivitySelectedOptionToCompare = createSelector(getConflictsActivity, (detail: ActivityDetail) => (detail && detail.selectedOptionToCompare) || '');

export const getActivityWork = createSelector(
  getConflictsActivity,
  (detail: ActivityDetail) => (detail && detail.works && detail.works.length && detail.works[0] && detail.works[0].work && detail.works[0].work.attributes) || {},
);

export const getActivityWorkToCompare = createSelector(getConflictsActivity, (detail: ActivityDetail) => (detail && detail.workToCompare) || ({} as CaseWorkDetail));

export const getActivityWorkToCompareClaims = createSelector(getActivityWorkToCompare, (workToCompare: CaseWorkDetail) => workToCompare && workToCompare.claims);

export const getActivityWorkToCompareSharePicture = createSelector(getActivityWorkToCompare, (workToCompare: CaseWorkDetail) => (workToCompare && workToCompare.ownerships) || []);

export const getActivityWorkToCompareTitles = createSelector(getActivityWorkToCompare, (workToCompare: CaseWorkDetail) => ConflictUtils.getTitlesList(workToCompare?.titles));

export const getActivityWorkToCompareInstruments = createSelector(getActivityWorkToCompare, (workToCompare: CaseWorkDetail) => (workToCompare && workToCompare.instruments) || []);

export const getActivityWorkToCompareInstrumentations = createSelector(
  getActivityWorkToCompare,
  (workToCompare: CaseWorkDetail) => (workToCompare && workToCompare.standardInstrumentation) || [],
);

export const getActivityWorkToCompareExtendedToP = createSelector(
  getActivityWorkToCompare,
  (workToCompare: CaseWorkDetail) => (workToCompare && WorkUtils.getExtendedToP(workToCompare, 'copyrightOverrides')) || [],
);

export const getActivityWorkToCompareRelations = createSelector(
  getActivityWorkToCompare,
  (workToCompare: CaseWorkDetail) => (workToCompare && workToCompare.relations && WorkUtils.getWorkToCompareXrefsIdsOrdered(workToCompare.relations)) || [],
);

export const getActivityConflictType = createSelector(getConflictsActivity, (detail: ActivityDetail) => detail && detail.attributes && detail.attributes.conflictType);

export const getActivityConflictSubType = createSelector(getConflictsActivity, (detail: ActivityDetail) => detail && detail.attributes && detail.attributes.conflictSubType);

export const getActivityResponse = createSelector(getConflictsActivity, (detail: ActivityDetail) => detail && detail.response);

export const getActivityAnalysisConflicts = createSelector(
  getActivityWorkAnalysis,
  (workAnalysis: CaseWorkDetail) => (workAnalysis && workAnalysis.conflicts && ConflictUtils.getActivityConflicts(workAnalysis.conflicts)) || null,
);

export const getActivityCompareConflicts = createSelector(
  getActivityWorkToCompare,
  (workToCompare: CaseWorkDetail) => (workToCompare && workToCompare.conflicts && ConflictUtils.getActivityConflicts(workToCompare.conflicts)) || null,
);

export const getActivityWorkAnalysisSocietyMarkers = createSelector(getActivityWorkAnalysis, (workAnalysis: CaseWorkDetail) => workAnalysis?.societyMarkers || []);

export const getActivityWorkToCompareSocietyMarkers = createSelector(getActivityWorkToCompare, (workToCompare: CaseWorkDetail) => workToCompare?.societyMarkers || []);

export const getScopeOfConflicts = createSelector(getConflictsActivity, (detail: ActivityDetail) => (detail && detail.scopeOfConflicts) || []);

export const getActivityWorkToCompareOtherParties = createSelector(getActivityWorkToCompare, (detail: CaseWorkDetail) => WorkUtils.getWorkPartyNames(detail?.partyNames));

export const getActivityWorkAnalysisOtherParties = createSelector(getActivityWorkAnalysis, (detail: CaseWorkDetail) => WorkUtils.getWorkPartyNames(detail?.partyNames));
