import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SectionsConfig } from 'config/sections-config';
import { last } from 'lodash';
import { of } from 'rxjs';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { DialogLayoutSearchParty } from '../dialog-layouts/dialog-layout-search-party';
import { EditModeDialog } from './common/edit-mode-dialog';

export class DialogClaimSearchParty {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;

  constructor(
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
    private dialog: MatDialog,
    private isWorkRegister = false,
  ) {}

  openDialog(type, organizationToFilter, onItemSelected, storeNewItem, includedOriginalItem = false, baseType = null) {
    const section = SectionsConfig.IPS.name;
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-85vw-90vh',
        layouts: [this.getLayoutSearchParty(type, organizationToFilter, onItemSelected, false, includedOriginalItem, baseType)],
        doPagination: _ => storeNewItem.dispatch(new fromForm.NewItemDoSearchNextPage({ type, section })),
      },
    });
  }

  getLayoutPublishers(onPublisherFromWorkSelected) {
    return {
      title: of(this.translate.instant('SELECT_ORGANIZATION_POPUP.TITLE_SELECT_PUBLISHER')),
      actions: [{ tooltip: this.translate.instant('SELECT_ORGANIZATION_POPUP.BT_SEARCH_PARTY'), nextLayout: 1, icon: 'search' }],
      layout: [
        {
          group: [
            {
              type: 'dataTable',
              config: {
                data: this.store.pipe(select(fromRoot.getWorkPublishers)),
                selectionType: 'multi',
                shadowed: true,
                showLoader: true,
                schema: EditModeDialog.getEditModeDatatableSchema(this.translate),
                sorts: [{ prop: 'value', dir: 'asc' }],
                visibleColumns: of(['value', 'key']),
                onSelect: (selected: any[]) => {
                  if (selected) {
                    onPublisherFromWorkSelected(Array.isArray(selected) ? selected[0] : selected);
                  }
                },
              },
            },
          ],
        },
      ],
    };
  }

  getLayoutSearchParty(type, organizationToFilter: string, onItemSelected, backButton = true, includedOriginalItem = false, baseType = null) {
    const { translate, translationLoader, store, storeNewItem } = this;
    const organizationFormatted = organizationToFilter && last(organizationToFilter.split(':')).replace('SO', '');
    const layoutSearchParty = new DialogLayoutSearchParty(translate, translationLoader, store, storeNewItem, false, this.isWorkRegister);
    return layoutSearchParty.getLayout(
      type,
      selected => {
        if (selected) {
          onItemSelected(selected);
          this.dialogRef.close();
        }
      },
      organizationToFilter ? { organizationMembership: `CISAC:${organizationFormatted}` } : {},
      backButton,
      includedOriginalItem,
      null,
      null,
      baseType,
    );
  }
}
