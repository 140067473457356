import { MatDialogRef } from '@angular/material/dialog';
import { ErrorsUtils } from '@ice/utils/api-responses/errors.utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromApiCalls from 'config/api-calls';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { ReportUtils } from '../../../../../@ice/utils/report/report.utils';
import { TRANSLATION_PREFIX } from '../../../../constants/response-errors-constants';
import { locale as tabEditTranslations } from '../../../../../assets/i18n/en/config/tab-edit-builders';

export class RepertoireEditInline {
  static afterUpdate(store: Store<any>, dialogRef: MatDialogRef<any>, translate: TranslateService, result: any, error: any, type: 'add' | 'edit' | 'delete' | 'new') {
    dialogRef.close();
    store.dispatch(new fromRoot.RefreshDetail());
    if (result) {
      store.dispatch(
        new fromRoot.ShowSnackBar({
          message: (type !== 'delete' && translate.instant(`REPERTOIRES.COMMON.ADD_SUCCESS`)) || translate.instant(`REPERTOIRES.COMMON.DELETE_SUCCESS`),
          duration: 3000,
        }),
      );
    } else if (error) {
      store.dispatch(
        new fromRoot.ShowSnackBar({
          message: error.error?.message || ErrorsUtils.parseResponseCustomError(translate, TRANSLATION_PREFIX, tabEditTranslations.data.RESPONSE_CUSTOM_ERRORS, error),
          duration: 3000,
        }),
      );
    }
  }

  static updateRepertoireWithMode(
    store: Store<any>,
    dialogRef: MatDialogRef<any, any>,
    translate: TranslateService,
    id: string,
    body: string,
    mode: string,
    itemIds?: Array<string>,
    currentIds?: Array<string>,
    http?: any,
    showDeleteSnack: boolean = false,
  ) {
    const type = mode === 'MERGE' ? 'add' : 'delete';
    if (!this.checkRepeatedIds(type, itemIds, currentIds, translate, store)) {
      store.dispatch(
        new fromRoot.StartApiCall({
          apiCall: fromApiCalls.updateRepertoireParties,
          apiCallData: { labels: { id, mode }, body },
          callBack: (result, error) => {
            if (error?.error?.group === 'REPERTOIRE_OVERLAPPING_SCOPES') {
              setTimeout(() => {
                ReportUtils.downloadFile(http.http, id, error.error.causes, 'application/text').subscribe(response => {
                  if (response.type === HttpEventType.Response) {
                    saveAs(response.body, `${id}.txt`);
                  }
                });
              }, 500);
            }
            this.afterUpdate(store, dialogRef, translate, result, error, showDeleteSnack ? 'delete' : type);
          },
        }),
      );
    }
  }

  static updateRepertoire(
    store: Store<any>,
    dialogRef: MatDialogRef<any>,
    translate: TranslateService,
    id: any,
    object: string,
    unsubscribeAll: Subject<unknown>,
    type: 'add' | 'edit' | 'delete' | 'new',
    currentIds,
  ) {
    if (!this.checkRepeatedIds(type, id, currentIds, translate, store)) {
      store.dispatch(new fromRoot.UpdateField({ object, newValue: id, type }));
      store.dispatch(new fromRoot.SaveSectionItem());
      store
        .select(fromRoot.getCopyrightResponse)
        .pipe(
          filter(response => !!response),
          take(1),
          takeUntil(unsubscribeAll),
        )
        .subscribe(response => {
          dialogRef.close();
          const error = ErrorsUtils.getResponseErrors(response);
          const result = !error && response;
          this.afterUpdate(store, dialogRef, translate, result, error, type);
        });
    }
  }

  static checkRepeatedIds(type, ids, currentIds, translate, store) {
    if (type === 'add' && RepertoireEditInline.checkForDuplicates(currentIds, ids)) {
      store.dispatch(
        new fromRoot.ShowSnackBar({
          message: translate.instant(`REPERTOIRES.COMMON.NO_CHANGES`),
          duration: 3000,
        }),
      );
      return true;
    }
    return false;
  }

  static checkForDuplicates(currentIds, newIds) {
    if (currentIds === undefined) {
      return false;
    }
    if (Array.isArray(newIds)) {
      return currentIds.filter(({ value: id1 }) => !newIds.some(({ value: id2 }) => id2 === id1)).length !== 0;
    } else {
      return currentIds.includes(newIds);
    }
  }
}
