import {
  ClaimantUtils,
  ClaimsUtils,
  CopyrightOverride,
  CopyrightUtils,
  DateTimeUtils,
  IpDetail,
  RepertoireDetail,
  TerritoryUtils,
  WorkClause,
  WorkDetail,
  WorkSociety,
} from '@ice';
import { IpNature, PartyRelationType, mrRights, prRights } from 'config/constants/ips.constants';
import { RELATIONTYPES } from 'config/constants/relation.constants';
import { RIGHT_TYPES } from 'config/constants/repertoires.constants';
import { WORK_CLAIM_SHARE_TYPE } from 'config/constants/shares.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { TitleTypes } from 'config/constants/works.constants';
import { SectionsConfig } from 'config/sections-config';
import { clone, cloneDeep, concat, difference, find, findIndex, get, has, isEqual, pickBy, pull, remove, set, toNumber, union } from 'lodash';
import { EditAction } from 'models/copyright/detail/edit';
import moment from 'moment';
import { ClaimRow } from 'models/copyright/detail/claims';
import { ConflictUtils } from '../conflict/conflict.utils';
import { SectionSendItemCleaner } from '../maps/send-update-map';
import { SocietiesUtils } from '../societies/societies.utils';
import { WorkUtils } from '../work/work.utils';

export class EditModeUtils {
  static edit(itemToEdit: any, action: EditAction, section: string) {
    switch (section) {
      case SectionsConfig.WORKS.name:
        this.editWork(itemToEdit, action);
        break;
      case SectionsConfig.IPS.name:
        this.editIP(itemToEdit, action);
        break;
      case SectionsConfig.CONFLICTS.name:
        ConflictUtils.editConflict(itemToEdit, action);
        break;
      case SectionsConfig.COUNTERCLAIMS.name:
        ConflictUtils.editCounterClaim(itemToEdit, action);
        break;
      case SectionsConfig.REPERTOIRES.name:
        this.editRepertoire(itemToEdit, action);
        break;
    }
  }

  static editIP(ip: IpDetail, action: EditAction) {
    const { object, newValue, type } = action;
    switch (object) {
      case 'partyName': {
        switch (type) {
          case 'edit': {
            const index = ip.partyNames.findIndex(({ id }) => id === newValue.id);
            ip.partyNames[index] = { ...ip.partyNames[index], ...newValue };
          }
        }
        break;
      }
      case 'clauses': {
        const clauses = ip.attributes.clauses || [];
        switch (type) {
          case 'new':
            if (!clauses.includes(newValue)) {
              clauses.push(newValue);
            }
            break;
          case 'delete':
            remove(clauses, clause => clause === newValue);
            break;
        }
        ip.attributes.clauses = [...clauses];
        break;
      }
      case 'alternativeName':
        ip.attributes.names = ip.attributes.names ? ip.attributes.names : [];
        const names = ip.attributes.names;
        const val = newValue;
        switch (type) {
          case 'new':
            const lastId = (names && names.map(name => name.nameId).sort()[names.length - 1]) || 0;
            names.push({ ...val, nameId: lastId + 1 });
            break;
          case 'edit':
            names.forEach(name => {
              if (name.nameId === val.nameId) {
                name.name = val.name;
                name.firstName = val.firstName;
                name.language = val.language;
              }
            });
            break;
          case 'delete':
            remove(names, name => name.nameId === newValue.nameId);
        }
        break;
      case IpNature.TYPE_PARENT: {
        switch (type) {
          case 'delete':
            const relationToRemove = (ip?.parentRelations || []).find(relation => relation.otherNameId === newValue.key);
            if (relationToRemove) {
              relationToRemove.deleted = true;
            }
            break;
        }
        break;
      }
      case IpNature.TYPE_CHILDREN: {
        switch (type) {
          case 'delete':
            const relationToRemove = (ip?.childrenRelationshipsData?.items || []).find(relation => relation.otherNameId === newValue.key);
            if (relationToRemove) {
              relationToRemove.deleted = true;
            }
            break;
        }
        break;
      }
    }
  }

  static editRepertoire(repertoire: RepertoireDetail, action: EditAction) {
    const { object, newValue, type } = action;
    switch (object) {
      case 'excludedWorks':
        switch (type) {
          case 'add':
            repertoire.attributes.exclude = { ...repertoire?.attributes?.exclude, workIds: [...(repertoire?.attributes?.exclude?.workIds || []), newValue] };
            break;
          case 'delete':
            remove(repertoire.attributes?.exclude?.workIds || [], id => CopyrightUtils.compareSuffix(id, newValue));
            break;
        }
        break;
      case 'excludedAgreements':
        switch (type) {
          case 'add':
            repertoire.attributes.exclude = { ...repertoire?.attributes?.exclude, agreementIds: [...(repertoire?.attributes?.exclude?.agreementIds || []), newValue] };
            break;
          case 'delete':
            remove(repertoire.attributes?.exclude?.agreementIds || [], id => CopyrightUtils.compareSuffix(id, newValue));
            break;
        }
        break;
    }
  }

  static editWork(work: WorkDetail, action: EditAction) {
    const { object, newValue, type } = action;

    switch (object) {
      case 'workClaims': {
        const claims = work.claims;
        switch (type) {
          case 'add': {
            const itemMapped = SectionSendItemCleaner['claim'](work, 'ICE', false, newValue);
            work.claims = get(itemMapped, 'claims', []);
            break;
          }
          case 'edit': {
            const claim = claims.find(workClaim => workClaim.claimId === newValue.claimId);
            if (claim) {
              this.addClaimTag(claim, 'updatedType', 'updated');
              WorkUtils.editWorkClaimShares(newValue, claim, WORK_CLAIM_SHARE_TYPE.SHARES);
              WorkUtils.editWorkClaimShares(newValue, claim, WORK_CLAIM_SHARE_TYPE.OWNERSHIP);
              ClaimantUtils.replaceClaimant(newValue, claim);
              const newClaims = ClaimsUtils.replaceClaims(newValue, claim, work);
              work.claims = [...newClaims];
            }
            break;
          }
          case 'delete': {
            const claim = claims.find(workClaim => workClaim.claimId === newValue.claimId);
            if (claim) {
              const newClaims = ClaimsUtils.removeClaimsAndChildren(claims, newValue);
              work.claims = [...newClaims];
            }
          }
        }
        break;
      }
      case 'worksIpRoleClaimsInline': {
        const claims = work.claims;
        switch (type) {
          case 'edit': {
            const claim = claims.find(workClaim => workClaim.claimId === newValue.claimId);
            this.addClaimTag(claim, 'updatedType', 'updated');
            claim.role = newValue.role.toUpperCase();
            claim.roleRaw = newValue.role.toUpperCase();
            work.claims = [...claims];
          }
        }
        break;
      }
      case 'workClaimsInline': {
        const claims = work.claims;
        switch (type) {
          case 'edit': {
            const claim = claims.find(workClaim => workClaim.claimId === newValue.claimId);
            this.addClaimTag(claim, 'updatedType', 'updated');
            const sourceClaims = newValue.type === WORK_CLAIM_SHARE_TYPE.SHARES ? claim.claimShares : claim.ownershipShares;
            const claimShare = sourceClaims[newValue.index];
            if (parseFloat(newValue.share) <= 100) {
              claimShare.share = (newValue.share && parseFloat(newValue.share) * 100) || 0;
            }
            claimShare.share = newValue.share === '' ? 0 : claimShare.share;
            work.claims = [...claims];
          }
        }
        break;
      }
      case 'workPrClaimsInline': {
        const claims = work.claims;
        switch (type) {
          case 'edit': {
            const claim = claims.find(workClaim => workClaim.claimId === newValue.claimId);
            this.addClaimTag(claim, 'updatedType', 'updated');
            const sourceClaims = newValue.type === WORK_CLAIM_SHARE_TYPE.SHARES ? claim.claimShares : claim.ownershipShares;
            if (sourceClaims.length === 1) {
              this.cloneAndSplitClaim(sourceClaims, RIGHT_TYPES.PR);
            }
            const claimShare = this.getClaimShare(sourceClaims, newValue, RIGHT_TYPES.PR);
            if (parseFloat(newValue.prShares) <= 100) {
              claimShare.share = (newValue.prShares && parseFloat(newValue.prShares) * 100) || 0;
            }
            claimShare.share = newValue.prShares === '' ? 0 : claimShare.share;
            work.claims = [...claims];
          }
        }
        break;
      }
      case 'workMrClaimsInline': {
        const claims = work.claims;
        switch (type) {
          case 'edit': {
            const claim = claims.find(workClaim => workClaim.claimId === newValue.claimId);
            this.addClaimTag(claim, 'updatedType', 'updated');
            const sourceClaims = newValue.type === WORK_CLAIM_SHARE_TYPE.SHARES ? claim.claimShares : claim.ownershipShares;
            if (sourceClaims.length === 1) {
              this.cloneAndSplitClaim(sourceClaims, RIGHT_TYPES.MR);
            }
            const claimShare = this.getClaimShare(sourceClaims, newValue, RIGHT_TYPES.MR);
            if (parseFloat(newValue.mrShares) <= 100) {
              claimShare.share = (newValue.mrShares && parseFloat(newValue.mrShares) * 100) || 0;
            }
            claimShare.share = newValue.mrShares === '' ? 0 : claimShare.share;
            work.claims = [...claims];
          }
        }
        break;
      }
      case 'relations': {
        const relations = work.relations;
        switch (type) {
          case 'new': {
            if (!relations.find(relation => relation.relation === newValue.relation && relation.otherId === newValue.otherId)) {
              relations.push(newValue);
            }
            break;
          }
          case 'delete': {
            remove(relations, relation => relation.otherId === newValue.otherId && relation.relation === newValue.relation);
            work.relations = [...relations];
            break;
          }
        }
        break;
      }
      case 'workExternalRelations': {
        const workExternalRelations = work.workExternalRelations;
        switch (type) {
          case 'delete': {
            work.workExternalRelations.items = workExternalRelations.items.map(relation =>
              `ICE:${relation.keyWithoutPrefix}` === newValue.otherId && relation.relationType === newValue.relation ? { ...relation, modified: 'deleted' } : relation,
            );
          }
        }
        break;
      }
      case 'workInternalRelations': {
        const workInternalRelations = work.workInternalRelations;
        const relations = work.relations;
        switch (type) {
          case 'delete': {
            remove(workInternalRelations.items, relation => `ICE:${relation.keyWithoutPrefix}` === newValue.otherId && relation.relationType === newValue.relation);
            remove(
              relations,
              relation =>
                CopyrightUtils.getKeySuffixNumerical(relation.otherId) === CopyrightUtils.getKeySuffixNumerical(newValue.otherId) && relation.relation === newValue.relation,
            );
            work.relations = [...relations];
            work.workInternalRelations.items = [...workInternalRelations.items];
          }
        }
        break;
      }
      case 'instruments': {
        if (!work.attributes.instruments) {
          work.attributes.instruments = [];
        }
        const instruments = work.attributes.instruments;
        switch (type) {
          case 'new': {
            if (!instruments.find(instrument => instrument.instrumentCode === newValue.instrumentCode)) {
              instruments.push({ ...newValue, index: instruments.length });
            }
            break;
          }
          case 'delete': {
            remove(instruments, instrument => instrument.instrumentCode === newValue);
            break;
          }
          case 'edit': {
            instruments[newValue.index] = newValue;
            break;
          }
        }
        break;
      }
      case 'instrumentations': {
        if (!work.attributes.standardInstrumentation) {
          work.attributes.standardInstrumentation = [];
        }
        const instrumentations = work.attributes.standardInstrumentation;

        switch (type) {
          case 'new': {
            if (!instrumentations.find(instrumentation => instrumentation.instrumentCode === newValue.instrumentCode)) {
              instrumentations.push({ ...newValue, index: instrumentations.length });
            }
            break;
          }
          case 'delete': {
            remove(instrumentations, instrumentation => instrumentation.instrumentCode === newValue);
            break;
          }
          case 'edit': {
            instrumentations[newValue.index] = newValue;
            break;
          }
        }
        break;
      }
      case 'otherParties': {
        work.partyNames = work.partyNames || [];
        const otherParties = work.partyNames;
        switch (type) {
          case 'new': {
            const { partyNameId, ipiNameNumber, name, role, firstName, dummyIp, partyId, previousPartyNameId } = newValue;
            const partyNameIndex = findIndex(otherParties, partyName => partyName.partyNameId === (previousPartyNameId || partyNameId));
            if (partyNameIndex !== -1) {
              otherParties[partyNameIndex].role = role;
              otherParties[partyNameIndex].partyName.attributes.typeOfName = role;
              otherParties[partyNameIndex].partyName.attributes.name = name;
              otherParties[partyNameIndex].partyName.attributes.firstName = firstName;
              set(otherParties[partyNameIndex].partyName, 'parties[0].party.attributes', dummyIp);
            } else {
              const partyNameItem = {
                partyNameId,
                role,
                partyName: {
                  id: partyNameId,
                  attributes: {
                    firstName,
                    ns: 'CUBE',
                    typeOfName: role,
                    name,
                    id: partyNameId,
                  },
                  ...(ipiNameNumber
                    ? {}
                    : {
                        parties: [
                          {
                            ns: 'OP',
                            partyNameId,
                            partyId,
                            version: 1,
                            party: {
                              ns: 'OP',
                              id: partyId,
                              version: 1,
                              attributes: dummyIp,
                            },
                          },
                        ],
                      }),
                  ...(ipiNameNumber
                    ? {
                        relations: [
                          {
                            otherId: `IPI:${ipiNameNumber}`,
                            relation: PartyRelationType.CROSS_REFERENCE,
                          },
                        ],
                      }
                    : {}),
                },
              };
              otherParties.push(partyNameItem);
            }
            break;
          }
          case 'edit': {
            const { partyNameId, ipiNameNumber, name, role, firstName, dummyIp, partyId, previousPartyNameId, previousRole } = action.newValue;
            const partyNameIndex = findIndex(
              otherParties,
              partyName => get(partyName, 'partyNameId', '') === (previousPartyNameId || partyNameId) && get(partyName, 'role', '') === (previousRole || role),
            );
            if (previousPartyNameId) {
              remove(otherParties, party => party.partyNameId === previousPartyNameId && party.role === (previousRole || role));
              const partyNameItem = {
                partyNameId,
                role,
                partyName: {
                  id: partyNameId,
                  attributes: {
                    firstName,
                    ns: 'CUBE',
                    typeOfName: role,
                    name,
                    id: partyNameId,
                  },
                  ...(ipiNameNumber
                    ? {}
                    : {
                        parties: [
                          {
                            ns: 'OP',
                            partyNameId,
                            partyId,
                            version: 1,
                            party: {
                              ns: 'OP',
                              id: partyId,
                              version: 1,
                              attributes: dummyIp,
                            },
                          },
                        ],
                      }),
                  ...(ipiNameNumber
                    ? {
                        relations: [
                          {
                            otherId: `IPI:${ipiNameNumber}`,
                            relation: PartyRelationType.CROSS_REFERENCE,
                          },
                        ],
                      }
                    : {}),
                },
              };
              otherParties.push(partyNameItem);
            } else if (partyNameIndex !== -1) {
              otherParties[partyNameIndex].role = role;
              otherParties[partyNameIndex].partyName.attributes.typeOfName = role;
              otherParties[partyNameIndex].partyName.attributes.name = name;
              otherParties[partyNameIndex].partyName.attributes.firstName = firstName;
            }
            break;
          }
          case 'delete': {
            const { partyNameId, role } = newValue;
            remove(otherParties, party => party.partyNameId === partyNameId && party.role === role);
            break;
          }
        }
        break;
      }
      case 'clauses': {
        const clauses = work.clauses;
        work.partyNames = work.partyNames || [];
        switch (type) {
          case 'new':
            {
              const { ipiList, typeSelected, name, clauseType } = action.newValue;
              const workClausesToAdd: WorkClause[] = ipiList
                .map(item => ({
                  type: typeSelected,
                  ipiNumber: item.refLabel,
                  partyNameId: item.partyNameId,
                  ipiName: item.name,
                  name,
                  clauseType,
                }))
                .filter(clause => !clauses.map(originalClause => `${originalClause.ipiNumber}|${originalClause.type}`).includes(`${clause.ipiNumber}|${clause.type}`));
              work.clauses.push(...workClausesToAdd);
              const submitWorkClauses: any = ipiList.map(item => ({
                partyNameId: item.partyNameId,
                clauses: [typeSelected],
              }));
              submitWorkClauses.forEach(ipi => {
                const partyNameIndex = findIndex(work.partyNames, partyName => partyName.partyNameId === ipi.partyNameId);
                if (partyNameIndex !== -1) {
                  work.partyNames[partyNameIndex].clauses = union(work.partyNames[partyNameIndex].clauses, [typeSelected]);
                } else {
                  work.partyNames.push(ipi);
                }
              });
            }
            break;
          case 'delete':
            {
              const { partyNameId } = action.newValue;
              remove(work.clauses, clause => clause.type === action.newValue.type && clause.partyNameId === partyNameId);
              const partyNameIndex = findIndex(work.partyNames, partyName => partyName.partyNameId === partyNameId);
              if (partyNameIndex !== -1) {
                remove(work.partyNames[partyNameIndex].clauses, clauseType => clauseType === action.newValue.type);
              }
            }
            break;
        }
        break;
      }
      case 'alternativeTitle':
        const attributes = work.attributes;
        const titles = attributes.titles;
        let titleType;
        let titleToAdd;
        const val = get(newValue, 'title');
        if (val) {
          titleType = get(newValue, 'type');
          titleToAdd = {
            national: newValue.title,
            duration: DateTimeUtils.setDurationToSeconds(get(newValue, 'durationFormatted')),
            sequenceNumber: 0,
          };
        }
        switch (type) {
          case 'new': {
            if (!WorkUtils.checkPreviousTitleExist(attributes.titles, titleType, titleToAdd)) {
              attributes.titles = {
                ...titles,
                [TitleTypes[titleType]]: (titles && titles[TitleTypes[titleType]] && [...titles[TitleTypes[titleType]], titleToAdd]) || [titleToAdd],
              };
            }
            break;
          }
          case 'edit': {
            const { index, oldType } = newValue;
            const titlesOfType = titles[TitleTypes[titleType]] || [];
            // The type of title has changed
            if (oldType !== titleType) {
              const titlesOldType = titles[TitleTypes[oldType]];
              titlesOldType.splice(index, 1);

              if (titles[TitleTypes[titleType]] && titles[TitleTypes[titleType]].length) {
                titles[TitleTypes[titleType]].push(titleToAdd);
              } else {
                titlesOfType.push(titleToAdd);
                titles[TitleTypes[titleType]] = titlesOfType;
              }
            } else {
              // Only update the title of the same register in the titles table
              titlesOfType[index]['national'] = val;
              titlesOfType[index]['duration'] = DateTimeUtils.setDurationToSeconds(get(newValue, 'durationFormatted'));
            }
            break;
          }
          case 'delete':
            titles.AL = titles.AL && titles.AL.filter(title => title['titleValue'] !== newValue);
            titles.AT = titles.AT && titles.AT.filter(title => title['titleValue'] !== newValue);
            titles.ET = titles.ET && titles.ET.filter(title => title['titleValue'] !== newValue);
            titles.FT = titles.FT && titles.FT.filter(title => title['titleValue'] !== newValue);
            titles.IT = titles.IT && titles.IT.filter(title => title['titleValue'] !== newValue);
            titles.OL = titles.OL && titles.OL.filter(title => title['titleValue'] !== newValue);
            titles.OT = titles.OT && titles.OT.filter(title => title['titleValue'] !== newValue);
            titles.PT = titles.PT && titles.PT.filter(title => title['titleValue'] !== newValue);
            titles.RT = titles.RT && titles.RT.filter(title => title['titleValue'] !== newValue);
            titles.TE = titles.TE && titles.TE.filter(title => title['titleValue'] !== newValue);
            titles.TT = titles.TT && titles.TT.filter(title => title['titleValue'] !== newValue);
            titles.DI = titles.DI && titles.DI.filter(title => title['titleValue'] !== newValue);
            titles.DT = titles.DT && titles.DT.filter(title => title['titleValue'] !== newValue);
            titles.LT = titles.LT && titles.LT.filter(title => title['titleValue'] !== newValue);
            titles.OV = titles.OV && titles.OV.filter(title => title['titleValue'] !== newValue);
            titles.FA = titles.FA && titles.FA.filter(title => title['titleValue'] !== newValue);
            titles.NT = titles.NT && titles.NT.filter(title => title['titleValue'] !== newValue);
            titles.CT = titles.CT && titles.CT.filter(title => title['titleValue'] !== newValue);
            titles.VT = titles.VT && titles.VT.filter(title => title['titleValue'] !== newValue);
        }
        break;
      case 'durationFormatted':
        work.attributes.duration = (newValue && DateTimeUtils.setDurationToSeconds(newValue)) || null;
        break;
      case 'ExtProtection':
        if (newValue) {
          switch (type) {
            case 'new':
              const { ip, societies, territories, protectedUntil } = newValue;
              const newCopyrightOverride: CopyrightOverride = {
                territories: { tisDate: DateTimeUtils.getTodayFormatted(), inExTisns: TerritoryUtils.convertTerritoryStringElements(territories, TerritoryDataType.TISA) },
                societyIds: societies.split(','),
                protectionDate: moment(protectedUntil).format('YYYY-MM-DD'),
              };
              if (newValue.type === 'work') {
                // Work Level
                if (work.attributes) {
                  const copyrightOverrides: CopyrightOverride[] = work.attributes.copyrightOverrides || [];
                  copyrightOverrides.push(newCopyrightOverride);
                  work.attributes.copyrightOverrides = copyrightOverrides;
                }
              } else if (newValue.type === 'ip') {
                // IP Level
                work.contributions = work.contributions.map(contributor => {
                  if (contributor.contributorId === ip) {
                    const copyrightOverrides: CopyrightOverride[] = contributor.copyrightOverrides || [];
                    copyrightOverrides.push(newCopyrightOverride);
                    contributor.copyrightOverrides = copyrightOverrides;
                  }
                  return contributor;
                });
                work.manuscriptData = work.manuscriptData.map(manuscriptData => {
                  if (manuscriptData.contributorId === ip) {
                    const copyrightOverrides: CopyrightOverride[] = manuscriptData.copyrightOverrides || [];
                    copyrightOverrides.push(newCopyrightOverride);
                    manuscriptData.copyrightOverrides = copyrightOverrides;
                  }
                  return manuscriptData;
                });
              }
              break;

            case 'delete':
              if (newValue.contributorId) {
                // IP Level
                work.contributions = work.contributions.map(contributor => {
                  if (contributor.contributorId === newValue.contributorId) {
                    const copyrightOverrides: CopyrightOverride[] = contributor.copyrightOverrides || [];
                    contributor.copyrightOverrides = WorkUtils.removeOverrides(copyrightOverrides, newValue);
                  }
                  return contributor;
                });
                work.manuscriptData = work.manuscriptData.map(manuscriptData => {
                  if (manuscriptData.contributorId === newValue.contributorId) {
                    const copyrightOverrides: CopyrightOverride[] = manuscriptData.copyrightOverrides || [];
                    manuscriptData.copyrightOverrides = WorkUtils.removeOverrides(copyrightOverrides, newValue);
                  }
                  return manuscriptData;
                });
              } else {
                // Work Level
                const copyrightOverrides: CopyrightOverride[] = work.attributes.copyrightOverrides;
                if (copyrightOverrides) {
                  work.attributes.copyrightOverrides = WorkUtils.removeOverrides(copyrightOverrides, newValue);
                }
              }
              break;
          }
        }
        break;
      case 'incomeParticipant':
        if (newValue) {
          switch (type) {
            case 'edit':
              const { claimantPartyId, claimantPartyNameId, incomeParticipant, role } = newValue;
              if (work.claims.find(claim => claim.claimantId === `${claimantPartyNameId}_${claimantPartyId}`)) {
                // Edit existing claim
                work.claims = work.claims.map(claim => {
                  if (claim.claimantId === `${claimantPartyNameId}_${claimantPartyId}`) {
                    return { ...claim, extensions: { ...claim.extensions, incomeParticipant: [incomeParticipant.toString()] } };
                  } else {
                    return claim;
                  }
                });
              } else {
                // Generate new claim
                work.claims = concat(work.claims, [
                  {
                    claimantId: `${claimantPartyNameId}_${claimantPartyId}`,
                    claimantPartyNameId,
                    claimantPartyId,
                    role: role.replace(/&nbsp;/g, ''),
                    extensions: { incomeParticipant: [incomeParticipant.toString()] },
                  } as unknown as ClaimRow, // because it will be converted in the BE
                ]);
              }
          }
        }
        break;
      case 'societyMarkers':
        let newSocietyMarkers = [];
        if (work.attributes && newValue) {
          const oldSocietyMarkers = work.attributes.societyMarkers;
          newSocietyMarkers = oldSocietyMarkers.includes(newValue) ? pull(oldSocietyMarkers, newValue) : [...oldSocietyMarkers, newValue];
          work.attributes[action['object']] = newSocietyMarkers;
        }
        break;
      case 'societyGradings':
        if (work.societies && newValue) {
          const { societyCode } = newValue;
          let indexSociety = -1;
          const society = work.societies.find((soc, index) => {
            const isSocietyFound = soc.societyId === `CISAC:${toNumber(societyCode)}`;
            if (isSocietyFound) {
              indexSociety = index;
            }
            return isSocietyFound;
          });

          switch (type) {
            case 'edit':
              if (society) {
                // EDIT
                const newGrading: any = pickBy(newValue, (attribute, value) => attribute && !['societyCode', 'grading'].includes(value));
                if (Array.isArray(society.grading)) {
                  let gradingFoundIndex = -1;
                  const gradingFound = society.grading.find((grading, index) => {
                    const newValueGrading = newValue && newValue.grading;
                    const isFound =
                      grading &&
                      newValueGrading &&
                      grading.gradingValue === newValueGrading.gradingValue &&
                      grading.gradingDecider === newValueGrading.gradingDecider &&
                      grading.gradingStatus === newValueGrading.gradingStatus &&
                      grading.gradingDate === newValueGrading.gradingDate;

                    if (isFound) {
                      gradingFoundIndex = index;
                    }
                    return isFound;
                  });

                  // Edit row of grading
                  // Add new grading value to society that exists
                  if (gradingFoundIndex >= 0) {
                    society.grading[gradingFoundIndex] = clone(newGrading);
                  } else {
                    society.grading.push(newGrading);
                  }
                } else {
                  // Add first one grading for this society
                  society.grading = [];
                  society.grading.push(newGrading);
                }
              } else {
                // ADD
                const gradingValue = get(newValue, 'gradingValue');
                if (gradingValue && !['blank', '-', 'empty'].includes(gradingValue.toLowerCase().trim())) {
                  const gradingToSend = [newValue];
                  const newSociety: WorkSociety = {
                    ns: work.attributes.ns,
                    societyId: `CISAC:${toNumber(societyCode)}`,
                    attributes: {},
                    grading: gradingToSend,
                    workId: work.id,
                    society: {
                      id: `CISAC:${toNumber(societyCode)}`,
                      attributes: { ns: 'CISAC', id: `CISAC:${toNumber(societyCode)}`, name: SocietiesUtils.getSocietyName(societyCode) },
                    },
                  };
                  work.societies.push(newSociety);
                }
              }
              break;
            case 'delete':
              if (indexSociety > -1) {
                const indexGrading = society.grading.findIndex(grading => grading === newValue);
                work.societies[indexSociety].grading.splice(indexGrading, 1);
                if (work.societies[indexSociety].grading.length === 0 && Object.keys(work.societies[indexSociety].attributes).length === 0) {
                  work.societies.splice(indexSociety, 1);
                }
              }
              break;
          }
        }
        break;
      case 'source-works':
        {
          const sourceWorks = work.sourceWorks.items;
          switch (type) {
            case 'delete':
              {
                remove(sourceWorks, sourceWork => sourceWork.id === newValue);
              }
              break;
          }
        }
        break;
      case 'societyAttributes':
        if (work.societies && newValue) {
          const { societyCode } = newValue;
          switch (type) {
            case 'edit':
              const newAttributes = pickBy(newValue, (attribute, value) => attribute && !['societyCode', 'grading'].includes(value));
              const isNewSociety = !work.societies.find(society => society.societyId === `CISAC:${toNumber(societyCode)}`);
              if (isNewSociety) {
                // Add
                const attributesToSend: Object = {};

                Object.keys(newAttributes).forEach(attribute => {
                  const value = newAttributes[attribute];
                  if (value && !['blank', '-', 'empty'].includes(value.toLowerCase().trim())) {
                    attributesToSend[attribute] = [newAttributes[attribute]];
                  }
                });
                if (Object.keys(attributesToSend).length) {
                  const newSociety: WorkSociety = {
                    ns: work.attributes.ns,
                    societyId: `CISAC:${toNumber(societyCode)}`,
                    attributes: attributesToSend,
                    workId: work.id,
                    society: {
                      id: `CISAC:${toNumber(societyCode)}`,
                      attributes: { ns: 'CISAC', id: `CISAC:${toNumber(societyCode)}`, name: SocietiesUtils.getSocietyName(societyCode) },
                    },
                  };
                  work.societies.push(newSociety);
                }
              } else {
                // Edit
                work.societies.map(society => {
                  if (society.societyId === `CISAC:${toNumber(societyCode)}`) {
                    Object.keys(newAttributes).forEach(attribute => {
                      const value = newAttributes[attribute];
                      if (value && !['blank', '-', 'empty'].includes(value.toLowerCase().trim())) {
                        society.attributes = society.attributes || {};
                        society.attributes[attribute] = [newAttributes[attribute]];
                      }
                    });

                    // clean deleted attributes
                    Object.keys(society.attributes).forEach(attribute => {
                      if (!newAttributes[attribute]) {
                        delete society.attributes[attribute];
                      }
                    });
                  }
                });
              }
              break;
            case 'delete':
              const { attributeName } = newValue;
              let indexSociety = -1;
              work.societies.map((society, index) => {
                if (society.societyId === `CISAC:${toNumber(societyCode)}`) {
                  indexSociety = index;
                  delete society.attributes[attributeName];
                }
              });
              if (work.societies[indexSociety].grading.length === 0 && Object.keys(work.societies[indexSociety].attributes).length === 0) {
                work.societies.splice(indexSociety, 1);
              }
          }
        }
        break;
      case 'xrefs':
        if (work.relations && newValue) {
          switch (type) {
            case 'edit':
            case 'new':
              const xrefValue = newValue;
              const xrefToAddOrEdit = { otherId: `${xrefValue.type.value}:${xrefValue.xref}`, relation: RELATIONTYPES.XREF };
              if (type === 'new') {
                work.relations.push(xrefToAddOrEdit);
              } else {
                const oldXref = xrefValue.xrefToEdit.join(':'); // Support for multiple : in namespace SWREF:022:123
                work.relations = work.relations.map(relation => {
                  if (relation.otherId === oldXref && relation.relation === RELATIONTYPES.XREF) {
                    relation.otherId = `${xrefValue.type.value}:${xrefValue.xref}`;
                  }
                  return relation;
                });
              }
              break;
            case 'delete':
              work.relations = work.relations.filter(relation => relation !== find(work.relations, rel => rel.otherId === newValue && rel.relation === RELATIONTYPES.XREF));
              break;
          }
        }
        break;
      case 'publicationDate':
      case 'musicArrangement':
      case 'lyricAdaption':
      case 'origin':
      case 'language':
      case 'genre':
      case 'prohibited':
      case 'category':
      case 'purpose':
      case 'textMusicRelationship':
      case 'source':
        if (work?.attributes) {
          const formattedNewValue = newValue === '-' ? '' : newValue;
          work.attributes[action['object']] = formattedNewValue;
        }
        break;
    }
  }

  static cloneAndSplitClaim(sourceClaims: any, rightType: string) {
    const rightsCollection: string[] = rightType === RIGHT_TYPES.PR ? prRights : mrRights;
    const newClaim = cloneDeep(sourceClaims[0]);
    newClaim.rightTypes = difference(newClaim.rightTypes, rightsCollection);
    sourceClaims[0].rightTypes = difference(sourceClaims[0].rightTypes, newClaim.rightTypes);
    const { prShares, mrShares } = SocietiesUtils.getPrMrShares(newClaim);
    sourceClaims.push({ ...newClaim, prShares, mrShares });
  }

  static getClaimShare(sourceClaims: any, newValue: any, rightType: string) {
    const rightsCollection: string[] = rightType === RIGHT_TYPES.PR ? prRights : mrRights;
    const matchClaim = (sourceClaims || []).find(claim => SocietiesUtils.checkClaimHasRights(claim.rightTypes, rightsCollection) && this.compareClaimRows(claim, newValue));
    return matchClaim || {};
  }

  static addClaimTag(claim: object, tagKey: string, tagValue: string) {
    if (claim && tagKey && tagValue) {
      if (!has(claim, 'tags')) {
        claim['tags'] = {};
      }
      claim['tags'][tagKey] = [tagValue];
    }
  }

  static compareClaimRows(currentClaim, newClaim) {
    return (
      isEqual(currentClaim.territories, newClaim.territories) &&
      isEqual(currentClaim.priorRoyaltiesDate, newClaim.priorDate) &&
      isEqual(currentClaim.startDate, newClaim.startDate) &&
      isEqual(currentClaim.endDate, DateTimeUtils.getIndefiniteDate(newClaim.endDate)) &&
      isEqual(currentClaim.postTermCollectionDate, DateTimeUtils.getIndefiniteDate(newClaim.postTermCollectionDate))
    );
  }
}
