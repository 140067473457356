import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AllClaimsFilter, ClaimantUtils, ClaimGraphUtils, TerritoryUtils, WorkClaimsUtils } from '@ice';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { tisnTisaConversionItems } from 'assets/ts/tisnTisaConversionItems';
import * as fromApiCalls from 'config/api-calls';
import { CUBE } from 'config/constants/global.constants';
import { INCLUDE_WORLD_IN_EX_TISN, TerritoryDataType } from 'config/constants/territories.constants';
import { CopyrightClaimsDataTable } from 'config/data-table-builders/copyright.claims';
import { DialogCounterClaimResolvers } from 'config/dialog-builders/dialog-counter-claim-resolvers';
import { ApiCallData } from 'config/sections-config/api-call';
import { StepType } from 'config/stepper-builders/stepper-config';
import { environment } from 'config/env';
import { combineLatest, of, Subscription } from 'rxjs';
import { map, share, take } from 'rxjs/operators';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { SearchUtilsFactory } from 'store/root/utils';
import { PermissionsService } from 'services/permissions/permissions.service';
export class CounterClaimRegistrationSelectStep {
  private schema: CopyrightClaimsDataTable;
  private resolversDialog: DialogCounterClaimResolvers;
  private claimantsSubscription: Subscription;
  filterParams: AllClaimsFilter;
  workId: string;
  requestId: string;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private dialog: MatDialog,
    private permissionsService: PermissionsService,
  ) {
    this.schema = new CopyrightClaimsDataTable(this.translate, this.fuseTranslationLoader, this.store, this.permissionsService);
    this.store.pipe(select(fromRoot.getRouterQueryParams), take(1)).subscribe(queryParams => {
      const { workId, workCubeId, ...filterParams } = queryParams;
      if (environment.apiNamespace === CUBE && workCubeId) {
        this.requestId = workCubeId;
      } else {
        this.requestId = workId;
      }
      const formattedFilterParams = SearchUtilsFactory.formatParamsAdvancedSearch(filterParams, null, false);
      const { territory, mrRight, prRight, usageDate, distributionDate, status } = formattedFilterParams;
      const filterClaims = ClaimGraphUtils.getClaimFilter(territory, usageDate, distributionDate, mrRight, prRight, status as any);
      this.filterParams = formattedFilterParams;
      this.resolversDialog = new DialogCounterClaimResolvers(this.translate, this.store, this.dialog, this.schema, this.filterParams);
      const apiCallData: ApiCallData = { labels: { id: this.requestId, filter: filterClaims }, cleanerData: { filterTerritories: territory } };
      this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getAllClaimsWork, apiCallData }));
    });
  }

  getStep(translate: TranslateService): StepType {
    return {
      label: translate.instant('COUNTERCLAIMS.STEPS.SELECT'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-3',
              fieldGroup: [
                {
                  key: 'countryLabel',
                  type: 'ice-input',
                  defaultValue:
                    (this.filterParams?.territory && TerritoryUtils.convertTerritoryStringElements(this.filterParams.territory, TerritoryDataType.TISA).join(', ')) ||
                    this.translate.instant('COUNTERCLAIMS.FORM.FIELDS.DEFAULT_TERRITORY'),
                  templateOptions: {
                    label: translate.instant('COUNTERCLAIMS.FORM.FIELDS.TERRITORY'),
                    placeholder: translate.instant('COUNTERCLAIMS.FORM.FIELDS.TERRITORY'),
                    disabled: true,
                    tooltipDuration: 250,
                    tooltipField: 'countryLabelTooltip',
                  },
                },
                {
                  key: 'country',
                  type: 'input',
                  hide: true,
                  defaultValue:
                    (this.filterParams?.territory && TerritoryUtils.convertTerritoryStringElements(this.filterParams.territory, TerritoryDataType.TISA)) ||
                    INCLUDE_WORLD_IN_EX_TISN,
                  templateOptions: {
                    label: translate.instant('COUNTERCLAIMS.FORM.FIELDS.TERRITORY'),
                    required: false,
                    disabled: true,
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  validators: {},
                  asyncValidators: {},
                },
                {
                  key: 'countryLabelTooltip',
                  type: 'input',
                  hide: true,
                  defaultValue:
                    (!(TerritoryUtils.getGroupsSelected(tisnTisaConversionItems, this.filterParams.territory).filter(item => item.tisa === '2WL').length > 0)
                      ? TerritoryUtils.getTerritoriesNamesTooltipTextWithoutHtml(
                          this.filterParams && this.filterParams.territory && TerritoryUtils.convertTerritoryStringElements(this.filterParams.territory, TerritoryDataType.NAME),
                        )
                      : [tisnTisaConversionItems.find(item => item.tisa === '2WL').name]) || [],
                  templateOptions: {
                    label: translate.instant('COUNTERCLAIMS.FORM.FIELDS.TERRITORY'),
                    disabled: true,
                  },
                },
              ],
            },
            {
              className: 'flex-5',
              fieldGroup: [
                {
                  key: 'usageDateLabel',
                  type: 'ice-input',
                  defaultValue: this.filterParams?.usageDate || '',
                  templateOptions: {
                    label: translate.instant('COUNTERCLAIMS.FORM.FIELDS.USAGE_DATE'),
                    placeholder: translate.instant('COUNTERCLAIMS.FORM.FIELDS.USAGE_DATE'),
                    disabled: true,
                  },
                },
              ],
            },
            {
              className: 'flex-5',
              fieldGroup: [
                {
                  key: 'prRightsLabel',
                  type: 'ice-input',
                  defaultValue: this.filterParams?.prRight || 'ALL PR Rights',
                  templateOptions: {
                    label: translate.instant('COUNTERCLAIMS.FORM.FIELDS.PR_RIGHTS'),
                    placeholder: translate.instant('COUNTERCLAIMS.FORM.FIELDS.PR_RIGHTS'),
                    disabled: true,
                  },
                },
              ],
            },
            {
              className: 'flex-5',
              fieldGroup: [
                {
                  key: 'mrRightsLabel',
                  type: 'ice-input',
                  defaultValue: this.filterParams?.mrRight || 'ALL MR Rights',
                  templateOptions: {
                    label: translate.instant('COUNTERCLAIMS.FORM.FIELDS.MR_RIGHTS'),
                    placeholder: translate.instant('COUNTERCLAIMS.FORM.FIELDS.MR_RIGHTS'),
                    disabled: true,
                  },
                },
              ],
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'parties',
              type: 'datatable-editable',
              templateOptions: {
                config: {
                  data: combineLatest([this.store.select(fromRoot.getAllClaims), this.store.select(fromForm.getClaimantGroups)]).pipe(
                    map(([claims, claimGroups]) => [ClaimGraphUtils.filterClaims(claims, this.filterParams), claimGroups]),
                    map(([claims, claimGroups]) => [WorkClaimsUtils.claimsWithSocietyCodes({ claims, claimsFilter: this.filterParams }), claimGroups]),
                    map(([claims, claimGroups]) => ClaimantUtils.setClaimsResolvers(claims, claimGroups, translate)),
                    share(),
                  ),
                  selectionType: SelectionType.checkbox,
                  dontStrip: true,
                  customClass: 'claim-graph ice-fixed-header datatable-checkbox-disabled',
                  hideEmpty: true,
                  fixedHeight: '400px',
                  searchColumns: ['refLabel', 'name', 'ipiBaseNumber', 'agreementId'],
                  schema: this.schema.getDataTableCounterClaimCreation(row => this.resolversDialog.openDialog(row)),
                  visibleColumns: of([
                    'role',
                    'name',
                    'refLabel',
                    'societyCodePr',
                    'societyCodeMr',
                    'startDate',
                    'endDate',
                    'territoriesTisa',
                    'allRights',
                    'status',
                    'inDispute',
                    'pr',
                    'mr',
                    'alert',
                    'resolverCheck',
                    'claimantStatusLabel',
                  ]),
                },
              },
              hooks: {
                onInit: field => {
                  const control = field.formControl;
                  this.claimantsSubscription = combineLatest([this.store.select(fromRoot.getAllClaims), this.store.select(fromForm.getClaimantGroups)])
                    .pipe(
                      map(([claims, claimGroups]) => ClaimantUtils.getClaimStatus(claims, claimGroups, false)),
                      map(claimants => claimants.filter(claimant => !!claimant.claimantStatus)),
                    )
                    .subscribe(data => {
                      if (data && data.length) {
                        control.markAsDirty();
                      }
                      control.setValue(data);
                    });
                },
                onDestroy: () => {
                  if (this.claimantsSubscription) {
                    this.claimantsSubscription.unsubscribe();
                  }
                  this.store.dispatch(new fromForm.SetClaimantGroups(null));
                },
              },
            },
          ],
        },
      ],
    };
  }
}
