import { Injectable } from '@angular/core';
import { capitalize, cloneDeep, fromPairs, get, isArray, isObject, mapKeys, mapValues, sortBy, toPairs } from 'lodash';
import { ExportMode } from 'models/copyright/search/export-mode';
import * as XLSXlib from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const CSV_TYPE = 'application/octet-stream;charset=UTF-8';
export enum FileType {
  CSV = 'csv',
  XLSX = 'xlsx',
}

@Injectable()
export class ExportService {
  /**
   * Cleans and restructures the provided JSON data.
   *
   * @param json - The JSON data to be cleaned.
   * @param exportColumns - An array of column names to be included in the cleaned JSON.
   * @param thisIdAsFirstElement - The key that should be the first element in the cleaned JSON.
   * @param columnLabelDictionary - An object mapping original column names to new column names.
   *
   * @returns - Returns an array of cleaned JSON objects. The keys of these objects are either the original column names,
   *            or their corresponding values in columnLabelDictionary if provided. The values are the original values
   *            with special characters replaced. If thisIdAsFirstElement is provided, it will be the first key in each object.
   */
  private cleanJson({
    json,
    exportColumns = [],
    thisIdAsFirstElement,
    columnLabelDictionary,
  }: {
    json: any[];
    exportColumns: string[];
    thisIdAsFirstElement: string;
    columnLabelDictionary: { [id: string]: string };
  }) {
    return (json || []).map(data => {
      const initialDataOrdered = sortBy(
        toPairs(data).filter(([key, value]) => (exportColumns.map(prop => (prop || '').split('.')[0]) || []).includes(key) && key !== thisIdAsFirstElement),
        ([key, value]) => (exportColumns.map(prop => (prop || '').split('.')[0]) || []).indexOf(key),
      );
      const dataOrdered = fromPairs(thisIdAsFirstElement ? [[`${thisIdAsFirstElement}`, data?.[`${thisIdAsFirstElement}`]], ...initialDataOrdered] : (initialDataOrdered as any));
      return mapKeys(
        mapValues(dataOrdered, (val, key) => {
          const deepKey = exportColumns.find(prop => (prop || '').split('.')[0] === key);
          if (deepKey) {
            val = get(dataOrdered, `${deepKey}`, '');
          }
          return this.replaceSpecialChars(((val || val === 0) && ((isObject(val) && JSON.stringify(val)) || `${val}`)) || '');
        }),
        (value, key) => this.replaceSpecialChars(columnLabelDictionary?.[key] || capitalize(key)),
      );
    });
  }

  /**
   * Exports data in the specified format.
   *
   * @param fileName - The name of the file to be exported. Timestamp will be appended to the name.
   * @param exportMode - The mode of export which includes details like the batch of data to be downloaded,
   *                     the type of file, the columns to be visible, the dictionary of column labels, etc.
   *
   * @returns
   */
  public exportAs(fileName: string, exportMode: ExportMode): void {
    // Destructure the exportMode object to get the necessary details
    const { downloadBatch, fileType, exportColumns, thisIdAsFirstElement, columnLabelDictionary } = exportMode;

    // Clone the downloadBatch if it's an array, else clone its items
    const json = isArray(downloadBatch) ? cloneDeep(downloadBatch) : cloneDeep(downloadBatch.items);

    // Clean the json data based on the visible columns, the first element, and the column labels
    const dataCleaned = this.cleanJson({ json, exportColumns, thisIdAsFirstElement, columnLabelDictionary });

    // Create a new workbook
    this.writeData({ data: dataCleaned, fileName: `${fileName}_${new Date().getTime()}`, fileType });
  }

  /**
   * Writes data to a file using XLSXlib.
   *
   * @param data - The data to be written to the file.
   * @param fileName - The name of the file.
   * @param fileType - The type of the file to be used as extension. Defaults to CSV.
   */
  public writeData({ data, fileName, fileType = FileType.CSV }: { data: object[]; fileName: string; fileType?: FileType }) {
    // Create a new workbook
    const wb = XLSXlib.utils.book_new();

    // Convert the data to a worksheet
    const ws: XLSXlib.WorkSheet = XLSXlib.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSXlib.utils.book_append_sheet(wb, ws, fileName.slice(0, 31)); // XLSXlib Limits the sheet name to 31 characters

    // Write the workbook to a file with the specified name and type, and the current timestamp
    XLSXlib.writeFile(wb, `${fileName}.${fileType}`);
  }

  replaceSpecialChars(value: String): String {
    return value
      .replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' ')
      .replace(/\s\s+/g, ' ')
      .replace(/(<([^>]+)>)/gi, '');
  }
}
