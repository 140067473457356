<div class="overWidget" *ngIf="showLoader && !schemaIsResolved">
  <div class="spinner-container">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
</div>

<h4 *ngIf="headerTitle" [ngClass]="headerClass">{{ headerTitle }}</h4>

<div class="table-header" *ngIf="!totals?.hideHeaderTotals">
  <div class="table-actions" *ngIf="sortReset">
    <button mat-flat-button class="ice-accent ice-text-button sort-reset" *ngIf="sortReset" [disabled]="disabledSort | async" (click)="onResetSort()">
      {{ 'RESET_SORT' | translate }} <mat-icon>restart_alt</mat-icon>
    </button>
  </div>
  <div class="table-action-button" *ngIf="tableActionButton">
    <button mat-flat-button class="ice-accent ice-text-button {{ tableActionButton.class }}" (click)="tableActionButton.onClick()">
      <mat-icon>{{ tableActionButton.icon }}</mat-icon> {{ tableActionButton.text }}
    </button>
  </div>
  <ice-data-table-totals *ngIf="totals" [config]="headerTotals"></ice-data-table-totals>
</div>

<ngx-datatable
  data-testid="ice-data-table"
  #ngxDatatable
  (resized)="onResized($event)"
  *ngIf="schemaIsResolved && (!hideEmpty || data?.length)"
  class="material"
  [ngClass]="getDatatableClasses()"
  [rows]="getRows() | async"
  [selected]="selected"
  [rowIdentity]="rowIdentity"
  [loadingIndicator]="loadingIndicator"
  [columnMode]="_columnMode"
  [expandable]="expandRowsEnabled"
  [expandableProperty]="expandableProperty && (expandableProperty | async)"
  [headerHeight]="headerHeight"
  [footerHeight]="footerHeight"
  [rowHeight]="rowHeight > 0 ? rowHeight : null"
  [reorderable]="reorderable"
  [columns]="_schema"
  [sorts]="sorts"
  [displayCheck]="displayCheck"
  [displayRadio]="displayRadio"
  (filter)="onFilter($event)"
  (sort)="onSort($event)"
  (reorder)="reorder($event)"
  [virtualization]="virtualization"
  [count]="data ? data.length : 0"
  [selectionType]="selectionType"
  (select)="onSelect($event)"
  [scrollbarV]="rowHeight !== 0"
  [scrollbarH]="scrollbarH"
  [messages]="getMessage()"
  [ngStyle]="{ 'min-height.px': (height | async) || 0, height: fixedHeight || 'auto', 'max-height.px': maxHeight, overflow: 'auto' }"
  [groupRowsBy]="groupRowsBy"
  [groupExpansionDefault]="groupExpansionDefault"
  [selectAllRowsOnPage]="false"
  [rowClass]="getRowClass"
  (activate)="onActivate($event)"
  (scroll)="onScroll($event)"
  [rowFixedDetails]="rowFixedDetails"
>
  <ngx-datatable-row-detail [rowHeight]="'auto'" (toggle)="onDetailToggle($event)" data-testid="ice-data-table-row">
    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
      <ice-data-table
        data-testid="ice-data-table-row-detail"
        *ngIf="expandRowsEnabled && !addDetailsOnMainDataTable && getExpandableData(row) | async; let datatable"
        [data]="datatable"
        [sorts]="detailSorts || sorts"
        [dontStrip]="dontStrip"
        class="material detail-table"
        [sliceLimit]="datatable.sliceLimit"
        [schema]="_expandableSchema"
        [visibleColumns]="detailVisibleColumns || visibleColumns"
        [rowHeight]="40"
        [virtualization]="datatable.length > 8"
        [fixedHeight]="getExpandableDataHeight(datatable, row, false)"
        [height]="getExpandableDataHeight(datatable, row, true)"
        [shadowed]="shadowed"
        [columnMode]="columnMode"
        [headerHeight]="expandableNoHeader ? 0 : datatable.length ? (getExpandableHeaderHeight() | async) : 0"
        [headerTitle]="datatable.headerTitle"
        [headerClass]="datatable.headerClass"
        (activateDetailEmit)="onActivateDetail($event)"
        (selectedRowEmit)="onSelectExpandable({ parent: row, child: $event })"
      ></ice-data-table>
    </ng-template>
  </ngx-datatable-row-detail>

  <ngx-datatable-footer *ngIf="footerOptions">
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
      <ice-data-table-footer-content [footerOptions]="footerOptions"></ice-data-table-footer-content>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<ice-data-table-totals *ngIf="totals" [config]="totals"></ice-data-table-totals>

<ng-template #noTooltipCellTemplate let-row="row" let-value="value" let-i="index">
  <span class="ice-data-table-row" [innerHTML]="value"></span>
</ng-template>

<ng-template #customButtonCellTemplate let-row="row" let-value="value" let-column="column">
  <button
    mat-flat-button
    [ngClass]="customButtonsConfig[value].buttonClass || 'ice-accent'"
    [matTooltip]="customButtonsConfig[value].hasTooltip ? customButtonsConfig[value].tooltip || value : null"
    [matTooltipClass]="'ice-tooltip'"
    [matTooltipPosition]="'above'"
    (mouseover)="customButtonsHover[value] = true"
    (mouseleave)="customButtonsHover[value] = false"
    (click)="copyToClipboard($event, value)"
    *ngIf="showCustomButton(row, column, value)"
  >
    {{ customButtonsHover[value] ? customButtonsConfig[value].hoverText : customButtonsConfig[value].text || value }}
  </button>
</ng-template>

<ng-template #iconRightOfTextCellTemplate let-row="row" let-value="value" let-column="column">
  <div class="ice-data-table-row" [matTooltip]="row[column.tooltip]" [matTooltipClass]="'ice-tooltip'" [matTooltipPosition]="'above'">
    <span *ngIf="value" fxLayoutAlign="start center"
      >{{ value
      }}<mat-icon *ngIf="row[column.iconRightOfText.icon]?.icon" class="{{ row[column.iconRightOfText.icon]?.class }}">{{ row[column.iconRightOfText.icon]?.icon }}</mat-icon></span
    >
  </div>
</ng-template>
