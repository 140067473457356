export const locale = {
  lang: 'en',
  data: {
    HOT_KEYS: {
      SIDE_MENU: 'Toggle the Side Menu.',
      ADV_SEARCH: 'Toggle the Advanced Search Bar.',
      SET_ALL_COUNTRIES: 'In a territories field, set All Countries.',
      SET_BELGIUM: 'In a territories field, set Belgium.',
      SET_DENMARK: 'In a territories field, set Denmark.',
      SET_FINLAND: 'In a territories field, set Finland.',
      SET_GERMANY: 'In a territories field, set Germany.',
      SET_NETHERLANDS: 'In a territories field, set Netherlands.',
      SET_NORWAY: 'In a territories field, set Norway.',
      SET_SWEDEN: 'In a territories field, set Sweden.',
      SET_UNITED_KINGDOM: 'In a territories field, set United Kingdom.',
    },
  },
};
