import {
  AgreementShares,
  WorkShare,
  CopyrightOwnershipTotals,
  IpCleaned,
  IpUtils,
  filterClaimsLocally,
  ContributorsUtils,
  CopyrightUtils,
  DateTimeUtils,
  RelationsUtils,
  TerritoryUtils,
  WorkClaimsUtils,
  WorkUtils,
  WorkDetail,
  SharePictureFullClaim,
  isSubPublisherRole,
} from '@ice';
import { NodeRole } from 'config/constants/claim-node.constants';
import { PartyRelationType, mrRights, prRights } from 'config/constants/ips.constants';
import { AUTHOR_ROLES, CREATOR_ROLES_LIST, HASH_SEPARATOR, PUBLISHER_ROLES_LIST, SHARES_PROPERTIES, TYPE_PUBLISHER } from 'config/constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { CREATOR, INCOME_PARTICIPANT_JSON, INCOME_PARTICIPANT_VALUE, PUBLISHER, SUB_PUBLISHER } from 'config/constants/works.constants';
import { UnKnowPublisherIPIKey, UnknownAuthorIPIKey } from 'config/stepper-builders/claim/new-claim-init';
import {
  clone,
  cloneDeep,
  concat,
  difference,
  differenceBy,
  filter,
  find,
  findKey,
  flatMap,
  flattenDeep,
  get,
  groupBy,
  has,
  includes,
  intersection,
  isEqual,
  isNaN,
  isNumber,
  last,
  map,
  remove,
  sortBy,
  split,
  sum,
  sumBy,
  toNumber,
  trimStart,
  uniq,
  values,
} from 'lodash';
import { IconInterface } from 'models/copyright/detail/icon';
import moment from 'moment';
import { ClaimRow } from 'models/copyright/detail/claims';
import { SectionSendItemCleaner } from '../maps/send-update-map';

interface SharesSummary {
  claim: {
    mr: number;
    pr: number;
  };
  ownership: {
    mr: number;
    pr: number;
  };
}
interface ClaimHierarchyHelper {
  [x: string]: {
    newClaimId: string;
    nchilds: number;
  };
}

export class ClaimsUtils {
  static getClaimsGroupedByPath(claims, claimIdMap?) {
    const parentMap = new Map();
    (claims || []).forEach(claim => {
      const id = claim.claimId;
      const parentId = claim.parentId;
      parentMap.set(id, parentId);
    });
    return values(
      groupBy(
        claims.map(claim => {
          const id = claim.claimId;
          const path = this.getClaimPath([id], id, parentMap, claimIdMap);
          return { ...claim, pathString: path.join('|'), path };
        }),
        'pathString',
      ),
    );
  }

  static getClaimPath(path, claimId, claimParentMap, claimIdMap?) {
    return this.pathBuilder(path, claimId, claimParentMap, false, claimIdMap);
  }

  static pathBuilder(path, pathId, pathMap, append, pathIdMap?) {
    const nextId = pathMap.get(pathId);
    if (nextId) {
      return this.getClaimPath(append ? [...path, nextId] : [nextId, ...path], nextId, pathMap, pathIdMap);
    }
    return pathIdMap ? path.map(id => pathIdMap.get(id)) : path;
  }

  static getAllClaimsByPathSorted(claimsByPath: any[]) {
    const rootClaims = [];
    const claimsByParentId = new Map();
    claimsByPath.forEach(claim => {
      if (claim.parentId) {
        if (claimsByParentId.has(claim.parentId)) {
          claimsByParentId.get(claim.parentId).push(claim);
        } else {
          claimsByParentId.set(claim.parentId, [claim]);
        }
      } else {
        rootClaims.push(claim);
      }
    });
    const sortedParentClaims = sortBy(rootClaims, claim => {
      // only creators and publishers are parents
      const isPublisher = claim.role === 'E';
      if (isPublisher) {
        return -1;
      }
      if (claim.hasChildren) {
        return -1;
      }
      return 1;
    });
    // fine grained sorting by territories, right number, name, role, start date, end date
    sortedParentClaims.sort((claimA, claimB) => {
      /**
       * We want to keep the sorting for same role and root claims with no children
       * Which was the goal of the previous sorting
       */
      if (claimA.roleRaw !== claimB.roleRaw || !claimB.hasChildren) {
        return 0;
      }
      const pathA = claimA.path;
      const pathB = claimB.path;
      const pathSort = this.pathSorter(pathA, pathB);
      return pathSort;
    });
    const sortedClaims = [];
    const recursivelyAddChildren = claim => {
      sortedClaims.push(claim);
      if (claim.hasChildren) {
        const children = flatMap<any>(claim.claimId.map(id => claimsByParentId.get(id) || []));
        // fine grained sorting by territories, right number, name, role, start date, end date
        children.sort((claimA, claimB) => {
          const pathA = claimA.path;
          const pathB = claimB.path;
          const pathSort = this.pathSorter(pathA, pathB);
          return pathSort;
        });
        children.forEach(recursivelyAddChildren);
      }
    };

    sortedParentClaims.forEach(recursivelyAddChildren);
    // remove unneeded fields
    return sortedClaims.map(claim => {
      const { rawCountries, pathString, ...cleanedClaim } = claim;
      return cleanedClaim;
    });
  }

  static pathSorter(a, b) {
    const l = Math.max(a.length, b.length);
    for (let i = 0; i < l; i++) {
      if (!(i in a)) {
        return -1;
      }
      if (!(i in b)) {
        return +1;
      }
      const [territoriesA, rightNumberA, nameA, roleA, startDateA, endDateA] = a[i].split(HASH_SEPARATOR);
      const [territoriesB, rightNumberB, nameB, roleB, startDateB, endDateB] = b[i].split(HASH_SEPARATOR);
      if (nameA !== nameB) {
        return nameA > nameB ? 1 : -1;
      }
      if (territoriesA !== territoriesB) {
        const territoriesCountA = (territoriesA.split(',') || []).length;
        const territoriesCountB = (territoriesB.split(',') || []).length;
        if (territoriesCountA !== territoriesCountB) {
          return territoriesCountA < territoriesCountB ? 1 : -1;
        }
        return territoriesA > territoriesB ? 1 : -1;
      }
      if (rightNumberA !== rightNumberB) {
        return parseFloat(rightNumberA) < parseFloat(rightNumberB) ? 1 : -1;
      }
      if (startDateA !== startDateB) {
        return startDateA > startDateB ? 1 : -1;
      }
      if (endDateA !== endDateB) {
        return endDateA > endDateB ? 1 : -1;
      }
      if (roleA !== roleB) {
        return roleA > roleB ? 1 : -1;
      }
    }
    return 0;
  }

  static formatOwnershipTableItem(item: any): any {
    if (item && !item.mr) {
      item.mr = '0 %';
      item.mrTooltip = '0 %';
    }
    if (item && !item.pr) {
      item.pr = '0 %';
      item.prTooltip = '0 %';
    }
    return item;
  }

  static calculateCopyrightOwnershipTotals(ownershipTableItems: any, prParser: (item) => number | string, mrParser: (item) => number | string): CopyrightOwnershipTotals {
    if (!ownershipTableItems || !ownershipTableItems.length) {
      return null;
    }
    const writerSubTotalPRValues = [];
    const writerSubTotalMRValues = [];
    const publisherSubTotalPRValues = [];
    const publisherSubTotalMRValues = [];
    const specialSubTotalPRValues = [];
    const specialSubTotalMRValues = [];

    ownershipTableItems.forEach(item => {
      const itemFormatted = this.formatOwnershipTableItem(cloneDeep(item));
      const pr = prParser(itemFormatted);
      const mr = mrParser(itemFormatted);
      if (isNumber(mr) && isNumber(pr)) {
        if (
          itemFormatted &&
          itemFormatted.role &&
          itemFormatted.role.length &&
          itemFormatted.role.length > 0 &&
          includes(AUTHOR_ROLES, itemFormatted.role.replace(/\&nbsp;/g, ''))
        ) {
          writerSubTotalPRValues.push(pr);
          writerSubTotalMRValues.push(mr);
          publisherSubTotalPRValues.push(0);
          publisherSubTotalMRValues.push(0);
          specialSubTotalPRValues.push(0);
          specialSubTotalMRValues.push(0);
        } else if (
          itemFormatted &&
          itemFormatted.role &&
          itemFormatted.role.length &&
          itemFormatted.role.length > 0 &&
          includes(['Special'], itemFormatted.role.replace(/\&nbsp;/g, ''))
        ) {
          specialSubTotalPRValues.push(pr);
          specialSubTotalMRValues.push(mr);
          publisherSubTotalPRValues.push(0);
          publisherSubTotalMRValues.push(0);
          writerSubTotalPRValues.push(0);
          writerSubTotalMRValues.push(0);
        } else {
          publisherSubTotalPRValues.push(pr);
          publisherSubTotalMRValues.push(mr);
          writerSubTotalPRValues.push(0);
          writerSubTotalMRValues.push(0);
          specialSubTotalPRValues.push(0);
          specialSubTotalMRValues.push(0);
        }
      }
    });

    const totalPR =
      concat(writerSubTotalPRValues, publisherSubTotalPRValues, specialSubTotalPRValues).length > 0
        ? sum(concat(writerSubTotalPRValues, publisherSubTotalPRValues, specialSubTotalPRValues)).toFixed(2)
        : '-';
    const totalMR =
      concat(writerSubTotalMRValues, publisherSubTotalMRValues, specialSubTotalMRValues).length > 0
        ? sum(concat(writerSubTotalMRValues, publisherSubTotalMRValues, specialSubTotalMRValues)).toFixed(2)
        : '-';
    const writerSubTotalMR = writerSubTotalMRValues.length > 0 ? sum(writerSubTotalMRValues).toFixed(2) : '-';
    const writerSubTotalPR = writerSubTotalPRValues.length > 0 ? sum(writerSubTotalPRValues).toFixed(2) : '-';
    const publisherSubTotalMR = publisherSubTotalMRValues.length > 0 ? sum(publisherSubTotalMRValues).toFixed(2) : '-';
    const publisherSubTotalPR = publisherSubTotalPRValues.length > 0 ? sum(publisherSubTotalPRValues).toFixed(2) : '-';
    const specialSubTotalMR = specialSubTotalMRValues.length > 0 ? sum(specialSubTotalMRValues).toFixed(2) : '-';
    const specialSubTotalPR = specialSubTotalPRValues.length > 0 ? sum(specialSubTotalPRValues).toFixed(2) : '-';
    return { totalPR, totalMR, publisherSubTotalMR, publisherSubTotalPR, writerSubTotalMR, writerSubTotalPR, specialSubTotalMR, specialSubTotalPR };
  }

  static getClaimStatus(rawStatus, translate) {
    return {
      status: rawStatus.length > 1 ? '' : (translate && translate.instant(`WORKS.ALL_CLAIMS.${rawStatus[0]}`)) || '',
      statusIcons: rawStatus.map(status => this.getClaimStatusIcon(status, translate)),
    };
  }

  static getClaimStatusIcon(status, translate): IconInterface {
    return {
      icon: 'fiber_manual_record',
      class: status ? this.getClaimStatusIconClass(status) + ' ice-dot-icon' : '',
      text: status ? (translate && translate.instant(status)) || '' : '',
    };
  }

  static getClaimStatusIconClass(status) {
    switch (status && status.toUpperCase()) {
      case 'ASSUMED':
      case 'DERIVED':
        return 'ice-gray';
      case 'SUBMITTED':
        return 'ice-blue';
      case 'UNAUTHORIZED':
        return 'ice-red';
      case 'VALIDADED':
      case 'VALIDATED':
        return 'ice-green';
    }
  }

  static trimRightShares(shares) {
    shares.rightTypes = shares.rightTypes.map(right => right.trim());
    shares.startDate = DateTimeUtils.getSimpleDateFormat(shares.startDate);
    shares.endDate = DateTimeUtils.getSimpleDateFormat(shares.endDate);
    shares.postTermCollectionDate = DateTimeUtils.getSimpleDateFormat(shares.postTermCollectionDate);
    shares.priorRoyaltiesDate = DateTimeUtils.getSimpleDateFormat(shares.priorRoyaltiesDate);
  }

  // Limit the amount of rows to copy and apply changes
  static getRelatedSubPublishers(parentId, claims): ClaimRow[] {
    const subtreeRows: ClaimRow[] = [];
    this.getRelatedSubPublishersRecursively(parentId, claims, subtreeRows);
    return subtreeRows;
  }

  static getRelatedSubPublishersRecursively(parentId: string, claims: ClaimRow[], subtreeRows: ClaimRow[]): void {
    claims.forEach(claim => {
      if (claim.parentId === parentId) {
        const clonedClaim = cloneDeep(claim);
        subtreeRows.push(clonedClaim);
        this.getRelatedSubPublishersRecursively(claim.claimId, claims, subtreeRows);
      }
    });
  }

  static importClaimTreeRecursively(srcClaimId, claims: ClaimRow[], mapOldNew: ClaimHierarchyHelper, newNodes: ClaimRow[]) {
    claims.forEach(claim => {
      if (claim.parentId === srcClaimId) {
        const clonedClaim = cloneDeep(claim);
        const { newClaimId, nchilds } = mapOldNew[srcClaimId];
        clonedClaim.parentId = newClaimId;
        clonedClaim.claimId = `${newClaimId}_${nchilds}`;
        mapOldNew[srcClaimId].nchilds = nchilds + 1;
        newNodes.push(cloneDeep(clonedClaim));
        mapOldNew[claim.claimId] = { newClaimId: clonedClaim.claimId, nchilds: 1 };
        this.importClaimTreeRecursively(claim.claimId, claims, mapOldNew, newNodes);
      }
    });
  }
  static getClaimTree(srcClaimId, claims: ClaimRow[], mapOldNew: ClaimHierarchyHelper) {
    const newNodes: ClaimRow[] = [];
    this.importClaimTreeRecursively(srcClaimId, claims, mapOldNew, newNodes);
    return newNodes;
  }

  static checkIfThereAreClaimIdRepeated(claims) {
    const idMap = {};
    claims.map(claim => {
      if (idMap[claim.claimId]) {
        idMap[claim.claimId].push(claim);
      } else {
        idMap[claim.claimId] = [claim];
      }
    });
  }

  static updateLinkToIncomeParticipant(fieldsToClean, claims, editClaim) {
    const claimIndex = claims.findIndex(c => c.claimId === editClaim);
    if (claimIndex > -1) {
      const claimToUpdate = claims[claimIndex];
      const clonedClaimToUpdate = cloneDeep(claimToUpdate);
      if (clonedClaimToUpdate) {
        clonedClaimToUpdate['extensions'] = INCOME_PARTICIPANT_JSON;
      }

      fieldsToClean.claims = [...claims];
      fieldsToClean.claims[claimIndex] = clonedClaimToUpdate;
    }
    return SectionSendItemCleaner['works'](fieldsToClean, 'ICE');
  }

  /**
   * Calculate the work share split for the creator based on the total shares of all linked creators to a publisher claim.
   * It has different rules for MR (Mechanical Rights) or PR (Performing Rights).
   *
   * @param workShare - The Work Share object representing the work share from the claim.
   * @param creator - The identifier of the creator.
   * @param creatorsSharesTotal - The total shares summary for all creators.
   * @param claimByCreator - A map of claims by creator.
   * @param isOwnerShipClaim - Indicates if it's an ownership claim. It's needed to identify the share of the creator to be calculated.
   * @return The calculated share for the creator.
   */
  static getCreatorWorkShareSplit({
    creatorsSharesTotal,
    workShare,
    creator,
    claimByCreator,
    isOwnerShipClaim,
  }: {
    workShare: WorkShare;
    creator: string;
    creatorsSharesTotal: SharesSummary;
    claimByCreator: Map<string, any>;
    isOwnerShipClaim: boolean;
  }) {
    const creatorClaim = claimByCreator.get(creator);
    if (!creatorClaim) {
      throw new Error('Creator claim not found');
    }
    const { share, rightTypes } = workShare;
    const hasPrRightTypes = WorkClaimsUtils.hasPerformingRights(rightTypes);
    const {
      claimShares: [creatorClaimMR, creatorClaimPR],
      ownershipShares: [creatorOwnershipMR, creatorOwnershipPR],
    } = creatorClaim;

    const creatorMR = isOwnerShipClaim ? creatorOwnershipMR : creatorClaimMR;
    const creatorPR = isOwnerShipClaim ? creatorOwnershipPR : creatorClaimPR;
    let creatorShare = 0;
    if (!hasPrRightTypes) {
      // <-- Calculate the MR share for the creator -->
      // Calculate share
      if (share === 0) {
        creatorShare = 0;
      } else if (creatorsSharesTotal.claim.mr === 0) {
        // When the claim includes the PR share, the MR share can be calculated based on the PR share
        if (creatorsSharesTotal.claim.pr > 0 && creatorPR?.rightTypes.includes('PR')) {
          creatorShare = ((creatorPR?.share || 0) / creatorsSharesTotal.claim.pr) * share;
        } else {
          // otherwise set an even split
          creatorShare = share / claimByCreator.size;
        }
      } else {
        // by default, set a split based on the ratio of each creator's share to the total MR shares
        creatorShare = ((creatorMR?.share || 0) / creatorsSharesTotal.claim.mr) * share;
      }
      // `pr` and `mr` values are integers representing a percentage without comma.
      // E.g. 1000 represents 10.00%. and 6250 represents 62.50%
      // ICE only allows for two decimals, so we round the values to the nearest integer
      return Math.round(creatorShare);
    }
    // <-- Calculate the PR share for the creator -->

    creatorShare = 0;
    // Set the claims (Collection shares)
    if (share === 0) {
      creatorShare = 0;
    } else if (creatorsSharesTotal.claim.pr === 0) {
      //  set an even split
      creatorShare = share / claimByCreator.size;
    } else {
      // by default, set a split based on the ratio of each creator's share to the total PR shares
      creatorShare = ((creatorPR?.share || 0) / creatorsSharesTotal.claim.pr) * share;
    }
    return Math.round(creatorShare);
  }

  /**
   * @description This method binds one publisher claim and his subPublishers with one or multiple creators
   * @param fieldsToClean is a work detail
   * @param linkCreator list of selected creators in the dropdown of stepper
   *                    ['WALGER, GOERAN - 140021250 - C', 'GROSS, - 0f1b72e394051b6a9eb545f421a397d4 - C', 'DEHM, DIETHER - 76200300 - C']
   * @param claims current list of claims from work detail
   * @param editClaim claimId of claim with missing parentId (missing link to one or more than one creator)
   *
   */
  static updateLinkCreatorInClaim({
    fieldsToClean,
    linkCreator,
    claims,
    editClaim,
    role,
  }: {
    fieldsToClean: any;
    linkCreator: string[];
    claims: ClaimRow[];
    editClaim: string;
    role: string;
  }) {
    // Prepare data
    const clonedClaims = cloneDeep(claims);
    const clonedFieldsToClean = cloneDeep(fieldsToClean);
    const groupedClaims = this.getPublishersFromClaims(clonedClaims);
    const { publishers } = groupedClaims;
    if (linkCreator.length) {
      const [firstCreatorId] = linkCreator;
      let newParentIdClaim;
      if (role === 'SE') {
        newParentIdClaim = this.findParentClaim(firstCreatorId, clonedClaims, editClaim);
      } else {
        newParentIdClaim = this.findClaimByIPI(firstCreatorId, clonedClaims);
      }
      const claimToUpdate = clonedClaims.find(c => c.claimId === editClaim);
      if (newParentIdClaim) {
        // When having multiple creators to be linked to a (sub?) publisher, the shares of that publisher need to be
        // split across the different creators with the weight of each creator share.
        // Example:
        // - Initial data:
        //      Claim: Creator1: 20%, Creator2: 30%, Creator3: 10% ; Publisher1: 40%
        // - Final data:
        //      Claim: Publisher1-Creator1: 13,33%, Publisher1-Creator2: 20%, Publisher1-Creator3: 6,67%
        // As you see in the example, Creator1 has 20% of the 60% of the total shares all creators sum. 20 out of 60 is a 33% share.
        // A 33% of 40% is 13,33% for Publisher1-Creator1.

        // Prepare data: find the total shares sum from all creators and claim types (collection and ownership)
        const creatorsSharesTotal = {
          claim: {
            mr: 0,
            pr: 0,
          },
          ownership: {
            mr: 0,
            pr: 0,
          },
        };
        const claimByCreator = new Map<string, any>();

        linkCreator.forEach(creator => {
          const claim = this.findClaimByIPI(creator, clonedClaims);
          claimByCreator.set(creator, claim);
          // when creating an income participant, `claim` can be undefined
          if (claim) {
            creatorsSharesTotal.claim.mr += claim.claimShares[0]?.share || 0;
            creatorsSharesTotal.claim.pr += claim.claimShares[1]?.share || 0;
            creatorsSharesTotal.ownership.mr += claim.ownershipShares[0]?.share || 0;
            creatorsSharesTotal.ownership.pr += claim.ownershipShares[1]?.share || 0;
          }
        });

        const relatedSubPublishers = this.getRelatedSubPublishers(editClaim, clonedClaims);
        const newRows = [];
        // as the relatedSubPublishers is a subset of the clonedClaims that will be updated on each creator, we can remove them
        remove<any>(clonedClaims, claim => relatedSubPublishers.find(subPublisherClaim => subPublisherClaim.claimId === claim.claimId));
        // remove the edited claim, as it will be updated
        remove<any>(clonedClaims, claim => claim.claimId === editClaim);

        linkCreator.map(creator => {
          // create the chain of claims for each creator
          const clonedClaimToUpdate = cloneDeep(claimToUpdate);
          const clonedSubtree = cloneDeep(relatedSubPublishers);

          // update the parentId with the original claimId
          const claimIdCreator = claimByCreator.get(creator).claimId;
          clonedClaimToUpdate.parentId = claimIdCreator;

          // update the new claim id
          const nextPublisherId = this.getNextPublisherId(claimIdCreator, publishers);
          clonedClaimToUpdate.claimId = this.formatIdType('P', claimIdCreator, nextPublisherId === 0 ? 1 : nextPublisherId);
          const mapOldNew: ClaimHierarchyHelper = { [editClaim]: { newClaimId: clonedClaimToUpdate.claimId, nchilds: 1 } };

          const subTreeRows = this.getClaimTree(editClaim, clonedSubtree, mapOldNew);
          //   update the claims to reflect the ratio of the new shares
          subTreeRows.concat(clonedClaimToUpdate).forEach(claimRow => {
            claimRow.claimShares = claimRow.claimShares.map(workShare => {
              const share = ClaimsUtils.getCreatorWorkShareSplit({
                creatorsSharesTotal,
                workShare,
                creator,
                claimByCreator,
                isOwnerShipClaim: false,
              });
              return { ...workShare, share };
            });

            claimRow.ownershipShares = claimRow.ownershipShares.map(workShare => {
              const share = ClaimsUtils.getCreatorWorkShareSplit({
                creatorsSharesTotal,
                workShare,
                creator,
                claimByCreator,
                isOwnerShipClaim: true,
              });
              return { ...workShare, share };
            });
            newRows.push(claimRow);
          });
        });
        clonedFieldsToClean.claims = [...clonedClaims, ...newRows];
      }
    }
    return SectionSendItemCleaner['works'](clonedFieldsToClean, 'ICE');
  }

  // TODO: search using name via partyName ¿?
  static getClaimIdFromCreatorOfLinkCreators(creator, claims) {
    const splitCreator = creator.split('-');
    const ipiCreator = splitCreator[1].trim();
    let claimCreator = null;
    // there are some creators without IPI, in this case claimCreator will be undefined
    if (ipiCreator) {
      claimCreator = this.findClaimByIPI(ipiCreator, claims);
    } else {
      const keyCreator = splitCreator[3].trim();
      claimCreator = this.findClaimByKey(keyCreator, claims);
    }

    return (claimCreator && claimCreator.claimId) || '';
  }

  static findClaimByIPI(ipi, claims) {
    return claims.find(c => {
      const parentRelations = get(c, 'claimant.partyName.relations');
      return parentRelations.find(rel => rel.otherId.includes(ipi));
    });
  }

  static findClaimsByIPI(ipi, claims) {
    return claims.filter(c => {
      const parentRelations = get(c, 'claimant.partyName.relations');
      return parentRelations.find(rel => rel.otherId.includes(ipi));
    });
  }

  static findClaimByKey(key, claims) {
    return claims.find(c => {
      const parentRelations = get(c, 'claimant.partyName.relations');
      return parentRelations.find(rel => rel.relation === PartyRelationType.DISPLAY_REFERENCE && rel.otherId.includes(key));
    });
  }

  static findClaimByXref(key, claims) {
    return claims.find(c => {
      const parentRelations = get(c, 'claimant.partyName.relations');
      return parentRelations.find(rel => rel.relation === PartyRelationType.CROSS_REFERENCE && rel.otherId.includes(key));
    });
  }

  static getClaimByClaimId(claims, claimId) {
    return claims.find(claim => (claim.claimId = claimId));
  }

  static findParentClaim(ipi, claims, editClaimId) {
    const claimsByIpi = this.findClaimsByIPI(ipi, claims);
    return claimsByIpi.find(claim => editClaimId.includes(claim.claimId));
  }

  /*
   * @returns {creators, publishers, subpublishers} object that contains grouped claims
   * IMPORTANT: This function needs to be reviewed to work like getPublishersFromClaims, where the key of container is a combination of claimIPI and claimId
   */
  static getGroupedClaims(claims) {
    const grouped = { creators: {}, publishers: {}, subpublishers: {} };
    claims.map(claim => {
      const { role, relations, claimId, parentId, type } = claim;
      const container = role === 'E' || type === 'ORIGINAL_PUBLISHER' ? grouped.publishers : role === 'SE' || type === 'SUB_PUBLISHER' ? grouped.subpublishers : grouped.creators;

      if (claim && claim.claimant && claim.claimant.partyName) {
        let claimIPI = get(claim, 'claimantId', '');
        if (claim.claimant.partyName.relations) {
          claimIPI = RelationsUtils.getICEFromRelations(claim.claimant.partyName.relations);
        }
        container[claimIPI] = cloneDeep(claim);
      }
    });
    return grouped;
  }

  static getPublishersFromClaims(claims) {
    const grouped = { creators: {}, publishers: {}, subpublishers: {} };
    claims.map(claim => {
      const { role, relations, claimId, parentId, type } = claim;
      const container = role === 'E' || type === 'ORIGINAL_PUBLISHER' ? grouped.publishers : role === 'SE' || type === 'SUB_PUBLISHER' ? grouped.subpublishers : grouped.creators;
      if (claim && claim.claimant && claim.claimant.partyName && claim.claimant.partyName.relations) {
        const claimIPI = RelationsUtils.getIPIFromRelations(claim.claimant.partyName.relations);
        container[`${claimIPI}${claimId}`] = cloneDeep(claim);
      }
    });
    return grouped;
  }

  static getNextCreatorID(creators): number {
    let maxId = 0;
    Object.keys(creators).forEach(key => {
      const creator = creators[key];
      maxId = this.getMaxIdFromClaim(creator, maxId);
    });
    return maxId > 0 ? maxId + 1 : 1;
  }

  static getNextPublisherId(creatorClaimId, publishers, isSubpublisher?): number {
    let maxId = 0;
    Object.keys(publishers).forEach(key => {
      const publisher = publishers[key];
      if (publisher.parentId === creatorClaimId || publisher.claimId.includes(creatorClaimId)) {
        maxId = this.getMaxIdFromClaim(publisher, maxId, isSubpublisher ? isSubpublisher : false);
      }
    });

    return maxId >= 0 ? maxId + 1 : 0;
  }

  static getMaxIdFromClaim(claim, maxId, isSubpublisher?): number {
    if (claim.claimId) {
      const splitClaimId = claim.claimId.split('_');
      if (splitClaimId.length > 1) {
        let idPublisher = splitClaimId[splitClaimId.length - 1];

        if (!isSubpublisher || idPublisher[0] === 'P') {
          idPublisher = idPublisher.substring(1, idPublisher.length);
        } else if (isSubpublisher || idPublisher[0] === 'SE') {
          idPublisher = idPublisher.substring(2, idPublisher.length);
        }
        // eslint-disable-next-line radix
        const nPublisher = parseInt(idPublisher);
        if (maxId < nPublisher) {
          return nPublisher;
        }
      }
    }
    return maxId;
  }

  static checkIfExistAndRoleIsOnClaim(claimantPartyId, role, claims, creatorIPI?): any {
    return claims.find(claim => {
      if (
        (creatorIPI === claim.parentId && claim.claimantPartyId === claimantPartyId && claim.role === role) ||
        (!creatorIPI && claim.claimantPartyId === claimantPartyId && claim.role === role)
      ) {
        return claim;
      }
    });
  }

  static generateClaim(
    claimId,
    ns,
    workId,
    role,
    type,
    claimantPartyId,
    claimantPartyNameId,
    startDate,
    endDate,
    shares,
    unauthorized,
    parentId?,
    agreementId?,
    incomeParticipant?,
    ClaimantInfo = null,
    datesFromShares = false,
    addOwnershipShares = true,
  ) {
    const partyName =
      (ClaimantInfo && { id: ClaimantInfo.id, attributes: ClaimantInfo.attributes, relations: ClaimantInfo.relations, societies: ClaimantInfo.parties[0].party.societies }) || null;

    const claim: ClaimRow = {
      claimId,
      ns,
      workId,
      role,
      type,
      claimantId: claimantPartyNameId && claimantPartyId ? `${claimantPartyNameId}_${claimantPartyId}` : null,
      claimantPartyId,
      claimantPartyNameId,
      claimShares: this.generateClaimShares(startDate, endDate, shares, unauthorized, false, datesFromShares),
      ownershipShares: addOwnershipShares ? this.generateClaimShares(startDate, endDate, shares, unauthorized, true, datesFromShares) : [],
      agreementId,
      tags: { originClaims: [claimId], updatedType: ['new'] },
      claimant: { partyName },
    };
    if (parentId && !incomeParticipant) {
      claim['parentId'] = parentId;
    }
    if (incomeParticipant) {
      claim['extensions'] = INCOME_PARTICIPANT_JSON;
    }
    return claim;
  }

  static generateClaimShares(claimStartDate, claimEndDate, shares, unauthorized, isOwnershipShare = false, datesFromShares = false) {
    return (shares || []).map(shareCol => {
      const { type, share, ownershipShare, territory, inclusion, startDate, endDate } = shareCol;
      let rightTypes = [];
      const shareStartDate = (datesFromShares && startDate) || claimStartDate;
      const shareEndDate = (datesFromShares && endDate) || claimEndDate;
      if (inclusion === 'ALL') {
        if (type.toLowerCase() === 'mechanical') {
          rightTypes = mrRights;
        } else {
          rightTypes = prRights;
        }
      } else {
        rightTypes = (inclusion && inclusion.split(',')) || [];
      }
      return {
        share: toNumber(isOwnershipShare ? ownershipShare : share) * 100 || 0,
        territories: territory
          .replace(/(?!^)\+/g, ',+')
          .replace(/(?!^)\-/g, ',-')
          .split(','),
        endDate: shareEndDate || '9999-12-31',
        unauthorized,
        rightTypes,
        startDate: shareStartDate || '1900-01-01',
        priorRoyaltiesDate: '1900-01-01',
        postTermCollectionDate: '9999-12-31',
      };
    });
  }

  static addCreatorToClaims(
    baseClaimId,
    claims,
    groupedClaims,
    claimNS,
    id,
    role,
    claimantPartyId,
    claimantPartyNameId,
    startDate,
    endDate,
    shares,
    unauthorized,
    ClaimantInfo = null,
    addOwnershipShares = true,
  ) {
    let nextCreatorId;
    let newClaimId;
    let newClaimShares;
    const foundClaim = this.checkIfExistAndRoleIsOnClaim(claimantPartyId, role, claims);
    if (foundClaim) {
      const claimIndex = claims.findIndex(claimItem => claimItem.claimId === foundClaim.claimId);
      newClaimShares = this.generateClaimShares(startDate, endDate, shares, unauthorized);
      newClaimShares.forEach(claimShare => {
        claims[claimIndex].claimShares.push(claimShare);
      });
      const newClaimOwnershipShares = this.generateClaimShares(startDate, endDate, shares, unauthorized, true);
      newClaimOwnershipShares.forEach(ownershipShare => {
        claims[claimIndex].ownershipShares.push(ownershipShare);
      });
    } else {
      nextCreatorId = this.getNextCreatorID(groupedClaims.creators);
      newClaimId = this.formatIdType('W', baseClaimId, nextCreatorId);
      claims.push(
        this.generateClaim(
          newClaimId,
          claimNS,
          id,
          role,
          CREATOR,
          claimantPartyId,
          claimantPartyNameId,
          startDate,
          endDate,
          shares,
          unauthorized,
          null,
          null,
          null,
          ClaimantInfo,
          addOwnershipShares,
        ),
      );
    }
  }

  /*
   * Push new claim foreach creator listed in link creators
   * Every creator can have some publishers like childs, getNextPublisherId search the max ID from the creator's publishers and increase in 1
   * @return claimId of new publisher
   */
  static addPublisherToClaims(
    claims,
    groupedClaims,
    linkCreator,
    agreementNumber,
    claimNS,
    id,
    role,
    claimantPartyId,
    claimantPartyNameId,
    startDate,
    endDate,
    shares,
    unauthorized,
    incomeParticipant?,
    baseClaimId?,
    ClaimantInfo = null,
    addOwnershipShares = true,
  ) {
    const { creators } = groupedClaims;
    let newPublisherId = null;
    let creatorClaimId = null;
    let nextPublisherId = null;
    const { publishers } = this.getPublishersFromClaims(claims);
    let foundClaim;
    let newClaimShares;
    if (linkCreator) {
      // prepare data for new publisher
      // Prepare data: find the total shares sum from all creators and claim types (collection and ownership)
      const creatorsSharesTotal = {
        claim: {
          mr: 0,
          pr: 0,
        },
        ownership: {
          mr: 0,
          pr: 0,
        },
      };

      const claimByCreator = new Map<string, any>();
      linkCreator.forEach(creator => {
        const claim = this.findClaimByIPI(creator, claims);
        claimByCreator.set(creator, claim);
        // when creating an income participant, `claim` can be undefined
        if (claim) {
          creatorsSharesTotal.claim.mr += claim.claimShares[0]?.share || 0;
          creatorsSharesTotal.claim.pr += claim.claimShares[1]?.share || 0;
          creatorsSharesTotal.ownership.mr += claim.ownershipShares[0]?.share || 0;
          creatorsSharesTotal.ownership.pr += claim.ownershipShares[1]?.share || 0;
        }
      });
      (linkCreator || []).map(creator => {
        if (incomeParticipant) {
          creatorClaimId = baseClaimId ? baseClaimId : null;
          foundClaim = this.checkIfExistAndRoleIsOnClaim(claimantPartyId, role, claims, creatorClaimId);
          if (foundClaim) {
            const claimIndex = claims.findIndex(claimItem => claimItem.claimId === foundClaim.claimId);
            newClaimShares = this.generateClaimShares(startDate, endDate, shares, unauthorized);
            newClaimShares.forEach(claimShare => {
              claims[claimIndex].claimShares.push(claimShare);
            });
            const newClaimOwnershipShares = this.generateClaimShares(startDate, endDate, shares, unauthorized, true);
            newClaimOwnershipShares.forEach(ownershipShare => {
              claims[claimIndex].ownershipShares.push(ownershipShare);
            });
          } else {
            nextPublisherId = this.getNextPublisherId(creatorClaimId, publishers);
            if (nextPublisherId === 0) {
              nextPublisherId = 1;
            }
            newPublisherId = this.formatIdType('P', creatorClaimId, nextPublisherId);

            const claim = this.generateClaim(
              newPublisherId,
              claimNS,
              id,
              role,
              PUBLISHER,
              claimantPartyId,
              claimantPartyNameId,
              startDate,
              endDate,
              shares,
              unauthorized,
              creatorClaimId,
              agreementNumber,
              incomeParticipant,
              ClaimantInfo,
              false,
              addOwnershipShares,
            );
            claims.push(claim);
          }
        } else if (creator) {
          let ipiCreator = CopyrightUtils.getKeySuffix(creator);
          creatorClaimId = get(creators, `[${ipiCreator}].claimId`, null);
          if (!creatorClaimId && ipiCreator) {
            ipiCreator = this.getTempCreatorClaimId(creators, ipiCreator);
            creatorClaimId = get(creators, `[${ipiCreator}].claimId`, null);
          }
          foundClaim = this.checkIfExistAndRoleIsOnClaim(claimantPartyId, role, claims, creatorClaimId);
          if (foundClaim) {
            const claimIndex = claims.findIndex(claimItem => claimItem.claimId === foundClaim.claimId);
            newClaimShares = this.generateClaimShares(startDate, endDate, shares, unauthorized);
            newClaimShares.forEach(claimShare => {
              claims[claimIndex].claimShares.push(claimShare);
            });
            const newClaimOwnershipShares = this.generateClaimShares(startDate, endDate, shares, unauthorized, true);
            newClaimOwnershipShares.forEach(ownershipShare => {
              claims[claimIndex].ownershipShares.push(ownershipShare);
            });
          } else {
            nextPublisherId = this.getNextPublisherId(creatorClaimId, publishers);
            newPublisherId = this.formatIdType('P', creatorClaimId, nextPublisherId);
            const claim = this.generateClaim(
              newPublisherId,
              claimNS,
              id,
              role,
              PUBLISHER,
              claimantPartyId,
              claimantPartyNameId,
              startDate,
              endDate,
              shares,
              unauthorized,
              creatorClaimId,
              agreementNumber,
              incomeParticipant,
              ClaimantInfo,
              false,
              addOwnershipShares,
            );

            claim.claimShares = claim.claimShares.map(claimShare => {
              const share = ClaimsUtils.getCreatorWorkShareSplit({
                creatorsSharesTotal,
                workShare: claimShare,
                creator,
                claimByCreator,
                isOwnerShipClaim: false,
              });
              return { ...claimShare, share };
            });
            claim.ownershipShares = claim.ownershipShares.map(ownershipShare => {
              const share = ClaimsUtils.getCreatorWorkShareSplit({
                creatorsSharesTotal,
                workShare: ownershipShare,
                creator,
                claimByCreator,
                isOwnerShipClaim: true,
              });
              return { ...ownershipShare, share };
            });
            claims.push(claim);
          }
        }
      });
    } else {
      // Income participant without parent
      nextPublisherId = this.getNextPublisherId(creatorClaimId, publishers);
      newPublisherId = this.formatIdType('P', '', nextPublisherId);
      const claim = this.generateClaim(
        newPublisherId,
        claimNS,
        id,
        role,
        PUBLISHER,
        claimantPartyId,
        claimantPartyNameId,
        startDate,
        endDate,
        shares,
        unauthorized,
        creatorClaimId,
        agreementNumber,
        incomeParticipant,
        ClaimantInfo,
        false,
        addOwnershipShares,
      );
      claims.push(claim);
    }
  }

  static addSubpublisherToClaims(
    claims,
    groupedClaims,
    publisherIpi,
    isPublisherFromWork,
    linkCreator,
    claimNS,
    id,
    role,
    claimantPartyId,
    claimantPartyNameId,
    startDate,
    endDate,
    shares,
    agreementNumber,
    unauthorized,
    incomeParticipant?,
    baseClaimId?,
    publisherIpiName?,
    PublisherParentId?,
    ClaimantInfo = null,
    claimId = null,
    parentId = null,
    addOwnershipShares = true,
    publisherPartyId = null,
    publisherPartynameId = null,
    publisherInfo = null,
  ) {
    const { creators } = groupedClaims;
    const { publishers, subpublishers } = this.getPublishersFromClaims(claims);
    /*
     * if the parent publisher belongs to this work
     *   link creator isn't needed
     *   step1. search this publisher to get the claimId
     *   step2. get all subpublisher of this publisher to obtain the next claimId
     */
    if (isPublisherFromWork) {
      const nextPublisherId = null;
      let nextSubPublisherId = null;
      nextSubPublisherId = this.getNextPublisherId(PublisherParentId, subpublishers, true);
      const newSubPublisherId = claimId || `${PublisherParentId}_SE${nextSubPublisherId}`;

      claims.push(
        this.generateClaim(
          newSubPublisherId,
          claimNS,
          id,
          role,
          SUB_PUBLISHER,
          claimantPartyId,
          claimantPartyNameId,
          startDate,
          endDate,
          shares,
          unauthorized,
          PublisherParentId,
          agreementNumber,
          incomeParticipant,
          ClaimantInfo,
          false,
          addOwnershipShares,
        ),
      );
    } else {
      /*
       * if the parent publisher is new for this work
       *   step1. add a publisher linked to selected work, same function that previous conditional
       *   step2. foreach linkcreator add this publisher and foreach of this publishers add the subpublisher
       */

      let ipiCreator = null;
      let creatorClaimId = null;
      let publisherId = null;
      let subPublisherId = null;
      const newPublisherId = null;
      let nextPublisherId = null;
      let nextSubPublisherId = null;
      const nextCreatorId = null;

      (linkCreator || []).map(creator => {
        if (incomeParticipant) {
          creatorClaimId = baseClaimId ? baseClaimId : null;
          nextPublisherId = this.getNextPublisherId(creatorClaimId, publishers);
          publisherId = this.formatIdType('P', creatorClaimId, nextPublisherId);
          nextSubPublisherId = this.getNextPublisherId(creatorClaimId, subpublishers, true);
          subPublisherId = `${publisherId}_SE${nextSubPublisherId}`;
        } else if (creator) {
          ipiCreator = CopyrightUtils.getKeySuffix(creator);
          creatorClaimId = get(creators, `[${ipiCreator}].claimId`, null);
          nextPublisherId = this.getNextPublisherId(creatorClaimId, publishers);
          publisherId = this.formatIdType('P', creatorClaimId, nextPublisherId);

          nextSubPublisherId = this.getNextPublisherId(creatorClaimId, subpublishers, true);
          subPublisherId = `${publisherId}_SE${nextSubPublisherId}`;
        }
        if (!claimId) {
          claims.push(
            this.generateClaim(
              publisherId,
              claimNS,
              id,
              'E',
              PUBLISHER,
              publisherPartyId,
              publisherPartynameId,
              startDate,
              endDate,
              shares,
              unauthorized,
              creatorClaimId,
              agreementNumber,
              incomeParticipant,
              publisherInfo,
              false,
              addOwnershipShares,
            ),
          );
        }

        claims.push(
          this.generateClaim(
            claimId || subPublisherId,
            claimNS,
            id,
            role,
            SUB_PUBLISHER,
            claimantPartyId,
            claimantPartyNameId,
            startDate,
            endDate,
            shares,
            unauthorized,
            parentId || publisherId,
            agreementNumber,
            null,
            ClaimantInfo,
            false,
            addOwnershipShares,
          ),
        );
      });
    }
  }

  static workRegisterFormatClaimsToSend(id: string, claimNS: string, newFields: any) {
    const { additionalClaims } = newFields;
    const claims = [];
    const sortedAdditionalClaims = sortBy([...additionalClaims] || [], ['label']);
    sortedAdditionalClaims
      .filter(additionalClaim => additionalClaim.label !== 'root')
      .forEach(additionalClaim => {
        const { label, parent, nodeRole } = additionalClaim;
        const {
          role,
          startDate,
          endDate,
          shares,
          agreementNumber,
          ClaimantPartyId,
          ClaimantPartyNameId,
          ClaimantIPNameNumber,
          ClaimantIPBaseNameNumber,
          PublisherIPI,
          ClaimantInfo,
        } = additionalClaim.model;

        const claimantPartyId = ClaimantIPBaseNameNumber || IpUtils.selectIpsKey(get(ClaimantInfo, 'parties[0].party.relations', []), ClaimantPartyId);
        const claimantPartyNameId = `ICE:${ClaimantIPNameNumber}`;
        const unauthorized = false;
        const claimId = label.replace('root-', '').split('-').join('_');
        const parentId = parent.replace('root-', '').split('-').join('_');
        if (CREATOR_ROLES_LIST.includes(role)) {
          claims.push(
            this.generateClaim(
              claimId,
              claimNS,
              id,
              role,
              CREATOR,
              claimantPartyId,
              claimantPartyNameId,
              startDate,
              endDate,
              shares,
              unauthorized,
              null,
              null,
              null,
              ClaimantInfo,
              true,
            ),
          );
        } else if (PUBLISHER_ROLES_LIST.includes(role)) {
          claims.push(
            this.generateClaim(
              claimId,
              claimNS,
              id,
              role,
              PUBLISHER,
              claimantPartyId,
              claimantPartyNameId,
              startDate,
              endDate,
              shares,
              unauthorized,
              parentId,
              agreementNumber,
              nodeRole === NodeRole.INCOME_PARTICIPANT,
              ClaimantInfo,
              true,
            ),
          );
        } else if (isSubPublisherRole(role)) {
          claims.push(
            this.generateClaim(
              claimId,
              claimNS,
              id,
              role,
              SUB_PUBLISHER,
              claimantPartyId,
              claimantPartyNameId,
              startDate,
              endDate,
              shares,
              unauthorized,
              parentId,
              agreementNumber,
              null,
              ClaimantInfo,
              true,
            ),
          );
        }
      });
    const formattedClaims = claims.map(claim => ({
      ...claim,
      claimId: trimStart((claim && claim.claimId) || '', '_'),
      parentId: claim && claim.parentId && trimStart((claim && claim.parentId) || '', '_'),
      tags: { ...(claim.tags || {}), originClaims: ((claim && claim.tags && claim.tags.originClaims) || []).map(value => `${trimStart(value || '', '_')}`) },
      ownershipShares: claim.ownershipShares && this.formatClaimShareToSend(claim.ownershipShares),
      claimShares: claim.claimShares && this.formatClaimShareToSend(claim.claimShares),
    }));

    return this.cleanShareEmptyDates(formattedClaims);
  }

  static joinClaimsToOwnership(ownershipRows, claims) {
    return ownershipRows.map(row => {
      const selectedClaim = claims.find(claim => claim.claimantId === `${row.claimantPartyNameId}_${row.claimantPartyId}`);
      const incomeParticipant =
        (selectedClaim &&
          selectedClaim.extensions &&
          selectedClaim.extensions.incomeParticipant &&
          selectedClaim.extensions.incomeParticipant[0] &&
          selectedClaim.extensions.incomeParticipant[0] === 'true') ||
        false;

      const incomeParticipantCheckIcons = this.getIncomeParticipantCheckIcons(row, incomeParticipant);
      return { ...row, incomeParticipant, incomeParticipantCheckIcons };
    });
  }

  static getIncomeParticipantCheckIcons(row, incomeParticipant): any[] {
    let icons: any[];
    if (this.isValidIncomeParticipantRole(row)) {
      icons = [
        {
          icon: incomeParticipant ? 'check_box' : 'check_box_outline_blank',
          onClickAction: true,
          class: 'ice-checkbox-icon',
        },
      ];
    }
    return icons;
  }

  static isValidIncomeParticipantRole(row): boolean {
    return row.roleRaw === 'E';
  }

  static hasOwnershipIncomeParticipant(ownershipRows, claims) {
    const ownershipWithIncomeParticipant = this.joinClaimsToOwnership(ownershipRows, claims);
    return ownershipWithIncomeParticipant.filter(row => row.incomeParticipant).length > 0;
  }

  static formatClaimShareToSend(shares) {
    return this.cleanShareEmptyDates(
      shares &&
        shares.map(share => ({
          ...share,
          territories: TerritoryUtils.formatShareTerritoriesForRegistration(share.territories),
          rightTypes: (share.rightTypes || []).map(right => (right || '').trim()),
          startDate: DateTimeUtils.getSimpleDateFormat(share.startDate),
          endDate: DateTimeUtils.getSimpleDateFormat(share.endDate),
        })),
    );
  }

  static getSharesFormatted(shares, translate) {
    let pr: string;
    let prIcons: IconInterface[];
    let prToExport: string;
    let mr: string;
    let mrIcons: IconInterface[];
    let mrToExport: string;
    const sharesIcons: IconInterface[] = [];
    let startDate: string;
    let endDate: string;
    let endDateToExport: string;
    let postTermCollectionDate: string;
    let postTermCollectionDateToExport: string;
    let territoriesText: string;
    let territoriesTooltip: string;
    let territoriesIcon: IconInterface[];
    let priorRoyaltiesStartDate: string;
    let partiallyTerminatedIcons: IconInterface[];

    if (shares && shares.length > 0) {
      const formattedShares = shares.map(share => {
        const newShare = clone(share);
        newShare.territories = TerritoryUtils.convertTerritoryArrayElements(share.territories, TerritoryDataType.NAME);
        return newShare;
      });

      const sharesItem: AgreementShares = formattedShares[0];
      ({ territoriesIcon, territoriesText, territoriesTooltip } =
        has(sharesItem, 'territories') && TerritoryUtils.getTerritoriesDisplayListData(sharesItem.territories, TerritoryDataType.NAME));

      startDate = sharesItem.startDate;
      const allTheSameDates = this.hasAgreementSharesSameEndDateAndPostTermCollectionDate(shares);
      if (allTheSameDates) {
        endDate = sharesItem.endDate;
        endDateToExport = endDate;
        postTermCollectionDate = sharesItem.postTermCollectionDate;
        postTermCollectionDateToExport = postTermCollectionDate;
      } else {
        partiallyTerminatedIcons = !allTheSameDates ? [{ icon: 'sms_failed', text: translate.instant(`AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.PARTIALLY_TERMINATED`) }] : [];
        endDateToExport = translate.instant(`AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.PARTIALLY_TERMINATED`);
        postTermCollectionDateToExport = translate.instant(`AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.PARTIALLY_TERMINATED`);
      }
      const priorRoyaltiesDate = sharesItem.priorRoyaltiesDate;
      priorRoyaltiesStartDate = this.selectPriorRoyaltiesStartDate(startDate, priorRoyaltiesDate);

      const prShares = this.selectShares(formattedShares, 'PR', translate);
      pr = prShares.value;
      prIcons = prShares.icons;
      prToExport = prShares.exportValue;
      const mrShares = this.selectShares(formattedShares, 'MR', translate);
      mr = mrShares.value;
      mrIcons = mrShares.icons;
      mrToExport = mrShares.exportValue;
    }
    return {
      territoriesIcon,
      territoriesText,
      territoriesTooltip,
      startDate,
      endDate,
      endDateToExport,
      postTermCollectionDate,
      postTermCollectionDateToExport,
      partiallyTerminatedIcons,
      priorRoyaltiesStartDate,
      sharesIcons,
      pr,
      prIcons,
      prToExport,
      mr,
      mrIcons,
      mrToExport,
    };
  }

  static hasAgreementSharesSameEndDateAndPostTermCollectionDate(shares: AgreementShares[]) {
    return uniq((shares || []).map(share => `${share.endDate}-${share.postTermCollectionDate}`)).length < 2;
  }

  static selectPriorRoyaltiesStartDate(startDate: string, priorRoyalties: string): string {
    const priorRoyaltiesMoment = priorRoyalties && moment(priorRoyalties);
    const startDateMoment = startDate && moment(startDate);
    const retval =
      priorRoyaltiesMoment && startDateMoment ? (priorRoyaltiesMoment.isSameOrAfter(startDateMoment) ? 'N' : 'Y') : priorRoyaltiesMoment?.isBefore(startDateMoment) ? 'Y' : 'N';
    return retval;
  }

  static getSharesPRorMRSociety(shares, type: string) {
    const rightsToSelect = type === 'MR' ? mrRights : prRights;
    const correctShares = filter(shares, share => {
      const correctRights = intersection(share.rights, rightsToSelect);
      return share.rights && correctRights.length > 0;
    });
    if (correctShares) {
      const generalShares = sumBy(shares, (share: any) => (!difference(rightsToSelect, share.rights).length ? 1 : 0)) === 1;
      const firstShare = correctShares[0];
      const hasCorrectShares = true;
      return { hasCorrectShares, firstShare, generalShares };
    }
    return { hasCorrectShares: false };
  }

  static selectShares(shares, type: string, translate) {
    const shareObject = this.getSharesPRorMRSociety(shares, type);
    if (shareObject.hasCorrectShares) {
      if (!shareObject.firstShare) {
        return { value: '', icons: [], exportValue: '' };
      }
      if (!shareObject.generalShares) {
        return {
          value: '',
          icons: [{ icon: 'sms_failed', text: translate.instant('AGREEMENTS.NON_GENERAL_SHARES') }],
          exportValue: translate.instant('AGREEMENTS.NON_GENERAL_SHARES'),
        };
      }
      const share = shareObject.firstShare;
      const sharesIn = get(share, 'shareIn', 0) / 100;
      const sharesOut = get(share, 'shareOut', 0) / 100;
      const value = `${sharesIn}%<br>${sharesOut}%`;
      return { value, icons: [], exportValue: value };
    }
    return { value: '', icons: [], exportValue: '' };
  }

  static getClaimsActiveClaimants(claims) {
    const claimants = [];
    let hasActiveMembership = false;
    claims.forEach(claim => {
      const partySocieties = get(claim, 'claimant.party.societies', []);
      const societies = [];
      partySocieties.forEach(society => {
        if (society.memberships && !hasActiveMembership) {
          hasActiveMembership = !!society.memberships.find(membership => DateTimeUtils.isDateSameOrAfterNow(membership.endDate));
        }
        if (hasActiveMembership) {
          societies.push(society.societyId);
        }
      });

      if (hasActiveMembership) {
        const { partyName } = claim.claimant;
        claimants.push({
          role: claim.role,
          name: IpUtils.getIpFullName(partyName.attributes),
          icePartyName: last(WorkUtils.selectWorkKey(partyName.relations, '').split(':')),
          ipiNameNumber: last(WorkUtils.selectIPIKey(partyName.relations).split(':')),
          societies,
        });
      }
    });
    return claimants;
  }

  static workTabAllClaimsCleaner(claims, claimsFilter?) {
    return this.cleanLabels(filterClaimsLocally(claims, claimsFilter))
      .map(this.setAllClaimsCounterclaimRows)
      .map(claim => {
        const { societyCodePr, societyCodePrTooltip, societyCodePrIcon, societyCodeMr, societyCodeMrTooltip, societyCodeMrIcon } = WorkClaimsUtils.getSocietiesCodes({
          societies: claim.societies,
          claimsFilter,
        });
        return { ...claim, societyCodePr, societyCodePrTooltip, societyCodePrIcon, societyCodeMr, societyCodeMrTooltip, societyCodeMrIcon };
      });
  }

  static setAllClaimsCounterclaimRows(claim) {
    const { hasNotResolvedCounterclaim, alertIcon } = claim;
    const counterClaimIcon = hasNotResolvedCounterclaim ? CopyrightUtils.getCounterclaimIconWithTooltip() : [];
    const alertIcons = !!alertIcon ? alertIcon : [];
    return { ...claim, rowClass: hasNotResolvedCounterclaim ? 'unresolved-cc-row' : '', alertIcon: [...alertIcons, ...counterClaimIcon] };
  }

  static formatIdType(type: string, creator, nextId) {
    return `${creator}_${type}${nextId}`;
  }

  static onSearchPartySelected(step, type) {
    return (item: IpCleaned) => {
      if (step.fieldIsPublisherFromWork) {
        let isWorkPublisher = false;
        if (type === TYPE_PUBLISHER && step.workPublishers && step.workPublishers.length > 0) {
          isWorkPublisher = step.workPublishers.find(publisher => publisher.key === item.ipiNameNumber) ? true : false;
        }
        step.fieldIsPublisherFromWork.formControl.setValue(isWorkPublisher);
      }
      if (item.ipiNameNumber && item.ipiNameNumber.length > 0) {
        step[`field${type}IPI`].formControl.setValue(item.ipiNameNumber);
        step[`field${type}IPNameNumber`].formControl.setValue(item.nameKey);
      } else {
        step['fieldClaimantKey'].formControl.setValue(item.nameKey);
        step[`field${type}IPI`].formControl.setValue('');
        step.searchPartyFieldValidatorIPNameKey(type, step['fieldClaimantKey'].formControl, step['fieldClaimantKey']);
      }
      if (step[`field${type}Name`]) {
        step[`field${type}Name`].formControl.setValue(item.fullName);
      }
      if (step[`field${type}Info`]) {
        step[`field${type}Info`].formControl.setValue(item.originalItem);
      }
      if (step[`field${type}PartyId`]) {
        step[`field${type}PartyId`].formControl.setValue(item.partyId);
      }
      if (step[`field${type}PartyNameId`]) {
        step[`field${type}PartyNameId`].formControl.setValue(item.partyNameId);
      }
      if (step[`field${type}IPBaseNameNumber`]) {
        step[`field${type}IPBaseNameNumber`].formControl.setValue(item.baseIpiNumber);
      }
      if (step[`field${type}IpBaseType`]) {
        step[`field${type}IpBaseType`].formControl.setValue(item.typeOf);
      }
      if (step[`field${type}Societies`] && !!item.societies?.length) {
        step[`field${type}Societies`].formControl.setValue(item.societies);
      }
    };
  }

  static cleanLabels(claims: any[]): any[] {
    return (claims || []).map(claim => {
      const roleLabel = ContributorsUtils.getRoleLabelFromRoleValue(claim.roleRaw);
      return { ...claim, name: IpUtils.cleanNameLabel(claim.name, claim.typeOf), roleLabel };
    });
  }

  static cleanNameLabel(name: string): string {
    const names: string[] = name.split(',');
    if (names.length > 0) {
      let nameLabel = last(names).trim();
      nameLabel = IpUtils.serializeFullName(nameLabel);
      names[names.length - 1] = ` ${nameLabel}`;
      return names.join(',');
    }
    return name;
  }

  static getUnauthorizedIcons(compare: any, translate: any) {
    const unauthorized = compare?.unauthorized?.some(Boolean);
    return unauthorized ? CopyrightUtils.getUnauthorizedIconWithTooltip(translate) : undefined;
  }

  static replaceClaims(newValue, claim, work) {
    let itemMapped;

    const fieldsToClean = DateTimeUtils.cleanObjectIndefiniteDateAliasToDate(work);
    if (newValue?.linkCreator?.length > 0) {
      if (newValue.linkCreator.includes(INCOME_PARTICIPANT_VALUE)) {
        itemMapped = ClaimsUtils.updateLinkToIncomeParticipant(fieldsToClean, work.claims, newValue.claimId);
      } else {
        itemMapped = ClaimsUtils.updateLinkCreatorInClaim({
          fieldsToClean,
          linkCreator: newValue.linkCreator,
          claims: work.claims,
          editClaim: newValue.claimId,
          role: newValue.role,
        });
      }
    }

    if (claim.role === 'SE' && newValue.parentId) {
      const claimIndex = itemMapped.claims.findIndex(claimI => claimI.claimId === claim.claimId);
      itemMapped.claims[claimIndex].parentId = newValue.parentId;
    } else if (claim.role === 'SE' && !newValue.isPublisherFromWork) {
      itemMapped = ClaimsUtils.addNewPublisherToClaimOnEditSE(newValue, itemMapped.claims, work);
    }
    return itemMapped?.claims;
  }

  static addNewPublisherToClaimOnEditSE(newValue, claims, work) {
    const groupedClaims = this.getPublishersFromClaims(claims);
    const { publishers, creators } = groupedClaims;
    let ipiCreator;
    const incomeParticipant = (newValue.role === 'E' || newValue.role === 'SE') && newValue.linkCreator.length > 0 && newValue.linkCreator.includes(INCOME_PARTICIPANT_VALUE);
    const allClaims = clone(claims);

    newValue.linkCreator.map((creator, index) => {
      ipiCreator = CopyrightUtils.getKeySuffix(creator);
      const claimCreator = this.findClaimByXref(creator, allClaims);
      const claimIdCreator = claimCreator.claimId;
      const workNS = get(work, 'attributes.ns');
      const workID = get(work, 'id');
      const nextPublisherId = this.getNextPublisherId(claimIdCreator, publishers);
      const publisherId = this.formatIdType('P', claimIdCreator, nextPublisherId);
      const unauthorized = newValue.ClaimantIPI === UnKnowPublisherIPIKey || newValue.ClaimantIPI === UnknownAuthorIPIKey;
      const startDate = get(newValue.shares, 'shares.startDate');
      const endDate = get(newValue.shares, 'shares.endDate');

      allClaims.push(
        this.generateClaim(
          publisherId,
          workNS,
          workID,
          'E',
          PUBLISHER,
          newValue.PublisherPartyId ? newValue.PublisherPartyId : '',
          newValue.PublisherPartyNameId ? newValue.PublisherPartyNameId : '',
          startDate,
          endDate,
          newValue.shares ? newValue.shares : null,
          unauthorized,
          claimIdCreator,
          newValue.agreementId ? newValue.agreementId : null,
          incomeParticipant,
          newValue.PublisherInfo,
        ),
      );
      //Set SE Parent Id
      const claimIndexSE = allClaims.findIndex(claimI => claimI.claimId === newValue.claimId);
      allClaims[claimIndexSE].parentId = publisherId;
    });
    work.claims = allClaims;
    return work;
  }

  static removeClaimsAndChildren(claims: any, field: any): any[] {
    const selectClaim = find(claims, { claimId: get(field, 'claimId', '') });
    const children: any[] = this.getChildren(claims, selectClaim);
    return differenceBy(claims, [selectClaim, ...flattenDeep(children)], 'claimId');
  }

  static getChildren(claims: any[], parentClaim: object): any[] {
    const children: any[] = filter(claims, { parentId: get(parentClaim, 'claimId') });
    if (!!children?.length) {
      children.push(map(children, child => this.getChildren(claims, child)));
    }
    return children;
  }

  static cleanClaimEmptyDates(claim: object): any {
    SHARES_PROPERTIES.forEach(section => {
      if (has(claim, section)) {
        this.cleanShareEmptyDates(get(claim, section));
      }
    });
    return claim;
  }

  static cleanShareEmptyDates(claims: object[]): any[] {
    return DateTimeUtils.cleanEmptyDates(claims, ['endDate', 'postTermCollectionDate', 'priorRoyaltiesDate', 'startDate']);
  }

  static getTempCreatorClaimId(creators: object, ipiCreator: string): string {
    const ipiCreatorKey = last(split(ipiCreator?.trim(), ' - '))?.trim();
    return findKey(creators, creator => {
      if (isNaN(Number(ipiCreatorKey))) {
        return this.checkByName(creator, ipiCreatorKey);
      } else {
        return this.checkByIpIKeyRelations(creator, ipiCreatorKey);
      }
    });
  }

  static checkByName(creator: any, ipiCreatorKey: string) {
    return includes(ipiCreatorKey, this.getClaimantFullNameFromClaim(creator));
  }

  static checkByIpIKeyRelations(creator: any, ipiCreatorKey: string) {
    return isEqual(CopyrightUtils.getKeySuffix(WorkUtils.selectIPIKey(get(creator, 'claimant.partyName.relations', []))), ipiCreatorKey);
  }

  static getClaimantFullNameFromClaim(claim: WorkDetail['claims'][number] | SharePictureFullClaim): string {
    const ipNames = claim.claimant?.partyName?.attributes.names;
    if (ipNames) {
      const originalLanguageName = ipNames.find(name => name.language === 'OL');
      if (originalLanguageName) {
        const { name, firstName: originalFirstName } = originalLanguageName;
        return name + (originalFirstName ? ', ' + originalFirstName : '');
      }
    }
    const firstName = get(claim, `claimant.partyName.attributes.firstName`, '').trim();
    return get(claim, `claimant.partyName.attributes.name`) + (firstName ? ', ' + firstName : '');
  }
}
