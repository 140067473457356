import { CopyrightUtils, IpUtils, RelationsUtils, TerritoryUtils, WorkUtils } from '@ice';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { flatMap, get } from 'lodash';

export class SearchActionsParser {
  static parse(items: any[], translate: TranslateService, extraParams?: Object, extraItems?: string[], store?: Store<any>) {
    return items.map(item => {
      const { status, createdDate, pointOfConflict, type, counterClaimTerritories } = item?.counterclaim?.attributes || {};
      const titleItem = WorkUtils.selectTitle(get(item, `counterclaim.work.attributes.titles`));
      const territories: string[] = flatMap((counterClaimTerritories || []).map(territory => territory.inExTisns));
      let workId;
      if (item.counterclaim && item.counterclaim.work && item.counterclaim.work.relations) {
        workId = WorkUtils.selectWorkKey(item.counterclaim.work.relations, item.counterclaim.attributes.workId);
        workId = workId.split(':')[1];
      }
      const id = item.id;
      const counterclaimIdFormatted = CopyrightUtils.clearNameSpace(get(item, `counterclaim.id`));
      const idFormatted = CopyrightUtils.clearNameSpace(id);
      const actionResponse = get(item, `attributes.response`, '');
      const actionType = get(item, `attributes.type`, '');
      const actionStatus = get(item, `attributes.status`, '');
      const actionAssignedUser = get(item, 'attributes.assignor');
      const resolutionOwners = get(item, `counterclaimParticipant.resolutionOwner`);
      const claimant = get(item, 'counterclaimParticipant.claimant');
      const ipiNameNumber = RelationsUtils.getIPIFromRelations(get(claimant, 'relations'));
      const claimantKey = IpUtils.selectIpsKey(get(claimant, 'relations'), 'claimant.id');
      const resolutionOwnerTooltip = SocietiesUtils.searchSocietyNameById(resolutionOwners);
      return {
        attributesRaw: item && item.attributes,
        id,
        counterclaimIdFormatted,
        counterclaimId: get(item, `counterclaim.id`),
        idFormatted,
        createdDate,
        pointOfConflict: pointOfConflict && translate.instant(`COUNTER.${pointOfConflict}`),
        territories,
        territoriesLabel: TerritoryUtils.convertTerritoryArrayElements(territories, TerritoryDataType.TISA),
        territoriesTooltip: TerritoryUtils.getTerritoriesNamesTooltipText(TerritoryUtils.convertTerritoryArrayElements(territories, TerritoryDataType.NAME)),
        type: type && translate.instant(`COUNTER.${type}`),
        workId,
        workTitle: titleItem ? titleItem.title : '',
        resolutionOwner: resolutionOwners || '',
        resolutionOwnerTooltip,
        status: status && translate.instant(`COUNTER.${status}`),
        actionStatus: actionStatus && translate.instant(`COUNTER.${actionStatus}`),
        actionType: actionType && translate.instant(`ACTIVITY.ACTION_TYPES.${actionType}`),
        actionAssignor: get(item, `attributes.assignor`, ''),
        actionOwner: CopyrightUtils.extractPartyNameFirstname(get(item, `ownerName.attributes`)) || CopyrightUtils.getKeySuffix(get(item, `attributes.owner`, '')),
        actionAssignedUser,
        actionResolver: ipiNameNumber,
        actionResolverKey: claimantKey,
        actionResolverName: get(item, 'counterclaimParticipant.claimant.attributes.name'),
        actionResponse: actionResponse && translate.instant(`ACTIVITY.ACTION_RESPONSES.${actionResponse}`),
        actionDeadline: get(item, `attributes.deadline`, ''),
      };
    });
  }
}
