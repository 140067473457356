<div class="ice-share-table-wrapper-column-group">
  <div class="ice-share-table-column-group">
    <div class="{{ to.wrapperClass }}" *ngFor="let fieldGroup of field.fieldGroup; let i = index">
      <div style="position: relative">
        <button
          *ngIf="(to.showButtons | async) && field.fieldGroup.length > 1"
          mat-icon-button
          color="warn"
          style="position: absolute; top: -8px; right: 24px; z-index: 10"
          type="button"
          (click)="remove(i)"
        >
          <mat-icon class="ice-share-delete-icon" matTooltip="{{ to.tooltipRemove }}" [matTooltipClass]="'ice-txt-size-12'" [matTooltipPosition]="'above'"
            >do_not_disturb_on</mat-icon
          >
        </button>
        <button mat-icon-button color="accent" style="position: absolute; top: -8px; right: 0px; z-index: 10" type="button" (click)="clone(i)">
          <mat-icon class="ice-share-delete-icon" matTooltip="{{ to.tooltipClone }}" [matTooltipClass]="'ice-txt-size-12'" [matTooltipPosition]="'above'">content_copy</mat-icon>
        </button>
      </div>
      <formly-group [field]="fieldGroup">
        <div *ngIf="(to.showButtons | async) && !to.onlyAllRights" class="ice-share-bt-inclusion">
          <button
            mat-icon-button
            color="primary"
            [ngClass]="'edit-usage-types-' + i"
            (click)="showInclusionPopup(i, $event)"
            matTooltip="{{ to.btSelectUsageTypes }}"
            [matTooltipClass]="'ice-txt-size-12 inclusion-tooltip'"
            [matTooltipPosition]="'above'"
          >
            <mat-icon class="ice-share-edit-icon">edit</mat-icon>
          </button>
        </div>
      </formly-group>
    </div>
    <div class="ice-share-col-w-first">
      <div class="ice-share-table-header-col">
        <div class="ice-share-table-row">
          <div *ngIf="to.showButtons | async" class="ice-share-info-container-first">
            <mat-icon
              matTooltip="{{ (isObservable(to.rowLabels) ? (to.rowLabels | async) : to.rowLabels)[0].info }}"
              [matTooltipClass]="'ice-tooltip ice-tooltip-shares-icon-info'"
              [matTooltipPosition]="'above'"
              >info</mat-icon
            >
          </div>
          <div class="ice-share-title-container-first">{{ (isObservable(to.rowLabels) ? (to.rowLabels | async) : to.rowLabel || [])[0]?.label }}</div>
        </div>
      </div>
      <div *ngFor="let rowLabel of isObservable(to.rowLabels) ? (to.rowLabels | async) : to.rowLabels; let i = index" [ngClass]="getShareTableLabelClass(i, rowLabel)">
        <div *ngIf="i > 0" class="ice-share-table-row">
          <div *ngIf="to.showButtons | async" class="ice-share-info-icon-container">
            <mat-icon matTooltip="{{ rowLabel.info }}" [matTooltipClass]="'ice-tooltip ice-tooltip-shares-icon-info'" [matTooltipPosition]="'above'">info</mat-icon>
          </div>
          <div class="ice-share-label-percentage">{{ rowLabel.label }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="to.showButtons | async" class="display-flex justify-content-start">
  <button *ngIf="to.addButtons.addMechanical" mat-button class="btn btn-primary ice-mr-50" type="button" (click)="addMechanical()">
    <mat-icon>add_circle</mat-icon>
    <span>{{ to.addButtons.addMechanical }}</span>
  </button>
  <button *ngIf="to.addButtons.addPerforming" mat-button class="btn btn-primary ice-mr-50" type="button" (click)="addPerforming()">
    <mat-icon>add_circle</mat-icon>
    <span>{{ to.addButtons.addPerforming }}</span>
  </button>
  <button *ngIf="to.addButtons.addSynchronisation" mat-button class="btn btn-primary ice-mr-50" type="button" (click)="addSynchronisation()">
    <mat-icon>add_circle</mat-icon>
    <span>{{ to.addButtons.addSynchronisation }}</span>
  </button>
  <button *ngIf="to.addButtons.addPrint" mat-button class="btn btn-primary ice-mr-50" type="button" (click)="addPrint()">
    <mat-icon>add_circle</mat-icon>
    <span>{{ to.addButtons.addPrint }}</span>
  </button>
</div>
