import { generateHiddenFields } from '@ice';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SelectOptions } from '../stepper-select-options';
import { DUMMY_ID_FIELD_KEYS } from '../steps/agreement-parties';

export class SummaryAgreementParties {
  static getFormField(translate): FormlyFieldConfig[] {
    const options = SelectOptions.getOptions(translate);
    return [
      {
        fieldGroupClassName: 'hidden pos-absolute',
        fieldGroup: generateHiddenFields(DUMMY_ID_FIELD_KEYS),
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ className: 'flex-1', template: `<h2 class="ice-text-color-blue">${translate.instant('AGREEMENTS.AGREEMENT_PARTIES.TITLE')}</h2>` }],
      },
      { fieldGroupClassName: 'display-flex', fieldGroup: [{ className: 'flex-1', template: `<h3>${translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR')}</h3>` }] },
      {
        fieldGroupClassName: 'display-flex flex-4',
        fieldGroup: [
          {
            className: 'flex-1 ice-value-mt-25 ice-txt-size-18',
            key: 'assignor.assignor_type',
            type: 'select',
            templateOptions: {
              label: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR_TYPE'),
              options: options.agreementParties.assignor_type,
              disabled: true,
              isSummary: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-2 ice-retention-agreement-label',
            key: 'assignor.assignor_name',
            type: 'label',
            templateOptions: { text: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR_NAME'), disabled: true, isSummary: true },
          },
          {
            className: 'flex-1 ice-retention-agreement-label',
            key: 'assignor.assignor_society_code',
            type: 'label',
            templateOptions: { text: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR_PRO_AFFILIATION'), disabled: true, isSummary: true },
          },
          {
            className: 'flex-1 ice-retention-agreement-label',
            key: 'assignor.assignor_ip_name_key',
            type: 'label',
            hideExpression: model => model.assignor && model.assignor.assignor_ipi_name_number && model.assignor.assignor_ipi_name_number === '',
            templateOptions: { text: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR_IP_NAME_KEY'), disabled: true, isSummary: true },
          },
          {
            className: 'flex-1 ice-retention-agreement-label',
            key: 'assignor.assignor_ipi_name_number',
            type: 'label',
            hideExpression: model => model.assignor && model.assignor.assignor_ip_name_key && model.assignor.assignor_ip_name_key === '',
            templateOptions: { text: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR_IPI_NAME_NUMBER'), disabled: true, isSummary: true },
          },
        ],
      },
      { fieldGroupClassName: 'display-flex', fieldGroup: [{ className: 'flex-1', template: `<h3>${translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNEE')}</h3>` }] },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-2 ice-retention-agreement-label',
            key: 'assignee_name',
            type: 'label',
            templateOptions: { text: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNEE_NAME'), disabled: true, isSummary: true },
          },
          {
            className: 'flex-1 ice-retention-agreement-label',
            key: 'assignee_society_code',
            type: 'label',
            templateOptions: { text: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNEE_PRO_AFFILIATION'), disabled: true, isSummary: true },
          },
          {
            className: 'flex-1 ice-retention-agreement-label',
            key: 'assignee_ip_name_key',
            type: 'label',
            hideExpression: model => model.assignee_ipi_name_number && model.assignee_ipi_name_number === '',
            templateOptions: { text: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNEE_IP_NAME_KEY'), disabled: true, isSummary: true },
          },
          {
            className: 'flex-1 ice-retention-agreement-label',
            key: 'assignee_ipi_name_number',
            type: 'label',
            hideExpression: model => model.assignee_ip_name_key && model.assignee_ip_name_key === '',
            templateOptions: { text: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNEE_IPI_NAME_NUMBER'), disabled: true, isSummary: true },
          },
        ],
      },
    ];
  }
}
