import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils, equalFieldsValuesAndNotEmpty, generateHiddenFields, IpCleaned, validateField } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { AGREEMENTS_CONSTANTS, AGREEMENTS_CONSTANTS as Types, GENERIC_IP_NAME_KEY_WITHOUT_ICE } from 'config/constants/agreements.constants';
import { IpNature, NEW_IP_ASSIGNEE, NEW_IP_ASSIGNOR } from 'config/constants/ips.constants';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { DialogSearchParty } from 'config/dialog-builders/dialog-search-party';
import { DialogLayoutCreateIp } from 'config/dialog-layouts/dialog-layout-create-ip';
import { SectionsConfig } from 'config/sections-config';
import { cloneDeep, get } from 'lodash';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromActions from 'store/root';
import * as fromRoot from 'store/root';
import { StepType } from '../../stepper-config';
import { SelectOptions } from '../stepper-select-options';
import { AgreementPartiesValidator } from './validators';

export const STEP_PROPS = ['fieldAssignorDummyPartyID', 'fieldAssigneeDummyPartyID', 'fieldAssignorDummyPartyNameID', 'fieldAssigneeDummyPartyNameID'];
export const DUMMY_ID_FIELD_KEYS = ['assignor_dummy_party_id', 'assignee_dummy_party_id', 'assignor_dummy_party_name_id', 'assignee_dummy_party_name_id'];
const IP_INITIAL_VALIDATION_STATE = {
  validatingAssignorIp: false,
  assignorIpResult: null,
  validatingAssigneeIp: false,
  assigneeIpResult: null,
};
const IPI_INITIAL_VALIDATION_STATE = {
  validatingAssignorIPI: false,
  assignorIPIResult: null,
  validatingAssigneeIPI: false,
  assigneeIPIResult: null,
};

const VALIDATION_TYPE_IP_NAME_KEY = 'IPNameKey';
const VALIDATION_TYPE_IPI_NAME_NUMBER = 'IPINameNumber';

interface UpdateAssigneeParams {
  ipKey: string;
  name: string;
  societyCode: string;
  baseIpiNumber: string;
  nameKey?: string;
  nameNumber?: string;
  baseKey?: string;
  dummyPartyId?: string;
  dummyPartyNameId?: string;
  fromSearch?: boolean;
}

interface UpdateAssignorParams {
  ipKey: string;
  type: string;
  name: string;
  societyCode: string;
  baseIpiNumber: string;
  nameKey?: string;
  nameNumber?: string;
  baseKey?: string;
  dummyPartyId?: string;
  dummyPartyNameId?: string;
  fromSearch?: boolean;
}

export class PartiesStep {
  private form: FormGroup;
  private dialogRefCreateIps: MatDialogRef<DialogMultiLayoutComponent, any>;
  private fieldAssignorType: any;
  private fieldAssignorIPI: any;
  private fieldAssignorSocietyCode: any;
  private fieldAssignorName: any;
  private fieldAssignorBaseIPINumber: any;
  private fieldAssignorBaseKey: any;
  private fieldAssigneeIPI: any;
  private fieldAssigneeSocietyCode: any;
  private fieldAssigneeName: any;
  private fieldAssigneeBaseIPINumber: any;
  private fieldAssigneeBaseKey: any;
  private fieldHiddenAssignorType: any;
  private fieldAssignorIPNameKey: any;
  private fieldAssigneeIPNameKey: any;
  private fieldAssignorDummyPartyID: any;
  private fieldAssigneeDummyPartyID: any;
  private fieldAssignorDummyPartyNameID: any;
  private fieldAssigneeDummyPartyNameID: any;
  private assignorIpKey: Object = {};
  private assigneeIpKey: Object = {};
  private submitterIPINameNumber = '';
  private isFirstTime = true;
  private validationIpFlags = cloneDeep(IP_INITIAL_VALIDATION_STATE);
  private validationIPIFlags = cloneDeep(IPI_INITIAL_VALIDATION_STATE);
  private unsubscribeAll = new Subject();
  private equalityCheckStatus = {
    isAssignorIpNameKeyInvalid: false,
    isAssigneeIpNameKeyInvalid: false,
    isAssigneeIPINameNumberInvalid: false,
    isAssignorIPINameNumberInvalid: false,
  };

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
    private dialog: MatDialog,
    private fieldValidatorService: FieldValidatorService,
    private searchService: SearchService,
  ) {
    this.store.pipe(select(fromForm.getNewSectionNewIp), takeUntil(this.unsubscribeAll)).subscribe(newIp => {
      if (newIp) {
        const { current, assignor, assignee } = newIp;
        if (current === NEW_IP_ASSIGNOR && assignor !== null) {
          const { name, firstName, id, xref, typeOf, idPartyName, xrefPartyName } = assignor;
          if (this.fieldAssignorIPI) {
            this.resetAssignor();
            this.updateAssignor({
              ipKey: '',
              type: typeOf,
              name: `${firstName || ''} ${name}`.toUpperCase(),
              societyCode: '',
              baseIpiNumber: xref,
              nameKey: CopyrightUtils.getKeySuffix(xrefPartyName),
              dummyPartyId: id,
              dummyPartyNameId: idPartyName,
            });
          }
          if (this.dialogRefCreateIps) {
            this.dialogRefCreateIps.close();
          }
        } else if (current === NEW_IP_ASSIGNEE && assignee !== null) {
          const { name, firstName, id, xref, idPartyName, xrefPartyName } = assignee;
          if (this.fieldAssigneeIPI) {
            this.resetAssignee();
            this.updateAssignee({
              ipKey: '',
              name: `${firstName || ''} ${name}`.toUpperCase(),
              societyCode: '',
              baseIpiNumber: xref,
              nameKey: CopyrightUtils.getKeySuffix(xrefPartyName),
              dummyPartyId: id,
              dummyPartyNameId: idPartyName,
            });
          }
          if (this.dialogRefCreateIps) {
            this.dialogRefCreateIps.close();
          }
        }
      }
    });
  }

  getStep(): StepType {
    const options = SelectOptions.getOptions(this.translate);
    return {
      label: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.TITLE'),
      showError: of(this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ERROR_PARTIES_STEP')),
      formBuilder: [
        {
          fieldGroupClassName: 'hidden pos-absolute',
          fieldGroup: generateHiddenFields(DUMMY_ID_FIELD_KEYS, STEP_PROPS, this).concat(this.cycleHooksField()),
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [{ fieldGroupClassName: 'display-flex', fieldGroup: [this.getButtons(Types.TYPE_ASSIGNOR)] }],
        },
        this.getAssignorFields(Types.TYPE_ASSIGNOR),
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [{ fieldGroupClassName: 'display-flex', fieldGroup: [this.getButtons(Types.TYPE_ASSIGNEE)] }],
        },
        this.getEntityFields(Types.TYPE_ASSIGNEE),
        {
          key: 'administrator',
          type: 'checkbox',
          defaultValue: false,
          templateOptions: {
            label: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.IS_ADMINISTRATOR'),
            required: false,
          },
        },
      ],
    };
  }

  cycleHooksField() {
    return {
      template: '',
      hooks: {
        onInit: field => {
          this.form = field.form;
        },
      },
    };
  }

  getBTSearch(type: string) {
    return {
      key: `bt_search_${type}`,
      className: 'ice-bt-ip',
      type: 'label',
      templateOptions: {
        materialType: 'mat-icon-button',
        icons: { icon: 'search', class: `search-${type}` },
        tooltipText: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.SEARCH_CLAIMANT'),
        onClick: (model, control) => {
          this.onClickSearchPartyDialog(type);
        },
      },
    };
  }

  getBtAdd(type: string) {
    return {
      key: `bt_add_${type}`,
      className: 'ice-bt-ip',
      type: 'label',
      templateOptions: {
        materialType: 'mat-icon-button',
        icons: { icon: 'add' },
        tooltipText: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.CREATE_DUMMY_IP'),
        onClick: model => {
          const submitterIpiNameNumber = get(this.form, 'parent.controls[0].controls.ipi_name_number.value');
          if (submitterIpiNameNumber) {
            this.submitterIPINameNumber = model['ipi_name_number'];
            this.store.dispatch(new fromForm.SetCurrentNewIp(type === Types.TYPE_ASSIGNOR ? NEW_IP_ASSIGNOR : NEW_IP_ASSIGNEE));
            this.openDialogCreateIP(type === Types.TYPE_ASSIGNEE);
          } else {
            this.openDialogNoSubmitterIPI();
          }
        },
      },
    };
  }

  getButtons(type: string) {
    const commonBt = {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          className: '',
          template: `<h2>${this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${type.toUpperCase()}`)}</h2>`,
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [this.getBTSearch(type), this.getBtAdd(type)],
        },
      ],
    };
    if (type === Types.TYPE_ASSIGNOR) {
      commonBt.fieldGroup.push(this.getBtGenericAssignor());
    }
    return commonBt;
  }

  getBtGenericAssignor(): any {
    return {
      key: 'bt_add_generic_assignor',
      className: 'ice-bt-ip',
      type: 'label',
      templateOptions: {
        materialType: 'mat-icon-button',
        icons: {
          icon: 'playlist_add',
        },
        tooltipText: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.GENERIC_CREATOR'),
        onClick: model =>
          this.updateAssignor({
            ipKey: GENERIC_IP_NAME_KEY_WITHOUT_ICE,
            type: IpNature.NATURAL_PERSON,
            name: 'GENERIC CREATOR',
            societyCode: '',
            baseIpiNumber: '',
            nameKey: GENERIC_IP_NAME_KEY_WITHOUT_ICE,
            nameNumber: '',
            baseKey: GENERIC_IP_NAME_KEY_WITHOUT_ICE,
          }),
      },
    };
  }

  getAssignorFieldSelectType(): any {
    const options = SelectOptions.getOptions(this.translate);
    return {
      fieldGroupClassName: 'display-flex flex-4 ice-pr-8',
      fieldGroup: [
        {
          className: 'flex-1 input-msg-overflow-inherit',
          wrappers: ['wrapper-info'],
          templateOptions: {
            infoText: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR_TYPE_INFO'),
          },
          fieldGroup: [
            {
              key: 'assignor_type',
              type: 'select',
              defaultValue: IpNature.LEGAL_ENTITY,
              modelOptions: {
                updateOn: 'change',
              },
              templateOptions: {
                label: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR_TYPE'),
                options: options.agreementParties.assignor_type,
              },
              hooks: {
                onInit: field => {
                  this.fieldAssignorType = field;
                  if ([IpNature.NATURAL_PERSON, IpNature.LEGAL_ENTITY].includes(field.formControl.value)) {
                    this.fieldValidatorService.agreementFormAssignorType$.next({ assignorType: field.formControl.value, isInitialValue: true });
                  }
                  field.formControl.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(assignorType => {
                    if ([IpNature.NATURAL_PERSON, IpNature.LEGAL_ENTITY].includes(assignorType)) {
                      if (this.fieldValidatorService.agreementFormAssignorType$.value === null) {
                        this.fieldValidatorService.agreementFormAssignorType$.next({ assignorType, isInitialValue: true });
                      } else if (this.fieldValidatorService.agreementFormAssignorType$.value?.assignorType !== assignorType) {
                        this.fieldValidatorService.agreementFormAssignorType$.next({ assignorType, isInitialValue: false });
                      }
                    }
                  });
                },
                onDestroy: () => {
                  this.formlyOnDestroy();
                },
              },
              validators: {
                selectedTypeIsDifferent: {
                  expression: control => !this.fieldHiddenAssignorType || get(this.fieldHiddenAssignorType, 'formControl.value') === control.value,
                  message: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ERROR_INCORRECT_SELECTED_TYPE'),
                },
              },
            },
            {
              className: 'ice-display-none',
              key: 'assignor_hidden_type',
              type: 'input',
              modelOptions: {
                updateOn: 'blur',
              },
              hooks: {
                onInit: field => (this.fieldHiddenAssignorType = field),
              },
            },
          ],
        },
      ],
    };
  }

  getNameFields(type: string): any {
    const typeUpper = type.toUpperCase();
    const expProperty =
      type === Types.TYPE_ASSIGNOR
        ? {
            'model.assignor_name_filled': 'model.assignor_name',
          }
        : {
            'model.assignee_name_filled': 'model.assignee_name',
          };
    return {
      className: 'flex-4',
      wrappers: ['wrapper-info'],
      templateOptions: {
        infoText: this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${typeUpper}_NAME_INFO`),
      },
      fieldGroup: [
        {
          className: 'ice-display-none',
          key: `${type}_name_filled`,
          type: 'input',
          templateOptions: {
            required: true,
          },
          expressionProperties: expProperty,
        },
        {
          key: `${type}_name`,
          type: 'input',
          modelOptions: {
            updateOn: 'blur',
          },
          templateOptions: {
            required: true,
            disabled: true,
            label: this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${typeUpper}_NAME`),
          },
          hooks: {
            onInit: field => {
              if (type === Types.TYPE_ASSIGNOR) {
                this.fieldAssignorName = field;
              } else {
                this.fieldAssigneeName = field;
              }
            },
          },
        },
      ],
    };
  }

  getSocietyCodeField(type: string): any {
    const typeUpper = type.toUpperCase();
    return {
      className: 'flex-4',
      wrappers: ['wrapper-info'],
      templateOptions: {
        infoText: this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${typeUpper}_NAME_INFO`),
      },
      fieldGroup: [
        {
          key: `${type}_society_code`,
          type: 'input',
          modelOptions: {},
          templateOptions: {
            tooltip: '',
            class: 'ice-overflow-hidden',
            disabled: true,
            label: this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${typeUpper}_PRO_AFFILIATION`),
            onClick: () => {
              if (type === Types.TYPE_ASSIGNOR && this.assignorIpKey && this.assignorIpKey['value']) {
                this.store.dispatch(
                  new fromRoot.Go({
                    path: [`copyright/${SectionsConfig.IPS.name}/${this.assignorIpKey['value']}/details`],
                  }),
                );
              } else if (type === Types.TYPE_ASSIGNEE && this.assigneeIpKey && this.assigneeIpKey['value']) {
                this.store.dispatch(
                  new fromRoot.Go({
                    path: [`copyright/${SectionsConfig.IPS.name}/${this.assigneeIpKey['value']}/details`],
                  }),
                );
              }
            },
          },
          expressionProperties: {
            'templateOptions.tooltip': model => SocietiesUtils.getSocietyNamesAndIdsByIds(type === Types.TYPE_ASSIGNOR ? model.assignor_society_code : model.assignee_society_code),
            'templateOptions.class': model => {
              if (type === Types.TYPE_ASSIGNOR) {
                return this.assignorIpKey && this.assignorIpKey['value'] ? 'ice-line-height-18 ice-overflow-hidden' : 'ice-line-height-18 ice-overflow-hidden ice-cursor-none';
              }
              return this.assigneeIpKey && this.assigneeIpKey['value'] ? 'ice-line-height-18 ice-overflow-hidden' : 'ice-line-height-18 ice-overflow-hidden ice-cursor-none';
            },
          },
          hooks: {
            onInit: field => {
              if (type === Types.TYPE_ASSIGNOR) {
                this.fieldAssignorSocietyCode = field;
              } else {
                this.fieldAssigneeSocietyCode = field;
              }
            },
          },
        },
      ],
    };
  }

  getIpNameKeyField(type: string) {
    const typeUpper = type.toUpperCase();
    const validatorAssignor = {
      assignorEqualToAssigneeIpNameKey: {
        expression: () => this.validateEqualityIpAssignor(),
        message: this.translate.instant('ERRORS.ASSIGNOR_ASSIGNEE_NOT_EQUAL'),
      },
    };
    const validatorAssignee = {
      assigneeEqualToaAssignorIpNameKey: {
        expression: () => this.validateEqualityIpAssignee(),
        message: this.translate.instant('ERRORS.ASSIGNOR_ASSIGNEE_NOT_EQUAL'),
      },
    };

    return {
      className: 'flex-4',
      fieldGroup: [
        {
          key: `${type}_ip_name_key`,
          wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
          type: 'input',
          modelOptions: {
            updateOn: 'blur',
          },
          templateOptions: {
            infoText: this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${typeUpper}_NAME_INFO`),
            tooltip: '',
            class: 'ice-overflow-hidden',
            disabled: false,
            label: this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${typeUpper}_IP_NAME_KEY`),
            errorMessages: {
              assignorEqualToAssigneeIpNameKey: this.translate.instant('ERRORS.ASSIGNOR_ASSIGNEE_NOT_EQUAL'),
              assigneeEqualToaAssignorIpNameKey: this.translate.instant('ERRORS.ASSIGNOR_ASSIGNEE_NOT_EQUAL'),
            },
          },
          hooks: {
            onInit: field => {
              if (type === Types.TYPE_ASSIGNOR) {
                this.fieldAssignorIPNameKey = field;
                if (field.formControl.value === GENERIC_IP_NAME_KEY_WITHOUT_ICE) {
                  this.fieldValidatorService.isAssignorGenericCreator.next(true);
                }
                field.formControl.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
                  if (value === GENERIC_IP_NAME_KEY_WITHOUT_ICE) {
                    this.fieldValidatorService.isAssignorGenericCreator.next(true);
                  } else {
                    this.fieldValidatorService.isAssignorGenericCreator.next(false);
                  }
                });
              } else {
                this.fieldAssigneeIPNameKey = field;
              }
            },
          },
          validators: type === Types.TYPE_ASSIGNOR ? validatorAssignor : validatorAssignee,
          asyncValidators:
            type === Types.TYPE_ASSIGNOR ? { assignorValidation: this.getAsyncIPNameKeyValidation(type) } : { assigneeValidation: this.getAsyncIPNameKeyValidation(type) },
        },
      ],
    };
  }

  getIPINameNumberField(type: string): any {
    const typeUpper = type.toUpperCase();
    const validatorAssignor = {
      assignorEqualToAssignee: {
        expression: () => {
          return this.validateEqualityIPIAssignor();
        },
        message: this.translate.instant('ERRORS.ASSIGNOR_ASSIGNEE_NOT_EQUAL'),
      },
    };
    const validatorAssignee = {
      assigneeEqualToAssignor: {
        expression: () => {
          return this.validateEqualityIPIAssignee();
        },
        message: this.translate.instant('ERRORS.ASSIGNOR_ASSIGNEE_NOT_EQUAL'),
      },
    };
    return {
      className: 'flex-3',
      wrappers: ['wrapper-info'],
      templateOptions: {
        infoText: this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${typeUpper}_NAME_INFO`),
      },
      fieldGroup: [
        {
          key: `${type}_ipi_name_number`,
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          modelOptions: {
            updateOn: 'blur',
          },
          className: 'input-msg-overflow-inherit',
          templateOptions: {
            type: 'text',
            needValidate: true,
            focused: false,
            label: this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${typeUpper}_IPI_NAME_NUMBER`),
          },
          hooks: {
            onInit: field => {
              if (type === Types.TYPE_ASSIGNOR) {
                this.fieldAssignorIPI = field;
              } else {
                this.fieldAssigneeIPI = field;
              }
            },
            blur: (field: FormlyFieldConfig) => {
              field.templateOptions.needValidate = false;
            },
            focus: (field: FormlyFieldConfig) => {
              field.templateOptions.needValidate = false;
            },
            keypress: (field, event) => {
              field.templateOptions.needValidate = true;
            },
          },
          validators: type === Types.TYPE_ASSIGNOR ? validatorAssignor : validatorAssignee,
          asyncValidators: type === Types.TYPE_ASSIGNOR ? { assignorValidation: this.getAsyncIPValidation(type) } : { assigneeValidation: this.getAsyncIPValidation(type) },
        },
        {
          className: 'ice-display-none',
          key: `${type}_base_ipi_number`,
          type: 'label',
          hooks: {
            onInit: field => (type === Types.TYPE_ASSIGNOR ? (this.fieldAssignorBaseIPINumber = field) : (this.fieldAssigneeBaseIPINumber = field)),
          },
        },
        {
          className: 'ice-display-none',
          key: `${type}_base_key`,
          type: 'label',
          hooks: {
            onInit: field => (type === Types.TYPE_ASSIGNOR ? (this.fieldAssignorBaseKey = field) : (this.fieldAssigneeBaseKey = field)),
          },
        },
      ],
    };
  }

  getAsyncIPNameKeyValidation(type: string) {
    return this.createAsyncIPValidation(type, VALIDATION_TYPE_IP_NAME_KEY, 'ICE');
  }

  getAsyncIPValidation(type: string) {
    return this.createAsyncIPValidation(type, VALIDATION_TYPE_IPI_NAME_NUMBER, null);
  }

  getAssignorFields(type: string): any {
    return {
      key: 'assignor',
      fieldGroup: [this.getAssignorFieldSelectType(), this.getEntityFields(type)],
    };
  }

  getEntityFields(type: string): any {
    return {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [this.getNameFields(type), this.getSocietyCodeField(type), this.getIpNameKeyField(type), this.getIPINameNumberField(type)],
    };
  }

  openDialogCreateIP(isAssignee: boolean) {
    const layoutCreateIp = new DialogLayoutCreateIp(this.translate);
    const isFormValid = new BehaviorSubject(false);
    let modelForm = null;

    this.dialogRefCreateIps = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-width-420',
        loading: this.store.pipe(select(fromForm.getNewSectionNewIpIsLoading)),
        loadingText: of(this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.CREATING_DUMMY_IP')),
        layouts: [
          {
            title: of(this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.TITLE')),
            actions: [
              { tooltip: this.translate.instant('POPUP.CANCEL'), color: 'warn', nextLayout: 0, icon: 'close', onClick: () => this.dialogRefCreateIps.close() },
              {
                tooltip: this.translate.instant('POPUP.CONFIRM'),
                nextLayout: 1,
                icon: 'done',
                className: 'ok-icon-button',
                disabled: isFormValid.pipe(map(isValid => !isValid)),
                onClick: () => {
                  this.store.dispatch(new fromForm.PutNewIp({ ...modelForm, submitterIPI: this.submitterIPINameNumber }));
                },
              },
            ],
            layout: [
              {
                group: [
                  {
                    type: 'formly',
                    config: {
                      model: of({}),
                      formBuilder: of(layoutCreateIp.getLayout(isAssignee)),
                      change: (modelChange: any) => (modelForm = cloneDeep(modelChange)),
                      setValidForm: isValid => isFormValid.next(isValid),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }

  onClickSearchPartyDialog(type) {
    DialogSearchParty.openDialog(type, this.dialog, this.storeNewItem, this.store, this.translate, this.fuseTranslationLoader, this.searchService, (item: IpCleaned) => {
      const { key, typeOf, fullName, societyCodes, baseIpiNumber, partyId, partyNameId, ipiNameNumber, baseKey } = item;

      if (type === AGREEMENTS_CONSTANTS.TYPE_ASSIGNOR) {
        this.fieldAssignorIPI.templateOptions.needValidate = false;
        this.resetAssignor();
        this.updateAssignor({
          ipKey: key,
          type: typeOf,
          name: fullName,
          societyCode: societyCodes,
          baseIpiNumber,
          nameKey: CopyrightUtils.getKeySuffix(key),
          nameNumber: ipiNameNumber,
          baseKey,
          dummyPartyId: partyId,
          dummyPartyNameId: partyNameId,
          fromSearch: true,
        });
      } else {
        this.fieldAssigneeIPI.templateOptions.needValidate = false;
        this.resetAssignee();
        this.updateAssignee({
          ipKey: key,
          name: fullName,
          societyCode: societyCodes,
          baseIpiNumber,
          nameKey: CopyrightUtils.getKeySuffix(key),
          nameNumber: ipiNameNumber,
          baseKey,
          dummyPartyId: partyId,
          dummyPartyNameId: partyNameId,
          fromSearch: true,
        });
      }
      this.dialog.closeAll();
    });
  }

  resetIfEmptyIPI(ipiField, fieldValue) {
    return !ipiField ? '' : fieldValue;
  }

  private getErrorMessageNewIp(): Observable<any> {
    return this.store.pipe(select(fromForm.getNewSectionNewIpError));
  }

  private updateAssignor(params: UpdateAssignorParams) {
    const { ipKey, type, name, societyCode, baseIpiNumber, nameKey, nameNumber, baseKey, dummyPartyId, dummyPartyNameId, fromSearch } = params;
    this.assignorIpKey['value'] = ipKey;
    this.fieldHiddenAssignorType.formControl.setValue(type);
    this.fieldAssignorType.formControl.setValue(type);
    this.fieldAssignorName.formControl.setValue(name);
    this.fieldAssignorSocietyCode.formControl.setValue(societyCode);
    this.fieldAssignorBaseIPINumber.formControl.setValue(baseIpiNumber);
    this.fieldAssignorBaseKey.formControl.setValue(baseKey);
    if (nameKey !== undefined) {
      this.fieldAssignorIPNameKey.formControl.setValue(nameKey);
    }
    if (nameNumber !== undefined) {
      this.fieldAssignorIPI.formControl.setValue(nameNumber);
    }
    if (dummyPartyId && dummyPartyNameId) {
      this.fieldAssignorDummyPartyID.formControl.setValue(dummyPartyId);
      this.fieldAssignorDummyPartyNameID.formControl.setValue(dummyPartyNameId);
      this.fieldAssignorIPI.templateOptions.needValidate = false;
      if (!fromSearch || !baseIpiNumber) {
        this.fieldAssignorIPI.formControl.setValue('');
      }
    }
    this.fieldAssignorIPI.formControl.markAsTouched();
    this.fieldValidatorService.generalSharesValidator();
  }

  private updateAssignee(params: UpdateAssigneeParams) {
    const { ipKey, name, societyCode, baseIpiNumber, nameKey, nameNumber, baseKey, dummyPartyId, dummyPartyNameId, fromSearch } = params;
    this.assigneeIpKey['value'] = ipKey;
    this.fieldAssigneeSocietyCode.formControl.setValue(societyCode);
    this.fieldAssigneeBaseIPINumber.formControl.setValue(baseIpiNumber);
    this.fieldAssigneeBaseKey.formControl.setValue(baseKey);
    if (nameKey) {
      this.fieldAssigneeIPNameKey.formControl.setValue(nameKey);
    }
    if (nameNumber) {
      this.fieldAssigneeIPI.formControl.setValue(nameNumber);
    }
    if (dummyPartyId && dummyPartyNameId) {
      this.fieldAssigneeDummyPartyID.formControl.setValue(dummyPartyId);
      this.fieldAssigneeDummyPartyNameID.formControl.setValue(dummyPartyNameId);
      this.fieldAssigneeIPI.templateOptions.needValidate = false;
      if (!fromSearch || !baseIpiNumber) {
        this.fieldAssigneeIPI.formControl.setValue('');
      }
    }
    if (societyCode !== undefined && societyCode.includes(AGREEMENTS_CONSTANTS.GEMA_SOCIETY)) {
      this.fieldValidatorService.agreementRegisterFields.gemaRemunerationClaimIndicator.hide = false;
    } else {
      this.fieldValidatorService.agreementRegisterFields.gemaRemunerationClaimIndicator.hide = true;
    }
    this.fieldValidatorService.agreementRegisterFields.gemaRemunerationClaimIndicator.formControl.updateValueAndValidity();
    // this is needed to force formly to revalidate fieldAssigneeIPI when is no required and his value is ''
    setTimeout(() => this.fieldAssigneeName.formControl.setValue(name), 200);
  }

  private resetFields(type: string, force = false, isIpNameKeyChanged = false) {
    if (type === Types.TYPE_ASSIGNOR) {
      this.resetAssignor(force, isIpNameKeyChanged);
    } else {
      this.resetAssignee(isIpNameKeyChanged);
    }
  }

  private resetAssignor(force = false, isIpNameKeyChanged = false) {
    if (!this.isFirstTime || force) {
      this.assignorIpKey['value'] = '';
      if (
        this.fieldHiddenAssignorType &&
        this.fieldAssignorType &&
        this.fieldAssignorName &&
        this.fieldAssignorSocietyCode &&
        this.fieldAssignorBaseIPINumber &&
        this.fieldAssignorIPNameKey
      ) {
        this.fieldHiddenAssignorType.formControl.setValue('');
        this.fieldAssignorType.formControl.setValue('');
        this.fieldAssignorName.formControl.setValue('');
        this.fieldAssignorSocietyCode.formControl.setValue('');
        this.fieldAssignorBaseIPINumber.formControl.setValue('');
        if (isIpNameKeyChanged) {
          this.fieldAssignorIPI.formControl.setValue('');
        } else {
          this.fieldAssignorIPNameKey.formControl.setValue('');
        }
      }
      this.isFirstTime = false;
    }
    this.fieldValidatorService.generalSharesValidator();
  }

  private resetAssignee(isIpNameKeyChanged = false) {
    this.assigneeIpKey['value'] = '';
    if (this.fieldAssigneeName && this.fieldAssigneeSocietyCode && this.fieldAssigneeBaseIPINumber && this.fieldAssigneeIPNameKey) {
      this.fieldAssigneeName.formControl.setValue('');
      this.fieldAssigneeSocietyCode.formControl.setValue('');
      this.fieldAssigneeBaseIPINumber.formControl.setValue('');
      if (isIpNameKeyChanged) {
        this.fieldAssigneeIPI.formControl.setValue('');
      } else {
        this.fieldAssigneeIPNameKey.formControl.setValue('');
      }
    }
  }

  private openDialogNoSubmitterIPI() {
    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const dialogRefInfo = dialogInfo.openDialog(
      this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_NO_SUBMITTER.TITLE'),
      this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_NO_SUBMITTER.TEXT'),
      () => dialogRefInfo.close(),
    );
  }

  forceIPINameNumberValidation(field, event?) {
    if (event) {
      event.preventDefault();
    }
    field.focus = false;
    field.focus = true;
  }

  validateEqualityIpAssignor() {
    if (!this.equalityCheckStatus.isAssignorIpNameKeyInvalid && (this.validationIpFlags.validatingAssignorIp || this.validationIpFlags.validatingAssigneeIp)) {
      return this.validationIpFlags.assignorIpResult;
    }

    this.validationIpFlags.validatingAssignorIp = true;

    const result = !equalFieldsValuesAndNotEmpty(this.fieldAssignorIPNameKey, this.fieldAssigneeIPNameKey);
    this.validationIpFlags.assignorIpResult = result;

    // Trigger validation for assignee only if it's not currently validating
    if (!this.validationIpFlags.validatingAssigneeIp) {
      this.validationIpFlags.validatingAssigneeIp = true;
      validateField(this.fieldAssigneeIPNameKey);
      this.equalityCheckStatus.isAssignorIpNameKeyInvalid = false;
      this.validationIpFlags.validatingAssigneeIp = false;
    }

    this.validationIpFlags.validatingAssignorIp = false;

    // Clear fields if assignor is equal to assignee
    if (!result) {
      this.equalityCheckStatus.isAssignorIpNameKeyInvalid = true;
      this.resetFields(Types.TYPE_ASSIGNOR, true, true);
    }
    return result;
  }

  validateEqualityIpAssignee() {
    if (!this.equalityCheckStatus.isAssigneeIpNameKeyInvalid && (this.validationIpFlags.validatingAssigneeIp || this.validationIpFlags.validatingAssignorIp)) {
      return this.validationIpFlags.assigneeIpResult;
    }

    this.validationIpFlags.validatingAssigneeIp = true;

    const result = !equalFieldsValuesAndNotEmpty(this.fieldAssignorIPNameKey, this.fieldAssigneeIPNameKey);
    this.validationIpFlags.assigneeIpResult = result;

    // Trigger validation for assignor only if it's not currently validating
    if (!this.validationIpFlags.validatingAssignorIp) {
      this.validationIpFlags.validatingAssignorIp = true;
      validateField(this.fieldAssignorIPNameKey);
      this.equalityCheckStatus.isAssigneeIpNameKeyInvalid = false;
      this.validationIpFlags.validatingAssignorIp = false;
    }

    this.validationIpFlags.validatingAssigneeIp = false;

    // Clear fields if assignee is equal to assignor
    if (!result) {
      this.equalityCheckStatus.isAssigneeIpNameKeyInvalid = true;
      this.resetFields(Types.TYPE_ASSIGNEE, true, true);
    }
    return result;
  }

  validateEqualityIPIAssignor() {
    if (!this.equalityCheckStatus.isAssignorIPINameNumberInvalid && (this.validationIPIFlags.validatingAssignorIPI || this.validationIPIFlags.validatingAssigneeIPI)) {
      return this.validationIPIFlags.assignorIPIResult;
    }

    this.validationIPIFlags.validatingAssignorIPI = true;

    const result = !equalFieldsValuesAndNotEmpty(this.fieldAssignorIPI, this.fieldAssigneeIPI);
    this.validationIPIFlags.assignorIPIResult = result;

    // Trigger validation for assignee only if it's not currently validating
    if (!this.validationIPIFlags.validatingAssigneeIPI) {
      this.validationIPIFlags.validatingAssigneeIPI = true;
      validateField(this.fieldAssigneeIPI);
      this.equalityCheckStatus.isAssignorIPINameNumberInvalid = false;
      this.validationIPIFlags.validatingAssigneeIPI = false;
    }

    this.validationIPIFlags.validatingAssignorIPI = false;
    // Clear fields if assignor is equal to assignee
    if (!result) {
      this.equalityCheckStatus.isAssignorIPINameNumberInvalid = true;
      this.resetFields(Types.TYPE_ASSIGNOR, true, false);
    }
    return result;
  }

  validateEqualityIPIAssignee() {
    if (!this.equalityCheckStatus.isAssigneeIPINameNumberInvalid && (this.validationIPIFlags.validatingAssigneeIPI || this.validationIPIFlags.validatingAssignorIPI)) {
      return this.validationIPIFlags.assigneeIPIResult;
    }

    this.validationIPIFlags.validatingAssigneeIPI = true;

    const result = !equalFieldsValuesAndNotEmpty(this.fieldAssignorIPI, this.fieldAssigneeIPI);
    this.validationIPIFlags.assigneeIPIResult = result;

    // Trigger validation for assignor only if it's not currently validating
    if (!this.validationIPIFlags.validatingAssignorIPI) {
      this.validationIPIFlags.validatingAssignorIPI = true;
      validateField(this.fieldAssignorIPI);
      this.equalityCheckStatus.isAssigneeIPINameNumberInvalid = false;
      this.validationIPIFlags.validatingAssignorIPI = false;
    }

    this.validationIPIFlags.validatingAssigneeIPI = false;
    // Clear fields if assignee is equal to assignor
    if (!result) {
      this.equalityCheckStatus.isAssigneeIPINameNumberInvalid = true;
      this.resetFields(Types.TYPE_ASSIGNEE, true, false);
    }
    return result;
  }

  createAsyncIPValidation(type: string, validationType: string, iceParam: string | null) {
    const typeUpper = type.toUpperCase();
    let isAssigneeNaturalPerson = false;

    const ipNameKeyTranslation = this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${typeUpper}_IP_NAME_KEY`);
    const ipiNameNumberTranslation = this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.${typeUpper}_IPI_NAME_NUMBER`);

    return {
      expression: (control: FormControl, field) => {
        if (!control.value) {
          return Promise.resolve(true); // Resolve immediately if the value is empty or null
        }

        if (type === Types.TYPE_ASSIGNOR) {
          if (this.validationIpFlags.validatingAssignorIp || this.validationIPIFlags.validatingAssignorIPI) {
            return Promise.resolve(true); // Prevent cyclic validation for assignor
          }
        } else if (type === Types.TYPE_ASSIGNEE) {
          if (this.validationIpFlags.validatingAssigneeIp || this.validationIPIFlags.validatingAssigneeIPI) {
            return Promise.resolve(true); // Prevent cyclic validation for assignee
          }
        }

        return new Promise(resolve => {
          if (control.value.length > 0) {
            this.store.dispatch(new fromActions.ShowDataLoadingVisibility(true));
            AgreementPartiesValidator.validIPKey(
              this.fieldValidatorService,
              control,
              '',
              (name: string, prSociety: string, ipType: string, ipKey: string, baseIpiNumber: string, nameKey: string, baseKey: string, ipiNameNumber?: string) => {
                if (type === Types.TYPE_ASSIGNOR) {
                  this.validationIpFlags.validatingAssignorIp = true;

                  const assignorParams: UpdateAssignorParams = {
                    ipKey,
                    type: ipType,
                    name,
                    societyCode: prSociety,
                    baseIpiNumber,
                    baseKey,
                  };
                  if (validationType === VALIDATION_TYPE_IP_NAME_KEY) {
                    assignorParams.nameNumber = ipiNameNumber;
                  } else {
                    assignorParams.nameKey = nameKey;
                  }
                  this.updateAssignor(assignorParams);

                  this.validationIpFlags.validatingAssignorIp = false;
                } else {
                  this.validationIpFlags.validatingAssigneeIp = true;

                  const assigneeParams: UpdateAssigneeParams = {
                    ipKey,
                    name,
                    societyCode: prSociety,
                    baseIpiNumber,
                    baseKey,
                  };

                  if (validationType === VALIDATION_TYPE_IP_NAME_KEY) {
                    assigneeParams.nameNumber = ipiNameNumber;
                  } else {
                    assigneeParams.nameKey = nameKey;
                  }
                  this.updateAssignee(assigneeParams);

                  isAssigneeNaturalPerson = false;
                  if (ipType === IpNature.NATURAL_PERSON) {
                    isAssigneeNaturalPerson = true;
                    this.store.dispatch(new fromActions.ShowDataLoadingVisibility(false));
                    this.validationIpFlags.validatingAssigneeIp = false;
                    return resolve(false);
                  }
                  this.validationIpFlags.validatingAssigneeIp = false;
                }
                this.store.dispatch(new fromActions.ShowDataLoadingVisibility(false));
                return resolve(true);
              },
              () => {
                this.resetFields(type, true, !!iceParam);
                this.store.dispatch(new fromActions.ShowDataLoadingVisibility(false));
                isAssigneeNaturalPerson = false;
                resolve(false);
              },
              iceParam,
            );
          } else {
            resolve(true);
          }
        });
      },
      message: () => {
        if (type === Types.TYPE_ASSIGNEE && isAssigneeNaturalPerson) {
          return this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ERROR_ASSIGNEE_CANNOT_BE_NATURAL_PERSON');
        }
        return this.translate.instant('ERROR.NOT_EXISTS').replace(/<<\w+>>/g, validationType === VALIDATION_TYPE_IP_NAME_KEY ? ipNameKeyTranslation : ipiNameNumberTranslation);
      },
    };
  }

  formlyOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
