import { AgreementUtils, ClaimantUtils, IpUtils, WorkUtils, RelationsUtils } from '@ice';
import { get } from 'lodash';
import { SearchAgreementsParser } from 'models/search-parsers/search-agreements-parser';
import { createPropertyCleaner, createResultCleaner } from '../api-call.factory';

export const getAgreementWorksPropertyCleaner = createPropertyCleaner(
  (value, detail, data, translate, tabName) => data?.key || (tabName === 'included-works' ? 'includedWorks' : 'excludedWorks'),
);

export const getAgreementWorksResultCleaner = createResultCleaner((value, detail, data, translate, tabName) =>
  WorkUtils.workSearchCleaner(
    get(value, 'items', [])
      .map(item => AgreementUtils.getAgreementApplicationWork(item))
      .filter(work => !!work),
    '',
    false,
    '',
    translate,
  ),
);

export const searchDialogAgreementsResultCleaner = createResultCleaner((value, detail, data, translate, tabName) =>
  SearchAgreementsParser.parse(get(value, 'items', []), translate),
);

export const getAgreementWritersCleaner = createResultCleaner((value, oldDetail, cleanerData, translate) => IpUtils.IpWriterCleaner(get(value, 'items', []), translate));

export const getAgreementWritersWorkIdsCleaner = createResultCleaner((value, oldDetail, cleanerData, translate) => get(value, 'items', [])[0].workIds);

export const getAgreementLinkedWriters = createResultCleaner((value, oldDetail, cleanerData, translate) =>
  value && value.items ? ClaimantUtils.cleanLinkedWriters(value.items, translate) : [],
);

export const getCounterResultCleaner = createResultCleaner(value => value.total);

export const getAgreementRetentionsResultCleaner = createResultCleaner(value => value?.items.map(item => item && item.relations && AgreementUtils.getAgreementKey(item.relations)));

export const getAgreementSubmitterPartyRelations = createResultCleaner(value => value?.items);

export const getAgreementConflictIconCleaner = createResultCleaner(
  (value, oldDetail, cleanerData, translate) => value && AgreementUtils.getAgreementConflictIcon(value, translate),
);

export const getAgreementCounterclaimsCleaner = createResultCleaner(value => value?.counterclaims);

export const getAgreementWritersCountCleaner = createResultCleaner(value => value?.total);

export const getAgreementOriginalPublishersList = createResultCleaner(value => get(value, 'items', []));

export const getAgreementRelationsCleaner = createResultCleaner(value => value?.relations);

export const getAgreementGroupsCleaner = createResultCleaner(value => value?.groups);

export const getAgreementXrefsCountCleaner = createResultCleaner(value => {
  if (!value) return 0;

  const relationsAndGroups = AgreementUtils.joinRelationsAndGroups(value);
  if (!relationsAndGroups) return 0;

  const xrefs = RelationsUtils.getAgreementXREF(relationsAndGroups);
  return xrefs.length;
});
