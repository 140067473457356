import { SPACE } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { addClassesToCdkPaneElement } from '@ice/utils';
import { Store } from '@ngrx/store';
import { FieldType } from '@ngx-formly/material';
import { CountryHotkeys } from 'assets/ts/countries';
import { concat, countBy, difference, every, filter, flatten, includes, intersection, intersectionWith, isArray, startsWith, toLower, uniq } from 'lodash';
import { Subject, isObservable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromRoot from 'store/root';

@Component({
  selector: 'ice-select',
  templateUrl: './select-type.component.html',
  styleUrls: ['./select-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTypeComponent extends FieldType implements OnInit, OnDestroy {
  @ViewChild(MatSelect, { static: true }) formFieldControl!: MatSelect;
  @ViewChild('searchInput') searchInput!: ElementRef;
  unsubscribeAll = new Subject();

  defaultOptions = {
    templateOptions: {
      options: [],
      compareWith: (o1: any, o2: any) => o1 === o2,
    },
  };
  public lastValue = [];

  private selectAllValue!: { options: any; value: any[] };
  allOptions = [];
  validOptions = [];
  previousValues = [];
  scrollTopBeforeSelection: any;

  constructor(private store: Store<fromRoot.RootState>) {
    super();
  }

  ngOnInit() {
    if (isArray(this.to.options)) {
      this.allOptions = this.to.options;
      this.setValidOptions(this.to.options);
    } else {
      this.to.options.pipe(takeUntil(this.unsubscribeAll)).subscribe(options => {
        this.allOptions = options;
        this.setValidOptions(options);
      });
    }
    if (this.to.change) {
      this.to.change(this.field, { value: this.formControl.value, lastValue: [] });
      this.lastValue = this.formControl.value;
    }

    this.store
      .select(fromRoot.getResetTerritorySearchInput)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        if (this.searchInput) {
          this.searchInput.nativeElement.value = this.searchInput.nativeElement.defaultValue || [];
          this.filterOptions('');
        }
      });

    this.setPreviousValuesList();

    this.formFieldControl._handleKeydown = event => {
      if (event.keyCode === SPACE) {
        return null;
      }
    };

    this.preventAutoScrollJump();
    // listen to hotkeys
    if (this.to.useCountryShortcuts) {
      this.store
        .select(fromRoot.getHotkey)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe(countryFromHotkey => {
          const allOptions = this.getAllOptions(this.allOptions);
          if (countryFromHotkey === CountryHotkeys.ALL_COUNTRIES) {
            if (isArray(this.allOptions)) {
              this.toggleSelectAll(this.allOptions);
            } else if (isObservable(this.to.options)) {
              this.to.options.pipe(takeUntil(this.unsubscribeAll)).subscribe(options => this.toggleSelectAll(options));
            }
          } else {
            const countryData = (allOptions || []).find(country => country.label === countryFromHotkey);

            if (!countryData) return;
            this.formControl.setValue([countryData.value]);
            this.formControl.markAsDirty();
          }
          // dispatch the `submit form shortKey` when submitShortcutEnable is true
          window.dispatchEvent(
            new KeyboardEvent('keyup', {
              key: 'Enter',
              altKey: true,
              bubbles: true,
              shiftKey: true,
            }),
          );
        });
    }
  }

  getSelectAllState(options: any[]) {
    if (this.empty || this.value.length === 0) {
      return '';
    }

    const allOptions = this.getAllOptions(this.to.componentVersion ? this.allOptions : options);
    return this.value.length !== allOptions.length ? 'indeterminate' : 'checked';
  }

  getSelectGroupState(options: any[]) {
    const currentOptionValues = options.map(option => option.value);
    const commonOptions = intersection(this.value, currentOptionValues);
    return !!commonOptions.length && commonOptions.length === options.length ? 'checked' : commonOptions.length === 0 ? 'empty' : 'indeterminate';
  }

  get selectedItemsCount(): number {
    return this.formControl.value?.filter?.(el => el !== null).length || 0;
  }

  getLabels(options) {
    const allOptions = this.getAllOptions(this.to.componentVersion ? this.allOptions : options);
    const allSelected = allOptions.length === this.formControl.value?.length;
    const matchedGroup =
      Array.isArray(this.formControl.value) &&
      this.allOptions.find(
        group =>
          group.options &&
          difference(
            group.options.map(op => op.value),
            this.formControl.value,
          ).length === 0 &&
          this.formControl.value.length === group.options.length,
      );
    const labels =
      Array.isArray(this.formControl.value) && intersectionWith(allOptions, this.formControl.value, (option: any, value) => option.value === value)?.map(option => option.label);

    const node = this.to.selectedLabelsAsValue ? 'value' : 'label';
    return (
      (this.to.multiple &&
        ((allSelected && this.to.selectAllLabel) ||
          (matchedGroup && matchedGroup.header) ||
          (labels && ((labels.length > 2 && `${labels.slice(0, 2)} (+${labels.length - 2} ${labels.length - 2 === 1 ? 'other' : 'others'} )`) || labels)) ||
          '')) ||
      allOptions?.find(option => option.value === this.formControl.value)?.[node] ||
      allOptions?.find(option => option.value === this.formControl.value)?.label ||
      undefined
    );
  }

  selectedAll(options): boolean {
    const allOptions = Array.isArray(options) && !!options[0]?.options ? flatten(options.map(group => group.options)) : options;
    return this.selectedItemsCount === allOptions.length;
  }

  clearControl() {
    this.formControl.setValue([]);
    this.toggleSelectAll(this.selectAllValue?.options || this.allOptions);
  }

  toggleSelectAll(options: any[]) {
    const selectAllValue = this.getSelectAllValue(concat(options, this.previousValues));
    const newValue = !this.value || uniq(this.value).length !== uniq(selectAllValue).length ? selectAllValue : [];
    this.formControl.markAsDirty();
    if (this.to.change) {
      this.to.change(this.field, { value: newValue, lastValue: this.formControl.value });
      this.lastValue = this.formControl.value;
    } else {
      this.formControl.setValue(uniq(newValue));
    }
  }

  toggleSelectGroup(options: any[]) {
    const currentOptionValues = options.map(option => option.value);
    const commonOptions = intersection(this.value, currentOptionValues);
    let newValues = [];
    if (commonOptions.length !== options.length) {
      newValues = uniq([...(this.value || []), ...currentOptionValues]);
    } else {
      newValues = difference(this.value || [], currentOptionValues);
    }
    this.formControl.markAsDirty();
    if (this.to.change) {
      this.to.change(this.field, { value: newValues, lastValue: this.formControl.value });
      this.lastValue = this.formControl.value;
    } else {
      this.formControl.setValue(newValues);
    }
  }

  change($event: MatSelectChange) {
    if (this.to.change) {
      this.to.change(this.field, { ...$event, lastValue: this.formControl.value });
      this.lastValue = this.formControl.value;
    } else {
      this.formControl.setValue($event.value);
    }
  }

  getAriaLabelledby() {
    if (this.to.attributes && this.to.attributes['aria-labelledby']) {
      return this.to.attributes['aria-labelledby'];
    }

    if (this.formField && this.formField._labelId) {
      return this.formField._labelId;
    }

    return null;
  }

  private getSelectAllValue(options: any[]) {
    if (!this.selectAllValue || options !== this.selectAllValue.options) {
      const flatOptions: any[] = [];
      filter(options, option => !!option).forEach(o => (o.options ? flatOptions.push(...o.options) : flatOptions.push(o)));

      this.selectAllValue = {
        options,
        value: flatOptions.map(o => o.value),
      };
    }

    return this.selectAllValue.value;
  }

  filterOptions(target) {
    if (target?.code === 'Space') {
      target.preventDefault();
      target.stopPropagation();
    }
    if (this.to.componentVersion) {
      this.removeHideOptions();
    }
    this.filterOptionGroup(target, this.validOptions);
    this.filterOptionGroup(target, this.previousValues);
  }

  private filterOptionGroup(target: any, group: any[]) {
    group?.forEach((option: any) => {
      if (option.options) {
        option.options.forEach(child => {
          this.setHideOption(target, child);
        });
        if (this.checkAllHiddenToDisplay(option.options)) {
          option.hide = true;
        }
      } else {
        this.setHideOption(target, option);
        this.checkAllHiddenToDisplay(option);
      }
    });
    if (this.to.componentVersion) {
      this.checkRemoveAllHiddenToDisplay();
    }
  }

  private setHideOption(target: any, option: any) {
    if (!!target?.value) {
      const tl = toLower(option['label']);
      const va = toLower(target.value);
      const bol = this.to.componentVersion > 0 ? includes(tl, va) : startsWith(tl, va);
      if (bol) {
        option.hide = false;
      } else {
        option.hide = true;
      }
    } else {
      option.hide = false;
    }
    return option;
  }

  private getAllOptions(options: any) {
    return options && options[0]?.options ? flatten(options.map(item => item?.options)) : options;
  }

  onOpen(isOpen) {
    if (isOpen) {
      this.scrollTopBeforeSelection = undefined;
    }
    if (!isOpen && this.to.componentVersion) {
      this.setPreviousValuesList();
      this.checkRemoveAllHiddenToDisplay();
    }
    this.updatePanelStyle();
  }

  private updatePanelStyle() {
    addClassesToCdkPaneElement('ice-translateY-21-i');
  }

  private setPreviousValuesList() {
    this.previousValues = filter(this.getAllOptions(this.allOptions), option => this.formControl?.value?.includes(option.value)).sort((a: any, b: any) =>
      a?.label > b?.label ? 1 : -1,
    );
    this.setValidOptions(this.allOptions);
  }

  private getValidOptions(options) {
    return options;
  }

  private checkAllHiddenToDisplay(options: any): boolean {
    if (this.to.componentVersion && isArray(options) && !countBy(options, 'hide').false) {
      options?.forEach(option => (option.hide = false));
      return true;
    }
    return false;
  }

  private checkRemoveAllHiddenToDisplay() {
    if (this.areAlloptionsHidden(this.validOptions) && this.areAlloptionsHidden(this.previousValues)) {
      this.removeHideOptions();
    }
  }

  private areAlloptionsHidden(optionToCheck: any[]) {
    return every(optionToCheck, option => option?.options?.hide || option.hide);
  }

  private removeHideOptions() {
    this.removeHideOptionsGroup(this.validOptions);
    this.removeHideOptionsGroup(this.previousValues);
  }

  private removeHideOptionsGroup(optionsToClean: any[]) {
    optionsToClean?.forEach(option => {
      option.hide = false;
      option.options?.forEach(child => (child.hide = false));
    });
  }

  private setValidOptions(options) {
    this.validOptions = this.getValidOptions(options);
    this.filterOptions(this.searchInput?.nativeElement);
  }

  private preventAutoScrollJump() {
    this.formFieldControl.openedChange.subscribe(open => {
      if (open) {
        this.formFieldControl?.panel?.nativeElement?.addEventListener('scroll', event => (this.scrollTopBeforeSelection = event.target.scrollTop));
      }
    });

    this.formFieldControl.optionSelectionChanges.subscribe(() => {
      if (this.formFieldControl?.panel?.nativeElement) {
        this.formFieldControl.panel.nativeElement.scrollTop = this.scrollTopBeforeSelection;
      }
    });
  }

  getClass(item?: any) {
    return `${this.to.optgroupClass || ''} ${this.to.componentVersion ? ' primary-checkbox' : ''} ${!!item?.hide ? ' hide' : ''}`;
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
