import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SearchService } from 'services/search/search.service';
import { StorageService } from 'services/storage/storage.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { StepType } from '../stepper-config';
import { UserInformationStep } from './steps/user-information.step';
import { UserRolesOrganizationStep } from './steps/user-roles-organization.step';

export class UserSteps {
  static getSteps(
    translate: TranslateService,
    translationLoader: FuseTranslationLoaderService,
    store: Store<fromRoot.RootState>,
    storeNewItem: Store<fromForm.NewSectionItemState>,
    fieldValidatorService: FieldValidatorService,
    searchService: SearchService,
    dialog: MatDialog,
    permissionsService: PermissionsService,
    storageService?: StorageService,
    featureFlagService?: FeatureFlagService,
  ): StepType[] {
    return [
      new UserRolesOrganizationStep(translate, translationLoader, store, fieldValidatorService, featureFlagService).getStep(translate),
      new UserInformationStep(fieldValidatorService, store).getStep(translate),
    ];
  }
}
