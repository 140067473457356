import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { column, IpDetailCleaned, IpUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { FormsAndTables } from '@ice/dynamic-components/forms-and-tables/forms-and-tables';
import { ErrorsUtils } from '@ice/utils/api-responses/errors.utils';
import { hasItems } from '@ice/utils/hasItems';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import * as fromApiCalls from 'config/api-calls';
import { IpNature } from 'config/constants/ips.constants';
import { CopyrightIpsDataTable } from 'config/data-table-builders/copyright.ips';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { DialogSearchDatatable } from 'config/dialog-builders/dialog-search-datatable';
import { IpsSearchExpressions, SearchCopyrightIpsForm } from 'config/search-form-builders/search-copyright-ips';
import { SectionsConfig } from 'config/sections-config';
import { IceFacade } from 'facades/ice.facade';
import { clone, get, isArray } from 'lodash';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { delay, filter, map, take, takeUntil } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SearchService } from 'services/search/search.service';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

// TODO: Add Angular decorator.
export class TabIpRelations implements SectionTabConfig {
  private subscriptionEditMode: Subscription;
  private searchCopyrightIpsForm: SearchCopyrightIpsForm;
  private canEditRelations = false;
  errors: string[];
  private addByBaseValue = false;
  private copyrightIp: IpDetailCleaned;
  private unsubscribeAll$ = new Subject();

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    commonApiService: null,
    detailService: null,
    nsService: null,
    private iceFacade: IceFacade,
    fieldValidatorService: null,
    private permissionsService: PermissionsService,
    private searchService: SearchService,
  ) {
    this.searchCopyrightIpsForm = new SearchCopyrightIpsForm(this.translate, this.fuseTranslationLoader);
    this.fuseTranslationLoader.loadTranslations(english);
    this.subscriptionEditMode = this.store.pipe(select(fromRoot.getEditMode)).subscribe((editMode: boolean) => {
      if (editMode) {
        this.canEditRelations = this.permissionsService.can('ips_relations_edit');
      }
    });
    this.store
      .pipe(select(fromRoot.getCopyrightIp))
      .subscribe(ip => {
        this.copyrightIp = ip;
      })
      .unsubscribe();
  }

  onDestroy = () => {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  };

  getConf(): FormsAndTables {
    return {
      tablesAlign: 'column',
      tablesGap: '10px',
      formsAlign: 'flex wrap',
      cardWithDataTableOne: {
        title: this.getTranslate('PARENT_TABLE.', 'TITLE'),
        customClass: 'parent-relationship-table',
        model: this.store.pipe(
          select(fromRoot.getParentRelationsSearchResults),
          map((ips: any) => (isArray(ips) ? ips : ips.items).filter(ip => !ip.deleted)),
        ),
        schema: this.getRelationshipTableSchema('PARENT_TABLE.', IpNature.TYPE_PARENT),
        columnMode: 'flex',
        tableWidth: '100',
        maxHeight: 300,
        actionButtons:
          (this.canEditRelations &&
            this.store.select(fromRoot.getParentRelationsSearchResults).pipe(
              takeUntil(this.unsubscribeAll$),
              map((ips: any) => (isArray(ips) ? ips : ips.items).filter(ip => !ip.deleted)),
              map(
                ips =>
                  (ips.length === 0 && [
                    {
                      icon: 'add',
                      tooltip: this.getTranslate('', 'TOOLTIPS.ADD_RELATION'),
                      class: 'mat-white-icon',
                      buttonClass: 'add-parent-relation',
                      onClick: () => this.openAddDialog(IpNature.TYPE_PARENT, this.copyrightIp),
                    },
                  ]) ||
                  [],
              ),
            )) ||
          of([]),
      },

      cardWithDataTableTwo: {
        title: this.getTranslate('CHILDREN_TABLE.', 'TITLE'),
        customClass: 'children-relationship-table',
        model: this.store.pipe(
          select(fromRoot.getChildrenRelationsSearchResults),
          map((ips: any) => (isArray(ips) ? ips : ips.items).filter(ip => !ip.deleted)),
        ),
        schema: this.getRelationshipTableSchema('CHILDREN_TABLE.', IpNature.TYPE_CHILDREN),
        columnMode: 'flex',
        tableWidth: '100',
        maxHeight: 300,
        actionButtons: this.canEditRelations
          ? of([
              {
                icon: 'add',
                tooltip: this.getTranslate('', 'TOOLTIPS.ADD_RELATION'),
                class: 'mat-white-icon',
                buttonClass: 'add-children-relation',
                onClick: () => this.openAddDialog(IpNature.TYPE_CHILDREN, this.copyrightIp),
              },
            ])
          : of(null),
      },
    };
  }

  private getRelationshipTableSchema(translationBase: string, type: string): Observable<DataTableRow[]> {
    const schema: DataTableRow[] = [
      column(this.getTranslate(translationBase, 'NAME'), 'fullName', 1),
      column(this.getTranslate(translationBase, 'IPI_NAME_NUMBER'), 'ipiNameNumber', 1),
      column(this.getTranslate(translationBase, 'IP_NAME_KEY'), 'nameKey', 1, 'ip-name-key'),
      column(this.getTranslate(translationBase, 'IPI_NAME_TYPE'), 'typeOfName', 1, '', 'tooltipTypeOfName'),
      column(this.getTranslate(translationBase, 'IPI_BASE_NUMBER'), 'baseIpiNumbersFormatted', 1, 'ip-base-ipi-number'),
      { actionButtonIcon: 'link', flexGrow: 0.001, maxWidth: 40, minWidth: 40, resizeable: false, action: event => this.goToIp(event) },
    ];

    if (this.canEditRelations) {
      const editButtons: DataTableRow[] = [
        {
          actionButtonIcon: 'delete',
          flexGrow: 0.001,
          maxWidth: 50,
          minWidth: 50,
          resizeable: false,
          action: row => this.openDeleteDialog(row, type),
          cellClass: 'delete-relation-cell',
        },
      ];
      schema.push(...editButtons);
    }

    return of(schema);
  }

  private getTranslate(cardTranslatePath, fieldTranslatePath) {
    return this.translate.instant(`IPS.RELATIONS.${cardTranslatePath}${fieldTranslatePath}`);
  }

  openAddDialog(ipiType: string, ip: IpDetailCleaned) {
    const nameKey = ip.attributes && ip.attributes.key.replace('ICE:', '');

    const linkedIPs = [...(ip?.childrenRelationshipsData?.items || []), ...(ip?.parentRelationshipsData?.items || [])];
    const baseKey = ip.baseInformation && ip.baseInformation[0] && ip.baseInformation[0].baseId.replace('ICE:', '');
    const copyrightIpsDatatable = new CopyrightIpsDataTable(this.translate, this.fuseTranslationLoader, this.store);
    const extraFilter = [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1 infix-padding-top-0',
            key: 'addByBase',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.translate.instant('IPS.ADD_BY_BASE'),
              required: false,
              change: field => (this.addByBaseValue = field.formControl.value),
            },
            hooks: {
              onInit: field => {
                this.addByBaseValue = field.formControl.value;
              },
              onDestroy: () => {
                this.subscriptionEditMode.unsubscribe();
              },
            },
          },
        ],
      },
    ];

    const dialogSearchAgreement = new DialogSearchDatatable(this.dialog, this.store, this.searchService, this.translate);
    dialogSearchAgreement.openDialog(
      null,
      copyrightIpsDatatable,
      fromApiCalls.searchDialogIps,
      {
        formBuilder: of(this.searchCopyrightIpsForm.getForm(ipiType)),
        model: this.store.pipe(
          select(fromRoot.getSectionSearch),
          map(search => {
            const typedSearch = search[ipiType] || {};
            return clone(typedSearch);
          }),
        ),
        submitEnabled: true,
        submitAvailable: true,
        submitLabel: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.BT_SEARCH'),
        extraFormBuilder: ipiType === IpNature.TYPE_CHILDREN ? extraFilter : [],
      },
      new IpsSearchExpressions(),
      item => {
        if (ipiType === IpNature.TYPE_CHILDREN && !this.addByBaseValue && item?.parentRelations.some(rel => rel.relation === IpNature.GENERAL_RELATION)) {
          this.dialog.closeAll();
          return this.store.dispatch(
            new fromRoot.ShowSnackBar({ message: this.translate.instant('IPS.RELATIONS.MESSAGES.SELECTED_IP_HAS_OWNER'), duration: 2000, icon: 'warning' }),
          );
        }
        this.store.dispatch(new fromRoot.UpdateIpsRelationsSelect({ ipSelected: item, ipiType, baseKey, nameKey, addByBaseValue: this.addByBaseValue }));
        this.dialog.closeAll();
        this.openSpinner();
      },
      null,
      items => {
        return (
          (hasItems(items) &&
            clone(
              items.filter(
                ipItem =>
                  (ipItem.baseKey !== baseKey || ipItem.nameKey !== nameKey) && // don't show self references and already linked ips in the search results
                  !linkedIPs.find(linkedIp => linkedIp.baseKey === ipItem.baseKey && linkedIp.ipiNameNumber === ipItem.ipiNameNumber && !linkedIp.deleted),
              ),
            )) ||
          []
        );
      },
    );
  }

  openDeleteDialog(row, ipiType) {
    this.store
      .pipe(select(fromRoot.getCopyrightIp))
      .subscribe(ip => {
        const nameKey = ip.attributes && ip.attributes.key && ip.attributes.key.replace('ICE:', '');
        const baseKey = ip.baseInformation && ip.baseInformation[0] && ip.baseInformation[0].baseId && ip.baseInformation[0].baseId.replace('ICE:', '');

        const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
        const dialogRefInfo = dialogInfo.openDialog(
          `${this.translate.instant('IPS.RELATIONS.DIALOG_TITLES.REMOVE_RELATION')} - ${row.nameKey}`,
          this.translate.instant('IPS.RELATIONS.MESSAGES.CHECK_DELETE'),
          () => {
            const toDeleteRelation = IpUtils.formatIpRelationObject(ipiType, baseKey, nameKey, row.baseKey, row.nameKey);
            const key = (ipiType === IpNature.TYPE_CHILDREN && 'childrenRelationshipsData') || 'parentRelationshipsData';
            const cleanerData = { relation: toDeleteRelation, type: 'delete', key, fullName: row.fullName };
            const labels = { id: (ipiType === IpNature.TYPE_CHILDREN && toDeleteRelation.partyNameId) || toDeleteRelation.otherNameId };
            this.store.dispatch(
              new fromRoot.StartApiCall({
                apiCall: fromApiCalls.getIpRelationshipData,
                apiCallData: { cleanerData, labels },
                callBack: () => this.store.dispatch(new fromRoot.UpdateField({ object: ipiType, newValue: row, type: 'delete' })),
              }),
            );
            this.dialog.closeAll();
            this.openSpinner();
          },
          () => dialogRefInfo.close(),
          this.translate.instant('NOTES.BUTTONS.REMOVE'),
          null,
          'delete-relation-btn',
        );
      })
      .unsubscribe();
  }

  goToIp(event) {
    this.store.dispatch(
      new fromRoot.OpenNewTab({
        path: [`copyright/${SectionsConfig.IPS.name}/${event.key}`],
      }),
    );
  }

  getDialogMessageForm() {
    return [
      {
        fieldGroupClassName: 'display-flex-col',
        fieldGroup: [
          {
            template: `<h3>${this.translate.instant('IPS.RELATIONS.MESSAGES.CHECK_DELETE')}</h3>`,
          },
        ],
      },
    ];
  }

  handleDialogClose(dialogRef) {
    this.store
      .pipe(
        select(fromRoot.getCopyrightResponse),
        filter(response => !response || (response && !response.multipleCalls)),
        delay(1000),
        take(1),
      )
      .subscribe(response => {
        dialogRef.close();
      });
  }

  openSpinner() {
    const dialogInfo = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialogInfo',
        layouts: [
          {
            title: of(this.translate.instant('ROOT.SENDING_DATA')),
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    className: 'dialog-wrapper-auto',
                    config: {
                      loading: this.store.pipe(
                        select(fromRoot.getCopyrightResponse),
                        map(response => {
                          if (response && response.results) {
                            return false;
                          } else {
                            return true;
                          }
                        }),
                      ),
                      loadingText: of(this.translate.instant('ROOT.SENDING_DATA')),
                      response: this.store.pipe(
                        select(fromRoot.getCopyrightResponse),
                        map(response => {
                          let text: string;
                          if (!ErrorsUtils.isResponseError(response)) {
                            this.store.dispatch(new fromRoot.CleanResponse());
                          }
                          return { text };
                        }),
                      ),
                      errors: this.store.pipe(
                        select(fromRoot.getCopyrightResponse),
                        map(response => ErrorsUtils.getResponseErrors(response, this.translate)),
                      ),
                      responseButtons: of([]),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });

    dialogInfo
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(new fromRoot.CleanResponse());
      });

    this.handleDialogClose(dialogInfo);
    dialogInfo.disableClose = true;
  }
}
