import { TerritoryGroup } from 'config/constants/territories.constants';
import { OptionsGroup } from 'models/options-group';

export const claimFilterCountryList = [
  {
    value: '56',
    label: 'BELGIUM',
  },
  {
    value: '2110',
    label: 'BENELUX',
  },
  {
    value: '2111',
    label: 'BRITISH ISLES',
  },
  {
    value: '208',
    label: 'DENMARK',
  },
  {
    value: '233',
    label: 'ESTONIA',
  },
  {
    value: '246',
    label: 'FINLAND',
  },
  {
    value: '352',
    label: 'ICELAND',
  },
  {
    value: '372',
    label: 'IRELAND',
  },
  {
    value: '428',
    label: 'LATVIA',
  },
  {
    value: '440',
    label: 'LITHUANIA',
  },
  {
    value: '442',
    label: 'LUXEMBOURG',
  },
  {
    value: '528',
    label: 'NETHERLANDS',
  },
  {
    value: '2127',
    label: 'NORDIC COUNTRIES',
  },
  {
    value: '578',
    label: 'NORWAY',
  },
  {
    value: '2131',
    label: 'SCANDINAVIA',
  },
  {
    value: '752',
    label: 'SWEDEN',
  },
  {
    value: '826',
    label: 'UNITED KINGDOM',
  },
];

export const countryGroups: OptionsGroup[] = [
  {
    header: TerritoryGroup.ICE_TERRITORIES,
    options: [
      {
        value: '56',
        label: 'BELGIUM',
      },
      {
        value: '196',
        label: 'CYPRUS',
      },
      {
        value: '208',
        label: 'DENMARK',
      },
      {
        value: '246',
        label: 'FINLAND',
      },
      {
        value: '276',
        label: 'GERMANY',
      },
      {
        value: '528',
        label: 'NETHERLANDS',
      },
      {
        value: '578',
        label: 'NORWAY',
      },
      {
        value: '752',
        label: 'SWEDEN',
      },
      {
        value: '826',
        label: 'UNITED KINGDOM',
      },
    ],
  },
  {
    header: 'Other Countries',
    options: [
      {
        value: '4',
        label: 'AFGHANISTAN',
      },
      {
        value: '8',
        label: 'ALBANIA',
      },
      {
        value: '12',
        label: 'ALGERIA',
      },
      {
        value: '20',
        label: 'ANDORRA',
      },
      {
        value: '24',
        label: 'ANGOLA',
      },
      {
        value: '28',
        label: 'ANTIGUA AND BARBUDA',
      },
      {
        value: '2103',
        label: 'ANTILLES',
      },
      {
        value: '32',
        label: 'ARGENTINA',
      },
      {
        value: '51',
        label: 'ARMENIA',
      },
      {
        value: '36',
        label: 'AUSTRALIA',
      },
      {
        value: '40',
        label: 'AUSTRIA',
      },
      {
        value: '31',
        label: 'AZERBAIJAN',
      },
      {
        value: '44',
        label: 'BAHAMAS',
      },
      {
        value: '48',
        label: 'BAHRAIN',
      },
      {
        value: '50',
        label: 'BANGLADESH',
      },
      {
        value: '52',
        label: 'BARBADOS',
      },
      {
        value: '2109',
        label: 'BALTIC STATES',
      },
      {
        value: '2110',
        label: 'BENELUX',
      },
      {
        value: '2111',
        label: 'BRITISH ISLES',
      },
      {
        value: '112',
        label: 'BELARUS',
      },
      {
        value: '84',
        label: 'BELIZE',
      },
      {
        value: '204',
        label: 'BENIN',
      },
      {
        value: '64',
        label: 'BHUTAN',
      },
      {
        value: '68',
        label: 'BOLIVIA',
      },
      {
        value: '70',
        label: 'BOSNIA AND HERZEGOVINA',
      },
      {
        value: '72',
        label: 'BOTSWANA',
      },
      {
        value: '76',
        label: 'BRAZIL',
      },
      {
        value: '2112',
        label: 'BRITISH WEST INDIES',
      },
      {
        value: '96',
        label: 'BRUNEI DARUSSALAM',
      },
      {
        value: '100',
        label: 'BULGARIA',
      },
      {
        value: '854',
        label: 'BURKINA FASO',
      },
      {
        value: '104',
        label: 'BURMA',
      },
      {
        value: '108',
        label: 'BURUNDI',
      },
      {
        value: '116',
        label: 'CAMBODIA',
      },
      {
        value: '120',
        label: 'CAMEROON',
      },
      {
        value: '124',
        label: 'CANADA',
      },
      {
        value: '132',
        label: 'CAPE VERDE',
      },
      {
        value: '140',
        label: 'CENTRAL AFRICAN REPUBLIC',
      },
      {
        value: '148',
        label: 'CHAD',
      },
      {
        value: '152',
        label: 'CHILE',
      },
      {
        value: '156',
        label: 'CHINA',
      },
      {
        value: '170',
        label: 'COLOMBIA',
      },
      {
        value: '174',
        label: 'COMOROS',
      },
      {
        value: '178',
        label: 'CONGO',
      },
      {
        value: '180',
        label: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
      },
      {
        value: '188',
        label: 'COSTA RICA',
      },
      {
        value: '384',
        label: "COTE D'IVOIRE",
      },
      {
        value: '191',
        label: 'CROATIA',
      },
      {
        value: '192',
        label: 'CUBA',
      },
      {
        value: '203',
        label: 'CZECH REPUBLIC',
      },
      {
        value: '200',
        label: 'CZECHOSLOVAKIA',
      },
      {
        value: '262',
        label: 'DJIBOUTI',
      },
      {
        value: '212',
        label: 'DOMINICA',
      },
      {
        value: '214',
        label: 'DOMINICAN REPUBLIC',
      },
      {
        value: '218',
        label: 'ECUADOR',
      },
      {
        value: '818',
        label: 'EGYPT',
      },
      {
        value: '222',
        label: 'EL SALVADOR',
      },
      {
        value: '226',
        label: 'EQUATORIAL GUINEA',
      },
      {
        value: '232',
        label: 'ERITREA',
      },
      {
        value: '231',
        label: 'ETHIOPIA',
      },
      {
        value: '242',
        label: 'FIJI',
      },
      {
        value: '250',
        label: 'FRANCE',
      },
      {
        value: '258',
        label: 'FRENCH POLYNESIA',
      },
      {
        value: '266',
        label: 'GABON',
      },
      {
        value: '270',
        label: 'GAMBIA',
      },
      {
        value: '268',
        label: 'GEORGIA',
      },
      {
        value: '288',
        label: 'GHANA',
      },
      {
        value: '300',
        label: 'GREECE',
      },
      {
        value: '308',
        label: 'GRENADA',
      },
      {
        value: '320',
        label: 'GUATEMALA',
      },
      {
        value: '324',
        label: 'GUINEA',
      },
      {
        value: '624',
        label: 'GUINEA-BISSAU',
      },
      {
        value: '328',
        label: 'GUYANA',
      },
      {
        value: '332',
        label: 'HAITI',
      },
      {
        value: '336',
        label: 'HOLY SEE (VATICAN CITY STATE)',
      },
      {
        value: '340',
        label: 'HONDURAS',
      },
      {
        value: '344',
        label: 'HONG KONG',
      },
      {
        value: '348',
        label: 'HUNGARY',
      },
      {
        value: '352',
        label: 'ICELAND',
      },
      {
        value: '356',
        label: 'INDIA',
      },
      {
        value: '360',
        label: 'INDONESIA',
      },
      {
        value: '364',
        label: 'IRAN, ISLAMIC REPUBLIC OF',
      },
      {
        value: '368',
        label: 'IRAQ',
      },
      {
        value: '372',
        label: 'IRELAND',
      },
      {
        value: '376',
        label: 'ISRAEL',
      },
      {
        value: '380',
        label: 'ITALY',
      },
      {
        value: '388',
        label: 'JAMAICA',
      },
      {
        value: '392',
        label: 'JAPAN',
      },
      {
        value: '400',
        label: 'JORDAN',
      },
      {
        value: '398',
        label: 'KAZAKHSTAN',
      },
      {
        value: '404',
        label: 'KENYA',
      },
      {
        value: '296',
        label: 'KIRIBATI',
      },
      {
        value: '408',
        label: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
      },
      {
        value: '410',
        label: 'KOREA, REPUBLIC OF',
      },
      {
        value: '414',
        label: 'KUWAIT',
      },
      {
        value: '417',
        label: 'KYRGYZSTAN',
      },
      {
        value: '418',
        label: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
      },
      {
        value: '428',
        label: 'LATVIA',
      },
      {
        value: '422',
        label: 'LEBANON',
      },
      {
        value: '426',
        label: 'LESOTHO',
      },
      {
        value: '430',
        label: 'LIBERIA',
      },
      {
        value: '434',
        label: 'LIBYAN ARAB JAMAHIRIYA',
      },
      {
        value: '438',
        label: 'LIECHTENSTEIN',
      },
      {
        value: '440',
        label: 'LITHUANIA',
      },
      {
        value: '442',
        label: 'LUXEMBOURG',
      },
      {
        value: '446',
        label: 'MACAO',
      },
      {
        value: '807',
        label: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
      },
      {
        value: '450',
        label: 'MADAGASCAR',
      },
      {
        value: '454',
        label: 'MALAWI',
      },
      {
        value: '458',
        label: 'MALAYSIA',
      },
      {
        value: '462',
        label: 'MALDIVES',
      },
      {
        value: '466',
        label: 'MALI',
      },
      {
        value: '470',
        label: 'MALTA',
      },
      {
        value: '584',
        label: 'MARSHALL ISLANDS',
      },
      {
        value: '478',
        label: 'MAURITANIA',
      },
      {
        value: '480',
        label: 'MAURITIUS',
      },
      {
        value: '484',
        label: 'MEXICO',
      },
      {
        value: '583',
        label: 'MICRONESIA, FEDERATED STATES OF',
      },
      {
        value: '498',
        label: 'MOLDOVA, REPUBLIC OF',
      },
      {
        value: '492',
        label: 'MONACO',
      },
      {
        value: '496',
        label: 'MONGOLIA',
      },
      {
        value: '499',
        label: 'MONTENEGRO',
      },
      {
        value: '504',
        label: 'MOROCCO',
      },
      {
        value: '508',
        label: 'MOZAMBIQUE',
      },
      {
        value: '104',
        label: 'MYANMAR',
      },
      {
        value: '516',
        label: 'NAMIBIA',
      },
      {
        value: '520',
        label: 'NAURU',
      },
      {
        value: '524',
        label: 'NEPAL',
      },
      {
        value: '540',
        label: 'NEW CALEDONIA',
      },
      {
        value: '554',
        label: 'NEW ZEALAND',
      },
      {
        value: '558',
        label: 'NICARAGUA',
      },
      {
        value: '562',
        label: 'NIGER',
      },
      {
        value: '566',
        label: 'NIGERIA',
      },
      {
        value: '2127',
        label: 'NORDIC COUNTRIES',
      },
      {
        value: '512',
        label: 'OMAN',
      },
      {
        value: '586',
        label: 'PAKISTAN',
      },
      {
        value: '585',
        label: 'PALAU',
      },
      {
        value: '591',
        label: 'PANAMA',
      },
      {
        value: '598',
        label: 'PAPUA NEW GUINEA',
      },
      {
        value: '600',
        label: 'PARAGUAY',
      },
      {
        value: '604',
        label: 'PERU',
      },
      {
        value: '608',
        label: 'PHILIPPINES',
      },
      {
        value: '616',
        label: 'POLAND',
      },
      {
        value: '620',
        label: 'PORTUGAL',
      },
      {
        value: '630',
        label: 'PUERTO RICO',
      },
      {
        value: '634',
        label: 'QATAR',
      },
      {
        value: '642',
        label: 'ROMANIA',
      },
      {
        value: '643',
        label: 'RUSSIAN FEDERATION',
      },
      {
        value: '646',
        label: 'RWANDA',
      },
      {
        value: '659',
        label: 'SAINT KITTS AND NEVIS',
      },
      {
        value: '662',
        label: 'SAINT LUCIA',
      },
      {
        value: '670',
        label: 'SAINT VINCENT AND THE GRENADINES',
      },
      {
        value: '882',
        label: 'SAMOA',
      },
      {
        value: '674',
        label: 'SAN MARINO',
      },
      {
        value: '678',
        label: 'SAO TOME AND PRINCIPE',
      },
      {
        value: '682',
        label: 'SAUDI ARABIA',
      },
      {
        value: '2131',
        label: 'SCANDINAVIA',
      },
      {
        value: '686',
        label: 'SENEGAL',
      },
      {
        value: '688',
        label: 'SERBIA',
      },
      {
        value: '891',
        label: 'SERBIA AND MONTENEGRO',
      },
      {
        value: '690',
        label: 'SEYCHELLES',
      },
      {
        value: '694',
        label: 'SIERRA LEONE',
      },
      {
        value: '702',
        label: 'SINGAPORE',
      },
      {
        value: '703',
        label: 'SLOVAKIA',
      },
      {
        value: '705',
        label: 'SLOVENIA',
      },
      {
        value: '90',
        label: 'SOLOMON ISLANDS',
      },
      {
        value: '706',
        label: 'SOMALIA',
      },
      {
        value: '710',
        label: 'SOUTH AFRICA',
      },
      {
        value: '728',
        label: 'SOUTH SUDAN',
      },
      {
        value: '724',
        label: 'SPAIN',
      },
      {
        value: '144',
        label: 'SRI LANKA',
      },
      {
        value: '729',
        label: 'SUDAN',
      },
      {
        value: '740',
        label: 'SURINAME',
      },
      {
        value: '748',
        label: 'SWAZILAND',
      },
      {
        value: '756',
        label: 'SWITZERLAND',
      },
      {
        value: '760',
        label: 'SYRIAN ARAB REPUBLIC',
      },
      {
        value: '158',
        label: 'TAIWAN, PROVINCE OF CHINA',
      },
      {
        value: '762',
        label: 'TAJIKISTAN',
      },
      {
        value: '834',
        label: 'TANZANIA, UNITED REPUBLIC OF',
      },
      {
        value: '764',
        label: 'THAILAND',
      },
      {
        value: '626',
        label: 'TIMOR-LESTE',
      },
      {
        value: '768',
        label: 'TOGO',
      },
      {
        value: '776',
        label: 'TONGA',
      },
      {
        value: '780',
        label: 'TRINIDAD AND TOBAGO',
      },
      {
        value: '788',
        label: 'TUNISIA',
      },
      {
        value: '792',
        label: 'TURKEY',
      },
      {
        value: '795',
        label: 'TURKMENISTAN',
      },
      {
        value: '798',
        label: 'TUVALU',
      },
      {
        value: '800',
        label: 'UGANDA',
      },
      {
        value: '804',
        label: 'UKRAINE',
      },
      {
        value: '784',
        label: 'UNITED ARAB EMIRATES',
      },
      {
        value: '840',
        label: 'UNITED STATES',
      },
      {
        value: '858',
        label: 'URUGUAY',
      },
      {
        value: '860',
        label: 'UZBEKISTAN',
      },
      {
        value: '548',
        label: 'VANUATU',
      },
      {
        value: '862',
        label: 'VENEZUELA',
      },
      {
        value: '704',
        label: 'VIETNAM',
      },
      {
        value: '887',
        label: 'YEMEN',
      },
      {
        value: '720',
        label: 'YEMEN, DEMOCRATIC',
      },
      {
        value: '894',
        label: 'ZAMBIA',
      },
      {
        value: '716',
        label: 'ZIMBABWE',
      },
    ],
  },
];

export enum CountryHotkeys {
  BELGIUM = 'BELGIUM',
  DENMARK = 'DENMARK',
  FINLAND = 'FINLAND',
  GERMANY = 'GERMANY',
  NETHERLANDS = 'NETHERLANDS',
  NORWAY = 'NORWAY', // key Y
  SWEDEN = 'SWEDEN',
  UNITED_KINGDOM = 'UNITED KINGDOM',
  ALL_COUNTRIES = 'ALL_COUNTRIES',
}
export const countryModifiers = ['+', '-', 'I', 'E'];

export const iceCountries = [
  {
    value: '2109',
    label: 'BALTIC STATES',
  },
  {
    value: '56',
    label: 'BELGIUM',
  },
  {
    value: '2110',
    label: 'BENELUX',
  },
  {
    value: '2111',
    label: 'BRITISH ISLES',
  },
  {
    value: '196',
    label: 'CYPRUS',
  },
  {
    value: '208',
    label: 'DENMARK',
  },
  {
    value: '233',
    label: 'ESTONIA',
  },
  {
    value: '246',
    label: 'FINLAND',
  },
  {
    value: '276',
    label: 'GERMANY',
  },
  {
    value: '352',
    label: 'ICELAND',
  },
  {
    value: '372',
    label: 'IRELAND',
  },
  {
    value: '428',
    label: 'LATVIA',
  },
  {
    value: '440',
    label: 'LITHUANIA',
  },
  {
    value: '442',
    label: 'LUXEMBOURG',
  },
  {
    value: '470',
    label: 'MALTA',
  },
  {
    value: '528',
    label: 'NETHERLANDS',
  },
  {
    value: '2127',
    label: 'NORDIC COUNTRIES',
  },
  {
    value: '578',
    label: 'NORWAY',
  },
  {
    value: '2131',
    label: 'SCANDINAVIA',
  },
  {
    value: '752',
    label: 'SWEDEN',
  },
  {
    value: '826',
    label: 'UNITED KINGDOM',
  },
];

export const notIceCountries = [
  {
    value: '4',
    label: 'AFGHANISTAN',
  },
  {
    value: '8',
    label: 'ALBANIA',
  },
  {
    value: '12',
    label: 'ALGERIA',
  },
  {
    value: '20',
    label: 'ANDORRA',
  },
  {
    value: '24',
    label: 'ANGOLA',
  },
  {
    value: '28',
    label: 'ANTIGUA AND BARBUDA',
  },
  {
    value: '2103',
    label: 'ANTILLES',
  },
  {
    value: '32',
    label: 'ARGENTINA',
  },
  {
    value: '51',
    label: 'ARMENIA',
  },
  {
    value: '36',
    label: 'AUSTRALIA',
  },
  {
    value: '40',
    label: 'AUSTRIA',
  },
  {
    value: '31',
    label: 'AZERBAIJAN',
  },
  {
    value: '44',
    label: 'BAHAMAS',
  },
  {
    value: '48',
    label: 'BAHRAIN',
  },
  {
    value: '50',
    label: 'BANGLADESH',
  },
  {
    value: '52',
    label: 'BARBADOS',
  },
  {
    value: '112',
    label: 'BELARUS',
  },
  {
    value: '84',
    label: 'BELIZE',
  },
  {
    value: '204',
    label: 'BENIN',
  },
  {
    value: '64',
    label: 'BHUTAN',
  },
  {
    value: '68',
    label: 'BOLIVIA',
  },
  {
    value: '70',
    label: 'BOSNIA AND HERZEGOVINA',
  },
  {
    value: '72',
    label: 'BOTSWANA',
  },
  {
    value: '76',
    label: 'BRAZIL',
  },
  {
    value: '2112',
    label: 'BRITISH WEST INDIES',
  },
  {
    value: '96',
    label: 'BRUNEI DARUSSALAM',
  },
  {
    value: '100',
    label: 'BULGARIA',
  },
  {
    value: '854',
    label: 'BURKINA FASO',
  },
  {
    value: '104',
    label: 'BURMA',
  },
  {
    value: '108',
    label: 'BURUNDI',
  },
  {
    value: '116',
    label: 'CAMBODIA',
  },
  {
    value: '120',
    label: 'CAMEROON',
  },
  {
    value: '124',
    label: 'CANADA',
  },
  {
    value: '132',
    label: 'CAPE VERDE',
  },
  {
    value: '140',
    label: 'CENTRAL AFRICAN REPUBLIC',
  },
  {
    value: '148',
    label: 'CHAD',
  },
  {
    value: '152',
    label: 'CHILE',
  },
  {
    value: '156',
    label: 'CHINA',
  },
  {
    value: '170',
    label: 'COLOMBIA',
  },
  {
    value: '174',
    label: 'COMOROS',
  },
  {
    value: '178',
    label: 'CONGO',
  },
  {
    value: '180',
    label: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
  },
  {
    value: '188',
    label: 'COSTA RICA',
  },
  {
    value: '384',
    label: "COTE D'IVOIRE",
  },
  {
    value: '191',
    label: 'CROATIA',
  },
  {
    value: '192',
    label: 'CUBA',
  },
  {
    value: '196',
    label: 'CYPRUS',
  },
  {
    value: '203',
    label: 'CZECH REPUBLIC',
  },
  {
    value: '200',
    label: 'CZECHOSLOVAKIA',
  },
  {
    value: '262',
    label: 'DJIBOUTI',
  },
  {
    value: '212',
    label: 'DOMINICA',
  },
  {
    value: '214',
    label: 'DOMINICAN REPUBLIC',
  },
  {
    value: '218',
    label: 'ECUADOR',
  },
  {
    value: '818',
    label: 'EGYPT',
  },
  {
    value: '222',
    label: 'EL SALVADOR',
  },
  {
    value: '226',
    label: 'EQUATORIAL GUINEA',
  },
  {
    value: '232',
    label: 'ERITREA',
  },
  {
    value: '233',
    label: 'ESTONIA',
  },
  {
    value: '231',
    label: 'ETHIOPIA',
  },
  {
    value: '242',
    label: 'FIJI',
  },
  {
    value: '250',
    label: 'FRANCE',
  },
  {
    value: '258',
    label: 'FRENCH POLYNESIA',
  },
  {
    value: '266',
    label: 'GABON',
  },
  {
    value: '270',
    label: 'GAMBIA',
  },
  {
    value: '268',
    label: 'GEORGIA',
  },
  {
    value: '288',
    label: 'GHANA',
  },
  {
    value: '300',
    label: 'GREECE',
  },
  {
    value: '308',
    label: 'GRENADA',
  },
  {
    value: '320',
    label: 'GUATEMALA',
  },
  {
    value: '324',
    label: 'GUINEA',
  },
  {
    value: '624',
    label: 'GUINEA-BISSAU',
  },
  {
    value: '328',
    label: 'GUYANA',
  },
  {
    value: '332',
    label: 'HAITI',
  },
  {
    value: '336',
    label: 'HOLY SEE (VATICAN CITY STATE)',
  },
  {
    value: '340',
    label: 'HONDURAS',
  },
  {
    value: '344',
    label: 'HONG KONG',
  },
  {
    value: '348',
    label: 'HUNGARY',
  },
  {
    value: '356',
    label: 'INDIA',
  },
  {
    value: '360',
    label: 'INDONESIA',
  },
  {
    value: '364',
    label: 'IRAN, ISLAMIC REPUBLIC OF',
  },
  {
    value: '368',
    label: 'IRAQ',
  },
  {
    value: '376',
    label: 'ISRAEL',
  },
  {
    value: '380',
    label: 'ITALY',
  },
  {
    value: '388',
    label: 'JAMAICA',
  },
  {
    value: '392',
    label: 'JAPAN',
  },
  {
    value: '400',
    label: 'JORDAN',
  },
  {
    value: '398',
    label: 'KAZAKHSTAN',
  },
  {
    value: '404',
    label: 'KENYA',
  },
  {
    value: '296',
    label: 'KIRIBATI',
  },
  {
    value: '408',
    label: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
  },
  {
    value: '410',
    label: 'KOREA, REPUBLIC OF',
  },
  {
    value: '414',
    label: 'KUWAIT',
  },
  {
    value: '417',
    label: 'KYRGYZSTAN',
  },
  {
    value: '418',
    label: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  {
    value: '422',
    label: 'LEBANON',
  },
  {
    value: '426',
    label: 'LESOTHO',
  },
  {
    value: '430',
    label: 'LIBERIA',
  },
  {
    value: '434',
    label: 'LIBYAN ARAB JAMAHIRIYA',
  },
  {
    value: '438',
    label: 'LIECHTENSTEIN',
  },
  {
    value: '442',
    label: 'LUXEMBOURG',
  },
  {
    value: '446',
    label: 'MACAO',
  },
  {
    value: '807',
    label: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
  },
  {
    value: '450',
    label: 'MADAGASCAR',
  },
  {
    value: '454',
    label: 'MALAWI',
  },
  {
    value: '458',
    label: 'MALAYSIA',
  },
  {
    value: '462',
    label: 'MALDIVES',
  },
  {
    value: '466',
    label: 'MALI',
  },
  {
    value: '584',
    label: 'MARSHALL ISLANDS',
  },
  {
    value: '478',
    label: 'MAURITANIA',
  },
  {
    value: '480',
    label: 'MAURITIUS',
  },
  {
    value: '484',
    label: 'MEXICO',
  },
  {
    value: '583',
    label: 'MICRONESIA, FEDERATED STATES OF',
  },
  {
    value: '498',
    label: 'MOLDOVA, REPUBLIC OF',
  },
  {
    value: '492',
    label: 'MONACO',
  },
  {
    value: '496',
    label: 'MONGOLIA',
  },
  {
    value: '499',
    label: 'MONTENEGRO',
  },
  {
    value: '504',
    label: 'MOROCCO',
  },
  {
    value: '508',
    label: 'MOZAMBIQUE',
  },
  {
    value: '104',
    label: 'MYANMAR',
  },
  {
    value: '516',
    label: 'NAMIBIA',
  },
  {
    value: '520',
    label: 'NAURU',
  },
  {
    value: '524',
    label: 'NEPAL',
  },
  {
    value: '540',
    label: 'NEW CALEDONIA',
  },
  {
    value: '554',
    label: 'NEW ZEALAND',
  },
  {
    value: '558',
    label: 'NICARAGUA',
  },
  {
    value: '562',
    label: 'NIGER',
  },
  {
    value: '566',
    label: 'NIGERIA',
  },
  {
    value: '512',
    label: 'OMAN',
  },
  {
    value: '586',
    label: 'PAKISTAN',
  },
  {
    value: '585',
    label: 'PALAU',
  },
  {
    value: '591',
    label: 'PANAMA',
  },
  {
    value: '598',
    label: 'PAPUA NEW GUINEA',
  },
  {
    value: '600',
    label: 'PARAGUAY',
  },
  {
    value: '604',
    label: 'PERU',
  },
  {
    value: '608',
    label: 'PHILIPPINES',
  },
  {
    value: '616',
    label: 'POLAND',
  },
  {
    value: '620',
    label: 'PORTUGAL',
  },
  {
    value: '630',
    label: 'PUERTO RICO',
  },
  {
    value: '634',
    label: 'QATAR',
  },
  {
    value: '642',
    label: 'ROMANIA',
  },
  {
    value: '643',
    label: 'RUSSIAN FEDERATION',
  },
  {
    value: '646',
    label: 'RWANDA',
  },
  {
    value: '659',
    label: 'SAINT KITTS AND NEVIS',
  },
  {
    value: '662',
    label: 'SAINT LUCIA',
  },
  {
    value: '670',
    label: 'SAINT VINCENT AND THE GRENADINES',
  },
  {
    value: '882',
    label: 'SAMOA',
  },
  {
    value: '674',
    label: 'SAN MARINO',
  },
  {
    value: '678',
    label: 'SAO TOME AND PRINCIPE',
  },
  {
    value: '682',
    label: 'SAUDI ARABIA',
  },
  {
    value: '686',
    label: 'SENEGAL',
  },
  {
    value: '688',
    label: 'SERBIA',
  },
  {
    value: '891',
    label: 'SERBIA AND MONTENEGRO',
  },
  {
    value: '690',
    label: 'SEYCHELLES',
  },
  {
    value: '694',
    label: 'SIERRA LEONE',
  },
  {
    value: '702',
    label: 'SINGAPORE',
  },
  {
    value: '703',
    label: 'SLOVAKIA',
  },
  {
    value: '705',
    label: 'SLOVENIA',
  },
  {
    value: '90',
    label: 'SOLOMON ISLANDS',
  },
  {
    value: '706',
    label: 'SOMALIA',
  },
  {
    value: '710',
    label: 'SOUTH AFRICA',
  },
  {
    value: '728',
    label: 'SOUTH SUDAN',
  },
  {
    value: '724',
    label: 'SPAIN',
  },
  {
    value: '144',
    label: 'SRI LANKA',
  },
  {
    value: '729',
    label: 'SUDAN',
  },
  {
    value: '740',
    label: 'SURINAME',
  },
  {
    value: '748',
    label: 'SWAZILAND',
  },
  {
    value: '756',
    label: 'SWITZERLAND',
  },
  {
    value: '760',
    label: 'SYRIAN ARAB REPUBLIC',
  },
  {
    value: '158',
    label: 'TAIWAN, PROVINCE OF CHINA',
  },
  {
    value: '762',
    label: 'TAJIKISTAN',
  },
  {
    value: '834',
    label: 'TANZANIA, UNITED REPUBLIC OF',
  },
  {
    value: '764',
    label: 'THAILAND',
  },
  {
    value: '626',
    label: 'TIMOR-LESTE',
  },
  {
    value: '768',
    label: 'TOGO',
  },
  {
    value: '776',
    label: 'TONGA',
  },
  {
    value: '780',
    label: 'TRINIDAD AND TOBAGO',
  },
  {
    value: '788',
    label: 'TUNISIA',
  },
  {
    value: '792',
    label: 'TURKEY',
  },
  {
    value: '795',
    label: 'TURKMENISTAN',
  },
  {
    value: '798',
    label: 'TUVALU',
  },
  {
    value: '800',
    label: 'UGANDA',
  },
  {
    value: '804',
    label: 'UKRAINE',
  },
  {
    value: '784',
    label: 'UNITED ARAB EMIRATES',
  },
  {
    value: '840',
    label: 'UNITED STATES',
  },
  {
    value: '858',
    label: 'URUGUAY',
  },
  {
    value: '860',
    label: 'UZBEKISTAN',
  },
  {
    value: '548',
    label: 'VANUATU',
  },
  {
    value: '862',
    label: 'VENEZUELA',
  },
  {
    value: '704',
    label: 'VIETNAM',
  },
  {
    value: '887',
    label: 'YEMEN',
  },
  {
    value: '720',
    label: 'YEMEN, DEMOCRATIC',
  },
  {
    value: '891',
    label: 'YUGOSLAVIA',
  },
  {
    value: '894',
    label: 'ZAMBIA',
  },
  {
    value: '716',
    label: 'ZIMBABWE',
  },
];

export const countryExceptions = [
  { name: 'ANTIGUA+BARBUDA', tisn: 28 },
  { name: 'BOSNIA+HERZEGO', tisn: 70 },
  { name: 'ST.KITTS+NEVIS', tisn: 659 },
  { name: 'S.TOME+PRINCIPE', tisn: 678 },
  { name: 'TRINIDAD+TOBAGO', tisn: 780 },
];
