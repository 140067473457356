import { CopyrightUtils, StringUtils, UsersUtils } from '@ice';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import * as fromApiCalls from 'config/api-calls';
import {
  TITLES_GROUP_SORT,
  UnassignedUserValue,
  XREF_GROUP_EXCEPTIONS,
  XREF_GROUP_SORT,
  allConflictSubTypes,
  allConflictTypes,
  bumaDomesticCodes,
  bumaDomesticHeader,
  conflictAreas,
  conflictSubTypes,
  conflictTypes,
  nonSociety,
  nordicDomestiCodes,
  nordicDomesticHeader,
  standarDomesticCodes,
  standarDomesticHeader,
} from 'config/constants/activity.constants';
import { ICE } from 'config/constants/global.constants';
import { ClaimInfo } from 'config/constants/shares.constants';
import { concat, countBy, difference, differenceBy, each, filter, find, flatten, get, isEqual, map as lodashMap, pick, sortBy, uniq, uniqWith } from 'lodash';
import { SelectOption } from 'models/copyright/formly/formly';
import { OrganizationCaseStatusType } from 'models/copyright/search/user-management-organization-search';
import { DictionaryCodeItem } from 'models/dictionaryCodeItem';
import { OptionsGroup } from 'models/options-group';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import * as fromRoot from 'store/root';

export class ActivityUtils {
  static compareListsAndHighlightDifference(left$: Observable<any[]>, right$: Observable<any[]>, keyToCompare: string, componentDestroyed: Subject<unknown>) {
    return combineLatest([left$, right$]).pipe(
      takeUntil(componentDestroyed),
      map(([left, right]) => this.highlightLeftDifference(left, right, keyToCompare)),
    );
  }

  static highlightLeftDifference(left: any[], right: any[], itemProp: string = 'value', comparator?: (item: any) => string): any[] {
    if (!!left.length) {
      const valuesToUpdate: string[] = differenceBy(left, right || [], comparator || itemProp)?.map(diff => diff[itemProp]);
      return left.map(leftItem => {
        if (valuesToUpdate.includes(leftItem[itemProp])) {
          leftItem = { ...leftItem, highlight: true, rowClass: 'highlight' };
        }
        return leftItem;
      });
    }
    return left;
  }

  static compareListsAndHighlightDifferenceXref(left$: Observable<any[]>, right$: Observable<any[]>, keyToCompare: string): Observable<any[]> {
    return combineLatest([left$, right$]).pipe(
      map(([left, right]) => {
        if (!!left.length) {
          const filterFn = item => CopyrightUtils.getKeySuffix(item[keyToCompare]);
          const splitExceptions = this.splitXrefExceptions(left, right);
          const exceptions = this.highlightLeftDifference(splitExceptions?.exceptions?.left, splitExceptions?.exceptions?.right, keyToCompare, filterFn);
          const regulars = this.highlightLeftDifference(splitExceptions?.regulars?.left, splitExceptions?.regulars?.right, keyToCompare);
          return concat(exceptions, regulars);
        } else {
          return left;
        }
      }),
    );
  }

  static splitXrefExceptions(left: any[], right: any[]): { exceptions: { left: any[]; right: any[] }; regulars: { left: any[]; right: any[] } } {
    const leftExceptionsInitial = this.getExceptionGroup(left, XREF_GROUP_EXCEPTIONS);
    const rightExceptionsInitial = this.getExceptionGroup(right, XREF_GROUP_EXCEPTIONS);
    const leftRegularInitial = difference(left, leftExceptionsInitial);
    const rightRegularInitial = difference(right, rightExceptionsInitial);
    return { exceptions: { left: leftExceptionsInitial, right: rightExceptionsInitial }, regulars: { left: leftRegularInitial, right: rightRegularInitial } };
  }

  static getExceptionGroup(list: any[], exceptionGroup: string[]): any[] {
    return list.filter(item => exceptionGroup.includes(CopyrightUtils.getKeyPrefix(item.value)));
  }

  static sortXref(xrefData$: Observable<any[]>): any[] | Observable<any[]> {
    return xrefData$.pipe(
      map(xrefData => {
        if (xrefData.length) {
          return this.splitXrefSortGroups(xrefData);
        } else {
          return xrefData;
        }
      }),
    );
  }

  static sortTitles(titlesData$: Observable<any[]>): any[] | Observable<any[]> {
    return titlesData$.pipe(
      map(titlesData => {
        if (titlesData.length) {
          return this.splitTitlesSortGroups(titlesData);
        } else {
          return titlesData;
        }
      }),
    );
  }

  static splitXrefSortGroups(xrefData: any[]): any[] {
    let sortData = [];
    const sortFunction = keySort => (sortData = concat(sortData, this.getSortedGroup(difference(xrefData, sortData), keySort)));
    XREF_GROUP_SORT.forEach(sortFunction);
    const otherGroups: string[] = this.getOtherGroups(difference(xrefData, sortData));
    otherGroups.forEach(sortFunction);
    return sortData;
  }

  static getSortedGroup(list: any[], keySort: string): any[] {
    const sortGroup = list.filter(item => CopyrightUtils.getKeyPrefix(item.value) === keySort);
    return CopyrightUtils.sortByPropAndSuffix(sortGroup);
  }

  static splitTitlesSortGroups(titlesData: any[]): any[] {
    const sortFn = (a, b) => (a?.title?.toUpperCase() < b?.title?.toUpperCase() ? -1 : 1);
    let sortData = [];
    TITLES_GROUP_SORT.forEach(keySort => (sortData = concat(sortData, titlesData.filter(item => item.type === keySort)?.sort(sortFn))));
    const otherSortedDataByTitle = difference(titlesData, sortData)?.sort(sortFn);
    const titlesCount = countBy(otherSortedDataByTitle, item => item.title);
    const repeatedTitles = Object.entries(titlesCount)
      .map(([key, value]: [string, number]) => {
        if (value > 1) {
          return key;
        }
      })
      .filter(title => !!title);
    const titlesManaged = [];
    otherSortedDataByTitle.forEach(forEachItem => {
      if (repeatedTitles.includes(forEachItem.title) && !titlesManaged.includes(forEachItem.title)) {
        const itemsWithRepeatedTitles = otherSortedDataByTitle.filter(filterItem => filterItem.title === forEachItem.title);
        titlesManaged.push(forEachItem.title);
        sortData = concat(
          sortData,
          itemsWithRepeatedTitles.sort((a, b) => (a?.type?.toUpperCase() < b?.type?.toUpperCase() ? -1 : 1)),
        );
      } else if (!titlesManaged.includes(forEachItem.title)) {
        sortData.push(forEachItem);
      }
    });
    return sortData.map(data => ({ ...data, title: StringUtils.visualizeAllSpaces(data?.title || '') }));
  }

  static getOtherGroups(list: any[]): string[] {
    return uniq(list.map(item => CopyrightUtils.getKeyPrefix(item.value)))?.sort();
  }

  static getConflictAreaSelectFormlyConfig(translate: TranslateService): FormlyFieldConfig {
    return {
      className: 'flex-1',
      key: 'conflictArea',
      type: 'ice-select',
      modelOptions: {
        updateOn: 'blur',
      },
      defaultValue: flatten(lodashMap(conflictAreas, group => lodashMap(get(group, 'options', []), 'value'))),
      templateOptions: {
        optgroupClass: 'primary-checkbox',
        type: 'text',
        placeholder: translate.instant('ACTIVITY.CONFLICT_AREA'),
        multiple: true,
        options: CopyrightUtils.createSelectGroupFromOptionsGroup(conflictAreas, translate, 'ACTIVITY.CONFLICT_AREA_TYPE'),
      },
    };
  }

  static getSearchAutocompleteUsersFormlyConfig(
    store: Store<fromRoot.RootState>,
    translate: TranslateService,
    fieldIsRequire: boolean = false,
    unassignedValIsNull: boolean = false,
    fieldLabel: string = translate.instant('ACTIVITY.ASSIGNEE'),
  ): FormlyFieldConfig {
    const userIdsOptions$ = new Subject<any[]>();
    store.dispatch(
      new fromRoot.StartApiCall({
        apiCall: fromApiCalls.searchUser,
        apiCallData: { labels: { ns: ICE, searchText: '*' } },
        callBack: () => {
          store
            .select(fromRoot.getAllApiData)
            .subscribe((apiData: any) => {
              const allUsers = lodashMap(get(apiData, 'items', []), user => ({
                ...user,
                fullName: UsersUtils.composeFullName(user),
              }));
              userIdsOptions$.next(allUsers);
              store.dispatch(new fromRoot.SaveAllUsers(allUsers));
            })
            .unsubscribe();
          store.dispatch(new fromRoot.SaveAllApiData(undefined));
        },
      }),
    );
    return {
      className: 'flex-1',
      key: 'userId',
      type: 'ice-autocomplete',
      templateOptions: {
        flex: 100,
        allowAnyValue: true,
        label: fieldLabel,
        options: this.getAssignedUserOptions(translate, userIdsOptions$, unassignedValIsNull),
        required: fieldIsRequire,
      },
    };
  }

  static getAssignedUserOptions(
    translate: TranslateService,
    userIdsOptions$: Subject<any[]>,
    unassignedValIsNull: boolean = false,
    sortProperty: string = 'label',
  ): Observable<SelectOption[]> {
    const unassignedOption = [{ value: unassignedValIsNull ? null : UnassignedUserValue, label: translate.instant('ACTIVITY.UNASSIGNED') }];
    return userIdsOptions$.pipe(
      take(1),
      map(userIdsOptions => {
        const options: SelectOption[] = CopyrightUtils.sortByProperty(
          userIdsOptions.map(item => ({ value: get(item, 'id', ''), label: get(item, 'fullName', '') })),
          sortProperty,
        );
        return concat(unassignedOption, ...options);
      }),
    );
  }

  static getDomesticSocietySelectFormlyConfig(translate: TranslateService): FormlyFieldConfig {
    const nordicDomesticGroup = this.getDomesticGroup();
    const bumaDomesticGroup = this.getBumaGroup();
    const standarDomesticGroup = this.getStandarGroup();
    const domesticSocieties = [nordicDomesticGroup, bumaDomesticGroup, standarDomesticGroup];
    return {
      className: 'flex-1',
      key: 'domesticSociety',
      type: 'ice-select',
      defaultValue: flatten(lodashMap(domesticSocieties, group => lodashMap(get(group, 'options', []), 'value'))),
      templateOptions: {
        type: 'text',
        placeholder: translate.instant('ACTIVITY.DOMESTIC_SOCIETY'),
        multiple: true,
        options: CopyrightUtils.createSelectGroupFromOptionsGroup(domesticSocieties, translate, 'ACTIVITY.DOMESTIC_SOCIETIES'),
        componentVersion: 1,
        filterActive: true,
        selectAllOption: translate.instant('ACTIVITY.ALL_SOCIETIES'),
        selectAllLabel: translate.instant('ACTIVITY.ALL_SOCIETIES'),
        clearAllLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.CLEAR_ALL'),
      },
    };
  }

  static getDomesticGroup(): OptionsGroup {
    return this.getSelectGroup(nordicDomesticHeader, nordicDomestiCodes);
  }

  static getBumaGroup(): OptionsGroup {
    return this.getSelectGroup(bumaDomesticHeader, bumaDomesticCodes);
  }

  static getStandarGroup(): OptionsGroup {
    const standarGroup = this.getSelectGroup(standarDomesticHeader, standarDomesticCodes);
    standarGroup.options.push({
      value: '',
      label: nonSociety,
    });
    standarGroup.options.push(
      ...CopyrightUtils.castToOptionFromDictionaryWithCode(sortBy(this.getOtherSocietyGroup(concat(nordicDomestiCodes, bumaDomesticCodes, standarDomesticCodes)), 'name')),
    );
    return standarGroup;
  }

  static getSelectGroup(header: string, groupValues: string[]): OptionsGroup {
    const societies: DictionaryCodeItem[] = this.getSocietyGroup(groupValues);
    return CopyrightUtils.createSelectGroupFromDictionary(header, societies);
  }

  static getSocietyGroup(groupValues: string[]): DictionaryCodeItem[] {
    const groups: DictionaryCodeItem[] = [];
    each(groupValues, group => groups.push(find(ipsSocietiesCodes, ['code', group])));
    return groups;
  }

  static getOtherSocietyGroup(groupValues: string[]) {
    return filter(ipsSocietiesCodes, society => !groupValues.includes(get(society, 'code')));
  }

  static getConflictTypeSelectFormlyConfig(translate: TranslateService): FormlyFieldConfig {
    return {
      className: 'flex-1',
      key: 'conflictType',
      type: 'ice-select',
      defaultValue: conflictTypes,
      templateOptions: {
        optgroupClass: 'primary-checkbox',
        type: 'text',
        placeholder: translate.instant('ACTIVITY.CONFLICT_TYPE'),
        multiple: true,
        options: CopyrightUtils.createSelectGroupFromOptionsGroup(
          [CopyrightUtils.createSelectGroupFromValues(allConflictTypes, conflictTypes)],
          translate,
          'ACTIVITY.CONFLICT_TYPES',
        ),
      },
    };
  }

  static getConflictSubTypeSelectFormlyConfig(translate: TranslateService): FormlyFieldConfig {
    return {
      className: 'flex-1',
      key: 'conflictSubType',
      type: 'ice-select',
      defaultValue: conflictSubTypes,
      templateOptions: {
        optgroupClass: 'primary-checkbox',
        type: 'text',
        placeholder: translate.instant('ACTIVITY.CONFLICT_SUB_TYPE'),
        multiple: true,
        options: CopyrightUtils.createSelectGroupFromOptionsGroup([CopyrightUtils.createSelectGroupFromValues(allConflictSubTypes, conflictSubTypes)], translate, 'ACTIVITY'),
      },
    };
  }

  static getCaseStatusSelectFormlyConfig(translate: TranslateService): FormlyFieldConfig {
    return {
      className: 'flex-1 case-status-select',
      key: 'caseStatus',
      type: 'ice-select',
      defaultValue: [OrganizationCaseStatusType.NEW, OrganizationCaseStatusType.IP, OrganizationCaseStatusType.RSCH, OrganizationCaseStatusType.AR],
      templateOptions: {
        optgroupClass: 'primary-checkbox',
        type: 'text',
        placeholder: translate.instant('ACTIVITY.CONFLICT_STATUS'),
        multiple: true,
        options: [
          {
            header: translate.instant('ACTIVITY.ALL_STATUS'),
            options: [
              { label: translate.instant('ACTIVITY.STATUS_NEW'), value: OrganizationCaseStatusType.NEW },
              { label: translate.instant('ACTIVITY.STATUS_IN_PROGRESS'), value: OrganizationCaseStatusType.IP },
              { label: translate.instant('ACTIVITY.STATUS_RESEARCHED'), value: OrganizationCaseStatusType.RSCH },
              { label: translate.instant('ACTIVITY.AWAITING_REPLY'), value: OrganizationCaseStatusType.AR },
            ],
          },
        ],
      },
    };
  }

  static compareClaims(claim1: ClaimInfo, claim2: ClaimInfo): boolean {
    const propertiesToCheck = [
      'claimId',
      'ipiBaseNameKey',
      'roleRaw',
      'name',
      'pr',
      'mr',
      'prSociety',
      'mrSociety',
      'societyCodeMr',
      'societyCodePr',
      'territories',
      'tisnTerritories',
      'startDate',
      'mrRights',
      'prRights',
      'sourceWork',
      'territoriesLabel',
      'claimView',
    ];
    const filteredObj1 = pick(claim1, propertiesToCheck);
    const filteredObj2 = pick(claim2, propertiesToCheck);
    return isEqual(filteredObj1, filteredObj2);
  }

  static claimsSharesSelect(newRowSelected: ClaimInfo, originalClaimsList: ClaimInfo[], rowsSelectedList: ClaimInfo[]) {
    let newRowsSelectedList = [...rowsSelectedList];
    if (!newRowsSelectedList.some(item => this.compareClaims(item, newRowSelected))) {
      newRowsSelectedList = [...newRowsSelectedList, newRowSelected];
    }
    const childrenToAdd = originalClaimsList.filter(
      claim =>
        (claim.parentId === newRowSelected.claimId || claim.claimId === newRowSelected.claimId) &&
        !newRowsSelectedList.some(selectedItem => this.compareClaims(selectedItem, claim)),
    );
    childrenToAdd.forEach(child => {
      const childSelections = this.claimsSharesSelect(child, originalClaimsList, newRowsSelectedList);
      const combinedList = [...newRowsSelectedList, ...childSelections];
      newRowsSelectedList = uniqWith(combinedList, this.compareClaims);
    });
    return newRowsSelectedList;
  }

  static getTopLevelParents(selectedClaims: ClaimInfo[]): ClaimInfo[] {
    const parentMap = new Map<string, ClaimInfo>();
    selectedClaims.forEach(claim => {
      if (claim.parentId) {
        const parent = selectedClaims.find(c => c.claimId === claim.parentId);
        if (parent) {
          parentMap.set(claim.claimId, parent);
        }
      }
    });
    const topLevelParents = new Set<ClaimInfo>();
    selectedClaims.forEach(claim => {
      if (!parentMap.has(claim.claimId)) {
        topLevelParents.add(claim);
      }
    });
    return Array.from(topLevelParents);
  }
}
