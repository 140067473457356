import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DateTimeUtils } from '@ice';
import { locale as english } from '@ice/i18n/en/ui-messages';
import { addClassesToElement } from '@ice/utils';
import { FieldType } from '@ngx-formly/material';
import { TranslateService } from '@ngx-translate/core';
import { chain, keys } from 'lodash';
import moment from 'moment';
import { IceDateAdapter } from './ice-datepicker-type-adapter';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'dd-MM-YYYY',
    monthYearA11yLabel: 'MM-YYYY',
  },
};

@Component({
  selector: 'ice-form-datepicker-type',
  templateUrl: './datepicker-type.component.html',
  styleUrls: ['./datepicker-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: IceDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DatepickerTypeComponent extends FieldType implements OnInit {
  @ViewChild(MatInput, { static: true }) formFieldControl: MatInput;

  onFilter = (date: Date) => {
    if (this.to?.datepickerOptions?.filter) {
      return this.to?.datepickerOptions?.filter(date, this.form);
    }
    return true;
  };

  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    super();
    this.fuseTranslationLoader.loadTranslations(english);
  }

  ngOnInit() {
    this.field.validation = {
      ...(this.field?.validation || {}),
      messages: {
        ...(this.field.validation?.messages || {}),
        incorrect: this.translate.instant('DATE_FORMAT_ERROR'),
        invalidDate: this.translate.instant('DATE_DOES_NOT_EXIST'),
        required: this.translate.instant('REQUIRED'),
        matDatepickerParse: this.translate.instant('DATE_FORMAT_ERROR'),
        matDatepickerFilter: this.translate.instant('FILTER'),
      },
    };
  }

  onChange(event) {
    if (this.field.templateOptions.dateChange) {
      this.field.templateOptions.dateChange(this.field, event.target.value);
    }
    this.field.templateOptions.currentValue = event.target.value;
  }

  onChangeInput(event) {
    this.checkValidDateValue(event);
  }

  onFocus(event) {
    this.field.formControl.setErrors(null);
    DateTimeUtils.datePickerTransformOnFocus(event);
  }

  onPickerPanelFocusOut(event) {
    this.field.formControl.setErrors(null);
    this.field.formControl.setValue(this.field.formControl.value);
  }

  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData.getData('text');
    const dateValue = moment(pastedText);
    const isAValidDate = dateValue && dateValue['_isValid'];
    if (pastedText !== '' && !isAValidDate) {
      this.field.formControl.setErrors({ invalidDate: true });
    }
  }

  onBlur(event) {
    this.checkValidDateValue(event);
    if (this.field.templateOptions?.datePickerRequired && !this.field.formControl.value && keys(this.field.formControl.errors).length === 0) {
      this.field.formControl.setErrors({ required: true });
    }
    this.formControl.updateValueAndValidity();
    if (this.field.templateOptions?.blur) {
      this.field.templateOptions.blur(event);
    }
    DateTimeUtils.datePickerTransformOnBlur(event);
  }

  private checkValidDateValue(event: any) {
    const inputValue = event.target.value;
    let dateValue = moment(inputValue, MY_FORMATS.parse.dateInput, true);
    let isAValidDate = dateValue && dateValue['_isValid'];
    if (!isAValidDate && inputValue.match(/^\d{8}$/)) {
      dateValue = moment(inputValue, 'YYYYMMDD', true);
      isAValidDate = dateValue && dateValue['_isValid'];
    }
    if ((inputValue !== '' && !inputValue.match(/^\d{1,4}(-\d{1,2}){0,2}$/) && !inputValue.match(/^\d{8}$/)) || this.checkException(dateValue)) {
      this.field.formControl.setValue(dateValue);
      this.field.formControl.setErrors({ incorrect: true });
    } else if (!isAValidDate && inputValue !== '') {
      this.field.formControl.setErrors({ invalidDate: true });
    }
  }

  private checkException(dateValue: moment.Moment): boolean {
    const month = dateValue['_i']?.split('-')?.[1];
    const day = dateValue['_i']?.split('-')?.[2];
    const exceptionValues = ['0', '00'];
    return exceptionValues.includes(month) || exceptionValues.includes(day);
  }

  checkDisbaledCalendarIcon(): boolean {
    return !!chain(this.formControl.errors).keys().intersection(['incorrect', 'invalidDate']).value().length;
  }

  onOpenPopup() {
    if (this.to?.popupForcePt) {
      addClassesToElement(`.mat-datepicker-popup`, `ice-pt-${this.to?.popupForcePt}`);
    }
  }
}
