import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { TranslateService } from '@ngx-translate/core';
import { mrRightsNonOnline, mrRightsOnline, prRightsNonOnLine, prRightsOnLine } from 'config/constants/ips.constants';
import { ALL_RIGHTS } from 'config/constants/shares.constants';
import { concat, indexOf } from 'lodash';
import { LevelTypes, RightTypeGroup, RightTypeGroupValue } from 'models/copyright/formly/right-types';
import { of } from 'rxjs';

export class DialogSharesUsesTypes {
  static showInclusionPopup(dialog, translate, rightType, currentIncluded, updateUsageTypes) {
    const dialogRef = this.openDialog(dialog, translate, rightType, currentIncluded, $event => updateUsageTypes($event, () => dialogRef.close()));
  }

  static openDialog(
    dialog: MatDialog,
    translate: TranslateService,
    rightType: RightTypeGroup,
    currentIncluded: string[],
    onSubmit: ($event) => void,
    customPopupLabels?: any,
    customUsesTypes?: any,
  ) {
    const popup = customPopupLabels ? customPopupLabels : this.getPopupLabels(translate);
    const usesTypes = customUsesTypes ? customUsesTypes : this.getUsesTypes(translate);
    const modelAndConfig = this.generateModelAndFormConfig(rightType, currentIncluded, usesTypes);

    const dialogRef = dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-width-420-h',
        layouts: [
          {
            title: of(popup.title),
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    className: 'dialog-wrapper-auto',
                    config: {
                      response: of({ text: popup.message }),
                      messageIcon: of('info'),
                    },
                  },
                ],
              },
              {
                group: [
                  {
                    type: 'formly',
                    config: {
                      formBuilder: of([{ fieldGroupClassName: 'display-flex-col', fieldGroup: modelAndConfig.formConfig }]),
                      model: of(modelAndConfig.model),
                      submitEnabled: true,
                      submitAvailable: 'true',
                      submitLabel: popup.submitLabel,
                      button1Enabled: true,
                      button1Available: 'true',
                      button1Label: popup.button1Label,
                      submit: onSubmit,
                      onButton1: () => {
                        dialogRef.close();
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });

    return dialogRef;
  }

  static getPopupLabels(translate) {
    return {
      title: translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.TITLE'),
      message: translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.MESSAGE'),
      submitLabel: translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.BT_INCLUDE'),
      button1Label: translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.BT_CANCEL'),
    };
  }

  static getUsesTypes(translate) {
    const mechanical = [
      {
        code: 'MECHANICAL_HEADING',
        name: translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.MECHANICAL_HEADING'),
        level: LevelTypes.HEADING,
      },
      {
        code: 'TITLE_ONLINE_MR',
        name: translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.ONLINE'),
        binded: mrRightsOnline,
        level: LevelTypes.SECTION,
      },
      { code: 'MB', name: translate.instant('RIGHTS.MB'), level: LevelTypes.ITEM },
      { code: 'MD', name: translate.instant('RIGHTS.MD'), level: LevelTypes.ITEM },
      {
        code: 'TITLE_NON_ONLINE_MR',
        name: translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.NON_ONLINE'),
        binded: mrRightsNonOnline,
        level: LevelTypes.SECTION,
      },
      { code: 'BT', name: translate.instant('RIGHTS.BT'), level: LevelTypes.ITEM },
      { code: 'MA', name: translate.instant('RIGHTS.MA'), level: LevelTypes.ITEM },
      { code: 'MR', name: translate.instant('RIGHTS.MR'), level: LevelTypes.ITEM },
      { code: 'MT', name: translate.instant('RIGHTS.MT'), level: LevelTypes.ITEM },
      { code: 'MV', name: translate.instant('RIGHTS.MV'), level: LevelTypes.ITEM },
      { code: 'RL', name: translate.instant('RIGHTS.RL'), level: LevelTypes.ITEM },
      { code: 'SY', name: translate.instant('RIGHTS.SY'), level: LevelTypes.ITEM },
    ];
    const print = [{ code: 'RP', name: translate.instant('RIGHTS.RP'), level: LevelTypes.ITEM }];
    const synchronisation = [{ code: 'SY', name: translate.instant('RIGHTS.SY'), level: LevelTypes.ITEM }];
    const performing = [
      {
        code: 'PERFORMING_HEADING',
        name: translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.PERFORMING_HEADING'),
        level: LevelTypes.HEADING,
      },
      {
        code: 'TITLE_ONLINE_PR',
        name: translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.ONLINE'),
        binded: prRightsOnLine,
        level: LevelTypes.SECTION,
      },
      { code: 'OB', name: translate.instant('RIGHTS.OB'), level: LevelTypes.ITEM },
      { code: 'OD', name: translate.instant('RIGHTS.OD'), level: LevelTypes.ITEM },
      {
        code: 'TITLE_NON_ONLINE_PR',
        name: translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.NON_ONLINE'),
        binded: prRightsNonOnLine,
        level: LevelTypes.SECTION,
      },
      { code: 'MP', name: translate.instant('RIGHTS.MP'), level: LevelTypes.ITEM },
      { code: 'PC', name: translate.instant('RIGHTS.PC'), level: LevelTypes.ITEM },
      { code: 'PR', name: translate.instant('RIGHTS.PR'), level: LevelTypes.ITEM },
      { code: 'PT', name: translate.instant('RIGHTS.PT'), level: LevelTypes.ITEM },
      { code: 'RB', name: translate.instant('RIGHTS.RB'), level: LevelTypes.ITEM },
      { code: 'RT', name: translate.instant('RIGHTS.RT'), level: LevelTypes.ITEM },
      { code: 'TB', name: translate.instant('RIGHTS.TB'), level: LevelTypes.ITEM },
      { code: 'TO', name: translate.instant('RIGHTS.TO'), level: LevelTypes.ITEM },
      { code: 'TP', name: translate.instant('RIGHTS.TP'), level: LevelTypes.ITEM },
      { code: 'TV', name: translate.instant('RIGHTS.TV'), level: LevelTypes.ITEM },
      { code: 'ER', name: translate.instant('RIGHTS.ER'), level: LevelTypes.ITEM },
    ];
    const rightTypes: Record<RightTypeGroup, RightTypeGroupValue> = {
      Mechanical: mechanical,
      Performing: performing,
      Synchronisation: synchronisation,
      Print: print,
      PRMR: concat(performing, mechanical),
      All: concat(performing, mechanical, print, synchronisation),
    };
    return rightTypes;
  }

  static generateModelAndFormConfig(rightType: RightTypeGroup, currentValues, usesTypes) {
    const modelAndConfig = { model: {}, formConfig: [] };
    const usesTypesGroups = [];
    currentValues = (currentValues && currentValues.map(value => value.toUpperCase())) || [];
    if (rightType) {
      for (const useType of usesTypes[rightType]) {
        if (useType.level !== LevelTypes.HEADING) {
          modelAndConfig.model[useType.code] = indexOf(currentValues, useType.code) !== -1 || indexOf(currentValues, ALL_RIGHTS) !== -1;
        }
        if (useType.level === LevelTypes.SECTION) {
          usesTypesGroups.push(useType);
          modelAndConfig.formConfig.push({
            fieldGroupClassName: 'display-flex-row-justify-left',
            fieldGroup: [
              {
                className: 'ice-checkbox-infix',
                type: 'checkbox',
                key: useType.code,
                value: 'true',
                templateOptions: {
                  change: (field, value) => {
                    const form = field.form as FormControl;
                    if (useType['binded']) {
                      for (const binded of useType['binded']) {
                        form.get(binded).setValue(value.checked);
                      }
                    }
                  },
                },
              },
              {
                className: 'ice-checkbox-infix',
                template: `<h3 class='right-types-title'><b>${useType.name}</b></h3>`,
              },
            ],
          });
        } else if (useType.level === LevelTypes.HEADING) {
          modelAndConfig.formConfig.push({
            fieldGroup: [
              {
                template: `<h2 class='right-types-heading'><b>${useType.name}</b></h2>`,
              },
            ],
          });
        } else {
          modelAndConfig.formConfig.push({
            className: 'row',
            type: 'checkbox',
            key: useType.code,
            value: 'true',
            templateOptions: {
              label: `${useType.code} - ${useType.name}`,
            },
          });
        }
      }
    }
    if (usesTypesGroups.length > 0) {
      usesTypesGroups.map(usesTypesGroup => {
        modelAndConfig.model[usesTypesGroup.code] = true;
        if (usesTypesGroup['binded']) {
          usesTypesGroup['binded'].map(codeBinded => {
            modelAndConfig.model[usesTypesGroup.code] = modelAndConfig.model[usesTypesGroup.code] && modelAndConfig.model[codeBinded];
          });
        }
      });
    }
    return modelAndConfig;
  }
}
