import { FormControl } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AgreementUtils, DateTimeUtils, DatepickerUtils } from '@ice';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import type { SearchService } from 'services/search/search.service';
import type { FieldValidatorService } from 'services/validators/field.validator.service';
import { FormConfig, SearchExpressions } from './form-config';

enum TerritorymatchType {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE',
}

export class AgreementsSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    switch (key) {
      case 'agreementType':
        return `{"equals":{"attributes.agreementType":"${value}"}}`;
      case 'assignorName':
        return `{"wildcard":{"assignor.partyName.attributes.name":"*${value}*"}}`;
      case 'assignorId':
        const pattern = `{"wildcard":{"assignor.partyName.relations[XREF].otherId":"*<<assignorId>>*"}}`;
        const query = value
          .split(',')
          .map(id => pattern.replace(/<<assignorId>>/g, id.trim()))
          .join(',');
        return `{"or":[${query}]}`;
      case 'assigneeName':
        return `{"wildcard":{"assignee.partyName.attributes.name":"*${value}*"}}`;
      case 'assigneeId':
        return `{"wildcard":{"assignee.partyName.relations[XREF].otherId":"*${value}*"}}`;
      case 'groupId':
        switch (prefix) {
          case 'group':
            return JSON.stringify(AgreementUtils.getAgreementGroupQueryByRelationType('GROUP', value));
          case 'recipient':
            return JSON.stringify(AgreementUtils.getAgreementGroupQueryByRelationType('RECIPIENT', value));
          case 'both':
            return `{"or":[
              ${['GROUP', 'RECIPIENT'].map(type => JSON.stringify(AgreementUtils.getAgreementGroupQueryByRelationType(type, value)))}
            ]}`;
        }
        return '';
      case 'startDate':
        return `{"range":{"attributes.shares.startDate":{"gte": "${value}"}}}`;
      case 'terminated':
        return !value || value === 'false' ? `{"range":{"attributes.shares.endDate":{"gte": "${DateTimeUtils.formatDate(moment())}"}}}` : null;
      case 'territories':
      case 'territoriesMatchType':
        return ''; // already resolved into getExtraCall + extraCallDataCleaner
      case 'needsBase':
        return ``;
      case 'xref':
        return `{"equals":{"relations[XREF].otherId":"${this.formatExactIDSearch(value)}"}}`;
      case 'xrefs':
        return `{"or":[${value
          .split(',')
          .map(id => `{"equals":{"relations[XREF].otherId":"${id}"}}`)
          .join(',')}]}`;
    }
    return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
  }

  getExtraSearchExpression() {
    return '';
  }

  getExtraCall(searchService: SearchService, data: any) {
    if (data.territories) {
      return searchService.getTerritoriesCountries(data.territories || '');
    }
    return of({});
  }

  extraCallDataCleaner(response: any, data: any, searchObject: any) {
    let formattedsearchObject = cloneDeep(searchObject);
    const tisnsCodes = response.tisns;
    if (tisnsCodes) {
      const tisnsAndExpression = tisnsCodes.map(item => ({ equals: { 'attributes.shares.countries': item } }));
      const matchType = data?.territoriesMatchType;
      let expression = null;
      switch (matchType) {
        case TerritorymatchType.EXCLUDE: {
          expression = { not: { or: tisnsAndExpression } };
          break;
        }
        case TerritorymatchType.INCLUDE: {
          expression = { and: tisnsAndExpression };
          break;
        }
      }
      if (formattedsearchObject.and) {
        formattedsearchObject.and.push(expression);
      } else {
        formattedsearchObject = { and: { ...expression } };
      }
    }
    return formattedsearchObject;
  }
}

export class SearchCopyrightAgreementsForm implements FormConfig {
  legacyAgreementField: FormlyFieldConfig;
  protected legacyAgreementMessage: BehaviorSubject<string>;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private searchService: SearchService,
    private fieldValidatorService: FieldValidatorService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.legacyAgreementMessage = new BehaviorSubject('');
  }

  async territoriesValidator(_, control: FormControl, { key }: any) {
    const isTerritoriesField = key === 'territories';
    const territories = control.parent.get('territories').value;
    if (territories && territories.length && territories.length > 0) {
      let codes;
      try {
        codes = await this.searchService.getTerritoriesCountries(territories).toPromise();
      } catch {
        return false;
      }
      if (!codes.tisns) {
        if (isTerritoriesField) {
          return false;
        }
        return true;
      }
    }
    return true;
  }

  getForm(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1 ice-width-91-percent',
            key: 'searchTerm',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENTS.AGREEMENT_NAME'),
              required: false,
            },
          },
          ...this.fieldValidatorService.agreementRefFieldAndLegacyValidator('xref', this.translate),
          {
            className: 'flex-1',
            key: 'assignor',
            wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENTS.ASSIGNOR'),
              required: false,
              infoText: this.translate.instant('AGREEMENTS.ASSIGNOR_INFO'),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'groupId',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENTS.GROUP_ID'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'groupIdPrefix',
            type: 'select',
            defaultValue: 'group',
            templateOptions: {
              label: this.translate.instant('AGREEMENTS.GROUP_ID_SEARCH_TYPE.TITLE'),
              options: [
                { label: this.translate.instant('AGREEMENTS.GROUP_ID_SEARCH_TYPE.GROUP'), value: 'group' },
                { label: this.translate.instant('AGREEMENTS.GROUP_ID_SEARCH_TYPE.RECIPIENT'), value: 'recipient' },
                { label: this.translate.instant('AGREEMENTS.GROUP_ID_SEARCH_TYPE.BOTH'), value: 'both' },
              ],
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'territories',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENTS.TERRITORIES'),
              required: false,
            },
            asyncValidators: {
              resolveTerritories: {
                expression: this.territoriesValidator.bind(this, ''),
                message: this.translate.instant('AGREEMENTS.ERRORS.INVALID_TERRITORIES'),
              },
            },
          },
          {
            className: 'flex-1',
            key: 'territoriesMatchType',
            type: 'select',
            defaultValue: TerritorymatchType.EXCLUDE,
            templateOptions: {
              label: this.translate.instant('AGREEMENTS.TERRITORIES_MATCH.TITLE'),
              options: [
                { label: this.translate.instant('AGREEMENTS.TERRITORIES_MATCH.EXCLUDE'), value: TerritorymatchType.EXCLUDE },
                { label: this.translate.instant('AGREEMENTS.TERRITORIES_MATCH.INCLUDE'), value: TerritorymatchType.INCLUDE },
              ],
              required: false,
            },
            asyncValidators: {
              resolveTerritories: {
                expression: this.territoriesValidator.bind(this, ''),
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'assignee',
            wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENTS.ASSIGNEE'),
              required: false,
              infoText: this.translate.instant('AGREEMENTS.ASSIGNEE_INFO'),
            },
          },
          DatepickerUtils.getDatepickerField({
            key: 'startDate',
            label: this.translate.instant('AGREEMENTS.START_DATE'),
            translate: this.translate,
          }),
          {
            className: 'flex-1',
            key: 'agreementType',
            type: 'select',
            defaultValue: '',
            templateOptions: {
              label: this.translate.instant('AGREEMENTS.AGREEMENT_TYPE.TITLE'),
              options: [
                { label: '', value: null },
                { label: this.translate.instant('AGREEMENTS.AGREEMENT_TYPE.GENERAL'), value: 'G' },
                { label: this.translate.instant('AGREEMENTS.AGREEMENT_TYPE.SPECIFIC'), value: 'S' },
              ],
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'terminated',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.translate.instant('AGREEMENTS.INCLUDE_TERMINATED'),
              required: false,
              indeterminate: false,
            },
          },
        ],
      },
    ];
  }
}
