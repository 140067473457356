import { DATE_FORMAT, DATE_TIME_FORMAT, END_DATES_NO_DISPLAY, END_DATE_ALIAS, END_DATE_NO_DISPLAY, TERRITORY_END_DATE_NO_DISPLAY } from 'config/constants/global.constants';
import { SectionsConfig } from 'config/sections-config';
import { every, has, map, mapValues, toPairs } from 'lodash';
import { FilterDate, MomentMethods } from 'models/date';
import moment from 'moment';
export class DateTimeUtils {
  static formatDate(momentDate: moment.Moment | string = '', pattern: string = DATE_FORMAT): string {
    return moment(!momentDate ? undefined : momentDate).format(pattern);
  }

  static formatDateTime(momentDate: moment.Moment | string, pattern: string = DATE_TIME_FORMAT): string {
    return moment(momentDate).format(pattern);
  }

  static formatDateAddDays(momentDate: moment.Moment, days: number, pattern: string = DATE_FORMAT): string {
    return moment(momentDate).add(days, 'days').format(pattern);
  }

  static differenceInHours(momentDate1: Date, momentDate2: Date): number {
    return moment(momentDate1).diff(moment(momentDate2), 'minutes') / 60;
  }

  static getTodayFormatted() {
    return DateTimeUtils.formatDate(moment());
  }

  // convertToZeroFormat2Digits("5") -> "05"
  static convertToZeroFormat2Digits(num: string | number): string {
    const numToString = num.toString();
    const len = numToString.length;
    if (Number(num) < 0) {
      throw new Error('The provided number must be equal or greater than 0');
    } else if (len > 2) {
      throw new Error('The provided number is too long (max 2 chars)');
    }
    return len > 1 ? numToString : `0${numToString}`;
  }

  static isDateBeforeNow(date: string) {
    return date && moment(date).isValid && moment(date).isBefore(moment());
  }

  static isDateSameOrAfterNow(date: string) {
    return date && moment(date).isValid && moment(date).isSameOrAfter(moment());
  }

  static isDateSameOrBeforeNow(date: string) {
    return moment(date).isSameOrBefore(moment());
  }

  static isDateBeforeDate(date: string, endDate: string) {
    return date && moment(date).isValid && moment(date).isBefore(moment(endDate));
  }

  static isDateBetween(date: string, startDate: string, endDate: string) {
    return date && moment(date).isValid && moment(date).isSameOrAfter(moment(startDate)) && moment(date).isSameOrBefore(moment(endDate));
  }

  static isDateSameOrBefore(date: string, beforeDay: string) {
    return moment(date).isSameOrBefore(beforeDay);
  }

  static getDurationTwoDigitsFormat(duration: number = 0): string {
    const secs = duration % 60;
    if (duration > 0) {
      return (
        Math.floor(duration / 60)
          .toString()
          .padStart(2, '0') +
        ':' +
        (secs < 10 ? '0' + secs : secs)
      );
    }
    return '00:00';
  }

  static getDurationHoursFormat(duration: number = 0): string {
    if (duration < 3600) {
      return DateTimeUtils.getDurationTwoDigitsFormat(duration);
    } else {
      return DateTimeUtils.getDurationHoursDigitsFormat(duration);
    }
  }

  static getDurationHoursDigitsFormat(duration: number = 0): string {
    if (duration > 0) {
      const minutesNum: number = Math.floor(duration / 60);
      const secs: string = (duration % 60).toString().padStart(2, '0');
      let hours: string = Math.floor(minutesNum / 60).toString();
      hours = hours.length > 2 ? hours.padStart(4, '0') : hours.padStart(2, '0');
      const minutesTxt: string = (minutesNum % 60).toString().padStart(2, '0');
      return `${hours}:${minutesTxt}:${secs}`;
    }
    return '00:00';
  }

  static checkDates(checkDate: Date, datesToCompare: Array<string>, filterType: MomentMethods): boolean {
    return every(
      datesToCompare,
      date =>
        !date ||
        !(moment(date, 'YYYY-MM-DD', true).isValid() || moment(date, 'YYYYMMDD', true).isValid()) ||
        moment(date).isSame(moment('9999-12-31')) ||
        moment(date).isSame(moment('1900-01-01')) ||
        moment(checkDate)[filterType](moment(date), undefined),
    );
  }

  static checkDate(filterDate: FilterDate): boolean {
    const { checkDate, filter } = filterDate;
    return checkDate && filter && toPairs(filter).every(([filterType, datesToCompare]: [MomentMethods, Array<string>]) => this.checkDates(checkDate, datesToCompare, filterType));
  }

  static setDurationToSeconds(duration: string) {
    const minsAndSecs = (duration || '0').split(':').map(Number).reverse();
    return minsAndSecs[1] * 60 + (minsAndSecs[0] ? minsAndSecs[0] : 0) + (minsAndSecs[2] ? minsAndSecs[2] * 60 * 60 : 0);
  }

  static cleanIndefiniteDate(val: any, isTerritorySection: boolean = false) {
    if (!moment(val).isValid()) {
      return val;
    }
    if ((!isTerritorySection && END_DATE_NO_DISPLAY === val) || (isTerritorySection && moment(val).isSameOrAfter(moment(TERRITORY_END_DATE_NO_DISPLAY)))) {
      return END_DATE_ALIAS;
    }
    return val;
  }

  static cleanObjectIndefiniteDateToAlias(item: any, isTerritorySection: boolean = false) {
    return mapValues(item, val => this.cleanIndefiniteDate(val, isTerritorySection));
  }

  static cleanObjectsIndefiniteDate(values: any[], section?: string) {
    const isTerritorySection = section && section === SectionsConfig.TERRITORIES.name;
    return (values && Array.isArray(values) ? values : []).map(value => this.cleanObjectIndefiniteDateToAlias(value, isTerritorySection));
  }

  static getIndefiniteDate(val: any) {
    return val === END_DATE_ALIAS ? END_DATE_NO_DISPLAY : val;
  }

  static cleanObjectIndefiniteDateAliasToDate(item: any) {
    return mapValues(item, val => this.getIndefiniteDate(val));
  }

  static datePickerTransformOnFocus(event: any) {
    if (event.target.value === END_DATE_ALIAS) {
      event.target.value = END_DATE_NO_DISPLAY;
    }
  }

  static datePickerTransformOnBlur(event: any) {
    if (event.target.value === END_DATE_NO_DISPLAY) {
      event.target.value = END_DATE_ALIAS;
    }
  }

  static getSimpleDateFormat(date: any) {
    return date?._isAMomentObject ? DateTimeUtils.formatDate(date) : date || date;
  }

  static cleanEmptyDates(items: object[], propertyKeys: string[]): object[] {
    if (items?.length && propertyKeys?.length) {
      return map(items, item => {
        propertyKeys.forEach(propertyKey => this.cleanObjectEmptyDate(item, propertyKey));
        return item;
      });
    }
    return items;
  }

  static cleanObjectEmptyDate(item: object, propertyKey: string): object {
    if (item && has(item, propertyKey) && !item[propertyKey]) {
      delete item[propertyKey];
    }
    return item;
  }

  static cleanIndefiniteDateToAlias(items: Array<any> = [], labels: { indefinite: string }) {
    return items.map(item => {
      return {
        ...item,
        value: item.value === END_DATE_NO_DISPLAY ? labels.indefinite : item.value,
      };
    });
  }
}
