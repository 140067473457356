import { TerritoryDataType } from 'config/constants/territories.constants';
import { generateClaimCollectionByClaimId, generateTrimClaimsChainsOptions, TerritoryUtils } from '@ice';
import { PostTermCollectionValue, TrimClaimsConfig, TrimClaimsSelection, TrimScopeOptions, WorkDetail, WorkShare } from 'models';
import { cloneDeep, intersection, keyBy, union } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT } from 'config/constants/global.constants';
import { mrRights, prRights } from 'config/constants/ips.constants';

export const getTrimmedClaims = ({ work, config }: { work: WorkDetail; config: TrimClaimsConfig }) => {
  // destructure needed variables
  const {
    territory,
    codes,
    chains,
    scope,
    claimsSelection,
    excludeCreators,
    startDate: newStartDate,
    endDate: newEndDate,
    postTermCollection,
    postTermCollectionDate: newPostTermCollectionDate,
    priorRoyaltiesDate: newPriorRoyaltiesDate,
    rightsCodes,
  } = config;
  const { claims } = work;
  // first create object than can be modified (claims coming from state are immutable)
  const clonedClaims = cloneDeep(claims);

  const trimClaimsChainsOptions = generateTrimClaimsChainsOptions({ work });
  const trimClaimsChainsOptionByValue = keyBy(trimClaimsChainsOptions, 'value');
  const claimCollectionByClaimId = generateClaimCollectionByClaimId({ work });
  const claimsIdsToModify = new Set<string>();
  const chainsToModify = claimsSelection === TrimClaimsSelection.ALL ? trimClaimsChainsOptions.map(chain => chain.value) : chains;
  // identify claims to be modified
  chainsToModify.forEach(chainId => {
    const chainOption = trimClaimsChainsOptionByValue[chainId];
    const parentId = chainOption.metadata?.parentId;

    // if we don't exclude creators and it has a parentId we need to include it and its children
    claimCollectionByClaimId[(!excludeCreators && parentId) || chainId].forEach(claim => {
      claimsIdsToModify.add(claim.claimId);
    });
  });

  const replaceWorkShare = (workShare: WorkShare) => {
    const getTerritories = () => {
      if (!territory?.value) {
        return workShare.territories;
      }
      const currentTerritoriesTisn = TerritoryUtils.flatTerritories(workShare.territories);
      const newTerritoriesTisn = codes.map(String);
      switch (scope) {
        case TrimScopeOptions.REPLACE:
          return TerritoryUtils.convertTerritoryStringElements(TerritoryUtils.splitTerritoryStringCodes(territory.value), TerritoryDataType.TISN);
        case TrimScopeOptions.TRIM:
          const intersectedTerritories = intersection(currentTerritoriesTisn, newTerritoriesTisn);
          return TerritoryUtils.convertTerritoryStringElements(TerritoryUtils.splitTerritoryStringCodes(`+${intersectedTerritories.join('+')}`), TerritoryDataType.TISN);
        case TrimScopeOptions.ADD:
          const unitedTerritories = union(currentTerritoriesTisn, newTerritoriesTisn);
          return TerritoryUtils.convertTerritoryStringElements(TerritoryUtils.splitTerritoryStringCodes(`+${unitedTerritories.join('+')}`), TerritoryDataType.TISN);
      }
    };
    const territories = getTerritories();
    // in the effect consuming this function, the territory fields will be shortened in batch (example: from +IE+GB to +2BI)
    const getStartDate = () => {
      const currentStartDate = moment(workShare.startDate);
      if (!newStartDate) {
        return currentStartDate;
      }
      const newStartDateMoment = moment(newStartDate);
      switch (scope) {
        case TrimScopeOptions.TRIM:
          return newStartDateMoment.isBefore(currentStartDate) ? newStartDateMoment : currentStartDate;
        case TrimScopeOptions.ADD:
          return newStartDateMoment.isAfter(currentStartDate) ? newStartDateMoment : currentStartDate;
        case TrimScopeOptions.REPLACE:
        default:
          return newStartDateMoment;
      }
    };
    const startDate = getStartDate().format(DATE_FORMAT);
    const getEndDate = () => {
      const currentEndDate = moment(workShare.endDate);
      if (!newEndDate) {
        return currentEndDate;
      }
      const newEndDateMoment = moment(newEndDate);
      switch (scope) {
        case TrimScopeOptions.TRIM:
          return newEndDateMoment.isBefore(currentEndDate) ? newEndDateMoment : currentEndDate;
        case TrimScopeOptions.ADD:
          return newEndDateMoment.isAfter(currentEndDate) ? newEndDateMoment : currentEndDate;
        case TrimScopeOptions.REPLACE:
        default:
          return newEndDateMoment;
      }
    };
    const endDate = getEndDate().format(DATE_FORMAT);
    /**
     * Prior Royalties Date can be treated as an EndDate, as it is the
     * last date to collect the prior royalties.
     */
    const getPriorRoyaltiesDate = () => {
      const currentPriorRoyaltiesDate = moment(workShare.priorRoyaltiesDate);
      if (!newPriorRoyaltiesDate) {
        return currentPriorRoyaltiesDate;
      }
      const newPriorRoyaltiesDateMoment = moment(newPriorRoyaltiesDate);
      switch (scope) {
        case TrimScopeOptions.TRIM:
          return newPriorRoyaltiesDateMoment.isBefore(currentPriorRoyaltiesDate) ? newPriorRoyaltiesDateMoment : currentPriorRoyaltiesDate;
        case TrimScopeOptions.ADD:
          return newPriorRoyaltiesDateMoment.isAfter(currentPriorRoyaltiesDate) ? newPriorRoyaltiesDateMoment : currentPriorRoyaltiesDate;
        case TrimScopeOptions.REPLACE:
        default:
          return newPriorRoyaltiesDateMoment;
      }
    };
    const priorRoyaltiesDate = getPriorRoyaltiesDate().format(DATE_FORMAT);

    /**
     * Get post term collection date.
     * - if post term collection is NONE, we use the end date of the original claim
     */
    const getPtcDate = () => {
      const currentPtcDateMoment = moment(workShare.postTermCollectionDate);
      if (!postTermCollection) {
        return currentPtcDateMoment;
      }
      if (postTermCollection === PostTermCollectionValue.NONE) {
        return moment(endDate);
      }
      const newPtcDateMoment = moment(newPostTermCollectionDate);
      switch (scope) {
        case TrimScopeOptions.TRIM:
          return newPtcDateMoment.isBefore(currentPtcDateMoment) ? newPtcDateMoment : currentPtcDateMoment;
        case TrimScopeOptions.ADD:
          return newPtcDateMoment.isAfter(currentPtcDateMoment) ? newPtcDateMoment : currentPtcDateMoment;
        case TrimScopeOptions.REPLACE:
        default:
          return newPtcDateMoment;
      }
    };
    const postTermCollectionDate = getPtcDate().format(DATE_FORMAT);

    const getRightTypes = () => {
      // we iterate over claims that could be Performing or Mechanical or both, so we need to distinguish them
      let currentMechanicalRights = intersection(workShare.rightTypes, mrRights);
      let currentPerformingRights = intersection(workShare.rightTypes, prRights);
      let newRights = [...workShare.rightTypes];
      if (!!currentPerformingRights.length) {
        if (rightsCodes.Performing.length) {
          switch (scope) {
            case TrimScopeOptions.TRIM:
              newRights = union(intersection(newRights, rightsCodes.Performing), currentMechanicalRights);
              break;
            case TrimScopeOptions.ADD:
              newRights = union(rightsCodes.Performing, newRights);
              break;
            case TrimScopeOptions.REPLACE:
              newRights = union(rightsCodes.Performing, currentPerformingRights);
              break;
          }
        }
      }
      currentPerformingRights = intersection(newRights, prRights);
      currentMechanicalRights = intersection(newRights, mrRights);
      if (!!currentMechanicalRights.length) {
        if (rightsCodes.Mechanical.length) {
          switch (scope) {
            case TrimScopeOptions.TRIM:
              newRights = union(intersection(newRights, rightsCodes.Mechanical), currentPerformingRights);
              break;
            case TrimScopeOptions.ADD:
              newRights = union(rightsCodes.Mechanical, newRights);
              break;
            case TrimScopeOptions.REPLACE:
              newRights = union(rightsCodes.Mechanical, currentMechanicalRights);
              break;
          }
        }
      }
      return newRights;
    };
    const rightTypes = getRightTypes();

    return {
      ...workShare,
      territories,
      startDate,
      endDate,
      postTermCollectionDate,
      priorRoyaltiesDate,
      rightTypes,
    };
  };

  return clonedClaims.map(claim => {
    // discard claims that are not to be modified
    if (!claimsIdsToModify.has(claim.claimId)) {
      return claim;
    }
    return {
      ...claim,
      claimShares: claim.claimShares?.map(replaceWorkShare),
      ownershipShares: claim.ownershipShares?.map(replaceWorkShare),
    };
  });
};
