/**
 * Values are used for the path of the bulk update request
 */
export enum BulkUpdateSegment {
  SWAP_IP = 'swapIp',
  TRANSFER_IP = 'transferIp',
  TERMINATE_IP = 'terminateIp',
  PURPOSE = 'purpose',
  TITLES = 'titles',
  OTHER_PARTIES = 'otherParties',
  WORK_XREFS = 'workXrefs',
  WORK_MATCHES = 'workMatches',
  WORK_BROADCAST_INFO = 'broadcastInfo',
}
/**
 * Values are used to translate the bulk update response.
 * Values are also used to get bulk updates by type.
 * `ALL` is an exception and it's merely used to be mapped in the selector. (We can't
 * request to BE with type='all')
 */
export enum BulkUpdateResponseType {
  ALL = 'all',
  SWAP_IP = 'SWAP_IP',
  TRANSFER_IP = 'TRANSFER_IP',
  TERMINATE_IP = 'TERMINATE_IP',
  PURPOSE = 'PURPOSE',
  TITLES = 'TITLES',
  OTHER_PARTIES = 'OTHER_PARTIES',
  WORK_XREFS = 'WORK_XREFS',
  WORK_MATCHES = 'WORK_MATCHES',
  WORK_BROADCAST_INFO = 'BROADCAST_INFO',
}
export enum BulkUpdateStatus {
  ERROR = 'error',
  IN_PROGRESS = 'in-progress',
  SUCCESS = 'success',
}

export enum BulkUpdateEditModeValues {
  ADD = 'add',
  EDIT = 'edit',
  REMOVE = 'remove',
}

export enum BulkUpdateResultStatus {
  /**
   * ALL is a pseudo status to be used in the filter, but not returned or accepted from the API itself. Only used for filtering
   */
  ALL = 'ALL',
  RUNNING = 'RUNNING',
  DELIVERED_SUCCESSFULLY = 'DELIVERED_SUCCESSFULLY',
  DELIVERED_WITH_ERRORS = 'DELIVERED_WITH_ERRORS',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
}
export enum BulkUpdateItemStatus {
  SUCCEEDED = 'SUCCEEDED',
  MASTERING = 'MASTERING',
  MASTERING_TIMED_OUT = 'MASTERING_TIMED_OUT',
  SKIPPED = 'SKIPPED',
  FAILED = 'FAILED',
  NOT_STARTED = 'NOT_STARTED',
}
export interface JobItem {
  bulkUpdateId: string;
  bulkUpdateType: BulkUpdateResponseType;
  note: string;
  comment: string;
  createdBy: string;
  createdAt: string;
  completedAt: string;
  worksCount: number;
  status: BulkUpdateResultStatus;
}

export interface JobUpdateQueryParams {
  userId?: string;
  /**
   * Date format: 2024-01-01T23:00:00.000Z
   */
  startDate?: string;
  /**
   * Date format: 2024-01-01T23:00:00.000Z
   */
  endDate?: string;
  bulkUpdateType?: BulkUpdateResponseType;
  status?: Exclude<BulkUpdateResultStatus, BulkUpdateResultStatus.ALL>;
}

export interface BulkUpdateDetailItem {
  workId: string;
  status: BulkUpdateResultStatus;
  details: string;
}

export type BulkUpdateDetail = BulkUpdateDetailItem[];
