import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { ReducedActionResponses, WithDrawActionTypes } from 'config/constants/activity.constants';
import { NOT_AVAILABLE } from 'config/constants/global.constants';
import { get } from 'lodash';
import { of } from 'rxjs';
import * as fromRoot from 'store/root';
import { PermissionsService } from 'services/permissions/permissions.service';
import { RootDatatable } from './root-datatable';

export class CopyrightClaimsDataTable extends RootDatatable {
  canOpenWorks: boolean;
  canOpenIps: boolean;
  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected permissionsService: PermissionsService,
  ) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
    this.canOpenWorks = this.permissionsService.can('works_details_data');
    this.canOpenIps = this.permissionsService.can('ips_agreements');
  }

  getDataTable(
    onEditStatus?,
    onDelete?,
    enableCheckResolver?,
    resolutionOwnerOptions?,
    onEditResolutionOwner?,
    clickIPINumber?,
    editReducedShare?,
    viewReduceClaim?,
    clickAgreement?,
  ): DataTableRow[] {
    return [
      {
        name: '',
        prop: 'rowSelected',
        flexGrow: 0.55,
        headerCheckboxable: false,
        checkboxable: true,
        width: 50,
        resizeable: false,
        canAutoResize: false,
        draggable: false,
        sortable: false,
      },
      {
        name: this.translate.instant('CLAIMS.ROLE'),
        prop: 'role',
        flexGrow: 1.5,
        tooltip: 'roleLabel',
        headerTooltip: ' ',
        canHideTooltip: true,
      },
      {
        name: this.translate.instant('CLAIMS.NAME'),
        prop: 'name',
        flexGrow: 3,
        onClickAction: clickIPINumber,
        cellClass: this.canOpenIps ? 'ice-table-link' : null,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.IPI_NAME_NUMBER'),
        prop: 'refLabel',
        flexGrow: 2,
        onClickAction: clickIPINumber,
        cellClass: this.canOpenIps ? 'ice-table-link' : null,
        headerTooltip: this.translate.instant('CLAIMS.IPI_NAME_NUMBER_TOOLTIP'),
      },
      {
        name: this.translate.instant('CLAIMS.PR_SOC'),
        prop: 'societyCodePr',
        tooltip: 'societyCodePrTooltip',
        flexGrow: 2.5,
        headerTooltip: this.translate.instant('CLAIMS.PR_SOC_TOOLTIP'),
        iconRightOfText: { icon: 'societyCodePrIcon' },
      },
      {
        name: this.translate.instant('CLAIMS.MR_SOC'),
        prop: 'societyCodeMr',
        tooltip: 'societyCodeMrTooltip',
        flexGrow: 2.5,
        headerTooltip: this.translate.instant('CLAIMS.MR_SOC_TOOLTIP'),
        iconRightOfText: { icon: 'societyCodeMrIcon' },
      },
      {
        name: this.translate.instant('CLAIMS.AGREEMENT'),
        prop: 'agreementId',
        flexGrow: 2,
        cellClass: clickAgreement ? 'ice-table-link' : null,
        onClickAction: clickAgreement,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.PRIOR_ROYALTY_DATE'),
        prop: 'priorRoyaltyDate',
        flexGrow: 2,
        headerTooltip: this.translate.instant('CLAIMS.PRIOR_ROYALTY_DATE_TOOLTIP'),
      },
      {
        name: this.translate.instant('CLAIMS.START_DATE'),
        prop: 'startDate',
        flexGrow: 2,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.END_DATE'),
        prop: 'endDate',
        flexGrow: 2,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.POST_TERM_COLLECTION_DATE'),
        prop: 'postTermCollectionDate',
        flexGrow: 2,
        headerTooltip: this.translate.instant('CLAIMS.POST_TERM_COLLECTION_DATE_TOOLTIP'),
      },
      {
        name: this.translate.instant('CLAIMS.TERRITORY'),
        prop: 'territoriesTisa',
        flexGrow: 3,
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.RIGHTS_PR_MR'),
        prop: 'allRights',
        flexGrow: 2,
        headerTooltip: this.translate.instant('CLAIMS.RIGHTS_PR_MR_TOOLTIP'),
      },
      {
        prop: 'status',
        flexGrow: 2,
        sortable: false,
        name: this.translate.instant('CLAIMS.STATUS'),
        icons: 'statusIcons',
        cellClass: 'all-claims-status-cell',
      },
      {
        name: this.translate.instant('CLAIMS.IN_CONFLICT'),
        prop: 'inDispute',
        cellClass: 'ice-justify-left',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 65,
        headerTooltip: this.translate.instant('CLAIMS.IN_CONFLICT_TOOLTIP'),
      },
      {
        name: this.translate.instant('CLAIMS.PR_SHARES'),
        prop: 'pr',
        flexGrow: 0.7,
        cellClass: 'align-right-table-cell',
        headerClass: 'align-right-table-cell',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 90,
        headerTooltip: this.translate.instant('CLAIMS.PR_SHARES_TOOLTIP'),
      },
      {
        name: this.translate.instant('CLAIMS.MR_SHARES'),
        prop: 'mr',
        flexGrow: 0.7,
        cellClass: 'align-right-table-cell',
        headerClass: 'align-right-table-cell',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 90,
        headerTooltip: this.translate.instant('CLAIMS.MR_SHARES_TOOLTIP'),
      },
      {
        name: this.translate.instant('CLAIMS.IPI_BASE_NAME_NUMBER'),
        prop: 'ipiBaseNumber',
        flexGrow: 2.5,
      },
      {
        name: this.translate.instant('CLAIMS.IP_SOCIETY_PR_MR'),
        prop: 'prMr',
        flexGrow: 3,
        headerTooltip: this.translate.instant('CLAIMS.IP_SOCIETY_PR_MR_TOOLTIP'),
        tooltip: 'prMrTooltip',
        canHideTooltip: true,
      },
      {
        name: this.translate.instant('CLAIMS.RESOLUTION_OWNER'),
        prop: 'resolutionOwnerLabel',
        tooltip: 'resolutionOwnerTooltip',
        flexGrow: 2,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.ACTION_TYPE'),
        prop: 'actionType',
        flexGrow: 2.5,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.ACTION_DEADLINE'),
        prop: 'actionDeadline',
        flexGrow: 2,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.ACTION_RESPONSE'),
        prop: 'actionResponse',
        flexGrow: 2.5,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.ACTION_STATUS'),
        prop: 'actionStatus',
        flexGrow: 2,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.REDUCED_PR_SHARE'),
        prop: 'reducedPr',
        editable: row => of(!!editReducedShare && !!row.actionId && !row.reducePending && row.pr !== NOT_AVAILABLE && parseFloat(row.pr.replace(' %', ' ')) !== 0),
        onEdit: editReducedShare,
        cellClass: 'align-right-table-cell',
        headerClass: 'align-right-table-cell',
        headerTooltip: this.translate.instant('CLAIMS.REDUCED_PR_SHARE_TOOLTIP'),
        flexGrow: 3,
      },
      {
        cellClass: 'ice-warning',
        name: this.translate.instant('CLAIMS.ALERT'),
        prop: 'alert',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        hideTextProperty: true,
        icons: 'alertIcon',
        width: 90,
        flexGrow: 1,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.REDUCED_MR_SHARE'),
        prop: 'reducedMr',
        editable: row => of(!!editReducedShare && !!row.actionId && !row.reducePending && row.mr !== NOT_AVAILABLE && parseFloat(row.mr.replace(' %', '')) !== 0),
        onEdit: editReducedShare,
        cellClass: 'align-right-table-cell',
        headerClass: 'align-right-table-cell',
        headerTooltip: this.translate.instant('CLAIMS.REDUCED_MR_SHARE_TOOLTIP'),
        flexGrow: 3,
      },
      {
        prop: 'resolverCheck',
        flexGrow: 1,
        sortable: false,
        icons: (!enableCheckResolver && 'resolverCheckIcons') || undefined,
        canAutoResize: false,
        resizeable: false,
        checkboxable: enableCheckResolver,
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.RESOLVER'),
        width: 60,
        headerTooltip: ' ',
      },
      {
        prop: 'claimantStatusLabel',
        flexGrow: 3,
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COUNTERCLAIM_RELATION'),
        icons: 'claimantStatusIcons',
        cellClass: 'claims-claimant-status-cell',
        onClickAction: onEditStatus,
        canAutoResize: false,
        resizeable: false,
        sortable: false,
        width: 120,
        headerTooltip: ' ',
      },
      {
        name: '',
        cellClass: 'delete',
        actionButtonIcon: 'delete',
        sortable: false,
        prop: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => (onDelete && onDelete(row)) || (() => {}),
      },
      {
        prop: 'relation',
        flexGrow: 2.5,
        name: this.translate.instant('CLAIMS.RELATION'),
        headerTooltip: ' ',
      },
      {
        prop: 'resolutionOwner',
        name: this.translate.instant('CLAIMS.RESOLUTION_OWNER'),
        cellClass: 'ice-justify-center ice-select-mt-0 resolution-owner-cell',
        defaultValue: '',
        flexGrow: 1,
        icons: 'resolutionOwnerIcons',
        selectOptions: resolutionOwnerOptions,
        onClickAction: onEditStatus,
        hideIfEmpty: row => !!row.detail,
        onEdit: onEditResolutionOwner,
        width: 150,
        canAutoResize: false,
        resizeable: false,
        sortable: false,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.INFO'),
        prop: 'viewInfo',
        flexGrow: 2,
        hideActionButton: row => of(!row.actionId || !ReducedActionResponses.includes(get(row, 'actionResponseRaw', ''))),
        actionButtonIcon: 'remove_red_eye',
        actionButtonTooltip: this.translate.instant('CLAIMS.REMOVE_RED_EYE_TOOLTIP'),
        action: row => viewReduceClaim && viewReduceClaim(row),
        headerTooltip: ' ',
      },
    ];
  }
  getCounterClaimDetailsDataTable({
    onEditStatus = null,
    onDelete = null,
    enableCheckResolver = null,
    resolutionOwnerOptions = null,
    onEditResolutionOwner = null,
    clickIPINumber = null,
    editReducedShare = null,
    viewClaims = null,
    clickAgreement = null,
    displaySelectColumn = false,
  }) {
    return this.getDataTable(
      onEditStatus,
      onDelete,
      enableCheckResolver,
      resolutionOwnerOptions,
      onEditResolutionOwner,
      clickIPINumber,
      editReducedShare,
      viewClaims,
      clickAgreement,
    )
      .map(item => {
        switch (item.prop) {
          case 'relation':
            return { ...item, width: displaySelectColumn ? 100 : 152 };
          case 'role':
          case 'postTermCollectionDate':
          case 'pr':
          case 'mr':
            return { ...item, width: 80 };
          case 'name':
            return { ...item, width: 220 };
          case 'refLabel':
          case 'prMr':
            return { ...item, width: 150 };
          case 'endDate':
          case 'allRights':
          case 'actionDeadline':
            return { ...item, width: displaySelectColumn ? 100 : 120 };
          case 'actionResponse':
            return { ...item, width: displaySelectColumn ? 120 : 100 };
          case 'viewInfo':
            return {
              ...item,
              width: 160,
              hideActionButton: row =>
                of(!row?.actionId || (!ReducedActionResponses.includes(get(row, 'actionResponseRaw', '')) && !WithDrawActionTypes.includes(get(row, 'action.type', '')))),
              actionButtonIcon: 'remove_red_eye',
              tooltip: 'actionButtonTooltip',
              canHideTooltip: false,
              actionButtonTooltip: '',
              action: row => {
                const actionType = row?.action?.type;
                const data = actionType.includes('REDUCE') ? row : row.action;
                return viewClaims?.(data);
              },
              headerTooltip: ' ',
            };
          case 'actionType':
            return { ...item, width: displaySelectColumn ? 190 : 200 };
          case 'rowSelected': {
            return { ...item, flexGrow: 1, width: 52, name: 'Select' };
          }
          default:
            return { ...item, width: 100 };
        }
      })
      .map(item => ({ ...item, sortable: false, canAutoResize: false }));
  }

  getDataTableCounterClaimCreation(onEditStatus?): DataTableRow[] {
    return this.getDataTable(onEditStatus).map(item => {
      switch (item.prop) {
        case 'role': {
          return { ...item, flexGrow: 1, width: 100 };
        }
        case 'name':
        case 'prMr': {
          return { ...item, flexGrow: 2 };
        }
        case 'refLabel':
        case 'startDate':
        case 'territoriesTisa':
        case 'status': {
          return { ...item, flexGrow: 1.5 };
        }
        case 'endDate': {
          return { ...item, flexGrow: 1 };
        }
        case 'inDispute':
        case 'pr':
        case 'mr': {
          return { ...item, width: 100 };
        }
        case 'alert': {
          return { ...item, width: 120, headerTooltip: '', sortable: false };
        }
        case 'resolverCheck': {
          return { ...item, icons: 'resolverCheckIcons' };
        }
        default: {
          return item;
        }
      }
    });
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }
}
