import { FormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceLayout } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { IP_TYPE_LEGAL_ENTITY } from 'config/constants/ips.constants';
import { CopyrightIpsDataTable } from 'config/data-table-builders/copyright.ips';
import { SearchCopyrightIpsForm } from 'config/search-form-builders/search-copyright-ips';
import { SectionsConfig } from 'config/sections-config';
import { clone } from 'lodash';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';

export class DialogLayoutSearchParty {
  constructor(
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
    private showTitle: boolean,
    private isWorkRegister = false,
  ) {}

  /**
   *
   * @param searchPartyType specifies the store space to save the search results
   * @param onSelectItem callback executed when user select item from search results
   */
  getLayout(
    searchPartyType: string,
    onSelectItem,
    extraSearchParams = {},
    backButton = true,
    includedOriginalItem = false,
    backButtonOnClick = null,
    customTitle = null,
    baseType = null,
  ): IceLayout {
    const { translate, translationLoader } = this;
    const copyrightIpsDatatable = new CopyrightIpsDataTable(translate, translationLoader, this.store);
    const searchIpsForm = new SearchCopyrightIpsForm(translate, translationLoader);
    const title = customTitle || this.translate.instant('SELECT_ORGANIZATION_POPUP.TITLE_EDIT_MODE_DIALOG');
    const backButtonConfig = {
      tooltip: this.translate.instant('POPUP_COMMON.BACK'),
      nextLayout: 0,
      icon: 'arrow_back',
    };
    if (backButtonOnClick) {
      backButtonConfig['onClick'] = backButtonOnClick;
    }
    let formData: FormGroup;
    const defaultValues = searchIpsForm.getDefaultValues(searchPartyType, null, baseType);

    const formButtons = [
      {
        className: 'ice-accent',
        type: 'button',
        templateOptions: {
          text: this.translate.instant('SELECT_ORGANIZATION_POPUP.RESET'),
          btnType: ' ice-accent',
          materialType: 'mat-button',
          onClick: () => {
            this.storeNewItem.dispatch(
              new fromForm.NewItemDoSearch({
                search: { ...defaultValues, ...extraSearchParams },
                section: SectionsConfig.IPS.name,
                type: searchPartyType,
                reset: true,
              }),
            );
          },
        },
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            formData = field.form;
          },
        },
      },
      {
        className: 'ice-accent',
        type: 'button',
        templateOptions: {
          type: 'submit',
          text: this.translate.instant('SELECT_ORGANIZATION_POPUP.SEARCH'),
          color: 'accent',
          materialType: 'mat-flat-button',
          isDisabled: () => {
            return !formData?.dirty || !formData?.valid;
          },
          disablePreventDefault: true,
        },
      },
    ];

    return {
      className: 'dialog-wrapper-85vw-90vh',
      title: of(
        this.isWorkRegister && baseType === IP_TYPE_LEGAL_ENTITY
          ? this.translate.instant('WORKS.WORK_CREATION.SEARCH_PUBLISHER_SUBMITTER')
          : this.translate.instant('SELECT_ORGANIZATION_POPUP.SEARCH_CLAIMANT'),
      ),
      actions: backButton ? [backButtonConfig] : [],
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                formClass: 'bg-filter-form bg-filter-form-search-ips',
                formBuilder: of(searchIpsForm.getForm(searchPartyType, null, baseType, formButtons)),
                model: this.getSearchIpsFormModel(searchPartyType),
                submit: $event =>
                  this.storeNewItem.dispatch(
                    new fromForm.NewItemDoSearch({
                      search: { ...$event, ...extraSearchParams },
                      section: SectionsConfig.IPS.name,
                      type: searchPartyType,
                      reset: true,
                      includedOriginalItem,
                    }),
                  ),
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'cardWithDataTable',
              config: {
                title:
                  this.isWorkRegister && baseType === IP_TYPE_LEGAL_ENTITY
                    ? this.translate.instant('WORKS.WORK_CREATION.PUBLISHER_SEARCH_RESULT')
                    : this.translate.instant('SELECT_ORGANIZATION_POPUP.CLAIMANT_SEARCH_RESULTS'),
                model: this.getSearchResults(searchPartyType),
                schema: copyrightIpsDatatable.getDataTable(),
                sorts: copyrightIpsDatatable.getDefaultSorting(),
                loadingIndicator: true,
                columnMode: copyrightIpsDatatable.getColumnMode(),
                messages: copyrightIpsDatatable.getMessages(),
                selectionType: of('multi'),
                onSelect: (selected: any[]) => onSelectItem(selected[0]),
              },
            },
          ],
        },
      ],
    };
  }

  // remove from dialog-search-party.ts this function and reuse this dialog-layout-search-party
  getSearchIpsFormModel(type) {
    return this.store.pipe(
      select(fromForm.getNewSectionSearch),
      map(search => {
        const typedSearch = search[type] || {};
        return clone(typedSearch);
      }),
    );
  }

  getSearchResults(type) {
    return this.storeNewItem.pipe(
      select(fromForm.getNewSectionSearchResult),
      map(searchResultsItem => {
        if (searchResultsItem) {
          const typedSearchResults = searchResultsItem[type] && searchResultsItem[type].items;
          return clone(typedSearchResults);
        }
        return null;
      }),
    );
  }
}
