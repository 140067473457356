import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils, DatatableUtils, DateTimeUtils } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { locale as uiMessagesEnglish } from '@ice/i18n/en/ui-messages';
import { AuditHistoryUtils } from '@ice/utils/audit-history/audit-history.utils';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { NotesUtils } from '@ice/utils/notes/notes.utils';
import { UsersUtils } from '@ice/utils/users/users.utils';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import * as fromApiCalls from 'config/api-calls';
import { getAuditEventNoteApiCall } from 'config/api-calls';
import { ALL, AUDIT_HISTORY_ADD_EVENT_DESCRIPTION, AUDIT_HISTORY_CREATED_EVENT_DESCRIPTION } from 'config/constants/global.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import { EVENT_ID, HISTORY_MULTIPLE_EVENTS_CLASS } from 'config/constants/works.constants';
import { AuditHistoryFilterConfig } from 'config/filter-builders/audit-history-filter';
import { SectionsConfig } from 'config/sections-config';
import * as diff from 'deep-diff';
import { capitalize, cloneDeep, concat, get, isArray, sortBy, toNumber, uniqBy } from 'lodash';
import { SelectOption } from 'models/copyright/formly/formly';
import { RowNote } from 'models/notes/notes';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../tabs-data';
import { ExpandableData } from './audit-history.model';

export abstract class TabAuditHistory implements SectionTabConfig {
  protected constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected dialog: MatDialog,
    protected permissionsService?: PermissionsService,
  ) {
    this.getItemId();
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(uiMessagesEnglish);
    this.checkAllowedShowUserDetails();
    this.checkCounterclaims();
  }

  protected isAllowedShowUserDetails = false;
  private _itemId: string;
  private isNotesVisible = false;
  protected get itemId(): string {
    return this._itemId;
  }
  protected set itemId(value: string) {
    this._itemId = value;
    this.getAuditEventsUsers();
  }
  protected section: string;
  protected auditTypes: string[];
  private createFromKey = 'emittedStartDate';
  private createToKey = 'emittedEndDate';
  private userIdKey = 'userId';
  private eventDescriptionKey = 'eventDescription';
  private versionKey = 'version';
  private eventId = EVENT_ID;
  private eventsWithNotes = 'eventsWithNotes';
  private workSourceId = 'workSourceId';
  private createFromControl;
  private createToControl;
  private dateValidationInProcess = false;
  private conflictsExpandableProperty$ = new BehaviorSubject('detail');
  private tableOneSort = [{ prop: 'date', dir: 'desc' }];
  private sectionText: string;
  private eventDescriptionDefaultOption;
  public addDetailsOnMainDataTable = false;
  private noteModel$ = new BehaviorSubject({ ...NotesUtils.DEFAULT_NEW_NOTE });
  private noteSubmitVisible$ = new BehaviorSubject(false);
  private auditEventUsers: Subject<SelectOption[]> = new Subject();
  private rowFixedDetails$ = new Subject<any>();
  private canViewWorkHistoryNotes = this.permissionsService.can('view_history_notes');
  private get tableActionButtonIcon() {
    return this.isNotesVisible ? 'check_box' : 'check_box_outline_blank';
  }
  private formData: FormGroup;
  private tableActionButton = {
    icon: this.tableActionButtonIcon,
    text: 'Show notes',
    class: 'mat-white-icon',
    onClick: () => {
      this.isNotesVisible = !this.isNotesVisible;
      if (this.isNotesVisible) {
        this.rowFixedDetails$.next({ property: 'note', class: 'audit-note-detail' });
      } else {
        this.rowFixedDetails$.next(null);
      }
      this.tableActionButton.icon = this.tableActionButtonIcon;
    },
  };
  protected abstract getTableOneModel(): Observable<any[]>;

  protected abstract onSelectRow(row: any[]);

  protected abstract onSelectExpandable(expandableData: ExpandableData);

  protected getItemId() {
    this.store
      .pipe(
        select(fromRoot.getCopyrightDetailBySection),
        filter(item => !!get(item, 'attributes.id')),
        take(1),
      )
      .subscribe(item => {
        this.itemId = item?.attributes?.id || item?.admin?.attributes?.id || '';
      });
    this.store
      .pipe(
        select(fromRoot.getRouterSection),
        filter(section => !!section),
        take(1),
      )
      .subscribe(section => {
        this.section = section;
        this.sectionText = this.getSectionText(section);
        this.eventDescriptionDefaultOption = {
          value: ALL,
          label: `${capitalize(this.sectionText)} ${this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.ALL_EVENTS')}`,
        };
      });
  }

  checkCounterclaims() {
    this.store.select(fromRoot.getCounterClaimList).subscribe((counterClaim: any[]) => {
      if (counterClaim?.length) {
        AuditHistoryFilterConfig.doSubmit(this.formData, this.store, this.itemId, this.auditTypes, this.getSortValue(this.tableOneSort));
      }
    });
  }

  getSectionText(section) {
    if (section === SectionsConfig.AGREEMENT_GROUP.auditTypes[0]) {
      return section.replace('-', ' ');
    } else if (section.replace('-', '').slice(0, -1) === SectionsConfig.COUNTERCLAIMS.auditTypes[0]) {
      return SectionsConfig.COUNTERCLAIMS.auditTypes[0];
    }

    return section.slice(0, -1);
  }
  checkAllowedShowUserDetails() {
    this.store
      .pipe(select(fromRoot.getUserDetail), take(1))
      .subscribe(
        userDetail => (this.isAllowedShowUserDetails = UsersUtils.getOrganizationFromOrganizationID(userDetail.organizationId).toLocaleLowerCase() === OrganizationType.ice),
      );
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              formClass: 'bg-filter-form',
              title: this.translate.instant('USERS.DETAILS.FORM.TITLE'),
              titleClass: 'ice-display-none',
              model: this.store.select(fromRoot.getAuditHistoryFilter).pipe(map(model => cloneDeep(model))),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getFilterSchema(),
            },
          },
        ],
      },
      ...this.getResultsCard(),
    ];
  }

  getFilterSchema() {
    let formData: FormGroup;

    const formButtons = [
      {
        className: 'flex-1 ice-accent audit-reset-button',
        type: 'button',
        templateOptions: {
          text: this.translate.instant('FORM_RESET'),
          btnType: ' ice-accent',
          materialType: 'mat-button',
          onClick: () =>
            this.store
              .select(fromRoot.getAuditHistoryFilter)
              .pipe(take(1))
              .subscribe(auditOriginalFilter => {
                formData.get(this.createFromKey).setValue(auditOriginalFilter?.initialEmittedStartDate || moment());
                formData.get(this.createToKey).setValue(auditOriginalFilter?.initialEmittedEndDate || moment());
                formData.get(this.userIdKey).setValue('*');
                formData.get(this.eventDescriptionKey).setValue(ALL);
                formData.get(this.eventId).setValue('');
                formData.get(this.eventsWithNotes).setValue(false);
                formData.get(this.workSourceId).setValue(undefined);
                AuditHistoryFilterConfig.doSubmit(formData?.value || {}, this.store, this.itemId, this.auditTypes, this.getSortValue(this.tableOneSort));
              }),
        },
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            formData = field.form;
            this.formData = field.form;
          },
        },
      },
      {
        className: 'flex-1 ice-accent audit-apply-button',
        type: 'button',
        templateOptions: {
          text: this.translate.instant('FORM_APPLY_FILTER'),
          color: 'accent',
          materialType: 'mat-flat-button',
          onClick: event => {
            const itemId = formData?.value?.workSourceId ?? this.itemId;
            const cleanValues = { ...formData?.value, eventDescription: formData?.value?.eventDescription?.value || '', workSourceId: itemId };
            AuditHistoryFilterConfig.doSubmit(cleanValues || {}, this.store, itemId, this.auditTypes, this.getSortValue(this.tableOneSort));
          },
          isDisabled: () => formData?.status !== 'VALID',
        },
      },
    ];

    const secondLineCommonFilter: FormlyFieldConfig = {
      fieldGroupClassName: 'display-flex ice-justify-right group-inputs ice-accent',
      fieldGroup: [
        {
          className: 'flex-1 ice-mr-20',
          key: this.eventsWithNotes,
          type: 'checkbox',
          defaultValue: false,
          hideExpression: !this.canViewWorkHistoryNotes,
          templateOptions: {
            label: this.translate.instant('SHARED.AUDIT_HISTORY.FILTER.EVENTS_WITH_NOTE'),
            required: false,
          },
        },
        ...formButtons,
      ],
    };

    return [...this.getForm(), secondLineCommonFilter];
  }

  getForm() {
    const userSelect = {
      className: 'flex-6',
      type: 'ice-select',
      key: this.userIdKey,
      defaultValue: '*',
      templateOptions: {
        label: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.USER'),
        required: false,
        options: this.auditEventUsers.pipe(map(users => concat([{ value: '*', label: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.ALL_USERS') }], users))),
      },
    };
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          DatepickerUtils.getDatepickerField({
            key: this.createFromKey,
            label: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CREATE_FROM'),
            controlFieldToAssign: this.createFromControl,
            datepickerOptions: {
              filter: (date: Date) => (!this.createToControl?.value ? true : moment(date).isBefore(this.createToControl.value)),
            },
            extraValidators: {
              endDateSameOrAfterStartDate: {
                expression: (control: FormControl) => {
                  const controlValue = DateTimeUtils.getIndefiniteDate(control.value);
                  const otherDateControl = control.parent.controls[this.createToKey];
                  const otherDateControlValue = DateTimeUtils.getIndefiniteDate(otherDateControl.value);
                  this.dateValidationInProcess = DatepickerUtils.changeDateValidationInProcessValue(this.dateValidationInProcess, otherDateControl);
                  DatepickerUtils.validateOtherDate(otherDateControl, this.dateValidationInProcess);
                  return (
                    !controlValue ||
                    otherDateControlValue === '' ||
                    DatepickerUtils.isOtherDateValidated(otherDateControl) ||
                    moment(controlValue).isSameOrBefore(otherDateControlValue)
                  );
                },
                message: (error, field: FormlyFieldConfig) => this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CREATE_FROM_SMAE_OR_BEFORE_CREATE_TO_ERROR'),
              },
              empyStartDateValidation: {
                expression: (control: FormControl) => !!control.value,
                message: (error, field: FormlyFieldConfig) => this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.START_DATE_EMPTY_ERROR'),
              },
            },
            required: true,
            translate: this.translate,
            extraClass: 'audit-create-from flex-3',
          }),
          DatepickerUtils.getDatepickerField({
            key: this.createToKey,
            label: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CREATE_TO'),
            controlFieldToAssign: this.createToControl,
            datepickerOptions: {
              filter: (date: Date) => (!this.createFromControl?.value ? true : moment(date).isBefore(this.createFromControl.value)),
            },
            extraValidators: {
              endDateSameOrAfterStartDate: {
                expression: (control: FormControl) => {
                  const controlValue = DateTimeUtils.getIndefiniteDate(control.value);
                  const otherDateControl = control.parent.controls[this.createFromKey];
                  const otherDateControlValue = DateTimeUtils.getIndefiniteDate(otherDateControl.value);
                  this.dateValidationInProcess = DatepickerUtils.changeDateValidationInProcessValue(this.dateValidationInProcess, otherDateControl);
                  DatepickerUtils.validateOtherDate(otherDateControl, this.dateValidationInProcess);
                  return (
                    !controlValue ||
                    otherDateControlValue === '' ||
                    DatepickerUtils.isOtherDateValidated(otherDateControl) ||
                    moment(controlValue).isSameOrAfter(otherDateControlValue)
                  );
                },
                message: (error, field: FormlyFieldConfig) => this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CREATE_TO_SAME_OR_AFTER_CREATE_FROM_ERROR'),
              },
            },
            required: false,
            translate: this.translate,
            extraClass: 'audit-create-to',
          }),
          ...(this.isAllowedShowUserDetails ? [userSelect] : []),
          {
            className: 'flex-1 flex-grow-5',
            type: 'ice-autocomplete',
            key: this.eventDescriptionKey,
            defaultValue: ALL,
            templateOptions: {
              label: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.EVENT_DESCRIPTION'),
              options: this.store.pipe(
                select(fromRoot.getCopyrightAuditEventTypes),
                withLatestFrom(this.store.select(fromRoot.getCopyrightAuditEventDescriptions)),
                map(([eventTypes, eventDescriptions]) => {
                  const counterClaimEventId = parseInt(AuditHistoryUtils.COUNTERCLAIMEVENTID, 10);
                  const normalizedAuditTypes = this.auditTypes.map(auditType => auditType.toLowerCase());
                  const normalizedEventDescriptions = eventDescriptions.map(toNumber);

                  return [
                    this.eventDescriptionDefaultOption,
                    ...eventTypes.filter(({ label, value }) => {
                      const normalizedLabel = label.toLowerCase();
                      const matchesAuditType = normalizedAuditTypes.some(auditType => normalizedLabel.startsWith(auditType));
                      const matchesEventDescription = normalizedEventDescriptions.includes(value);
                      const isCounterClaimEvent = value === counterClaimEventId;

                      return (matchesAuditType || matchesEventDescription || isCounterClaimEvent) && matchesEventDescription;
                    }),
                  ];
                }),
              ),
              required: false,
            },
          },
          {
            className: 'flex-6  ice-mt-0',
            wrappers: ['form-field', 'wrapper-input-text'],
            key: this.eventId,
            type: 'input',
            templateOptions: {
              label: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CUBE_AUDIT_ID.HEADER_LABEL'),
              disabled: false,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-4 select-work-source-history',
            key: this.workSourceId,
            type: 'ice-select',
            templateOptions: {
              label: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.WORK_MASTER_ID'),
              options: this.getRelatedWorks(),
            },
          },
        ],
      },
    ];
  }

  getRelatedWorks() {
    return this.store.pipe(
      select(fromRoot.getSourceWorks),
      withLatestFrom(this.store.select(fromRoot.getWorkKey)),
      map(([sourceWorks, workKey]) => {
        const sourceWorkWithCurrentWork = [...[{ id: workKey, keyWithoutPrefix: workKey }], ...sourceWorks];
        let orderedList = sortBy(sourceWorkWithCurrentWork, item => (item.key === workKey ? 0 : 1));
        orderedList = orderedList.map(relation => {
          return {
            label: relation.id === workKey ? `${relation.id} (master work)` : relation.id,
            value: CopyrightUtils.getKeySuffixWithPrefix(relation.keyWithoutPrefix),
          };
        });
        return CopyrightUtils.removeDuplicatesByProperty(orderedList, 'value');
      }),
    );
  }

  getAuditEventsUsers() {
    this.store.dispatch(
      new fromRoot.StartApiCall({
        apiCall: fromApiCalls.getAuditEventsUsersApiCall,
        apiCallData: { labels: { id: this.itemId } },
        callBack: data => {
          if (isArray(data)) {
            this.auditEventUsers.next(data.map(x => ({ value: x, label: x })));
          }
        },
      }),
    );
  }

  getResultsCard(): IceGroupComponent[] {
    const datatableMessages = DatatableUtils.getDataTableDefaultMessages(this.translate);

    datatableMessages.noResultsMessage.emptyMessage = this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.NO_RESULTS');

    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.TITLE'),
              class: 'audit-table',
              expandableNoHeader: true,
              model: this.getTableOneModel().pipe(
                map((items: any[]) => uniqBy(items, 'cubeAuditId')),
                map((items: any[]) => this.formatValues(items)),
                map((items: any[]) => {
                  return items.map(item => {
                    return {
                      ...item,
                      userId: this.isAllowedShowUserDetails ? item.userId : '*****',
                      note: (NotesUtils.convertNotesToRows(item.note, this.translate) as RowNote[])[0]?.note?.text ?? '',
                    };
                  });
                }),
              ),
              columnMode: 'flex',
              isSelectable: true,
              schema: this.getDatatableSchema(),
              sorts: this.tableOneSort,
              onSort: sortValue => (this.tableOneSort = sortValue),
              messages: datatableMessages,
              expandRowsEnabled: true,
              expandableProperty: this.conflictsExpandableProperty$,
              expandableSchema: {
                detail: this.getDatatableSchema(),
              },
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              onSelect: row => this.onSelectRow(row),
              onSelectExpandable: (expandableData: ExpandableData) => this.onSelectExpandable(expandableData),
              addDetailsOnMainDataTable: this.addDetailsOnMainDataTable,
              apiCall: fromApiCalls.getAuditHistory,
              rowFixedDetails: this.rowFixedDetails$,
              tableActionButton: !this.canViewWorkHistoryNotes ? null : this.tableActionButton,
            },
          },
        ],
      },
    ];
  }

  getDatatableSchema() {
    const userId = {
      cellClass: 'hide-cell',
      sortable: false,
      name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.USER_ID'),
      prop: 'userId',
      flexGrow: 1,
    };

    const schema = [
      { cellClass: 'cursor-text hide-child', name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.DATE'), prop: 'date', flexGrow: 1 },
      ...(this.isAllowedShowUserDetails ? [userId] : []),
      {
        cellClass: 'cursor-text',
        sortable: false,
        name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CUBE_AUDIT_ID.HEADER_LABEL'),
        prop: 'cubeAuditId',
        customButton: {
          text: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CUBE_AUDIT_ID.BUTTON_LABEL'),
          hoverText: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CUBE_AUDIT_ID.HOVER_LABEL'),
          buttonClass: 'cube-audit-id-button',
          hasTooltip: false,
          hideWhenNoValue: true,
        },
        flexGrow: 0.8,
      },
      {
        cellClass: 'cursor-text',
        sortable: false,
        name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.EVENT_DESCRIPTION'),
        prop: 'eventDescription',
        flexGrow: 2,
      },
      {
        cellClass: `cursor-text`,
        sortable: false,
        name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.ACTION'),
        prop: 'action',
        flexGrow: 0.5,
      },
      {
        cellClass: `cursor-text`,
        sortable: false,
        name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.NEW_VALUE'),
        prop: 'newValue',
        flexGrow: 2,
        isNoTooltipCell: true,
      },
      {
        cellClass: `cursor-text`,
        sortable: false,
        name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.PREVIOUS_VALUE'),
        prop: 'oldValue',
        flexGrow: 2,
        isNoTooltipCell: true,
      },
      {
        name: '',
        prop: '',
        icons: 'noteIcon',
        hideTextProperty: true,
        flexGrow: 0.5,
        action: row => this.openNotePopup(row),
      },
    ];
    if (!this.canViewWorkHistoryNotes) {
      return schema.filter((c: any) => c.icons !== 'noteIcon');
    }
    return schema;
  }

  getSortValue(tableSort: { prop: string; dir: string }[]): string {
    const value = get(tableSort, '[0].dir');
    return !!tableSort.length ? `attributes.createdDate:${value}` : '';
  }

  openNotePopup(row) {
    this.noteSubmitVisible$.next(false);
    this.store.dispatch(new fromRoot.CleanResponse());
    const notesUtils = new NotesUtils(this.translate, this.store, this.dialog);
    const currentTimeUTC = new Date().toISOString();
    const dialogClass = 'dialog-wrapper-event-note';

    if (row?.hasNote) {
      this.store.dispatch(
        new fromRoot.StartApiCall({
          apiCall: getAuditEventNoteApiCall,
          apiCallData: { labels: { id: row.cubeAuditId } },
          callBack: (result, error) => {
            const notes = get(result, 'attributes.notes');
            const noteResult: RowNote[] = NotesUtils.convertNotesToRows(notes, this.translate);
            const noteText = get(noteResult, '[0].note.text');
            this.noteModel$.next({ ...NotesUtils.DEFAULT_NEW_NOTE, note: noteText });

            if (notes) {
              const extraFields = [
                {
                  className: 'flex-1 ice-accent edit-event-note-button',
                  type: 'button',
                  templateOptions: {
                    text: this.translate.instant('NOTES.BUTTONS.EDIT_EVENT_NOTE'),
                    btnType: ' ice-accent',
                    materialType: 'mat-button',
                    onClick: () => {
                      noteDialogRef['componentInstance'].setLayout(1);
                      setTimeout(() => {
                        this.noteSubmitVisible$.next(true);
                      }, 300);
                    },
                  },
                },
              ];

              const noteDialogRef = notesUtils.openDialog(
                this.translate.instant('NOTES.DIALOG_TITLES.VIEW_EVENT_NOTE'),
                NotesUtils.getDialogForm(currentTimeUTC, this.translate, true, extraFields, false, true),
                this.noteModel$,
                this.translate.instant('NOTES.BUTTONS.SAVE'),
                this.translate.instant('NOTES.BUTTONS.CANCEL'),
                $event => this.onCreateOrUpdateNote($event, notesUtils, row),
                null,
                null,
                this.noteSubmitVisible$,
                true,
                {
                  title: this.translate.instant('NOTES.DIALOG_TITLES.EDIT_EVENT_NOTE'),
                  formBuilder: of(NotesUtils.getDialogForm(currentTimeUTC, this.translate, true, null, true)),
                },
                'ice-blue-button',
                dialogClass,
              );
            } else {
              this.openNotePopupHasNotNote(row, notesUtils, currentTimeUTC, dialogClass);
            }
          },
        }),
      );
    } else {
      this.openNotePopupHasNotNote(row, notesUtils, currentTimeUTC, dialogClass);
    }
  }

  openNotePopupHasNotNote(row, notesUtils, currentTimeUTC, dialogClass) {
    notesUtils.openDialog(
      this.translate.instant('NOTES.DIALOG_TITLES.ADD_EVENT_NOTE'),
      NotesUtils.getDialogForm(currentTimeUTC, this.translate, true, null, true),
      of({}),
      this.translate.instant('NOTES.BUTTONS.SAVE'),
      this.translate.instant('NOTES.BUTTONS.CANCEL'),
      $event => this.onCreateOrUpdateNote($event, notesUtils, row),
      null,
      null,
      null,
      null,
      null,
      null,
      dialogClass,
    );
  }

  onCreateOrUpdateNote(event, notesUtils, row) {
    const newNote = { ...event };
    newNote['userId'] = notesUtils.userName;
    const oldNotes = {};
    const finalNotes = NotesUtils.updateNote(oldNotes, newNote);
    notesUtils.updateNotesStore(finalNotes, 'cube-audits', row.cubeAuditId);
  }

  formatValues(values: any[]): any[] {
    const highlightValue = value => {
      return value.eventDescription.includes(AUDIT_HISTORY_CREATED_EVENT_DESCRIPTION) || value.eventDescription.includes(AUDIT_HISTORY_ADD_EVENT_DESCRIPTION) ? false : true;
    };
    return values.map(item => {
      const detail = item.detail.map(detailItem => {
        return {
          ...detailItem,
          newValue: this.parseAuditHistoryValuesToHtml(detailItem.newValue, detailItem.oldValue, highlightValue(detailItem)),
          oldValue: this.parseAuditHistoryValuesToHtml(detailItem.oldValue),
          jsonNewValue: detailItem.newValue,
          jsonOldValue: detailItem.oldValue,
        };
      });

      return {
        ...item,
        userId: this.cleanUserIdHTMLTags(item.userId),
        newValue: this.parseAuditHistoryValuesToHtml(item.newValue, item.oldValue, highlightValue(item)),
        oldValue: this.parseAuditHistoryValuesToHtml(item.oldValue),
        jsonNewValue: item.newValue,
        jsonOldValue: item.oldValue,
        detail,
      };
    });
  }

  cleanUserIdHTMLTags(userId: string): string {
    return userId?.split('<')[0];
  }
  parseAuditHistoryValuesToHtml(jsonString = '', oldValue = '{}', highlightValue = false): string {
    let valueYAML = jsonString || '';
    const oldValueYAML = oldValue || '';
    let parsedObject;
    let parsedOldObject;
    let isJSON = true;

    const hasMultipleEventsOrNull = jsonString ? jsonString.includes(HISTORY_MULTIPLE_EVENTS_CLASS) : true;
    try {
      parsedObject = JSON.parse(jsonString);
      parsedOldObject = JSON.parse(oldValueYAML);
    } catch (error) {
      isJSON = false;
      if (!hasMultipleEventsOrNull) {
        valueYAML = AuditHistoryUtils.formatStringToYAML(jsonString, oldValue, highlightValue);
      }
    }
    if (!hasMultipleEventsOrNull && isJSON) {
      const firstObjectPropertyValue = Object.values(parsedObject)[0];
      const objectHasOneProperty = Object.keys(parsedObject).length === 1 && typeof firstObjectPropertyValue === 'object';
      const objectToConvert = objectHasOneProperty ? firstObjectPropertyValue : parsedObject;
      const differences = diff(parsedObject, parsedOldObject);
      valueYAML = AuditHistoryUtils.formatToYAML(objectToConvert, highlightValue, differences);
    }
    return valueYAML;
  }
}
