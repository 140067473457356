import { ClaimGraphUtils, DateTimeUtils, IpUtils, WorkUtils } from '@ice';
import { AuditHistoryUtils } from '@ice/utils/audit-history/audit-history.utils';
import { get } from 'lodash';
import { SearchAgreementsParser } from 'models/search-parsers/search-agreements-parser';
import { SearchIpsParser } from 'models/search-parsers/search-ips-parser';
import { createPropertyCleaner, createResultCleaner } from '../api-call.factory';

const addRelationshipResultCleaner = createResultCleaner((value, detail, data, translate, tabName) => [
  ...(detail[data.key]?.items || []),
  ...((value && SearchIpsParser.IpSearchCleaner([IpUtils.editIpRelations(value, data)], translate, ['rawItem', 'baseInformation'])) || []),
]);

const deleteRelationshipResultCleaner = createResultCleaner(
  (value, detail, data, translate, tabName) =>
    (value &&
      detail[data.key] &&
      (detail[data.key]?.items || []).map(ips =>
        [ips.key, ips.id].includes(value.id)
          ? { ...SearchIpsParser.IpSearchCleaner([IpUtils.editIpRelations(value, data)], translate, ['rawItem', 'baseInformation'])[0], deleted: true }
          : ips,
      )) ||
    null,
);

export const setIpRelationshipResultCleaner = createResultCleaner((value, detail, data, translate, tabName) =>
  data.type === 'add' ? addRelationshipResultCleaner(value, detail, data, translate) : deleteRelationshipResultCleaner(value, detail, data, translate),
);

export const setRelationshipPropertyCleaner = createPropertyCleaner((value, detail, data, translate, tabName) => data.key);

export const getClaimGraphResultCleaner = createResultCleaner(
  (value, detail, data, translate, tabName, prevResponse, user) =>
    value && ClaimGraphUtils.getClaimView(value['claims'], translate, data?.filterTerritories || prevResponse, user.detail),
);

export const getWorksToAddResultCleaner = createResultCleaner((value, detail, data, translate) => WorkUtils.workSearchCleaner(value.items, '', false, '', translate));

export const getItemsCleaner = createResultCleaner(result => (result && result.items) || []);

export const getAgreementsResultCleaner = createResultCleaner((value, detail, data, translate) => SearchAgreementsParser.parse(value.items, translate));

export const getAuditHistoryResultCleaner = createResultCleaner(
  (value, oldDetail, cleanerData, translate) => (value && AuditHistoryUtils.formatAuditHistoryJoinXrefs(value, translate, get(oldDetail, 'auditTypes[0]'))) || [],
);

export const getAuditHistoryFilterEndDateResultCleaner = createResultCleaner((value, oldDetail, cleanerData, translate) => {
  const initialEmittedEndDate = get(value, 'items[0].attributes.createdDate')
    ? DateTimeUtils.getIndefiniteDate(DateTimeUtils.formatDate(get(value, 'items[0].attributes.createdDate')))
    : '';
  return {
    ...oldDetail.auditHistoryFilter,
    initialEmittedEndDate,
    emittedEndDate: initialEmittedEndDate,
  };
});

export const getAuditHistoryFilterStartDateResultCleaner = createResultCleaner((value, oldDetail, cleanerData, translate) => {
  const initialEmittedStartDate = get(value, 'items[0].attributes.createdDate') ? DateTimeUtils.formatDate(get(value, 'items[0].attributes.createdDate')) : '';
  return {
    ...oldDetail.auditHistoryFilter,
    initialEmittedStartDate,
    emittedStartDate: initialEmittedStartDate,
  };
});

export const getCounterResultCleaner = createResultCleaner(value => {
  return value.total;
});

export const getEventTypesResultCleaner = createResultCleaner((value, detail, data, translate, tabName) => (value && AuditHistoryUtils.cleanEventTypes(value)) || []);

export const getEventDescriptionsResultCleaner = createResultCleaner((value, detail, data, translate, tabName) => value || []);
