import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { combineFormDateFields, ConflictUtils, CounterClaimUtils, DateTimeUtils, getFormlyRow } from '@ice';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { locale as englishTabs } from 'assets/i18n/en/config/tabs-data-builders';
import { CountersBaseQuery, InformedPartyBaseQuery } from 'config/constants/counter-claims.constants';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { map, split } from 'lodash';
import { FormConfig, SearchExpressions } from './form-config';

export class ActionsSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    switch (key) {
      case 'workRef':
        const valueArray = value.split(':');
        const addPrefix = valueArray.length > 1 ? false : true;
        if (prefix) {
          if (addPrefix) {
            return `{"wildcard":{"counterclaim.work.relations[XREF].otherId":"*${prefix}:${value}*"}}`;
          } else {
            return `{"wildcard":{"counterclaim.work.relations[XREF].otherId":"*${value}*"}}`;
          }
        }
        return `{"wildcard":{"counterclaim.work.relations[XREF].otherId":"*${value}*"}}`;
      case 'actionResolver':
        return `{"wildcard":{"counterclaimParticipant.claimant.relations[XREF].otherId":"*${value}*"}}`;
      case 'id':
        return `{"wildcard":{"id":"*${value}*"}}`;
      case 'actionAssignedUser':
        return `{"wildcard":{"attributes.assignor":"*${value}*"}}`;
      case 'counterclaimType':
        return `{"equals":{"counterclaim.attributes.type":"${value}"}}`;
      case 'counterclaimStatus':
        return `{"equals":{"counterclaim.attributes.status":"${value}"}}`;
      case 'counterclaimCreatedDate':
        return `{"equals":{"counterclaim.attributes.createdDate":"${DateTimeUtils.formatDate(moment(value))}"}}`;
      case 'actionStatus':
        return `{"or": [${map(split(value, ','), val => `{"equals": {"attributes.status":"${val}"}}`).toString()}]}`;
      case 'actionType':
        return `{"equals":{"attributes.type":"${value}"}}`;
      case 'resolutionOwners':
        return `{"equals":{"counterclaimParticipant.resolutionOwner":"${value}"}}`;
      case 'actionOwner':
        return `{ "or": [{ "wildcard": { "ownerName.relations[XREF].otherId": "IPI:*${value}*" }}, { "equals": { "attributes.owner": "ICE:${value}" }}]}`;
      case 'actionUser':
        return `{"wildcard":{"attributes.assignor":"*${value}*"}}`;
      case 'actionDeadlineFrom':
        return `{"range":{"attributes.deadline":{"gte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
      case 'actionDeadlineTo':
        return `{"range":{"attributes.deadline":{"lte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
      case 'actionResponse':
        return `{"equals":{"attributes.response":"${value}"}}`;
      case 'counterclaimId':
        return `{"wildcard":{"counterclaim.id":"*${value}*"}}`;
      case 'actionDeadline':
        return `{"equals":{"attributes.deadline":"${DateTimeUtils.formatDate(moment(value))}"}}`;
      case 'actionDeadlineRange':
        return `{"range":{"attributes.deadline":{
              "gte": "${value.split('_')[0]}",
              "lte": "${value.split('_')[1]}"
            }}}`;
      case 'dashboardQueryType':
        if (value === 'INFORMED_PARTY') {
          return InformedPartyBaseQuery.replace('<<extra>>', '').replace('<<pattern>>', prefix);
        }
        return CountersBaseQuery.replace('<<extra>>', '')
          .replace('<<pattern>>', prefix)
          .replace('<<typeFilter>>', `${CounterClaimUtils.getDashboardTypeFilter(value)}`);
    }
    return `{"wildcard":{"attributes.${key}":"*${value}*"}}`;
  }

  getExtraSearchExpression() {
    return '';
  }
}

export class SearchConflictsCounterClaimsActionsForm implements FormConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishTabs);
  }

  getActionFormFields(): FormlyFieldConfig[] {
    let subsDeadlineFrom: Subscription;
    let subsDeadlineTo: Subscription;

    const createdDateName = 'counterclaimCreatedDate';
    const createdDateLabel = this.translate.instant('ACTIVITY.CREATION_DATE');
    const translate = this.translate;
    const createdDateExtraClass = 'flex-1';

    const deadlineFromName = 'actionDeadlineFrom';
    const deadlineFromLabel = this.translate.instant('ACTIVITY.ACTION_DEADLINE_FROM');
    const deadlineFromExtraClass = 'flex-1';
    const deadlineFromHooks = {
      onInit: field => {
        subsDeadlineFrom = field.formControl.valueChanges.subscribe(value =>
          combineFormDateFields(field.form.controls, ['actionDeadlineFrom', 'actionDeadlineTo'], 'actionDeadlineRange'),
        );
      },
      onDestroy: () => {
        subsDeadlineFrom.unsubscribe();
      },
    };
    const sinceFieldConfig = DatepickerUtils.getDatepickerSimpleField({
      key: deadlineFromName,
      label: deadlineFromLabel,
      translate,
      extraClass: deadlineFromExtraClass,
      hooks: deadlineFromHooks,
    });

    const deadlineToName = 'actionDeadlineTo';
    const deadlineToLabel = this.translate.instant('ACTIVITY.ACTION_DEADLINE_TO');
    const deadlineToExtraClass = 'flex-1';
    const deadlineToHooks = {
      onInit: field => {
        subsDeadlineTo = field.formControl.valueChanges.subscribe(value =>
          combineFormDateFields(field.form.controls, ['actionDeadlineFrom', 'actionDeadlineTo'], 'actionDeadlineRange'),
        );
      },
      onDestroy: () => {
        subsDeadlineTo.unsubscribe();
      },
    };
    const toFieldConfig = DatepickerUtils.getDatepickerSimpleField({
      key: deadlineToName,
      label: deadlineToLabel,
      translate,
      extraClass: deadlineToExtraClass,
      hooks: deadlineToHooks,
    });

    DatepickerUtils.createDatepickerRange({ translate, sinceFieldConfig, toFieldConfig });

    return [
      {
        className: '',
        key: 'actionDeadlineRange',
        type: 'input',
        hide: true,
      },
      {
        className: 'flex-1',
        key: 'workRef',
        wrappers: ['form-field', 'wrapper-input-text'],
        type: 'input',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ACTIVITY.WORK_REF'),
        },
      },
      DatepickerUtils.getDatepickerSimpleField({
        key: createdDateName,
        label: createdDateLabel,
        translate,
        extraClass: createdDateExtraClass,
      }),
      {
        className: 'flex-1',
        key: 'counterclaimType',
        type: 'select',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ACTIVITY.COUNTERCLAIM_TYPE'),
          options: ConflictUtils.getConflictTypeOptions(this.translate),
        },
      },
      {
        className: 'flex-1',
        key: 'counterclaimStatus',
        type: 'select',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ACTIVITY.COUNTERCLAIM_STATUS'),
          options: ConflictUtils.getStatusOptions(this.translate),
        },
      },
      {
        className: 'flex-1',
        key: 'actionAssignedUser',
        wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
        type: 'input',
        templateOptions: {
          type: 'text',
          infoText: this.translate.instant('ACTIVITY.ACTION_ASSIGNED_USER_INFO'),
          placeholder: this.translate.instant('ACTIVITY.ACTION_ASSIGNED_USER'),
        },
      },
      {
        className: 'flex-1',
        key: 'id',
        wrappers: ['form-field', 'wrapper-input-text'],
        type: 'input',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ACTIVITY.ACTION_ID'),
        },
      },
      {
        className: 'flex-1',
        key: 'resolutionOwners',
        type: 'select',
        templateOptions: {
          options: ConflictUtils.getAllCounterClaimsResolutioOwnerTypesOptions(this.translate),
          type: 'text',
          placeholder: this.translate.instant('ACTIVITY.RESOLUTION_OWNERS'),
        },
      },
      {
        className: 'flex-1',
        key: 'actionResolver',
        wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
        type: 'input',
        templateOptions: {
          type: 'text',
          infoText: this.translate.instant('ACTIVITY.ACTION_OWNER_INFO'),
          placeholder: this.translate.instant('ACTIVITY.ACTION_RESOLVER'),
        },
      },
      {
        className: 'flex-1',
        key: 'actionResponse',
        type: 'select',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ACTIVITY.ACTION_RESPONSE'),
          options: ConflictUtils.getActionResponseOptions(this.translate),
        },
      },
      {
        className: 'flex-1',
        key: 'counterclaimId',
        wrappers: ['form-field', 'wrapper-input-text'],
        type: 'input',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ACTIVITY.COUNTERCLAIM_ID'),
        },
      },
      {
        className: 'flex-1',
        key: 'actionType',
        type: 'select',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ACTIVITY.ACTION_TYPE'),
          options: ConflictUtils.getActionTypesOptions(this.translate),
        },
      },
      {
        className: 'flex-1',
        key: 'actionStatus',
        type: 'ice-select',
        templateOptions: {
          optgroupClass: 'primary-checkbox',
          type: 'text',
          placeholder: this.translate.instant('ACTIVITY.ACTION_STATUS'),
          multiple: true,
          options: ConflictUtils.getActionStatusOptions(this.translate),
        },
      },
      {
        className: 'flex-1',
        key: 'actionOwner',
        wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
        type: 'input',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ACTIVITY.ACTION_OWNER'),
          infoText: this.translate.instant('ACTIVITY.ACTION_OWNER_INFO'),
        },
      },
      sinceFieldConfig,
      toFieldConfig,
    ];
  }

  getDashboardForm(): FormlyFieldConfig[] {
    const formFields = this.getActionFormFields();
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: getFormlyRow(['workRef', 'counterclaimId', 'resolutionOwners', 'counterclaimType', 'counterclaimStatus'], formFields),
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: getFormlyRow(['actionType', 'actionStatus', 'actionOwner', 'counterclaimCreatedDate', 'actionDeadlineFrom', 'actionDeadlineTo'], formFields),
      },
    ];
  }

  getForm(): FormlyFieldConfig[] {
    const formFields = this.getActionFormFields();
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: getFormlyRow(['id', 'actionAssignedUser', 'resolutionOwners', 'actionResolver', 'actionDeadlineFrom', 'actionDeadlineTo', 'actionDeadlineRange'], formFields),
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: getFormlyRow(['counterclaimId', 'counterclaimType', 'actionType', 'actionStatus', 'actionResponse', 'actionOwner', 'workRef'], formFields),
      },
    ];
  }
}
