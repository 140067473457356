import { TrimClaimsConfig } from 'models';
import { PutNewIpPayload, SearchResultCleaned } from '@ice';
import { Action } from '@ngrx/store';
import { NewIpType } from 'config/constants/ips.constants';
import { IceErrorPayload } from 'models/action-payloads/ice-error-payload';

export const SAVE_FIELD = '[NewSectionItem] Save Field Data';
export const SAVE_NEW_SECTION_ITEM = '[NewSectionItem] Save';
export const SAVE_NEW_SECTION_ITEM_FAIL = '[NewSectionItem] Save Fail';
export const SAVE_NEW_SECTION_ITEM_SUCCESS = '[NewSectionItem] Save Success';
export const RESET_NEW_SECTION_ITEM = '[NewSectionItem] Reset';
export const SET_IS_VALID_AN_CHANGED_FORM = '[NewSectionItem] Set Is Valid And Changed Form';
export const NEW_ITEM_CLEAN_SEARCH = '[NewItemDoSearch] New Item Clean Search';
export const NEW_ITEM_DO_SEARCH = '[NewItemDoSearch] New Item Do a Search';
export const NEW_ITEM_DO_SEARCH_FAIL = '[NewItemSearch] New Item Search Fail';
export const NEW_ITEM_DO_SEARCH_SUCCESS = '[NewItemSearch] New Item Search Success';
export const NEW_ITEM_DO_SEARCH_NEXT_PAGE = '[NewItemSearch] New Item Search Next Page';
export const NEW_ITEM_RESET_SEARCH_RESULTS = '[NewItemSearch] New Item Delete Search Criteria and Results';
export const DUPLICATE_NEW_ITEM = '[NewItemSearch] Duplicate New Item';
export const NEW_ITEM_RESET_RESPONSE = '[NewItemSearch] New Item Reset Response';
export const NEW_ITEM_CLOSE_POPUP = '[NewItemSearch] New Item Close Popup';
export const NEW_ITEM_OPEN_SUMMARY_POPUP = '[NewItemSearch] New Item Open Summary Popup';
export const MARK_POPUP_OK = '[NewItemSearch] Mark popup Ok button submit';
export const UNMARK_POPUP_OK = '[NewItemSearch] Unmark popup Ok button submit';
export const SET_INITIAL_FIELDS = '[NewSectionItem] Set Initial Fields';
export const SET_FIELDS = '[NewSectionItem] Set Fields';
export const NEW_ITEM_SELECT_SHAREPIC_IPS = '[NewSectionItem] Set SharePicture Ips';
export const NEW_ITEM_UNSELECT_SHAREPIC_IPS = '[NewSectionItem] Unset SharePicture Ips';
export const NEW_ITEM_DIALOG_PRE_SELECT_SHAREPIC_IPS = '[NewSectionItem] Dialog PreSelect SharePicture Ips';
export const UPDATE_COUNTER_CLAIM_PARTIES_STATUS = '[NewSectionItem] Update Counter Claim Parties Status';
// Actions to create new IPs in AGREEMENT NEW/UPDATE
export const SET_CURRENT_NEW_IP = '[NewSectionItem] Set Current New Ip';
export const PUT_NEW_IP = '[NewSectionItem] Put New Ip';
export const PUT_NEW_IP_SUCCESS = '[NewSectionItem] Put New Ip Success';
export const PUT_NEW_IP_FAIL = '[NewSectionItem] Put New Ip Fail';
export const NEW_ITEM_DIALOG_ADD_IP_SELECTION = '[NewSectionItem] Add ip to dialog selection';
export const NEW_ITEM_DIALOG_REMOVE_IP_SELECTION = '[NewSectionItem] Remove ip from dialog selection';
export const TERMINATE_CLAIM = '[NewSectionItem] Terminate Claim';
export const DELETE_CLAIM = '[NewSectionItem] Delete Claim';
export const SET_NEW_SECTION_LOADING = '[NewSectionItem] Set New Section Loading';
export const SET_CLAIMANT_GROUPS = '[NewSectionItem] Set Claimant Groups';
export const MARK_IS_CLONING = '[NewSectionItem] Set New Section IsCloning';
export const ADD_DOCUMENT = '[NewSectionItem] Add document';
export const SAVE_DOCUMENT = '[NewSectionItem] Save document';
export const SAVE_DOCUMENT_FAIL = '[NewSectionItem] Save document Fail';
export const SAVE_DOCUMENT_SUCCESS = '[NewSectionItem] Save document Success';
export const DELETE_DOCUMENT = '[NewSectionItem] Delete document';
export const DELETE_DOCUMENT_FAIL = '[NewSectionItem] Delete document Fail';
export const DELETE_DOCUMENT_SUCCESS = '[NewSectionItem] Delete document Success';
export const SET_IS_CHANGED = '[NewSectionItem] Set Is Changed';
export const DELETE_DOCUMENTS = '[NewSectionItem] Delete documents';
export const DELETE_DOCUMENTS_FAIL = '[NewSectionItem] Delete documents Fail';
export const DELETE_DOCUMENTS_SUCCESS = '[NewSectionItem] Delete documents Success';
// Edit actions
export const TRIM_CLAIMS = '[Edit] Trim claims';

export class SaveField implements Action {
  readonly type = SAVE_FIELD;
  constructor(public payload: any, public noRemoveOldFields = false) {}
}

export class SetInitialFields implements Action {
  readonly type = SET_INITIAL_FIELDS;
  constructor(public payload: any) {}
}

export class TerminateClaim implements Action {
  readonly type = TERMINATE_CLAIM;
  constructor(public payload: { workId: string; claimIds: string[]; terminationDate: string; postTermCollectionDate: string }) {}
}

export class DeleteClaim implements Action {
  readonly type = DELETE_CLAIM;
  constructor(public payload: { workId: string; claimIds: string[] }) {}
}

export class SetFields implements Action {
  readonly type = SET_FIELDS;
  constructor(public payload: any) {}
}

export class SaveNewSectionItem implements Action {
  readonly type = SAVE_NEW_SECTION_ITEM;

  constructor(public payload?: { section: string; fields: any }) {}
}

export class SaveNewSectionItemFail implements Action {
  readonly type = SAVE_NEW_SECTION_ITEM_FAIL;

  constructor(public payload?: IceErrorPayload) {}
}

export class SaveNewSectionItemSuccess implements Action {
  readonly type = SAVE_NEW_SECTION_ITEM_SUCCESS;
  constructor(public payload: any) {}
}

export class ResetNewSectionItem implements Action {
  readonly type = RESET_NEW_SECTION_ITEM;
}

export class SetIsValidAndChangedForm implements Action {
  readonly type = SET_IS_VALID_AN_CHANGED_FORM;

  constructor(public payload: boolean) {}
}
export class NewItemCleanSearch implements Action {
  readonly type = NEW_ITEM_CLEAN_SEARCH;

  constructor() {}
}

export class NewItemDoSearch implements Action {
  readonly type = NEW_ITEM_DO_SEARCH;

  constructor(public payload: { search: any; section: string; type: string; reset?: boolean; includedOriginalItem?: boolean }) {}
}

export class NewItemDoSearchFail implements Action {
  readonly type = NEW_ITEM_DO_SEARCH_FAIL;

  constructor(public payload: Error | any) {}
}

export class NewItemDoSearchSuccess implements Action {
  readonly type = NEW_ITEM_DO_SEARCH_SUCCESS;

  constructor(public payload: SearchResultCleaned) {}
}

export class NewItemDoSearchNextPage implements Action {
  readonly type = NEW_ITEM_DO_SEARCH_NEXT_PAGE;

  constructor(public payload: { type: string; section: string }) {}
}

export class NewItemResetSearchResults implements Action {
  readonly type = NEW_ITEM_RESET_SEARCH_RESULTS;

  constructor(public payload: { type: string }) {}
}

export class DuplicateNewItem implements Action {
  readonly type = DUPLICATE_NEW_ITEM;
}

export class NewItemResetResponse implements Action {
  readonly type = NEW_ITEM_RESET_RESPONSE;
}

export class NewItemClosePopup implements Action {
  readonly type = NEW_ITEM_CLOSE_POPUP;
}

export class NewItemOpenSummaryPopup implements Action {
  readonly type = NEW_ITEM_OPEN_SUMMARY_POPUP;
}

export class MarkPopupOk implements Action {
  readonly type = MARK_POPUP_OK;
}

export class UnmarkPopupOk implements Action {
  readonly type = UNMARK_POPUP_OK;
}

export class SetCurrentNewIp implements Action {
  readonly type = SET_CURRENT_NEW_IP;

  constructor(public payload: NewIpType) {}
}

export class PutNewIp implements Action {
  readonly type = PUT_NEW_IP;

  constructor(public payload: PutNewIpPayload) {}
}

export class PutNewIpSuccess implements Action {
  readonly type = PUT_NEW_IP_SUCCESS;

  constructor(public payload: any) {}
}

export class PutNewIpFail implements Action {
  readonly type = PUT_NEW_IP_FAIL;

  constructor(public payload: any) {}
}

export class NewItemSelectSharePicIps implements Action {
  readonly type = NEW_ITEM_SELECT_SHAREPIC_IPS;

  constructor(public payload?: any[]) {}
}

export class NewItemUnselectSharePicIps implements Action {
  readonly type = NEW_ITEM_UNSELECT_SHAREPIC_IPS;

  constructor(public payload?: any) {}
}

export class NewItemDialogAddSharePicIp implements Action {
  readonly type = NEW_ITEM_DIALOG_ADD_IP_SELECTION;

  constructor(public payload: any) {}
}

export class NewItemDialogRemoveSharePicIp implements Action {
  readonly type = NEW_ITEM_DIALOG_REMOVE_IP_SELECTION;

  constructor(public payload: any) {}
}

export class NewItemDialogPreSelectSharePicIps implements Action {
  readonly type = NEW_ITEM_DIALOG_PRE_SELECT_SHAREPIC_IPS;

  constructor(public payload?: any[]) {}
}

export class UpdateCounterClaimPartiesStatus implements Action {
  readonly type = UPDATE_COUNTER_CLAIM_PARTIES_STATUS;

  constructor(public payload?: any[]) {}
}

export class SetNewSectionLoading implements Action {
  readonly type = SET_NEW_SECTION_LOADING;

  constructor(public payload?) {}
}
export class SetClaimantGroups implements Action {
  readonly type = SET_CLAIMANT_GROUPS;

  constructor(public payload?: any[]) {}
}
export class MarkIsCloning implements Action {
  readonly type = MARK_IS_CLONING;
  constructor(public payload: boolean) {}
}

export class SetIsChanged implements Action {
  readonly type = SET_IS_CHANGED;
  constructor(public payload: boolean) {}
}

export class AddDocument implements Action {
  readonly type = ADD_DOCUMENT;
  constructor(public payload: any) {}
}
export class SaveDocument implements Action {
  readonly type = SAVE_DOCUMENT;
  constructor(public onValidate: any) {}
}
export class SaveDocumentFail implements Action {
  readonly type = SAVE_DOCUMENT_FAIL;
  constructor(public result: any, public onValidate: Function) {}
}
export class SaveDocumentSuccess implements Action {
  readonly type = SAVE_DOCUMENT_SUCCESS;
  constructor(public result: any, public onValidate: Function) {}
}
export class DeleteDocument implements Action {
  readonly type = DELETE_DOCUMENT;
  constructor(public payload) {}
}
export class DeleteDocumentFail implements Action {
  readonly type = DELETE_DOCUMENT_FAIL;
  constructor(public payload) {}
}
export class DeleteDocumentSuccess implements Action {
  readonly type = DELETE_DOCUMENT_SUCCESS;
  constructor(public payload) {}
}
export class DeleteDocuments implements Action {
  readonly type = DELETE_DOCUMENTS;
  constructor(public payload) {}
}
export class DeleteDocumentsFail implements Action {
  readonly type = DELETE_DOCUMENTS_FAIL;
  constructor() {}
}
export class DeleteDocumentsSuccess implements Action {
  readonly type = DELETE_DOCUMENTS_SUCCESS;
  constructor() {}
}

export class TrimClaims implements Action {
  readonly type = TRIM_CLAIMS;
  constructor(public payload: TrimClaimsConfig) {}
}

export type NewSectionItemActions =
  | SaveField
  | SaveNewSectionItem
  | SaveNewSectionItemFail
  | SaveNewSectionItemSuccess
  | ResetNewSectionItem
  | SetIsValidAndChangedForm
  | NewItemCleanSearch
  | NewItemDoSearch
  | NewItemDoSearchFail
  | NewItemDoSearchSuccess
  | NewItemResetSearchResults
  | DuplicateNewItem
  | NewItemResetResponse
  | NewItemClosePopup
  | NewItemOpenSummaryPopup
  | MarkPopupOk
  | UnmarkPopupOk
  | SetInitialFields
  | SetFields
  | SetCurrentNewIp
  | PutNewIp
  | PutNewIpSuccess
  | PutNewIpFail
  | NewItemSelectSharePicIps
  | NewItemUnselectSharePicIps
  | NewItemDialogAddSharePicIp
  | NewItemDialogRemoveSharePicIp
  | NewItemDialogPreSelectSharePicIps
  | UpdateCounterClaimPartiesStatus
  | NewItemDoSearchNextPage
  | TerminateClaim
  | DeleteClaim
  | SetNewSectionLoading
  | SetClaimantGroups
  | MarkIsCloning
  | AddDocument
  | SaveDocument
  | SaveDocumentFail
  | SaveDocumentSuccess
  | DeleteDocument
  | DeleteDocumentFail
  | DeleteDocumentSuccess
  | SetIsChanged
  | DeleteDocuments
  | DeleteDocumentsFail
  | DeleteDocumentsSuccess
  | TrimClaims;
