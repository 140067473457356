export const locale = {
  lang: 'en',
  data: {
    ROLES: {
      AG: 'Graphic Designer',
      AP: 'Analyst/Programer',
      AS: 'Author Of Screenplay/Author Of Dialogue',
      AT: 'Architect',
      CD: 'Costume-Designer',
      CG: 'Choreographer',
      CM: 'Director Of Photography/Cinematograph',
      CP: 'Author Computer Graphic',
      CT: 'Cartoonist',
      DA: 'Data Architect',
      DD: 'Dubbing Director',
      DG: 'Designer',
      DS: 'Author Of Subtitles/Dubbing',
      DW: 'Drawer,Graphic Artist',
      EB: 'Book Publisher',
      EJ: 'Journal/Periodical/Magazine Publisher',
      EM: 'Music Publisher',
      EP: 'Newspaper Publisher',
      ET: 'Film Editor',
      FA: 'Author Of Fine Art',
      FD: 'Film Distributor',
      JO: 'Journalist',
      LY: 'Lyricist/Librettist',
      MC: 'Musical Creator (Composer)',
      MD: 'Multimedia Director',
      MS: 'Stage Director',
      PC: 'Producer',
      PD: 'Production Designer (Set-Designer)',
      PH: 'Photographer',
      PR: 'Press Agency',
      PW: 'Playwright',
      RE: 'Film Director',
      ST: 'Sound Engineer',
      WP: 'Author/Poet',
    },
    GLOBAL_RIGHTS: {
      MR: 'Mechanical',
      PR: 'Performing',
      SY: 'Synchronization',
      RP: 'Print',
    },
    RIGHTS: {
      ALL: 'All',
      // MECHANICAL
      BT: 'Blank Tape Remuneration',
      MA: 'Mechanical Right Radio',
      MB: 'Mechanical Right - non-interactive',
      MD: 'Mechanical Right - interactive',
      MR: 'Mechanical Right Sound Carrier',
      MT: 'Mechanical Right TV',
      MV: 'Mechanical Right Video',
      RL: 'Rental and Lending Right',
      SY: 'Synchronisation Right',

      // PERFORMING
      MP: 'Motion Picture Exhibition Right',
      OB: 'Making Available Right - non-interactive',
      OD: 'Making Available Right - interactive',
      PC: 'Right of Public Performance of a Recording',
      PR: 'Performing Right',
      PT: 'Theatrical Performing Right',
      RB: 'Radio Broadcast Right',
      RT: 'Retransmission Right',
      TB: 'Television Broadcast Right',
      TO: 'Right of Public Performance of Radio Broadcast',
      TP: 'Right of Retransmission of Performance',
      TV: 'Right of Public Performance of TV Broadcast',
      ER: 'Educational Right',

      // PRINTING
      RP: 'Reproduction right',
    },
    ERROR: {
      UPDATE: 'Server Error updating data',
      NEW_PARTY: 'Error creating the party',
      NEW_PARTY_NAME: 'Error creating the party name',
      NOT_FOUND: 'Not found',
    },
    SEARCH: {
      ORGANIZATIONS_AUTOCOMPLETE: 'Organizations',
    },
    DELETE: {
      DELETE_SUCCESS: 'was deleted correctly.',
    },
    ACTIONS: {
      COPY_TO_CLIPBOARD: 'Copy {{message}} to clipboard',
      COPIED_TO_CLIPBOARD: 'Copied {{message}} to clipboard',
      DOWNLOAD_COMPLETE: 'Download complete',
    },
    CLAUSES: {
      LP: 'Local Lang Prohibited',
      REVRG: 'Revisionary Right',
      SO: 'Self Admin Online',
      SY: 'Sync Rights Admin',
      ULA: 'US License is ASCAP',
      ULB: 'US License is BMI',
      ULS: 'US License is SESAC',
      NM: 'NO MPRT',
      CC: 'Creative Commons-IP',
    },
    POST_TERM_COLLECTION: {
      LABEL: 'Post-term Collection',
      NONE: 'None',
      UNTIL_DATE: 'Until Date',
      INDEFINITE: 'Indefinite',
      END_DATE: 'Post-term Collection End Date',
    },
  },
};
