import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { getParentSelector } from '@ice';
import { ColorType } from '@ice/dynamic-components/group-component/group-component';
import { locale as english } from '@ice/i18n/en/form-container';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { cloneDeep, isEqual, isNull, mapValues, omitBy, uniqueId } from 'lodash';
import { Observable, Subject, combineLatest } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { FormlyService } from 'services/formly/formly.service';

@Component({
  selector: 'ice-form',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormContainerComponent implements OnDestroy, OnInit {
  @Input() formBuilder: FormlyFieldConfig[];
  @Input() extraFormBuilder: FormlyFieldConfig[];
  @Input() model: any;
  @Input() extraModel: any;
  @Input() resetAvailable = false;
  @Input() submitAvailable = true;
  @Input() submitLabel: string;
  @Input() submitEnabled = false;
  @Input() forceSubmitDisabled = false;
  @Input() button1Available = false;
  @Input() button1Label: string;
  @Input() button1Enabled = false;
  @Input() form = new FormGroup({});
  @Input() extraForm = new FormGroup({});
  @Input() extraActionAvailable = false;
  @Input() extraActionColor = ColorType.accent;
  @Input() extraActionLabel: string;
  @Input() isFlatButton: boolean;
  @Input() submitButtonIcon: string;
  @Input() button1ButtonIcon: string;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() button1Clicked: EventEmitter<any> = new EventEmitter<void>();
  @Output() extraActionClick: EventEmitter<any> = new EventEmitter<void>();
  @Output() resetClick: EventEmitter<any> = new EventEmitter<void>();
  @Output() emitChange: EventEmitter<any> = new EventEmitter();
  @Output() emitValidForm: EventEmitter<boolean> = new EventEmitter();
  @Input() submitInlineAvailable = false;
  @Input() filterToggleConfig: { label: string; checked: boolean; onChange: (event) => void };
  @Input() submitShortcutEnable = false;
  @Input() formInline = false;
  @Input() className = '';
  @Input() avoidResetModel = false;
  @Input() submitClass = '';
  @Input() showSubmit: Observable<boolean>;
  @Input() button1CustomClass: string;
  @Input() changeInitModel: Observable<string>;

  options: FormlyFormOptions = {};
  extraOptions: FormlyFormOptions = {};
  unsubscribeAll = new Subject();
  initialModel = {};
  formlyId = `form ${uniqueId()}`;
  lastSection = null;
  lastInitModel = null;

  constructor(private fuseTranslationLoader: FuseTranslationLoaderService, private formlyService: FormlyService, private changeDetectorService: ChangeDetectorRef) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  @HostListener('keydown.enter', ['$event']) onKeydownHandler(event) {
    const isToolbar = !!getParentSelector(event.target, 'ice-toolbar');
    if (isToolbar && event.target.tagName !== 'MAT-SELECT') {
      event.target.blur();
      if (this.checkValidForm()) {
        this.emitSubmit();
      }
    }
  }

  @HostListener('window:keyup', ['$event']) onKeyDown(event) {
    const isToolbar = !!getParentSelector(event.target, 'ice-toolbar');
    if (this.submitShortcutEnable && !isToolbar && event.altKey && event.shiftKey && event.key === 'Enter') {
      event.target.blur();
      if (this.checkValidForm()) {
        this.emitSubmit();
      }
    }
  }

  private checkValidForm() {
    return this.setSubmitEnabled() && this.model && !this.form.invalid && ((this.extraFormBuilder && !this.extraForm.invalid) || !this.extraFormBuilder);
  }

  ngOnInit() {
    this.initialModel = cloneDeep(this.model);
    combineLatest([this.form.valueChanges.pipe(delay(200)), this.form.statusChanges])
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(e => {
        this.emitChange.emit(cloneDeep(this.model));
        this.emitValidForm.emit(this.form.valid);
      });
    this.formlyService.attachFormlyConfig({ form: this.form, options: this.options, formBuilder: this.formBuilder, model: this.model, id: this.formlyId });
    if (this.changeInitModel) {
      this.changeInitModel.subscribe(section => {
        if (!!this.lastSection && section !== this.lastSection) {
          setTimeout(() => {
            // Hacky timeout to update multi selects which have options model as observable and we need to use the default value once options are loaded
            // Example: https://ice-cube.atlassian.net/browse/CAR-94
            this.lastInitModel = this.getCleanedModel();
            this.form.patchValue(mapValues(this.form.value, value => value || null));
          });
        }
        this.lastSection = section;
      });
    }
  }

  protected hasData() {
    return this.model && Object.values(this.model).some(el => !!el);
  }

  setSubmitEnabled() {
    return (
      this.form.valid &&
      !isEqual(
        mapValues(omitBy(this.model, isNull), value => value || null),
        this.lastInitModel,
      ) &&
      this.forceSubmitEnabled() &&
      !this.forceSubmitDisabled
    );
  }

  forceSubmitEnabled() {
    return this.submitEnabled || this.button1Enabled || this.form.dirty;
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.lastInitModel = this.getCleanedModel();
    if (this.model && !this.form.invalid && ((this.extraFormBuilder && !this.extraForm.invalid) || !this.extraFormBuilder)) {
      this.emitSubmit();
    }
  }

  onReset(e) {
    if (!this.avoidResetModel) {
      this.model = cloneDeep(this.initialModel);
    }
    e.stopPropagation();
    this.resetClick.emit(this.form);
  }

  onButton1(e) {
    e.stopPropagation();
    this.button1Clicked.emit(this.model);
  }

  onExtraButtonClick(e) {
    e.stopPropagation();
    this.extraActionClick.emit(this.model);
  }

  private emitSubmit() {
    this.submit.emit(cloneDeep(this.model));
  }

  ngOnDestroy() {
    this.formlyService.detachFormlyConfig(this.formlyId);
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.submit.unsubscribe();
  }

  getCleanedModel() {
    return omitBy(
      mapValues(this.form.value, value => value || null),
      isNull,
    );
  }
}
