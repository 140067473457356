import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { DatatableUtils } from '@ice';

import { ColumnMode } from '@swimlane/ngx-datatable';
import { DataTableBuilder } from './data-table-builder';

export class CopyrightAgreementsXrefDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, protected store) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'label', dir: 'desc' }];
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.TERM'), prop: 'label', flexGrow: 1 },
      { name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.DATE'), prop: 'value', flexGrow: 1, cellClass: 'bold-table-cell' },
      { prop: 'groupRelation', flexGrow: 1 },
    ];
  }

  transformRows(rows) {
    return DatatableUtils.getDatatableSimpleListFromRows(
      rows,
      this.translate,
      {
        group: { label: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_LIST_LABELS.AGREEMENT_GROUP_REFERENCE') },
        society: { label: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_LIST_LABELS.SOCIETY_AGREEMENT_REFERENCE') },
        publisher: { label: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_LIST_LABELS.PUBLISHER_AGREEMENT_REFERENCE') },
      },
      {
        included: { label: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.AGREEMENT_GROUP_RELATION.INCLUDED') },
        related: { label: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.AGREEMENT_GROUP_RELATION.RELATED') },
      },
    );
  }
}
