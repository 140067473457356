import { Injectable } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {
  AgreementDetail,
  ApiNamespace,
  CC_STATUS_CLOSED,
  ClaimsUtils,
  CopyrightDetailRequest,
  CopyrightUtils,
  CounterClaimDocumentCurrentState,
  CounterClaimUtils,
  DateTimeUtils,
  getTrimmedClaims,
  IpUtils,
  SearchItemCleaned,
  SearchResult,
  TrimScopeOptions,
  WorkDetail,
} from '@ice';
import { SectionSendItemCleaner } from '@ice/utils/maps/send-update-map';
import { NotesUtils } from '@ice/utils/notes/notes.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/app/common/shared';
import { SEARCH_TYPE_DASHBOARD } from 'config/constants/counter-claims.constants';
import { TIMEOUT_MAX } from 'config/constants/response-errors-constants';
import { INCOME_PARTICIPANT_VALUE } from 'config/constants/works.constants';
import { environment } from 'config/env';
import { SectionsConfig } from 'config/sections-config';
import { SectionRouterViews } from 'config/sections-config/section-router-views';
import { sections } from 'config/sections-config/sections';
import { cloneDeep, first, get, tail } from 'lodash';
import { EMPTY, forkJoin, from, merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, timeoutWith, withLatestFrom, toArray } from 'rxjs/operators';
import { DetailService } from 'services/detail/detail.service';
import { SaveItemService } from 'services/save-item/save-item.service';
import { SearchService } from 'services/search/search.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';
import { CopyrightItemView, GetUserOrganizations } from 'store/root';
import * as fromRootActions from 'store/root/actions';
import * as fromFeature from 'store/root/reducers';
import { DATE_FORMAT } from 'config/constants/global.constants';
import moment from 'moment';
import * as fromActions from '../actions';
import * as fromSelectors from '../selectors';
import * as fromState from '../state';

@Injectable()
export class FormEffects {
  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.NEW_ITEM_DO_SEARCH),
      map((action: fromActions.NewItemDoSearch) => action.payload),
      withLatestFrom(
        this.newSectionStore.pipe(select(fromRoot.getGlobalApiNamespace)),
        this.newSectionStore.pipe(select(fromRoot.getUserNS)),
        this.newSectionStore.pipe(select(fromRoot.getUserCurrentOrganization)),
      ),
      map(([payload, ns, userNs, organization]: [{ search: any; section: string; type: string; reset: boolean; includedOriginalItem?: boolean }, ApiNamespace, string, any]) => ({
        ...payload,
        ns,
        userNs,
        organization,
      })),
      switchMap(request => {
        const { section, search, ns, type, userNs, reset, includedOriginalItem, organization } = request;
        const searchCopy = cloneDeep(search);
        if (reset) {
          searchCopy.from = 0;
        }
        const publisherList = (organization && organization.accessPartyNames) || [];
        const sectionData = CopyrightUtils.getSectionData(section);
        const searchNs = ((section === SectionsConfig.ORGANIZATIONS.name || section === SectionsConfig.USERS.name) && userNs) || ns;
        return this.searchService.getSearchResults(sectionData, { ...searchCopy, ns: searchNs, source: type, publisherList }).pipe(
          map((data: SearchResult) => {
            const resultsCleaner = type === SEARCH_TYPE_DASHBOARD ? 'homeConfig' : 'search';
            const cleanedItems: SearchItemCleaned[] = sections[section][resultsCleaner].cleaner.parse(
              (data && data.items) || data.data || data.territories || [],
              this.translate,
              {
                searchCopy,
                includedOriginalItem,
              },
              null,
              this.rootStore,
            );
            const { items, ...extra } = data;
            const typedItemExtra = { ...extra, type, reset };
            return new fromActions.NewItemDoSearchSuccess({ items: cleanedItems, extra: typedItemExtra });
          }),
          catchError(error => {
            return of(new fromActions.NewItemDoSearchFail(error));
          }),
        );
      }),
    ),
  );

  deleteOrTerminateClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.DELETE_CLAIM, fromActions.TERMINATE_CLAIM),
      withLatestFrom(this.newSectionStore.pipe(select(fromRoot.getGlobalApiNamespace))),
      switchMap(([action, ns]: [any, string]) => {
        const { workId, claimIds, terminationDate, postTermCollectionDate } = action.payload;
        const request$ =
          action.type === fromActions.DELETE_CLAIM
            ? this.detailService.deleteRequestCubeData(`${environment.apiUrl}/claims/${ns}/${workId}`, {}, undefined, { claimIds })
            : this.detailService.postRequestCubeData(`${environment.apiUrl}/claims/${ns}/${workId}/terminate`, {}, { claimIds, terminationDate, postTermCollectionDate });
        return request$.pipe(
          switchMap(response => this.detailService.checkStatus('claims', response)),
          map(response => new fromForm.SaveNewSectionItemSuccess(response)),
          catchError(error => {
            this.newSectionStore.dispatch(new fromRootActions.ShowDataLoadingVisibility(false));
            return of(new fromRoot.DeleteFail(error));
          }),
        );
      }),
    ),
  );

  newItemDoSearchNextPage = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.NEW_ITEM_DO_SEARCH_NEXT_PAGE),
      map((action: fromActions.NewItemDoSearchNextPage) => action.payload),
      withLatestFrom(this.newSectionStore.pipe(select(fromForm.getNewSectionSearchResult)), this.newSectionStore.select(fromForm.getNewSectionSearch)),
      filter(([payload, results, search]) => {
        const { type } = payload;
        return results[type] && search[type];
      }),
      map(([payload, results, search]) => {
        const { type, section } = payload;
        const searchFrom = results[type] && results[type].items && results[type].items.length;
        return new fromForm.NewItemDoSearch({ search: { ...search[type], from: searchFrom }, section: section || SectionsConfig.IPS.name, type });
      }),
    ),
  );

  saveNewSectionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SAVE_NEW_SECTION_ITEM),
      withLatestFrom(
        this.newSectionStore.pipe(select(fromSelectors.getNewSectionItemFieldsWithSection)),
        this.newSectionStore.pipe(select(fromRoot.getGlobalApiNamespace)),
        this.newSectionStore.pipe(select(fromRoot.getRouterView)),
        this.newSectionStore.pipe(select(fromRoot.getUserNS)),
        this.newSectionStore.pipe(select(fromRoot.getRouterPaths)),
        this.newSectionStore.pipe(select(fromRoot.getCopyrightDetailBySection)),
        this.newSectionStore.pipe(select(fromRoot.getEditingId)),
        this.newSectionStore.pipe(select(fromRoot.getCopyrightItemView)),
        this.newSectionStore.pipe(select(fromRoot.getEditingMultipleNamespaces)),
        this.newSectionStore.pipe(select(fromRoot.getUserName)),
        this.newSectionStore.pipe(select(fromFeature.getCopyrightFeatureState)),
        this.newSectionStore.pipe(select(fromRoot.getCreationId)),
        this.newSectionStore.pipe(select(fromRoot.getCreationMultipleNamespaces)),
      ),
      map(
        ([action, fields, ns, view, userNS, paths, detail, editingId, copyrightView, multipleNamespaces, userName, copyrightStore, createModeNs, creationMultipleNamespaces]) => ({
          ...fields,
          ns,
          view,
          userNS,
          paths,
          detail,
          editingId,
          multipleNamespaces,
          copyrightView,
          payload: action.payload,
          userName,
          copyrightStore,
          createModeNs,
          creationMultipleNamespaces,
        }),
      ),
      switchMap(fieldsWithSection => {
        const { fields, ns, view, userNS, detail, editingId, copyrightView, payload, multipleNamespaces, userName, createModeNs, creationMultipleNamespaces } = fieldsWithSection;
        const rodFromWork = fields.rodFromWork || false;
        let paths = fieldsWithSection.paths;
        if (rodFromWork) {
          paths = ['copyright', 'reports', 'new', 'CUBE013'];
        }
        const onBehalfOfSection = rodFromWork ? SectionsConfig.REPORTS.name : payload?.section;
        const onBehalfOfItem = payload?.fields;
        const section = onBehalfOfSection || fieldsWithSection.section;
        let itemMapped;
        let fieldsToClean;
        let nsRequest = section === 'organizations' ? userNS : ns;
        let isEdit = false;
        let subSection: string;
        if (paths.findIndex(path => path === 'new') === 3) {
          subSection = paths[4];
          fieldsToClean = DateTimeUtils.cleanObjectIndefiniteDateAliasToDate(onBehalfOfItem || detail);
          nsRequest = editingId;
          try {
            itemMapped = SectionSendItemCleaner[subSection](fieldsToClean, nsRequest, isEdit, fields, userName, paths);
            if (subSection === 'claim') {
              nsRequest = itemMapped?.attributes?.ns || nsRequest;
            }
          } catch (exception) {
            return of(new fromActions.SaveNewSectionItemFail(exception));
          }
        } else if (detail && detail.editClaim && detail.claims) {
          fieldsToClean = DateTimeUtils.cleanObjectIndefiniteDateAliasToDate(onBehalfOfItem || detail);

          const { linkCreator } = fields;
          subSection = 'claim';

          if (linkCreator.length > 0) {
            if (linkCreator.includes(INCOME_PARTICIPANT_VALUE)) {
              itemMapped = ClaimsUtils.updateLinkToIncomeParticipant(fieldsToClean, detail.claims, detail.editClaim);
            } else {
              // When linking a publisher to multiple creators, we create one publisher claim per creator. But, as we
              // already had one publisher claim (with no creator), that one is reused to be linked to the first creator.
              // Additionally, the shares from each creator need to be split to sum up the total shares of the publisher.
              itemMapped = ClaimsUtils.updateLinkCreatorInClaim({ fieldsToClean, linkCreator, claims: detail.claims, editClaim: detail.editClaim, role: detail.role });
            }
          } else {
            itemMapped = SectionSendItemCleaner['claim'](fieldsToClean, 'ICE', isEdit, fields);
          }
        } else {
          fieldsToClean = DateTimeUtils.cleanObjectIndefiniteDateAliasToDate(onBehalfOfItem || fields);
          nsRequest = createModeNs || get(first(creationMultipleNamespaces), 'editId') || nsRequest;
          isEdit = rodFromWork ? false : view === SectionRouterViews.edit || (view === SectionRouterViews.detail && copyrightView === CopyrightItemView.Edit);
          itemMapped = SectionSendItemCleaner[section](fieldsToClean, nsRequest, isEdit, null, userName, paths, sections);
        }
        const editSubSection = rodFromWork ? false : paths.length > 4 && paths[4];
        isEdit = isEdit || editSubSection === 'edit' || subSection === 'claim';
        const editKey = isEdit && get(detail, 'attributes.key');
        const sectionData = CopyrightUtils.getSectionData(section);
        const isWorkCreation = rodFromWork ? false : sectionData.name === SectionsConfig.WORKS.name && !(editKey || editSubSection);
        try {
          return this.saveItemService
            .saveItem({
              sectionConfig: sectionData,
              item: itemMapped,
              namespace: ns,
              editKey,
              nsOnBehalfOf: nsRequest,
              multipleNamespaces: multipleNamespaces || creationMultipleNamespaces,
              editSubSection,
            })
            .pipe(
              switchMap(response => {
                return this.detailService
                  .checkStatus(sectionData.name, response, null, null, editKey, false, isWorkCreation)
                  .pipe(
                    switchMap(checkResponse => {
                      switch (sectionData.name) {
                        case SectionsConfig.AGREEMENTS.name:
                          const agreementId = get(checkResponse, 'events[0].requestId');
                          const agreementRequest: CopyrightDetailRequest = {
                            section: SectionsConfig.AGREEMENTS.name,
                            key: agreementId,
                            customInclude: 'counterclaims.counterclaim.attributes',
                            ns,
                          };
                          return agreementId
                            ? this.detailService.getDetail(agreementRequest).pipe(
                                switchMap((agreementResult: AgreementDetail) => {
                                  const openedCounterclaims = (agreementResult?.counterclaims || []).filter(
                                    counterClaim => counterClaim?.counterclaim?.attributes?.status !== CC_STATUS_CLOSED,
                                  );
                                  const conflictIds = openedCounterclaims.map(obj => obj.counterclaim.attributes.id);
                                  return of({ conflictIds, ...checkResponse });
                                }),
                                catchError(error => of(checkResponse)),
                              )
                            : of(checkResponse);

                        case SectionsConfig.WORKS.name:
                          // TODO: This is a workaround until API supports creation under more than one NS https://ice-cube.atlassian.net/browse/WMG-433
                          if (creationMultipleNamespaces?.length > 1) {
                            const workNs = get(checkResponse, 'events[0].ns', nsRequest);
                            const workId = get(checkResponse, 'events[0].idVersion', nsRequest);
                            const workRequest: CopyrightDetailRequest = {
                              section: SectionsConfig.WORKS.name,
                              key: workId.substring(0, workId.length - 2),
                              customInclude: 'attributes,claims,contributions,relations',
                              ns: workNs,
                            };
                            return workId
                              ? this.detailService.getDetail(workRequest).pipe(
                                  switchMap((workDetail: WorkDetail) => {
                                    if (workDetail) {
                                      return this.saveItemService
                                        .saveItem({
                                          sectionConfig: sectionData,
                                          item: workDetail,
                                          namespace: ns,
                                          editKey: workRequest.key,
                                          nsOnBehalfOf: ns,
                                          multipleNamespaces: tail(creationMultipleNamespaces),
                                        })
                                        .pipe(
                                          switchMap(updateResponse => {
                                            return this.detailService.checkStatus(sectionData.apiSegment, updateResponse, null, null, true).pipe(
                                              switchMap(updateResponseStatus => {
                                                return of(checkResponse);
                                              }),
                                              catchError(error => of(checkResponse)),
                                            );
                                          }),
                                          catchError(error => of(checkResponse)),
                                        );
                                    } else {
                                      return of(checkResponse);
                                    }
                                  }),
                                  catchError(error => of(checkResponse)),
                                )
                              : of(checkResponse);
                          } else {
                            of(checkResponse);
                          }
                        case SectionsConfig.BULK_UPDATES.name:
                          // `results` is needed for bulk updates, as it contains an array of errors
                          return of({ ...checkResponse, results: response });
                        default:
                          return of(checkResponse);
                      }
                    }),
                  )
                  .pipe(timeoutWith(TIMEOUT_MAX, of(response)));
              }),
              switchMap((result: any) => {
                if (result.status === 'in-progress') {
                  return of(new fromActions.SaveNewSectionItemSuccess({ ...result, section }));
                }
                if (result && result.error) {
                  return of(new fromActions.SaveNewSectionItemFail({ message: 'Action failed. Please try again', severity: 'info', ...result.error }));
                }
                if (!result) {
                  return of(new fromActions.SaveNewSectionItemFail({ message: `Internal error saving new Item on ${section}` }));
                }
                if (section === SectionsConfig.AGREEMENTCONFLICT.name) {
                  const ccResult = { ...result, id: get(result, 'attributes.counterclaimId', '') };
                  return of(new fromActions.SaveNewSectionItemSuccess({ ...ccResult, section }));
                }
                return of(
                  ...[
                    new fromActions.SaveNewSectionItemSuccess({ ...result, section }),
                    ...(detail && detail.editClaim ? [new fromRoot.EditClaimSuccess()] : []),
                    ...(sectionData.apiSegment === SectionsConfig.ORGANIZATIONS.apiSegment ? [new GetUserOrganizations()] : []),
                  ],
                );
              }),
              catchError(error => {
                if (error?.error) {
                  return of(new fromActions.SaveNewSectionItemFail({ message: error.error }));
                }

                return of(new fromActions.SaveNewSectionItemFail(error));
              }),
            );
        } catch (error) {
          return of(new fromActions.SaveNewSectionItemFail());
        }
      }),
    ),
  );

  saveNewSectionItemSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SAVE_NEW_SECTION_ITEM_SUCCESS),
      withLatestFrom(
        this.newSectionStore.pipe(select(fromSelectors.getNewSectionItemFieldsWithSection)),
        this.newSectionStore.pipe(select(fromRoot.getRouterView)),
        this.newSectionStore.pipe(select(fromRoot.getCopyrightItemView)),
      ),
      switchMap(([action, fields, view, copyrightView]: [any, any, any, any]) => {
        const fieldsToClean = DateTimeUtils.cleanObjectIndefiniteDateAliasToDate(fields.fields);
        const { section } = action.payload;
        if ([SectionsConfig.AGREEMENTS.name, SectionsConfig.REPERTOIRES.name].includes(section)) {
          const noteText = get(fieldsToClean, 'notes') || get(fieldsToClean, 'additional_information');
          let notePayload;
          const isEdit = view === SectionRouterViews.edit || (view === SectionRouterViews.detail && copyrightView === CopyrightItemView.Edit);
          if (!!noteText) {
            const notesUtils = new NotesUtils(this.translate, this.rootStore, null);
            if (!isEdit) {
              notePayload = notesUtils.getNotePayload(noteText, isEdit, section);
              return of(new fromRoot.UpdateNotes({ ...notePayload, notSaveResponse: true, avoidSnackbar: true }));
            } else {
              return this.rootStore.pipe(
                select(fromRoot.getCopyrightDetailBySection),
                take(1),
                map(copyrightDetail => {
                  const notes = NotesUtils.stringifyAllNotesClusters(copyrightDetail?.notes);
                  notePayload = notesUtils.getNotePayload(noteText, isEdit, section, notes);
                  return new fromRoot.UpdateNotes({ ...notePayload, notSaveResponse: true, avoidSnackbar: true });
                }),
              );
            }
          } else {
            return EMPTY;
          }
        } else {
          return EMPTY;
        }
      }),
      catchError(error => {
        return of(new fromActions.SaveNewSectionItemFail(error));
      }),
    ),
  );

  putNewIp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.PUT_NEW_IP),
      switchMap((action: fromForm.PutNewIp) => {
        return this.saveItemService.createDummyIps({ ...action.payload, ns: IpUtils.generateNSForDummyIp(action.payload.submitterIPI) });
      }),
      map(result => new fromForm.PutNewIpSuccess(result)),
      catchError(error => {
        const message = error || this.translate.instant('ERROR.NEW_PARTY');
        return of(
          new fromForm.PutNewIpFail(message),
          new fromRoot.ShowSnackBar({
            duration: 5000,
            message,
          }),
        );
      }),
    ),
  );

  saveDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SAVE_DOCUMENT),
      withLatestFrom(this.newSectionStore.pipe(select(fromSelectors.getNewSectionItemFields))),
      switchMap(([action, fields]: [fromForm.SaveDocument, any]) => {
        const { counterclaimId, documents } = fields;
        const pendingDocuments = documents.filter(document => document.status === CounterClaimDocumentCurrentState.UPLOADING);
        const results = [];
        pendingDocuments.forEach(document => results.push(this.saveItemService.uploadDocumentUsingPreSignedURL(document.file, counterclaimId, document.actionId)));

        return of({ result: merge(...results), onValidate: action.onValidate });
      }),
      map(({ result, onValidate }) => new fromForm.SaveDocumentSuccess(result, onValidate)),
      catchError(({ error, onValidate }) => of(new fromForm.SaveDocumentFail(error, onValidate))),
    ),
  );

  saveDocumentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.SAVE_DOCUMENT_SUCCESS, fromActions.SAVE_DOCUMENT_FAIL),
        withLatestFrom(this.newSectionStore.pipe(select(fromSelectors.getNewSectionDocuments))),
        map(([action, documents]: [fromForm.SaveDocumentSuccess | fromForm.SaveDocumentFail, any]) => {
          let newDocuments = documents;
          let status;
          switch (action.type) {
            case fromActions.SAVE_DOCUMENT_SUCCESS:
              status = CounterClaimDocumentCurrentState.UPLOADED;
              break;
            case fromActions.SAVE_DOCUMENT_FAIL:
              status = CounterClaimDocumentCurrentState.ERROR;
          }
          action.result.forEach(result => {
            newDocuments = CounterClaimUtils.formatUploadDocumentSuccess(newDocuments, result, status);
            action.onValidate(newDocuments);
          });
        }),
      ),
    { dispatch: false },
  );

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.DELETE_DOCUMENT),
      switchMap((action: fromForm.DeleteDocument) => this.saveItemService.deleteDocument(action.payload)),
      map(result => new fromForm.DeleteDocumentSuccess(result)),
      catchError(error => of(new fromForm.DeleteDocumentFail(error))),
    ),
  );

  trimClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.TrimClaims>(fromActions.TRIM_CLAIMS),
      withLatestFrom(this.rootStore.select(fromRoot.getCopyrightWork)),
      switchMap(([{ payload }, work]) => {
        const modifiedClaims = getTrimmedClaims({ work, config: payload });
        if (payload.scope === TrimScopeOptions.REPLACE) {
          // when replacing claims the territory field is already shortened in the form
          return of(new fromRoot.UpdateFieldSuccess('works', { ...work, claims: modifiedClaims }));
        }
        // we need to shorten every single claim's territories as we don't do that in the FE
        const updatedClaims$ = from(modifiedClaims).pipe(
          mergeMap(claim => {
            /**
             * If the array is empty, forkJoin completes without emitting a value, thus the claim will be skipped
             * and that led to an issue where sub-publishers were removed from the claims list
             * because they had claimShares or ownershipShares with an empty array. When that's the case,
             * we need to emit the value as an empty array. `of([])`
             */
            return forkJoin({
              claimShares: claim.claimShares.length
                ? forkJoin(
                    claim.claimShares.map(workShare =>
                      this.searchService
                        .getShortenTerritory(workShare.territories, moment().format(DATE_FORMAT))
                        .pipe(map((territories: typeof workShare.territories) => ({ ...workShare, territories }))),
                    ),
                  )
                : of([]),
              ownershipShares: claim.ownershipShares.length
                ? forkJoin(
                    claim.ownershipShares.map(workShare =>
                      this.searchService
                        .getShortenTerritory(workShare.territories, moment().format(DATE_FORMAT))
                        .pipe(map((territories: typeof workShare.territories) => ({ ...workShare, territories }))),
                    ),
                  )
                : of([]),
            }).pipe(
              map(updatedShares => ({
                ...claim,
                claimShares: updatedShares.claimShares,
                ownershipShares: updatedShares.ownershipShares,
              })),
            );
          }),
          toArray(), // Collect all modified claims into an array
        );

        return updatedClaims$.pipe(
          map(updatedClaims => {
            return {
              ...work,
              claims: updatedClaims,
            };
          }),
          map(updatedWork => new fromRoot.UpdateFieldSuccess('works', updatedWork)),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private newSectionStore: Store<fromState.NewSectionItemState>,
    private rootStore: Store<fromFeature.RootState>,
    private searchService: SearchService,
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private saveItemService: SaveItemService,
    private detailService: DetailService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }
}
