import { AgreementUtils, CopyrightUtils, DateTimeUtils, Ip, IpBaseInformation, IpDetail, IpSocietyRelation, IpWriterCleaner, StringUtils, WorkRelation } from '@ice';
import { TranslateService } from '@ngx-translate/core';
import { ICE_PREFIX, IPI_PREFIX, PUBLISHER_PREFIX, SIREF_PREFIX } from 'config/constants/global.constants';
import {
  CREATION_CLASS,
  IpNature,
  IP_STATUS,
  IP_TYPES,
  IP_TYPE_LEGAL_ENTITY,
  IP_TYPE_NATURAL_PERSON,
  PartyRelationType,
  mrRightsSelectOptions,
  prRightsSelectOptions,
} from 'config/constants/ips.constants';
import { RIGHT_TYPES } from 'config/constants/repertoires.constants';
import { cloneDeep, concat, difference, find, flatten, get, isEqual, join, last, map, padStart, remove, startsWith, take, uniqWith } from 'lodash';
import { IpRelation } from 'models';
import { ClaimClaimantPartyName } from 'models/copyright/detail/claims';
import { IconInterface } from 'models/copyright/detail/icon';
import { SelectOption } from 'models/copyright/formly/formly';
import moment from 'moment';
import * as uuid from 'uuid';
import { RelationsUtils, SocietiesUtils } from '@ice';
import { sortRightsString } from './rights.utils';

export interface SocietyObject {
  societyCode: string;
  multipleSocieties: boolean;
  hasICESociety: boolean;
  societyCodes: string;
}

const dispAndXrefRelationTypes = [PartyRelationType.DISPLAY_REFERENCE, PartyRelationType.CROSS_REFERENCE];

export class IpUtils {
  static getIpFullName(item: Ip) {
    if (item.firstName && item.firstName.trim() !== '') {
      return StringUtils.joinStrings(', ', item.name || '', item.firstName || '');
    }
    return item.name;
  }

  static dummyIpCleaner(item: any, translate) {
    const party = get(item, 'partyName.parties[0].party.attributes') || {};
    const { dateOfBirth, dateOfDeath, status, typeOf, creationClass } = party;
    const partyNames = get(item, 'partyName.attributes') || {};
    const { name, firstName } = partyNames;
    return {
      name,
      firstName,
      dateOfBirth,
      dateOfDeath,
      typeOf,
      creationClass,
      status: status ? translate.instant('AGREEMENTS.AGREEMENT_PARTIES.STATUS_ACTIVE') : translate.instant('AGREEMENTS.AGREEMENT_PARTIES.STATUS_INACTIVE'),
      rawItem: item,
    };
  }

  static linkedPublishersCleaner(value, detail) {
    if (isEqual(get(value, 'items[0].writerId'), get(detail, 'id', ''))) {
      return get(value, 'items[0].publishers', []).map(item => ({
        ...item,
        ipiNameNumber: (item.ipiNameNumber || '').split(':')[1],
        ipNameKey: (item.ipNameKey || '').split(':')[1],
        writerId: get(value, 'items[0].writerId'),
        ipNameKeyRaw: item.ipNameKey || '',
      }));
    } else {
      return get(detail, 'linkedPublishers');
    }
  }

  static linkedWritersCleaner(value) {
    return get(value, 'items[0].writers', []).map(item => ({
      ...item,
      ipiNameNumber: (item.ipiNameNumber || '').split(':')[1],
      ipNameKey: (item.ipNameKey || '').split(':')[1],
      publisherId: get(value, 'items[0].publisherId'),
      ipNameKeyRaw: item.ipNameKey || '',
    }));
  }

  static selectIPsIdByNS(relations, ns) {
    const xrefItemIPI = find(
      relations,
      ({ otherId, relation }) => otherId && otherId.split(':')[0] === ns && [PartyRelationType.CROSS_REFERENCE, PartyRelationType.MATCH].indexOf(relation) !== -1,
    );
    if (relations && relations.length && xrefItemIPI) {
      return (xrefItemIPI && xrefItemIPI.otherId) || '';
    }
    return '';
  }

  static getNameFromPartyAttributes(partyName: any) {
    return StringUtils.joinStrings(`${partyName.attributes.name}, ${partyName.attributes.firstName}`);
  }

  static getNameFromParty(partyName: any) {
    const { name, firstName } = partyName.attributes;
    const firstNameTrim = firstName?.trim();
    return `${name}${firstName && firstNameTrim !== '' ? `, ${firstNameTrim}` : ''}`;
  }

  static getIPNameNumberWithouthPrefix(partyName) {
    const ref = partyName && partyName.relations && IpUtils.selectIPINumber(partyName.relations);
    return (ref && ref.replace('IPI:', '')) || '';
  }

  static selectIpsKey(IpRelations, id: string): string {
    // Key Selection Order:
    // 1- DISP -> ICE first, IPI second
    // 2- XREF -> ICE first, IPI second
    // 3- if party and relations IPI
    // 4- id
    const dispOrXrefKey = RelationsUtils.selectRelationByTypeAndPrefix(IpRelations, dispAndXrefRelationTypes, [ICE_PREFIX, IPI_PREFIX]);
    if (dispOrXrefKey) {
      return dispOrXrefKey;
    }

    const iswcRelation = IpRelations && IpRelations.find(relation => get(relation, 'otherId').split(':')[0] === IPI_PREFIX);

    return (iswcRelation && iswcRelation.otherId) || id;
  }

  static getNsFromId(id: String) {
    return id.substring(0, id.lastIndexOf(':'));
  }

  static selectIPINumber(IpRelations) {
    return RelationsUtils.selectRelationByTypeAndPrefix(IpRelations, dispAndXrefRelationTypes, [IPI_PREFIX]);
  }

  static selectICENumber(IpRelations) {
    return RelationsUtils.selectRelationByTypeAndPrefix(IpRelations, dispAndXrefRelationTypes, [ICE_PREFIX]);
  }

  static formatPartyName(item, translate) {
    const ip: Ip = item['attributes'];
    const relations = item['relations'];
    let IPINameNumber = '';
    let IPNameKey = '';
    let key = '';
    let typeOfIcon: IconInterface[];
    const baseParties = (item.parties && map(item.parties, base => base['party']).filter(party => !!party)) || [];

    IPINameNumber = RelationsUtils.selectRelationByTypeAndPrefix(relations, [PartyRelationType.CROSS_REFERENCE], [IPI_PREFIX]);
    if (IPINameNumber.indexOf(':') > -1) {
      IPINameNumber = IPINameNumber.split(':')[1];
    }

    key = RelationsUtils.selectRelationByTypeAndPrefix(relations, dispAndXrefRelationTypes, [ICE_PREFIX]);
    if (key.indexOf(':') > -1) {
      IPNameKey = key.split(':')[1];
    }

    if (baseParties?.length <= 5) {
      const types = map(baseParties, t => (t.attributes && t.attributes['typeOf']) || '');
      typeOfIcon = flatten(map(types, t => IpUtils.generateBaseTypeIcons(t, translate, 'material-icons md-14') || []));
    } else {
      typeOfIcon = CopyrightUtils.generateIconWithTooltip(translate.instant('IPS.MULTIPLE_TYPES'), 'more_horiz');
    }

    if (ip) {
      return {
        id: item['id'],
        ipiNameNumber: IPINameNumber,
        key,
        nameKey: IPNameKey,
        typeOfName: ip.typeOfName,
        typeOfNameTooltip: translate.instant(`IPS.TYPE_OF_NAME_TOOLTIPS.${ip.typeOfName}`),
        fullName: this.getIpFullName(ip),
        typeOf: get(item, 'parties[0].party.attributes.typeOf'),
        typeOfIcon,
        dataType: 'ip',
        agreements: get(item, 'agg.attributes.agreementCount', 0),
        works: get(item, 'agg.attributes.workCount', 0),
      };
    }
  }
  static formatPartyNames(partyNames, translate) {
    if (partyNames && partyNames.items) {
      return partyNames.items.map(item => this.formatPartyName(item, translate));
    }
    return [];
  }

  static getIpSocieties(parties, translate) {
    const mapOfRelations = {};
    if (parties && parties.length && parties.length > 0) {
      parties.map(party => {
        const societies = get(party, 'party.societies');
        if (societies && societies.length && societies.length > 0) {
          societies.map(socParty => {
            const { memberships, society } = socParty;
            const societyNumber = society && SocietiesUtils.getSocietyCode(society.id);
            const societyName = SocietiesUtils.searchSocietyNameById(societyNumber);
            if (memberships && memberships.length && memberships.length > 0) {
              memberships.map(member => {
                const { roles, share, territories, startDate, endDate, rights } = member;
                const rolesList = roles.join(',');
                const key = `${societyNumber}:${startDate}:${endDate}:${territories}:${rolesList}`;
                const relation = mapOfRelations[key];
                if (relation) {
                  relation.rightsCodes = [...relation.rightsCodes, ...difference(rights, relation.rightsCodes)];
                } else {
                  mapOfRelations[key] = {
                    societyNumber,
                    creationClass: CREATION_CLASS,
                    societyName,
                    share: (share && share > 100 ? share / 100 : share) || '',
                    territories: join(territories),
                    validFrom: startDate,
                    validTo: DateTimeUtils.cleanIndefiniteDate(endDate),
                    roleCodes: roles,
                    rightsCodes: rights,
                  };
                }
              });
            }
          });
        }
      });
    }

    const groupOfRightCodes = Object.keys(mapOfRelations);
    return groupOfRightCodes.map(key => {
      const relation = mapOfRelations[key];
      return {
        ...mapOfRelations[key],
        roleCodes: join(
          relation.roleCodes.map(role => `<span>${role}</span>`),
          ', ',
        ),
        roleCodesRaw: relation.roleCodes,
        rightsCodes: sortRightsString({ rights: relation.rightsCodes.join() }),
      };
    });
  }

  static mapRightsCodes(rights: string[], translate) {
    return (Array.isArray(rights) && rights.includes('PR') && RIGHT_TYPES.PR) || RIGHT_TYPES.MR;
  }

  static getPartyRelationsSocieties(item: any): IpSocietyRelation[] {
    const relations = IpUtils.getIpSocietyRelationCleaner(
      RelationsUtils.getAllRelationsByTypeAndPrefix(item?.relations || [], [PartyRelationType.CROSS_REFERENCE], [SIREF_PREFIX]),
    );
    const parties = IpUtils.getIpSocietyRelationCleaner(
      RelationsUtils.getAllRelationsByTypeAndPrefix(item?.parties[0]?.party?.relations || [], [PartyRelationType.CROSS_REFERENCE], [SIREF_PREFIX]),
    );
    return [...relations, ...parties];
  }

  static getPartyRelationsPublishers(partyName) {
    const publisherRelations = (partyName.relations && partyName.relations.filter(relation => startsWith(relation.otherId, PUBLISHER_PREFIX))) || [];
    return publisherRelations.map(relation => {
      const otherIdSplit = relation.otherId && relation.otherId.split(':');
      const societyIpReference = (otherIdSplit && otherIdSplit.length > 0 && otherIdSplit[1]) || '';
      const societyNumber = otherIdSplit && otherIdSplit.length > 0 && padStart(otherIdSplit[0].replace(PUBLISHER_PREFIX, ''), 3, '0');
      const societyName = societyNumber ? SocietiesUtils.searchSocietyNameById(societyNumber) : '';

      return {
        otherId: relation.otherId,
        societyNumber,
        societyName,
        societyIpReference,
      };
    });
  }

  static getBaseIdWithPrefix(item): string {
    const ids = item?.parties?.map(partyItem => this.getPartyIpsKey(partyItem.party)) || [];
    return last(ids) || '';
  }

  static getIpBaseInfo(item, translate: TranslateService) {
    return item && item.parties
      ? item.parties.map(party => {
          const partyInfo = party.party;
          if (partyInfo) {
            const { attributes, relations } = partyInfo;
            const { typeOf, status, sex, dateOfBirth, countryOfBirth, placeOfBirth, dateOfDeath } = attributes;
            const baseIdWithPrefix = this.getPartyIpsKey(partyInfo);
            const formattedDateOfBirth = dateOfBirth && moment(dateOfBirth).format('YYYY-MM-DD');
            const statusTooltip = this.getStatusTooltip(status, translate);
            const statusIcons = this.getStatusIcons(status);
            const mergeId = this.getMergeId(status, relations);
            const mergeIdLabelTooltip = this.getMergeIdLabelTooltip(mergeId, translate);
            const mergeIdIcons = this.getMergeIdIcons(status);
            return {
              baseId: baseIdWithPrefix.replace('ICE:', '') || '',
              baseIdWithPrefix,
              baseIPINumber: (partyInfo && relations && IpUtils.selectIPINumber(relations).replace('IPI:', '')) || '',
              name: (item.attributes && item.attributes.name && IpUtils.getIpFullName(item.attributes)) || '',
              typeOf: typeOf || IP_TYPE_NATURAL_PERSON,
              status,
              statusIcons,
              statusTooltip,
              sex,
              dateOfBirth: (typeOf && typeOf.toUpperCase() === IP_TYPE_NATURAL_PERSON && dateOfBirth) || '',
              dateOfFoundation: (typeOf && typeOf.toUpperCase() === IP_TYPE_LEGAL_ENTITY && formattedDateOfBirth) || '',
              countryOfBirth,
              placeOfBirth,
              dateOfDeath: (typeOf && typeOf.toUpperCase() === IP_TYPE_NATURAL_PERSON && dateOfDeath) || '',
              dateOfDissolution: (typeOf && typeOf.toUpperCase() === IP_TYPE_LEGAL_ENTITY && dateOfDeath) || '',
              mergeId,
              mergeIdLabel: CopyrightUtils.getKeySuffix(mergeId),
              mergeIdLabelTooltip,
              mergeIdIcons,
            };
          } else {
            return {};
          }
        })
      : [];
  }

  static getPartyIpsKey(partyDetails) {
    const { id, relations } = partyDetails;
    return (partyDetails && relations && this.selectIpsKey(relations, id)) || '';
  }

  static displayStatus(status: string, translate: TranslateService): string {
    return status === '1' ? translate.instant('IPS.IP_BASE_STATUS.ACTIVE') : translate.instant('IPS.IP_BASE_STATUS.INACTIVE');
  }

  static generateBaseTypeIcons(value: string, translate: TranslateService, classParam): IconInterface[] {
    return value && value.toUpperCase() === IP_TYPE_LEGAL_ENTITY
      ? [{ icon: 'business', text: translate.instant('IPS.IP_TYPE_LEGAL_ENTITY'), class: classParam }]
      : [{ icon: 'person', text: translate.instant('IPS.IP_TYPE_NATURAL_PERSON'), class: classParam }];
  }

  static editIpRelations(sourceItem, data) {
    const item = cloneDeep(sourceItem);
    const relationToUpdate = data.relation;
    if (item && data.fullName) {
      item.attributes.fullName = data.fullName;
    }
    switch (data.type) {
      case 'add':
        {
          const relations: IpRelation[] = item && get(item, 'parties[0].relations');
          if (IpUtils.ipRelationNotExists(relations, relationToUpdate)) {
            // Avoid duplicated relations
            relations.push(relationToUpdate);
          }
        }
        break;
      case 'delete': {
        remove(get(item, `parties[0].relations`) || [], (o: any) => o.otherNameId === relationToUpdate.otherNameId);
      }
    }
    return item;
  }

  static getIpRelations(parties: any[], type: string) {
    const relations = parties && type && flatten(parties.map(party => party[type]));
    return relations.length > 0 && relations[0] ? relations : null; // relations[0] Avoids one element array with undefined value if field is not mapped in API
  }

  static generateNSForDummyIp(submitterIPI): string {
    return `PB${StringUtils.removeExtraSpaces(submitterIPI).toLowerCase()}`;
  }

  static generateIDForDummyIp(ns): string {
    return `${ns}:${uuid.v4().replace(/-/g, '')}`;
  }

  static buildIpRelationObject(partyId: string, partyNameId: string, otherId: string, otherNameId: string): any {
    return {
      ns: 'ICE',
      partyId,
      partyNameId,
      version: 0,
      otherId,
      otherNameId,
      relation: 'GR',
    };
  }

  static getBaseInformation(ipDetail: IpDetail, translate: TranslateService): IpBaseInformation[] {
    const ipBaseInfo = IpUtils.getIpBaseInfo(ipDetail, translate);
    return (ipBaseInfo.length && ipBaseInfo) || null;
  }

  static ipRelationNotExists(relations: IpRelation[], relationTosearch: IpRelation) {
    return !find(
      relations,
      r =>
        r.ns === relationTosearch.ns &&
        r.otherId === relationTosearch.otherId &&
        r.otherNameId === relationTosearch.otherNameId &&
        r.partyId === relationTosearch.partyId &&
        r.partyNameId === relationTosearch.partyNameId &&
        r.relation === relationTosearch.relation,
    );
  }

  static formatIpRelationObject(ipiType: string, baseKey: string, nameKey: string, ipSelectedBaseKey: string, ipSelectedNameKey: string) {
    if (ipiType === IpNature.TYPE_PARENT) {
      return IpUtils.buildIpRelationObject(`ICE:${baseKey}`, `ICE:${nameKey}`, `ICE:${ipSelectedBaseKey}`, `ICE:${ipSelectedNameKey}`);
    } else if (ipiType === IpNature.TYPE_CHILDREN) {
      return IpUtils.buildIpRelationObject(`ICE:${ipSelectedBaseKey}`, `ICE:${ipSelectedNameKey}`, `ICE:${baseKey}`, `ICE:${nameKey}`);
    }
  }

  static getClaimantRef(partyName: ClaimClaimantPartyName, removePrefix = false): string {
    const ref = (partyName.relations && IpUtils.selectIPINumber(partyName.relations)) || '';
    return removePrefix ? last(ref.split(':')) : ref;
  }

  static getAuditHistoryTime(ipSection, isLastElement) {
    const auditHistory: any[] = ipSection.auditHistory;
    return auditHistory && auditHistory.length > 0 && get(auditHistory[isLastElement ? auditHistory.length - 1 : 0], 'attributes.data.time', '');
  }

  static getPartyAttributes(party) {
    return (party.partyName && party.partyName.attributes) || {};
  }

  static getPartyFullName(party) {
    const attributes = this.getPartyAttributes(party);
    const fistNameWithSeparator = attributes && attributes.firstName && attributes.firstName.trim() ? ', ' + attributes.firstName : '';
    return (attributes && `${attributes.name}${fistNameWithSeparator}`) || '';
  }

  static concatPartyNames(names = [], max = 5): string {
    return take(
      names.map(item => this.getPartyFullName(item)),
      max,
    ).join('<br>');
  }

  static IpWriterCleaner(items: any[], translate, extraItems?: string[], addFullItemInfo = false): IpWriterCleaner[] {
    return items?.map(item => {
      const { endDateUpdated, postTermCollectionDateUpdated } = AgreementUtils.comparePTCTerminationDates(item.usageEndDate, item.distributionEndDate);
      return <IpWriterCleaner>{
        ...item,
        cleanCreatorIpi: last(item.creatorIpi.split(':')),
        cleanPublisherIpi: last(item.publisherIpi.split(':')),
        usageEndDate: endDateUpdated,
        distributionEndDate: postTermCollectionDateUpdated,
      };
    });
  }

  static cleanNameLabel(name: string, typeOf: IpNature): string {
    switch (typeOf) {
      case IpNature.NATURAL_PERSON:
        return this.serializeFullName(name);

      case IpNature.LEGAL_ENTITY:
        return this.uppercaseFullName(name);
      default:
        break;
    }
  }

  static serializeFullName(fullName: string) {
    const names = fullName?.split(',');
    for (let i = 0; i < names.length; i++) {
      if (i === 0) {
        names[i] = names[i].toUpperCase();
      } else {
        const namesCapitalize = names[i]?.split(' ');
        for (let nc = 0; nc < namesCapitalize.length; nc++) {
          namesCapitalize[nc] = namesCapitalize[nc].charAt(0).toUpperCase() + namesCapitalize[nc].slice(1).toLowerCase();
        }
        names[i] = namesCapitalize.join(' ');
      }
    }

    return names.join(',');
  }

  static uppercaseFullName(fullName: string) {
    return fullName.toUpperCase();
  }

  static getIpSocietyRelationCleaner(relations: WorkRelation[]): IpSocietyRelation[] {
    return relations.map(relation => {
      const otherIdSplit = relation.otherId && relation.otherId.split(':');
      const societyNumber = (otherIdSplit && otherIdSplit.length > 0 && otherIdSplit[1]) || '';
      const societyIpReference = (otherIdSplit && otherIdSplit.length > 0 && otherIdSplit[2]) || '';
      const societyName = societyNumber ? SocietiesUtils.searchSocietyNameById(societyNumber) : '';

      return <IpSocietyRelation>{
        otherId: relation.otherId,
        societyNumber,
        societyName,
        societyIpReference,
      };
    });
  }

  static getPrOptions(additionalOptions: SelectOption[] = [], sortProperty: string = 'label'): SelectOption[] {
    const prOptions = CopyrightUtils.sortByProperty(prRightsSelectOptions || [], sortProperty);
    return concat(additionalOptions, prOptions);
  }

  static getMrOptions(additionalOptions: SelectOption[] = [], sortProperty: string = 'label'): SelectOption[] {
    const mrOptions = CopyrightUtils.sortByProperty(mrRightsSelectOptions || [], sortProperty);
    return concat(additionalOptions, mrOptions);
  }

  static getOtherPartyLabelFromValue(valueToSearch: any) {
    if (valueToSearch) {
      return IP_TYPES.find(item => item.value === valueToSearch).label;
    } else {
      return '';
    }
  }

  static formatLabelSelectIPProtection(manuscript) {
    const num = manuscript.ipiNameNumber ? manuscript.ipiNameNumber : manuscript.ipiNameKey;
    return `${manuscript.role}:${manuscript.name}:${num}`;
  }

  static getStatusTooltip(status: string, translate: TranslateService): string {
    return !!status ? translate.instant(`IPS.DETAILS.CARD_WITH_FORM.STATUS.${status}`) : '';
  }

  static getMergeId(status: string, relations: any[]): string | undefined {
    let mergedId;
    if (status === IP_STATUS.MERGED) {
      mergedId = RelationsUtils.selectRelationByTypeAndPrefix(relations, [PartyRelationType.MERGE], [ICE_PREFIX]);
    }
    return mergedId;
  }

  static getMergeIdLabelTooltip(mergeId: string, translate: TranslateService): string {
    return !!mergeId ? translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.REPLACED_BY') : '';
  }

  static getStatusIcons(status: string): IconInterface[] {
    return status === IP_STATUS.MERGED ? [{ icon: 'warning', text: '', class: 'red-icon cursor-default' }] : [{ icon: null, text: '', class: '' }];
  }

  static getMergeIdIcons(status: string): IconInterface[] {
    return status === IP_STATUS.MERGED ? [{ icon: 'east', text: '', class: 'black-icon cursor-default' }] : [{ icon: null, text: '', class: '' }];
  }

  static getTabTableTitle(itemsCount: number, singularLabel: string, pluralLabel: string): string {
    if (itemsCount === 1) {
      return `${itemsCount.toLocaleString('en-GB')} ${singularLabel}`;
    } else if (itemsCount > 1) {
      return `${itemsCount.toLocaleString('en-GB')} ${pluralLabel}`;
    }
    return pluralLabel;
  }

  static prOrMrComparator(modelA, modelB, fieldBase) {
    return this.prOrMrComparatorModelValue(modelA, fieldBase) > this.prOrMrComparatorModelValue(modelB, fieldBase) ? 1 : -1;
  }

  static prOrMrComparatorModelValue(model, fieldBase): string {
    return model[`${fieldBase}Icons`].length > 0 ? (model[`${fieldBase}IsIce`] === true ? 10000 : 9999) : model[fieldBase];
  }
}
