import { allRights } from 'config/constants/ips.constants';
import { DEFAULT_SEARCH_FROM } from 'config/constants/search.constants';
import { environment } from 'config/env';
import { SectionsConfig } from 'config/sections-config';
import { RequestType } from 'config/sections-config/api-call';
import { SectionCopyrightAgreements } from 'config/sections-config/section-copyright-agreements';
import {
  copyrightGlobal,
  createApiCall,
  createApiCallFromBase,
  isAthena,
  isPageable,
  setBody,
  setInclude,
  setLabelResolver,
  setPageable,
  setQueryParams,
  setResultCleaner,
  validateLabels,
} from '../api-call.factory';
import { getAuditHistory, getAuditHistoryFilterEndDate, getAuditHistoryFilterStartDate } from '../common/common.api-calls';
import * as fromCommonResultCleaners from '../common/common.result-cleaners';
import * as fromLabelResolvers from './ips.label-resolvers';
import * as fromResultCleaners from './ips.result-cleaners';

const ipsSection = SectionsConfig.IPS.name;

export const getIpsWorks = createApiCall(
  `${environment.apiUrlCubeData}/claim-graphs/<<ns>>/search`,
  RequestType.POST,
  setInclude(
    `work.attributes,work.counterclaims.attributes{status}, work.contributions.contributor.partyName.attributes,
    work.contributions.contributor.partyName.relations,work.conflictsCount,work.activityTriggersCount`,
  ),
  setBody(`{"equals":{"claims.attributes.claimantPartyNameId":"<<id>>"}}`),
  setResultCleaner(['works', fromResultCleaners.getIpsWorksResultCleaner]),
  setLabelResolver(ipsSection, { id: 'id' }),
  isPageable,
);

export const getIpsAgreements = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.AGREEMENTS.name}/<<ns>>/search`,
  RequestType.POST,
  setInclude(SectionCopyrightAgreements.apiIncludes.search),
  setBody(`{"or":[{"equals":{"assignor.partyName.relations[XREF].otherId":"<<id>>"}},
      {"equals":{"assignee.partyName.relations[XREF].otherId":"<<id>>"}}]}`),
  setLabelResolver(ipsSection, { id: 'attributes.key' }),
  setResultCleaner(['agreements', fromCommonResultCleaners.getAgreementsResultCleaner]),
  isPageable,
);

export const searchDialogIps = createApiCall(
  `${environment.apiUrlCubeData}/party-names/<<ns>>/search`,
  RequestType.POST,
  setInclude(`attributes{id,name,firstName, typeOfName, publisher}, relations{relation,otherId},parties.relations,parties.party.relations[XREF]{relation,otherId},
  parties.party.societies{societyId,society,memberships}, parties.party.attributes{typeOf, status},
  parties.party.partyNames.partyName.relations[XREF]{relation,otherId}, agg.attributes`),
  setQueryParams({ followRedirects: false }),
  setResultCleaner(['dialogSearchData', fromResultCleaners.searchDialogIpsResultCleaner]),
  isPageable,
  copyrightGlobal,
);

export const getIpsAuditHistoryFilterStartDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterStartDate);

export const getIpsAuditHistoryFilterEndDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterEndDate);

export const getIpsWorkClauses = createApiCall(
  `${environment.apiUrlCubeData}/works/CUBE/search`,
  RequestType.POST,
  setInclude(`attributes,relations, partyNames{clauses, partyNameId}`),
  isPageable,
  setBody(`{"and":[{"equals":{"partyNames.partyNameId":"<<id>>"}},{
    "exists": {
      "field": "partyNames.clauses"
    }
}]}`),
  setLabelResolver(ipsSection, { id: 'id' }),
  setResultCleaner(['workClauses', fromResultCleaners.getWorkClausesResultCleaner]),
  validateLabels,
);

export const getIpsLinked = createApiCall(
  `${environment.apiUrlCubeData}/postgres/<<linkedIps>>_summary/search`,
  RequestType.POST,
  setBody(`{ "equals": { <<linkedRequestId>> } }`),
  setLabelResolver(ipsSection, fromLabelResolvers.getLinkedIpsLabelResolver),
  setResultCleaner([fromResultCleaners.getLinkedPropertyCleaner, fromResultCleaners.getLinkedResultCleaner]),
  isAthena,
);

export const getIpsPartyNames = createApiCall(
  `${environment.apiUrlCubeData}/parties/CUBE/<<id>>`,
  RequestType.GET,
  setInclude(
    `partyNames.partyName.attributes{typeOfName,name,firstName},partyNames.partyName.relations{relation,otherId},
    partyNames.partyName.agg.attributes, partyNames.partyName.parties.party.attributes{typeOf}`,
  ),
  setQueryParams({ followRedirects: false }),
  setLabelResolver(ipsSection, fromLabelResolvers.getPartyNamesLabelResolver),
  validateLabels,
  setResultCleaner(['partyNames', fromResultCleaners.getIpsPartyNamesResultCleaner]),
);

export const getIpsPartyNamesById = createApiCall(
  `${environment.apiUrlCubeData}/parties/CUBE/<<id>>`,
  RequestType.GET,
  setInclude(
    `partyNames[partyNameId='<<partyNameId>>'].partyName.attributes{typeOfName,name,firstName},partyNames.partyName.relations{relation,otherId},
    partyNames.partyName.agg.attributes, partyNames.partyName.parties.party.attributes{typeOf}`,
  ),
  setQueryParams({ followRedirects: false }),
  setLabelResolver(ipsSection, fromLabelResolvers.getPartyNamesLabelResolver),
  validateLabels,
  setResultCleaner(['partyNames', fromResultCleaners.getIpsPartyNamesResultCleaner]),
);

export const getIpsParentRelationshipsData = createApiCall(
  `${environment.apiUrlCubeData}/party-names/<<ns>>/search`,
  RequestType.POST,
  setPageable(DEFAULT_SEARCH_FROM, 100),
  setInclude(`parties.relations, attributes{id,name,firstName, typeOfName}, relations{relation,otherId}, parties.party.relations[XREF]{relation,otherId},
  parties.party{relations, societies}.societies{societyId,society,memberships}, parties.party.attributes{typeOf}`),
  setQueryParams({ followRedirects: false }),
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(ipsSection, fromLabelResolvers.getIpsParentRelationshipPattern),
  setResultCleaner(['parentRelationshipsData', fromResultCleaners.getIpsRelationshipResultCleaner]),
);

export const getIpsChildrenRelationshipsData = createApiCall(
  `${environment.apiUrlCubeData}/party-names/<<ns>>/search`,
  RequestType.POST,
  setPageable(DEFAULT_SEARCH_FROM, 100),
  setInclude(`parties.relations, attributes{id,name,firstName, typeOfName}, relations{relation,otherId}, parties.party.relations[XREF]{relation,otherId},
  parties.party{relations, societies}.societies{societyId,society,memberships}, parties.party.attributes{typeOf}`),
  setQueryParams({ followRedirects: false }),
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(ipsSection, fromLabelResolvers.getIpsChildrenRelationshipPattern, fromLabelResolvers.getPartyNamesLabelResolver),
  setResultCleaner(['childrenRelationshipsData', fromResultCleaners.getIpsRelationshipResultCleaner]),
  validateLabels,
);

export const getIpPartyForMerge = createApiCall(
  `${environment.apiUrlCubeData}/parties/<<ns>>/<<id>>`,
  RequestType.GET,
  setInclude(`attributes,relations,partyNames`),
  setQueryParams({ followRedirects: false }),
);

export const mergeIp = createApiCall(
  `${environment.apiUrlCubeData}/parties/<<ns>>/<<id>>`,
  RequestType.PUT,
  setLabelResolver(ipsSection, fromLabelResolvers.mergeIpData),
  validateLabels,
);
/**
 * This call will store the result into `agreementChains.inbox` in the redux store
 * which will be used to trigger the `upsertAgreementChains$` effect to get
 * the subsequent agreement children
 */
export const getAgreementChains = createApiCall(
  `${environment.apiUrlCubeData}/agreements/assignors/<<ns>>/collect`,
  RequestType.POST,
  setInclude(`
    assignee.party.attributes,
    assignee.party.relations,
    assignee.partyName.attributes,
    assignee.partyName.relations,
    assignor.party.attributes,
    assignor.party.relations,
    assignor.partyName.attributes,
    assignor.partyName.relations,
    attributes,
    relations
  `),
  setBody(
    `{
      "agreementTypes": ["G", "S"],
      "rootAssignorPartyId": <<rootAssignorPartyId>>,
      "rootAssignorPartyNameId": <<rootAssignorPartyNameId>>,
      "parentAgreementIds": <<parentAgreementIds>>,
      "scopes": [
        {
          "territories": {
            "tisDate": <<tisDate>>,
            "inExTisns": <<inExTisns>>
          },
          "rightTypes": <<rightTypes>>,
          "usageStartDate": "2000-01-01",
          "usageEndDate": "9999-12-31",
          "distributionStartDate": "2000-01-01",
          "distributionEndDate": "9999-12-31"
        }
      ]
    }`,
  ),
  setLabelResolver(ipsSection, {
    // note that JSON.stringify is needed to convert the quotes and backticks to valid JSON
    rootAssignorPartyId: sectionSlice => JSON.stringify(sectionSlice.parties?.[0].partyId || ''),
    rootAssignorPartyNameId: sectionSlice => JSON.stringify(sectionSlice.parties?.[0].partyNameId || ''),
    tisDate: () => JSON.stringify('2000-01-01'),
    inExTisns: () => JSON.stringify(['+2WL']),
    rightTypes: () => JSON.stringify(allRights),
    parentAgreementIds: () => JSON.stringify([]),
  }),
  setResultCleaner(['agreementChains.inbox', fromResultCleaners.getAgreementChainsResultCleaner]),
);

export const getTransferSourceIpWorks = (index: number) =>
  createApiCall(
    `${environment.apiUrlCubeData}/claim-graphs/<<ns>>/search`,
    RequestType.POST,
    setPageable(10 * (index - 1), 10),
    setInclude(`work.attributes{id, titles}`),
    setBody(`{"equals":{"claims.attributes.claimantPartyNameId":"<<id>>"}}`),
    setResultCleaner(['transferIpWorksInfo.sourceIpWorks', fromResultCleaners.getIpsWorksResultCleaner]),
    setLabelResolver(ipsSection, { id: 'transferIpWorksInfo.sourceIp.id' }),
  );

export const searchIpByName = createApiCall(
  `${environment.apiUrlCubeData}/party-names/<<ns>>/search`,
  RequestType.POST,
  setPageable(DEFAULT_SEARCH_FROM, 100),
  setInclude(`relations{otherId,relation}`),
  setQueryParams({ followRedirects: false }),
  setBody(`{"and": [{"or": [{"equals": {"attributes.name": "<<name>>"}}, {"equals": {"attributes.names.name": "<<name>>"}}]},
            {"equals": {"parties.party.attributes.status": "1"} },
            {"and": [{"equals": {"relations.relation": "MTCH"}},{"wildcard": {"relations.otherId": "OP:*"}}]
          }]}`),
  setLabelResolver(ipsSection, fromLabelResolvers.getIpsParentRelationshipPattern),
  setResultCleaner(['parentRelationshipsData', fromResultCleaners.getIpsRelationshipResultCleaner]),
);

export const getBulkUpdateJobs = createApiCall(
  `${environment.apiUrlCubeData}/works/<<ns>>/bulkUpdate/status/overview`,
  RequestType.GET,
  setQueryParams({ userId: '<<userName>>' }),
  setLabelResolver(SectionsConfig.BULK_UPDATES.name, fromLabelResolvers.bulkUpdatesLabelResolver),
  setResultCleaner(['jobs', fromResultCleaners.getBulkUpdateJobsResultCleaner]),
);

export const getBulkUpdateDetails = createApiCall(
  `${environment.apiUrlCubeData}/works/<<ns>>/bulkUpdate/status/details`,
  RequestType.GET,
  setQueryParams({ bulkUpdateId: '<<bulkUpdateId>>' }),
);
