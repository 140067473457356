import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Search, SearchBarComponent, SearchUtils } from '@ice';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { sections } from 'config/sections-config/sections';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import {
  AdvancedSearchToggle,
  ExpertSearchModeToggle,
  Go,
  Logout,
  OpenUploadXrefDialog,
  RootState,
  SearchAdvanced,
  SearchExpert,
  SearchReset,
  SearchSimple,
  getAdvancedSearchCollapsed,
  getExpertSearchCollapsed,
  getLoadingVisibility,
  getRouterSection,
  getRouterView,
  getSearchResultsInputParams,
  getSearchResultsInputQuery,
  getSearchResultsInputTerm,
  getUserDetail,
  getUserDisplayName,
} from 'store/root';
import { SearchUtilsFactory } from 'store/root/utils';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import { HotkeysService } from 'angular2-hotkeys';
import { SectionsConfig } from '../../../../config/sections-config';
import { ExpertSearchComponent } from './../../../../@ice/components/expert-search-form/expert-search.component';

@Component({
  selector: 'ice-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
  advancedSearchCollapsed$ = this.store.pipe(select(getAdvancedSearchCollapsed), distinctUntilChanged());
  expertSearchCollapsed$ = this.store.pipe(select(getExpertSearchCollapsed), distinctUntilChanged());
  expertSearchExperimentalWarning$ = this.featureFlagService.isExpertSearchExperimentalWarningEnabled$;
  creationModeVisible$: Observable<boolean> = this.store.pipe(select(fromRoot.getCreationMode));
  creationName$ = this.store.pipe(select(fromRoot.getCreationName));
  editModeVisible$: Observable<boolean> = this.store.pipe(select(fromRoot.getEditMode));
  mergeIPModeVisible$: Observable<boolean> = this.store.pipe(select(fromRoot.getMergingIP));
  editOrganizationName$: Observable<string> = this.store.pipe(select(fromRoot.getEditingName));
  forcedNSVisible$: Observable<boolean> = this.store.pipe(select(fromRoot.getForcedNS));
  globalApiNameSpace$: Observable<string> = this.store.pipe(select(fromRoot.getApiNamespace));
  disableNormalSearch$: Observable<boolean>;
  showSearchBar$: Observable<boolean>;
  displayName$ = this.store.pipe(select(getUserDisplayName));
  searchInputParams$ = this.store.pipe(select(getSearchResultsInputParams));
  searchTerm$ = this.store.pipe(select(getSearchResultsInputTerm));
  searchInputQuery$ = this.store.pipe(select(getSearchResultsInputQuery));
  modeLabelVisible = false;
  unsubscribeAll = new Subject();
  searchSection: string;
  section$: Observable<string> = this.store.pipe(
    select(getRouterSection),
    map(section => {
      this.searchSection = section;
      return this.searchSection;
    }),
  );
  showExpertSearch$ = this.section$.pipe(map(section => section !== 'territories'));
  formBuilder$: Observable<FormlyFieldConfig[]>;
  uploadXref$: Observable<boolean>;
  userId$: Observable<string>;
  isLoading$ = this.store.pipe(select(getLoadingVisibility)).pipe(startWith(<boolean>null), delay(0));
  model = {} as any;
  ICEtext = 'ICE';
  @ViewChild(ExpertSearchComponent) expertSearch;
  @ViewChild(SearchBarComponent) simpleSearch;

  constructor(
    private sidebarService: FuseSidebarService,
    private store: Store<RootState>,
    private fuseTranslateService: TranslateService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private searchService: SearchService,
    private fieldValidatorService: FieldValidatorService,
    private detCh: ChangeDetectorRef,
    private permissionsService: PermissionsService,
    private featureFlagService: FeatureFlagService,
    private hotkeys: HotkeysService,
  ) {
    this.searchInputParams$.pipe(takeUntil(this.unsubscribeAll)).subscribe(params => {
      const formattedParams = SearchUtilsFactory.formatParamsAdvancedSearch(params, null, false);
      this.model = { ...formattedParams };
    });
    this.disableNormalSearch$ = this.toggleNormalSearchVisibilityForSection();
    this.uploadXref$ = this.section$.pipe(map(section => (section === 'works' ? true : false)));
    this.formBuilder$ = this.getFormBuilder(this.section$);
    this.showSearchBar$ = this.showSearchBar();
  }

  getFormBuilder(section$): Observable<FormlyFieldConfig[]> {
    return section$.pipe(
      switchMap((section: string) => {
        const instance =
          section &&
          sections[section]?.search?.form &&
          sections[section]?.search?.form(this.fuseTranslateService, this.fuseTranslationLoaderService, this.searchService, this.store, this.fieldValidatorService);
        if (instance) {
          return of(instance.getForm());
        }
        return of([]);
      }),
    );
  }

  ngOnInit() {
    this.userId$ = this.store.pipe(
      select(getUserDetail),
      map(user => user && user.id),
      takeUntil(this.unsubscribeAll),
    );
    this.section$.subscribe(() => {
      if (this.simpleSearch) {
        this.simpleSearch.focus();
      }
    });
  }

  ngAfterViewInit() {
    combineLatest([this.editModeVisible$, this.mergeIPModeVisible$, this.forcedNSVisible$])
      .pipe(delay(0), takeUntil(this.unsubscribeAll))
      .subscribe(([editModeVisible, mergeIPVisible, forcedNSVisible]) => {
        this.modeLabelVisible = editModeVisible || mergeIPVisible || forcedNSVisible;
        this.detCh.detectChanges();
      });
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  toggleSidebarOpened(key) {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  searchAdvanced(params: Search): void {
    this.store.dispatch(new SearchAdvanced(params, this.searchSection));
  }

  searchSimple(term: string) {
    if (term) {
      this.store.dispatch(new SearchSimple(term, this.searchSection));
    }
  }

  searchExpert(query: string) {
    this.store.dispatch(new SearchExpert(query, this.searchSection));
  }

  searchReset() {
    this.store.dispatch(new SearchReset(this.searchSection));
  }

  advancedCollapse() {
    this.store.dispatch(new AdvancedSearchToggle());
  }

  toggleExpert() {
    this.store.dispatch(new ExpertSearchModeToggle());
  }

  gotoUserDetails(id) {
    this.store.dispatch(
      new Go({
        path: [`user-management/users/${id}/details`],
      }),
    );
  }

  uploadXref() {
    this.store.dispatch(new OpenUploadXrefDialog());
  }

  logout() {
    this.store.dispatch(new Logout());
  }
  openShortcutsOverlay() {
    this.hotkeys.cheatSheetToggle.next(true);
  }

  private toggleNormalSearchVisibilityForSection(): Observable<boolean> {
    return this.section$.pipe(map((sectionName: string) => sectionName === SectionsConfig.CONFLICTS.name));
  }

  private showSearchBar(): Observable<boolean> {
    return combineLatest([this.section$, this.store.pipe(select(getRouterView)), this.store.pipe(select(fromRoot.getUserCurrentOrganization))]).pipe(
      map(([sectionName, view, userCurrentOrganization]) => {
        if ([SectionsConfig.COUNTERCLAIM_NOTIFICATIONS.name, SectionsConfig.BULK_UPDATES.name].includes(sectionName)) {
          return false;
        }
        return SearchUtils.viewHasPermission(view, this.permissionsService, sectionName);
      }),
    );
  }
}
