import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IpCleaned } from '@ice';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CopyrightAgreementsDataTable } from 'config/data-table-builders/copyright.agreements';
import { DialogSearchParty } from 'config/dialog-builders/dialog-search-party';
import { StepType } from 'config/stepper-builders/stepper-config';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';

export class AgreementGroupStep {
  searchTableBuilder: CopyrightAgreementsDataTable;
  fieldAgreements: any;

  constructor(
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private fieldValidatorService: FieldValidatorService,
    private dialog: MatDialog,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
    private searchService: SearchService,
  ) {
    this.searchTableBuilder = new CopyrightAgreementsDataTable(translate, translationLoader, store);
  }

  openDialogSearchParty(control) {
    DialogSearchParty.openDialog('ICE', this.dialog, this.store, this.storeNewItem, this.translate, this.translationLoader, this.searchService, (item: IpCleaned) => {
      const { id: partyNameId, nameKey: ipNameKey, fullName: ipName } = item;
      control.parent.get('ipNameKey').setValue(ipNameKey);
      control.parent.get('ipName').setValue(ipName);
      control.parent.get('partyNameId').setValue(partyNameId);
      control.parent.get('partyNameId').markAsTouched();
    });
  }

  getStep(): StepType {
    return {
      label: 'Group details',
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'description',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: false,
                label: 'Agreement Group Description',
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'ipNameKey',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              className: 'flex-1',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: true,
                label: 'IP Name Key',
              },
              asyncValidators: {
                ipiNumberValid: {
                  expression: (control, field) =>
                    this.fieldValidatorService.searchPartyFieldValidator(
                      (partyId, partyNameId, name) => {
                        control.parent.get('partyNameId').setValue(partyNameId);
                        control.parent.get('ipName').setValue(name);
                      },
                      control,
                      field,
                    ),
                  message: this.translate.instant('AGREEMENT_GROUP.ERRORS.IP_NAME_KEY_ERROR'),
                },
              },
            },
            {
              // IP Search
              key: 'ipNameBtSearch',
              className: 'bt-search-ip',
              type: 'label',
              templateOptions: {
                materialType: 'mat-icon-button',
                icons: {
                  icon: 'search',
                },
                tooltipText: this.translate.instant('AGREEMENT_GROUP.SEARCH_IP'),
                onClick: (_, control) => {
                  this.openDialogSearchParty(control);
                },
              },
            },
            {
              key: 'ipName',
              type: 'input',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                disabled: true,
                label: 'IP Name',
              },
            },
          ],
        },
        {
          className: 'ice-display-none',
          key: `partyNameId`,
          type: 'input',
        },
      ],
    };
  }
}
