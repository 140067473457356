import { AGREEMENTS_CONSTANTS, RELATED_AGREEMENT_TYPES } from 'config/constants/agreements.constants';
import { IpNature } from 'config/constants/ips.constants';

export class SelectOptions {
  static getOptions(translate) {
    return {
      coreAgreements: {
        type_of_agreement: [
          {
            label: translate.instant('AGREEMENTS.CORE_AGREEMENT.TYPE_OF_AGREEMENT.VALUES.GENERAL'),
            value: AGREEMENTS_CONSTANTS.GENERAL,
          },
          {
            label: translate.instant('AGREEMENTS.CORE_AGREEMENT.TYPE_OF_AGREEMENT.VALUES.SPECIFIC'),
            value: AGREEMENTS_CONSTANTS.SPECIFIC,
          },
        ],
        sales_manufacture: [
          {
            label: translate.instant('AGREEMENTS.CORE_AGREEMENT.SALES_MANUFACTURE.VALUES.SALES'),
            value: AGREEMENTS_CONSTANTS.SALES,
          },
          {
            label: translate.instant('AGREEMENTS.CORE_AGREEMENT.SALES_MANUFACTURE.VALUES.MANUFACTURE'),
            value: AGREEMENTS_CONSTANTS.MANUFACTURE,
          },
        ],
        transfer_works: [
          {
            label: translate.instant('AGREEMENTS.CORE_AGREEMENT.TRANSFER_WORKS.VALUES.YES'),
            value: true,
          },
          {
            label: translate.instant('AGREEMENTS.CORE_AGREEMENT.TRANSFER_WORKS.VALUES.NO'),
            value: false,
          },
        ],
        share_in_override: [
          {
            label: translate.instant('AGREEMENTS.CORE_AGREEMENT.SHARE_IN_OVERRIDE.VALUES.YES'),
            value: true,
          },
          {
            label: translate.instant('AGREEMENTS.CORE_AGREEMENT.SHARE_IN_OVERRIDE.VALUES.NO'),
            value: false,
          },
        ],
      },
      agreementTerms: {
        prior_royalties: [
          {
            label: translate.instant('AGREEMENTS.AGREEMENT_TERM.PRIOR_ROYALTIES.VALUES.YES'),
            value: 'Y',
          },
          {
            label: translate.instant('AGREEMENTS.AGREEMENT_TERM.PRIOR_ROYALTIES.VALUES.NO'),
            value: 'N',
          },
        ],
      },
      creationClass: {
        cration_class: [
          {
            label: 'MW',
            value: IpNature.TYPE_MW,
          },
        ],
      },
      agreementParties: {
        assignor_type: [
          {
            label: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR_LEGAL_ENTITY'),
            value: IpNature.LEGAL_ENTITY,
          },
          {
            label: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR_PERSON'),
            value: IpNature.NATURAL_PERSON,
          },
        ],
        assignee_type: [
          {
            label: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.ASSIGNOR_LEGAL_ENTITY'),
            value: IpNature.LEGAL_ENTITY,
          },
        ],
      },
      additionalFields: {
        related_agreement_type: [
          {
            label: '',
            value: '',
          },
          {
            label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.RELATED_AGREEMENT_TYPE.VALUES.C'),
            value: RELATED_AGREEMENT_TYPES.COPUBLISHER,
          },
          {
            label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.RELATED_AGREEMENT_TYPE.VALUES.R'),
            value: RELATED_AGREEMENT_TYPES.RECIPIENT,
          },
        ],
        writer_mechanical_reason: [
          {
            label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.WRITER_MECHANICAL_REASON.VALUES.ADVANCE_PAID'),
            value: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.WRITER_MECHANICAL_REASON.VALUES.ADVANCE_PAID'),
          },
          {
            label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.WRITER_MECHANICAL_REASON.VALUES.ANGLO_AMERICAN_WRITER_ONLY'),
            value: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.WRITER_MECHANICAL_REASON.VALUES.ANGLO_AMERICAN_WRITER_ONLY'),
          },
          {
            label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.WRITER_MECHANICAL_REASON.VALUES.WRITER_IS_NON_SOCIETY'),
            value: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.WRITER_MECHANICAL_REASON.VALUES.WRITER_IS_NON_SOCIETY'),
          },
        ],
        usa_license_indicators: [
          {
            label: '',
            value: null,
          },
          {
            label: '021',
            value: '021',
          },
          {
            label: '010',
            value: '010',
          },
          {
            label: '071',
            value: '071',
          },
        ],
        library_music: [
          {
            label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.LIBRARY_PRODUCTION.LIBRARY_MUSIC.VALUES.YES'),
            value: 'y',
          },
          {
            label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.LIBRARY_PRODUCTION.LIBRARY_MUSIC.VALUES.NO'),
            value: 'n',
          },
        ],
      },
    };
  }
}
