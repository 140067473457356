import { FormGroup } from '@angular/forms';
import { DateTimeUtils } from '@ice';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StepType } from 'config/stepper-builders/stepper-config';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StorageService } from 'services/storage/storage.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { SelectOptions } from '../stepper-select-options';

// TODO: Add Angular decorator.
export class AgreementTermsStep {
  subscriptionEditMode: Subscription;
  postTermCollectionDate: string;
  confirmedTerminationDate: string;
  expectedTermination: string;
  priorRoyaltiesDate: string;
  isEditMode: boolean;
  startDate: string;
  termTerritoryField$ = new BehaviorSubject(true);
  form: FormGroup;
  fieldStartDate: any;
  fieldExpectedTermination: any;
  fieldPriorRoyaltiesDate: any;
  subscriptionStartDate: Subscription;
  subscriptionExpectedTermination: Subscription;
  subscriptionPriorRoyaltiesDate: Subscription;

  valueStartDate = null;
  valuePrior = null;

  constructor(private store: Store<fromRoot.RootState>, private storageService: StorageService, private fieldValidatorService: FieldValidatorService) {
    this.subscriptionEditMode = this.store.pipe(select(fromRoot.getRouterView)).subscribe(view => {
      this.isEditMode = view === 'EDIT' ? true : false;
      this.fieldValidatorService.checkFormlyExpressions();
    });
  }

  getStep(translate: TranslateService, fieldValidatorService: FieldValidatorService): StepType {
    const options = SelectOptions.getOptions(translate);
    const startName = 'start_date';
    const startlabel = translate.instant('AGREEMENTS.AGREEMENT_TERM.START_DATE.NAME');
    const startDatepickerOptions = {
      filter: this.validateStartDate.bind(this),
    };
    const startExtraValidators = {
      correctStartDate: {
        expression: c => {
          const controlValue = DateTimeUtils.getIndefiniteDate(c.value);
          return (
            controlValue !== '9999-12-31' &&
            (moment(controlValue ? controlValue : '2020-01-01', 'YYYY-MM-DD', true).isValid() || moment(controlValue ? controlValue : '20200101', 'YYYYMMDD', true).isValid())
          );
        },
        message: translate.instant('AGREEMENTS.ERRORS.INVALID_DATE'),
      },
      dateBefore: {
        expression: control => {
          if (!control.value) {
            return true;
          } else {
            let valid = false;
            const controlValue = DateTimeUtils.getIndefiniteDate(control.value);
            const selectPrior = control.parent.controls['prior_royalties'].value;
            if (selectPrior === 'Y') {
              const isBefore = !this.expectedTermination || moment(controlValue).isBefore(this.expectedTermination);
              valid = this.validateStartDate(controlValue) && isBefore;
            } else {
              valid = this.validateStartDateBeforeTermination(controlValue);
            }
            this.startDate = controlValue;
            return valid;
          }
        },
        message: translate.instant('AGREEMENTS.ERRORS.DATE_BEFORE'),
      },
    };
    const startRequired = true;
    const startExtraClass = 'ice-h-auto';
    const startHooks = {
      onInit: field => {
        this.fieldStartDate = field;
        this.subscriptionStartDate = field.formControl.valueChanges.subscribe(value => {
          if (this.valueStartDate !== value) {
            this.valueStartDate = value;
            if (this.fieldPriorRoyaltiesDate) {
              this.fieldPriorRoyaltiesDate.formControl.updateValueAndValidity();
            }
            if (this.fieldExpectedTermination) {
              this.fieldExpectedTermination.formControl.updateValueAndValidity();
            }
          }
        });
      },
      onDestroy: () => {
        this.subscriptionStartDate.unsubscribe();
        this.subscriptionEditMode.unsubscribe();
      },
    };

    const terminationName = 'expected_termination';
    const terminationLabel = translate.instant('AGREEMENTS.AGREEMENT_TERM.EXPECTED_TERMINATION.NAME');
    const terminationDatepickerOptions = {
      filter: this.validateExpectedTerminationDate.bind(this),
    };
    const terminationExtraValidators = {
      correctDate: {
        expression: c => {
          const controlValue = DateTimeUtils.getIndefiniteDate(c.value);
          return moment(controlValue ? controlValue : '2020-01-01', 'YYYY-MM-DD', true).isValid() || moment(controlValue ? controlValue : '20200101', 'YYYYMMDD', true).isValid();
        },
        message: translate.instant('AGREEMENTS.ERRORS.INVALID_DATE'),
      },
      dateBefore: {
        expression: (control, field) => {
          const controlValue = DateTimeUtils.getIndefiniteDate(control.value);
          const valid = !controlValue || this.validateExpectedTerminationDate(controlValue);
          this.expectedTermination = controlValue;
          this.updateStartDateErrors();
          const startDateControl = field?.form?.controls['start_date'];
          if (startDateControl?.value) {
            startDateControl.updateValueAndValidity();
          }
          return valid;
        },
        message: translate.instant('AGREEMENTS.ERRORS.DATE_AFTER'),
      },
    };
    const terminationExtraClass = 'ice-h-auto';
    const terminationHooks = {
      onInit: field => (this.fieldExpectedTermination = field),
    };
    const terminationHideExpression = null;
    const terminationAsyncValidators = {};
    return {
      label: translate.instant('AGREEMENTS.AGREEMENT_TERM.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-2',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.AGREEMENT_TERM.START_DATE.INFO_TEXT'),
              },
              fieldGroup: [
                DatepickerUtils.getDatepickerField({
                  key: startName,
                  label: startlabel,
                  datepickerOptions: startDatepickerOptions,
                  extraValidators: startExtraValidators,
                  required: startRequired,
                  translate,
                  extraClass: startExtraClass,
                  hooks: startHooks,
                  extraTemplateOptions: { popupForcePt: 6 },
                }),
              ],
            },
            {
              className: 'flex-1',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.AGREEMENT_TERM.EXPECTED_TERMINATION.INFO_TEXT'),
              },
              fieldGroup: [
                DatepickerUtils.getDatepickerField({
                  key: terminationName,
                  label: terminationLabel,
                  datepickerOptions: terminationDatepickerOptions,
                  extraValidators: terminationExtraValidators,
                  translate,
                  extraClass: terminationExtraClass,
                  hooks: terminationHooks,
                  hideExpression: terminationHideExpression,
                  asyncValidators: terminationAsyncValidators,
                  extraTemplateOptions: { popupForcePt: 6 },
                }),
              ],
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-2',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.AGREEMENT_TERM.PRIOR_ROYALTIES.INFO_TEXT'),
              },
              fieldGroup: [
                {
                  className: 'ice-h-auto',
                  key: 'prior_royalties',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    attributes: { 'data-testid': 'select-prior_royalties' },
                    required: true,
                    label: translate.instant('AGREEMENTS.AGREEMENT_TERM.PRIOR_ROYALTIES.NAME'),
                    options: options.agreementTerms.prior_royalties,
                    change: (field, event) => {
                      const control = field.formControl;
                      control.setValue(event.value, { emitEvent: false });
                      control.parent.controls['start_date'].updateValueAndValidity();
                      this.updateStartDateErrors();
                    },
                  },
                },
              ],
            },
            {
              className: 'flex-2',
            },
          ],
        },
      ],
    };
  }

  private updateStartDateErrors() {
    if (this.form) {
      this.form.controls['start_date'].setErrors(this.validateStartDate(this.startDate) ? null : { dateBefore: true });
    }
  }

  private validateStartDate(date) {
    return DateTimeUtils.checkDate({
      checkDate: date,
      filter: {
        isSameOrAfter: [this.priorRoyaltiesDate],
        isSameOrBefore: [this.expectedTermination],
      },
    });
  }

  private validateStartDateBeforeTermination(date) {
    return DateTimeUtils.checkDate({
      checkDate: date,
      filter: {
        isSameOrBefore: [this.expectedTermination],
      },
    });
  }
  private validateExpectedTerminationDate(date) {
    return DateTimeUtils.checkDate({
      checkDate: date,
      filter: {
        isSameOrAfter: [this.startDate],
        isBefore: [this.confirmedTerminationDate, this.postTermCollectionDate],
      },
    });
  }

  private validatePriorRoyaltiesDate(date) {
    return DateTimeUtils.checkDate({
      checkDate: date,
      filter: {
        isBefore: [this.startDate],
      },
    });
  }

  private validateDateNotDefault(date) {
    return !moment(date).isSame(moment('1900-01-01'));
  }
}
