import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AgreementDetail } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { LINKED_WRITERS_IP_SEARCH } from 'config/constants/agreements.constants';
import { CopyrightIpsDataTable } from 'config/data-table-builders/copyright.ips';
import { DialogEditLinkedWriter } from 'config/dialog-builders/dialog-edit-linked-writer';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SearchService } from 'services/search/search.service';
import * as fromForm from 'store/form';
import * as fromRoot from 'store/root';

export class TabAgreementLinkedWriters implements SectionTabConfig {
  private canEditLinkedWriters = false;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: null,
    private detailService: null,
    private nsService: null,
    private iceFacade: null,
    private fieldValidatorService: null,
    private permissionsService: PermissionsService,
    private searchService: SearchService,
    private storeNewItem: Store<fromForm.NewSectionItemState>,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.canEditLinkedWriters = this.permissionsService.can('agreements_linked_writers_edit');
  }

  getConf(): IceGroupComponent[] {
    const copyrightIpsDatatable = new CopyrightIpsDataTable(this.translate, this.fuseTranslationLoader, this.store);
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('AGREEMENTS.LINKED_WRITERS.TAB_TITLE'),
              model: this.store.pipe(select(fromRoot.getAgreementLinkedWriters)),
              schema: [
                ...copyrightIpsDatatable.getDataTable(),
                {
                  name: null,
                  prop: null,
                  actionButtonIcon: 'delete',
                  flexGrow: 0.001,
                  maxWidth: 50,
                  minWidth: 50,
                  resizeable: false,
                  action: row => this.openDeleteDialog(row),
                  hideActionButton: row => of(!this.canEditLinkedWriters),
                  actionButtonTooltip: `${this.translate.instant('AGREEMENTS.LINKED_WRITERS.POPUP.DELETE_TITLE')}`,
                },
              ],
              loadingIndicator: true,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              actionButtons: this.canEditLinkedWriters
                ? of([
                    {
                      icon: 'add',
                      tooltip: this.translate.instant('AGREEMENTS.LINKED_WRITERS.ADD_LINKED_WRITERS'),
                      class: 'mat-white-icon',
                      onClick: () => this.onClickButtonIPISearchDialog(),
                    },
                  ])
                : null,
            },
          },
        ],
      },
    ];
  }

  openDeleteDialog(row) {
    DialogEditLinkedWriter.openDeleteWriterDialog(this.dialog, this.store, this.translate, row, () => {
      this.reloadWritersList();
    });
  }

  onClickButtonIPISearchDialog() {
    DialogEditLinkedWriter.openAddWriterDialog(LINKED_WRITERS_IP_SEARCH, this.dialog, this.store, this.storeNewItem, this.translate, this.fuseTranslationLoader, () => {
      this.reloadWritersList();
    });
  }

  reloadWritersList() {
    this.store
      .pipe(
        select(fromRoot.getCopyrightDetailBySection),
        filter(detail => !!detail),
        take(1),
        map((detail: AgreementDetail) => {
          this.store.dispatch(new fromRoot.StartApiCall(fromApiCalls.getAgreementLinkedWriters));
        }),
      )
      .subscribe();
  }
}
