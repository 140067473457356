import { SUBPUBLISHER_ROLES } from 'config/constants';
import { WorkDetail } from 'models';

export const isSubPublisherRole = (role: string): boolean => {
  return SUBPUBLISHER_ROLES.includes(role);
};
export const filterClaimsLocally = (claims: any[], filterParams: Partial<WorkDetail['allClaimsFilter']> = {}) => {
  const { onlyInConflict, removeE, removeSE } = filterParams;
  const filteredClaims = claims.filter(claim => {
    const role = claim.roleRaw;

    const removeInConflict = onlyInConflict && claim.inDispute === 'Y';
    if (removeInConflict) return false;

    const removeRoleE = removeE && role === 'E';
    if (removeRoleE) return false;

    const removeRoleSE = removeSE && isSubPublisherRole(role);
    if (removeRoleSE) return false;

    return true;
  });
  return filteredClaims;
};

export const getSubPublisherNumberFromClaimId = (claimId: string): number => {
  const numbers = claimId.match(/_(\d+)$/);
  return numbers ? parseInt(numbers[1], 10) : 0;
};
