import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClaimantUtils, CounterClaimClaimantRelation, PostTermCollectionValue, CounterClaimWithdrawnStatus, ClaimsUtils, getPostTermCollectionInputs } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { CounterClaimInActive } from '@ice/components/expert-search-form/model/enums';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import * as fromApiCalls from 'config/api-calls';
import { ActionResponse, ActionStatus, ReducedActionResponses, WithDrawActionTypes } from 'config/constants/activity.constants';
import { CounterClaimActionDialog, CounterClaimActionDialogType, reduceVisibleRows } from 'config/constants/counter-claims.constants';
import { CopyrightClaimsDataTable } from 'config/data-table-builders/copyright.claims';
import { SectionsConfig } from 'config/sections-config';
import { get, groupBy, pick, toPairs } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import * as fromRoot from 'store/root';
import { errorMessageShowtime } from 'store/root/effects/ui/show-error';

export class DialogCounterClaimActions {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private flagShowMergedTable = this.featureFlagService.showNewMergedTableSbj;
  private expandTableSbj = new BehaviorSubject<boolean>(false);

  constructor(
    private translate: TranslateService,
    private store: Store<any>,
    private dialog: MatDialog,
    private schema: CopyrightClaimsDataTable,
    private commonApiService: CommonApiService,
    private featureFlagService: FeatureFlagService,
  ) {}

  toggleRows() {
    const currentValue = this.expandTableSbj.getValue();
    this.expandTableSbj.next(!currentValue);
  }

  openDialog(type: CounterClaimActionDialogType, actionRow?: any) {
    if (actionRow && type === CounterClaimActionDialog.View) {
      const actionResponse = get(actionRow, 'actionResponseRaw', '') || get(actionRow, 'response', '');
      if (WithDrawActionTypes.includes(get(actionRow, 'type', ''))) {
        this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
          data: {
            layouts: [this.getViewWithdrawnLayout(actionRow)],
          },
        });
      } else if (ReducedActionResponses.includes(actionResponse)) {
        const apiCallData = { labels: { actionId: get(actionRow, 'actionId', null) } };
        this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getActionReduceClaimsApiCall, apiCallData, callBack: result => this.viewReduceData(result) }));
      }
    } else {
      this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
        data: {
          loading: this.store.pipe(select(fromRoot.getCopyrightLoading)),
          layouts: this.getCounterClaimActionLayout(type),
        },
      });
    }
  }

  openIpOnNewTab(event) {
    if (event.activatedColumn === 'refLabel' || event.activatedColumn === 'name') {
      this.store.dispatch(
        new fromRoot.OpenNewTab({
          path: [`copyright/${SectionsConfig.IPS.name}/${event.ref || event.key}`],
        }),
      );
    }
  }

  private getCounterClaimActionLayout(type: CounterClaimActionDialogType) {
    switch (type) {
      case CounterClaimActionDialog.Withdraw:
        return [this.getDialogWithdrawClaimsLayout(), this.dialogResponseLayout(this.translate.instant('COUNTER.WITHDRAW_DIALOG.TITLE'))];
      case CounterClaimActionDialog.Reduce:
        return [this.getDialogReduceClaimsLayout(), this.dialogResponseLayout(this.translate.instant('COUNTER.REDUCE_DIALOG.TITLE_VIEW_ONLY'))];
      case CounterClaimActionDialog.Resolve:
        return [this.getDialogResolveClaimsLayout(), this.dialogResponseLayout(this.translate.instant('COUNTER.RESOLVE_COUNTERCLAIM_DIALOG.TITLE'))];
    }
  }

  private getDialogWithdrawClaimsLayout() {
    const checkRowsSubject = new BehaviorSubject([]);
    return {
      title: of(this.translate.instant('COUNTER.WITHDRAW_DIALOG.TITLE')),
      className: 'dialog-wrapper-width-150vw',
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                formBuilder: of(this.getWithdrawClaimsForm(checkRowsSubject)),
                model: of({}),
                submitAvailable: true,
                submitLabel: this.translate.instant('COUNTER.WITHDRAW_DIALOG.SUBMIT_BUTTON'),
                submit: form => {
                  const { withdrawType, terminationDate, postTermCollectionValue, postTermCollectionDate } = form;
                  const body = JSON.stringify({
                    response: 'WITHDRAWN',
                    actions: checkRowsSubject.value
                      .map(claim => get(claim, `actionId`))
                      .map(id => ({
                        id,
                        actions: {
                          withdrawType,
                          ...(terminationDate && { terminationDate }),
                          ...(postTermCollectionValue && { postTermCollection: ClaimantUtils.parsePostTermCollectionValue(postTermCollectionValue) }),
                          ...(postTermCollectionDate && { postTermCollectionDate }),
                          claims: JSON.stringify(JSON.stringify(get(form, 'parties'))),
                        },
                      })),
                  });
                  this.dialogRef.componentInstance.setLayout(1);
                  this.store.dispatch(
                    new fromRoot.StartApiCall({ apiCall: fromApiCalls.updateCounterclaim, apiCallData: { body }, callBack: response => this.afterCounterClaimAction(response) }),
                  );
                },
              },
            },
          ],
        },
      ],
    };
  }

  private getDialogReduceClaimsLayout(data?) {
    const reducedClaimList$ = new BehaviorSubject(null);
    const newReducedClaim$ = new BehaviorSubject(null);
    return {
      title: data ? of(this.translate.instant('COUNTER.REDUCE_DIALOG.TITLE_VIEW_ONLY')) : of(this.translate.instant('COUNTER.REDUCE_DIALOG.TITLE')),
      className: 'dialog-wrapper-width-100vw',
      actions: !data && [
        { tooltip: this.translate.instant('CANCEL_NEW'), color: 'warn', nextLayout: 0, icon: 'close', onClick: () => this.dialogRef.close() },
        {
          tooltip: this.translate.instant('SAVE_NEW'),
          nextLayout: 1,
          disabled: reducedClaimList$.pipe(map(reducedClaims => !ClaimantUtils.hasReducedClaims(reducedClaims))),
          icon: 'done',
          onClick: () => {
            const actionGroups = groupBy(
              reducedClaimList$.value
                .filter(claim => claim.actionId && (!isNaN(claim.reducedPr) || !isNaN(claim.reducedMr)))
                .map(claim => pick(claim, [...reduceVisibleRows, 'actionId'])),
              'actionId',
            );
            const body = JSON.stringify({
              response: 'REDUCED',
              actions: toPairs(actionGroups).map(([actionId, claims]) => ({
                id: actionId,
                actions: {
                  claims: JSON.stringify(JSON.stringify(claims)),
                },
              })),
            });
            this.dialogRef.componentInstance.setLayout(1);
            this.store.dispatch(
              new fromRoot.StartApiCall({ apiCall: fromApiCalls.updateCounterclaim, apiCallData: { body }, callBack: response => this.afterCounterClaimAction(response) }),
            );
          },
        },
      ],
      layout: [
        {
          group: [
            {
              type: 'cardWithDataTable',
              config: {
                title: this.translate.instant('COUNTER.ASSOCIATED_CLAIMS'),
                model:
                  (data && of(data)) ||
                  combineLatest([
                    this.store.select(fromRoot.getCounterClaimWork).pipe(
                      filter(counterClaimWork => !!counterClaimWork && !!counterClaimWork.workClaims),
                      map(counterClaimWork =>
                        ClaimantUtils.getRelatedParties(counterClaimWork.workClaims, counterClaimWork.participants, counterClaimWork.actions, this.translate),
                      ),
                      withLatestFrom(this.store.select(fromRoot.getUserCurrentOrganization)),
                      map(([parties, currentOrganization]) => ClaimantUtils.getUserAllowedCounterClaimParties(parties, currentOrganization, true)),
                    ),
                    newReducedClaim$,
                  ]).pipe(
                    withLatestFrom(reducedClaimList$),
                    map(([[claims, reduced], reducedClaims]) => ClaimantUtils.reduceClaims(reducedClaims || claims, reduced)),
                    tap(reducedClaims => reducedClaimList$.next(reducedClaims)),
                  ),
                dontStrip: true,
                customClass: 'claim-graph',
                sorts: [],
                schema: this.schema.getDataTable(null, null, null, null, null, null, newReducedClaim => newReducedClaim$.next(newReducedClaim)),
                onMouseSelect: event => this.openIpOnNewTab(event),
                visibleColumns: of(reduceVisibleRows),
                loadingIndicator: false,
                isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
                reorderable: true,
                shadowed: false,
                columnMode: 'flex',
              },
            },
          ],
        },
      ],
    };
  }

  private getDialogResolveClaimsLayout() {
    return {
      title: of(this.translate.instant('COUNTER.RESOLVE_COUNTERCLAIM_DIALOG.TITLE')),
      actions: [],
      className: 'dialog-wrapper-auto',
      layout: [
        {
          group: [
            {
              type: 'response-error',
              config: {
                response: of({
                  text: this.translate.instant('COUNTER.RESOLVE_COUNTERCLAIM_DIALOG.MESSAGE'),
                }),
                errors: of(null),
                errorButtons: [],
                responseButtons: of([
                  {
                    text: of(this.translate.instant('POPUP.YES')),
                    class: 'ok-button',
                    action: () => {
                      this.dialogRef.componentInstance.setLayout(1);
                      this.store.dispatch(new fromRoot.UpdateField({ object: 'status', newValue: CounterClaimInActive.RESOLVED, type: 'edit' }));
                      this.store.dispatch(new fromRoot.UpdateField({ object: 'resolvedDate', newValue: moment().format('YYYY-MM-DD'), type: 'edit' }));
                      this.store.dispatch(new fromRoot.SaveSectionItem({ onSuccess: () => this.afterCounterClaimAction() }));
                    },
                  },
                  {
                    text: of(this.translate.instant('POPUP.NO')),
                    action: () => this.dialogRef.close(),
                  },
                ]),
              },
            },
          ],
        },
      ],
    };
  }
  private generateWithdrawPartiesFormControl({
    cardTitle = 'COUNTER.SELECTED_PARTIES',
    customClass = 'claim-graph',
    expandRowsEnabled = false,
    expandableProperty = null,
    detailVisibleColumns = null,
    checkRowsSubject = null,
    shouldAddParentRowClass = false,
    displayTopLevelIps = false,
    shouldDisplay = true,
    key = 'withdraw-parties',
    visibleColumns = ['rowSelected', 'relation', 'role', 'name', 'refLabel', 'resolutionOwnerLabel', 'actionType', 'actionResponse', 'actionDeadline', 'actionStatus'],
    data = this.store.select(fromRoot.getCounterClaimWork).pipe(
      filter(counterClaimWork => !!counterClaimWork && !!counterClaimWork.workClaims),
      map(counterClaimWork =>
        ClaimantUtils.getCounterClaimsRelatedParties(
          ClaimantUtils.getCounterClaimParties(counterClaimWork.workClaims, counterClaimWork.participants, counterClaimWork.actions, this.translate),
          counterClaimWork.participants,
          this.translate,
          displayTopLevelIps,
        ),
      ),
      withLatestFrom(this.store.select(fromRoot.getUserCurrentOrganization)),
      map(([parties, currentOrganization]) => ClaimantUtils.getUserAllowedCounterClaimParties(parties, currentOrganization)),
    ),
  }) {
    if (shouldDisplay) {
      let partiesFormControl: FormControl;
      return {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key,
            type: 'datatable-editable',
            wrappers: ['wrapper-card'],
            templateOptions: {
              cardTitle: this.translate.instant(cardTitle),
              required: true,
              config: {
                title: this.translate.instant(cardTitle),
                data,
                dontStrip: true,
                customClass,
                shouldAddParentRowClass,
                expandRowsEnabled,
                expandableProperty: of(expandableProperty),
                detailVisibleColumns: of(detailVisibleColumns),
                expandableSchema: expandableProperty === 'associatedClaims' ? { associatedClaims: this.schema.getCounterClaimDetailsDataTable({}) } : {},
                schema: expandableProperty === 'associatedClaims' ? this.schema.getCounterClaimDetailsDataTable({ displaySelectColumn: true }) : this.schema.getDataTable(),
                visibleColumns: of(visibleColumns),
                loadingIndicator: false,
                isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
                shadowed: false,
                columnMode: 'flex',
                selectionType: SelectionType.checkbox,
                selected: checkRowsSubject,
                expandAllRows: this.expandTableSbj.asObservable(),
                rowIdentity: row => row.sortProperty,
                onSelect: (selecteds: any[]) => {
                  checkRowsSubject.next(selecteds);
                  if (partiesFormControl) {
                    partiesFormControl.setValue(selecteds);
                    partiesFormControl.markAsDirty();
                  }
                },
                displayCheck: (row: any, column?: any, value?: any) => {
                  return (
                    row.actionId &&
                    row.relationRaw === CounterClaimClaimantRelation.RESOLVER &&
                    row.actionStatusRaw !== ActionStatus.CLOSED &&
                    !(row.actionResponseRaw === ActionResponse.NOT_SUPPORTED || row.actionResponseRaw === ActionResponse.WITHDRAWN) &&
                    row.userAllowed
                  );
                },
              },
            },
            hooks: {
              onInit: field => {
                partiesFormControl = field.formControl;
              },
            },
          },
        ],
      };
    } else return {};
  }
  private getWithdrawClaimsForm(checkRowsSubject) {
    let partiesFormControl: FormControl;

    const terminationName = 'terminationDate';
    const terminationLabel = this.translate.instant('WORKS.DELETE_CLAIM.TERMINATION_DATE');
    const translate = this.translate;
    const terminationExpressionProperties = {
      'templateOptions.required': model => model.withdrawType === CounterClaimWithdrawnStatus.TERMINATE,
      'templateOptions.disabled': model => model.withdrawType !== CounterClaimWithdrawnStatus.TERMINATE,
    };

    const postTermName = 'postTermCollectionDate';
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            key: 'btn',
            type: 'button',
            className: 'bt-detail-toggle',
            templateOptions: {
              materialType: 'mat-button',
              text: translate.instant('COUNTER.EXPAND_ROWS'),
              type: 'flat',
              icon: 'keyboard_arrow_down',
              btnType: ' ice-accent',
              iconPosition: 'right',
              dynamicallyDisplay: true,
              shouldDisplay$: combineLatest([this.featureFlagService.showNewMergedTableSbj, this.expandTableSbj.asObservable()]).pipe(
                map(([flag, expanded]) => !flag || !expanded),
              ),
              onClick: () => this.toggleRows(),
            },
          },
          {
            key: 'btn',
            type: 'button',
            className: 'bt-detail-toggle',
            templateOptions: {
              materialType: 'mat-button',
              text: translate.instant('COUNTER.COLLAPSE_ROWS'),
              type: 'flat',
              icon: 'keyboard_arrow_up',
              btnType: ' ice-accent',
              iconPosition: 'right',
              dynamicallyDisplay: true,
              shouldDisplay$: combineLatest([this.featureFlagService.showNewMergedTableSbj, this.expandTableSbj.asObservable()]).pipe(map(([flag, expanded]) => !flag || expanded)),
              onClick: () => this.toggleRows(),
            },
          },
        ],
      },
      this.generateWithdrawPartiesFormControl({
        shouldDisplay: !this.flagShowMergedTable.getValue(),
        cardTitle: 'COUNTER-CLAIM-SUPPORT.STEPS.PARTIES.REVIEW_CLAIMS',
        visibleColumns: ['role', 'name', 'prMr', 'agreementId', 'territoriesTisa', 'allRights', 'pr', 'mr', 'reducemr', 'startDate', 'endDate', postTermName, 'conflict'],
        key: 'review-parties',
        data: this.store.select(fromRoot.getCounterClaimWork).pipe(
          filter(counterClaimWork => !!counterClaimWork && !!counterClaimWork.workClaims),
          map(counterClaimWork => ClaimantUtils.getRelatedParties(counterClaimWork.workClaims, counterClaimWork.participants, counterClaimWork.actions, this.translate)),
        ),
      }),
      this.generateWithdrawPartiesFormControl({ checkRowsSubject, shouldDisplay: !this.flagShowMergedTable.getValue() }),
      this.generateWithdrawPartiesFormControl({
        customClass: 'merged-table',
        expandRowsEnabled: true,
        expandableProperty: 'associatedClaims',
        detailVisibleColumns: ['relation', 'role', 'name', 'prMr', 'startDate', 'endDate', postTermName, 'territoriesTisa', 'allRights', 'status', 'pr', 'mr'],
        checkRowsSubject,
        shouldAddParentRowClass: true,
        displayTopLevelIps: true,
        shouldDisplay: this.flagShowMergedTable.getValue(),
      }),
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            key: 'withdrawType',
            className: 'flex-1',
            modelOptions: { updateOn: 'blur' },
            type: 'select',
            templateOptions: {
              label: this.translate.instant('COUNTER.WITHDRAW_DIALOG.WITHDRAW_TYPE'),
              required: true,
              options: [
                { value: CounterClaimWithdrawnStatus.DELETE, label: this.translate.instant('COUNTER.WITHDRAW_DIALOG.DELETE_CLAIMS') },
                { value: CounterClaimWithdrawnStatus.TERMINATE, label: this.translate.instant('COUNTER.WITHDRAW_DIALOG.TERMINATE_CLAIMS') },
              ],
            },
          },
          DatepickerUtils.getDatepickerField({
            key: terminationName,
            label: terminationLabel,
            translate,
            expressionProperties: terminationExpressionProperties,
          }),
          ...getPostTermCollectionInputs({
            translate,
            endDateKey: terminationName,
            postTermProps: {
              key: 'postTermCollectionValue',
              className: 'flex-1',
              hooks: {
                onInit: field => {
                  field.form.get('withdrawType').valueChanges.subscribe(withdrawType => {
                    field.templateOptions.disabled = withdrawType !== CounterClaimWithdrawnStatus.TERMINATE;
                    if (withdrawType === CounterClaimWithdrawnStatus.DELETE) {
                      field.formControl.setValue('');
                      field.model[postTermName] = '';
                    } else {
                      field.formControl.setValue(PostTermCollectionValue.NONE);
                    }
                  });
                },
              },
            },
            postTermDateProps: {
              key: postTermName,
              extraClass: 'flex-1',
            },
          }),
        ],
      },
    ];
  }

  private getViewWithdrawnLayout(row) {
    return {
      title: of(this.translate.instant('COUNTER.VIEW_WITHDRAWN_DIALOG.TITLE')),
      className: 'dialog-wrapper-width-100vw',
      layout: [
        {
          group: [
            {
              type: 'cardWithDataTable',
              config: {
                title: this.translate.instant('COUNTER.VIEW_WITHDRAWN_DIALOG.CARD_TITLE'),
                model: of(row.withdrawnInfo),
                dontStrip: true,
                customClass: 'claim-graph',
                sorts: [],
                schema: [
                  {
                    name: this.translate.instant('COUNTER.VIEW_WITHDRAWN_DIALOG.FIELDS.TYPE'),
                    prop: 'withdrawType',
                    flexGrow: 1.5,
                  },
                  {
                    name: this.translate.instant('COUNTER.VIEW_WITHDRAWN_DIALOG.FIELDS.POST_TERM_COLLECTION'),
                    prop: 'postTermCollection',
                    flexGrow: 1.5,
                  },
                  {
                    name: this.translate.instant('COUNTER.VIEW_WITHDRAWN_DIALOG.FIELDS.POST_TERM_COLLECTION_DATE'),
                    prop: 'postTermCollectionDate',
                    flexGrow: 1.5,
                  },
                  {
                    name: this.translate.instant('COUNTER.VIEW_WITHDRAWN_DIALOG.FIELDS.TERMINATION_DATE'),
                    prop: 'terminationDate',
                    flexGrow: 1.5,
                  },
                ],
                loadingIndicator: false,
                reorderable: false,
                shadowed: false,
                columnMode: 'flex',
              },
            },
          ],
        },
      ],
    };
  }

  private dialogResponseLayout(title) {
    return {
      title: of(title),
      className: 'dialog-wrapper-height-auto',
      layout: [],
    };
  }

  private openReduceDialogView(data) {
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        loading: this.store.pipe(select(fromRoot.getCopyrightLoading)),
        layouts: [this.getDialogReduceClaimsLayout(data)],
      },
    });
  }

  private viewReduceData(response) {
    this.commonApiService.downloadJSON(response.url).subscribe((json: string) => {
      const data = JSON.parse(JSON.parse(json));
      this.openReduceDialogView(
        data.map(row => ({
          ...row,
          ...(row.reducedPr && { reducedPr: `${row.reducedPr} %` }),
          ...(row.reducedMr && { reducedMr: `${row.reducedMr} %` }),
          reducePending: true,
          rowClass: 'reduce-pending-claimant-row',
        })),
      );
    });
  }

  private afterCounterClaimAction(response?) {
    if (response?.url) {
      this.commonApiService.downloadJSON(response.url).subscribe((json: string) => {
        const data = JSON.parse(JSON.parse(json));
        this.openReduceDialogView(
          data.map(row => ({
            ...row,
            ...(row.reducedPr && { reducedPr: `${row.reducedPr} %` }),
            ...(row.reducedMr && { reducedMr: `${row.reducedMr} %` }),
            reducePending: true,
            rowClass: 'reduce-pending-claimant-row',
          })),
        );
      });
    } else {
      this.store.dispatch(
        new fromRoot.ShowSnackBar({
          icon: 'done',
          message: this.translate.instant('COUNTER.REQUEST_SUCCESS'),
          duration: errorMessageShowtime.normal,
          position: {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          },
        }),
      );
      this.dialogRef.close();
      this.store.dispatch(new fromRoot.RefreshDetail());
    }
  }
}
