import { FormGroup } from '@angular/forms';
import {
  ActivityTrigger,
  AgreementUtils,
  ClaimsUtils,
  ContributorsUtils,
  CopyrightOverride,
  CopyrightUtils,
  CounterClaimUtils,
  DateTimeUtils,
  IceOption,
  IceOptions,
  IpClause,
  IpUtils,
  PostTermCollectionValue,
  RelationsUtils,
  SocietiesUtils,
  TerritoryUtils,
  Work,
  WorkClause,
  WorkDetail,
  hasItems,
} from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { CounterClaimStatus } from '@ice/components/expert-search-form/model/enums';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { searchPartyName } from 'config/api-calls';
import { CREATORS, DATE_FORMAT } from 'config/constants/global.constants';
import { IP_CLAUSES, PartyRelationType, mrRights, prRights } from 'config/constants/ips.constants';
import { RELATION_ORIGIN } from 'config/constants/relation.constants';
import { ARRANGERS_AND_ADAPTER_ROLES, CREATOR_ROLES, CREATOR_ROLES_LIST, WORK_CLAIM_SHARE_TYPE } from 'config/constants';
import { TerritoryDataType } from 'config/constants';
import {
  INVALID_SPECIAL_ICONS,
  ROLE_SPECIAL,
  SOCIETIES,
  TitleTypes,
  WORK_CATEGORY_OPTIONS,
  WORK_MUSIC_RELATIONSHIP_OPTIONS,
  WORK_TAGS,
  WORK_TAGS_SUBMITTED,
} from 'config/constants/works.constants';
import { SectionCopyrightWorks } from 'config/sections-config/section-copyright-works';
import * as _ from 'lodash';
import { cloneDeep, compact, concat, filter, find, flatten, forEach, get, groupBy, has, includes, isEqual, last, lowerCase, omit, orderBy, remove, uniqBy, uniqWith } from 'lodash';
import type {
  CaseWorkDetail,
  ClaimRow,
  CopyrightOwnershipTableItem,
  SendWork,
  SendWorkContribution,
  SendWorkCopyrightOverride,
  TitlesInterface,
  WorkCleaned,
  WorkInstrument,
  WorkInstrumentation,
  WorkSearchItemExtended,
  WorkTitles,
  WorksConflict,
} from 'models';
import { EditModeHistory, SelectEditorDatatableRow } from 'models/copyright/detail/edit-mode';
import { SelectOption } from 'models/copyright/formly/formly';
import { OptionsGroup } from 'models/options-group';
import moment from 'moment';
import { StartApiCall } from 'store/root';

export class WorkUtils {
  static selectTitle(titles: any): TitlesInterface {
    const titleProp = 'titleValue';
    const titleNationalProp = 'national';
    const durationProp = 'duration';

    if (titles && titles.OT && titles.OT.length) {
      if (titles.OT[0][titleNationalProp]) {
        return { type: 'OT', title: titles.OT[0][titleNationalProp], duration: titles.OT[0][durationProp] };
      } else if (titles.OT[0][titleProp]) {
        // UI-897: Breaking changes in TitleValue
        return { type: 'OT', title: titles.OT[0][titleProp], duration: titles.OT[0][durationProp] };
      }
      // fallback to previous structure
      return { type: 'OT', title: titles.OT[0].toString(), duration: titles.OT[0][durationProp] };
    } else if (titles && titles.AT && titles.AT.length) {
      if (titles.AT[0][titleNationalProp]) {
        // UI-897: Breaking changes in TitleValue
        return { type: 'AT', title: titles.AT[0][titleNationalProp], duration: titles.AT[0][durationProp] };
      } else if (titles.AT[0][titleProp]) {
        // UI-897: Breaking changes in TitleValue
        return { type: 'AT', title: titles.AT[0][titleProp], duration: titles.AT[0][durationProp] };
      }
      // fallback to previous structure
      return { type: 'AT', title: titles.AT[0].toString(), duration: titles.AT[0][durationProp] };
    }

    return null;
  }

  static reorderEditHistoryItems(items: EditModeHistory[], maxItemsToSave: number): EditModeHistory[] {
    return items && items.length < maxItemsToSave + 1
      ? items
      : items
          .reverse()
          .slice(items.length - maxItemsToSave)
          .reverse() || [];
  }

  static selectWorkKey(workRelations, id: string): string {
    // Key Selection Order:
    // 1- DISP -> ICE first, ISWC second
    // 2- XREF -> ICE first, ISWC second
    // 3- if Work and relations ISWC
    // 4- id
    if (workRelations) {
      const dispOrXrefKey = RelationsUtils.selectRelationByTypeAndPrefix(workRelations, ['DISP', 'XREF'], ['ICE', 'ISWC']);
      if (dispOrXrefKey) {
        return dispOrXrefKey;
      }

      const iswcRelation = workRelations.find(relation => get(relation, 'otherId').split(':')[0] === 'ISWC');

      return (iswcRelation && iswcRelation.otherId) || id;
    }
    return id;
  }

  static selectIPIKey(relations) {
    const xrefItemIPI = find(
      relations,
      ({ otherId, relation }) => otherId && otherId.split(':')[0] === 'IPI' && [PartyRelationType.CROSS_REFERENCE, PartyRelationType.MATCH].indexOf(relation) !== -1,
    );
    if (relations && relations.length && xrefItemIPI) {
      return (xrefItemIPI && xrefItemIPI.otherId) || '';
    }
    return '';
  }

  static getTitles(data: Object[], titleType: string): TitlesInterface[] {
    const result = [];
    if (data) {
      data.forEach(item => {
        if (item['titleValue']) {
          // UI-897: Breaking change of title sctructure
          result.push({ title: item['titleValue'], type: titleType });
        } else {
          result.push({ title: item, type: titleType });
        }
      });
    }
    return result;
  }

  static getIswc(workDetail: WorkDetail): string {
    return workDetail?.relations?.find(relation => relation?.otherId?.match('ISWC'))?.otherId || '';
  }

  static getWorkToCompareXrefsIdsOrdered(relations, iteratees = ['value'], orders = ['asc']) {
    const values =
      (relations &&
        orderBy(
          filter(relations, relation => relation.relation === 'XREF' && !relation.otherId.includes('CUBE')).map(item => {
            return {
              key: item.otherId,
              value: `${item.otherId}`,
              rawItem: item,
              hasMTCH: !!find(relations, relation => relation.relation === 'MTCH' && relation.otherId === item.otherId),
              tooltip: `WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.${item.otherId.split(':')[0]}`,
            };
          }),
          iteratees,
          orders,
        )) ||
      [];
    const iswc = values.filter(item => item.value.includes('ISWC:'));
    const isPub = values.filter(item => item.value.includes('PUB:'));
    const others = values.filter(item => !item.value.includes('ISWC:') && !item.value.includes('PUB:'));
    return [...iswc, ...isPub, ...others] || [];
  }

  static joinWorkContributorsAgreements(work: WorkDetail, translate) {
    const agreements = [];
    if (work && work.contributions) {
      work.contributions.forEach(contribution => {
        if (contribution.agreements) {
          contribution.agreements.forEach(a => {
            if (a.agreement) {
              agreements.push(AgreementUtils.cleanAgreement(a.agreement, translate));
            }
          });
        }
      });
    }
    return agreements;
  }

  static getWorkAlternativeTitles(titles): TitlesInterface[] {
    const titlesList = [];
    const titlesOT = [];
    const titlesAT = [];
    Object.keys(titles).map(type => {
      (titles[type] || []).map(item => {
        if (item && (item['national'] || item['titleValue'])) {
          const title = { title: item && item['national'] ? item['national'] : item['titleValue'], type, duration: item && item['duration'] };
          if (type === 'OT') {
            titlesOT.push(title);
          } else if (type === 'AT') {
            titlesAT.push(title);
          } else {
            titlesList.push(title);
          }
        } else {
          titlesList.push({ title: item, type });
        }
      });
    });
    return [...titlesOT, ...titlesAT, ...titlesList];
  }

  static getWorkDetailPartyNamesOrdered(data, iteratees = ['value'], orders = ['asc']) {
    return (
      (data &&
        orderBy(
          data.map(item => {
            if (item.first_name && item.first_name.trim()) {
              return { key: item.id, value: `${item.name}, ${item.first_name}` };
            } else {
              return { key: item.id, value: `${item.name}` };
            }
          }),
          iteratees,
          orders,
        )) ||
      []
    );
  }

  static getWorkDetailContributorsOrdered(data: WorkDetail, iteratees = ['name'], orders = ['asc']) {
    if (data) {
      return (
        (data.contributions &&
          orderBy(
            data.contributions.map(contribution => {
              const name = ContributorsUtils.selectContributionPartyNameFullName(contribution) || '';
              return { id: contribution.contributorId, role: contribution.role, name };
            }),
            iteratees,
            orders,
          )) ||
        []
      );
    }
    return [];
  }

  static getWorkPartyNames(partyNames) {
    if (partyNames) {
      return partyNames
        .filter(partyName => !!partyName.role)
        .map(party => {
          const role = party.role || '';
          const roleLabel = IpUtils.getOtherPartyLabelFromValue(party.role);
          const partyName = party.partyName;
          const attributes = partyName && partyName.attributes;
          const partyNameId = (attributes && attributes.id) || '';
          const typeOfName = (attributes && attributes.typeOfName) || '';
          const fullName = (party && IpUtils.getPartyFullName(party)) || '';
          const ipiNameNumber = (partyName.relations && this.selectIPIKey(partyName.relations) && this.selectIPIKey(partyName.relations).replace('IPI:', '')) || '';
          const formattedPartyName = attributes ? { role, roleLabel, partyNameId, typeOfName, fullName, ipiNameNumber, rawItem: party } : {};
          return formattedPartyName;
        });
    }
    return [];
  }

  static getWorkWithIswcAndDuration(detail: WorkDetail, translate?): Work {
    const { attributes, counterclaims } = detail;
    const iswc = WorkUtils.getIswc(detail);
    const durationFormatted = DateTimeUtils.getDurationHoursFormat(detail && attributes?.duration);
    const formattedAttributes = WorkUtils.fixAttributesUndefinedValues(attributes);
    const shareDivisionOwnerTooltip = attributes.shareDivisionOwner ? SocietiesUtils.getSocietyName(attributes.shareDivisionOwner) : '';
    const ownerTooltip = attributes.owner ? SocietiesUtils.getSocietyName(attributes.owner) : '';
    const originTooltip = attributes.origin ? translate?.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.ORIGIN_TYPE.${attributes.origin}`) : '';
    const genreTooltip = attributes.genre ? translate?.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.GENRE_TYPE.${attributes.genre}`) : '';
    const purposeTooltip = attributes.purpose ? translate?.instant(`WORKS.PURPOSES.${attributes.purpose}`) : '';
    const categoryTooltip = attributes.category ? translate?.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.CATEGORY_OPTIONS.${attributes.category}`) : '';
    const insDatesArray = cloneDeep(get(attributes, 'tags.insdate[0]') && get(attributes, 'tags.insdate'));
    const insDate = insDatesArray && insDatesArray.sort((a, b) => moment(a).diff(b))[0];
    const tagsCreatedDate = get(detail, 'tags.created', '');
    const workCreationDate = tagsCreatedDate ? moment(new Date(tagsCreatedDate)).format(DATE_FORMAT) : '';
    const creationDate = insDate || workCreationDate;

    return {
      ...formattedAttributes,
      iswc,
      durationFormatted,
      shareDivisionOwnerTooltip,
      ownerTooltip,
      originTooltip,
      genreTooltip,
      purposeTooltip,
      categoryTooltip,
      creationDate,
      counterclaims,
    };
  }

  static fixAttributesUndefinedValues(attributes: Work): Work {
    const { lyricAdaption, textMusicRelationship, musicArrangement, source, purpose, category } = attributes;
    return {
      ...attributes,
      lyricAdaption: lyricAdaption || null,
      textMusicRelationship: textMusicRelationship || null,
      musicArrangement: musicArrangement || null,
      source: source || null,
      purpose: purpose || null,
      category: category || null,
    };
  }

  static getWorkInstruments(instruments: WorkInstrument[], translate: TranslateService) {
    return (
      instruments &&
      instruments.map((instrument, index) => {
        const { instrumentCode } = instrument;
        const instrumentName = translate?.instant(`INSTRUMENT.${instrument.instrumentCode}.DESCRIPTION`);
        const instrumentFamily = translate?.instant(`INSTRUMENT.${instrument.instrumentCode}.INSTRUMENT_FAMILY`);
        const numberOfInstruments = instrument.numberOfInstruments === 0 ? '0' : instrument.numberOfInstruments;
        const numberOfPlayers = instrument.numberOfPlayers === 0 ? '0' : instrument.numberOfPlayers;
        return { instrumentCode, numberOfInstruments, numberOfPlayers, instrumentName, instrumentFamily, index };
      })
    );
  }

  static getWorkInstrumentations(instrumentations: WorkInstrumentation[], translate: TranslateService) {
    return (
      instrumentations &&
      instrumentations.map((instrumentation, index) => {
        const { instrumentCode } = instrumentation;
        const instrumentName = translate?.instant(`INSTRUMENTATION.${instrumentation.instrumentCode}.DESCRIPTION`);
        const instrumentDescription = translate?.instant(`INSTRUMENTATION.${instrumentation.instrumentCode}.DESCRIPTION_TEXT`);
        const numberOfInstruments = instrumentation.numberOfInstruments === 0 ? '0' : instrumentation.numberOfInstruments;
        const numberOfPlayers = instrumentation.numberOfPlayers === 0 ? '0' : instrumentation.numberOfPlayers;
        const numberOfParts = instrumentation.numberOfParts === 0 ? '0' : instrumentation.numberOfParts;
        return { instrumentCode, instrumentName, instrumentDescription, numberOfInstruments, numberOfPlayers, numberOfParts, index };
      })
    );
  }

  static createRawPartyNamesWithClauses(ipiList: any[], workClauses: WorkClause[]) {
    return ipiList.map(ipi => ({
      partyNameId: ipi.partyNameId,
      clauses: (groupBy(workClauses, workClause => workClause.ipiNumber)[ipi.refLabel] || []).map((workClause: WorkClause) => workClause.type),
    }));
  }

  static editWorkClaimShares(shares, claim, type) {
    const sourceClaims = type === WORK_CLAIM_SHARE_TYPE.SHARES ? claim.claimShares : claim.ownershipShares;
    remove(sourceClaims, (sourceClaim, index) => !shares[type].find(share => share.index === index));
    const indexes = [];
    let repeatedIndex = false;
    (shares[type] || []).map(share => {
      const index = share.index;
      if (!indexes.includes(index)) {
        indexes.push(index);
        repeatedIndex = false;
      } else {
        repeatedIndex = true;
      }
      const currentShare = (index !== undefined && !repeatedIndex && sourceClaims[index]) || {};
      const endDate = DateTimeUtils.getIndefiniteDate(share.endDate);
      const priorRoyaltiesDate = DateTimeUtils.getIndefiniteDate(share.priorRoyaltiesDate);
      const postTermCollectionDate = DateTimeUtils.getIndefiniteDate(share.postTermCollectionDate);
      const startDate = DateTimeUtils.getIndefiniteDate(share.startDate);
      currentShare.endDate =
        (endDate && moment(endDate).format(DATE_FORMAT)) || share.postTermCollectionValue?.toUpperCase() === PostTermCollectionValue.NONE ? endDate : '9999-12-31';
      currentShare.priorRoyaltiesDate = (priorRoyaltiesDate && moment(priorRoyaltiesDate).format(DATE_FORMAT)) || '';
      currentShare.rightTypes = share.inclusion === 'ALL' ? (share.type === 'Performing' && prRights) || mrRights : share.inclusion.split(',');
      currentShare.postTermCollectionDate =
        (postTermCollectionDate && moment(postTermCollectionDate).format(DATE_FORMAT)) || share.postTermCollectionValue?.toUpperCase() === PostTermCollectionValue.NONE
          ? postTermCollectionDate
          : '9999-12-31';
      currentShare.share = (share.share && parseFloat(share.share) * 100) || 0;
      currentShare.unauthorized = !!share.unauthorized;
      currentShare.startDate = (startDate && moment(startDate).format(DATE_FORMAT)) || '1900-01-01';
      currentShare.territories = TerritoryUtils.convertTerritoryStringElements(TerritoryUtils.splitTerritoryStringCodes(share.territory), TerritoryDataType.TISN);
      if (index === undefined || repeatedIndex) {
        sourceClaims.push(currentShare);
      }
    });
  }

  static getWorkCreationDate(item: WorkSearchItemExtended): string {
    const tagsCreatedDate = get(item, 'tags.created', '');
    const workCreationDate = tagsCreatedDate ? moment(new Date(tagsCreatedDate)).format(DATE_FORMAT) : '';
    return get(item, 'attributes.tags.insdate.[0]') || workCreationDate;
  }

  static workSearchCleaner(
    items: WorkSearchItemExtended[],
    relationType = '',
    external = false,
    relationTypeDisplay = null,
    translate: TranslateService,
    detail?: any,
    store?: Store<any>,
    fromRoot?,
  ): WorkCleaned[] {
    return items.map((item: WorkSearchItemExtended) => {
      const { id, attributes, relations, contributions, claims, rawItem, activityTriggersCount, conflictsCount, counterclaims } = item;
      if (attributes) {
        const { genre, publicationDate, source, owner, status, duration, language, tags, purpose, origin } = attributes;
        const titleItem = WorkUtils.selectTitle(attributes.titles);
        const key = WorkUtils.selectWorkKey(relations, id);
        let namesToString: string;
        let roleCodesToString: string;
        let roleCodesToStringTooltip: any;
        let IPINumbersToString: string;
        let creators;
        let rowClass = '';
        let tooltipTitles: string = null;
        let titlesTotal = null;
        let tooltipCreators: string = null;
        const creationDate: string = WorkUtils.getWorkCreationDate(item);
        const tooltipTitlesType: string = null;
        const insuser = tags && get(tags, 'insuser[0]', '');
        const userSociety = (insuser || '').includes(' - ') ? insuser.split(' - ')[1].split(' ')[0] : insuser;
        const userSocietyItem = ipsSocietiesCodes.find(society => society.name === (userSociety || '').toUpperCase());
        const migratedFromFormatted = userSocietyItem ? `${userSocietyItem.code} - ${userSocietyItem.name}` : '';
        if (hasItems(contributions)) {
          namesToString = ContributorsUtils.concatContributionsPartyNames(contributions);
          roleCodesToString = ContributorsUtils.concatContributionsRolesCodes(contributions);
          roleCodesToStringTooltip = ContributorsUtils.getRoleLabelFromRoleContributions(contributions, translate);
          IPINumbersToString = ContributorsUtils.concatContributionsPartyNamesIPINumbers(contributions);
          creators = contributions
            .filter(cont => CREATORS.includes(cont.role))
            .map(contribution => {
              if (has(contribution, 'contributor')) {
                const ip =
                  has(contribution, 'contributor.partyName.relations') && IpUtils.selectIPINumber(get(contribution, 'contributor.partyName.relations')).replace('IPI:', '');
                return {
                  name: ContributorsUtils.selectContributionPartyNameFullName(contribution),
                  role: contribution.role,
                  contributorPartyNameId: ip,
                };
              }
            });
          tooltipCreators = this.createTableCreatorsTooltip(creators, translate);
          if (creators.length > 5) {
            rowClass = ' ice-bold-row';
          }
        }
        if (attributes.titles) {
          ({ tooltipTitles, rowClass, titlesTotal } = this.createWorkTitlesField(attributes.titles, rowClass));
        }
        let wsm = WorkUtils.getWSM(item);
        let tooltipWsm;
        let exportWSM: string;
        if (wsm) {
          const wsmClass = wsm === 'NO_WSM' ? 'ice-wsm-grey' : wsm === 'ALL_SM' ? 'ice-wsm-green' : 'ice-wsm-orange';
          wsm = `<div class="ice-wsm-icon ${wsmClass}"> WSM </div>`;
          const societyMarkers = attributes?.societyMarkers || [];
          const societies = societyMarkers.map(societyCode => ipsSocietiesCodes.find(soc => societyCode === soc.code) || ipsSocietiesCodes.find(soc => societyCode === soc.name));
          tooltipWsm = this.createSocietyTooltip(societies);
          exportWSM = societies.map(soc => `${soc.code} ${soc.name}`).join(', ');
        }
        const activityIcon =
          activityTriggersCount > 0 &&
          CopyrightUtils.generateIconWithTooltip(translate?.instant('WORKS.ACTIVITY_ICON'), 'music_note', 'ice-work-activity-icon', (row, event) => {
            if (store && fromRoot) {
              store.dispatch(
                new fromRoot.Go({
                  path: [`${SectionCopyrightWorks.url}/${row.key}/work-activity`],
                  forceReload: true,
                }),
              );
            }
          });

        const counterclaimsFormatted = item.conflicts ? compact(WorkUtils.getCounterClaimsFromWorkConflicts(item.conflicts)) : [];
        const htmlText = counterclaimsFormatted && counterclaimsFormatted.length > 0 ? CounterClaimUtils.getCounterClaimsHtmlTable(counterclaimsFormatted, translate) : '';
        let counterclaimText;
        if (counterclaims?.length > 0 && counterclaims.filter(cc => cc?.attributes?.status !== CounterClaimStatus.RESOLVED).length > 0) {
          counterclaimText = '<div class="ice-cc-icon">CC</div>';
        }

        let iconConflict;
        let iconConflictTooltip;
        if (conflictsCount > 0) {
          iconConflict = `<div class="ice-conflicts-icon"> CW </div>`;
          iconConflictTooltip = `${translate?.instant(`WORKS.CONFLICT_TOOLTIP`)}<br>${translate?.instant(`WORKS.CONFLICT_NUMBER_TOOLTIP`)}: ${conflictsCount}`;
        }
        const durationFormatted = DateTimeUtils.getDurationHoursFormat(duration);

        const cwrSource: string[] = (tags && get(tags, 'cwrSource', [])) || [];
        const label = last(cwrSource) || '';
        const cwrSourceLabel = label?.length > 10 ? `${label?.substr(0, 10)}...` : label;
        const cwrSourceTooltip = cwrSource.join('<br>') || '';
        const isCwr = tags && get(tags, 'isCwr', []);
        const intExt = external ? RELATION_ORIGIN.EXTERNAL : RELATION_ORIGIN.INTERNAL;
        let relation;
        if (external && detail) {
          relation = (item.relations || []).find(rel => rel.otherId === detail?.id || rel.otherId === detail?.attributes?.key);
        }
        const relationTypeValue = relationType !== '' ? relationType : relation?.relation || '';

        const originTooltip = origin ? translate?.instant(`WORKS.TYPE_ORIGIN.${origin}`) : '';
        const purposeTooltip = purpose ? translate?.instant(`WORKS.PURPOSES.${purpose}`) : '';

        return <WorkCleaned>{
          id,
          attributes,
          rowClass,
          tooltipTitles,
          titlesTotal,
          tooltipCreators,
          activityTriggersCount,
          activityIcon,
          iconConflict,
          iconConflictTooltip,
          conflict: conflictsCount,
          purpose,
          purposeTooltip,
          origin,
          originTooltip,
          wsm,
          tooltipWsm,
          exportWSM,
          creators,
          claims,
          genre,
          publicationDate,
          source,
          owner,
          status,
          duration,
          durationFormatted,
          language,
          migratedFrom: migratedFromFormatted,
          title: titleItem ? titleItem.title : '',
          title_type: titleItem ? titleItem.type : '',
          tooltipTitlesType,
          namesToString,
          roleCodesToString,
          roleCodesToStringTooltip,
          IPINumbersToString,
          key,
          ns: attributes?.ns || CopyrightUtils.getKeyPrefix(id),
          keyWithoutPrefix: (CopyrightUtils.getKeySuffix(id) || '').replace(/\b0+/g, ''),
          dataType: 'work',
          htmlText,
          relationType: relationTypeValue,
          rawItem,
          intExt,
          relationTypeDisplay: relationTypeDisplay || WorkUtils.getWorkRelationDisplayType(relationTypeValue, intExt, translate),
          relations,
          counterclaims: counterclaimText,
          cwrSource,
          cwrSourceLabel,
          cwrSourceTooltip,
          isCwr,
          creationDate,
        };
      }
      return <WorkCleaned>{
        id,
        genre: '',
        publicationDate: '',
        source: '',
        owner: null,
        status: '',
        duration: null,
        category: '',
        language: '',
        title: '',
        title_type: '',
        namesToString: '',
        roleCodesToString: '',
        IPINumbersToString: '',
        key: '',
        keyWithoutPrefix: '',
        dataType: 'work',
        htmlText: '',
        counterclaims: [],
      };
    });
  }

  static createTitleTooltip(items: any[][], keys: any[]): string {
    let html = '<table><tbody>';
    items.forEach((list, index) => {
      html += `<tr><td>${keys[index]}</td><td>`;
      list.forEach(element => {
        const title = element && element.national ? element.national : element.titleValue;
        html += `${title}<br>`;
      });
      html += '</td></tr>';
    });
    html += '</tbody></table>';
    return html;
  }

  static createWorkTitlesField(attributesTitles, currentRowClass) {
    let tooltipTitles;
    let rowClass;
    let titlesTotal;
    const titlesKeys = Object.keys(attributesTitles);
    const titles = titlesKeys.map(keyTitle => attributesTitles[keyTitle]);
    const countTitles = flatten(titles).length;
    if (countTitles > 1) {
      titlesTotal = countTitles > 99 ? '99+' : countTitles;
    }
    if (titles.length > 0) {
      rowClass = `${currentRowClass} ice-bold-title-row`;
      tooltipTitles = this.createBadgeTitleTooltip(titles, titlesKeys);
    } else {
      tooltipTitles = attributesTitles[TitleTypes.original][0].titleValue;
    }
    return { tooltipTitles, rowClass, titlesTotal };
  }

  static createBadgeTitleTooltip(items: any[][], keys: any[]): string {
    // https://ice-cube.atlassian.net/browse/CUBE-13427
    const itemsWithType = [];
    let itemsAdded = 0;
    items.forEach((list, index) => {
      list.forEach(itemList => {
        if (itemsAdded < 100) {
          itemsWithType.push({ ...itemList, type: keys[index] });
        }
        itemsAdded++;
      });
    });
    let html = '<table class="titles-tooltip-table"><tbody>';
    const limit100Items = groupBy(itemsWithType, 'type');
    let isFirstType = true;
    Object.keys(limit100Items).forEach(keyName => {
      html += `<tr><td ${!isFirstType && 'class="title-type"'}>${keyName}</td></tr>`;
      if (isFirstType) {
        isFirstType = false;
      }
      let left = true;
      limit100Items[keyName].forEach(item => {
        let title = item?.national || item.titleValue;
        if (title.length > 90) {
          title = `${title.substring(0, 90)}...`;
        }
        if (left) {
          html += `<tr><td>${title}</td>`;
        } else {
          html += `<td>${title}</td></tr>`;
        }
        left = !left;
      });
    });

    html += '</tbody></table>';
    return html;
  }

  static createSocietyTooltip(items: any[]): string {
    let html = '<table><tbody>';
    items.forEach(item => {
      if (item && item.code && item.name) {
        html += `<tr><td>${item.code}</td><td>${item.name}</td></tr>`;
      }
    });
    html += '</tbody></table>';
    return html;
  }

  static createTableCreatorsTooltip(items: any[], translate): string {
    let html = '<table><tbody><thead></thead><tbody>';
    items
      .filter(item => !!item)
      .forEach((item, index) => {
        html += '<tr>';
        html += `<td>${item['contributorPartyNameId']}</td>`;
        html += `<td>${item['role']}</td>`;
        html += `<td>${item['name']}</td>`;
        html += '</tr>';
      });
    html += '</tbody></table>';
    return html;
  }

  static buildInternalWorkItemsFromRelations(items: any[], oldDetail: any, translate: TranslateService): any[] {
    const intExt = RELATION_ORIGIN.INTERNAL;
    return flatten(
      items.map(item => {
        const { id, attributes, contributions, relations, claims, counterclaims } = item;
        const key = WorkUtils.selectWorkKey(relations, id);
        if (attributes) {
          const { source, owner, status, duration } = attributes;
          const titleItem = WorkUtils.selectTitle(attributes.titles);
          const relationCounterClaims = _.isEmpty(counterclaims) ? 0 : counterclaims.length;
          const relation = (oldDetail?.relations || []).find(rel => (rel.otherId === id || rel.otherId === key) && ['SMPL', 'MOD', 'EXCP'].includes(rel.relation));

          return [
            {
              id,
              attributes,
              keyWithoutPrefix: CopyrightUtils.getKeySuffix(id).replace(/\b0+/g, ''),
              title: titleItem ? titleItem.title : '',
              relationTypeDisplay: relation && WorkUtils.getWorkRelationDisplayType(relation.relation, intExt, translate),
              relationType: relation && relation.relation,
              claims,
              intExt,
              namesToString: ContributorsUtils.concatContributionsPartyNames(contributions),
              roleCodesToString: ContributorsUtils.concatContributionsRolesCodes(contributions),
              IPINumbersToString: ContributorsUtils.concatContributionsPartyNamesIPINumbers(contributions),
              source,
              owner,
              status,
              duration,
              title_type: '',
              key: '',
              relations,
              counterclaims: relationCounterClaims,
            },
          ];
        }
      }),
    );
  }

  static getWorkRelationDisplayType(relation: string, intExt: string, translate: TranslateService): string {
    // Folowing this logic: https://ice-cube.atlassian.net/browse/CUBE-10977?focusedCommentId=61801
    if (translate) {
      if (intExt === RELATION_ORIGIN.INTERNAL) {
        switch (relation) {
          case 'SMPL':
            return translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.SAMPLES');
          case 'MOD':
            return translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.IS_MODIFIED_BY');
          case 'EXCP':
            return translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.IS_EXCERPTED_BY');
        }
      } else if (intExt === RELATION_ORIGIN.EXTERNAL) {
        switch (relation) {
          case 'SMPL':
            return translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.IS_SAMPLED_BY');
          case 'MOD':
            return translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.MODIFIES');
          case 'EXCP':
            return translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.EXCERPTS');
        }
      }
    }
    return 'type';
  }

  static getPublishersFromWorkDetail(detail: WorkDetail): SelectEditorDatatableRow[] {
    if (detail && detail.claims) {
      return uniqWith(
        detail.claims
          .filter(publisher => publisher.role === 'SE' || publisher.role === 'E')
          .map(publisher => {
            const name = get(publisher, 'claimant.partyName.attributes.name', '');
            const publisherRelations = get(publisher, 'claimant.partyName.relations', []);
            // if we need get ipiNameKey perform indexOf('ICE') instead 'IPI'
            const ipiNameNumber = publisherRelations.find(relation => relation.otherId.indexOf('IPI') === 0 && relation.relation === 'XREF');
            return { value: name, key: ipiNameNumber && CopyrightUtils.getKeySuffix(ipiNameNumber.otherId), type: 'publisher' };
          }),
        isEqual,
      ).filter(publisher => publisher.key && publisher.key !== '');
    }
    return [];
  }

  static getPublishersICEFromWorkDetail(detail: WorkDetail): SelectEditorDatatableRow[] {
    if (detail && detail.claims) {
      return uniqWith(
        detail.claims
          .filter(publisher => publisher.role === 'SE' || publisher.role === 'E')
          .map(publisher => {
            const name = get(publisher, 'claimant.partyName.attributes.name', '');
            const publisherRelations = get(publisher, 'claimant.partyName.relations', []);
            // if we need get ipiNameKey perform indexOf('ICE') instead 'IPI'
            const iceKey = publisherRelations.find(relation => relation.otherId.indexOf('ICE') === 0 && relation.relation === 'XREF');
            const ipiNameNumber = publisherRelations.find(relation => relation.otherId.indexOf('IPI') === 0 && relation.relation === 'XREF');
            return {
              value: name,
              key: iceKey && CopyrightUtils.getKeySuffix(iceKey.otherId),
              ipiNameNumber: ipiNameNumber && CopyrightUtils.getKeySuffix(ipiNameNumber.otherId),
              type: 'publisher',
            };
          }),
        isEqual,
      ).filter(publisher => publisher.key && publisher.key !== '');
    }
    return [];
  }

  static getPublishersAndCreatorICEFromWorkDetail(detail: WorkDetail): SelectEditorDatatableRow[] {
    if (detail && detail.claims) {
      return uniqWith(
        detail.claims
          .filter(publisher => publisher.role === 'E')
          .map(publisherAndCreator => {
            const name = get(publisherAndCreator, 'claimant.partyName.attributes.name', '');
            const role = get(publisherAndCreator, 'role', '');
            const newClaimId = get(publisherAndCreator, 'claimId', '');
            const publisherRelations = get(publisherAndCreator, 'claimant.partyName.relations', []);
            const iceKey = publisherRelations.find(relation => relation.otherId.indexOf('ICE') === 0 && relation.relation === 'XREF');
            const ipiNameNumber = publisherRelations.find(relation => relation.otherId.indexOf('IPI') === 0 && relation.relation === 'XREF');
            const parent = get(publisherAndCreator, 'parentId', '');
            let parentClaimItem;
            let parentPath;
            if (parent) {
              parentClaimItem = detail.claims.find(parentClaimI => {
                return parentClaimI.claimId === parent;
              });
              parentPath = `${parentClaimItem.role} - ${parentClaimItem.claimant.partyName.attributes.name} -`;
            } else {
              parentClaimItem = {};
              parentPath = '';
            }
            if (publisherAndCreator.role === 'SE' || publisherAndCreator.role === 'E') {
              return {
                value: name,
                role,
                parent: { parentClaimItem, parentPath },
                key: iceKey && CopyrightUtils.getKeySuffix(iceKey.otherId),
                ipiNameNumber: ipiNameNumber && CopyrightUtils.getKeySuffix(ipiNameNumber.otherId),
                type: 'publisher',
                newClaimId,
              };
            } else {
              return {};
            }
          }),
        isEqual,
      ).filter(publisher => publisher.key && publisher.key !== '');
    }
    return null;
  }

  static getCreatorsFromWorkDetail(detail: WorkDetail): SelectEditorDatatableRow[] {
    const creators = detail && detail.manuscriptData;
    return (creators || []).map(creator => ({ value: creator.name, ipiNameNumber: creator['refLabel'], key: creator['key'], type: 'creator' }));
  }

  static getCreatorsOptionsFromWorkDetail(detail: WorkDetail): { value: string; label: string }[] {
    const creators = detail && detail.manuscriptData;
    const allClaim = detail && detail.claims;
    let correctManuscriptData = true;
    const options = (creators || []).map(creator => {
      const { refLabel, key, partyNameId } = creator;

      const claimId = WorkUtils.getClaimIdByKey(key, allClaim);
      correctManuscriptData = (correctManuscriptData && refLabel && refLabel.length && refLabel.length > 0) || (key && key.length && key.length > 0);
      return {
        claimId,
        value: key,
        label: creator['refLabel'] ? `${creator.name} - ${creator['refLabel']} - ${creator.role}` : `${creator.name} - ${creator.role}`,
      };
    });
    if (options.length > 0 && correctManuscriptData) {
      return options;
    }
    return WorkUtils.getCreatorsFromClaims(detail);
  }

  static getCreatorsFromClaims(detail: WorkDetail): { value: string; label: string }[] {
    if (detail && detail['claims'] && detail['claims'].length && detail['claims'].length > 0) {
      const { claims } = detail;
      const filteredCreators = claims
        .filter(claim => CREATORS.includes(claim.role))
        .map(claim => {
          const attributes = get(claim, 'claimant.partyName.attributes');
          const relations = get(claim, 'claimant.partyName.relations');
          const { name, firstName } = attributes;
          const ipi = RelationsUtils.getIPIFromRelations(relations);
          return { value: `${name}, ${firstName} - ${ipi}`, label: `${name}, ${firstName} - ${ipi}` };
        });
      return uniqBy(filteredCreators, c => c['value']);
    }
    return [];
  }

  static getPurposeOptions(translate: TranslateService): IceOptions {
    return [
      { label: '', value: '' },
      { label: translate.instant('WORKS.PURPOSES.AUD'), value: 'AUD' },
      { label: translate.instant('WORKS.PURPOSES.COM'), value: 'COM' },
      { label: translate.instant('WORKS.PURPOSES.FIL'), value: 'FIL' },
      { label: translate.instant('WORKS.PURPOSES.GEN'), value: 'GEN' },
      { label: translate.instant('WORKS.PURPOSES.LIB'), value: 'LIB' },
      { label: translate.instant('WORKS.PURPOSES.MUL'), value: 'MUL' },
      { label: translate.instant('WORKS.PURPOSES.RAD'), value: 'RAD' },
      { label: translate.instant('WORKS.PURPOSES.TEL'), value: 'TEL' },
      { label: translate.instant('WORKS.PURPOSES.THR'), value: 'THR' },
      { label: translate.instant('WORKS.PURPOSES.VID'), value: 'VID' },
      { label: translate.instant('WORKS.PURPOSES.FIB'), value: 'FIB' },
      { label: translate.instant('WORKS.PURPOSES.FLL'), value: 'FLL' },
      { label: translate.instant('WORKS.PURPOSES.FIT'), value: 'FIT' },
      { label: translate.instant('WORKS.PURPOSES.FIV'), value: 'FIV' },
      { label: translate.instant('WORKS.PURPOSES.FIR'), value: 'FIR' },
      { label: translate.instant('WORKS.PURPOSES.TEB'), value: 'TEB' },
      { label: translate.instant('WORKS.PURPOSES.TLL'), value: 'TLL' },
      { label: translate.instant('WORKS.PURPOSES.TET'), value: 'TET' },
      { label: translate.instant('WORKS.PURPOSES.TEV'), value: 'TEV' },
      { label: translate.instant('WORKS.PURPOSES.TER'), value: 'TER' },
    ];
  }

  static getXrefGroupedOptions(translate: TranslateService): OptionsGroup[] {
    return [
      {
        header: 'Types',
        options: WorkUtils.getXrefTypeOptions(translate),
      },
      {
        header: 'Societies',
        options: ipsSocietiesCodes.filter(soc => soc.status === 'member').map(soc => ({ value: `SWREF:${soc.code}`, label: `${soc.name}` })),
      },
    ];
  }

  static getXrefTypeOptions(translate: TranslateService): IceOptions {
    // sorted by the translation values
    return [
      { value: 'ALLTC', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.ALLTC') }, // 'Alliance Tunecode'
      { value: 'DELTC', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.DELTC') }, // 'Alliance Deleted Tunecode'
      { value: 'BSREG', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.BSREG') }, // 'BUMA/STEMRA Registered Title ID'
      { value: 'EJNW', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.EJNW') }, // 'Electronic Joint Notification Work Reference Number'
      { value: 'ICE', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.ICE') }, // 'ICE Work Key'
      { value: 'ISRC', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.ISRC') }, // 'ISRC Reference'
      { value: 'ISWCA', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.ISWCA') }, // 'ISWC Archived'
      { value: 'ISWC', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.ISWC') }, // 'ISWC Number'
      { value: 'ALLIB', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.ALLIB') }, // 'Library Production Number'
      { value: 'NDREF', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.NDREF') }, // 'Norddoc Workkey'
      { value: 'ALLAW', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.ALLAW') }, // 'PRS Active Work Number'
      { value: 'ALLRW', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.ALLRW') }, // 'PRS Registered Work Number'
      { value: 'SIWID', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.SIWID') }, // 'SABAM - SIWID'
      { value: 'PAPID', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.PAPID') }, // 'Society Paper Id'
      { value: 'RECID', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.RECID') }, // 'Submitter Recording Identifier'
      { value: 'SWWRR', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.SWWRR') }, // 'STIM Web Work Registration Reference'
      { value: 'WIDNO', label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF_TYPES.WIDNO') }, // 'WIDB Number'
    ];
  }

  static getAlternativeTitlesOptions(translate: TranslateService): IceOptions<keyof typeof TitleTypes> {
    return [
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternative}`), value: 'alternative' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeET}`), value: 'alternativeET' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeFT}`), value: 'alternativeFT' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeIT}`), value: 'alternativeIT' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.original}`), value: 'original' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativePT}`), value: 'alternativePT' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeRT}`), value: 'alternativeRT' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeTE}`), value: 'alternativeTE' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeTT}`), value: 'alternativeTT' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeDI}`), value: 'alternativeDI' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeDT}`), value: 'alternativeDT' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeLT}`), value: 'alternativeLT' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeOV}`), value: 'alternativeOV' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeFA}`), value: 'alternativeFA' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeNT}`), value: 'alternativeNT' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeCT}`), value: 'alternativeCT' },
      { label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${TitleTypes.alternativeVT}`), value: 'alternativeVT' },
    ];
  }
  static getAlternativeTitlesConstantOptions(translate: TranslateService): IceOptions {
    return Object.values(TitleTypes).map(type => ({
      label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${type}`),
      value: type,
    }));
  }

  static getTitleTypeFromLabel(translate: TranslateService, label: string): string {
    const foundType = WorkUtils.getAlternativeTitlesOptions(translate).find(element => {
      return element.label === label;
    });
    return foundType.value;
  }

  static getLyricAdaptationOptions(translate: TranslateService): IceOptions {
    return [
      { label: '', value: '-' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.LYRIC_ADAPTION.ADDITION'), value: 'ADL' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.LYRIC_ADAPTION.MODIFICATION'), value: 'MOD' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.LYRIC_ADAPTION.NEW'), value: 'NEW' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.LYRIC_ADAPTION.ORIGINAL'), value: 'ORI' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.LYRIC_ADAPTION.REPLACEMENT'), value: 'REP' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.LYRIC_ADAPTION.TRANSLATION'), value: 'TRA' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.LYRIC_ADAPTION.UNSPECIFIED'), value: 'UNS' },
    ];
  }

  static getMusicArrangementOptions(translate: TranslateService): IceOptions {
    return [
      { label: '', value: '' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.MUSIC_ARRANGEMENT.ADDITION'), value: 'ADM' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.MUSIC_ARRANGEMENT.ARRANGEMENT'), value: 'ARR' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.MUSIC_ARRANGEMENT.NEW'), value: 'NEW' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.MUSIC_ARRANGEMENT.ORIGINAL'), value: 'ORI' },
      { label: translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.MUSIC_ARRANGEMENT.UNSPECIFIED'), value: 'UNS' },
    ];
  }

  static getTitleOfTitles(translate: TranslateService, titles) {
    if (titles && titles.length > 0) {
      let lastIndex = -1;
      let currentTitleType = '';
      let tooltipTitlesType = '';
      return titles.map((title, index) => {
        const { type } = title;
        if (type !== currentTitleType) {
          currentTitleType = type;
          lastIndex = 0;
        } else {
          lastIndex++;
        }
        tooltipTitlesType = translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${title.type}`);
        return {
          label: translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.${title.type}`),
          value: _.escape(title.title),
          index: lastIndex,
          duration: title.duration,
          durationFormatted: DateTimeUtils.getDurationHoursFormat(title.duration),
          type,
          tooltipTitlesType,
        };
      });
    }
    return [];
  }

  static getSocietyMarkers(markers, translate) {
    return ipsSocietiesCodes
      .filter(soc => SOCIETIES.includes(soc.name))
      .map(society => {
        const { code, name } = society;
        const valueParameters = markers.includes(society.code) && { value: 'Yes', key: true, tooltip: `${name}: ${translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.ACTIVE')}` };
        return { code, label: name, ...valueParameters };
      });
  }

  static getCounterClaimsFromWorkConflicts(conflicts: WorksConflict[]): any[] {
    return flatten(
      conflicts.map(itemConflict => {
        return (
          itemConflict &&
          itemConflict.conflict &&
          itemConflict.conflict.claimCases.map(claimCaseItem => {
            const claimCase = claimCaseItem.claimCase;
            const claimCaseAttributes = claimCase.attributes;
            const claimants = claimCase.claimants || [];
            let endDate: string;
            let startDate: string;
            let status: string;
            let rights: string[];
            let territories: string[];
            let type: string[];

            if (claimCaseAttributes) {
              endDate = claimCaseAttributes.endDate;
              startDate = claimCaseAttributes.startDate;
              status = claimCaseAttributes.status;
              rights = claimCaseAttributes.rights;
              territories = claimCaseAttributes.territories;
              type = claimCaseAttributes.type;
            }

            return {
              id: claimCaseItem.claimCaseId,
              conflictId: claimCaseItem.claimCaseId,
              endDate,
              startDate,
              status,
              rights,
              territories,
              type,
              claimants,
            };
          })
        );
      }),
    );
  }

  static getExtendedToP(workDetail: CaseWorkDetail | WorkDetail, path = 'attributes.copyrightOverrides') {
    let workProtections = cloneDeep(get(workDetail, path, []));
    workProtections = WorkUtils.completeTerritoriesData(workProtections);
    if (workDetail.manuscriptData) {
      let ipProtections = [];
      workDetail.manuscriptData.forEach((manuscriptData: CopyrightOwnershipTableItem) => {
        if (manuscriptData.copyrightOverrides) {
          const { role, ipiNameNumber, name, contributorId } = manuscriptData;
          const newIpProtections = manuscriptData.copyrightOverrides.map(override => {
            return { ...override, role, ipiNameNumber, name, contributorId };
          });
          ipProtections = concat(ipProtections, newIpProtections);
        }
      });
      return concat(workProtections, ipProtections);
    }
    return workProtections;
  }

  static removeOverrides(copyrightOverrides, overrideToRemove) {
    return copyrightOverrides.filter(override => {
      return !(
        override.territories.inExTisns.join(',') === overrideToRemove.territories.inExTisns.join(',') &&
        override.societyIds.join(',') === overrideToRemove.societyIds.join(',') &&
        override.protectionDate === overrideToRemove.protectionDate
      );
    });
  }

  static getWorkClauses(workPartyNames, translate): WorkClause[] {
    const workClauses: WorkClause[] = [];
    if (workPartyNames) {
      workPartyNames.forEach(workPartyName => {
        const { clauses, partyName, partyNameId } = workPartyName;
        if (clauses?.length) {
          const { attributes, relations } = partyName;
          const formattedClauses = this.getFormattedClauses(clauses, attributes, relations, 'work', partyNameId, translate);
          workClauses.push(...formattedClauses);
        }
      });
    }
    return workClauses;
  }

  static getFormattedClauses(clauses, attributes, relations, clauseType, partyNameId, translate): WorkClause[] {
    const ipiNumber = relations && WorkUtils.selectIPIKey(relations);
    const ipiName = attributes && IpUtils.getIpFullName(attributes);
    const formattedClauses: WorkClause[] = [];
    clauses.forEach(type => {
      const selectedClause = IP_CLAUSES.find(ipClauseType => ipClauseType === type);
      const clause = {
        partyNameId,
        type,
        ipiNumber: ipiNumber.replace('IPI:', ''),
        ipiName,
        name: (selectedClause && translate.instant(`CLAUSES.${selectedClause}`)) || '???',
        clauseType,
      };
      formattedClauses.push(clause);
    });
    return formattedClauses;
  }

  static getWorkClausesFromWorks(works, partyNameId, translate): IpClause[] {
    const workClauses = [];
    works.forEach(work => {
      const { id, attributes, partyNames, relations } = work;
      const workRef = WorkUtils.selectWorkKey(relations, id);
      const workTitleItem = attributes && attributes.titles && WorkUtils.selectTitle(attributes.titles);
      const workTitle = workTitleItem ? workTitleItem.title : '';
      let tooltipTitles: string = null;
      let rowClass = '';
      let titlesTotal;
      if (attributes.titles) {
        ({ tooltipTitles, rowClass, titlesTotal } = this.createWorkTitlesField(attributes.titles, rowClass));
      }
      partyNames
        .filter(partyName => partyName.partyNameId === partyNameId)
        .forEach(partyName => {
          const { clauses } = partyName;
          if (clauses) {
            clauses.forEach(clause => {
              const selectedClause = IP_CLAUSES.find(clauseType => clauseType === clause);
              const name = (selectedClause && translate.instant(`CLAUSES.${selectedClause}`)) || '???';
              workClauses.push({ type: clause, workRef: workRef.replace('ICE:', ''), workTitle, name, clauseType: 'work', tooltipTitles, rowClass, titlesTotal });
            });
          }
        });
    });
    return uniqWith(workClauses, isEqual);
  }

  static cleanWorkForClone(detail: WorkDetail) {
    const { attributes, claims } = detail;
    const { duration, purpose, titles, source } = attributes;
    const { titleValue } = get(titles, 'OT[0]', {});
    const durationFormatted = DateTimeUtils.getDurationHoursFormat(duration);
    const xrefs = [];

    return {
      originalTitle: titleValue,
      duration: durationFormatted,
      purpose,
      source,
      xrefs,
      cloneClaims: claims,
    };
  }

  static formatOverrideToSend(override: CopyrightOverride): SendWorkCopyrightOverride {
    const { protectionDate, territories, societyIds } = override;
    const { inExTisns, tisDate } = territories;
    const formattedTerritories = territories && TerritoryUtils.convertTerritoryArrayElements(inExTisns, TerritoryDataType.TISN);
    return { protectionDate, territories: { tisDate, inExTisns: formattedTerritories }, societyIds };
  }

  static createWorkItemToEdit(item: WorkDetail, ns: string) {
    if (item) {
      const { attributes, contributions, relations, claims, partyNames, workInternalRelations, workExternalRelations } = cloneDeep(item);
      const id: string = attributes?.key;
      const attributesOverrides = attributes && attributes.copyrightOverrides && attributes.copyrightOverrides.map(override => this.formatOverrideToSend(override));
      const newAttributes = { ...(attributes || {}), copyrightOverrides: attributesOverrides, ns, id };
      newAttributes.tags = newAttributes.tags ? { ...newAttributes.tags, ...WORK_TAGS_SUBMITTED } : WORK_TAGS;
      const newContributions: SendWorkContribution[] =
        contributions &&
        contributions.map(contributor => ({
          ...contributor,
          copyrightOverrides: contributor && contributor.copyrightOverrides && contributor.copyrightOverrides.map(override => this.formatOverrideToSend(override)),
        }));
      const cleanedClaims = (claims || []).map(claim => {
        if (claim) {
          if (claim.ownershipShares && claim.ownershipShares.length) {
            claim.ownershipShares.forEach(ClaimsUtils.trimRightShares);
          }
          if (claim.claimShares && claim.claimShares.length) {
            claim.claimShares.forEach(ClaimsUtils.trimRightShares);
          }
          return ClaimsUtils.cleanClaimEmptyDates(claim);
        }
        const { claimant, auxFields, ...cleanedClaim } = claim;
        const claimantId = claim.claimantPartyNameId && claim.claimantPartyId ? `${claim.claimantPartyNameId}_${claim.claimantPartyId}` : null;
        return { ...cleanedClaim, claimantId };
      });
      return <SendWork>{
        id,
        attributes: newAttributes,
        contributions: newContributions,
        societies: item.societies,
        relations,
        partyNames,
        workExternalRelations,
        workInternalRelations,
        claims: cleanedClaims,
        recordings: item.recordings,
      };
    }
    return {};
  }

  static createWorkItemToRegister(item: any, ns: string) {
    const { duration, originalTitle, purpose, titles, xrefs, source } = item;
    const isComplexNs = ns.includes(':');
    const id = `${ns}:W-${DateTimeUtils.formatDate(moment(), 'YYYYMMDDhhmmss').slice(5)}`; // Review
    const relations = xrefs.map(xref => ({ relation: 'XREF', otherId: `${xref.type}:${xref.value}` }));
    const durationInSeconds = (duration && DateTimeUtils.setDurationToSeconds(duration)) || null;
    const formattedTitles = { OT: [{ duration: durationInSeconds || 0, sequenceNumber: 1, titleValue: originalTitle.toUpperCase(), national: originalTitle.toUpperCase() }] };
    titles.forEach(title => {
      const oldTitleGroup = formattedTitles[TitleTypes[title.type]] || [];
      formattedTitles[TitleTypes[title.type]] = [
        ...oldTitleGroup,
        {
          duration: (title.duration && DateTimeUtils.setDurationToSeconds(title.duration)) || null,
          sequenceNumber: 1,
          national: (title.name || '').toUpperCase(),
        },
      ];
    });

    const formattedClaims = ClaimsUtils.workRegisterFormatClaimsToSend(id, ns, item);
    const hasArrangerOrAdapter = formattedClaims.find(claim => ARRANGERS_AND_ADAPTER_ROLES.includes(claim.role));
    const arragerFieldsDefaultValue = hasArrangerOrAdapter ? 'UNS' : undefined;

    let itemToSend = {
      id,
      attributes: {
        titles: formattedTitles,
        purpose,
        ns,
        genre: 'POP',
        origin: hasArrangerOrAdapter ? 'MOD' : 'ORI',
        musicRecordedIndicator: 'N',
        duration: durationInSeconds,
        source,
        musicArrangement: arragerFieldsDefaultValue,
        lyricAdaption: arragerFieldsDefaultValue,
        ...WORK_TAGS,
      },
      claims: formattedClaims,
      contributions: [],
      relations,
      version: 1,
    };
    if (isComplexNs) {
      itemToSend = omit(itemToSend, 'id');
      itemToSend.attributes = omit(get(itemToSend, 'attributes'), 'ns');
    }
    return itemToSend;
  }

  static getSourceWorksQuery(relations) {
    return {
      or: relations.map(relation => ({ equals: { id: relation } })),
    };
  }

  static completeTerritoriesData(workProtections: any[]): any[] {
    return workProtections.map(workProtection => {
      workProtection.territories.territoriesTisa = TerritoryUtils.convertTerritoryArrayElements(workProtection.territories.inExTisns, TerritoryDataType.TISA);
      workProtection.territoriesTooltip = TerritoryUtils.getTerritoriesNamesTooltipText(
        TerritoryUtils.convertTerritoryArrayElements(workProtection.territories.inExTisns, TerritoryDataType.NAME),
      );
      return workProtection;
    });
  }

  static getWSM(detail: WorkDetail | WorkSearchItemExtended) {
    if (detail && detail.attributes && detail.attributes.societyMarkers) {
      const societyMarkers = detail.attributes.societyMarkers;
      return WorkUtils.getWSMResult(societyMarkers);
    }
    return null;
  }

  static getWSMResult(societyMarkers: string[]) {
    if (societyMarkers) {
      const correctSocietyMarkersLength = ipsSocietiesCodes.filter(soc => SOCIETIES.includes(soc.name) && societyMarkers.includes(soc.code)).length;
      return correctSocietyMarkersLength === 0 ? 'NO_WSM' : correctSocietyMarkersLength === SOCIETIES.length ? 'ALL_SM' : 'AT_LEAST_ONE_SM';
    }
    return null;
  }

  static checkPreviousTitleExist(titles: WorkTitles, titleType: string, titleToAdd: any) {
    const typeTitles = titles[TitleTypes[titleType]];
    if (typeTitles) {
      return typeTitles.find(title => title.titleValue === titleToAdd.titleValue);
    }
  }

  static getClaimIdByKey(key, allClaim) {
    const claimId = allClaim.find(claim => {
      const relationClaim = get(claim, 'claimant.partyName.relations', []);
      const otherId = relationClaim.find(relation => relation.otherId.indexOf('ICE') === 0 && relation.relation === 'XREF').otherId;
      return otherId === key;
    });
    return claimId?.claimId;
  }

  static getMusicRelationshipLabelTooltip(musicRelationTosearch, translate: TranslateService) {
    if (musicRelationTosearch) {
      const value = WORK_MUSIC_RELATIONSHIP_OPTIONS.find(musicRelation => musicRelation.value === musicRelationTosearch)?.value || '';
      return value ? translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.WORK_MUSIC_RELATIONSHIP_OPTIONS.${value}`) : '';
    }
  }

  static getWorkCategoryLabelTooltip(categoryTosearch, translate: TranslateService) {
    if (categoryTosearch) {
      const value = WORK_CATEGORY_OPTIONS.find(category => category.value === categoryTosearch)?.value || '';
      return value ? translate.instant(`WORKS.DETAILS.CARD_WITH_FORM.FORM.CATEGORY_OPTIONS.${value}`) : '';
    }
  }

  static getOtherPartyTableCommonFields(translate): DataTableRow[] {
    return [
      {
        name: translate.instant('WORKS.OTHER_PARTIES.TABLE.TYPE_OF_NAME'),
        prop: 'role',
        flexGrow: 1,
        cellClass: 'ice-txt-size-14',
        tooltip: 'roleLabel',
      },
      {
        name: translate.instant('WORKS.OTHER_PARTIES.TABLE.NAME'),
        prop: 'fullName',
        flexGrow: 3,
        cellClass: 'ice-txt-size-14',
      },
      {
        name: translate.instant('WORKS.OTHER_PARTIES.TABLE.IPI_NAME_NUMBER'),
        prop: 'ipiNameNumber',
        flexGrow: 3,
        cellClass: 'ice-txt-size-14',
      },
    ];
  }

  static updateForm(form: FormGroup, value: any) {
    form.patchValue(value);
    form.markAllAsTouched();
    form.markAsDirty();
    form.updateValueAndValidity();
  }

  static trimCubeId(CubeId: String) {
    return CubeId.replace(/^CUBE:0+/, '');
  }
  static trimICEId(CubeId: String) {
    return CubeId.replace(/^ICE:/, '');
  }

  /**
   * Removes namespaces from a given work ID.
   * Namespaces can exist in before and after the colon, and can have leading zeros.
   * When it comes to identification, all those are not relevant.
   *
   * @param id - The work ID with namespaces.
   * @returns The work ID without namespaces.
   */
  static workIdWithoutNamespaces(id: string) {
    return id.replace(/([A-Z0-9]+:)+[A-Z]*0*/, '');
  }

  static cleanWorkDataTableLabels(data: CopyrightOwnershipTableItem[]): any[] {
    return (data || []).map((item: any) => {
      item = this.cleanIconsForSpecialRows(item);
      return { ...item, name: IpUtils.cleanNameLabel(item?.name, item?.typeOf) };
    });
  }

  static cleanIconsForSpecialRows(item: any) {
    if (item?.role?.includes(ROLE_SPECIAL)) {
      item = this.removeInvalidSpecialIcons(item);
    }
    return item;
  }

  static removeInvalidSpecialIcons(item: any): any {
    if (item.alertIcon?.length) {
      item.alertIcon = filter(item.alertIcon, icon => !INVALID_SPECIAL_ICONS.includes(lowerCase(icon.text)));
    }
    return item;
  }

  static formatWorkActivityTriggers(translate: TranslateService, activityTriggers: ActivityTrigger[]) {
    return (activityTriggers || []).map(activityTrigger => {
      const uploadCategory = get(activityTrigger, 'attributes.uploadCat');
      const reportingSociety = get(activityTrigger, 'attributes.soc');
      const processLabel = get(activityTrigger, 'attributes.processDescription', '');
      return {
        ...activityTrigger.attributes,
        uploadCategoryLabel: `${uploadCategory} - ${CopyrightUtils.getTranslation(translate, 'ACTIVITY.UPLOAD_CATEGORY_TYPE.' + get(activityTrigger, 'attributes.uploadCat'))}`,
        reportingSocietyLabel: `${reportingSociety} - ${SocietiesUtils.searchSocietyNameById(reportingSociety)}`,
        processLabel,
      };
    });
  }

  static concatCreators(claimsOptions: SelectOption[], contributionsOptions: SelectOption[]): SelectOption[] {
    forEach(contributionsOptions, contribution => {
      const simpleValue = get(contribution, 'label', '');
      remove(claimsOptions, item => includes(item.label, simpleValue.substring(0, simpleValue.lastIndexOf('-'))?.trim()));
    });
    return concat(contributionsOptions, claimsOptions);
  }

  static removeLeadingZeros(workId: string) {
    const id = workId.split(':')[1];
    return `${Number(id)}`;
  }
  static cleanXrefPrefix(items: any): IceOptions {
    return items.map(item => {
      return {
        value: item.attributes.publisher.prefix,
        label: `${item.attributes.publisher.prefix} - ${item.attributes.name}`,
      };
    });
  }
  static getXrefPublisherReference({
    field,
    store,
    translate,
    callback,
  }: {
    field: FormlyFieldConfig;
    store: Store;
    translate: TranslateService;
    callback?: ({
      backendXrefList,
      frontXrefList,
      backendOption,
      frontOption,
    }: {
      backendXrefList: IceOptions;
      frontXrefList: OptionsGroup[];
      backendOption: IceOption;
      frontOption: IceOption;
    }) => void;
  }) {
    if (_.isObject(field.model?.type)) {
      return;
    }
    const optionToSearch = field.model?.type?.includes(':') ? field.model?.type.split(':')[1] : field.model?.type;
    store.dispatch(
      new StartApiCall({
        apiCall: searchPartyName,
        apiCallData: {
          body: `{
            "and": [
              {
                "exists": "attributes.publisher.prefix"
              },
              {
                "equals": {"attributes.publisher.prefix":"${optionToSearch}"}
              }
            ]
          }`,
        },
        callBack: (response, error) => {
          if (!error) {
            const backendXrefList = WorkUtils.cleanXrefPrefix(response.items);
            const frontXrefList = WorkUtils.getXrefGroupedOptions(translate);
            const backendOption = backendXrefList?.find(option => option.value.includes(optionToSearch));
            const frontOption = [...frontXrefList[0]?.options, ...frontXrefList[1]?.options].find(option => option.value.includes(optionToSearch));
            callback?.({ backendXrefList, frontXrefList, backendOption, frontOption });
          }
        },
      }),
    );
  }
}
export const generateTrimClaimsChainsOptions = ({ work }: { work: WorkDetail }) => {
  const { claims } = work;
  if (!claims) {
    return [];
  }
  const claimsById = _.keyBy(claims, 'claimId');
  const claimGroups = _.groupBy(claims, 'parentId');
  const options: IceOptions<string, { parentId?: string }> = [];
  claims.forEach(claim => {
    const { parentId } = claim;
    const isParent = !parentId;
    const isCreator = CREATOR_ROLES_LIST.includes(claim.role);
    const isPublisher = claim.role === 'E';
    /**
     * Only Creators and Publishers can have children
     */
    const hasChildren = !!claimGroups[claim.claimId]?.length;
    const { firstName, name } = claim.claimant.partyName.attributes;
    const claimantName = firstName ? `${firstName}, ${name}` : name;

    /**
     * A Creator without children is an income participant by itself (similar to a publisher).
     * Creators with children will be added to the options from the Publisher(s).
     */
    if (isCreator && !hasChildren) {
      options.push({ value: claim.claimId, label: `${claim.role} - ${claimantName}` });
    }
    if (isPublisher) {
      // a parent Publisher is not a child of any Creator, so it deserves its own `option`
      if (isParent) {
        options.push({ value: claim.claimId, label: `${claim.role} - ${claimantName}` });
      } else {
        const parentCreatorClaim = claimsById[parentId];
        const { firstName: parentFirstName, name: parentName } = parentCreatorClaim.claimant.partyName.attributes;
        options.push({ value: claim.claimId, label: `${parentCreatorClaim.role} - ${parentFirstName}, ${parentName} → ${claim.role} - ${claimantName}`, metadata: { parentId } });
      }
    }
  });
  return _.sortBy(options, 'label');
};

/**
 * Generates a Dictionary with the claims related to a chain (tree), keyed by the claimId.
 * This list is useful when we want to iterate over all the claims related to a chain.
 *
 * Given a Creator C1 with 3 Publishers P1, P2 and P3 with 2 Sub-Publishers SP1, SP2, it would return:
 * ```ts
 * {
 *   [C1.claimId]: [ C1 P1, P2, P3, SP1, SP2 ],
 *   [P1.claimId]: [ P1 ],
 *   [P2.claimId]: [ P2 ],
 *   [P3.claimId]: [ P3, SP1, SP2 ],
 * }
 * ```
 */
export const generateClaimCollectionByClaimId = ({ work }: { work: WorkDetail }) => {
  const { claims } = work;
  /**
   * As in a chain a Creator can have Publishers as children, and a Publisher can have Sub-Publishers as children,
   * We will get a list of claims grouped by their parentId similar to:
   * ```ts
   * {
   *   creator1: [ publisher1, publisher2 ...],
   *   publisher1: [ sub-publisher1, sub-publisher2 ...],
   *   creator2: [ publisher1, publisher3 ...],
   *   ...
   *   undefined: [ creator7, publisher8, ...], // creators and publishers with no parentId, income participants
   * }
   * ```
   */
  const parentGroups = _.groupBy(claims, 'parentId');
  const claimCollectionByClaimId: Record<string, ClaimRow[]> = {};
  // we want to include to the publisher all the sub-publishers, and to the creator all the publishers
  claims.forEach(claim => {
    const { claimId } = claim;
    if (!claimCollectionByClaimId[claimId]) {
      claimCollectionByClaimId[claimId] = [claim];
    }
    /**
     * Only Creators and Publishers can have children
     */
    const hasChildren = !!parentGroups[claim.claimId]?.length;
    if (hasChildren) {
      claimCollectionByClaimId[claimId] = _.union(claimCollectionByClaimId[claimId], parentGroups[claim.claimId]);
      const isPublisher = claim.role === 'E';
      if (isPublisher && claim.parentId) {
        claimCollectionByClaimId[claim.parentId] = _.union(claimCollectionByClaimId[claim.parentId] || [], claimCollectionByClaimId[claimId]);
      }
    }
  });

  return claimCollectionByClaimId;
};
