import { TerritoryUtils, WorkUtils } from '@ice';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { first, flatMap, get } from 'lodash';
import { CounterClaimSearchItem } from 'models/copyright/search/conflicts-counterclaim-search';

export class SearchCounterClaimsParser {
  static parse(items: any[], translate: TranslateService, extraParams?: Object, extraItems?: string[], store?: Store<any>): CounterClaimSearchItem[] {
    return items.map(item => {
      const { status, createdDate, resolutionOwners, pointOfConflict, type, counterClaimTerritories } = item && item.attributes;
      const firstAction: any = first((item && item.actions) || []);
      const titleItem = WorkUtils.selectTitle(get(item, `work.attributes.titles`));
      const territories: string[] = flatMap((counterClaimTerritories || []).map(territory => territory.inExTisns));
      let workId;
      if (item.work && item.work.relations) {
        workId = WorkUtils.selectWorkKey(item.work.relations, item.attributes.workId);
        workId = workId.split(':')[1];
      }
      const id = item.id;
      const idFormatted = id.split(':')[1];
      let actionOwner = get(firstAction, `attributes.owner`, '');
      actionOwner = actionOwner && actionOwner.split(':')[1];
      const actionResponse = get(firstAction, `attributes.response`, '');
      const actionType = get(firstAction, `attributes.type`, '');
      const actionStatus = get(firstAction, `attributes.status`, '');
      return {
        id,
        idFormatted,
        createdDate,
        pointOfConflict: pointOfConflict && translate.instant(`COUNTER.${pointOfConflict}`),
        territories,
        territoriesLabel: TerritoryUtils.convertTerritoryArrayElements(territories, TerritoryDataType.TISA),
        territoriesTooltip: TerritoryUtils.getTerritoriesNamesTooltipText(TerritoryUtils.convertTerritoryArrayElements(territories, TerritoryDataType.NAME)),
        type: type && translate.instant(`COUNTER.${type}`),
        workId,
        workTitle: titleItem ? titleItem.title : '',
        resolutionOwner: (resolutionOwners || []).filter(owner => !!owner).join(', '),
        status: status && translate.instant(`COUNTER.${status}`),
        actionStatus: actionStatus && translate.instant(`COUNTER.${actionStatus}`),
        actionType: actionType && translate.instant(`ACTIVITY.ACTION_TYPES.${actionType}`),
        actionAssignor: get(firstAction, `attributes.assignor`, ''),
        actionResolver: get(firstAction, `attributes.resolver`, ''),
        actionOwner,
        actionResponse: actionResponse && translate.instant(`ACTIVITY.ACTION_RESPONSES.${actionResponse}`),
        actionDeadline: get(firstAction, `attributes.deadline`, ''),
      };
    });
  }
}
