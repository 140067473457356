import * as fromNewSectionItemActions from 'store/form/actions';
import * as fromActions from 'store/root/actions';

export type hideLoadingTypes =
  | fromActions.FetchSearchPageFail
  | fromActions.FetchSearchPageSuccess
  | fromActions.GetItemFail
  | fromActions.GetAccessFail
  | fromActions.IceError
  | fromActions.AuthError
  | fromActions.GetItemNotFound
  | fromActions.GetItemSuccess
  | fromActions.GetSharePictureFail
  | fromActions.GetSharePictureSuccess
  | fromActions.ApiCallError
  | fromActions.ApiCallSuccess
  | fromActions.CancelRequest
  | fromActions.CancelApiCall
  | fromActions.UpdateIpsRelationsFail
  | fromActions.SaveSectionItemSuccess
  | fromActions.SaveSectionItemFail
  | fromActions.SendRejectDocumentsFail
  | fromActions.SendRejectDocumentsSuccess
  | fromNewSectionItemActions.SaveNewSectionItemFail
  | fromNewSectionItemActions.NewItemDoSearchSuccess
  | fromNewSectionItemActions.NewItemDoSearchFail
  | fromNewSectionItemActions.SaveNewSectionItemSuccess
  | fromActions.PauseExportMode
  | fromActions.CancelDownload
  | fromActions.UpdateConflictsSuccess
  | fromActions.UpdateIpsRelationsFail
  | fromActions.AuditHistoryFilterSuccess
  | fromActions.AuditHistoryFilterFail
  | fromActions.WorkResolutionSuccess
  | fromActions.WorkResolutionFail
  | fromNewSectionItemActions.SaveDocumentSuccess
  | fromNewSectionItemActions.SaveDocumentFail
  | fromActions.GetItemFullDataEnd
  | fromActions.ClearSharePicture
  | fromActions.GetAllTerritoriesFail;
export const hideLoadingActions = [
  fromActions.FETCH_SEARCH_PAGE_FAIL,
  fromActions.FETCH_SEARCH_PAGE_SUCCESS,
  fromActions.EXPORT_DATA_SUCCESS,
  fromActions.CANCEL_DOWNLOAD,
  fromActions.CANCEL_API_CALL,
  fromActions.GET_ITEM_FAIL,
  fromActions.GET_ACCESS_FAIL,
  fromActions.GET_ITEM_NOT_FOUND,
  fromActions.GET_ITEM_SUCCESS,
  fromActions.GET_DETAIL_PAGE_SUCCESS,
  fromActions.GET_SHARE_PICTURE_FAIL,
  fromActions.GET_SHARE_PICTURE_SUCCESS,
  fromActions.CANCEL_REQUEST,
  fromActions.IPS_RELATIONS_SEARCH_SUCCESS,
  fromActions.UPDATE_IPS_RELATIONS_FAIL,
  fromActions.SAVE_SECTION_ITEM_SUCCESS,
  fromActions.SAVE_SECTION_ITEM_FAIL,
  fromActions.SEND_REJECT_DOCUMENTS_FAIL,
  fromActions.SEND_REJECT_DOCUMENTS_SUCCESS,
  fromNewSectionItemActions.NEW_ITEM_DO_SEARCH_SUCCESS,
  fromNewSectionItemActions.NEW_ITEM_DO_SEARCH_FAIL,
  fromNewSectionItemActions.SAVE_NEW_SECTION_ITEM_SUCCESS,
  fromNewSectionItemActions.SAVE_NEW_SECTION_ITEM_FAIL,
  fromActions.UPDATE_CONFLICTS_FAIL,
  fromActions.UPDATE_CONFLICTS_SUCCESS,
  fromActions.UPDATE_IPS_RELATIONS_FAIL,
  fromActions.ICE_ERROR,
  fromActions.AUTH_ERROR,
  fromActions.AUDIT_FILTER_HISTORY_SUCCESS,
  fromActions.AUDIT_FILTER_HISTORY_FAIL,
  fromActions.WORK_RESOLUTION_SUCCESS,
  fromActions.WORK_RESOLUTION_FAIL,
  fromNewSectionItemActions.SAVE_DOCUMENT_SUCCESS,
  fromNewSectionItemActions.SAVE_DOCUMENT_FAIL,
  fromActions.API_CALL_ERROR,
  fromActions.API_CALL_SUCCESS,
  fromActions.GET_ITEM_FULL_DATA_END,
  fromActions.CANCEL_DOWNLOAD,
  fromActions.PAUSE_EXPORT_MODE,
  fromActions.CLEAR_SHARE_PICTURE,
  fromActions.GET_ALL_TERRITORIES_FAIL,
];
