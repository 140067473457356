import { FetchApiCallUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { select, Store } from '@ngrx/store';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ApiCallConfig, isApiCallConfig } from 'config/sections-config/api-call';
import { cloneDeep, isArray, keyBy, mapValues, pull, without } from 'lodash';
import { ExportMode, ExportModeType } from 'models/copyright/search/export-mode';
import { SelectMode } from 'models/copyright/search/select-mode';
import { UpdateMode, UpdateModeType } from 'models/copyright/search/update-mode';
import { combineLatest, isObservable, Observable, of } from 'rxjs';
import { delay, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from 'store/root';

export class SelectionDatatableBuilder {
  private selectMode: SelectMode = null;
  private exportMode: ExportMode = null;
  private updateMode: UpdateMode = null;
  private exportMode$ = this.store.select(fromRoot.getExportMode);
  private selectMode$ = this.store.select(fromRoot.getSelectMode);
  private updateMode$ = this.store.select(fromRoot.getUpdateMode);
  private getSearchResultsExtraTotal$: Observable<number>;
  private columnLabelDictionary: { [id: string]: string };
  private exportColumns: string[];
  private schema: any[];
  private mergeIpMode: boolean;
  private mergeIpMode$ = this.store.select(fromRoot.getMergeIpMode);

  constructor(
    private store: Store<fromRoot.RootState>,
    schema: DataTableRow[],
    private singleSelection?: (items) => void,
    private apiCall?: ApiCallConfig | (() => ApiCallConfig),
    private updatedColumns?: Observable<string[]>,
    private queueChunckSize?: number,
    private customSearchSelector?: Observable<any[]>,
  ) {
    this.setSchema(schema);
    this.getSearchResultsExtraTotal$ = this.customSearchSelector
      ? this.customSearchSelector.pipe(map(rows => rows?.length || 0))
      : this.store.select(fromRoot.getSearchResultsExtraTotal);
  }

  setSchema(schema) {
    this.schema = schema ? [...this.getCheckSchema(), ...schema] : [];
  }

  getSelectionDatatableConfig() {
    return {
      schema: this.schema,
      visibleColumns: this.getVisibleColumns(),
      selected: this.getSelectedRows(),
      selectionType: this.getSelectableMode(),
      isSelectableMode: this.isSelectableMode(),
      onSelect: (event: any[]) => this.onSelect(event),
      onAllSelected: event => this.allSelected(event),
      onReorderColumn: event => this.reorderColumn(event),
      ...(this.apiCall && { apiCall: this.apiCall }),
      displayCheck: row => {
        return true;
      },
    };
  }

  getSchema() {
    return this.schema;
  }

  isSelectableMode(): Observable<boolean> {
    return combineLatest([this.exportMode$, this.selectMode$, this.updateMode$, this.getSearchResultsExtraTotal$, this.mergeIpMode$]).pipe(
      tap(([exportMode, selectMode, updateMode, , mergeIpMode]) => this.setSelectionModes(selectMode, exportMode, updateMode, mergeIpMode)),
      map(([exportMode, selectMode, updateMode, resultsTotals, mergeIpMode]) => resultsTotals > 0 && (!!exportMode || !!updateMode || !!selectMode || !!mergeIpMode)),
    );
  }

  getSelectableMode() {
    return this.isSelectableMode().pipe(map(isSelectableMode => (isSelectableMode ? SelectionType.checkbox : SelectionType.single)));
  }

  getCheckSchema() {
    return [
      {
        name: '',
        prop: 'rowSelected',
        flexGrow: 0.55,
        headerCheckboxable: true,
        checkboxable: true,
        width: 50,
        minWith: 50,
        resizeable: false,
        canAutoResize: false,
        draggable: false,
        sortable: false,
      },
    ];
  }

  getSelectedRows(otherSelection?: Observable<any>): Observable<any[]> {
    return combineLatest([
      this.store.pipe(select(fromRoot.getSearchResultsAllFormatted)),
      this.selectMode$,
      this.exportMode$.pipe(filter(exportMode => !exportMode?.downloadBatch || isArray(isArray(exportMode?.downloadBatch)))),
      this.updateMode$,
    ]).pipe(
      switchMap(([rows, selectMode, exportMode, updateMode]) => {
        if (exportMode) {
          return of([...((isArray(exportMode.downloadBatch) && cloneDeep(exportMode.downloadBatch)) || [])]);
        } else if (updateMode) {
          return of([...(updateMode.items || [])]);
        } else if (selectMode) {
          return of([...(selectMode.selection || [])]);
        }
        return otherSelection || of([]);
      }),
    );
  }

  setSelectionModes(selectMode, exportMode, updateMode, mergeIpMode) {
    this.selectMode = selectMode;
    this.exportMode = exportMode;
    this.updateMode = updateMode;
    this.mergeIpMode = mergeIpMode;
  }

  onSelect(inputItems) {
    const items = [].concat(inputItems);
    if (this.exportMode) {
      const apiCallConfig = this.apiCall && (isApiCallConfig(this.apiCall) ? this.apiCall : this.apiCall());
      const apiCall = FetchApiCallUtils.getApiCall(apiCallConfig);
      const propsToReplace = this.schema.filter(field => !!field.exportProp);
      const exportItems = items.map(item => {
        const formattedItem = cloneDeep(item);
        if (formattedItem) {
          propsToReplace.forEach(field => (formattedItem[field.prop] = formattedItem[field.exportProp]));
        }
        return formattedItem;
      });
      this.store.dispatch(
        new fromRoot.SetExportModeBatch({
          batch: exportItems,
          exportColumns: this.exportColumns,
          columnLabelDictionary: this.columnLabelDictionary,
          apiCall,
          queueChunckSize: this.queueChunckSize,
        }),
      );
    } else if (this.selectMode) {
      this.store.dispatch(new fromRoot.SetSelectMode(items));
    } else if (this.updateMode) {
      this.store.dispatch(new fromRoot.SetUpdateModeBulk({ items }));
    } else if (this.mergeIpMode) {
      this.store.dispatch(new fromRoot.SetMergeIpItems(items));
    } else if (this.singleSelection) {
      this.singleSelection(items);
    }
  }

  public allSelected(allSelected) {
    if (this.exportMode) {
      const exportModeType = allSelected ? ExportModeType.QUEUE : ExportModeType.BATCH;
      this.store.dispatch(new fromRoot.SetExportModeType(exportModeType));
    }
    if (this.updateMode) {
      const updateModeType = allSelected ? UpdateModeType.BULK_ALL : UpdateModeType.BULK;
      this.store.dispatch(new fromRoot.SetUpdateModeType(updateModeType));
    }
    if (this.selectMode) {
      this.store.dispatch(new fromRoot.SetSelectMode(this.selectMode.selection));
    }
  }

  getVisibleColumns(): Observable<string[]> {
    return combineLatest([isObservable(this.schema) ? this.schema : of(this.schema), this.isSelectableMode(), this.updatedColumns || of(null)]).pipe(
      delay(200),
      map(([schema, isSelectableMode, updatedColumns]: [DataTableRow[], boolean, string[]]) => {
        let visibleColumns = [];
        if (updatedColumns?.length > 0) {
          const updatedSchema = schema?.filter(row => updatedColumns.includes(row.prop));
          this.exportColumns = updatedSchema?.filter(row => !row?.excludeForExport).map(row => row.prop) || [];
          visibleColumns = (updatedSchema && ['rowSelected', ...updatedSchema.filter(row => !row?.excludeForVisibility).map(row => row.prop)]) || [];
        } else {
          this.exportColumns = without(schema?.filter(row => !row?.excludeForExport).map(row => row.prop) || [], 'rowSelected');
          visibleColumns = schema?.filter(row => !row?.excludeForVisibility).map(row => row.prop) || [];
        }
        this.columnLabelDictionary = mapValues(keyBy(schema, 'prop'), (row: any) => row.name);
        return isSelectableMode ? visibleColumns : without(visibleColumns, 'rowSelected');
      }),
    );
  }

  reorderColumn(columns) {
    this.exportColumns = without(
      columns.filter(row => !row?.excludeForExport).map(row => row.prop),
      'rowSelected',
    );
    this.store.dispatch(new fromRoot.SetExportModeBatch({ exportColumns: this.exportColumns }));
  }
}
