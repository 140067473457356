import { Action } from '@ngrx/store';
import { SnackBarConfiguration } from 'store/root/state';
import { GetSharePictureSuccess, SetWorkFilter } from 'store/root/actions/copyright/copyright.actions';

export const ADVANCED_SEARCH_TOGGLE = '[Ui] Advanced Search Toggle';
export const EXPERT_SEARCH_MODE_SET = '[Ui] Set Expert Search';
export const EXPERT_SEARCH_MODE_TOGGLE = '[Ui] Toggle Expert Search';
export const SHOW_ROUTE_LOADING_VISIBILITY = '[Ui] Show Router Loading Visibility';
export const SHOW_DATA_LOADING_VISIBILITY = '[Ui] Show Data Loading Visibility';
export const STATUS_DIALOG_COMPONENT = '[Ui] Status Dialog Component';
export const SHOW_SNACKBAR = '[Ui] Show Snackbar';
export const RESET_SNACKBAR = '[Ui] Hide/Reset Snackbar';
export const SET_NAVIGATION = '[Ui] Set Navigation';
export const GET_NAVIGATION_BADGES = '[Ui] Get Navigation Badges';
export const GET_NAVIGATION_BADGES_SUCCESS = '[Ui] Get Navigation Badges Success';
export const SET_SIDEBAR_OPEN = '[Ui] Set Sidebar open';
export const ON_APP_FOCUS = '[Ui] On App Focus';
export const RESET_TERRITORY_SEARCH_INPUT = '[Ui] Reset Territory Search Input';
export const SET_FAB_MAIN_BUTTONS_COUNT = '[Ui] Set FAB main buttons count';
export const SET_FAB_CHILD_BUTTONS_COUNT = '[Ui] Set FAB child buttons count';
export const SET_HOTKEY = '[Ui] Set Hotkey';

export class AdvancedSearchToggle implements Action {
  readonly type = ADVANCED_SEARCH_TOGGLE;

  constructor(public payload?: boolean) {}
}

export class OnAppFocus implements Action {
  readonly type = ON_APP_FOCUS;
}

export class ExpertSearchModeSet implements Action {
  readonly type = EXPERT_SEARCH_MODE_SET;

  constructor(public payload: boolean) {}
}

export class ExpertSearchModeToggle implements Action {
  readonly type = EXPERT_SEARCH_MODE_TOGGLE;

  constructor() {}
}

export class ShowRouterLoadingVisibility implements Action {
  readonly type = SHOW_ROUTE_LOADING_VISIBILITY;

  constructor(public payload?: boolean) {}
}

export class ShowDataLoadingVisibility implements Action {
  readonly type = SHOW_DATA_LOADING_VISIBILITY;

  constructor(public payload?: boolean) {}
}

export class StatusDialogComponent implements Action {
  readonly type = STATUS_DIALOG_COMPONENT;

  constructor(public payload?: boolean) {}
}

export class ShowSnackBar implements Action {
  readonly type = SHOW_SNACKBAR;

  constructor(public payload: SnackBarConfiguration) {}
}

export class ResetSnackBar implements Action {
  readonly type = RESET_SNACKBAR;
}

export class SetNavigation implements Action {
  readonly type = SET_NAVIGATION;

  constructor(public payload: any) {}
}

export class GetNavigationBadges implements Action {
  readonly type = GET_NAVIGATION_BADGES;

  constructor(public payload?: string) {}
}
export class GetNavigationBadgesSuccess implements Action {
  readonly type = GET_NAVIGATION_BADGES_SUCCESS;

  constructor(public payload: any) {}
}

export class SetSidebarOpen implements Action {
  readonly type = SET_SIDEBAR_OPEN;
  constructor(public payload: any) {}
}
export class ResetTerritorySearchInput implements Action {
  readonly type = RESET_TERRITORY_SEARCH_INPUT;
  constructor(public payload: any) {}
}

export class SetFabMainButtonsCount implements Action {
  readonly type = SET_FAB_MAIN_BUTTONS_COUNT;
  constructor(public payload: number) {}
}

export class SetFabChildButtonsCount implements Action {
  readonly type = SET_FAB_CHILD_BUTTONS_COUNT;
  constructor(public payload: number) {}
}

export class SetHotkey implements Action {
  readonly type = SET_HOTKEY;
  constructor(public payload: string) {}
}

export type UiActions =
  | AdvancedSearchToggle
  | ExpertSearchModeSet
  | ExpertSearchModeToggle
  | ShowRouterLoadingVisibility
  | ShowDataLoadingVisibility
  | ResetSnackBar
  | ShowSnackBar
  | SetNavigation
  | StatusDialogComponent
  | GetNavigationBadges
  | GetNavigationBadgesSuccess
  | SetSidebarOpen
  | OnAppFocus
  | ResetTerritorySearchInput
  | SetFabMainButtonsCount
  | SetFabChildButtonsCount
  | SetHotkey
  | GetSharePictureSuccess
  | SetWorkFilter;
