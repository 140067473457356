export const locale = {
  lang: 'en',
  data: {
    ERRORS: {
      NOT_EXISTS_OR_WRONG_TYPE: `The <<placeholder>> doesn't exist or is not Legal entity`,
      ASSIGNOR_ASSIGNEE_NOT_EQUAL: 'Assignor and Assignee must be different',
    },
    ADD_BY_ID_DIALOG: {
      WORK: {
        TITLE: 'Work Reference',
        ERROR: `The work doesn't exist, please enter another reference`,
      },
      AGREEMENT: {
        TITLE: 'Agreement Id',
        ERROR: `The agreement doesn't exist, please enter another id`,
      },
      IPS: {
        TITLE: 'Please enter IPI Name Number of the Legal Entity Party',
        NAME: 'IPI Name Number',
        ERROR: `This legal entity doesn't exist, please enter another id`,
      },
      CREATOR_AFFILIATIONS: {
        TITLE: 'Creator Affiliation',
        ERROR: `One or more societies doesn't exist`,
      },
      COMMON: {
        ADD: 'Add',
      },
    },
    PRE_STEPPER_DIALOG: {
      AGREEMENTS: {
        TERMINATE_TAB: {
          TITLE: 'Terminate agreement',
          CONFIRMED_TERMINATED_DATE: 'Confirmed termination date',
          POSTTERM_COLLECTION_END_DATE: 'Post-term Collection End Date',
          TERRITORY: 'Territory',
          RIGHT_TYPES: 'Right Types',
          EDIT_RIGHT_TYPES: 'Edit Right Types',
          RETENTION: 'Add Retention?',
          SAVE: 'Save',
          CANCEL: 'Cancel',
          NOT_IN_AGREEMENT_TERRITORY: `One or more territories aren't included in Agreement Territory`,
          ERROR_RIGHTS: `One or more rights aren't included in Agreement Rights`,
          TRANSFERED_WORKS: `works transfered out of a total of`,
        },
        RETENTION_TAB: {
          TITLE: 'Retention Agreement',
        },
        RESPONSE_TAB: {
          GO_TO_WORK_LIST: 'Go to Work List',
          ADD_WORKS_FOR_RETENTION: 'Add Works For Retention',
          RETENTION_AGREEMENT: 'Retention Agreement',
          ERROR_RETENTION_AGREEMENT: 'Error Creating Retention Agreement',
          SELECT_RETENTION_WORKS: 'Do you want to select works for retention?',
          RETENTION_SUCCESS_FIRST_PART: 'The selected works will be uploaded to',
          RETENTION_SUCCESS_LAST_PART: 'please check later.',
          BUTTON_SELECT_WORKS_TO_TRANSFER: 'Select works to transfer',
          BUTTON_TRANSFER_ALL_WORKS: 'Transfer all works',
          BUTTON_UPLOAD_CSV_WORKS: 'Upload CSV works file',
        },
      },
    },
    STEPS: { TITLE_UPDATE: 'Update', TITLE_CLONE: 'Clone' },
    USERS: {
      POPUP: {
        OK_MESSAGE: 'The submitted user was successfully registered with the following ID',
        KO_MESSAGE: `The submitted user can't be registered. Please review the submitted information`,
        OK_MESSAGE_EDIT: `The account was updated successfully`,
      },
      STEPS: {
        USER_INFO: 'User information',
        ROLES_ORGANIZATION: 'Roles and Organization',
        MESSAGE_GDPR:
          'The username information will be visible to 3rd parties (societies, publishers) outside ICE. Please refrain from using any personal data eg. First/Last name.',
      },
      ITEM_TITLE: 'user',
      DETAILS: {
        TAB_TITLE: 'User details',
        FORM: {
          TITLE: 'User Information',
          FIELDS: {
            ID_TOOLTIP: 'Type the unique user Id',
            FIRST_NAME_TOOLTIP: 'Write the first name of the user',
            LAST_NAME_TOOLTIP: 'Write the last name of the user',
            EMAIL_TOOLTIP: 'Write user email',
            EMAIL_ERROR_MESSAGE: 'Enter a valid email',
            STATUS_TOOLTIP: 'Status of user',
            LAST_LOGIN_ATTEMPT_TOOLTIP: 'Last login attempt',
            USERNAME: 'Username',
            USERNAME_EXIST: 'This user already exists',
            EMAIL_EXISTS: 'This email already exists',
            USERNAME_INVALID_FORMAT: 'Invalid username format',
            FIRST_NAME: 'First Name',
            LAST_NAME: 'Last Name',
            EMAIL: 'Email',
            ASSOCIATED_ORGANIZATION: 'Associated Organization',
            ORGANIZATION_ID: `ORGANIZATION ID's`,
            ORGANIZATION_DOESNT_EXIST: 'Organization does not exist',
            ORGANIZATION_SELECT_PLACEHOLDER: 'Please select associated organization',
            ASSOCIATED_ROLES: 'Associated Roles',
            ACTIVITY_STATUS: 'Activity Status',
            LAST_LOGIN_ATTEMPT: 'Last Login Attempt',
          },
        },
      },
    },
    'COUNTER-CLAIMS': {
      ITEM_TITLE: 'Counter Claims',
      POPUP: {
        OK_MESSAGE: 'The submitted Counter Claim was successfully registered with the following ID',
        KO_MESSAGE: `The submitted Counter Claim can't be registered. Please review the submitted information`,
        RETURN_TO_WORK: `Return to work`,
      },
    },
    COUNTERCLAIMS: {
      STEPS: {
        TYPE: 'Type',
        PARTIES: 'Parties',
        ADD_PARTY: 'Add Party',
        CONFLICT: 'Select Counterclaim type and Point of conflict',
        SELECT: 'Review scope, select Claimants and Resolvers',
        SET_RESOLUTION_OWNERS: 'Set resolution owners',
        SET_RESOLUTION_OWNERS_ERROR: 'Every resolver must have a resolution owner',
        FIRST: 'First claimant',
        NEW: 'New claimant',
        RESOLVER: 'Resolver',
      },
      RESOLVERS_DIALOG: {
        TITLE: 'Set claimant and resolvers',
      },
      ITEM_TITLE: 'counterclaims',
      FORM: {
        FIELDS: {
          ID_TOOLTIP: 'CounterClaim Type',
          TYPE: 'Counterclaim Type',
          CONFIRM: 'Type to be confirmed by member',
          RESOLUTION_OWNER: 'Counterclaim Resolution Owner',
          TERM_TERRITORY: 'Territory',
          CONFLICT: 'Point Of Conflict',
          START_DATE: 'Start date',
          END_DATE: 'End date',
          USAGE_DATE: 'Usage date',
          PR_RIGHTS: 'PR Rights',
          MR_RIGHTS: 'MR Rights',
          TERRITORY: 'Counterclaim territory',
          DEFAULT_TERRITORY: 'WORLDWIDE',
        },
        ERRORS: {
          NOT_ENOUGH_PARTIES: 'The counterclaim must contain at least two parties',
          NOT_ENOUGH_FIRST_CLAIMANTS: 'The counterclaim must contain at least one FIRST claimant',
          INVALID_PARTIES: 'Invalid parties for type',
          ADD_TYPE: 'You have to set a COUNTERCLAIM TYPE and add at least two valid PARTIES',
        },
      },
      TYPES: {
        AS: 'Author Share',
        AU: 'Authorship',
        OW: 'Ownership',
        OR: 'Original',
        SA: 'Sample',
        NA: 'No Agreement',
        BC: 'Breach of Contract',
        IN: 'Infringement',
      },
      POINT_OF_CONFLICT: {
        BC: 'Between Creators',
        WAP: 'Writer And Original Publisher',
        PASP: 'Publisher And Sub Publisher',
        IP: 'Infringed Parties',
        NC: 'New Creator',
      },
      PARTIES_DIALOG: {
        BT_SEARCH: 'Search',
        BT_CANCEL: 'Cancel',
        BT_SELECT: 'Select',
      },
    },
    'COUNTER-CLAIM-SUPPORT': {
      ITEM_TITLE: 'Counter Claim Support',
      STEPPER_TITLE: 'SUPPORT CLAIM PROCESS',
      STEPS: {
        PARTIES: {
          TITLE: 'Review the claims and select parties',
          REVIEW_CLAIMS: 'Review your claims',
          SELECT_PARTIES: 'Select parties to support',
        },
        UPLOAD_DOCUMENTATION: {
          TITLE: 'Upload Documentation',
          DOCUMENTS: {
            TITLE: 'Documents',
            DATE_TIME: 'Date',
            OWNER_NAME: 'Owner Name',
            OWNER_IPI_NUMBER: 'Owner IPI Number',
            CLAIMANT_NAME: 'Claimant Name',
            CLAIMANT_IPI_NUMBER: 'Claimant IPI Number',
            FILE_NAME: 'File Name',
            STATUS: 'Upload Status',
          },
          ERROR_NO_ACTION_SELECTED: 'You must select a party with action to upload documents',
          ERROR_ACTION_NO_NEEDS_DOCUMENTATION: 'None of the selected actions need documentation',
          DISCLAIMER: {
            TITLE: 'Disclaimer',
            TEXT_1: 'By uploading documentation in support of your claim, you:',
            TEXT_2:
              // eslint-disable-next-line max-len
              '- are agreeing to make such documents avalable to view and download by the other Counterclaim Claimant in the event that the Counterclaim reaches the Confirmed status.',
            TEXT_3: '- acknowledge and agree that the documents will be available to view unless you request your Society to reject the documents.',
            TEXT_4:
              // eslint-disable-next-line max-len
              'Do not upload documents unless you have checked your documents to ensure that any commercially sensitive information (in so far as it is not necessary to support your claim) has been redacted.',
            TEXT_5: 'Please contact your Society if you have questions about the supporting documentation you are required to provide.',
            ACCEPT: 'I Accept',
            YOU_MUST_ACCEPT_DISCLAIMER: 'You must accept the Disclaimer Terms',
          },
        },
      },
    },
    WORKS: {
      STEP_WORK_DETAIL: {
        TITLE: 'Work details',
        ORIGINAL_TITLE: 'Original Title',
        ORIGINAL_TITLE_ERROR: 'Original Title is missing',
        DURATION: 'Duration (MM:SS)',
        PURPOSE: 'Purpose',
        TITLES: {
          TITLE: 'Titles',
          TYPE: 'Title Type',
          NAME: 'Title Name',
          DURATION: 'Title Duration (MM:SS)',
        },
        XREFS: {
          TITLE: 'Xrefs',
          TYPE: 'Xref Type',
          VALUE: 'Xref Value',
        },
        SOURCE: 'Source',
      },
      STEP_FIRST_CREATOR_TITLE: 'First creator share',
      STEP_ADDITIONAL_CLAIMS: {
        TITLE: 'Add Claims',
        CLAIM_DIALOG: {
          TITLE: 'Please add claim details',
          SUBMIT: 'Ok',
          CANCEL: 'Cancel',
        },
        DELETE_CLAIM: 'DELETE CLAIM',
        DELETE_CREATOR: 'The current Creator and all it’s children nodes will be removed. Continue?',
        DELETE_PUBLISHER: 'The current Publisher and all it’s children nodes will be removed. Continue?',
        DELETE_SUBPUBLISHER: 'The current SubPublisher and all it’s children nodes will be removed. Continue?',
        DELETE_YES: 'Yes',
        DELETE_NO: 'No',
        DUMMY_IP_ADDED: 'Dummy Ip correctly added',
        DUMMY_RETURN: 'Return to the claim Info',
        MODIFY_CLAIM: 'MODIFY CLAIM',
        MULTIPLE_SCOPES_LINE_1: 'More than one scope.',
        MULTIPLE_SCOPES_LINE_2: 'Please use Modify for details',
        SELECT_IPI_RIGHTS: 'Select IPI Right(s)',
        ROOT_NODE_OPTIONS: {
          ADD_CREATOR: 'ADD CREATOR',
          ADD_UNKNOWN_CREATOR: 'ADD UNKNOWN CREATOR',
          ADD_INC_PART_PUBLISHER: 'ADD INC PART PUBLISHER',
        },
        CREATOR_NODE_OPTIONS: {
          ADD_E_PUBLISHER: 'ADD E PUBLISHER',
          ADD_UNKNOWN_E_PUB: 'ADD UNKNOWN E PUBLISHER',
        },
        PUB_NODE_OPTIONS: {
          ADD_SE_PUBLISHER: 'ADD SE-PUBLISHER',
        },
        VALIDATION_ERRORS: {
          NO_VALID_FIRST_CREATOR: 'You must appoint at least one creator the role of C, A or CA',
        },
      },
      POPUP: {
        TITLE: 'Work Registration',
        OK_MESSAGE: 'Work was successfully submitted for mastering ',
        OK_MESSAGE_EDIT: 'The submitted work was succesfully updated: ',
        OK_MESSAGE_CLONE: 'New work ',
        OK_MESSAGE_CLONE_AFTER_LINK: ' created. Please update the information if needed.',
        CLONE_ERROR: 'Could not clone work because',
        KO_MESSAGE: `The submitted work can't be updated. Please review the submitted information`,
        ROD_MESSAGE_FROM_WORK: 'The fiche is being processed and can be picked up under ROD',
      },
      TRIM_CLAIMS: {
        TITLE: 'Trim Claims',
        CLAIMS_SELECTION: 'Claims Selection',
        NEW_SCOPE: 'Setting the new Scope of the Claims',
        NEW_SCOPE_HOW: 'How do you want to set the new Scope for the Claims?',
        NEW_SCOPE_PLACEHOLDER: 'Set the Trim Claims mode',
        TRIM_SCOPE: 'Trim the Claims without increasing the Scope',
        REPLACE_SCOPE: 'Replace the Scope of the Claims',
        ADD_SCOPE: 'Add to the current Scope of the Claims',
        CLAIM_CHANGE_WARNING: 'Please check any Counterclaims on the work as the Claim Change might affect them!',
        ADDITIONAL_CONFLICTS_WARNING: 'By changing the claims, you will create additional Conflicts!',
        TEMPORARY_UPDATES_WARNING:
          'Please note that your updates will only be visible in the Submitted Claims page. Only after saving(Submit) the work, they will show e.g. in the Work Details page!',
        SWITCH_TO_EXPERT: 'Switch to Expert Mode',
        SWITCH_TO_EXPERT_TOOLTIP: 'Expert Mode has more options on each step.',
        EXPERT_MODE: 'Expert Mode',
        EXPERT_MODE_TOOLTIP: `Once in Expert Mode, you can't switch back to Normal Mode`,
        SELECT_CLAIMS_PLACEHOLDER: 'Select Claims',
        SELECT_ALL_CLAIMS: 'All Claims on the Work',
        SELECTED_CHAINS: 'Selected Chains on the Work',
        SELECTED_CHAINS_PLACEHOLDER: 'Select Chains',
        EXCLUDE_CREATORS: 'Exclude Creators from trimming',
        EMPTY_FIELDS_INFO: 'Fields left empty will not be used in the trimming.',
        MECHANICAL_PLACEHOLDER: 'Mechanical Rights',
        PERFORMING_PLACEHOLDER: 'Performing Rights',
        PRIOR_ROYALTIES_DATE: 'Prior Royalties Date',
      },
    },
    'AGREEMENT-CONFLICT': {
      POPUP: {
        KO_MESSAGE: `The submitted agreement conflict can't be updated. Please review the sumitted information`,
      },
    },
    CLAIM: {
      STEP_CLAIM_DETAILS: 'Claim details*',
      STEP_SHARES: 'Shares*',
      CLAIMANT_IPI_NUMBER: 'Claimant IPI number',
      IP_NAME_KEY: 'IP Name Key',
      NAME: 'Name',
      CLAIMANT_ROLE_CODE: 'Claimant Role Code',
      CLAIMANT_ROLE_CODE_NO_CREATORS_ERROR: 'You must appoint at least one creator the role of C, A, CA, AD or AR',
      CLAIMANT_ROLE_CODE_REQUIRED: 'Claimant Role Code is required',
      CLAIMANT_ROLE_INVALID_IP_TYPE: 'The role is not allowed for the selected type of party',
      PUBLISHER_FROM_WORK: 'Get Original Publisher from work',
      START_DATE: 'Start Date (default indefinite)',
      END_DATE: 'End Date (default indefinite)',
      SIMPLY_START_DATE: 'Start Date',
      SIMPLY_END_DATE: 'End Date',
      TERMINATION_DATE: 'End Date (default indefinite)',
      POSTTERM_DATE: 'Post-term Date (default indefinite)',
      SIMPLY_POSTTERM_DATE: 'Post-term Date',
      POSTTERM_VALUE: 'Post-term Value',
      PUBLISHER_IPI: 'Select the original publisher',
      PUBLISHERPI: 'Original Publisher IPI number',
      LINK_CREATOR: 'Link creator or income participant',
      ORIGINAL_PUBLISHER_NAME: 'Original Publisher Name',
      AGREEMENT_NUMBER: 'Agreement Number',
      INCOME_PARTICIPANT: 'Income Participant',
      POPUP: {
        OK_MESSAGE: 'The submitted claim was successfully registered in work',
        KO_MESSAGE: `The submitted claim can't be registered. Please review the submitted information`,
      },
      ERRORS: {
        CLAIMANT_IPI_NUMBER_ERROR: 'IPI name could not be validated',
        CLAIMANT_IPI_MUST_BE_NATURAL_PERSON: 'IPI name must be a Natural Person',
        AGREEMENT_NUMBER_ERROR: 'No Agreement with this Agreement Number',
      },
    },
    AGREEMENT_GROUP: {
      SEARCH_IP: 'Search Party',
      CREATE_POPUP_TITLE: 'Create New Agreement Group',
      POPUP: {},
      ERRORS: {
        IP_NAME_KEY_ERROR: 'IP Name Key could not be validated',
      },
      DIALOG: {
        TITLE: 'Agreements',
        ADD_MANUALLY: 'Add agreements manually',
        INFO: 'You have added <<number>> agreements',
        CONFIRM_TITLE: 'Confirm adding agreements',
        CONFIRM_MESSAGE: 'You are adding <<number>> new agreements. Please confirm.',
        CANCEL_TITLE: 'Cancel adding agreements',
        CANCEL_MESSAGE: 'Everything in this table will be lost. Are you sure?',
        SUCCESS_MESSAGE: `Thanks! We have processed your request. Please wait for the UI to reflect the changes.`,
        ERROR_MESSAGE: `Attention! We have had problems executing your request.`,
        TIMEOUT_ERROR: `Attention! Request is taking longer than expected. Please check later.`,
        DELETE_TITLE: 'Remove selected agreement',
        DELETE_MESSAGE: 'Are you sure you want to remove this agreement?',
      },
    },
    AGREEMENTS: {
      ITEM_TITLE: 'agreement',
      CORE_AGREEMENT: {
        TITLE: 'Core Agreement',
        IPI_NUMBER: {
          NAME: 'Submitter IPI Name number',
          INFO_TEXT: 'Enter here the IPI Name Number for the Person/Company submitting this agreement. It must be numeric',
          NOT_IPI_PREFIX: 'IPI Name Number Prefix is not correct, It must be IPI',
          NOT_VALID: 'IPI Name Number could not be validated',
        },
        TYPE_OF_AGREEMENT: {
          NAME: 'Type of Agreement',
          INFO_TEXT: 'General agreements cover all works either written by given creator(s) or published by given publishers (allowing for exclusions).',
          VALUES: { GENERAL: 'General', SPECIFIC: 'Specific' },
          ERROR: "Assignor is a GENERIC CREATOR, Please select 'Specific' Type of agreement",
        },
        SOURCE: {
          ALL: 'Alliance',
          BUMA: 'Buma Stemra',
          GEMA: 'GEMA Data Alignment',
          NAS: 'NAS Agreement Validation',
          PUBL: 'Notified by Publisher',
          TEOST: 'Teosto',
          UND: 'Undocumented',
          UNNOT: 'Unnotified Publisher',
        },
        RETENTION: 'Retention',
        SALES_MANUFACTURE: {
          NAME: 'Sales/Manufacture',
          INFO_TEXT:
            'The Sales/Manufacture-clause-indicator is a marker which shows whether the acquiring party has acquired rights either for products manufactured or for ' +
            'products sold in the territories of the agreement.',
          VALUES: { SALES: 'Sales', MANUFACTURE: 'Manufacture' },
        },
        SHARE_IN_OVERRIDE: {
          NAME: 'Share-in override',
          INFO_TEXT: 'Default share-in to 100 when assignor is SE',
          VALUES: { YES: 'Yes', NO: 'No' },
        },
        TRANSFER_WORKS: {
          NAME: 'Transfer works',
          INFO_TEXT: 'This field specifies if previously registered works by the assignor can be transferred to this agreement.',
          VALUES: { YES: 'Yes', NO: 'No' },
        },
        IMPLIED_ON_BEHALF_OF: {
          NAME: 'Implied/on behalf of',
          INFO_TEXT: 'A flag to indicate if the agreement being registered or disseminated is an implied agreement or is being sent on-behalf of another party. (Society use)',
          VALUES: { IMPLIED: 'Implied', ON_BEHALF_OF: 'On behalf of' },
        },
      },
      AGREEMENT_TERM: {
        TITLE: 'Agreement term',
        START_DATE: { NAME: 'Start date', INFO_TEXT: 'The date the agreement is effective from' },
        PRIOR_ROYALTIES: {
          NAME: 'Prior royalties',
          INFO_TEXT: 'A flag to indicate if the assignee party is allowed to collect royalties which were accrued before the start of the agreement but not yet paid out.',
          VALUES: { YES: 'Yes', NO: 'No' },
        },
        EXPECTED_TERMINATION: {
          NAME: 'Expected termination',
          INFO_TEXT: 'Date the submitter anticipates that the term of the agreement will end. Note that this will not cause an automatic termination of the agreement.',
        },
      },
      ERRORS: {
        INVALID_DATE: 'Not a valid date',
        DATE_BEFORE: 'Start date must be before expected termination date',
        DATE_AFTER: 'Expected termination date must be after start date',
        DATE_BEFORE_START_DATE: 'The date must be before start date',
        DATE_AFTER_BIRTH_DATE: 'The date must be after birth date',
        DATE_BEFORE_TODAY: 'The date must be before today',
        DATE_SAME_OR_BEFORE_TODAY: 'The date must be same or before today',
        DATE_NOT_DEFAULT: 'The date must not be previous default date',
        DEFAULT_ERROR_TERMINATE_AGREEMENT: 'Error terminating the agreement',
      },
      AGREEMENT_PARTIES: {
        TITLE: 'Agreement Parties',
        ASSIGNOR: 'Assignor',
        CLAIMANT_TYPE: 'Claimant Type',
        ASSIGNOR_TYPE: 'Assignor Type',
        CREATION_CLASS: 'Creation class',
        STATUS: 'Status',
        STATUS_ACTIVE: 'ACTIVE',
        STATUS_INACTIVE: 'ACTIVE',
        ASSIGNOR_TYPE_INFO: 'Choose "Person" if the assignor is a creator (i.e. if the agreement is an ESA or specific writer\'s agreement)',
        ASSIGNOR_PERSON: 'Person',
        ASSIGNOR_LEGAL_ENTITY: 'Legal entity',
        ASSIGNOR_NAME: 'Assignor name',
        ASSIGNOR_NAME_INFO: 'Party assigning the right to collect income in the territory covered by the agreement.',
        ASSIGNOR_PRO_AFFILIATION: 'Assignor PRO affiliation',
        ASSIGNOR_IP_NAME_KEY: 'Assignor IP name key',
        ASSIGNOR_IPI_NAME_NUMBER: 'Assignor IPI name number',
        SUBMITTER_ASSIGNOR_IP_CODE: 'Submitter assignor IP Code',
        ASSIGNEE: 'Assignee',
        ASSIGNEE_IPI_NAME_NUMBER: 'Assignee IPI name number',
        ASSIGNEE_NAME: 'Assignee name',
        ASSIGNEE_NAME_INFO: 'Party receiving the right to collect income in the territory covered by the agreement.',
        ASSIGNEE_PRO_AFFILIATION: 'Assignee PRO affiliation',
        ASSIGNEE_IP_NAME_KEY: 'Assignee IP name key',
        COMPANY_GROUPING: 'Company grouping',
        ERROR_ASSIGNOR_TYPE_NOT_MATCH: 'Assignor type does not match',
        ERROR_INCORRECT_SELECTED_TYPE: 'Incorrect selected type',
        ERROR_ASSIGNEE_CANNOT_BE_NATURAL_PERSON: 'Natural Person as Assignee is not allowed',
        BT_SEARCH: 'Search',
        BT_CANCEL: 'Cancel',
        ERROR_PARTIES_STEP: 'Please select the assignor and assignee.',
        POPUP_CREATE_IP: {
          TITLE: 'Create new IP',
          TITLE_EDIT: 'Edit IP',
          FIELD_NAME: 'Name',
          FIELD_FIRSTNAME: 'Firstname',
          FIELD_BIRTH: 'Date of birth / foundation',
          FIELD_DEATH: 'Date of death / dissolution',
          FIRST_NAME_NOT_SPECIFIED: 'First Name not specified',
          CREATING_DUMMY_IP: 'Creating Dummy IP',
          ERRORS: {
            INVALID_NAME: 'Please enter a valid name',
          },
        },
        POPUP_NO_SUBMITTER: {
          TITLE: 'Information',
          TEXT: 'You need to specify an IPI name number submitter in the first step Core Agreement before adding a Dummy IP',
          ACCEPT: 'Accept',
        },
        SEARCH_CLAIMANT: 'Search claimant',
        SEARCH_PUBLISHER: 'Search publisher',
        SEARCH_ASSIGNEE: 'Search assignee',
        CREATE_DUMMY_IP: 'Create dummy IP',
        GENERIC_CREATOR: 'Click here to set GENERIC CREATOR as the assignor',
        IS_ADMINISTRATOR: 'Assignee is administrator?',
      },
      WORKS: { TITLE: 'Works' },
      SHARES: {
        TITLE: 'Shares',
        TITLE_CLAIMED_SHARES: 'Claimed Shares',
        TITLE_OWNER_SHARES: 'Owner Shares',
        TABLE: {
          COL_TITLE_RIGHT_TYPE: 'Right type',
          COL_TITLE_START_DATE: 'Start Date',
          COL_TITLE_END_DATE: 'End Date',
          COL_TITLE_TERMINATION_DATE: 'Termination Date',
          COL_TITLE_PRIOR_ROYALTIES_DATE: 'Prior Royalties Date',
          COL_TITLE_POSTTERM_DATE: 'Post Term Collection Date',
          COL_TITLE_POSTTERM_VALUE: 'Post Term Collection Value',
          COL_TITLE_MECHANICAL: 'Mechanical',
          COL_TITLE_PERFORMING: 'Performing',
          COL_TITLE_SYNCHRONISATION: 'Synchronisation',
          COL_TITLE_PRINT: 'Print',
          RIGHT_TYPE_CREATOR: 'Creator share retained',
          RIGHT_TYPE_PUBLISHER: 'Publisher share retained',
          RIGHT_TYPE_CREATOR_NATURAL_PERSON: 'Creator share',
          RIGHT_TYPE_ASSIGNEE: 'Assignee collectable share',
          RIGHT_TYPE_SHARE: 'Share',
          RIGHT_TYPE_COLLECTION_SHARE: 'Collection Share',
          RIGHT_TYPE_OWNERSHIP_SHARE: 'Ownership Share',
          SHARES_TERRITORY: 'Shares territory',
          RIGHT_TYPE_INCLUSION: 'IPI Right(s)',
          BT_ADD_INCLUSION: 'Select',
          DEFAULT_TERRITORY: '2WL',
          DEFAULT_USAGE_TYPE: 'ALL',
          NO_SHAREOUT: 'No share-out',
          TOOLTIPS: {
            REMOVE_COLUMN: 'Remove column',
            CLONE_COLUMN: 'Clone column',
            MODIFY_USAGE_TYPES: 'Modify usage types',
            RIGHT_TYPE:
              'Select PR/MR to include all performing and mechanical rights. For clarity, if one type of right is claimed alone, ' + 'then no other type of right is claimed.',
            CREATOR:
              'The collection shares retained by the creator. For any given song share subject to this agreement (and in relation to the given right), what proportion is ' +
              'retained by the creator? (ie "writer\'s share").',
            PUBLISHER:
              'The collection shares not contracted to the assignee and thus retained by another publisher. For any given song share subject to this agreement ' +
              '(and in relation to the given right), what proportion is controlled by another publisher? (ie non-assigned "publisher\'s share").',
            ASSIGNEE:
              'The collections shares contracted to the assignee to collect. For any given song share subject to this agreement (and in relation to the given right), ' +
              'what proportion may be collected by the assignee?',
            SHARES: 'One right only can be assigned one time for territory.',
            COLLECTION_SHARES: 'One right only can be assigned one time for territory.',
            OWNERSHIP_SHARES: 'One right only can be assigned one time for territory.',
            INCLUSION: 'You can specify usage types with the Edit button',
            INCLUSION_READONLY: 'IPI rights will be submitted as All PR / All MR',
            DATES: 'One right only can be assigned one time for territory. Default indefinite.',
            NO_SHAREOUT: 'Register share-in only',
          },
        },
        BOTTOM_BUTTONS: {
          ADD_MECHANICAL: 'Add Mechanical',
          ADD_PERFORMING: 'Add Performing',
          ADD_SYNCHRONISATION: 'Add Synchronisation',
          ADD_PRINT: 'Add Print',
        },
        INCLUSION_POPUP: {
          TITLE: 'Usage Types',
          DESC: 'Select all of uses types included:',
        },
        USES_TYPES: {
          POPUP: {
            TITLE: 'IPI Rights',
            MESSAGE: 'Select usage types included in this right',
            ONLINE: 'Online usages',
            NON_ONLINE: 'Non-online usages',
            BT_INCLUDE: 'Include',
            BT_CANCEL: 'Cancel',
            PERFORMING_HEADING: 'Performing Right types',
            MECHANICAL_HEADING: 'Mechanical Right types',
          },
        },
        VALIDATION: {
          NAN: 'Please, enter a valid number.',
          ONLY_2_DECIMALS: 'Please, only 2 decimals.',
          TOTAL_100: 'Each column must add up to 100% or assignee field must be in range 0 and 100 when no shareout',
          TOTAL_MORE_THAN_100: 'Total shares exceed 100 %',
          ONE_RIGHT_FILLED: 'Please enter values for at least one right.',
          NO_REPETITION_RIGHT: 'Only one right can be assigned to a territory.',
          AT_LEAST_ONE_BY_TERRITORY: 'Please enter at least one right for each agreement territories.',
          NO_NEGATIVE_VALUE: 'Please enter values between 0 and 100',
          LIMIT_EXCEEDED: 'Shares exceed 100 percent in work CLAIM conflicts',
          ERROR_TERRITORY_NOT_EXISTS: `Territory doesn't exist`,
          ERROR_WRONG_END_DATE: 'The post term collection date is before the end date',
          ERROR_TERRITORY_IS_EMPTY: 'Territory is empty',
          ERROR_NOT_CLAIM: 'Impossible find claimId in claims',
          ERROR_WRITER_MECHANICAL_REASON_IS_EMPTY: 'Writer Mechanical Reason is empty',
          ERROR_NO_ENOUGH_MR_RIGHTS: 'SY and any other Mechanical Right must be selected',
          ERROR_NO_ENOUGH_PR_RIGHTS: 'At least one Performing Right must be selected',
        },
      },
      ADDITIONAL_FIELDS: {
        TITLE: 'Additional fields',
        AGREEMENT_NAME: {
          NAME: 'Agreement name',
          INFO_TEXT: 'Name/identifier for this agreement',
        },
        IDENTIFIERS: {
          TITLE: 'Identifiers',
          SUBMITTER_AGREEMENT_NUMBER: {
            NAME: 'Submitter agreement number',
            INFO_TEXT: 'Submitter’s own reference for this agreement',
            ERROR_VALUE: 'The Submitter agreement number has some error, review special characters',
          },
          RECIPIENT_AGREEMENT_NUMBER: {
            NAME: 'Recipient agreement number',
            INFO_TEXT: 'Recipient’s reference for this agreement',
          },
          RECIPIENT_AGREEMENT_NUMBER_OWNER: {
            NAME: 'Recipient agreement number owner',
            INFO_TEXT: 'Who owns the recipient agreement number?',
          },
        },
        RELATED_AGREEMENTS: {
          TITLE: 'Related agreements',
          ADD_BUTTON: 'Add more related agreements',
          REMOVE_BUTTON: 'Remove related agreement',
          RELATED_AGREEMENT_TYPE: {
            NAME: 'Related agreement type',
            INFO_TEXT:
              'Recipient refers to an existing society publisher hierarchy/set of intercompany links. Co-Publishing refers to another transaction in the CAF ' +
              'representation of a co-publishing agreement',
            VALUES: { C: 'Co-publishing (C)', R: 'Recipient (R)' },
          },
          RELATED_AGREEMENT_NUMBER: {
            NAME: 'Related agreement number',
            INFO_TEXT: 'Enter an agreement reference so the agreements can be connected in ICE',
          },
          RELATED_AGREEMENT_NUMBER_OWNER: {
            NAME: 'Related agreement number owner',
            INFO_TEXT: 'Who owns this agreement reference?',
          },
          ERROR_RELATED_AGREEMENT_NUMBER: 'Invalid Reference',
          CREATE_NEW_AGREEMENT_GROUP: 'Create a New Agreement Group',
          ERROR_AGREEMENTS_GROUP: 'The value entered doesn’t correspond to any Agreement Group id',
        },
        INTERNATIONAL_FACTORS: {
          TITLE: 'International factors',
          WRITER_MECHANICAL_REASON: {
            NAME: 'Writer mechanicals reason ',
            INFO_TEXT: 'Reason for being able to collect more than the society-mandated maximum publisher mechanical share',
            VALUES: {
              ADVANCE_PAID: 'Advance paid',
              ANGLO_AMERICAN_WRITER_ONLY: 'Anglo-American writer(s) only',
              WRITER_IS_NON_SOCIETY: 'Writer is Non-Society',
            },
          },
          USA_LICENSE_INDICATOR: {
            NAME: 'USA licence indicator',
            INFO_TEXT: 'Defines the US society preference for licensing performing rights, via ASCAP, BMI, or SESAC. Codes used should be 021 (BMI), 010 (ASCAP), or 071 (SESAC)',
          },
          GEMA_REMUNERATION_CLAIM_INDICATOR: {
            NAME: 'GEMA remuneration claim indicator',
            INFO_TEXT:
              'If marked, this field indicates that the assignee has been authorised by the writer to receive the publisher’s share for remuneration rights; ' +
              'and indemnifies GEMA if such claim is incorrect. If unmarked, the assignee will not receive publisher’s share for remuneration rights.',
            VALUES: { YES: 'Y', NO: 'N' },
          },
        },
        LIBRARY_PRODUCTION: {
          TITLE: 'Library/production',
          LIBRARY_MUSIC: {
            NAME: 'Library music ',
            INFO_TEXT: 'When the repertoire assigned is to be managed/licensed according to library or production music rules by receiver',
            VALUES: { YES: 'Y', NO: 'N' },
          },
          RECORDING_PREFIX: {
            NAME: 'Recording prefix',
            INFO_TEXT: 'This is the catalog number prefix for those recordings which are submitted by the library, single entry here so list cannot be exhaustive.',
          },
        },
        ADDITIONAL_INFORMATION: {
          TITLE: 'Additional information',
          HEADER_TEXT: 'Please add any further information related to this agreement registration',
          FOOTER_TEXT: `Please note that if possible the data should be entered into the relevant
            sections of this form rather than this field, in order to increase the efficiency of handling your registration`,
        },
      },
      POPUP: {
        OK_MESSAGE: 'The submitted agreement was successfully registered with the following agreement number',
        SELECT_WORKS: 'Do you want to select works for retention?',
        SELECT_WORKS_ALL: 'Do you want to select all works for retention?',
        OK_MESSAGE_EDIT: 'The submitted agreement was successfully updated',
        OK_IN_PROGRESS: 'Agreement edits are being processed. You can verify the changes in the audit history here',
        OK_MESSAGE_CONFLICTS: 'A possible overlap has been identified. See conflict: ',
        OK_MESSAGE_MULTIPLE_CONFLICTS: 'Multiple possible overlaps have been identified. See conflicts: ',
        OK_MESSAGE_AGREEMENT_MERGED: ' which merged into the existing agreement number  <<targetIdVersion>>',
        KO_MESSAGE: `The submitted agreement can't be registered. Please review the submitted information`,
        OK_MESSAGE_UPDATE_FIRST_PART: `Agreement`,
        OK_MESSAGE_UPDATE_LAST_PART: `was successfully updated`,
      },
    },
    REPERTOIRES: {
      POPUP: {
        OK_MESSAGE: 'The submitted repertoire was successfully registered with the following',
        KO_MESSAGE: `The submitted repertoire can't be registered. Please review the submitted information`,
        OK_MESSAGE_EDIT: `The submitted repertoire was successfully updated with the following`,
      },
      STEPS: {
        TYPE: {
          TITLE: 'Type',
          FIELDS: {
            TYPE: {
              TITLE: 'Type',
              OPTIONS: {
                PUBLISHER: 'Publisher/Publisher group',
                SOCIETY: 'Society',
                WORK: 'Work List',
              },
            },
            NAME: 'Description (Repertoire name)',
          },
        },
        DETAILS: {
          TITLE: 'Details',
          FIELDS: {
            ROLL_UP: {
              TITLE: 'Original Publisher Roll-up',
              FIELDS: {
                ROLL_UP: 'Roll-up',
                EXCLUDED_TERRITORIES: 'Excluded Territories',
                ALLOW_NON_SOCIETY_OP: 'Allow Non-society OP',
              },
            },
            OP_CAN_DETERMINE: 'OP can determine Repertoire',
            USAGE_TYPES: { TITLE: 'Usage Types', FIELDS: { TYPE: 'Type' } },
            CLAIM_TYPES: {
              TITLE: 'Claim Types',
              OPTIONS: { DIRECT: 'Direct', DERIVED: 'Derived', ASSUMED: 'Assumed' },
            },
            CREATOR_AFFILIATIONS: {
              TITLE: 'Creator Affiliations',
              FIELDS: {
                SOCIETY_CODE: 'Society Code',
                SOCIETY_NAME: 'Society Name',
                ERROR_SOCIETY_NOT_EXISTS: `One or more societies doesn't exist`,
                RIGHTS: {
                  TITLE: 'Rights',
                  ICON_TOOLTIP: 'Edit Rights',
                  ERROR_RIGHT_NOT_EXISTS: `One or more rights doesn't exist`,
                  PR: 'PR',
                  MR: 'MR',
                },
              },
            },
            WORK_LIST: {
              TITLE: 'Work List',
              FIELDS: { KEY: 'ICE Work Key', ERROR: 'No Work with this Work Key' },
            },
            CONTRACTING_COMPANIES: {
              TITLE: 'Contracting Companies',
              FIELDS: {
                KEY: 'IPI Name Number or IPI Base Number',
                ERROR: 'No Legal Entity with this IPI Name Number or IPI Base Number',
                PARTY_ALREADY_IN_ADDITIONAL_COLLECTORS: 'This Party is already added in Additional Collectors',
              },
              CONTRACTING_COMPANIES_DETAILS: {
                MAX_CONTRACTING_COMPANIES_REACHED: 'You have reached the maximum of contracting companies available',
              },
              DIALOG: {
                TITLE: 'Bulk import contracting companies',
                ADD_MANUALLY: 'Add contracting companies manually',
                INFO: 'You have uploaded <<placeholder>> contracting companies',
                CONFIRM_TITLE: 'Confirm uploading contracting companies',
                CONFIRM_MESSAGE: 'You are uploading <<placeholder>> new contracting companies. Please confirm.',
                CANCEL_TITLE: 'Cancel uploading contracting companies',
                CANCEL_MESSAGE: 'Everything in this upload will be lost. Are you sure?',
                INVALID_MESSAGE: "<<placeholder>> contracting companies haven't passed validation.",
                DRAG_AND_DROP_TEXT: 'Drag and Drop your CSV file here or select',
                INVALID_FILE: 'The uploaded file "<<placeholder>>" isn\'t in CSV format.',
                BULK_UPLOAD_TO_MUCH_ENTRIES:
                  'The uploaded file "<<placeholder>>" contain more then 100 contracting companies.' + '\n You can upload maximum 100 contracting companies in one file',
                BULK_UPLOAD_SUCCESS_MESSAGE: 'File contain <<placeholder>> contracting companies.\n',
                BULK_UPLOAD_SUCCESS_MESSAGE_ALL_VALID: 'All of them are ready for import.',
                BULK_UPLOAD_SUCCESS_MESSAGE_SOME_VALID: '<<placeholder>> contracting companies are ready to import.\n',
                BULK_UPLOAD_SUCCESS_MESSAGE_SOME_INVALID: '<<placeholder>> contracting companies are failed and will not be imported.',
              },
            },
            ADDITIONAL_COLLECTORS: {
              TITLE: 'Additional Collectors',
              FIELDS: {
                KEY: 'IPI Name Number or IPI Base Number',
                ERROR: 'No Legal Entity with this IPI Name Number or IPI Base Number',
                PARTY_ALREADY_IN_CONTRACTING_COMPANIES: 'This Party is already added in Contracting Companies',
              },
              ADDITIONAL_COLLECTORS_DETAILS: {
                MAX_ADDITIONAL_COLLECTORS_REACHED: 'You have reached the maximum of additional collectors available',
              },
              DIALOG: {
                TITLE: 'Bulk import additional collectors',
                ADD_MANUALLY: 'Add additional collectors manually',
                INFO: 'You have uploaded <<placeholder>> additional collectors',
                CONFIRM_TITLE: 'Confirm uploading additional collectors',
                CONFIRM_MESSAGE: 'You are uploading <<placeholder>> new additional collectors. Please confirm.',
                CANCEL_TITLE: 'Cancel uploading additional collectors',
                CANCEL_MESSAGE: 'Everything in this upload will be lost. Are you sure?',
                INVALID_MESSAGE: "<<placeholder>> additional collectors haven't passed validation.",
                DRAG_AND_DROP_TEXT: 'Drag and Drop your CSV file here or select',
                INVALID_FILE: 'The uploaded file "<<placeholder>>" isn\'t in CSV format.',
                BULK_UPLOAD_TO_MUCH_ENTRIES:
                  'The uploaded file "<<placeholder>>" contain more then 100 additional collectors.' + '\n You can upload maximum 100 additional collectors in one file',
                BULK_UPLOAD_SUCCESS_MESSAGE: 'File contain <<placeholder>> additional collectors.\n',
                BULK_UPLOAD_SUCCESS_MESSAGE_ALL_VALID: 'All of them are ready for import.',
                BULK_UPLOAD_SUCCESS_MESSAGE_SOME_VALID: '<<placeholder>> additional collectors are ready to import.\n',
                BULK_UPLOAD_SUCCESS_MESSAGE_SOME_INVALID: '<<placeholder>> additional collectors are failed and will not be imported.',
              },
            },
          },
        },
        TERM: {
          TITLE: 'Term',
          FIELDS: {
            VALID_FROM: 'Valid From (YYYY-MM-DD)',
            VALID_TO: 'Valid To (YYYY-MM-DD)',
            TERRITORY: 'Territory',
            ERROR_VALID_TO_AFTER_VALID_FROM: 'Valid To must be the same or after Valid From',
          },
        },
        EXCLUSIONS: {
          TITLE: 'Exclusions',
          FIELDS: {
            NO_APPLICABLE: 'No applicable for Work List Type',
            WORKS: {
              TITLE: 'Works',
              FIELDS: {
                KEY: 'ICE Work Key',
                ERROR: 'No Work with this Work Key',
                NOT_STATUS_4_ERROR: 'Work cannot be added as source as it is not a Status 4 work',
              },
            },
            AGREEMENTS: {
              TITLE: 'Agreements',
              FIELDS: {
                KEY: 'ICE Agreement Key',
                ERROR: 'No Agreement with this Agreement Key',
              },
            },
          },
        },
        ADDITIONAL_FIELDS: {
          TITLE: 'Additional Fields',
          FIELDS: {
            NOTES: 'Notes',
          },
        },
      },
      COMMON: {
        ADD_SUCCESS: `Thanks! We have added your entry to the list`,
        DELETE_SUCCESS: `Thanks! We have deleted the entry from the list`,
        ERROR: `Attention! We have had problems to execute your request. Please try again or contact your support`,
        NO_CHANGES: `This item is already in the list.`,
      },
    },
    ORGANIZATIONS: {
      ITEM_TITLE: 'organization',
      ORGANIZATION: {
        TITLE: 'Organization',
        NAME: { NAME: 'Organization Name', INFO_TEXT: 'Organization Name' },
        ID: {
          NAME: 'Organization ID',
          INFO_TEXT: 'Organization ID',
          INVALID: 'There is already an organization for this {{type}}',
        },
        TYPE: {
          NAME: 'Organization Type',
          INFO_TEXT: 'Organization Type',
          PUBLISHER: 'Publisher',
          SOCIETY: 'Society',
          ICE: 'ICE',
          STAGE: 'STAGE',
        },
        SOCIETY: { NAME: 'Society', INFO_TEXT: 'Society' },
        PARENT_IPI: { NAME: 'Organization IPI Name Number', INFO_TEXT: 'Organization IPI Name Number' },
      },
      POPUP: {
        OK_MESSAGE: 'The submitted organization was successfully registered with the following organization number',
        KO_MESSAGE: `The submitted organization can't be registered. Please review the submitted information`,
        OK_MESSAGE_EDIT: `Organization was successfully updated`,
      },
    },
    REPORTS: {
      ITEM_TITLE: 'report',
      POPUP: {
        TITLE: 'Report creation',
        OK_MESSAGE: 'The submitted report was successfully registered.',
        KO_MESSAGE: `The submitted report can't be registered`,
        OK_MESSAGE_EDIT: 'The submitted report was successfully registered.',
        OK_MESSAGE_CLONE: 'The submitted report was successfully cloned.',
        CLONE_ERROR: 'Could not clone work because',
      },
      AGREEMENTS_LIST: {
        TITLE: 'Enter the IP base reference',
        IP_BASE: 'IP Base key',
        EXCLUDE: 'Exclude terminated agreements',
        ERROR_IP: 'IP Base key not exists',
        YES: 'Yes',
        NO: 'No',
      },
      REPORTS_LIST: {
        TITLE: 'List works with reference from agreement number',
        LABEL: 'Enter the ICE Agreement number',
        FIELD: 'ICE Agreement number',
      },
      IPS_SHARES: {
        TITLE_STEP_1: 'Enter either one ICE Work key or upload a list of references',
        REF_NUM_TYPE: 'Reference Number Type',
        REF_NUM: 'Reference Number',
        WORK_REF_TYPE: 'Work Reference Number Type',
        OR: 'OR',
        SELECT_ALL_WORKS: 'Select all works to IP base?',
        BTN_SELECT_CSV: 'Select CSV file for upload',
        TITLE_STEP_2: 'Work scope details',
        COUNTRY: 'Country',
        USAGE_DATE: 'Usage Date',
        DISTRIBUTION_DATE: 'Distribution Date',
        PR_DISTRIBUTING_SOCIETY: 'PR Distributing Society',
        MR_DISTRIBUTING_SOCIETY: 'MR Distributing Society',
        REPORTING_SOCIETY: 'Reporting Society',
        PR_IPI: 'PR IPI right',
        MR_IPI: 'MR IPI right',
        PAYMENT: 'Payment or Ownership',
        PAYMENT_VALUE: 'Payment',
        OWNERSHIP_VALUE: 'Ownership',
        SOCIETY_FOR_REF: 'Society for references',
        INCLUDE_ADDITIONAL: 'Include additional work references',
        YES: 'YES',
        NO: 'NO',
        YES_MIN: 'Yes',
        NO_MIN: 'No',
        INCLUDE_REPERTOIRE: 'Include repertoire',
        INCLUDE_FICHE: 'Include Fiche',
        INCLUDE_PERFORMER_INFORMATION: 'Include performer information',
        INCORRECT_FILE: 'The uploaded list is invalid. Try again.',
        ERROR_WORKEYS: 'Error validating work references',
        ERROR_NO_WORKEYS: 'Error not found work references',
        ERROR_WORK_KEY: `Error Work Key doesn't exist`,
        ERROR_AGREEMENT_NUMBER: `Error Agreement Number doesn't exist`,
        ERROR_IPI_NAME_NUMBER: `Error IPI Name Number doesn't exist`,
        ERROR_DATE_BEFORE_2000: `Date can't be before 2000-01-01`,
        ERROR_DISTRIBUTION_BEFORE_USAGE: 'Distribution date before usage date',
      },
      WORK_DETAILS_GENERATE_FICHE: {
        TITLE_STEP_1: 'Enter either one ICE Work key or upload a list of references',
        REF_NUM_TYPE: 'Reference Number Type',
        REF_NUM: 'Reference Number',
        WORK_REF_TYPE: 'Work Reference Number Type',
        OR: 'OR',
        SELECT_ALL_WORKS: 'Select all works to IP base?',
        BTN_SELECT_CSV: 'Select CSV file for upload',
        TITLE_STEP_2: 'Work scope details',
        COUNTRY: 'Country',
        USAGE_DATE: 'Usage Date',
        DISTRIBUTION_DATE: 'Distribution Date',
        PR_DISTRIBUTING_SOCIETY: 'PR Distributing Society',
        MR_DISTRIBUTING_SOCIETY: 'MR Distributing Society',
        REPORTING_SOCIETY: 'Reporting Society',
        PR_IPI: 'PR IPI right',
        MR_IPI: 'MR IPI right',
        PAYMENT: 'Payment or Ownership',
        PAYMENT_VALUE: 'Payment',
        OWNERSHIP_VALUE: 'Ownership',
        SOCIETY_FOR_REF: 'Society for references',
        INCLUDE_ADDITIONAL: 'Include additional work references',
        YES: 'YES',
        NO: 'NO',
        YES_MIN: 'Yes',
        NO_MIN: 'No',
        INCLUDE_PERFORMER_INFORMATION: 'Include performer information',
        INCORRECT_FILE: 'The uploaded list is invalid. Try again.',
        ERROR_WORKEYS: 'Error validating work references',
        ERROR_NO_WORKEYS: 'Error not found work references',
        ERROR_WORK_KEY: `Error Work Key doesn't exist`,
        ERROR_AGREEMENT_NUMBER: `Error Agreement Number doesn't exist`,
        ERROR_IPI_NAME_NUMBER: `Error IPI Name Number doesn't exist`,
        ERROR_DATE_BEFORE_2000: `Date can't be before 2000-01-01`,
        ERROR_DISTRIBUTION_BEFORE_USAGE: 'Distribution date before usage date',
      },
    },
    POPUP: {
      APPLY_CHANGES: 'Apply Changes',
      TITLE: 'Cancel new <<placeholder>>',
      MSG: 'Everything in this registration will be lost. Are you sure?',
      CANCEL_EDIT_TITLE: 'Cancel edit <<placeholder>>',
      CANCEL_EDIT_MSG: 'Any changes are not yet saved. Are you sure you want to cancel the edit <<placeholder>> process?',
      CANCEL: 'Cancel',
      DELETE: 'Delete',
      OK: 'Ok',
      CONFIRM: 'Confirm',
      CONFIRM_BULK: 'Confirm bulk import',
      SUMMARY: 'Summary',
      RESULTS: 'Results of submit',
      SENDING_TEXT: 'Sending',
      YES: 'Yes',
      NO: 'No',
      CLOSE: 'Close',
      DUPLICATE: 'Duplicate <<placeholder>>',
      NEW: 'Add new <<placeholder>>',
      CREATE_DUMMY_IP: 'Create dummy IP',
      ACCEPT: 'Accept',
      BACK: 'Back',
      RETRY: 'Retry',
      ERROR: 'Error',
      CUSTOM_ERRORS: {
        USERNAMEEXISTSEXCEPTION: 'An account with the email already exists.',
        DUPLICATE_KEY: 'The key is duplicated',
        SHARES_EXCEED_100_PERCENT_IN_WORK: `Your work update cannot be processed automatically as the total claims you are submitting are greater than 100%.
        Please adjust the claims and resubmit or raise an update request with ICE to update the work`,
      },
      MERGE_IP: {
        TITLE: 'Merge IPs',
        SUBTITLE: 'Please review the selected IPs for the merge request.',
        TARGET_IPI: 'Target IPI',
        UNIDENTIFIED_IPS: 'Selected unidentified IPs',
        CANCEL: 'Back',
        CONFIRM: 'Confirm merge',
        TOO_MANY_BASE_IPS_ERROR: 'INVALID DATA: There could only be 1 IP with ipiNameNumber',
        API_ERROR: 'A problem occurred. Please try again or contact your support',
      },
    },
    SEARCH_POPUP: {
      BT_SEARCH: 'Search',
      BT_CANCEL: 'Cancel',
    },
    REQUIRED: 'Field is required',
    VALIDATING_FIELD: ' Validating field ...',
    REPEATER_ERROR: 'There are errors in the list (Click to go)',
    REPEATER_SEARCH_TITLE: 'Find in list',
    REPEATER_REQUIRED: 'Field is required',
    MAX_ITEMS_REACHED: 'Max items reached',
    DATE_FORMAT_ERROR: 'Wrong date format was entered',
    DATE_DOES_NOT_EXIST: 'Date is incorrect or invalid',
    DATE_AFTER_1999_12_31: 'Date must be after 1999-12-31',
    SINCE_DATE_SHOULD_BE_BEFORE_TO_DATE: 'Date must be before {{toDateLabel}}',
    TO_DATE_SHOULD_BE_AFTER_SINCE_DATE: 'Date must be after {{sinceDateLabel}}',
    FILTER: 'Filter constraints are violated',
    'COUNTERCLAIM-NOTIFICATIONS_REGISTRATION': 'Counterclaim Notifications',
    'COUNTERCLAIM-NOTIFICATIONS': {
      TITLE: 'Counterclaim Notifications',
      ITEM_TITLE: 'Counterclaim Notifications',
      UPLOAD_DOCUMENTATION: {
        TITLE: 'Upload your CSV file with your emails',
        DOCUMENTS: {
          TITLE: 'Documents',
          DATE_TIME: 'Date',
          FILE_NAME: 'File Name',
          STATUS: 'Upload Status',
        },
        DRAG_DROP_TEXT: 'Drag and Drop or select a CSV file containing the emails you want to send counterclaim notifications.',
        CSV_REQUIRED_FORMAT: 'CSV file required format',
        CSV_REQUIRED_FORMAT_DESCRIPTION: 'Before uploading the CSV file please make sure it has 2 columns, ipi_no and email:',
        CSV_REQUIRED_FORMAT_DESCRIPTION_TEXT1: '-ipi_no: it needs to be an ipi name number or a 3-digits society code.',
        CSV_REQUIRED_FORMAT_DESCRIPTION_TEXT2: '-email: the email address of the contact to notify',
        NO_CONTENT: 'The chosen file has no content, please review and upload.',
      },
      POPUP: {
        OK_MESSAGE: 'The CSV file was successfully uploaded',
        KO_MESSAGE: 'The CSV file upload was failed. Please review the uploaded information',
      },
    },
    'BULK-UPDATES_REGISTRATION': 'Enter details of bulk update',
    'BULK-UPDATES': {
      TITLE: 'Bulk update',
      ITEM_TITLE: 'Bulk update',
      TYPE: {
        GROUP_LABEL: 'Select bulk update type',
        NAME: 'Bulk update type',
        IP_SWAP: {
          LABEL: 'IP Swap',
        },
        IP_TRANSFER: {
          LABEL: 'IP Transfer',
        },
        IP_TERMINATION: {
          LABEL: 'IP Termination',
        },
        PURPOSE: {
          LABEL: 'Work Purpose',
        },
        TITLES: {
          LABEL: 'Work Titles',
        },
        OTHER_PARTIES: {
          LABEL: 'Work Other Parties',
        },
        WORK_XREFS: {
          LABEL: 'Work Cross References (XREF)',
        },
        WORK_MATCHES: {
          LABEL: 'Work Matches',
        },
        WORK_BROADCAST_INFO: {
          LABEL: 'Work Broadcast Info',
        },
      },
      WORK_DETAILS: {
        GROUP_LABEL: 'Work details',
        EDIT: 'Edit',
        REMOVE: 'Remove',
        ADD: 'Add',
        MODE: 'Update mode',
        OLD_TITLE: 'Old title',
        OLD_TITLE_TYPE: 'Old title type',
        NEW_TITLE: 'New title',
        NEW_TITLE_TYPE: 'New title type',
        NEW_DURATION: 'New duration',
        IPI_NAME_NUMBER: 'IPI Name Number',
        BROADCAST_INFO: 'Broadcast Info',
        FEEDBACK: {
          TITLE_TYPE_NOT_ALLOWED: 'Title Type not allowed for selected Update Mode',
        },
      },
      WORK_NO_DETAILS: {
        GROUP_LABEL: 'No Work details needed',
      },
      IP_DETAILS: {
        GROUP_LABEL: 'Enter IP details',
        IPI_NAME_NUMBER: {
          LABEL: 'IPI Name Number (existing)',
          ERROR_MESSAGE: 'IPI Name Number provided could not be validated',
          SEARCH_PARTY: 'Search Party',
          IP_NAME: 'Ip Name',
        },
        PRIOR_ROYALTIES: 'Prior royalties',
        IPI_NAME_NUMBER_REPLACEMENT: 'IPI Name Number (replacement)',
        IPI_NAME_NUMBER_REPLACEMENT_SAME_ERROR: 'IPI name number for replacement should be different from existing one',
        IPI_PRIOR_ROYALTIES_DATE: 'Agreement start date',
        TERMINATION_DATE: 'Termination date',
        TERRITORIES: 'Territories',
        RIGHT_TYPES: 'Right types',
        POST_TERM_COLLECTION_DATE: 'Post term collection date',
        YES: 'Yes',
        NO: 'No',
      },
      ADDITIONAL_INFO: {
        GROUP_LABEL: 'Additional Info',
        COMMENT: 'Comment',
        NOTE: 'Note',
      },
      POPUP: {
        OK_MESSAGE: 'The Bulk update is being processed. Track the progress and any error messages on the dashboard.',
        KO_MESSAGE: 'The Bulk update process encountered some errors and was unable to complete successfully.',
      },
    },
  },
};
