import { DateTimeUtils, selectControlValue } from '@ice';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { IpNature } from 'config/constants/ips.constants';
import { SelectOptions } from 'config/stepper-builders/agreements/stepper-select-options';
import moment from 'moment';

export class DialogLayoutCreateIp {
  private optionsTypeOf: any[];
  private today = new Date();
  private dateOfBirth: string;
  private creationsTypeOf: any[];

  constructor(private translate: TranslateService) {
    const options = SelectOptions.getOptions(this.translate);
    this.optionsTypeOf = options.agreementParties.assignor_type;
    this.creationsTypeOf = options.creationClass.cration_class;
  }

  getLayout(isAssignee = false, typeDefaultValue = IpNature.LEGAL_ENTITY): FormlyFieldConfig[] {
    const options = SelectOptions.getOptions(this.translate);
    const birthName = 'dateOfBirth';
    const birthLabel = this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.FIELD_BIRTH');
    const birthDatepickerOptions = {
      filter: (date: Date) => moment(date).isBefore(this.today),
    };
    const birthExtraValidators = {
      date_before_today: {
        expression: c => {
          const controlValue = DateTimeUtils.getIndefiniteDate(c.value);
          return moment(controlValue).isSameOrBefore(this.today) || !controlValue;
        },
        message: (error, field: FormlyFieldConfig) => `${this.translate.instant('AGREEMENTS.ERRORS.DATE_BEFORE_TODAY')}`,
      },
    };
    const translate = this.translate;
    const birthExtraClass = 'ice-mat-form-field-pt-0';
    const birthExpressionProperties = {
      'model.dateOfBirth': (model: any) => {
        this.dateOfBirth = model.dateOfBirth;
      },
    };
    const birthDateChange = field => {
      const dateOfDeathControl = field.formControl.parent.get('dateOfDeath');
      const dateValue = selectControlValue(dateOfDeathControl);

      if (dateOfDeathControl && dateOfDeathControl.value) {
        dateOfDeathControl.markAsTouched();
        setTimeout(() => {
          dateOfDeathControl.setValue(dateValue);
        }, 300);
      }
    };

    const deathName = 'dateOfDeath';
    const deathLabel = this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.FIELD_DEATH');
    const deathDatepickerOptions = {
      filter: (date: Date) => (moment(date).isAfter(this.dateOfBirth) && moment(date).isSameOrBefore(this.today)) || null,
    };
    const deathExtraValidators = {
      date_before_today: {
        expression: c => {
          const controlValue = DateTimeUtils.getIndefiniteDate(c.value);
          return !c.value || DateTimeUtils.isDateSameOrBeforeNow(controlValue);
        },
        message: (error, field: FormlyFieldConfig) => `${this.translate.instant('AGREEMENTS.ERRORS.DATE_SAME_OR_BEFORE_TODAY')}`,
      },
      date_after_birth: {
        expression: c => {
          const controlValue = DateTimeUtils.getIndefiniteDate(c.value);
          return moment(controlValue).isAfter(this.dateOfBirth) || !controlValue;
        },
        message: (error, field: FormlyFieldConfig) => `${this.translate.instant('AGREEMENTS.ERRORS.DATE_AFTER_BIRTH_DATE')}`,
      },
    };
    const deathExtraClass = 'ice-mat-form-field-pt-0';

    const sinceFieldConfig = DatepickerUtils.getDatepickerField({
      key: birthName,
      label: birthLabel,
      datepickerOptions: birthDatepickerOptions,
      extraValidators: birthExtraValidators,
      translate,
      extraClass: birthExtraClass,
      expressionProperties: birthExpressionProperties,
      dateChange: birthDateChange,
    });

    const toFieldConfig = DatepickerUtils.getDatepickerField({
      key: deathName,
      label: deathLabel,
      datepickerOptions: deathDatepickerOptions,
      extraValidators: deathExtraValidators,
      translate,
      extraClass: deathExtraClass,
    });

    DatepickerUtils.createDatepickerRange({ translate, sinceFieldConfig, toFieldConfig });

    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1 ice-mat-form-field-pt-0',
            key: 'name',
            wrappers: ['wrapper-input-text'],
            type: 'ice-input',
            templateOptions: { type: 'text', required: true, placeholder: this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.FIELD_NAME`) },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1 ice-mat-form-field-pt-0',
            key: 'firstName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', required: false, placeholder: this.translate.instant(`AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.FIELD_FIRSTNAME`) },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [sinceFieldConfig],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [toFieldConfig],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            key: 'typeOf',
            type: 'select',
            className: 'flex-1 ice-mat-form-field-pt-0',
            defaultValue: typeDefaultValue,
            modelOptions: {
              updateOn: 'blur',
            },
            templateOptions: {
              attributes: { 'data-testid': 'select-claimant-type' },
              required: true,
              label: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.CLAIMANT_TYPE'),
              disabled: isAssignee,
              options: isAssignee ? options.agreementParties.assignee_type : options.agreementParties.assignor_type,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        hideExpression: this.creationsTypeOf.length <= 1,
        fieldGroup: [
          {
            key: 'creationClass',
            type: 'select',
            className: 'flex-1 ice-mat-form-field-pt-0',
            defaultValue: IpNature.TYPE_MW,
            modelOptions: {
              updateOn: 'blur',
            },
            templateOptions: {
              required: true,
              label: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.CREATION_CLASS'),
              options: this.creationsTypeOf,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            key: 'status',
            type: 'label',
            className: 'flex-1 ice-mat-form-field-pt-0',
            defaultValue: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.STATUS_ACTIVE'),
            templateOptions: { text: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.STATUS') },
          },
        ],
      },
    ];
  }
}
