import { SectionsConfig } from 'config/sections-config';
import { without } from 'lodash';
import { SelectCodeItem } from 'models/selectCodeItem';

export const MODE_OVERWRITE = 'OVERWRITE';
export const REQUEST_PARAM_NOT_FOUND = 'REQUEST_PARAM_NOT_FOUND';
export const ALL = 'ALL';
export const DEFAULT_LANGUAGE = 'EN';
export const EMPTY_RESULTSET = 'EmptyResultSet';
export const EMPTY_SELECT_ITEM: SelectCodeItem[] = [
  {
    value: undefined,
    label: '',
  },
];
export const COUNTRIES_DEFAULT_DATE = '2018-01-01';

export const MULTIPLE_SCROLL_CALLS_ERROR = (newScrollApiCall, currentScrollApiCall) =>
  `A DATATABLE IS DEFINING THE ${newScrollApiCall} SCROLL API CALL BUT ANOTHER APICALL HAS BEEN DEFINED ${currentScrollApiCall}`;

export const SOCIETY_PREFIX = 'SO';
export const PUBLISHER_PREFIX = 'PB';
export const ICE = 'ICE';
export const ICE_PREFIX = ICE;
export const IPI_PREFIX = 'IPI';
export const SIREF_PREFIX = 'SIREF';

export const MIN_START_DATE = '2000-01-01';
export const MIN_VALID_DATE = '2000-01-01';
export const END_DATE_NO_DISPLAY = '9999-12-31';
export const TERRITORY_END_DATE_NO_DISPLAY = '2999-12-31';
export const END_DATES_NO_DISPLAY = [END_DATE_NO_DISPLAY, TERRITORY_END_DATE_NO_DISPLAY];
export const END_DATE_ALIAS = 'Indefinite';
export const CUBE = 'CUBE';
export const CUBE_PREFIX = CUBE;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD - HH:mm:ss';

export const noteTypeBySection = {
  works: 'work',
  repertoires: 'repertoire',
  agreements: 'agreement',
  ips: 'party-name',
};

export const DEFAULT_CODE = 'GEN';

export const ICEADMIN_ORIGIN = 'ICEADMIN';

export const EMPTY_SEARCH_ERROR = 'EMPTY_SEARCH_ERROR';
export const DEFAULT_EXPORT_RETRY_TIMES = 3;

export const AUDIT_HISTORY_ADD_EVENT_DESCRIPTION = 'Added';
export const AUDIT_HISTORY_CREATED_EVENT_DESCRIPTION = 'Created';

export const ROLES_PERMISSIONS = {
  CAN_CREATE: {
    default: { roles: ['admin', 'editor', 'super'] },
    organizations: { roles: ['admin', 'super'] },
    'counter-claims': { roles: ['super', 'editor'], organizations: ['ICE:ICE'] },
  },
  CAN_EDIT: {
    default: { roles: ['admin', 'editor', 'super'] },
    organizations: { roles: ['admin', 'super'] },
    'counter-claims': { roles: ['super', 'editor'], organizations: ['ICE:ICE'] },
  },
  CAN_VIEW: {
    default: { roles: ['all'] },
    organizations: { roles: ['admin', 'super'] },
  },
};

export const ICE_MODELS_BY_API_SEGMENT = {
  works: 'work',
  repertoires: 'repertoire',
  agreements: 'agreement',
  ips: 'party',
};

export const ROLES = {
  super: 'super',
  admin: 'admin',
  viewer: 'viewer',
  editor: 'editor',
  restrictedViewer: 'restricted-viewer',
  counterclaim: 'counterclaim',
  publisherExtendedViewer: 'publisher-extended-viewer',
  editorEditSociety: 'editor-edit-society',
  societyAddMatch: 'add-match',
  societyIpMerge: 'ip-merge',
};

export const PREFIXES = {
  ice: 'ice-',
  society: 'so-',
  publisher: 'pb-',
};
export const ICE_SUPER = 'ice-super';
export const ICE_ADMIN = 'ice-admin';
export const ICE_VIEWER = 'ice-viewer';
export const ICE_EDITOR = 'ice-editor';
export const SO_VIEWER = 'so-viewer';
export const SO_RESTRICTED_VIEWER = 'so-restricted-viewer';
export const SO_ADD_MATCH = 'so-add-match';
export const SO_IP_MERGE = 'so-ip-merge';
export const SO_EDITOR_EDIT_SOCIETY = 'so-editor-edit-society';
export const SO_EDITOR = 'so-editor';
export const SO_ADMIN = 'so-admin';
export const PB_VIEWER = 'pb-viewer';
export const PB_EDITOR = 'pb-editor';
export const PB_ADMIN = 'pb-admin';
export const PB_COUNTERCLAIM = 'pb-counterclaim';
export const PB_PUBLISHER_EXTENDED_VIEWER = 'pb-publisher-extended-viewer';

export const GROUP_ICE_ALL = [ICE_SUPER, ICE_ADMIN, ICE_VIEWER, ICE_EDITOR] as const;
export const GROUP_ICE_VIEW = [ICE_SUPER, ICE_VIEWER, ICE_EDITOR] as const;
export const GROUP_SO_ALL = [SO_RESTRICTED_VIEWER, SO_ADD_MATCH, SO_IP_MERGE, SO_VIEWER, SO_EDITOR_EDIT_SOCIETY, SO_EDITOR, SO_ADMIN] as const;
export const GROUP_PB_ADMIN_EDITOR_VIEWER = [PB_ADMIN, PB_EDITOR, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER] as const;
export const GROUP_ICE_EDIT = [ICE_SUPER, ICE_ADMIN, ICE_EDITOR] as const;
export const GROUP_VIEWERS = [SO_RESTRICTED_VIEWER, SO_ADD_MATCH, SO_IP_MERGE, ICE_VIEWER, SO_VIEWER, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER] as const;
export const GROUP_ICE_SUPER_ADMIN = [ICE_SUPER, ICE_ADMIN] as const;
export const GROUP_ICE_SUPER_EDITOR = [ICE_SUPER, ICE_EDITOR] as const;
export const GROUP_ICE_SUPER_EDITOR_PUBLISHER_SOCIETY = [ICE_SUPER, ICE_EDITOR, PB_EDITOR, SO_EDITOR] as const;
export const GROUP_ICE_SUPER_AND_EDITORS = [ICE_SUPER, ICE_EDITOR, SO_EDITOR, PB_EDITOR, SO_EDITOR_EDIT_SOCIETY] as const;
export const GROUP_SO_PB_ADMIN = [SO_ADMIN, PB_ADMIN] as const;
export const GROUP_SUPER_ADMINS = [...GROUP_ICE_SUPER_ADMIN, ...[SO_ADMIN], ...[PB_ADMIN]] as const;
export const GROUP_EDIT_WORKS_DROPDOWN = [...GROUP_ICE_SUPER_EDITOR, ...[PB_EDITOR]] as const;
export const GROUP_EDIT_WORKS_AND_IPS = [...GROUP_ICE_SUPER_EDITOR, ...[PB_EDITOR], ...[SO_EDITOR]] as const;
export const GROUP_EDIT_WORKS_EDIT_SOCIETY = [...GROUP_EDIT_WORKS_AND_IPS, ...[SO_EDITOR_EDIT_SOCIETY]] as const;
export const GROUP_EDIT_EXTENDED_TOP_WORKS = [ICE_SUPER, ICE_EDITOR, SO_EDITOR] as const;
export const GROUP_EDIT_CLAUSES = [ICE_SUPER, ICE_EDITOR, SO_EDITOR, SO_EDITOR_EDIT_SOCIETY] as const;
export const GROUP_COUNTER_CLAIMS_DASHBOARD = [ICE_SUPER, SO_EDITOR, PB_EDITOR, PB_COUNTERCLAIM, SO_VIEWER, SO_RESTRICTED_VIEWER] as const;
export const GROUP_COUNTER_CLAIMS = [ICE_SUPER, ICE_EDITOR, SO_EDITOR, PB_EDITOR, PB_COUNTERCLAIM] as const;
export const GROUP_ICE_SUPER_VIEWER_EDITOR = [ICE_SUPER, ICE_VIEWER, ICE_EDITOR] as const;
export const GROUP_ICE_EDITOR_SO_EDITOR_EDIT_SOCIETY = [...GROUP_ICE_SUPER_EDITOR, ...[SO_EDITOR], ...[SO_EDITOR_EDIT_SOCIETY]] as const;
export const GROUP_ICE_SUPER_EDITOR_PB_EDITOR = [ICE_SUPER, ICE_EDITOR, PB_EDITOR] as const;
export const GROUP_SO_PB = [...[SO_EDITOR_EDIT_SOCIETY, SO_EDITOR, SO_ADMIN], ...[PB_ADMIN, PB_EDITOR]] as const;
export const GROUP_SO_PB_EDITORS = [PB_EDITOR, SO_EDITOR, SO_EDITOR_EDIT_SOCIETY] as const;
export const GROUP_SO_EDITORS = [SO_EDITOR_EDIT_SOCIETY, SO_EDITOR] as const;
export const GROUP_PB_ALL = [PB_VIEWER, PB_EDITOR, PB_ADMIN, PB_COUNTERCLAIM, PB_PUBLISHER_EXTENDED_VIEWER] as const;
export const GROUP_ALL_NO_SOCIETY_VIEWERS = [...GROUP_ICE_ALL, ...GROUP_PB_ALL, SO_EDITOR_EDIT_SOCIETY, SO_EDITOR, SO_ADMIN] as const;
/**
 * this can be used to remove specific roles from the permissions.
 * For example, if you want to remove only SO_EDITOR and PB_EDITOR from the roles that have access to the works_audit-history tab, you can do:
 * works_audit-history: _.without(GROUP_ALL, SO_EDITOR, PB_EDITOR);
 */
export const GROUP_ALL = [
  ICE_SUPER,
  ICE_ADMIN,
  ICE_VIEWER,
  ICE_EDITOR,
  SO_VIEWER,
  SO_RESTRICTED_VIEWER,
  SO_ADD_MATCH,
  SO_IP_MERGE,
  SO_EDITOR_EDIT_SOCIETY,
  SO_EDITOR,
  SO_ADMIN,
  PB_VIEWER,
  PB_EDITOR,
  PB_ADMIN,
  PB_COUNTERCLAIM,
  PB_PUBLISHER_EXTENDED_VIEWER,
] as const;

/*
 * FORMAT OF NAMING FEATURES *

   // ALLOWED NAVIGATION
  <section>_navbar

  // GENERAL BUTTONS
  <section>_new
  <section>_edit
  <section>_delete
  <section>_note
  <section>_counterclaim

  // EDITABLE TABS
  <section>_<tab>

  // EDITABLE TAB FEATURES
  <section>_<tab>_add_owned_by
  <section>_<tab>_add_owned_of

 */

export const FEATURES = {
  // NAVIGATION
  copyright_navbar: [...GROUP_ICE_ALL, ...GROUP_SO_ALL, ...GROUP_PB_ADMIN_EDITOR_VIEWER],
  administration_navbar: GROUP_SUPER_ADMINS,
  organizations_navbar: GROUP_ICE_SUPER_ADMIN,
  users_navbar: GROUP_SUPER_ADMINS,
  cases_navbar: [ICE_VIEWER, ...GROUP_COUNTER_CLAIMS, SO_VIEWER, SO_RESTRICTED_VIEWER],
  'agreement-conflict_navbar': GROUP_ICE_VIEW,
  activity_navbar: GROUP_ICE_SUPER_EDITOR,
  'counter-claims-actions_navbar': GROUP_ICE_VIEW,
  dashboard_navbar: GROUP_COUNTER_CLAIMS_DASHBOARD,
  reports_navbar: GROUP_ICE_SUPER_EDITOR,
  'bulk-updates_navbar': GROUP_ICE_SUPER_EDITOR,
  repertoires_navbar: without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, SO_RESTRICTED_VIEWER, SO_EDITOR),
  'counterclaim-notifications_navbar': [ICE_SUPER, SO_EDITOR],
  // AGREEMENTS
  agreements_new: GROUP_ICE_SUPER_EDITOR,
  agreements_edit: GROUP_ICE_SUPER_EDITOR,
  agreements_note: GROUP_ICE_SUPER_EDITOR,
  agreements_alternative_update: [ICE_SUPER, ICE_EDITOR, PB_EDITOR],
  agreements_new_agreement_conflict: GROUP_ICE_SUPER_EDITOR,
  agreements_add_retention_works: [ICE_SUPER],
  agreements_linked_writers_edit: GROUP_ICE_SUPER_EDITOR,
  agreements_add_works: GROUP_ICE_SUPER_EDITOR,
  agreements_counterclaim_icon: [...GROUP_ICE_SUPER_EDITOR, SO_VIEWER],
  'agreements_audit-history': without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER),
  'agreement-conflict_bt_new': GROUP_ICE_SUPER_EDITOR,
  'agreement-conflict_edit': GROUP_ICE_SUPER_EDITOR,
  agreements_delete_xrefs: GROUP_ALL_NO_SOCIETY_VIEWERS,
  // AGREEMENT GROUP
  'agreement-group_new': GROUP_ICE_EDIT,
  'agreement-group_edit': GROUP_ICE_EDIT,
  'agreement-group_audit-history': without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  // WORKS
  works_new: [...GROUP_ICE_SUPER_EDITOR, PB_EDITOR, SO_EDITOR_EDIT_SOCIETY],
  works_edit: GROUP_EDIT_WORKS_DROPDOWN,
  works_edit_by_society: [SO_EDITOR],
  works_clone: [SO_EDITOR_EDIT_SOCIETY, ...GROUP_ICE_SUPER_EDITOR, PB_EDITOR],
  works_note: GROUP_ICE_SUPER_EDITOR,
  works_new_counterclaim: GROUP_ICE_SUPER_EDITOR,
  works_new_claim_read: GROUP_ICE_SUPER_EDITOR,
  works_can_bulk_update: GROUP_ICE_SUPER_EDITOR,
  // WORKS TABS EDITABLES
  works_new_claim: [ICE_SUPER, ICE_EDITOR, PB_EDITOR],
  works_edit_claim: [ICE_SUPER, ICE_EDITOR, PB_EDITOR],
  works_edit_all_claim: [...GROUP_ICE_SUPER_EDITOR, ...GROUP_SO_PB_EDITORS],
  works_details_titles: GROUP_EDIT_WORKS_AND_IPS,
  works_details_xrefs: [...GROUP_ICE_SUPER_EDITOR, PB_EDITOR],
  works_details_societyMarkers: [...GROUP_ICE_SUPER_EDITOR, PB_EDITOR],
  works_details_duration: GROUP_EDIT_WORKS_AND_IPS,
  works_details_origin: GROUP_EDIT_WORKS_AND_IPS,
  works_details_textMusicRelationship: GROUP_EDIT_WORKS_AND_IPS,
  works_details_lyricAdaption: GROUP_EDIT_WORKS_AND_IPS,
  works_details_purpose: GROUP_EDIT_WORKS_AND_IPS,
  works_details_genre: GROUP_EDIT_WORKS_AND_IPS,
  works_details_language: GROUP_EDIT_WORKS_AND_IPS,
  works_details_musicArrangement: GROUP_EDIT_WORKS_AND_IPS,
  works_details_category: GROUP_EDIT_WORKS_AND_IPS,
  works_details_publicationDate: GROUP_EDIT_WORKS_AND_IPS,
  works_details_repertoires_tab: [...GROUP_ICE_ALL, PB_EDITOR, PB_ADMIN, PB_COUNTERCLAIM, SO_VIEWER, SO_ADMIN, PB_PUBLISHER_EXTENDED_VIEWER] as const,
  works_details_notes_tab: [...GROUP_ICE_ALL, PB_EDITOR, PB_ADMIN, PB_COUNTERCLAIM, SO_ADMIN] as const,
  works_detail_submitted_claims_tab: without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  works_detail_all_claims_tab: without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  'works_detail_conflicts-cc_tab': without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  works_details_data: without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  works_details_manuscript: without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  works_repertoires: GROUP_EDIT_WORKS_AND_IPS,
  works_other_parties: [...GROUP_EDIT_WORKS_AND_IPS],
  works_society_attributes: GROUP_ICE_EDITOR_SO_EDITOR_EDIT_SOCIETY,
  works_terms_of_protection: GROUP_EDIT_WORKS_EDIT_SOCIETY, // For Share Picture Add ext IP Protection button and Extended ToP Tab
  works_terms_of_protection_works: GROUP_EDIT_EXTENDED_TOP_WORKS,
  works_ip_clauses_read: [...GROUP_EDIT_CLAUSES, SO_RESTRICTED_VIEWER, ICE_VIEWER, SO_VIEWER, ...GROUP_SUPER_ADMINS, ...GROUP_EDIT_WORKS_EDIT_SOCIETY] as const,
  works_ip_clauses_edit: GROUP_ICE_SUPER_EDITOR,
  works_details_source_works_delete_all: GROUP_ICE_SUPER_EDITOR_PUBLISHER_SOCIETY,
  works_details_source_works_society: [SO_EDITOR],
  works_details_source_works_publisher: [PB_EDITOR],
  works_delete_source_works: GROUP_ICE_SUPER_EDITOR,
  works_all_claims_counterclaim: GROUP_ICE_SUPER_EDITOR,
  works_show_edit_work_message: GROUP_SO_PB,
  work_source_works_add_works_of_all_statuses: GROUP_ICE_SUPER_EDITOR,
  work_source_works_add_works_of_status_4: [...GROUP_ICE_SUPER_EDITOR, SO_ADD_MATCH] as const,
  work_source_works_edit_work_relations: GROUP_ICE_SUPER_EDITOR,
  works_details_add_enquiry: GROUP_SO_PB,
  works_activity: [...GROUP_ICE_VIEW, SO_VIEWER, SO_RESTRICTED_VIEWER, SO_EDITOR] as const,
  works_activity_value_read: GROUP_ICE_VIEW,
  works_conflicts_list_value_read: [...GROUP_ICE_ALL, ...GROUP_PB_ALL, SO_EDITOR_EDIT_SOCIETY, SO_ADMIN],
  // INSTRUMENTS
  work_instruments: GROUP_ICE_SUPER_EDITOR_PB_EDITOR,
  // IP
  ips_note: GROUP_ICE_SUPER_EDITOR,
  ips_edit: GROUP_ICE_SUPER_EDITOR,
  ips_clauses_read: [...GROUP_EDIT_CLAUSES, SO_RESTRICTED_VIEWER, ICE_VIEWER, SO_VIEWER, ...GROUP_SUPER_ADMINS, ...GROUP_EDIT_WORKS_EDIT_SOCIETY],
  ips_clauses_edit: GROUP_EDIT_CLAUSES,
  ips_relations_edit: GROUP_ICE_SUPER_EDITOR,
  ips_relations_read: [...GROUP_ICE_ALL, SO_EDITOR, SO_VIEWER, SO_RESTRICTED_VIEWER] as const,
  ips_works_share_picture_audit_info_read: GROUP_ICE_SUPER_VIEWER_EDITOR,
  ips_transfer_works: GROUP_ICE_SUPER_EDITOR,
  ips_merge: [...GROUP_ICE_SUPER_EDITOR, SO_IP_MERGE] as const,
  'ips_audit-history': without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  ips_agreements: without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  'ips_agreement-chains': without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  ips_xref: without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  ips_works: without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  'ips_alternative-names': without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  'ips_linked-publishers': without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  'ips_linked-writers': without(GROUP_ALL, PB_VIEWER, PB_PUBLISHER_EXTENDED_VIEWER, PB_COUNTERCLAIM),
  // REPERTOIRES
  repertoires_new: GROUP_ICE_SUPER_EDITOR,
  repertoires_edit: GROUP_ICE_SUPER_EDITOR,
  repertoires_note: GROUP_ICE_SUPER_EDITOR,
  // ORGANIZATIONS
  organizations_new: GROUP_ICE_SUPER_ADMIN,
  organizations_edit: GROUP_ICE_SUPER_ADMIN,
  organizations_delete: GROUP_ICE_SUPER_ADMIN,
  // USERS
  users_new: GROUP_SUPER_ADMINS,
  users_edit: GROUP_SUPER_ADMINS,
  users_delete: GROUP_SUPER_ADMINS,
  users_activate: GROUP_SUPER_ADMINS,
  create_dummy_ip: GROUP_ICE_SUPER_EDITOR_PUBLISHER_SOCIETY,
  // REPORTS
  reports_new: [ICE_SUPER, ICE_EDITOR, SO_EDITOR],
  reports_edit: [],
  reports_access: [ICE_SUPER, ICE_EDITOR, SO_EDITOR],
  // COUNTERCLAIMS
  'counter-claims_edit': GROUP_ICE_SUPER_EDITOR,
  'counter-claims_details_support': GROUP_COUNTER_CLAIMS,
  'counter-claims_details_reduce': GROUP_COUNTER_CLAIMS,
  'counter-claims_details_withdraw': GROUP_COUNTER_CLAIMS,
  'counter-claims_details_resolve': GROUP_ICE_SUPER_EDITOR,
  'counter-claims_detail_search': GROUP_ICE_VIEW,
  'counter-claims_tab_actions': GROUP_ICE_ALL,
  'view-counter-claims_detail_actions': GROUP_ALL_NO_SOCIETY_VIEWERS,
  'counter-claims_download_documents': [...GROUP_ICE_EDIT, SO_EDITOR, PB_COUNTERCLAIM, PB_EDITOR],
  'counter-claims_reject_documents': [...GROUP_ICE_EDIT, SO_EDITOR],
  'counter-claims_dashboard_cards': [...GROUP_ICE_ALL, ...GROUP_PB_ALL],
  view_history_notes: [...GROUP_ICE_ALL, PB_EDITOR, PB_ADMIN, SO_EDITOR_EDIT_SOCIETY, SO_ADMIN],
  'counterclaim-notifications_new': [ICE_SUPER, SO_EDITOR],
};

export const NAV_PERMISSIONS = {
  users: 'administration_navbar',
  organizations: 'organizations_navbar',
  activity: 'cases_navbar',
  reports: 'reports_navbar',
};

export const CREATORS = ['A', 'AD', 'AR', 'C', 'CA', 'SA', 'SR', 'TR'];

export const CREATOR_ROLES = [
  {
    label: 'A',
    value: 'A',
    labelFull: 'Author',
  },
  {
    label: 'AD',
    value: 'AD',
    labelFull: 'Adaptor',
  },
  {
    label: 'AR',
    value: 'AR',
    labelFull: 'Arranger',
  },
  {
    label: 'C',
    value: 'C',
    labelFull: 'Composer',
  },
  {
    label: 'CA',
    value: 'CA',
    labelFull: 'Composer Author',
  },
  {
    label: 'E',
    value: 'E',
    labelFull: 'Original Publisher',
  },
  {
    label: 'SA',
    value: 'SA',
    labelFull: 'Sub Author',
  },
  {
    label: 'SE',
    value: 'SE',
    labelFull: 'Sub-publisher',
  },
  {
    label: 'SR',
    value: 'SR',
    labelFull: 'Sub Arranger',
  },
  {
    label: 'TR',
    value: 'TR',
    labelFull: 'Translator',
  },
];

export const NOT_AVAILABLE = 'N/A';

export const AUTO_SEARCH_SECTIONS = [
  SectionsConfig.COUNTERCLAIMS.name,
  SectionsConfig.COUNTERCLAIMS_ACTIONS.name,
  SectionsConfig.CONFLICTS.name,
  SectionsConfig.AGREEMENTCONFLICT.name,
  SectionsConfig.REPORTS.name,
];

export const nextKeys = ['ArrowRight', 'ArrowDown'];
export const previousKeys = ['ArrowLeft', 'ArrowUp'];

export enum BooleanType {
  true = 'true',
  false = 'false',
}

export const WorkStatusType = {
  '1': '1',
  '2': '2',
  '4': '4',
};

export enum ApiParams {
  ORG_PARTY_NAMES = 'orgPartyNames',
  FOLLOW_REDIRECTS = 'followRedirects',
  INCLUDE = 'include',
}

export enum OriginType {
  ORI = 'ORI',
  MOD = 'MOD',
}

export enum PurposeType {
  COM = 'COM',
  FIL = 'FIL',
  GEN = 'GEN',
  LIB = 'LIB',
  MUL = 'MUL',
  RAD = 'RAD',
  TEL = 'TEL',
  THR = 'THR',
  VID = 'VID',
  FIB = 'FIB',
  FLL = 'FLL',
  FIT = 'FIT',
  FIV = 'FIV',
  FIR = 'FIR',
  TEB = 'TEB',
  TLL = 'TLL',
  TET = 'TET',
  TEV = 'TEV',
  TER = 'TER',
}

export enum EditType {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
}
/**
 * This magic number is intended to multiply the offsetHeight of our datatables.
 * It is used to trigger the scroll event before the user reaches the end of the table, so it will be triggered
 * when we still need to scroll a distance (ratio of the height of the table) to reach the end of the invisible results
 *
 * Example: if the table has 1000px of scrollHeight and the offsetHeight is 500px, when setting scrollTriggerRatio to 1.4,
 * the scroll event will be triggered when the user scrolls to 200px (40% of 500px) before the end of the table, so it will be triggered
 * when the user scrolls 800px (out of total 1000px) of the table scrollHeight:
 * ```js
 * if(Math.round(target.scrollTop + SCROLL_TRIGGER_RATIO * target.offsetHeight) >= target.scrollHeight) {
 * // trigger event to get more results
 * }
 * ```
 */

export const SCROLL_TRIGGER_RATIO = 1.4;

export const HASH_SEPARATOR = '→'; // needs to be different than `/`, as it is used in IPs like `SONY/ATV`
