import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ClaimantUtils, ClaimsUtils, getPostTermCollectionInputs, isNotValidPrimitiveNumerical, SHARES_TYPE_CLAIM, SharesError, TerritoryUtils } from '@ice';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { END_DATE_ALIAS, END_DATE_NO_DISPLAY } from 'config/constants/global.constants';
import { mrRights, syRight } from 'config/constants/ips.constants';
import {
  ALL_RIGHTS,
  ERROR_NO_ENOUGH_MR_RIGHTS,
  ERROR_NO_ENOUGH_PR_RIGHTS,
  ERROR_NO_NEGATIVE_VALUE,
  ERROR_ONLY_2_DECIMALS,
  ERROR_TERRITORY_IS_EMPTY,
  ERROR_TERRITORY_NOT_EXISTS,
  ERROR_TOTAL_100,
  ERROR_TOTAL_MORE_THAN_100,
  FIRST_VALIDATOR_REQUEST_DELAY,
  RightTypes,
  WORLD,
} from 'config/constants/shares.constants';
import { DialogClaimNode } from 'config/dialog-builders/dialog-claim-node';
import { DialogSharesUsesTypes } from 'config/dialog-builders/dialog-shares-uses-types';
import { concat, find, get, keys, remove } from 'lodash';
import moment from 'moment';
import { Subject, Subscription, interval, of } from 'rxjs';
import { debounce, filter, map, take, withLatestFrom } from 'rxjs/operators';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import * as fromForm from 'store/form';
import { SharesValidator } from '../agreements/steps/validators';

export class SharesStepType {
  public form: FormGroup;
  public msgValidationControl: AbstractControl;
  public errors = new Array<SharesError>();
  private territories = new Array<string>();
  private termTerritoryCodes = new Array<number>();
  private territoriesSubscription: Subscription;
  private termTerritoryCodesSubscription: Subscription;
  public validationErrors: Array<SharesError>;
  public currentErrorMsg = '';
  private fieldSharesSubscription: Subscription;
  private totalSharesValueSubscription: Subscription;
  private totalSharesValue = 0;
  public editClaim;
  private parsedShares = [];
  private claimsSubscription: Subscription;
  public dialogClaimRef: DialogClaimNode;
  private startDate: Date;
  private endDate: Date;

  constructor(
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected fieldValidatorService: FieldValidatorService,
    protected store: Store<fromRoot.RootState>,
    protected claimsType?: string,
    protected claimShareType?: string,
    protected setLayout?: (layout) => void,
    protected rightsIncludeView$?: Subject<any>,
  ) {
    this.totalSharesValueSubscription = this.store
      .select(fromRoot.getManuscriptData)
      .subscribe(data => data && data.forEach(manuscript => (this.totalSharesValue += +manuscript.pr.split('%')[0].trim() + +manuscript.mr.split('%')[0].trim())));
    this.validationErrors = SharesValidator.getSharesValidationErrors(this.translate);
  }

  public getRepeatSectionFormConfig(isClaim = true, onlyBasicRows = false, addOwnershipShares = false, onlyAllRights = false): FormlyFieldConfig {
    return {
      key: this.claimShareType || 'shares',
      type: 'shares-repeat',
      templateOptions: {
        sharesType: SHARES_TYPE_CLAIM,
        onlyAllRights,
        wrapperClass: 'ice-share-col-w hide-overflow-texts',
        clickInclusionButton: (rightType, currentIncluded, updateUsageTypes) =>
          !!this.isWorkClaimScreen()
            ? (this.rightsIncludeView$.next({ rightType, currentIncluded, updateUsageTypes }), this.setLayout(1))
            : this.dialogClaimRef
            ? this.dialogClaimRef.goToRightsLayout(rightType, currentIncluded, updateUsageTypes)
            : DialogSharesUsesTypes.showInclusionPopup(this.dialog, this.translate, rightType, currentIncluded, updateUsageTypes),
        btSelectUsageTypes: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.MODIFY_USAGE_TYPES'),
        tooltipRemove: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.REMOVE_COLUMN'),
        tooltipClone: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.CLONE_COLUMN'),
        popup: {
          title: this.translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.TITLE'),
          message: this.translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.MESSAGE'),
          ok: this.translate.instant('POPUP.OK'),
          ko: this.translate.instant('POPUP.CANCEL'),
          submitLabel: this.translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.BT_INCLUDE'),
          button1Label: this.translate.instant('AGREEMENTS.SHARES.USES_TYPES.POPUP.BT_CANCEL'),
        },
        rowLabels: this.fieldValidatorService.isClaimRoleSubPublisher.pipe(
          map(value => [
            ...[
              {
                label: this.translate.instant('AGREEMENTS.SHARES.TABLE.COL_TITLE_RIGHT_TYPE'),
                info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.RIGHT_TYPE'),
              },
              ...(addOwnershipShares && !value
                ? [
                    {
                      label: this.translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_OWNERSHIP_SHARE'),
                      info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.OWNERSHIP_SHARES'),
                    },
                    {
                      label: this.translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_COLLECTION_SHARE'),
                      info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.COLLECTION_SHARES'),
                    },
                  ]
                : [
                    {
                      label: this.translate.instant(!isClaim ? 'AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_COLLECTION_SHARE' : 'AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_SHARE'),
                      info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.SHARES'),
                    },
                  ]),
              {
                label: this.translate.instant('AGREEMENTS.SHARES.TABLE.SHARES_TERRITORY'),
                info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.SHARES'),
              },
            ],
            ...(this.isWorkClaimScreen()
              ? [
                  {
                    label: this.translate.instant('AGREEMENTS.SHARES.TABLE.COL_TITLE_START_DATE'),
                    info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.DATES'),
                    classes: 'ice-mb-14-i',
                  },
                  {
                    label: this.translate.instant('AGREEMENTS.SHARES.TABLE.COL_TITLE_END_DATE'),
                    info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.DATES'),
                    classes: 'ice-mb-14-i',
                  },
                  {
                    label: this.translate.instant('AGREEMENTS.SHARES.TABLE.COL_TITLE_POSTTERM_VALUE'),
                    info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.SHARES'),
                  },
                  {
                    label: this.translate.instant('AGREEMENTS.SHARES.TABLE.COL_TITLE_POSTTERM_DATE'),
                    info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.DATES'),
                    classes: 'ice-mb-14-i',
                  },
                  {
                    label: this.translate.instant('AGREEMENTS.SHARES.TABLE.COL_TITLE_PRIOR_ROYALTIES_DATE'),
                    info: this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.SHARES'),
                    classes: 'ice-mb-14-i',
                  },
                ]
              : []),
            ...[
              {
                label: this.translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_INCLUSION'),
                info: onlyAllRights
                  ? this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.INCLUSION_READONLY')
                  : this.translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.INCLUSION'),
              },
            ],
          ]),
        ),
        addButtons: {
          addMechanical: this.translate.instant('AGREEMENTS.SHARES.BOTTOM_BUTTONS.ADD_MECHANICAL'),
          addPerforming: this.translate.instant('AGREEMENTS.SHARES.BOTTOM_BUTTONS.ADD_PERFORMING'),
        },
        showButtons: this.store.pipe(
          select(fromRoot.getCopyrightWork),
          map(detail => !(detail && detail.editClaim && detail.editClaim.length)),
        ),
      },
      hooks: {
        onInit: field => {
          this.form = field.form;
          if (isClaim) {
            this.fillDataInEditClaimMode();
            // we do changes in the form and validation goes crazy. Starts as valid and some values being updated are setting the form
            // to an invalid state, and not triggering the validation or updating the redux state. This is a workaround to update the state in redux
            field.formControl.valueChanges.pipe(debounce(() => interval(500))).subscribe(() => {
              this.store.dispatch(new fromForm.SetIsValidAndChangedForm(field.form.controls.msgValidation?.status === 'VALID'));
            });
          }
          this.fieldSharesSubscription = field.formControl.valueChanges.subscribe(data => {
            this.generalSharesValidator(field.formControl as FormControl, field, isClaim, false);
          });
          if (this.form && this.form.get('msgValidation')) {
            this.msgValidationControl = this.form.get('msgValidation');
          }
          const controls = get(this.form, 'parent.controls[1]');
          const territoriesFieldControl = controls?.get('term_territory');
          this.territories = territoriesFieldControl && territoriesFieldControl.value.split(',');

          const termTerritoryCodesFieldControl = controls?.get('term_territory_codes');
          this.termTerritoryCodes = termTerritoryCodesFieldControl && termTerritoryCodesFieldControl.value;

          this.territoriesSubscription =
            territoriesFieldControl &&
            territoriesFieldControl.valueChanges.subscribe(d => {
              let termTerritory = territoriesFieldControl.value;
              this.territories = (termTerritory && termTerritory.split(',')) || [];

              if (SharesValidator.checkInitialStateForClaim(this.form)) {
                termTerritory = this.territories.map(t => t.trim()).join(',');
                if (termTerritory !== WORLD) {
                  this.form.controls['shares']['controls'].map(element => {
                    element['controls']['territory'].setValue(termTerritory);
                  });
                }
              }
            });

          const parentFormTermTerritoryCodes = controls?.get('term_territory_codes');
          this.termTerritoryCodesSubscription =
            parentFormTermTerritoryCodes &&
            parentFormTermTerritoryCodes.valueChanges.subscribe(codes => {
              if (codes) {
                this.termTerritoryCodes = codes;
              }
            });
        },
        onDestroy: () => {
          if (this.territoriesSubscription) {
            this.territoriesSubscription.unsubscribe();
          }
          if (this.termTerritoryCodesSubscription) {
            this.termTerritoryCodesSubscription.unsubscribe();
          }
          if (this.fieldSharesSubscription) {
            this.fieldSharesSubscription.unsubscribe();
          }
          if (this.totalSharesValueSubscription) {
            this.totalSharesValueSubscription.unsubscribe();
          }
          if (this.claimsSubscription) {
            this.claimsSubscription.unsubscribe();
          }
        },
      },
      fieldArray: {
        fieldGroupClassName: 'ice-share-col-w datepicker-max-height',
        fieldGroup: concat(this.getBasicFields(onlyBasicRows, addOwnershipShares), this.getExtraFieldsConfig(onlyBasicRows), this.getInclusionField(onlyAllRights)),
      },
    };
  }

  getBasicFields(onlyBasicRows, addOwnershipShares): FormlyFieldConfig[] {
    const rightType: FormlyFieldConfig[] = [
      {
        className: 'display-flex-row-justify-left',
        type: 'label',
        defaultValue: 'Mechanical',
        key: 'type',
        modelOptions: { updateOn: 'submit' },
        templateOptions: { class: 'ice-bold' },
      },
    ];
    const ownershipShares: FormlyFieldConfig[] = addOwnershipShares
      ? [
          {
            fieldGroupClassName: 'display-flex-row-justify-left',
            fieldGroup: [
              {
                className: 'ice-form-field-infix',
                type: 'input',
                key: 'ownershipShare',
                defaultValue: 0,
                modelOptions: { updateOn: 'change' },
                templateOptions: {
                  attributes: { 'data-testid': 'input-ownership-share' },
                  type: 'number',
                  min: 0,
                  max: 100,
                  required: true,
                },
                expressionProperties: {
                  'templateOptions.required': model => addOwnershipShares,
                  'templateOptions.disabled': model => !addOwnershipShares,
                },
                hooks: {
                  onInit: field => {
                    this.fieldValidatorService.isClaimRoleSubPublisher.subscribe(value => {
                      if (value) {
                        field.hide = true;
                      } else {
                        field.hide = false;
                      }
                    });
                    this.onInitShareField(field);
                  },
                  onDestroy: field => this.onDestroyShareField(field),
                },
              },
              {
                className: 'ice-form-field-infix ice-form-field-infix-edge',
                template: '%',
                hooks: {
                  onInit: field => {
                    this.fieldValidatorService.isClaimRoleSubPublisher.subscribe(value => {
                      if (value) {
                        field.hide = true;
                      } else {
                        field.hide = false;
                      }
                    });
                  },
                },
              },
            ],
          },
        ]
      : [];

    const sinceFieldConfig = DatepickerUtils.getDatepickerField({
      key: 'startDate',
      label: this.translate.instant('CLAIM.SIMPLY_START_DATE'),
      translate: this.translate,
      extraClass: 'not-type-field claim-shares-date ice-px-0',
      datepickerOptions: {
        filter: (date: Date) => (!this.endDate ? true : moment(date).isBefore(this.endDate)),
      },
      expressionProperties: {
        'templateOptions.datepickerOptions.startDate': model => ((this.startDate = model.startDate), (this.endDate = model.endDate)),
      },
      hooks: {
        onInit: field => {
          if (!this.isWorkClaimScreen() || onlyBasicRows) {
            field.hide = true;
          }
        },
      },
      dateChange: (field, value) => {
        field.form.controls['endDate'].updateValueAndValidity();
      },
    });
    const toFieldConfig = DatepickerUtils.getDatepickerField({
      key: 'endDate',
      label: this.translate.instant('CLAIM.SIMPLY_END_DATE'),
      translate: this.translate,
      extraClass: 'not-type-field claim-shares-date ice-px-0',
      datepickerOptions: {
        filter: (date: Date) => (!this.startDate ? true : moment(date).isAfter(this.startDate)),
      },
      hooks: {
        onInit: field => {
          if (!this.isWorkClaimScreen() || onlyBasicRows) {
            field.hide = true;
          }
        },
      },
    });
    DatepickerUtils.createDatepickerRange({ translate: this.translate, sinceFieldConfig, toFieldConfig });
    const mainFields: FormlyFieldConfig[] = [
      {
        fieldGroupClassName: 'display-flex-row-justify-left',
        fieldGroup: [
          {
            className: 'ice-form-field-infix',
            type: 'input',
            key: 'share',
            defaultValue: 0,
            modelOptions: { updateOn: 'change' },
            templateOptions: {
              attributes: { 'data-testid': 'input-share' },
              type: 'number',
              min: 0,
              max: 100,
              required: true,
            },
            expressionProperties: {
              'templateOptions.required': model => !this.editClaim,
              'templateOptions.disabled': model => this.editClaim,
            },
            hooks: {
              onInit: field => this.onInitShareField(field),
              onDestroy: field => this.onDestroyShareField(field),
            },
          },
          {
            className: 'ice-form-field-infix ice-form-field-infix-edge',
            template: '%',
          },
        ],
      },
      {
        className: 'ice-form-field-infix-no-percentage shares-territory-input',
        type: 'ice-input',
        key: 'territory',
        modelOptions: { updateOn: 'blur' },
        defaultValue: '2WL',
        templateOptions: {
          codes: [],
        },
        expressionProperties: {
          'templateOptions.required': model => !this.editClaim,
          'templateOptions.disabled': model => this.editClaim,
        },
        hooks: {
          onInit: field => {
            const form = field.form;
            const controls = get(this.form, 'parent.controls[1]');
            if (controls?.get('term_territory')) {
              field.formControl.setValue(controls.get('term_territory').value);
            }

            field.templateOptions.bindedFieldCodes = form.controls.codes;
            field.templateOptions.subscriptionChanges = field.formControl.valueChanges.subscribe(territory => {
              if (!this.editClaim) {
                if (territory && territory.length && territory.length === 0) {
                  this.addError(ERROR_TERRITORY_IS_EMPTY, field.id);
                } else {
                  this.removeError(ERROR_TERRITORY_IS_EMPTY, field.id);
                }
              }
            });
          },
          onDestroy: field => {
            this.removeError(ERROR_TERRITORY_IS_EMPTY, field.id);
            if (field && field.templateOptions && field.templateOptions.subscriptionChanges) {
              field.templateOptions.subscriptionChanges.unsubscribe();
            }
          },
        },
        asyncValidators: {
          territoryValidator: {
            expression: (control, field) => {
              if (this.editClaim) {
                return of(true);
              }
              if (this.termTerritoryCodes && this.termTerritoryCodes.length > 0) {
                return this.territoryValidator(control, field);
              }
              return new Promise(resolve => setTimeout(resolve, FIRST_VALIDATOR_REQUEST_DELAY)).then(
                () => this.fieldValidatorService.useValidatorOnTouch(field) || this.territoryValidator(control, field),
              );
            },
          },
        },
      },
      ...TerritoryUtils.getTerritoryHiddenFields(this.fieldValidatorService),
      sinceFieldConfig,
      toFieldConfig,
    ];
    return concat(rightType, ownershipShares, mainFields);
  }

  getExtraFieldsConfig(onlyBasicRows): FormlyFieldConfig[] {
    return onlyBasicRows
      ? []
      : [
          ...getPostTermCollectionInputs({
            translate: this.translate,
            postTermProps: {
              className: 'ice-form-field-infix-no-percentage',
              key: 'postTermCollectionValue',
              type: 'select',
              templateOptions: {
                label: this.translate.instant('WORKS.DELETE_CLAIM.POSTTERM_COLLECTION'),
                required: true,
              },
              hooks: {
                onInit: field => {
                  field.hide = !this.isWorkClaimScreen();
                },
              },
            },
            postTermDateProps: {
              label: this.translate.instant('CLAIM.SIMPLY_POSTTERM_DATE'),
              extraClass: 'not-type-field claim-shares-date ice-px-0 max',
              hooks: {
                onInit: field => {
                  field.hide = !this.isWorkClaimScreen();
                  field.form.get('postTermCollectionValue').valueChanges.subscribe(value => {
                    // we don't want it to be hidden when value is NONE or INDEFINITE
                    field.hide = !this.isWorkClaimScreen();
                  });
                },
              },
            },
          }),
          DatepickerUtils.getDatepickerField({
            key: 'priorRoyaltiesDate',
            label: this.translate.instant('CLAIM.SIMPLY_START_DATE'),
            translate: this.translate,
            extraClass: 'not-type-field claim-shares-date ice-px-0',
            hideExpression: model => !this.isWorkClaimScreen(),
          }),
        ];
  }

  getInclusionField(onlyAllRights = false): FormlyFieldConfig[] {
    return [
      {
        className: 'ice-form-field-infix-no-percentage w-50 ice-overflow-x-hidden-span',
        type: 'ice-input',
        key: 'inclusion',
        defaultValue: 'ALL',
        modelOptions: { updateOn: 'blur' },
        templateOptions: {
          required: true,
          readonly: true,
        },
        expressionProperties: {
          'templateOptions.disabled': model => this.editClaim || onlyAllRights,
        },
      },
    ];
  }

  getMsgValidator(): any {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            type: 'label',
            key: 'msgValidation',
            modelOptions: { updateOn: 'submit' },
            templateOptions: { class: 'ice-error-msg' },
            asyncValidators: {
              sharesValidation: (control: FormControl) => new Promise((resolve, reject) => resolve(this.errors.length === 0)),
            },
          },
        ],
      },
    ];
  }
  fillDataInEditClaimMode() {
    this.claimsSubscription = this.store
      .pipe(
        select(fromRoot.getCopyrightWork),
        withLatestFrom(this.store.pipe(select(fromRoot.getRouterParams))),
        filter(([work, params]) => work && work.claims && !!work.editClaim),
        take(1),
      )
      .subscribe(([detail, params]) => {
        this.editClaim = detail && detail.editingClaim && (detail.editClaim || detail.editConflictId);
        const { claims } = detail;
        if (this.editClaim && claims && claims.length && claims.length > 0 && params.tab === 'edit-claim') {
          const claim = ClaimantUtils.getClaimFromClaims(this.editClaim, claims);
          if (claim) {
            this.parsedShares = ClaimantUtils.getParsedSharesFromClaim(claim);
            const dates = ClaimantUtils.getDatesFromClaimShares(claim);
            const controls = this.form.parent.controls[0].controls;
            const sharesToTable = this.parsedShares.map(e => ({
              type: e.type,
              share: e.share,
              inclusion: e.inclusion,
              territory: e.territory,
              codes: e.codes,
              lastTerritoryValue: null,
            }));
            this.form.controls.shares.setValue(sharesToTable || []);
            controls['ClaimantIPI'].setValue(ClaimantUtils.getRelationFromClaim(claim, 'IPI'));
            controls['role'].setValue(claim.role);
            const startDateValue = dates.startDate === END_DATE_NO_DISPLAY ? END_DATE_ALIAS : dates.startDate;
            controls['startDate'].setValue(startDateValue, { emitEvent: false });
            const endDateValue = dates.endDate === END_DATE_NO_DISPLAY ? END_DATE_ALIAS : dates.endDate;
            controls['endDate'].setValue(endDateValue, { emitEvent: false });
            controls['ClaimantIPI'].disable();
            controls['role'].disable();
            controls['startDate'].disable();
            controls['endDate'].disable();
          } else {
            this.store.dispatch(new fromRoot.ShowSnackBar({ message: this.translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_NOT_CLAIM') }));
          }
        }
      });
  }

  public generalSharesValidator(control: FormControl, field: FormlyFieldConfig, isClaim: boolean, validateShares100: boolean) {
    if (!this.editClaim) {
      if (SharesValidator.check2DecimalsInAll(field.model)) {
        this.addError(ERROR_ONLY_2_DECIMALS, 'ONLY_2_DECIMALS');
        return false;
      } else {
        this.removeError(ERROR_ONLY_2_DECIMALS, 'ONLY_2_DECIMALS');
      }
      if (SharesValidator.checkNegative(field.model)) {
        this.addError(ERROR_NO_NEGATIVE_VALUE, 'ERROR_NO_NEGATIVE_VALUE');
        return false;
      } else {
        this.removeError(ERROR_NO_NEGATIVE_VALUE, 'ERROR_NO_NEGATIVE_VALUE');
      }
      if (!isClaim && validateShares100) {
        const { sum100, someMoreThan100, hasMinimumMRRights, hasMinimumPRRights } = this.checkTotal100(field.model);
        if (!sum100) {
          this.addError(ERROR_TOTAL_100, 'ERROR_TOTAL_100');
          return false;
        } else {
          this.removeError(ERROR_TOTAL_100, 'ERROR_TOTAL_100');
        }
        if (someMoreThan100) {
          this.addError(ERROR_TOTAL_MORE_THAN_100, 'ERROR_TOTAL_MORE_THAN_100');
          return false;
        } else {
          this.removeError(ERROR_TOTAL_MORE_THAN_100, 'ERROR_TOTAL_MORE_THAN_100');
        }
        if (!hasMinimumMRRights) {
          this.addError(ERROR_NO_ENOUGH_MR_RIGHTS, 'ERROR_NO_ENOUGH_MR_RIGHTS');
          return false;
        } else {
          this.removeError(ERROR_NO_ENOUGH_MR_RIGHTS, 'ERROR_NO_ENOUGH_MR_RIGHTS');
        }
        if (!hasMinimumPRRights) {
          this.addError(ERROR_NO_ENOUGH_PR_RIGHTS, 'ERROR_NO_ENOUGH_PR_RIGHTS');
          return false;
        } else {
          this.removeError(ERROR_NO_ENOUGH_PR_RIGHTS, 'ERROR_NO_ENOUGH_PR_RIGHTS');
        }
      }
    }
    return true;
  }

  public checkMinimumMRRights(totals) {
    const hasMRAllRights = keys(totals).some(totalKey => totalKey === `${RightTypes.MR}-${ALL_RIGHTS}`);
    const hasSYRight = keys(totals).some(totalKey => totalKey === `${RightTypes.MR}-${syRight}`);
    const hasOtherMRRight = keys(totals).some(
      totalKey => totalKey.includes(`${RightTypes.MR}-`) && totalKey !== `${RightTypes.MR}-${ALL_RIGHTS}` && totalKey !== `${RightTypes.MR}-${syRight}`,
    );
    return hasMRAllRights || (hasSYRight && hasOtherMRRight);
  }

  public checkMinimumPRRights(totals) {
    return keys(totals).some(totalKey => totalKey.includes(`${RightTypes.PR}-`));
  }

  public checkTotal100(model: Array<any>) {
    let sum100 = true;
    let someMoreThan100 = false;
    const totals = {};

    SharesValidator.initTotals(model, totals);
    SharesValidator.sumTypeALLValueToIndividualRights(model, totals);
    this.removeAllRightsIfNeeded(RightTypes.PR, totals);
    this.removeAllRightsIfNeeded(RightTypes.MR, totals);

    const hasMinimumMRRights = this.checkMinimumMRRights(totals);
    const hasMinimumPRRights = this.checkMinimumPRRights(totals);
    keys(totals).forEach(key => {
      if (totals[key] !== 100) {
        sum100 = false;
        if (totals[key] > 100) {
          someMoreThan100 = true;
        }
      }
    });

    return { sum100, someMoreThan100, hasMinimumMRRights, hasMinimumPRRights };
  }

  removeAllRightsIfNeeded(rightType, totals) {
    if (totals[`${rightType}-${ALL_RIGHTS}`]) {
      const individualRightsIncluded = [];
      mrRights.forEach(right => {
        individualRightsIncluded.push(!!totals[`${rightType}-${right}`]);
      });
      if (!individualRightsIncluded.some(rightExists => !rightExists)) {
        // Remove if all individual rights exists for this group
        delete totals[`${rightType}-${ALL_RIGHTS}`];
      }
    }
  }

  public addError(type: number, id: string) {
    const error = find(this.errors, { id, type });
    if (!error) {
      this.errors.push({
        msg: this.validationErrors[type].msg,
        type,
        id,
      });
    } else {
      error['msg'] = this.validationErrors[type].msg;
    }
    this.updateErrorMsg();
  }

  public removeError(type: number, id: string) {
    remove(this.errors, { id, type });
    this.updateErrorMsg();
  }

  public removeAllErrorsField(id: string) {
    remove(this.errors, { id });
    this.updateErrorMsg();
  }

  public updateErrorMsg() {
    if (this.msgValidationControl) {
      if (this.errors.length > 0) {
        if (this.currentErrorMsg !== this.errors[this.errors.length - 1].msg) {
          this.currentErrorMsg = this.errors[this.errors.length - 1].msg;
          this.msgValidationControl.setValue(this.currentErrorMsg);
        }
      } else if (this.currentErrorMsg !== '') {
        this.currentErrorMsg = '';
        this.msgValidationControl.setValue(this.currentErrorMsg);
      }
    }
  }

  public onInitShareField(field) {
    if (isNotValidPrimitiveNumerical(field.formControl.value) || field.formControl.value === 0) {
      field.formControl.setValue('0.00');
    }
    field.templateOptions.subscriptionChanges = field.formControl.valueChanges.subscribe(share => {
      if (isNotValidPrimitiveNumerical(share) || share === 0) {
        field.formControl.setValue('0.00');
      }
      if (share >= 0 && share <= 100) {
        this.removeError(ERROR_NO_NEGATIVE_VALUE, field.id);
      } else {
        this.addError(ERROR_NO_NEGATIVE_VALUE, field.id);
      }
    });
  }

  public onDestroyShareField(field) {
    this.removeError(ERROR_NO_NEGATIVE_VALUE, field.id);
    if (field && field.templateOptions && field.templateOptions.subscriptionChanges) {
      this.removeAllErrorsField(field.id);
      field.templateOptions.subscriptionChanges.unsubscribe();
    }
  }

  public territoryValidator(control, field) {
    return this.fieldValidatorService.existTerritory(
      control,
      field,
      () => this.addError(ERROR_TERRITORY_NOT_EXISTS, field.id),
      res => {
        if (res && res['tisns']) {
          field.templateOptions.bindedFieldCodes.setValue(res['tisns']);
        }
        this.removeError(ERROR_TERRITORY_NOT_EXISTS, field.id);
      },
    );
  }

  private isWorkClaimScreen() {
    return this.claimsType === 'WORK_CLAIMS';
  }
}
